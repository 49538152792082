/* eslint-disable react-hooks/exhaustive-deps */

import AmountCard from "./AmountCard";
import HistoryTable from "./HistoryTable";

import "./Payment.scss";

import PaymentProgress from "./PaymentProgress";

import moment from "moment";
import { paymentDetailsType } from "../../types/VisitDetailsSeriesType";

interface PaymentDetailsPropsType {
  data: paymentDetailsType;
}

const Payment: React.FC<any> = (props: PaymentDetailsPropsType) => {
  const data = props.data;

  const generateActivityArray = () => {
    let newArray: any[] = [];
    data?.activities.forEach((item: any) => {
      const description = item.description;
      const date = moment(item.activity_date).format("MM/DD");

      const today = moment(new Date());
      let duration = moment.duration(today.diff(date));
      let noOfDays = Math.round(duration.asDays());

      newArray.push({ date: date, description: description, days: noOfDays });
    });
    return newArray;
  };

  const tableData = generateActivityArray();
  return (
    <div className="payment-page">
      <div className="amountcard-block">
        <AmountCard amount={data?.insurance_paid_amount} />
      </div>
      <div className="payment-status">
        <p className="sub-heading">Payment Status</p>
        <PaymentProgress status={data?.status} />
      </div>

      <div className="payment-history">
        <p className="sub-heading">Payment History</p>
        <HistoryTable
          header={["Date", "Description", "Days"]}
          columnSize={["2", "8", "2"]}
          data={tableData}
        />
      </div>
    </div>
  );
};

export default Payment;
