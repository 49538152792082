/* eslint-disable react-hooks/exhaustive-deps */
import { IonContent, IonIcon, IonText } from "@ionic/react";
import "./PatientSearch.scss";
import { useEffect, useState } from "react";
import { getPatientsSearch } from "../../../helper/backendHelper";
import { PatientType } from "../../../types/PatientType";
import SearchBox from "../SearchBox/SearchBox";
import { IconSvg } from "../IconSvg/IconSvg";
import { SortIcon } from "../../../icons/icons";
import { calendarOutline, star, starOutline } from "ionicons/icons";
import { debounce } from "../../../utils/debounce";
import { useAppSelector } from "../../../store/hooks";
import Loader from "../Loaders/Loader";
import moment from "moment";
// import { useAppDispatch } from "../../../store/hooks";
// import { setSelectedPatient } from "../../../store/reducers/patient";

// import { getPatients } from "../../../helper/backendHelper";

type PatientSearchProps = {
  onSelect: (data: PatientType) => void;
  onClose?: (isClose: boolean) => void;
};

const PatientSearch: React.FC<PatientSearchProps> = ({ onSelect, onClose }) => {
  const [lastNameSearch, setLastNameSearch] = useState("");
  // const [firstNameSearch, setFirstNameSearch] = useState("");
  const [searchResult, setSearchResult] = useState<PatientType[]>([]);
  const [loading, setLoading] = useState(false);
  const darkThemeBoolean = useAppSelector((state) => state.layout.darkTheme);
  // const [Patient, setPatient] = useState<PatientType | null>(null);
  // const router = useIonRouter();

  // const dispatch = useAppDispatch();

  useEffect(() => {
    patientListHandler(lastNameSearch);
  }, [lastNameSearch]);

  const patientListHandler = async (
    // firstNameSearch: string,
    lastNameSearch: string
  ) => {
    setLoading(true);
    try {
      let res = await getPatientsSearch({
        name: lastNameSearch,
      });

      setSearchResult(res.resData);
    } catch (error) {}
    setLoading(false);
  };

  // useEffect(() => {
  //   if (Patient != null) {
  //     onSelect(Patient);
  //     if (onClose) {
  //       onClose(false);
  //     }
  //   }
  // }, [Patient]);

  // const onSelectPatient = (item: any) => {
  //   setPatient(item);
  //   dispatch(setSelectedPatient(item));
  //   router.push("/addNewEncounter");
  // };

  const onSelectFavourite = (i: any) => {
    for (let index = 0; index < searchResult?.length; index++) {
      if (index === i) {
        searchResult[index] = {
          ...searchResult[index],
          favourite: searchResult[index]?.favourite
            ? !searchResult[index]?.favourite
            : true,
        };
      }
    }
    setSearchResult([...searchResult]);
  };

  return (
    <>
      <div className="grid">
        <div className="newenc-searchbox-container">
          <div className="newenc-searchbox-census">
            <SearchBox
              variant="round-corner"
              placeholder={"Search in patients..."}
              onChange={debounce(setLastNameSearch, 1000)}
              value={lastNameSearch}
            />
          </div>

          <div className="newenc-filter-holder">
            <div
              className="enc-icon-holder filter-icon"
              // onClick={() => handleFilterModal(true)}
            >
              <IconSvg Icon={SortIcon} />
            </div>
          </div>
        </div>
      </div>
      <IonContent>
        <div className="suggestions-list">
          {loading === true ? (
            <div className="loaderPatientSearch">
              <Loader
                data={{
                  name: "crescent",
                  color: "primary",
                }}
              />
            </div>
          ) : searchResult?.length > 0 && loading === false ? (
            searchResult.map((item: any, index: number) => (
              <div className="patient-card-encounter">
                <div className="column-zero">
                  <div
                    className={`leftIcon ${
                      item?.favourite === true && "favourite"
                    }`}
                    onClick={(e) => onSelectFavourite(index)}
                  >
                    <IonIcon
                      icon={
                        item?.favourite === true
                          ? star
                          : !darkThemeBoolean
                          ? starOutline
                          : star
                      }
                    />
                  </div>
                </div>
                <div className="column-one">
                  <div>
                    <span className="name-bold">{item?.last_name}, </span>
                    <span className="name-regular">{item?.first_name}</span>
                    {item?.dob && (
                      <span className="name-regular">
                        {" "}
                        ({moment(item?.dob).format("MM-DD-YYYY")})
                      </span>
                    )}
                  </div>
                  <div>
                    <div>
                      <span className="facility-name">
                        {item?.facility?.name} -{" "}
                      </span>
                    </div>
                  </div>

                  <div>
                    <div>
                      <span className="location">
                        {" "}
                        #{item?.room ? item?.room : "-"} |
                      </span>
                      <span className="diagnosis-code">
                        {item?.diagnosis_code_primary?.code} -{" "}
                      </span>
                      <span className="diagnosis-description">
                        {item?.diagnosis_code_primary?.description}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="column-two">
                  <div className="code">99233 </div>
                  {item?.admission_date && (
                    <div className="admission">
                      {moment(item?.admission_date).format("MM-DD-YYYY")}
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : searchResult?.length === 0 && loading === false ? (
            <p className="text-display user-message">No result found</p>
          ) : (
            <></>
          )}
        </div>
      </IonContent>
    </>
  );
};

export default PatientSearch;
