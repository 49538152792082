import { IonCard, IonCardSubtitle, IonIcon } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
import React from "react";
import "./EncounterCard.scss";

type EncounterCardType = {
  title: string;
  isIcon?: boolean;
};

const EncounterCard: React.FC<EncounterCardType> = ({
  children,
  title,
  isIcon,
}) => {
  return (
    <IonCard className="diagnosisCard">
      <div className="diagnosisCardHeader">
        <IonCardSubtitle className="diagnosisCardTitle">
          {title}
        </IonCardSubtitle>
        {isIcon && (
          <IonIcon className="info-icon" icon={informationCircleOutline} />
        )}
      </div>
      {children}
    </IonCard>
  );
};

export default EncounterCard;
