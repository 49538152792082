import { IonContent, IonIcon, IonModal, IonText } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React from "react";
import "./RecallBtnFirstPopup.scss";

interface RecallBtnFirstPopupType {
  firstPopupISOpen: boolean;
  setFirstPopupISOpen: (item: boolean) => void;
  setSecondPopupISOpen: (item: boolean) => void;
}

const RecallBtnFirstPopup: React.FC<RecallBtnFirstPopupType> = ({
  firstPopupISOpen,
  setFirstPopupISOpen,
  setSecondPopupISOpen,
}) => {
  const closeModal = () => {
    setFirstPopupISOpen(false);
  };

  const onContinue = () => {
    setFirstPopupISOpen(false);
    setSecondPopupISOpen(true);
  };
  return (
    <>
      <IonModal
        className="FirstPopup_modal"
        isOpen={firstPopupISOpen}
        onDidDismiss={closeModal}
      >
        <div className="modal-heading-container">
          <div className="modal-heading">Recall submission</div>
          <div className="modal-icon-holder">
            <IonIcon
              onClick={() => setFirstPopupISOpen(false)}
              className="close-icon"
              icon={closeOutline}
            />
          </div>
        </div>
        <IonContent className="FirstPopup_modal_content">
          <div className="FirstPopup_modal_text">
            <IonText>Are you sure?</IonText>
          </div>

          <div className="modalButtons">
            <div
              className="FirstPopupButton btn btn-primary"
              onClick={() => onContinue()}
            >
              <span>Confirm</span>
            </div>
            <div
              className="FirstPopupButton cancelButton btn btn-primary"
              onClick={() => closeModal()}
            >
              <span>Cancel</span>
            </div>
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default RecallBtnFirstPopup;
