import {
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import { personOutline } from "ionicons/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  bulkClone,
  bulkUndoDischargeKey,
  bulkVisitRecall,
  bulkVisitStatus,
  bulkVisitStatusKey,
  postBulkVoid,
} from "../../helper/backendHelper";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getVisitsCensus } from "../../store/thunk/censusThunk";
import { newToast } from "../Common/Toaster/Toast";
import BulkRecallPopup from "./BulkRecallPopup";

type ToastedList = {
  id: number;
  description: string;
};

type BulkActionProps = {
  selectedVisits: any[];
  setSelectedVisits: (item: any) => void;
  setToastedMessage: (item: any) => void;
  toastedMessage: ToastedList[];
};

const buttonStatus = {
  discharge: "Discharge",
  billSalt: "Bill SALT",
  confirm: "Confirm",
  undo_discharge: "Undo discharge",
  skip: "Skip",
  recall: "Recall bill",
  undo_skip: "Undo skip",
  bill: "Bill",
  void: "Void",
};

const BulkActions: React.FC<BulkActionProps> = ({
  selectedVisits,
  setSelectedVisits,
  setToastedMessage,
  toastedMessage,
}) => {
  const router = useIonRouter();
  const dispatch = useAppDispatch();
  const { visitStatuses } = useAppSelector((state) => state.census);
  const currentFacility = useAppSelector(
    (state) => state.census.censusFacility
  );
  const thisDate =
    useAppSelector((state) => state.census.activeDate) || new Date();
  const [isBulkRecallModel, setIsBulkRecallModel] = useState<boolean>(false);
  const [commonActions, setCommonActions] = useState<String[]>([]);
  console.log("commonActions", commonActions);

  useEffect(() => {
    let currentVisitAction = [];
    let Statuses: any = visitStatuses;
    for (let i = 0; i < selectedVisits?.length; i++) {
      currentVisitAction.push(Statuses[selectedVisits[i].status]["actions"]);
    }

    let common = commonElements(...currentVisitAction).filter(
      (x) =>
        ![
          "Change CPT",
          "Change DX",
          "Change POS",
          "Visit note",
          "Clone visit",
          "Bill",
          "Bill SALT",
          // 'Undo discharge',
          // 'Undo skip',
          "Change appointment",
          // 'Void',
          "_Change CPT",
          "_Change DX",
          "_Change DOS",
          "_Change POS",
          "Change DOS",
          "RVU",
        ].includes(x)
    );

    let currentVisitActionss = [];
    //show common actions based on selected visits
    for (let i = 0; i < selectedVisits?.length; i++) {
      const visit = selectedVisits[i];
      let currentVisitActions: any[] = [];
      if (visit?.status === "Not seen") {
        currentVisitActions = [
          ...currentVisitActions,
          buttonStatus["undo_skip"],
        ];
      }
      if (common.includes(buttonStatus["confirm"])) {
        currentVisitActions = [...currentVisitActions, buttonStatus["confirm"]];
      }
      if (common.includes(buttonStatus["void"])) {
        currentVisitActions = [...currentVisitActions, buttonStatus["void"]];
      }
      if (common.includes(buttonStatus["undo_discharge"])) {
        if (
          visit?.statuses_index?.last_visit &&
          new Date(visit?.service_date_from) <= new Date()
        )
          currentVisitActions = [
            ...currentVisitActions,
            buttonStatus["undo_discharge"],
          ];
      }
      if (common.includes(buttonStatus["discharge"])) {
        if (
          !visit?.statuses_index?.last_visit &&
          new Date(visit?.service_date_from) <= new Date()
        )
          currentVisitActions = [
            ...currentVisitActions,
            buttonStatus["discharge"],
          ];
      }
      if (common.includes(buttonStatus["skip"])) {
        currentVisitActions = [...currentVisitActions, buttonStatus["skip"]];
      }
      if (common.includes(buttonStatus["billSalt"])) {
        if (
          new Date(thisDate) <= new Date() &&
          !visit?.statuses_index?.recalled &&
          visit?.procedure_code_primary &&
          visit?.procedure_code_primary?.code &&
          new Date(visit?.service_date_from) < new Date()
        )
          currentVisitActions = [
            ...currentVisitActions,
            buttonStatus["billSalt"],
          ];
      }
      if (common.includes("Bill")) {
        if (
          new Date(thisDate) <= new Date() &&
          visit?.diagnosis_codes?.length > 0 &&
          visit?.procedureCodes?.length > 0
        ) {
          currentVisitActions = [...currentVisitActions, "Bill"];
        }
      }
      if (common.includes(buttonStatus["recall"])) {
        if (!visit?.statuses_index?.recalled)
          currentVisitActions = [
            ...currentVisitActions,
            buttonStatus["recall"],
          ];
      }
      currentVisitActionss.push(currentVisitActions);
    }

    // console.log({ currentVisitActionss });

    setCommonActions(
      commonElements(...currentVisitActionss).filter(
        (x) =>
          ![
            "Change CPT",
            "Change DX",
            "Change POS",
            "Visit note",
            "Clone visit",
            "Bill",
            "Bill SALT",
            // 'Undo discharge',
            // 'Undo skip',
            "Change appointment",
            // 'Void',
            "_Change CPT",
            "_Change DX",
            "_Change DOS",
            "_Change POS",
            "Change DOS",
            "RVU",
          ].includes(x)
      )
    );

    // setCommonActions(common);

    // console.log({ currentVisitAction, common });
  }, [selectedVisits, visitStatuses]);

  const commonElements = (...arr: any[]) => {
    // console.log({ arr })

    const lookup = arr.reduce((map, a) => {
      const unique = [...new Set(a)];
      unique.forEach((v) => {
        map.set(v, (map.get(v) || 0) + 1);
      });
      return map;
    }, new Map());

    return [...lookup.keys()].filter((k) => lookup.get(k) === arr?.length);
  };

  const onBulkVoid = async () => {
    const visitIds = selectedVisits.map((selectedVisit) => selectedVisit._id);
    const req = {
      visitIds: visitIds,
      status: "Checked In",
      isVoid: true,
    };

    const response = await postBulkVoid(req);
    if (response?.status === "success") {
      let toastMsg = newToast("Visits updated successfully", "Success");
      setToastedMessage([...toastedMessage, toastMsg]);
    } else {
      let toastMsg = newToast("These visits cannot be void", "Error");
      setToastedMessage([...toastedMessage, toastMsg]);
    }
    setSelectedVisits([]);
    dispatch(
      getVisitsCensus({
        servicedate: moment(thisDate).format("YYYY-MM-DD"),
        facilityId: currentFacility?._id,
      })
    );
  };

  const onBulkDischarge = async () => {
    let successVisitsCount = 0;
    let failedVisitsCount = 0;
    // 3 array of objects
    let lastDischarge = [];
    let scheduledDischarge = [];
    let otherDischarge = [];

    for (let i = 0; i < selectedVisits.length; i++) {
      const visit = selectedVisits[i];
      const visitId = visit._id;
      if (visit.status === "last") {
        const body = {
          visit_id: visitId,
          status: "not_seen",
          service_date: thisDate,
          last_visit: true,
        };
        lastDischarge.push(body);
      } else if (
        visit.status === "scheduled" ||
        visit.status === "checked_in" ||
        visit.status === "admit"
      ) {
        const body = {
          visit_id: visitId,
          status: "not_seen",
          last_visit: true,
        };
        scheduledDischarge.push(body);
      } else {
        const body = {
          visit_id: visitId,
          key: "last_visit",
          value: true,
        };
        otherDischarge.push(body);
      }
    }

    if (lastDischarge.length > 0) {
      let result = await bulkClone(JSON.stringify(lastDischarge));

      if (result?.status === "sucesss") {
        result.data.forEach((item: any) => {
          if (item.status === "success") successVisitsCount++;
          if (item.status === "failure") failedVisitsCount++;
        });
      }
    }
    if (scheduledDischarge.length > 0) {
      let result = await bulkVisitStatus(
        JSON.stringify({
          visit_id: scheduledDischarge.map((x) => x.visit_id),
          status: scheduledDischarge.map((x) => x.status),
          last_visit: scheduledDischarge.map((x) => x.last_visit),
        })
      );

      if (result?.status === "sucesss") {
        result.data.forEach((item: any) => {
          if (item.status === "success") successVisitsCount++;
          if (item.status === "failure") failedVisitsCount++;
        });
      }
    }
    if (otherDischarge.length > 0) {
      let result = await bulkVisitStatusKey({
        visit_id: otherDischarge.map((x) => x.visit_id),
        key: otherDischarge.map((x) => x.key),
        value: otherDischarge.map((x) => x.value),
      });

      if (result?.status === "sucesss") {
        result.data.forEach((item: any) => {
          if (item.status === "success") successVisitsCount++;
          if (item.status === "failure") failedVisitsCount++;
        });
      }
    }

    if (selectedVisits.length === successVisitsCount) {
      let toastMsg = newToast("All visits updated successfully", "Success");
      setToastedMessage([...toastedMessage, toastMsg]);
    } else if (selectedVisits.length === failedVisitsCount) {
      let toastMsg = newToast("Failed to update visits", "Error");
      setToastedMessage([...toastedMessage, toastMsg]);
    } else {
      let toastMsg = newToast(
        `{${successVisitsCount} "visits updated successfully.${failedVisitsCount} "visits failed to update"}`,
        "Error"
      );
      setToastedMessage([...toastedMessage, toastMsg]);
    }
    setSelectedVisits([]);
    dispatch(
      getVisitsCensus({
        servicedate: moment(thisDate).format("YYYY-MM-DD"),
        facilityId: currentFacility?._id,
      })
    );
  };

  const onBulkUndoDischarge = async () => {
    let successVisitsCount = 0;
    let failedVisitsCount = 0;

    let undoDischargeVisits = [];
    let undoDischargeVisitsSocketObj = [];
    for (let i = 0; i < selectedVisits.length; i++) {
      const visit = selectedVisits[i];
      console.log(visit);
      const visitId = visit._id;
      let body = {
        visit_id: visitId,
        key: "last_visit",
        value: false,
      };
      undoDischargeVisits.push(body);
      undoDischargeVisitsSocketObj.push(visit);
    }

    if (undoDischargeVisits.length > 0) {
      let result = await bulkUndoDischargeKey({
        visit_id: undoDischargeVisits.map((x) => x.visit_id),
        key: undoDischargeVisits.map((x) => x.key),
        value: undoDischargeVisits.map((x) => x.value),
      });

      console.log("res", result);
      if (result?.status === "sucesss") {
        result.data.forEach((item: any) => {
          if (item.status === "success") successVisitsCount++;
          if (item.status === "failure") failedVisitsCount++;
        });
      }
    }

    if (selectedVisits.length === successVisitsCount) {
      let toastMsg = newToast("All visits updated successfully", "Success");
      setToastedMessage([...toastedMessage, toastMsg]);
    } else if (selectedVisits.length === failedVisitsCount) {
      let toastMsg = newToast("Failed to update visits", "Error");
      setToastedMessage([...toastedMessage, toastMsg]);
    } else {
      let toastMsg = newToast(
        `${successVisitsCount} visits updated successfully.${failedVisitsCount} visits failed to update`,
        "Error"
      );
      setToastedMessage([...toastedMessage, toastMsg]);
    }
    setSelectedVisits([]);
    dispatch(
      getVisitsCensus({
        servicedate: moment(thisDate).format("YYYY-MM-DD"),
        facilityId: currentFacility?._id,
      })
    );
  };

  const onBulkSkip = async () => {
    let successVisitsCount = 0;
    let failedVisitsCount = 0;

    let lastSkipVisits = [];
    let otherSkipVisits = [];
    for (let i = 0; i < selectedVisits.length; i++) {
      const visit = selectedVisits[i];
      const visitId = visit._id;
      if (visit.status == "last") {
        let body = {
          visit_id: visitId,
          status: "Not seen",
          service_date: moment(thisDate).format("YYYY-MM-DD"),
        };
        lastSkipVisits.push(body);
      } else {
        let body = {
          visit_id: visitId,
          status: "Not seen",
        };
        otherSkipVisits.push(body);
      }
    }

    if (lastSkipVisits.length > 0) {
      let result = await bulkClone(JSON.stringify(lastSkipVisits));
      if (result?.status === "sucesss") {
        result.data.forEach((item: any) => {
          if (item.status === "success") successVisitsCount++;
          if (item.status === "failure") failedVisitsCount++;
        });
      }
    }

    if (otherSkipVisits.length > 0) {
      let result = await bulkVisitStatus({
        visit_id: otherSkipVisits.map((x) => x.visit_id),
        status: otherSkipVisits.map((x) => x.status),
      });
      if (result?.status === "sucesss") {
        result.data.forEach((item: any) => {
          if (item.status === "success") successVisitsCount++;
          if (item.status === "failure") failedVisitsCount++;
        });
      }
    }

    if (selectedVisits.length === successVisitsCount) {
      let toastMsg = newToast("All visits updated successfully", "Success");
      setToastedMessage([...toastedMessage, toastMsg]);
    } else if (selectedVisits.length === failedVisitsCount) {
      let toastMsg = newToast("Failed to update visits", "Error");
      setToastedMessage([...toastedMessage, toastMsg]);
    } else {
      let toastMsg = newToast(
        `${successVisitsCount} visits updated successfully.${failedVisitsCount} visits failed to update`,
        "Error"
      );
      setToastedMessage([...toastedMessage, toastMsg]);
    }
    setSelectedVisits([]);
    dispatch(
      getVisitsCensus({
        servicedate: moment(thisDate).format("YYYY-MM-DD"),
        facilityId: currentFacility?._id,
      })
    );
  };

  const onBulkMyCensus = async () => {
    let successVisits = [];
    let failedVisits = [];

    let sucessVisitsCount = 0;
    let failedVisitsCount = 0;

    let lastMYCensus = [];
    let admitMyCensus = [];
    for (let i = 0; i < selectedVisits.length; i++) {
      const visit = selectedVisits[i];
      const visitId = visit._id;

      if (visit.status == "Last") {
        let body = {
          visit_id: visitId,
          status: "Checked In",
          service_date: moment(thisDate).format("YYYY-MM-DD"),
        };
        lastMYCensus.push(body);
        //socket emit info
      } else if (
        visit.status == "Scheduled" ||
        visit.status == "Not seen" ||
        visit.status == "Admit"
      ) {
        let body = {
          visit_id: visitId,
          status: "Checked In",
        };
        admitMyCensus.push(body);
        //socket emit info
      } else {
        // let toastMsg = 'Danger'
        // setToastedDescription('Oops... Failed to update status. Please try again...')
        // setToastedMessage(toastMsg)
        // setVisibleToast(true)
      }
    }
    if (lastMYCensus.length > 0) {
      let result = await bulkClone(JSON.stringify(lastMYCensus));
      if (result?.status === "sucesss") {
        result.data.forEach((item: any) => {
          if (item.status === "success") sucessVisitsCount++;
          if (item.status === "failure") failedVisitsCount++;
        });
      }
    }
    if (admitMyCensus.length > 0) {
      let result = await bulkVisitStatus({
        visit_id: admitMyCensus.map((x) => x.visit_id),
        status: admitMyCensus.map((x) => x.status),
      });
      const ids = admitMyCensus.map((item) => item.visit_id);
      if (result?.status === "sucesss") {
        result.data.forEach((item: any) => {
          if (item.status === "success") sucessVisitsCount++;
          if (item.status === "failure") failedVisitsCount++;
        });
      }
    }

    if (selectedVisits.length === sucessVisitsCount) {
      let toastMsg = newToast("All visits updated successfully", "Success");
      setToastedMessage([...toastedMessage, toastMsg]);
    } else if (selectedVisits.length === failedVisitsCount) {
      let toastMsg = newToast("Failed to update visits", "Error");
      setToastedMessage([...toastedMessage, toastMsg]);
    } else {
      let toastMsg = newToast(
        `${sucessVisitsCount} visits updated successfully.${failedVisitsCount} visits failed to update`,
        "Error"
      );
      setToastedMessage([...toastedMessage, toastMsg]);
    }

    setSelectedVisits([]);

    dispatch(
      getVisitsCensus({
        servicedate: moment(thisDate).format("YYYY-MM-DD"),
        facilityId: currentFacility?._id,
      })
    );
  };

  const onBulkRecall = () => {
    setIsBulkRecallModel(true);
  };

  const onBulkConfirm = async (messageText = "") => {
    let result_visits = [];

    let sucessVisitsCount = 0;
    let failedVisitsCount = 0;
    const recalBody = [];

    for (let i = 0; i < selectedVisits.length; i++) {
      const visit = selectedVisits[i];
      const visitId = visit._id;

      recalBody.push(visitId);
    }

    let x = {
      visit_id: recalBody,
      messageText: messageText,
    };
    if (recalBody.length > 0) {
      const result = await bulkVisitRecall(x);

      if (result?.status === "sucesss") {
        result.data.forEach((item: any) => {
          if (item.status === "success") {
            sucessVisitsCount++;
          }
          if (item.status === "failure") failedVisitsCount++;
        });
      }
    }
    if (selectedVisits.length === sucessVisitsCount) {
      let toastMsg = newToast("All visits updated successfully", "Success");
      setToastedMessage([...toastedMessage, toastMsg]);
    } else if (selectedVisits.length === failedVisitsCount) {
      let toastMsg = newToast("Failed to update visits", "Error");
      setToastedMessage([...toastedMessage, toastMsg]);
    } else {
      let toastMsg = newToast(
        `{${sucessVisitsCount} "visits updated successfully.${failedVisitsCount} "visits failed to update"}`,
        "Error"
      );
      setToastedMessage([...toastedMessage, toastMsg]);
    }

    setIsBulkRecallModel(false);
    setSelectedVisits([]);
    dispatch(
      getVisitsCensus({
        servicedate: moment(thisDate).format("YYYY-MM-DD"),
        facilityId: currentFacility?._id,
      })
    );
  };

  // console.log({ commonActions, selectedVisits, visitStatuses });

  return (
    <div className="encounter-popup">
      <IonGrid>
        {commonActions?.includes("Void") && (
          <IonRow>
            <IonCol
              className="encounter-popup-col"
              onClick={() => onBulkVoid()}
            >
              <button className="encounter-popup-btn">
                <IonText>Void</IonText>
              </button>
            </IonCol>
          </IonRow>
        )}
        {commonActions?.includes("Confirm") && (
          <IonRow>
            <IonCol className="encounter-popup-col">
              <button
                className="encounter-popup-btn"
                onClick={() => onBulkMyCensus()}
              >
                <IonText>My Census</IonText>
              </button>
            </IonCol>
          </IonRow>
        )}
        {commonActions?.includes("Discharge") && (
          <IonRow>
            <IonCol
              className="encounter-popup-col"
              onClick={() => onBulkDischarge()}
            >
              <button className="encounter-popup-btn">
                <IonText>Discharge</IonText>
              </button>
            </IonCol>
          </IonRow>
        )}
        {commonActions?.includes("Skip") && (
          <IonRow>
            <IonCol
              className="encounter-popup-col"
              onClick={() => onBulkSkip()}
            >
              <button className="encounter-popup-btn">
                <IonText>Skip</IonText>
              </button>
            </IonCol>
          </IonRow>
        )}
        {commonActions?.includes("Undo discharge") && (
          <IonRow>
            <IonCol className="encounter-popup-col">
              <button
                className="encounter-popup-btn"
                onClick={() => onBulkUndoDischarge()}
              >
                <IonText>Undo discharge</IonText>
              </button>
            </IonCol>
          </IonRow>
        )}
        {commonActions?.includes("Undo skip") && (
          <IonRow>
            <IonCol className="encounter-popup-col">
              <button className="encounter-popup-btn">
                <IonText>Undo skip</IonText>
              </button>
            </IonCol>
          </IonRow>
        )}
        {commonActions?.includes("Recall bill") && (
          <IonRow>
            <IonCol
              className="encounter-popup-col"
              onClick={() => onBulkRecall()}
            >
              <button className="encounter-popup-btn">
                <IonText>Recall Submission</IonText>
              </button>
            </IonCol>
          </IonRow>
        )}
      </IonGrid>

      <BulkRecallPopup
        isBulkRecallModel={isBulkRecallModel}
        setIsBulkRecallModel={setIsBulkRecallModel}
        onConfirm={() => onBulkConfirm()}
      />
    </div>
  );
};

export default BulkActions;
