import axios from "axios";
import { SETTINGS_URL, setupInterceptorsTo } from "./apiHelper";

const settingsApi = setupInterceptorsTo(
  axios.create({
    baseURL: SETTINGS_URL,
    headers: {
      "Content-Type": "application/json",
      // Origin: "claimocity",
    },
  })
);

export async function getSettings(url: any, config = {}) {
  return await settingsApi
    .get(url, { ...config })
    .then((response: { data: any }) => response.data);
}

export async function postSettings(url: any, data: any, config = {}) {
    return settingsApi
    .post(url, { ...data }, { ...config })
    .then((response: { data: any }) => response.data);
}

export async function putSettings(url: any, data: any, config = {}) {
  return settingsApi
    .put(url, { ...data }, { ...config })
    .then((response: { data: any }) => response.data);
}

export async function putSettingsForSingleParam(url: any, config = {}) {
  return settingsApi
    .put(url,{ ...config })
    .then((response: { data: any }) => response.data);
}

export async function postSettingsForSingleParam(url: any, data: any=[], config = {}) {
  return settingsApi
    .post(url,[ ...data ],{ ...config })
    .then((response: { data: any}) => response.data);
}

export async function delSettings(url: any, config = {}) {
  return await settingsApi
    .delete(url, { ...config })
    .then((response: { data: any }) => response.data);
}

