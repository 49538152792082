/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  IonContent,
  IonHeader,
  IonGrid,
  IonText,
  IonToolbar,
  IonRow,
  IonButtons,
  IonBackButton,
  IonLabel,
  IonCol,
  useIonRouter,
} from "@ionic/react";

import { arrowBackOutline } from "ionicons/icons";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
// import { selectTheme, switchTheme } from "../../store/reducers/layout";
import Button from "../../components/Common/Buttons/Buttons";
// import setNewPassword from "./SetNewPassword";
// import PasswordRecovery from "./PasswordRecovery";
import "../ForgotPassword/ForgotPassword.scss";
import { InputBoxType } from "../../types/Types";
import { verifyForgotpwdOtp } from "../../store/thunk/forgotPasswordThunk";
import { Layout } from "../../components/Layout/Layout";
import InputFieldNew from "../../components/Common/InputFieldNew/InputFiledNew";

const VerificationCode: React.FC = () => {
  const [verificationCode, setVerificationCode] = useState<InputBoxType>(null);
  const [invalidcode, setInvalidcode] = useState<boolean>(false);

  const thisState = useAppSelector((state) => state.forgotPassword);

  const [isSubmited, setIsSubmited] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const router = useIonRouter();

  // const toggleThemeEvent = (isDark: boolean) => {
  //   dispatch(switchTheme(isDark));
  // };

  const onVeryfyCode = () => {
    if (
      thisState.userName != null &&
      verificationCode != null &&
      verificationCode !== ""
    ) {
      setIsSubmited(true);
      dispatch(
        verifyForgotpwdOtp({
          otp: verificationCode.toLocaleString(),
          username: thisState.userName,
        })
      ).then((res) => {
        if (res.meta.requestStatus == "fulfilled") {
          setInvalidcode(false);
          if (res?.payload?.data?.user_id !== null) {
            setVerificationCode(null);
            router.push("/setNewPassword", "forward", "push");
          }
        } else {
          setInvalidcode(true);
        }
      });
    }
  };

  const HandleVerificationCode = (code: any) => {
    setVerificationCode(code);
    if (code.length === 6) {
      setInvalidcode(false);
    } else {
      setInvalidcode(true);
    }
  };

  return (
    <Layout
      isLoading={thisState.isloading}
      isError={thisState.isError}
      errorMsg={thisState.errorMsg}
    >
      <IonContent className="forgot-password-page ion-padding">
        <div className="main-bg">
          <IonHeader class="ion-no-border" className="ion-header">
            <IonToolbar className="ion-toolbar" mode="ios">
              <IonButtons slot="start">
                <IonBackButton
                  defaultHref="/passwordRecovery"
                  text=""
                  icon={arrowBackOutline}
                />
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <div className="centered-div-holder">
            <div className="reset-password-container">
              <IonGrid className="ion-grid1 verification" fixed>
                <IonRow className="text-center title">
                  <IonText className="page-heading">
                    {" "}
                    Enter verification code
                  </IonText>
                </IonRow>

                <IonRow className="ion-row">
                  <IonText color="medium" className="ion-text page-sub-txt">
                    You should have received a text message on your registered
                    phone number. Please enter the code.
                  </IonText>
                </IonRow>

                <IonRow className="label-text">
                  <IonLabel className="ionlabel">
                    Enter Verification code
                  </IonLabel>
                </IonRow>

                <IonRow className="ion-row">
                  <InputFieldNew
                    type="text"
                    value={verificationCode}
                    onKeyUp={(e) =>
                      HandleVerificationCode(e.currentTarget?.value)
                    }
                    invalid={invalidcode ? true : false}
                  />
                </IonRow>

                {isSubmited && invalidcode && (
                  <IonRow>
                    <span className="errormsg">
                      Please enter a valid verification code
                    </span>
                  </IonRow>
                )}

                <IonRow className="no-code-recieve">
                  <IonCol className="no-code-recieve-col">
                    <IonLabel className="label-recieve-varification">
                      {/* Didn't receive the code? */}
                      <a className="no-recieve-req" href="/">
                        {/* Send it again */}
                      </a>
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow className="btn-verification-row">
                  <IonCol className="btn-verification">
                    <Button
                      onClick={onVeryfyCode}
                      fullWidth
                      disabled={!verificationCode}
                    >
                      <span className="btn-label-verification lbl-continue">
                        Continue
                      </span>
                    </Button>
                  </IonCol>
                  <IonCol className="label-verification-col">
                    <IonLabel className="label-recieve-varification">
                      {/* I want to use my email address */}
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </div>
      </IonContent>
    </Layout>
  );
};

export default VerificationCode;
