/* eslint-disable react-hooks/exhaustive-deps */
import { IonCol, IonIcon, IonRow } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/Common/CustomButton/CustomButton";
import SelectBox from "../../components/Common/SelectBox/SelectBox";
import CustomCard from "../../components/Common/CustomCard/CustomCard";
import { CodeTemplatesType, FavProceduresType } from "../../types/AddVisitType";
import {
  codeTemplatesSelectDataFormat,
  proceduresTxtArrayToObj,
} from "../../helper/formater";

type ProceduresBlockProps = {
  codeTemplates: CodeTemplatesType[];
  proceduresCode: FavProceduresType[];
  selectedProcedureCodes: FavProceduresType[];
  onsetSelectedProcedures: (codes: FavProceduresType[]) => void;
  onSetChangeTemplate: (codes: string) => void;
};

const ProceduresBlock: React.FC<ProceduresBlockProps> = ({
  codeTemplates,
  proceduresCode,
  onsetSelectedProcedures,
  selectedProcedureCodes,
  onSetChangeTemplate,
}) => {
  const [selectTemplate, setSelectTemplate] = useState(
    codeTemplatesSelectDataFormat(codeTemplates)[0]
  );

  const [selectedProcedureCodesTxt, setSelectedProcedureCodesTxt] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (selectedProcedureCodesTxt.length >= 0) {
      let tempArray = proceduresTxtArrayToObj(
        selectedProcedureCodesTxt,
        proceduresCode
      );
      onsetSelectedProcedures(tempArray);
    }
  }, [selectedProcedureCodesTxt]);

  useEffect(() => {
    onSetChangeTemplate(selectTemplate);
  }, [selectTemplate]);

  const deSelectHandler = (code: string) => {
    let tempArry = [...selectedProcedureCodesTxt];
    let codeIndex = tempArry.indexOf(code);
    if (codeIndex !== -1) {
      tempArry.splice(codeIndex, 1);
      setSelectedProcedureCodesTxt(tempArry);
    }
  };

  const onSelectHandler = (code: string) =>
    setSelectedProcedureCodesTxt([...selectedProcedureCodesTxt, code]);

  return (
    <IonRow>
      <IonCol>
        <CustomCard isStep={true} title="Procedures" step={4} isValid={true}>
          <div className="children-wrapper">
            <div className="content-wrapper">
              <p className="card-sub">Code list for procedures & diagnosis</p>
            </div>
            <div className="select-box-container">
              <SelectBox
                options={codeTemplatesSelectDataFormat(codeTemplates)}
                selected={selectTemplate}
                setSelected={(code) => setSelectTemplate(code)}
              />
            </div>
            <div className="content-wrapper">
              <p className="card-sub">Add procedure for this visit</p>
              <p className="more-btn">More</p>
            </div>
            <div className="procedures-container">
              {proceduresCode.map((procedureCode, index) => (
                <CustomButton
                  key={`${procedureCode.code}${index}`}
                  text={procedureCode.code}
                  defultSelected={selectedProcedureCodesTxt.includes(
                    procedureCode.code
                  )}
                  onSelect={onSelectHandler}
                  deSelect={deSelectHandler}
                  isDirectSelect={true}
                />
              ))}
            </div>
            <div className="list-items">
              {selectedProcedureCodes.map((code, index) => (
                <div className="list-item" key={`${code._id}${index}`}>
                  <p>
                    {code.code} - {code.description}
                  </p>
                  <IonIcon
                    className="close-icon"
                    icon={closeOutline}
                    onClick={() => deSelectHandler(code.code)}
                  ></IonIcon>
                </div>
              ))}
            </div>
          </div>
        </CustomCard>
      </IonCol>
    </IonRow>
  );
};

export default ProceduresBlock;
