/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonText,
  useIonRouter,
  IonToast,
  IonBackButton,
} from "@ionic/react";

import { usePhotoGallery } from "../../hooks/usePhotoGallery";
import { defineCustomElements } from "@ionic/pwa-elements/loader";

import { cameraOutline, micSharp } from "ionicons/icons";
import "./AddNewEncounter.scss";
import DateOfServiceCard from "./DateOfServiceCard/DateOfServiceCard";
import DiagnosisCard from "./DiagnosisCard/DiagnosisCard";
import ProcedureCard from "./ProcedureCard/ProcedureCard";
import AdditionalInfoCard from "./AdditionalCard/AdditionalInfo";
import Button from "../../components/Common/Buttons/Buttons";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setDateOfService,
  setDiagnosisCode,
  setProcedureCode,
  selectPatientDetail,
  handleRegroupCode,
  resetThisState,
} from "../../store/reducers/newEncounter";
import { postVisit } from "../../helper/backendHelper";
import moment from "moment";
import { getVisitsCensus } from "../../store/thunk/censusThunk";
import EncounterCard from "./EncounterCard/EncounterCard";
import axios from "axios";
import {
  COLLECTOR_URL,
  setupInterceptorsTo,
} from "../../helper/apiHelper/apiHelper";
import { selectUser } from "../../store/reducers/user";
const collectorApi = setupInterceptorsTo(
  axios.create({
    baseURL: COLLECTOR_URL,
  })
);
interface uploadFileType {
  fileType?: string;
  fileName?: string;
  fileSize?: string;
  status?: string;
  originalFileInfo?: any;
}

const AddNewEncounter: React.FC = () => {
  const [files, setFiles] = useState<any>([]);
  const [showToast, setShowToast] = useState(false);
  const [toastmessage, setToastMessage] = useState("");
  const currentFacility = useAppSelector(
    (state) => state.census.censusFacility
  );
  const dispatch = useAppDispatch();
  const { photos, takePhoto } = usePhotoGallery();
  const [binaryData, setBinaryData] = useState<any>();
  const [isPictureShow, setIsPictureShow] = useState<boolean>(false);
  const userData = useAppSelector(selectUser);

  const router = useIonRouter();

  const thisEncounterState = useAppSelector((state) => state.newEncounter);
  // const facility = useAppSelector((state) => state.facility);
  const patientDetail = useAppSelector(selectPatientDetail);
  const censusFacility = useAppSelector((state) => state.census.censusFacility);
  // console.log({ thisEncounterState });
  const facilitiesList = useAppSelector((state) => state.census.facilitiesList);

  const [icons, setIcons] = useState([
    {
      name: (
        <div className="custom_icon">
          <span className="text">T</span>
        </div>
      ),
      isIcon: false,
      selected: true,
    },
    {
      name: cameraOutline,
      isIcon: true,
      selected: false,
    },
    {
      name: micSharp,
      isIcon: true,
      selected: false,
    },
  ]);
  useEffect(() => {
    // console.log(photos,'photos photos')
    // if (photos.length > 0) {
    //   let formData = new FormData();
    //   formData.append("file", photos[photos.length - 1].filepath);
    //   formData.append("fileName", photos[photos.length - 1].filepath);
    //   alert(11111111)
    //   collectorApi.post("/dailycensus/image", formData);
    // }
  }, [photos]);
  const capturePhoto = () => {
    defineCustomElements(window);
    takePhoto();
  };
  const onSelect = (index: any) => {
    for (let i = 0; i < icons.length; i++) {
      if (i === index) {
        icons[i] = { ...icons[i], selected: true };

        if (index === 1) {
          capturePhoto();
        }
      } else {
        icons[i] = { ...icons[i], selected: false };
      }
    }
    setIcons([...icons]);
  };

  const onDateSelect = (date: any) => {
    dispatch(setDateOfService(moment(date).format("YYYY-MM-DD")));
  };

  const onProcedureCodeSet = (code: any) => {
    dispatch(setProcedureCode(code));
  };
  const onDiagnosisCodeSet = (code: any) => {
    dispatch(setDiagnosisCode(code));
  };

  const handleCodeRegroup = (regroupData: any) => {
    dispatch(handleRegroupCode(regroupData));
  };

  const handleSaveDraft = async () => {
    let procedureCodes: any[] = [];
    thisEncounterState.procedureCodesSelected.forEach(
      (item) =>
        (procedureCodes = [
          ...procedureCodes,
          { code: item.code, modifiers: [] },
        ])
    );
    let fac_id = await (censusFacility._id ||
      thisEncounterState?.patientDetail?.facility_id);
    let indexx =
      (await thisEncounterState?.patientDetail) &&
      thisEncounterState?.patientDetail?.patient_admit_info != undefined &&
      thisEncounterState?.patientDetail?.patient_admit_info &&
      thisEncounterState?.patientDetail?.patient_admit_info != null
        ? thisEncounterState?.patientDetail?.patient_admit_info.findIndex(
            (x: any) => x.facility_id === fac_id
          )
        : -1;
    const data = {
      files: files,
      facility_id:
        censusFacility._id || thisEncounterState?.patientDetail?.facility_id,
      service_date: thisEncounterState.dateOfService,
      appointment_time: "",
      appointment_duration: "",
      status: moment().isAfter(moment(thisEncounterState.dateOfService))
        ? "Checked In"
        : "Scheduled",
      patient_id: thisEncounterState.patientDetail.patient_id,
      patient: null,
      diagnosis_codes: thisEncounterState.diagnosisCodesSelected.map(
        (item) => item.code
      ),
      procedure_codes: procedureCodes,

      code_assist: {
        diagnosis_notes: "",
        billing_notes: "",
        audio_notes: [],
        templates: [],
      },
      telehealth: false,
      facility_mrn:
        indexx >= 0 &&
        thisEncounterState?.patientDetail?.patient_admit_info?.length > 0
          ? thisEncounterState?.patientDetail?.patient_admit_info[indexx]
              ?.facility_mrn
          : thisEncounterState?.patientDetail?.facility_mrn,
      admission_date:
        indexx >= 0 &&
        thisEncounterState?.patientDetail?.patient_admit_info?.length > 0
          ? thisEncounterState?.patientDetail?.patient_admit_info[indexx]
              ?.admission_date
          : thisEncounterState?.patientDetail?.admission_date,
      room:
        indexx >= 0 &&
        thisEncounterState?.patientDetail?.patient_admit_info?.length > 0
          ? thisEncounterState?.patientDetail?.patient_admit_info[indexx]?.room
          : thisEncounterState?.patientDetail?.room,
      references: thisEncounterState.patientDetail.references,
      place_of_service:
        thisEncounterState.patientDetail?.facility?.place_of_service ||
        thisEncounterState.patientDetail?.place_of_service,
    };

    await postVisit(data)
      .then((res) => {
        if (res.status === "success") {
          if (
            moment(new Date()).isSame(thisEncounterState.dateOfService, "date")
          )
            dispatch(
              setDiagnosisCode(thisEncounterState.diagnosisCodesSelected)
            );
          dispatch(setProcedureCode([]));
          dispatch(setDateOfService(""));

          dispatch(
            getVisitsCensus({
              servicedate: moment(new Date()).format("YYYY-MM-DD"),
              // thisProvider: userData.provider,
              facilityId: currentFacility?._id,
            })
          );
          router.push("/census", "back");
        }
        if (res?.isAxiosError) {
          setToastMessage(res.response.data.error);
          setShowToast(true);
        }
      })
      .catch((response) => {
        setToastMessage(response.response.data.message);
        setShowToast(true);
      });
  };

  const handleBillVisit = async () => {
    if (isValidate) {
      let procedureCodes: any[] = [];
      thisEncounterState.procedureCodesSelected.forEach(
        (item) =>
          (procedureCodes = [
            ...procedureCodes,
            { code: item.code, modifiers: [] },
          ])
      );
      let fac_id = await (censusFacility._id ||
        thisEncounterState?.patientDetail?.facility_id);
      let indexx =
        (await thisEncounterState?.patientDetail) &&
        thisEncounterState?.patientDetail?.patient_admit_info != undefined &&
        thisEncounterState?.patientDetail?.patient_admit_info &&
        thisEncounterState?.patientDetail?.patient_admit_info != null
          ? thisEncounterState?.patientDetail?.patient_admit_info.findIndex(
              (x: any) => x.facility_id === fac_id
            )
          : -1;
      const data = {
        files: files,
        facility_id:
          censusFacility?._id || thisEncounterState?.patientDetail?.facility_id,
        service_date: thisEncounterState.dateOfService,
        appointment_time: "",
        appointment_duration: "",
        status: moment().isAfter(moment(thisEncounterState.dateOfService))
          ? "Bill submitted"
          : "Scheduled",
        patient_id: thisEncounterState.patientDetail?.patient_id,
        patient: null,
        diagnosis_codes: thisEncounterState.diagnosisCodesSelected.map(
          (item) => item.code
        ),
        procedure_codes: procedureCodes,

        code_assist: {
          diagnosis_notes: "",
          billing_notes: "",
          audio_notes: [],
          templates: [],
        },
        telehealth: false,
        facility_mrn:
          indexx >= 0 &&
          thisEncounterState?.patientDetail?.patient_admit_info?.length > 0
            ? thisEncounterState?.patientDetail?.patient_admit_info[indexx]
                ?.facility_mrn
            : thisEncounterState?.patientDetail?.facility_mrn,
        admission_date:
          indexx >= 0 &&
          thisEncounterState?.patientDetail?.patient_admit_info?.length > 0
            ? thisEncounterState?.patientDetail?.patient_admit_info[indexx]
                ?.admission_date
            : thisEncounterState?.patientDetail?.admission_date,
        room:
          indexx >= 0 &&
          thisEncounterState?.patientDetail?.patient_admit_info?.length > 0
            ? thisEncounterState?.patientDetail?.patient_admit_info[indexx]
                ?.room
            : thisEncounterState?.patientDetail?.room,
        references: thisEncounterState.patientDetail.references,
        place_of_service:
          thisEncounterState.patientDetail?.facility?.place_of_service ||
          thisEncounterState.patientDetail?.place_of_service,
      };

      await postVisit(data)
        .then((res) => {
          if (res.status === "success") {
            setToastMessage("Bill submited");
            setShowToast(true);
            dispatch(
              getVisitsCensus({
                servicedate: moment(new Date()).format("YYYY-MM-DD"),
                // thisProvider: userData.provider,
                facilityId: currentFacility?._id,
              })
            );
            dispatch(
              setDiagnosisCode(thisEncounterState.diagnosisCodesSelected)
            );
            dispatch(setProcedureCode([]));
            dispatch(setDateOfService(""));
            router.push("/census", "back");
          }

          if (res?.isAxiosError) {
            setToastMessage(res.response.data.error);
            setShowToast(true);
          }
        })
        .catch((response) => {
          setToastMessage(response.response.data.message);
          setShowToast(true);
        });

      // console.log({ postvisitRes });
    } else {
      setToastMessage("Please select missing information");
      setShowToast(true);
    }
  };

  const [isValidate, setIsValidate] = useState(false);

  useEffect(() => {
    if (
      thisEncounterState.dateOfService !== "" &&
      thisEncounterState.dateOfService !== null &&
      thisEncounterState.diagnosisCodesSelected.length > 0 &&
      thisEncounterState.procedureCodesSelected.length > 0
    ) {
      setIsValidate(true);
    } else {
      setIsValidate(false);
    }

    return () => {
      setIsValidate(false);
    };
  }, [thisEncounterState]);

  useEffect(() => {
    return () => {
      dispatch(resetThisState());
    };
  }, []);

  const backButtonHandler = () => {
    dispatch(setDiagnosisCode(thisEncounterState.diagnosisCodesSelected));
    dispatch(setProcedureCode([]));
    dispatch(setDateOfService(""));
    router.push("/censusEncounter", "forward");
  };

  const validateForm = () => {};

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar className="addnewencounter-toolbar-background">
          {/* <IonBackButton
              text={""}
              className="backbutton-color-addnewencounter"
            /> */}
          {/*  <IonButton
              onClick={() => {
                backButtonHandler();
              }}
            > */}
          <IonButtons slot="start">
            <IonBackButton text={""} className="setting-backbutton" />
          </IonButtons>

          <IonTitle className=".addnewencounter-toolbar-patientname-text">
            {patientDetail?.patient_name}
          </IonTitle>
          <div className="encounterheadericons">
            {icons.map((item: any, i) =>
              item.isIcon === true ? (
                <div
                  key={i}
                  className={`headerIcons ${
                    item.selected === true && "active"
                  }`}
                  onClick={(e) => onSelect(i)}
                >
                  <IonIcon icon={item.name} />
                </div>
              ) : (
                <div
                  key={i}
                  className={`headerIcons ${
                    item.selected === true && "active"
                  }`}
                  onClick={(e) => onSelect(i)}
                >
                  <IonText>{item.name}</IonText>
                </div>
              )
            )}
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="addNewEncounterContent">
        <p className="headerLabel">Add new encounter</p>
        <EncounterCard title="Date of service">
          <DateOfServiceCard
            onSelect={onDateSelect}
            dateValue={thisEncounterState.dateOfService}
          />
        </EncounterCard>

        <EncounterCard title="Diagnosis codes" isIcon>
          <DiagnosisCard
            selectedCode={thisEncounterState.diagnosisCodesSelected}
            onSetCode={onDiagnosisCodeSet}
            handleRegroup={handleCodeRegroup}
          />
        </EncounterCard>

        <EncounterCard title="Procedure codes" isIcon>
          <ProcedureCard
            selectedCode={thisEncounterState.procedureCodesSelected}
            onSetCode={onProcedureCodeSet}
            handleRegroup={handleCodeRegroup}
          />
        </EncounterCard>

        <EncounterCard title="Additional info">
          <AdditionalInfoCard files={files} setFiles={setFiles} />
        </EncounterCard>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastmessage}
          duration={1000}
        />
        <div className="billvisit-btn-text">
          <Button
            boldfont={true}
            fullWidth={true}
            onClick={handleBillVisit}
            disabled={!isValidate}
          >
            <IonText>Bill visit</IonText>
          </Button>
        </div>
        <Button
          color="tertiary"
          variant="normal"
          fullWidth={true}
          onClick={handleSaveDraft}
        >
          <IonText className="savedraft-btn-text">Save as draft</IonText>
        </Button>
      </IonContent>
    </IonPage>
  );
};

export default AddNewEncounter;
