import { IonSpinner } from "@ionic/react";
import React from "react";
import "./Loader.scss";
const Loader = (props: any) => {
  return (
    <div className="fullscreen-loader">
      <IonSpinner
        className={`center-loader ${props?.className}`}
        color={props?.color || "dark"}
      />
    </div>
  );
};

export default Loader;

//color - "dark" ｜ "light" ｜ "info" ｜ "warning" ｜ "primary" ｜ "secondary" ｜ "success"  | "danger"
