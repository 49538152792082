import React from "react";
import {
  IonModal,
  IonLabel,
  IonIcon,
  IonImg,
  IonFooter,
  IonButton,
  IonPage,
} from "@ionic/react";
import "../Census/AddendumPopup.scss";

import { close, download } from "ionicons/icons";

interface AddendumPopupPropsType {
  addendumOpen: boolean;
  closeModal: () => void;
}

const AddendumPopup: React.FC<AddendumPopupPropsType> = ({
  addendumOpen,
  closeModal,
}) => {
  const dummyImage = [
    {
      id: 1,
      name: "dummy image 1",
      path: require("./dummyimage.png"),
    },
    {
      id: 2,
      name: "dummy image 2",
      path: require("./dummyimage.png"),
    },
  ];

  return (
    <IonModal
      className="addendum-modal"
      isOpen={addendumOpen}
      onDidDismiss={closeModal}
      breakpoints={[0, 0.3, 0.4, 1]}
      initialBreakpoint={0.9}
      backdropBreakpoint={0.2}
    >
      <IonPage className="addendum-openmodal">
        <div className="header-holder">
          <div>
            <IonIcon
              icon={download}
              className="download-icon"
              mode="md"
            ></IonIcon>
          </div>
          <div className="title-holder">
            <IonLabel className="labelTitle">Progress Note</IonLabel>
          </div>
          <div className="btn-holder">
            <IonIcon
              icon={close}
              className="close-icon"
              onClick={closeModal}
            ></IonIcon>
          </div>
        </div>
        <div className="content-addendum">
          {dummyImage.map((displayimage, key) => (
            <div key={key}>
              <IonImg
                src={displayimage.path}
                alt="dummy-image"
                className="addendum-image"
              />
            </div>
          ))}
        </div>
        <IonFooter className="addendum-footer ion-no-border">
          <IonButton className="btn-addendum">Create Addendum</IonButton>
        </IonFooter>
      </IonPage>
    </IonModal>
  );
};

export default AddendumPopup;