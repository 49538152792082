import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonButtons,
  IonButton,
  IonFooter,
  IonRow,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonModal,
} from "@ionic/react";
import "../../../components/Common/ProcedureSearch/ProcedureSearch.scss";
import { useEffect, useState } from "react";
import "./DiagnosisCode.scss";
import DiagnosisCodeSearchCard from "./DiagnosisCodeSearchCard";
import { chevronBackOutline, addOutline } from "ionicons/icons";
import DiagnosisButton from "./DiagnosisButton/DiagnosisButton";
import {
  getFavDiagnosisSearch,
  favoriteDiagnosis,
  unFavoriteDiagnosis,
  getDiagnosisSortLimit,
} from "../../../helper/backendHelper";
import { debounce } from "../../../utils/debounce";
import SearchBox from "../../../components/Common/SearchBox/SearchBox";

interface DiagnosisSearchInterface {
  code: string;
  description: string;
  long_description: string;
  enabled: boolean;
  favourite: boolean;
  isSelected?: boolean;
}

interface DiagnosisCodeInterface {
  isOpen?: boolean;
  setIsOpen: (val: boolean) => void;
  onSelectCode?: (code: any) => void;
  selectedCodes?: any;
  numberPermited?: any;
  addDiagnosis?: any;
  selectedDiagnosis?: any;
  dxClick?: any;
}

const DiagnosisCode: React.FC<DiagnosisCodeInterface> = (props: any) => {
  const [diagnosisData, setDiagnosisData] = useState<
    DiagnosisSearchInterface[]
  >([]);
  // const [searchData, setSearchData] = useState<DiagnosisSearchInterface[]>([]);

  // const [isSearchEnabled, setIsSeach] = useState(false);
  // const [selectedData, setSelectedData] = useState<DiagnosisSearchInterface[]>(
  //   []
  // );
  const [loading, setLoading] = useState(false);
  // let finalData = isSearchEnabled ? searchData : diagnosisData;

  const [searchValue, setsearchValue] = useState("");
  const [diagnosisSelected, setdiagnosisSelected] = useState<any>([]);
  const [favoriteButton, setFavoriteButton] = useState(false);

  let favArray: any = [];

  // useEffect(() => {
  //   setSelectedData(props.selectedCodes);
  // }, [props.selectedCodes]);

  // useEffect(() => {
  //   setLoading(true);
  //   if (!favoriteButton) {
  //     const getDiagnosisList = async () => {
  //       let res = await getDiagnosisSearch("");
  //
  //       setDiagnosisData(res?.results);
  //     };
  //     getDiagnosisList();
  //   } else {
  //     const getFavDiagnosisList = async () => {
  //       let res = await getFavDiagnosisSearch("");
  //       let newArr: any = [];
  //       res?.results.forEach(
  //         (item: any) => (newArr = [...newArr, { ...item, favorite: true }])
  //       );
  //       setDiagnosisData(newArr);
  //     };
  //     getFavDiagnosisList();
  //   }
  //   setLoading(false);
  // }, [favoriteButton]);

  const diagnosisListHandler = async (codeSearch: string) => {
    try {
      let res = await getDiagnosisSortLimit({
        search: codeSearch,
        startIndex: 0,
        count: 20,
      });
      let newResult: any[] = [];
      res.results.forEach((item: any) => {
        // if (
        //   !props.selectedDiagnosis.map((e: any) => e.code).includes(item.code)
        // )
        newResult = [...newResult, item];
      });
      setDiagnosisData(newResult);
    } catch (error) {}
  };

  const diagnosisFavListHandler = async (codeSearch: string) => {
    let data = [];
    try {
      let res = await getFavDiagnosisSearch(codeSearch);
      data = res.results;
    } catch (error) {}

    let newArr: any = [];
    data.forEach((item: any) => {
      if (
        !props.selectedDiagnosis.map((e: any) => e.code).includes(item.code)
      ) {
        newArr = [...newArr, { ...item, favorite: true }];
      }
    });

    setDiagnosisData(newArr);
  };

  const favoriteHandler = (item: any) => {
    let newArray: DiagnosisSearchInterface[] = diagnosisData.map(
      (list: DiagnosisSearchInterface, idx) => {
        if (list.code === item.code) {
          diagnosisFavoriteHandler({ ...item, favorite: !item.favorite });
          return { ...item, favorite: !item.favorite };
        } else {
          return list;
        }
      }
    );
    setDiagnosisData(newArray);
    //setSelectedData(newArray.filter((list:any) => list?.isSelected === true));
  };

  const diagnosisFavoriteHandler = async (item: any) => {
    let res: any;

    favArray.push(item.code);

    if (item.favorite) {
      res = await favoriteDiagnosis(favArray);
    } else {
      res = await unFavoriteDiagnosis(item.code);
    }
  };

  const cardSelectHandler = (item: any) => {
    let isSelected = diagnosisSelected.some(
      (code: any) => item.code === code.code
    );

    if (isSelected) {
      let temp = diagnosisSelected?.filter((code: any) => {
        let isSelected = item.code !== code.code;

        return isSelected;
      });
      setdiagnosisSelected(temp);
    } else {
      if (props.numberPermited > diagnosisSelected.length) {
        setdiagnosisSelected([...diagnosisSelected, item]);
      }
    }
  };

  const searchhandler = (e: string) => {
    setsearchValue(e);
  };

  useEffect(() => {
    console.log("any ...");
    setLoading(true);
    if (favoriteButton) diagnosisFavListHandler(searchValue);
    else diagnosisListHandler(searchValue);
    setLoading(false);
  }, [searchValue, favoriteButton, props.selectedDiagnosis, props.isOpen]);

  const closeModal = () => {
    setsearchValue("");
    setFavoriteButton(false);
    props.setIsOpen(false);
  };

  const handleButtonSelect = (buttonName: string) => {
    switch (buttonName) {
      case "Favorites":
        setFavoriteButton(true);
        break;
      default:
        break;
    }
  };

  const handleButtonDeselect = (buttonName: string) => {
    switch (buttonName) {
      case "Favorites":
        setFavoriteButton(false);
        break;
      default:
        break;
    }
  };

  const handleAddItem = () => {
    props.addDiagnosis(diagnosisSelected);
    setdiagnosisSelected([]);
  };

  const sendProcedureSearch = () => {};
  return (
    <>
      <IonModal
        className="add_diagnosis-newdesign"
        isOpen={props.isOpen}
        onDidDismiss={closeModal}
        initialBreakpoint={0.8}
      >
        <IonHeader className="add_diagnosis_header-newdesign">
          <IonToolbar className="add_diagnosis_toolbar-newdesign">
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  closeModal();
                }}
              >
                <IonIcon icon={chevronBackOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle className="add_diagnosis_title-newdesign">
              Diagnosis codes
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container-search-box-diagnosiscode">
          <div className="search-box-diagnosiscode-newdesign">
            <SearchBox
              placeholder="Search by code or description..."
              onChange={debounce(searchhandler, 1000)}
              value={searchValue}
            />
          </div>
          <div className="diagnosis-button-scroll-newdesign">
            <IonRow className="card-titles" class="ion-nowrap">
              <div>
                <IonCol>
                  <DiagnosisButton
                    text="Favorites"
                    isDirectSelect={true}
                    defultSelected={favoriteButton}
                    onSelect={handleButtonSelect}
                    deSelect={handleButtonDeselect}
                  ></DiagnosisButton>
                </IonCol>
              </div>
              <div>
                <IonCol>
                  <DiagnosisButton text="Recent search"></DiagnosisButton>
                </IonCol>
              </div>
              <div>
                <IonCol>
                  <DiagnosisButton text="Initial"></DiagnosisButton>
                </IonCol>
              </div>
              <div>
                <IonCol>
                  <DiagnosisButton text="Subsequent"></DiagnosisButton>
                </IonCol>
              </div>
            </IonRow>
          </div>
        </div>
        <IonContent className="add-diagnosis-content">
          <IonGrid className="ion-content-diagnosis-newdesign">
            <div>
              {diagnosisData?.length > 0 ? (
                <p className="selectcodetext">Tap to select codes</p>
              ) : (
                diagnosisData?.length === 0 && (
                  <p className="selectcodetext user-message">No result found</p>
                )
              )}
            </div>

            {diagnosisData?.length > 0 &&
              diagnosisData.map(
                (item: DiagnosisSearchInterface, index: number) => {
                  let isSelected = diagnosisSelected?.some(
                    (code: any) => item.code === code.code
                  );
                  return (
                    <IonRow key={`${item.code}+${index}`}>
                      <DiagnosisCodeSearchCard
                        favoriteHandler={favoriteHandler}
                        cardSelectHandler={cardSelectHandler}
                        item={{ ...item, isSelected }}
                        diagnosisSelected={diagnosisSelected}
                        selectedDiagnosis={props.selectedDiagnosis}
                        // favoriteButton={favoriteButton}
                        dxClick={props.dxClick}
                      />
                    </IonRow>
                  );
                }
              )}
          </IonGrid>
        </IonContent>

        <IonFooter className="add_diag_footer">
          <IonButton
            className="add_diag_footer_button"
            // color="primary"
            onClick={handleAddItem}
          >
            <IonIcon icon={addOutline} />
            <p>Add diagnosis</p>
            <div className="add_diag_footer_num">
              {diagnosisSelected.length}
            </div>
          </IonButton>
        </IonFooter>
      </IonModal>
    </>
  );
};

export default DiagnosisCode;
