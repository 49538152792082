import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonIcon,
  useIonLoading,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonText,
  IonReorderGroup,
  IonReorder,
  IonModal,
} from "@ionic/react";
import {
  chevronBackOutline,
  reorderTwo,
  star,
  starOutline,
} from "ionicons/icons";
import "./DiagnosisCodeFullList.scss";
import { useAppSelector } from "../../../store/hooks";
// import Toast, { newToast } from "../../../components/Common/Toaster/Toast";

// type ToastedList= {
//   id: number;
//   description: string,
// };
const DiagnosisllList: React.FC<any> = ({
  isOpen,
  setIsOpen,
  selectedDiagnosis,
  doReorder,
}) => {
  const [present, dismiss] = useIonLoading();
  // const [toastedMessage,setToastedMessage] = useState<ToastedList[]>([]);
  const darkThemeBoolean = useAppSelector((state) => state.layout.darkTheme);

  useEffect(() => {
    if (isOpen) {
      present({
        message: "Loading...",
        duration: 500,
      });
      dismiss();
      // let toastMsg =  newToast("Loading...","Info");
      //     setToastedMessage([...toastedMessage, toastMsg]);
    }
  }, [isOpen]);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
    {/* <Toast toastList={toastedMessage} position="bottom-left" autoDelete={true} autoDeleteTime={3000}/> */}
      <IonModal
        className="fulllist_diagnosis"
        isOpen={isOpen}
        onDidDismiss={closeModal}
        initialBreakpoint={0.8}
      >
        <IonHeader translucent mode="ios" className="fulllist_diagnosis_header">
          <IonToolbar className="fulllist_diagnosis_toolbar">
            <IonTitle className="fulllist_diagnosis_title">
              Selected diagnosis codes
            </IonTitle>
            <IonButtons>
              <IonButton
                onClick={(e) => {
                  closeModal();
                }}
              >
                <IonIcon icon={chevronBackOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className="fulllist_diagnosis_content">
          <div className="header_text_wrapper">
            <IonText className="content_header_text">Diagnosis codes</IonText>
          </div>
          <IonReorderGroup
            disabled={false}
            onIonItemReorder={doReorder}
            className="content_list"
          >
            {selectedDiagnosis.map((item: any, index: any) => (
              <div key={item.code} className="content_list_item">
                <div
                  className={
                    item?.favorite
                      ? "content_list_itemIcon favourite"
                      : "content_list_itemIcon"
                  }
                >
                  <IonIcon
                    icon={
                      item?.favorite === true
                        ? star
                        : !darkThemeBoolean
                        ? starOutline
                        : star
                    }
                  />
                </div>
                <div className="content_list_description">
                  <IonText className="content_list_itemText">
                    {item?.code}
                  </IonText>
                  <IonText className="content_list_itemPara">
                    {item?.description}
                  </IonText>
                </div>
                <div className="diagnosisReorderIcon">
                  <IonReorder
                    children={<IonIcon icon={reorderTwo} size="large" />}
                  />
                </div>
              </div>
            ))}
          </IonReorderGroup>
        </IonContent>
      </IonModal>
    </>
  );
};

export default DiagnosisllList;
