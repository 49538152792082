import React from "react";
import {
  IonCol,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import ProviderHold from "./ProviderHold";
import {  chevronBack } from "ionicons/icons";
import "./ProviderHold.scss";

const ProviderTab: React.FC = () => {
  const router = useIonRouter();

  return (
    <IonPage className="provider-hold-page">
      <IonHeader className="no-shadow no-border providerHoldHeader">
        <IonToolbar className="d-pad-05 providerToolbar" mode="ios">
          <IonIcon
            onClick={() => router.goBack()}
            className="left-icon"
            icon={chevronBack}
          />
          <IonTitle className="title-header-dashboard">Issues</IonTitle>
          {/* <div className="provider-notification" slot="end">
            <IonButtons className="provider-item" slot="end">
              <IonIcon className="provider-icon" icon={notifications}></IonIcon>
              <span className="provider-text">14</span>
            </IonButtons>
           </div> */}

          {/* <IonIcon
            className="provider-ellips"
            icon={ellipsisVertical}
            slot="end"
          ></IonIcon> */}
        </IonToolbar>
        <div>
          <IonRow>
            <IonCol className="title-header">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All
              time
            </IonCol>
            <IonCol className="title-header">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|
            </IonCol>
            <IonCol className="title-header">All facilities</IonCol>
          </IonRow>
        </div>
      </IonHeader>
      <ProviderHold />
    </IonPage>
  );
};

export default ProviderTab;
