import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  getChartText,
  getDocThumbnails,
  getDocuments,
  getProgressNoteImage,
} from "../../helper/backendHelper";
import "./Documents.scss";

const ImageComp: React.FC<{ images: any }> = ({ images }) => {
  const [thumbnails, setThumbnails] = useState<any>();

  useEffect(() => {
    getDocThumbnailsList(images);
  }, [images]);

  const getDocThumbnailsList = async (element: any) => {
    let res = await getDocThumbnails(element);
    if (res.status === "success") {
      setThumbnails(res.data);
    }
  };

  return (
    <IonCol size="4">
      <img src={thumbnails}></img>
    </IonCol>
  );
};

const Documents = () => {
  let quary: any = useParams<{ id?: string }>();
  const router = useIonRouter();
  const [files, setFiles] = useState<any[]>([]);
  const [progressNotes, setProgressNotes] = useState<any[]>([]);
  const [chartText, setChartText] = useState<string>("");

  useEffect(() => {
    const getDocumentsList = async () => {
      let res = await getDocuments(quary?.id);
      if (res.status === "success") {
        const documents: any = await res.results.map(
          (item: any) => item.file_name
        );
        setFiles(documents);
      }
    };

    const getProgressNoteList = async () => {
      let res = await getProgressNoteImage(quary?.id);
      if (res.status === "success") {
        setProgressNotes([...progressNotes, res.data]);
      }
    };

    const getChartTextList = async () => {
      let res = await getChartText(quary?.id);
      if (res.status === "success") {
        setChartText(res.data?.note_txt);
      }
    };
    getDocumentsList();
    getProgressNoteList();
    getChartTextList();
  }, [quary.id]);

  return (
    <IonPage className="patient-documents">
      <IonHeader className="documents-header">
        <IonToolbar className="ion-toolbar-dashboard" mode="ios">
          <IonIcon
            onClick={() => router.goBack()}
            className="left-icon"
            icon={chevronBack}
          />
          <IonTitle className="title-header-dashboard">
            Uploaded documents
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="documents-content">
        <p>Documents</p>
        <IonGrid>
          <IonRow>
            {files?.length > 0 ? (
              <>
                {files.map((image, index) => (
                  <ImageComp images={image} key={index} />
                ))}
              </>
            ) : (
              <div className="no-doc-text">
                <p>-No data found-</p>
              </div>
            )}
          </IonRow>
        </IonGrid>
        <p>Progress notes (Image)</p>
        <IonGrid>
          <IonRow>
            {progressNotes?.length > 0 ? (
              <>
                {progressNotes.map((item: any, index: any) => (
                  <IonCol size="4" key={index}>
                    <img src={item}></img>
                  </IonCol>
                ))}
              </>
            ) : (
              <div className="no-doc-text">
                <p>-No data found-</p>
              </div>
            )}
          </IonRow>
        </IonGrid>
        <p>Progress notes (Text)</p>
        <IonGrid>
          <IonRow>
            {chartText ? (
              <div>
                <p>{chartText}</p>
              </div>
            ) : (
              <div className="no-doc-text">
                <p>-No data found-</p>
              </div>
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Documents;
