import React from "react";
import "./CardDisplay.scss";
import { useAppSelector } from "../../../store/hooks";
import { selectTheme } from "../../../store/reducers/layout";
import ScopeWiseCard from "./ScopeWiseCard";

interface CardDisplayProps {
  items?: any[];
}

const CardDisplay: React.FC<CardDisplayProps> = (props: any) => {
  const isDarkTheme = useAppSelector(selectTheme);
  /*const [filteredyear,setfilteredYear] = useState('2020');
  const calenderFilterHandler = (selectedYear:any) => {
    setfilteredYear(selectedYear);
  }

  const filteredData = props.items.filter((data:any)=>{
    return data.date.getFullYear().toString() === filteredyear;
  });*/

  const slideOpts = {
    speed: 400,
    slidesPerView: 1.5,
    loop: true,
    centeredSlides: true,
  };

  return (
    <div className="card-display-scroll">
      {props.items.map((BData: any, i: any) => (
        <div key={i} className={`bars-card-holder`}>
          <ScopeWiseCard items={BData}></ScopeWiseCard>
        </div>
      ))}
    </div>
  );
};

export default CardDisplay;
