import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCodeTemplates,
  getFavDiagnosis,
  getFavProcedures,
} from "../../helper/backendHelper";

export const initialConfig = createAsyncThunk(
  //action type string
  "addVisit/initialConfig",
  // callback function
  async (_, thunkAPI) => {
    const codeTemplatesRes = await getCodeTemplates();
    const favProceduresRes = await getFavProcedures();
    const favDiagnosisRes = await getFavDiagnosis();

    return {
      codeTemplates: codeTemplatesRes?.results,
      favProcedures: favProceduresRes?.results,
      favDiagnosis: favDiagnosisRes?.results,
    };
  }
);

export const changeCodeTemplates = createAsyncThunk(
  //action type string
  "addVisit/changeCodeTemplates",
  // callback function
  async (code: string, thunkAPI) => {
    const favProceduresRes = await getFavProcedures(
      code !== "Personal favorites" ? { template: code } : undefined
    );
    const favDiagnosisRes = await getFavDiagnosis(
      code !== "Personal favorites" ? { template: code } : undefined
    );

    return {
      selectedCodeTemplates: code,
      favProcedures: favProceduresRes?.results,
      favDiagnosis: favDiagnosisRes?.results,
    };
  }
);

export const setCodeTemplates = createAsyncThunk(
  //action type string
  "addVisit/setCodeTemplates",
  // callback function
  async (_, thunkAPI) => {
    const res = await getCodeTemplates();
    if (res?.status === 500) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      if (res?.status === "success") {
        return res?.results;
      }
    }
  }
);
