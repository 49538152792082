import { createSlice } from "@reduxjs/toolkit";
import { FacilityType } from "../../types/Types";
import { RootState } from "../store";
import { getFacility } from "../thunk/facilityThunk";
type UserState = {
  isloading: Boolean;
  facilities: FacilityType[];
  errorMsg: any;
};

const initialState: UserState = {
  isloading: true,
  facilities: [],
  errorMsg: "",
};

export const facilitySlice = createSlice({
  name: "facility",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFacility.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(getFacility.fulfilled, (state, action) => {
        state.isloading = false;
        state.facilities = action.payload?.facilityRes?.results;
      })
      .addCase(getFacility.rejected, (state, action) => {
        state.isloading = false;
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
            state.errorMsg = action.payload;
        } else {
            state.errorMsg = action.error.message?.toString();
        }
      });
  },
});

export const selectFacility = (state: RootState) => state.facility;
export const facilityReducer = facilitySlice.reducer;
