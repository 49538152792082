import moment from "moment";
import {
  callConfirmVisit,
  getCPTListFav,
  getProcedureList,
  submitcpt,
  visitStatusCensus,
} from "../helper/backendHelper";

import { DeviceFeedback } from "@ionic-native/device-feedback/ngx";
import { TapticEngine } from "@ionic-native/taptic-engine/ngx";
import { isPlatform } from "@ionic/react";

export let scheduled: number;
export let billed: number = 0;
export const selectedDate: any = moment().format("YYYY-MM-DD");
let cRadius: number = 50;
let angle: number = -((2 * Math.PI) / 4);
let ccx: number = cRadius * Math.cos(angle);
let ccy: number = cRadius * Math.sin(angle);
let lastSPos: any;
let progressLine: String = "304.777px";
let selectedVisitHightlight = null;
export let visitsObject: any;
let StatusIconService: any;
let facility: any;

let deviceFeedback: DeviceFeedback;
// let plt: typeof isPlatform;
let taptic: TapticEngine;

export const visitClone = (
  charge_id: string,
  status: string,
  service_date: string,
  last_visit?: boolean,
  description?: string,
  procedures?: any,
  diagnosis_codes?: any,
  facility_id?: any,
  diagnosis_notes?: any
): any => {
  let body = {
    status: status,
    service_date: service_date,
    last_visit: last_visit,
    description: description,
    procedures: procedures,
    diagnosis_codes: diagnosis_codes,
    facility_id: facility_id,
    diagnosis_notes: diagnosis_notes,
  };
  // return callConfirmVisit(charge_id, body);
  // let url = globals.dailyCensusView_URL + '/visits/' + charge_id + '/clone';
  // return this.http.post(url, body);
  return callConfirmVisit(charge_id, body);
  /*.then((result) => {
    if (result.status === "success") {
      // let visitEventsProvider=this.injector.get(VisitEventsProvider);
      //delete visitsObject[charge_id];
      visitsObject[result.result._id] = result.result;
    }
  }); */
};

export const statusIcon = (visit: any) => {
  visit["iconObject"] = {};
  visit.iconObject.iconcolor = "";
  visit.iconObject.circle = false;
  switch (visit.status) {
    case "Scheduled":
      visit.iconObject.icon = visit.statuses_index.new_visit
        ? "fas fa-star"
        : "";
      visit.iconObject.iconcolor = "fa_danger";
      visit.iconObject.circle = true;
      break;

    case "Checked In":
      visit.iconObject.icon = visit.statuses_index.new_visit
        ? "fas fa-star"
        : "";
      visit.iconObject.iconcolor = "fa_danger";
      visit.iconObject.circle = true;
      break;

    case "Superbill":
      visit.iconObject.icon = visit.statuses_index.new_visit
        ? "fas fa-star"
        : "fal fa-check";
      visit.iconObject.iconcolor =
        visit.rendering_provider_id == visit.providerId
          ? "fa_success"
          : "fa_success-alt";
      visit.iconObject.circle = true;
      break;

    case "Not seen":
      visit.iconObject.icon = "fal fa-redo";
      visit.iconObject.iconcolor = "fa_light";
      visit.iconObject.circle = false;
      break;

    case "Last":
      visit.iconObject.icon = "fa-circle";
      visit.iconObject.iconcolor = "fa_danger";
      visit.iconObject.circle = true;
      break;

    case "Completed":
      visit.iconObject.icon = visit.statuses_index.new_visit
        ? "fas fa-star"
        : "fal fa-check";
      visit.iconObject.iconcolor =
        visit.rendering_provider_id == visit.providerId
          ? "fa_success"
          : "fa_success-alt";
      visit.iconObject.circle = true;
      break;

    case "Pending":
      visit.iconObject.icon = "fa-check-circle";
      visit.iconObject.iconcolor = "fa_danger";
      visit.iconObject.circle = true;
      break;

    case "Provider Hold":
      visit.iconObject.icon = "fa-circle";
      visit.iconObject.iconcolor = "fa_danger";
      visit.iconObject.circle = true;
      break;

    default:
      visit.iconObject.icon = "";
      visit.iconObject.iconcolor = "";
      visit.iconObject.circle = false;
  }
};

export const groupVisits = (visits: any, curr_visit: any) => {
  visits = visits;

  let groupedVisits = [];
  let currentFacility = "";
  let currentVisits = [];
  let currentGroup: any;

  //in case if are psublishing in dailycensus page
  //  this.latestvisit = this.navParams.data.recentid;
  let groupedfacility: any = [];
  let groupedvisits_obj: any = {};
  visits.map((visit: any, index: any) => {
    if (curr_visit && curr_visit._id && curr_visit._id == visit._id) {
      visit = curr_visit;
    }

    currentFacility = visit.facility_short_name
      ? visit.facility_short_name + " - " + visit.facility_name
      : visit.facility_name;
    if (groupedfacility.indexOf(currentFacility) == -1) {
      groupedfacility.push(currentFacility);
      // if (((visit.facility_short_name) ? visit.facility_short_name + ' - ' + visit.facility_name : visit.facility_name) != currentFacility) {
      //   currentFacility = ((visit.facility_short_name) ? visit.facility_short_name + ' - ' + visit.facility_name : visit.facility_name); //visit.facility_name;
      let newGroup = {
        facility_name: visit.facility_short_name
          ? visit.facility_short_name + " - " + visit.facility_name
          : visit.facility_name,
        visits: [],
        scheduled: 0,
        new: 0,
        seen: 0,
        billed: 0,
      };
      currentGroup = newGroup;
      currentVisits = newGroup.visits;
      groupedvisits_obj[currentFacility] = newGroup;
      groupedVisits.push(newGroup);
    }

    //  if(visit._id == this.latestvisit){
    //   this.visitHighlight(visit);
    //  }
    if (visit.status != "Not seen" && visit.status != "Cancelled") {
      currentGroup.scheduled += 1;
    }
    if (visit.new) currentGroup.new += 1;
    if (visit.seen) currentGroup.seen += 1;
    if (visit.billed) currentGroup.billed += 1;
    groupedvisits_obj[currentFacility].visits.push(visit);
    //currentVisits.push(visit);
  });
  groupedVisits = Object.values(groupedvisits_obj); //.push(newGroup);
  let novisits: any = visits.length == 0;
  progressMethod(); //(this.scheduled, this.billed);
  // this.content.resize();
};

export const progressMethod = () => {
  //(scheduled, billed) {
  let ratio = scheduled == 0 ? 0 : billed / scheduled;
  let angle = ratio * (2 * Math.PI);
  progressLine = 304.777 * (1 - ratio) + "px";
  ccy = cRadius * Math.sin(angle + angle);
  ccx = cRadius * Math.cos(angle + angle);
};

export function showToast(message: string) {}

export const hapticFeedBack = () => {
  heptic();
  deviceFeedback?.isFeedbackEnabled().then((feedback) => {});
};

export const heptic = () => {
  //deviceFeedback.acoustic();
  if (isPlatform("android")) {
    deviceFeedback.haptic(0);
  } else if (isPlatform("ios")) {
    taptic?.notification({
      type: "success",
    });
  }
};

export const visitHighlight = (visit: any) => {
  selectedVisitHightlight = visit;
  //selectedVisitHightlight = visit._id;
};

export const updateVisit = (visit: any) => {
  if (visitsObject) visitsObject[visit._id] = visit;
};

export const visitUpdateEvent = (visit: any) => {
  updateVisit(visit);
};

export const visitStatus = (
  charge_id: string,
  status: string,
  last_visit?: boolean,
  description?: string
): any => {
  let body = {
    status: status,
    last_visit: last_visit,
    description: description,
  };
  //let url = globals.dailyCensusView_URL + '/visits/' + charge_id + '/visitstatus';
  return visitStatusCensus(charge_id, body);
};

export const visitProcedureMenu = (
  visit: any,
  key: any,
  obj: any,
  type: any
) => {
  if (type == "Change CPT") {
    getCPTListFav("", visit.place_of_service)
      .then((result) => {
        // this.setState({
        //     selectedVisit: visit,
        // });
        // this.setState({
        //     favProcedureList: result,
        // });
        // this.setState({
        //     isCPTChangeClicked: !this.state.isCPTChangeClicked,
        // });
      })
      .catch((err: any) => {});
  }
};

const getVisits = () => {
  if (visitsObject != undefined && visitsObject != null) {
    let visits: any = Object.values(visitsObject);
    return visits;
  } else {
    return [undefined];
  }
};

export const processVisits = () => {
  let visits = getVisits();
  if (visitsObject != undefined) {
    let statuses = StatusIconService.visitStatuses;
    let facility_id = facility._id;
    let filteredVisits = visits.filter(
      (visit: any) => visit.facility_id == facility_id
    );
    // filter inpatient and encounters
    let inpatients = [];
    let encounters = [];

    for (let tmp of filteredVisits) {
      if (tmp.activities.length > 0) {
        tmp.last_activity =
          tmp.activities[tmp.activities.length - 1].activity_date;
      } else {
        tmp.last_activity = "";
      }
      if (
        tmp.diagnosis_code_primary &&
        tmp.diagnosis_code_primary.description
      ) {
        if (tmp.diagnosis_code_primary.description.length > 20) {
          tmp.diagnosis_code_primary.description =
            tmp.diagnosis_code_primary.description.substring(0, 20) + " ...";
        }
      } else {
        if (tmp.diagnosis_codes && tmp.diagnosis_codes[0]) {
          tmp.diagnosis_code_primary = {
            code: tmp.diagnosis_codes[0],
            description: "",
          };
        }
      }
      if (tmp.rendering_provider_name) {
        let names = tmp.rendering_provider_name.split(" ");
        let firstname = names[1];
        let lastname = names[0];
        tmp.rendering_initial = lastname[0];
        if (firstname) {
          tmp.rendering_initial = tmp.rendering_initial + firstname[0];
        }
        tmp.rendering_initial = tmp.rendering_initial.toUpperCase();
      }

      if (statuses[tmp.status] && statuses[tmp.status].group == "Available") {
        inpatients.push(tmp);
      } else if (statuses[tmp.status].group != "Available") {
        encounters.push(tmp);
      }
    }
  }
};

export const visitDetail = (visit: any) => {
  setTimeout(() => {
    // if (itemSliding) {
    //   event.stopPropagation();
    //   itemSliding.close();
    // }
    // this.visitEventsProvider.visitHighlight(visit); // this.visitEventsProvider.visitHighlight(visit);
    // let queryParams = {
    //   visit: visit,
    //   charge_id: visit._id,
    //   group : group,
    //   groupInx: groupInx,
    //   codeTemplateList: this.codeTemplateList
    // };
    // this.navparams.setParams(queryParams);
    // this.navCtrl.navigateForward('visitdetails');
  }, 200);
};

export const quickBillMenu = (visit: any) => {
  getProcedureList(visit?._id).then((data: any) => {
    if (data.status == "success" && data.results.length > 0) {
      let codes = data.results;
      let filtered = data.results.filter((code: any) => {
        let exclude = ["99218", "99219", "99220", "99221", "99222", "99223"];
        if (exclude.indexOf(code.code) > -1) {
          return;
        } else {
          return code;
        }
      });
      // let buttonsToSet = [];
      // for (let code of filtered) {
      //   let button = {
      //     text: code.custom_code
      //       ? code.custom_code.custom_code +
      //         " - " +
      //         (code.custom_code.custom_description
      //           ? code.custom_code.custom_description
      //           : code.description)
      //       : code.code + " - " + code.description,
      //     handler: () => {
      //       quickBill(visit, code);
      //     },
      //   };
      //   buttonsToSet.push(button);
      // }
      // buttonsToSet.push({
      //   text: "Cancel",
      //   role: "cancel",
      // });
      // let actionCtrlParams = {
      //   header:
      //     "Change billing code - " +
      //     visit.patient_lastname +
      //     ", " +
      //     visit.patient_firstname,
      //   backdropDismiss: false,
      //   buttons: buttonsToSet,
      // };
      // setTimeout(() => {
      //   getQuickBillAction(actionCtrlParams);
      // }, 100);
    }
  });
};

export const quickBill = (visit: any, code: any) => {
  let codes = visit.procedureCodes.map((x: any) => {
    let res = {
      code: x.code,
      modifiers: x.modifiers ? x.modifiers : [],
    };
    return res;
  });
  codes.splice(0, 1, { code: code.code, modifiers: code.modifiers || [] });
  if (visit.status == "Last") {
    visitClone(visit._id, "Scheduled", selectedDate, undefined, codes).then(
      (data: any) => {
        if (data.status == "success") {
          visit.clone_id = visit._id;
          visit._id = data.result._id;
          visit.status = data.result.status;
          visit.statuses = data.result.statuses;
          visit.statuses_index = data.result.statuses_index;
          visit.activities = data.result.activities;
          visit.service_lines = data.result.service_lines;
          visit.service_date_from = data.result.service_date_from;
          visit.legal_entity_id = data.result.legal_entity_id;
          visit.procedure_codes = codes;
          visit.procedureCodes.splice(0, 1, code);
          if (codes.length > 0) {
            visit.procedure_code_primary = code;
          } else {
            visit.procedure_code_primary = undefined;
          }
          visit.rendering_provider_id = data.result.rendering_provider_id;
          visit.rendering_provider_name = data.result.rendering_provider_name;
          let provider = visit.rendering_provider_name
            ? visit.rendering_provider_name
            : "";
          let initials = provider
            .split(" ")
            .map(function (item: any) {
              return item[0];
            })
            .join("");
          visit.rendering_provider_initials = initials;
          // this.visitEventsProvider.seen += 1;
          // this.visitEventsProvider.statusIcon(visit);
          // this.groupCountUpdate(visit);
          // this.visitEventsProvider.visitHighlight(visit);
          // this.visitEventsProvider.visitUpdateEvent(visit); // emit visit-update event
          showToast("Primary procedure changed sucessfully");
        } else {
          showToast(
            "Oops... Failed to change primary procedure for this visit! Please try billing from the detail page."
          );
        }
      }
    );
  } else if (visit._id) {
    submitcpt(visit._id, codes).then((data: any) => {
      visit.procedure_codes = codes;
      visit.procedureCodes.splice(0, 1, code);
      if (codes.length > 0) {
        visit.procedure_code_primary = code;
      } else {
        visit.procedure_code_primary = undefined;
      }
      visit.service_lines = data.result.service_lines;
      // this.visitEventsProvider.visitHighlight(visit);
      // this.visitEventsProvider.visitUpdateEvent(visit); // emit visit-update event
      showToast("Primary procedure changed sucessfully");
    });
  }
};
