import React, { useState, useEffect } from "react";
import "./statusicon.scss";
import { useAppSelector } from "../../../store/hooks";
import { VisitsByServicedate } from "../../../types/VisitsByServicedate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import "../../../fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type StatusIconProps = {
  visit: VisitsByServicedate[];
};

const StatusIcon: React.FC<any> = (props: any) => {
  const censusData = useAppSelector((state) => state.census);
  const [visitIcon, setVisitIcon] = useState("");
  const [visitIconColor, setVisitIconColor] = useState("");
  const [visitCircle, setVisitCircle] = useState(false);

  let status = props.visit.status;

  props.visit["iconObject"] = {};
  props.visit.iconObject.iconcolor = "";
  props.visit.iconObject.circle = false;
  let config = censusData?.visitStatuses;

  useEffect(() => {
    let config = censusData?.visitStatuses;

    if (config[status]) {
      props.visit.iconObject.icon = config[status].icon.replace("fal", "fas");
      props.visit.iconObject.circle = config[status].circle;
      props.visit.iconObject.iconcolor = config[status].iconcolor;
      if (config[status].iconcolor_option) {
        props.visit.iconObject.iconcolor = config[status].iconcolor_option;
      } else {
        props.visit.iconObject.iconcolor = config[status].iconcolor;
      }
    }

    setVisitCircle(props.visit.iconObject.circle);
    setVisitIcon(props.visit.iconObject.icon);
    setVisitIconColor(props.visit.iconObject.iconcolor);
    props.visit.statusGroup = config[status].group;

    return props.visit;
  }, [props.visit, visitIconColor, visitIcon, visitCircle]);

  let iconvsr: any = "fas fa-circle";

  // let test = `${visitIcon}` as IconProp;

  // console.log({ test });

  return (
    <div className="fa-1x">
      {props.visit && visitCircle === true ? (
        <span className={`fa-layers fa-fw ${visitIconColor}`}>
          <FontAwesomeIcon icon={iconvsr} transform="grow-4" />
          <FontAwesomeIcon
            icon={`${visitIcon}` as IconProp}
            className={`${visitIconColor}`}
            inverse
            transform="shrink-6"
          />
        </span>
      ) : (
        <span className={`fa-layers fa-fw ${visitIconColor}`}>
          <FontAwesomeIcon
            icon={`${iconvsr}` as IconProp}
            transform="shrink-6 grow-4"
          />
        </span>
      )}
    </div>
  );
};

export default StatusIcon;
