import { useIonRouter } from "@ionic/react";
import React from "react";
import BottomCardsItem from "./BottomCardsItem";

interface BottomCardsListProps {
  items?: any;
}

const slideOpts = {
  speed: 400,
  slidesPerView: 1.8,
  loop: true,
  centeredSlides: true,
};

const BottomCardsList: React.FC<BottomCardsListProps> = (props: any) => {
  const router = useIonRouter();
  if (props.items.length === 0) {
    return <h2 className="facilities-list__fallback">Found No Expenses</h2>;
  }

  return (
    <div>
      <div className="facilities-card-scroll">
        {props.items.map((BData: any, i: any) => (
          <div key={i}>
            <a href="https://claimocity.com/">
              <BottomCardsItem
                key={BData.id}
                Bname={BData.Bname}
                Btext={BData.Btext}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottomCardsList;
