import {
  IonCol,
  IonGrid,
  IonRow,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import React, { useState } from "react";
import "./DashBoard.scss";
import PracticeOverview from "./PracticeOverview/PracticeOverview";
import "./PracticeOverview/PracticeOverview.scss";
import moment from "moment";
import { getPracticeOverview } from "../../store/thunk/DashboardThunk";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Loader from "../../components/Common/Loader/Loader";
import { useAppSelector } from "../../store/hooks";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from "@ionic/react";
import "./PracticeOverview/PracticeOverview.scss";
import Buttons from "../../components/Common/Buttons/Buttons";

interface PracticeOverviewType {
  roles: any[];
}

const PracticeOverviewInfo = (props: PracticeOverviewType) => {
  const dispatch = useDispatch();
  const [practiceOverviewFilter, setPracticeOverviewFilter] = useState("week");
  const { isloading } = useAppSelector((state) => state.dashboard);
  const filter = async (type: any) => {
    setPracticeOverviewFilter(type);

    let currentDate = moment().format("YYYY-MM-DD");
    dispatch(getPracticeOverview({ currentDate, type }));
  };
  useEffect(() => {
    filter("week");
  }, []);
  console.log(practiceOverviewFilter);
  return (
    <div className="practiceoverview-container">
      {isloading && <Loader />}

      <IonGrid>
        <div className="header-container">
          <div className="left-section">
            <IonRow>
              <IonCol>
                <IonText className="practiceoverview-heading">
                  Practice overview
                </IonText>
              </IonCol>
              <IonCol className="drop">
                <IonList className="practice-list">
                  <IonItem className="practice-item ">
                    <IonSelect
                      interface="popover"
                      placeholder=" In this Week"
                      onIonChange={(e) => filter(e.detail.value)}
                    >
                      <IonSelectOption value={"week"}>
                        In this Week
                      </IonSelectOption>
                      <IonSelectOption value={"month"}>
                        In this Month
                      </IonSelectOption>
                      <IonSelectOption value={"year"}>
                        In this Year
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonList>
              </IonCol>
            </IonRow>
          </div>
        </div>

        <IonRow>
          <IonCol size="12">
            <div className="charts-holder">
              <PracticeOverview roles={props.roles} />
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCard className="graph-card chart-card1">
            <IonCardHeader className="graphcard-title-container ">
              <IonCardTitle className=" chart-text-title mx-3 mt-3 ">
                Do you need help?
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="contactus-subHeader ">
              <div>Our technical support team is here to help.</div>
              <div className="">
                <Buttons> Contact us</Buttons>
              </div>
            </IonCardContent>
          </IonCard>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default PracticeOverviewInfo;
