import React, { useState, useEffect } from 'react';
// import checkIcon from './assets/check.svg';
// import errorIcon from './assets/error.svg';
// import infoIcon from './assets/info.svg';
// import warningIcon from './assets/warning.svg';
import PropTypes from 'prop-types';
import './Toast.scss';
import { contractOutline } from 'ionicons/icons';

const TypeToast = [
    { title: 'Success', backgroundColor: '#9AF398', className: 'successtoast' },
    { title: 'Error', backgroundColor: '#FF877A', className: 'errortoast' },
    { title: 'Danger', backgroundColor: '#FF877A', className: 'dangertoast' },
    { title: 'Info', backgroundColor: '#8DBDED', className: 'infotoast' },
    { title: 'Warning', backgroundColor: '#FFD442', className: 'warningtoast' }]

export const newToast = (message: any, typeToast: any) => {
    let re = TypeToast.find(t => t.title === typeToast);
    const id = Math.floor((Math.random() * 101) + 1);
    let design = { id: id, description: message };
    let leToast = { ...re, ...design };
    return leToast;

}
const Toast = (props: any) => {
    const { toastList, position, autoDelete, autoDeleteTime } = props;
    const [list, setList] = useState(toastList);
    useEffect(() => {
        setList([...toastList]);
    }, [toastList]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (autoDelete && toastList.length && list.length) {
                deleteToast(toastList[0].id);
            }
        }, autoDeleteTime);

        return () => {
            clearInterval(interval);
        }

    }, [toastList, autoDelete, autoDeleteTime, list]);

    const deleteToast = (id: any) => {
        const listItemIndex = list.findIndex((e: any) => e.id === id);
        const toastListItem = toastList.findIndex((e: any) => e.id === id);
        list.splice(listItemIndex, 1);
        toastList.splice(toastListItem, 1);
        setList([...list]);
    }

    return (
        <>
            <div className={`notification-container ${position}`}>
                {
                    list.map((toast: any, i: any) =>
                        <div
                            key={i}
                            className={`notification toast ${position} ${toast.className}`}
                            style={{ backgroundColor: toast.backgroundColor }}
                        >
                            <button onClick={() => deleteToast(toast.id)}>
                                X
                            </button>
                            {/* <div className="notification-image">
                                <img src={toast.icon} alt="" />
                            </div> */}
                            <div>
                                <p className="notification-title">{toast.title}</p>
                                <p className="notification-message">
                                    {toast.description}
                                </p>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
}

Toast.propTypes = {
    toastList: PropTypes.array.isRequired,
    position: PropTypes.string,
    autoDelete: PropTypes.bool,
    autoDeleteTime: PropTypes.number
}

export default Toast;