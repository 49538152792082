import {
  IonAccordion,
  IonCard,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
} from "@ionic/react";
import { chevronDownOutline, informationCircleOutline } from "ionicons/icons";
import React from "react";
import CheckBox from "../../../components/Common/CheckBox/CheckBox";
import "./FamilyMedicalCard.scss";
const FamilyMedicalCard: React.FC = () => {
  let data = {
    arr: [
      {
        name: "Yes",
      },
      {
        name: "No",
      },
    ],
    buttonType: {
      type: "filled",
    },
    labelName: {
      name: "Gender",
    },
  };
  return (
    <IonCard className="accordian-card">
      <IonAccordion className="accordian">
        <IonItem className="title-container" lines="none" slot="header">
          <IonLabel className="card-title">Family Medical Hx</IonLabel>
        </IonItem>
        <IonList slot="content" className="card-content">
          <IonRow className="family-ion-row">
            <IonCol size="1">
              <IonLabel>
                <CheckBox
                  value="social"
                  checked={false}
                  strictMode
                  className="family-ion-checkbox"
                />
              </IonLabel>
            </IonCol>
            <IonCol className="family-ion-checkbox-content">
              <IonText>
                The patient’s family history was reviewed and does not
                contribute to the current problem/conditon
              </IonText>
            </IonCol>
          </IonRow>
          <IonCard className="family-card">
            <div className="family-accordian-card-header">
              <div className="family-accordian-card-title">
                <div className="family-left">
                  <div className="family-toggle">
                    <IonSegment
                      className="family-buttnSegment"
                      onIonChange={(e: any) => {
                        // setSelected(e.detail.value);
                      }}
                    >
                      {data.arr.map((item: any, i: any) => (
                        <IonSegmentButton
                          key={i}
                          value={item?.name}
                          className={`familytoggleBtn ${
                            i === 0 ? "lhs" : "rhs"
                          }`}
                          // className={
                          //   selected === item?.name
                          //     ? "togglebttn active"
                          //     : "togglebttn"
                          // }
                        >
                          <IonLabel>{item?.name}</IonLabel>
                        </IonSegmentButton>
                      ))}
                    </IonSegment>
                  </div>
                  <div className="family-accordian-card-header-text">
                    <IonText>HTN</IonText>
                  </div>
                </div>
                <div className="family-accordian-card-header-icon">
                  <IonIcon icon={chevronDownOutline} />
                </div>
              </div>
            </div>
          </IonCard>
          <IonCard className="family-card">
            <div className="family-accordian-card-header">
              <div className="family-accordian-card-title">
                <div className="family-left">
                  <div className="family-toggle">
                    <IonSegment
                      className="family-buttnSegment"
                      onIonChange={(e: any) => {
                        // setSelected(e.detail.value);
                      }}
                    >
                      {data.arr.map((item: any, i: any) => (
                        <IonSegmentButton
                          key={i}
                          value={item?.name}
                          className={`familytoggleBtn ${
                            i === 0 ? "lhs" : "rhs"
                          }`}
                          // className={
                          //   selected === item?.name
                          //     ? "togglebttn active"
                          //     : "togglebttn"
                          // }
                        >
                          <IonLabel>{item?.name}</IonLabel>
                        </IonSegmentButton>
                      ))}
                    </IonSegment>
                  </div>
                  <div className="family-accordian-card-header-text">
                    <IonText>DM Type II</IonText>
                  </div>
                </div>
                <div className="family-accordian-card-header-icon">
                  <IonIcon icon={chevronDownOutline} />
                </div>
              </div>
            </div>
          </IonCard>
          <IonCard className="family-card">
            <div className="family-accordian-card-header">
              <div className="family-accordian-card-title">
                <div className="family-left">
                  <div className="family-toggle">
                    <IonSegment
                      className="family-buttnSegment"
                      onIonChange={(e: any) => {
                        // setSelected(e.detail.value);
                      }}
                    >
                      {data.arr.map((item: any, i: any) => (
                        <IonSegmentButton
                          key={i}
                          value={item?.name}
                          className={`familytoggleBtn ${
                            i === 0 ? "lhs" : "rhs"
                          }`}
                          // className={
                          //   selected === item?.name
                          //     ? "togglebttn active"
                          //     : "togglebttn"
                          // }
                        >
                          <IonLabel>{item?.name}</IonLabel>
                        </IonSegmentButton>
                      ))}
                    </IonSegment>
                  </div>
                  <div className="family-accordian-card-header-text">
                    <IonText>Cancer</IonText>
                  </div>
                </div>
                <div className="family-accordian-card-header-icon">
                  <IonIcon icon={chevronDownOutline} />
                </div>
              </div>
            </div>
          </IonCard>
          <IonCard className="family-card">
            <div className="family-accordian-card-header">
              <div className="family-accordian-card-title">
                <div className="family-left">
                  <div className="family-toggle">
                    <IonSegment
                      className="family-buttnSegment"
                      onIonChange={(e: any) => {
                        // setSelected(e.detail.value);
                      }}
                    >
                      {data.arr.map((item: any, i: any) => (
                        <IonSegmentButton
                          key={i}
                          value={item?.name}
                          className={`familytoggleBtn ${
                            i === 0 ? "lhs" : "rhs"
                          }`}
                          // className={
                          //   selected === item?.name
                          //     ? "togglebttn active"
                          //     : "togglebttn"
                          // }
                        >
                          <IonLabel>{item?.name}</IonLabel>
                        </IonSegmentButton>
                      ))}
                    </IonSegment>
                  </div>
                  <div className="family-accordian-card-header-text">
                    <IonText>Heart disease</IonText>
                  </div>
                </div>
                <div className="family-accordian-card-header-icon">
                  <IonIcon icon={chevronDownOutline} />
                </div>
              </div>
            </div>
          </IonCard>
          <IonCard className="family-card">
            <div className="family-accordian-card-header">
              <div className="family-accordian-card-title">
                <div className="family-left">
                  <div className="family-toggle">
                    <IonSegment
                      className="family-buttnSegment"
                      onIonChange={(e: any) => {
                        // setSelected(e.detail.value);
                      }}
                    >
                      {data.arr.map((item: any, i: any) => (
                        <IonSegmentButton
                          key={i}
                          value={item?.name}
                          className={`familytoggleBtn ${
                            i === 0 ? "lhs" : "rhs"
                          }`}
                          // className={
                          //   selected === item?.name
                          //     ? "togglebttn active"
                          //     : "togglebttn"
                          // }
                        >
                          <IonLabel>{item?.name}</IonLabel>
                        </IonSegmentButton>
                      ))}
                    </IonSegment>
                  </div>
                  <div className="family-accordian-card-header-text">
                    <IonText>COPD</IonText>
                  </div>
                </div>
                <div className="family-accordian-card-header-icon">
                  <IonIcon icon={chevronDownOutline} />
                </div>
              </div>
            </div>
          </IonCard>
          <IonCard className="family-add-card">
            <div className="family-accordian-card-header">
              <div className="family-accordian-card-title">
                <div className="family-left">
                  <div className="circleIcon">
                    <p>+</p>
                  </div>
                  <IonText className="family-add-card-text">
                    Add new field{" "}
                  </IonText>
                  <IonIcon
                    icon={informationCircleOutline}
                    className="alertIcon"
                  />
                </div>
                <div className="family-accordian-card-header-icon">
                  <IonIcon icon={chevronDownOutline} />
                </div>
              </div>
            </div>
          </IonCard>
          <div className="familyCardBottom">
            <IonLabel className="familyCardBottomLabel">Other notes</IonLabel>
            <input type="text" />
          </div>
        </IonList>
      </IonAccordion>
    </IonCard>
  );
};
export default FamilyMedicalCard;
