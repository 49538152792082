import React from "react";
import { IonInput } from "@ionic/react";
import "./InputFieldNew.scss";
import { InputBoxType } from "../../../types/Types";
import { TextFieldTypes } from "@ionic/core";

type TextBoxType = {
  value: InputBoxType;
  onKeyUp?: React.KeyboardEventHandler<HTMLIonInputElement> | undefined;
  type: TextFieldTypes | undefined;
  invalid?: boolean;
};

const InputFieldNew: React.FC<TextBoxType> = ({
  value,
  onKeyUp,
  type,
  invalid,
}) => {
  return (
    <IonInput
      className={`ioninput-inputbox ${invalid ? "invalid-inputbox" : ""}`}
      value={value}
      onKeyUp={onKeyUp}
      type={type}
    ></IonInput>
  );
};

export default InputFieldNew;
