import React from "react";
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonIcon,
  IonGrid,
  IonText,
  IonToolbar,
  IonRow,
  IonCol,
  IonFooter,
  IonPage,
  IonBackButton,
  IonButtons,
} from "@ionic/react";

import {
  keyOutline,
  chevronForwardOutline,
  moonOutline,
  exitOutline,
  lockClosedOutline,
} from "ionicons/icons";

import "../Settings/Settings.scss";
import ToggleSwitch from "../../components/Common/Switch/Switch";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectTheme, switchTheme } from "../../store/reducers/layout";
import { userLogout } from "../../store/reducers/user";

const Settings: React.FC = () => {
  const isDarkTheme = useAppSelector(selectTheme);

  const dispatch = useAppDispatch();

  const toggleThemeEvent = (isDark: boolean) => {
    dispatch(switchTheme(isDark));
  };

  const logOutHandle = () => {
    dispatch(userLogout());
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border" className="ion-header">
        <IonToolbar className="ion-toolbar" mode="ios">
          <IonButtons slot="start">
            <IonBackButton text={""} className="setting-backbutton" />
          </IonButtons>

          <IonTitle className="title-header">Settings</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid className="ion-grid" fixed>
          <IonRow className="ion-row">
            <IonCol class="ion-align-self-start, my-thin-col">
              <IonIcon icon={keyOutline} className="ion-icon-setting" />
            </IonCol>
            <IonCol class="ion-align-self-start">
              <IonText class="ion-text-left padding">Change password</IonText>
            </IonCol>
            <IonCol class="ion-align-self-end, my-thin-col">
              <IonIcon
                icon={chevronForwardOutline}
                className="ion-icon-setting"
              />
            </IonCol>
          </IonRow>

          <IonRow className="ion-row">
            <IonCol class="ion-align-self-start, my-thin-col">
              <IonIcon icon={lockClosedOutline} className="ion-icon-setting" />
            </IonCol>
            <IonCol class="ion-align-self-start">
              <IonText class="ion-text-left padding">Privacy and Terms</IonText>
            </IonCol>
            <IonCol class="ion-align-self-end, my-thin-col">
              <IonIcon
                icon={chevronForwardOutline}
                className="ion-icon-setting"
              />
            </IonCol>
          </IonRow>

          <IonRow className="ion-row">
            <IonCol class="ion-align-self-start, my-thin-col">
              <IonIcon icon={moonOutline} className="ion-icon-setting" />
            </IonCol>
            <IonCol class="ion-float-left">
              <IonText class="ion-text-left padding">Dark mode</IonText>
            </IonCol>
            <IonCol class="ion-align-self-end, my-thin-col-toggle">
              <ToggleSwitch
                name="themeSwitch"
                checked={isDarkTheme}
                onCheck={(isDark) => toggleThemeEvent(isDark)}
              />
            </IonCol>
          </IonRow>

          <IonRow className="ion-row">
            <IonCol class="ion-align-self-start, my-thin-col">
              <IonIcon icon={keyOutline} className="ion-icon-setting" />
            </IonCol>
            <IonCol class="ion-float-left">
              <IonText class="ion-text-left padding">Smart census</IonText>
            </IonCol>
            <IonCol class="ion-align-self-end, my-thin-col">
              <IonIcon
                icon={chevronForwardOutline}
                className="ion-icon-setting"
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter class="ion-no-border" className="ion-footer">
        <IonToolbar className="ion-footer-toolbar" onClick={logOutHandle}>
          <IonIcon
            slot="start"
            icon={exitOutline}
            className="ion-icon-setting footer-icon"
          />
          <IonTitle className="title-footer">Log out</IonTitle>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Settings;
