import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPatientsSearchByID,
  getVisitDetails,
  getVisitStatuses,
} from "../../helper/backendHelper";

export const getPatient = createAsyncThunk(
  //action type string
  "patient/getById",
  // callback function
  async (id: string, thunkAPI) => {
    const res = await getPatientsSearchByID({ id });
    let resVisit = await getVisitDetails({ id });

    let resStatuses = await getVisitStatuses();

    if (res?.status === 500) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      if (res?.status === "success") {
        return {
          patient: res?.result?.[0],
          visitStatuses: resStatuses?.results[0]?.values,
          visitDetails: resVisit?.resData,
        };
      }
    }
  }
);
