import { IonIcon, IonImg, IonText, useIonRouter } from "@ionic/react";
import { notifications } from "ionicons/icons";
import React from "react";
import ProviderHoldsCard from "../../../components/Common/ProviderHoldsCard/ProviderHoldsCard";
import "./ProviderHolds.scss";

interface ProvidHoldsProps {
  providerHoldData: any;
  issuesCount: number;
}

const ProviderHolds: React.FC<ProvidHoldsProps> = ({
  providerHoldData,
  issuesCount,
}) => {
  const router = useIonRouter();
  return (
    <div className="providerholds-container">
      <div className="header-container">
        <div className="left-section">
          <IonText className="providerholds-heading">Provider holds</IonText>
          <div className="notification-icon-container">
            <IonIcon className="bell-icon" icon={notifications}></IonIcon>
            <div className="notification-number">{issuesCount}</div>
          </div>
        </div>
        {providerHoldData?.length > 0 && (
          <span
            className="provider-seeall-link"
            onClick={() => {
              router.push("/providerhold");
            }}
          >
            See all
          </span>
        )}
      </div>
      {providerHoldData?.length > 0 ? (
        providerHoldData.map((item: any, index: number) => (
          <div key={index}>
            <ProviderHoldsCard item={item} />
          </div>
        ))
      ) : (
        <div className="noresult-found">
          <IonText>No result found</IonText>
        </div>
      )}
    </div>
  );
};

export default ProviderHolds;
