import { createSlice } from "@reduxjs/toolkit";
import { PatientDetailsType } from "../../types/PatientDetailsType";
import { VisitStatusesTypes } from "../../types/VisitStatusesType";
import { getPatient } from "../thunk/patientThunk";
// import { getFacility } from "../thunk/facilityThunk";
type UserState = {
  isloading: boolean;
  isError: boolean;
  errorMsg: any;
  thisPatient: PatientDetailsType | null;
  visitStatuses: VisitStatusesTypes[];
  visitDetails: any;
};

const initialState: UserState = {
  isloading: true,
  isError: false,
  errorMsg: "",
  thisPatient: null,
  visitStatuses: [],
  visitDetails: [],
};

export const patientDetailsSlice = createSlice({
  name: "patientDetails",
  initialState,
  reducers: {
    closeError: (state) => {
      state.isError = false;
      state.errorMsg = "";
    },
    closeLoading: (state) => {
      state.isloading = false;
    },

    setThispatient: (state) => {
      state.thisPatient = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatient.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(getPatient.fulfilled, (state, action) => {
        state.isloading = false;
        state.thisPatient = action.payload?.patient;
        state.visitDetails = action.payload?.visitDetails
          ? action.payload?.visitDetails
          : [];
        state.visitStatuses = action.payload?.visitStatuses
          ? action.payload?.visitStatuses
          : [];
      })
      .addCase(getPatient.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload;
        } else {
          state.errorMsg = action.error.message?.toString();
        }
      });
  },
});

export const { closeError, closeLoading, setThispatient } =
  patientDetailsSlice.actions;
export const patientDetailsReducer = patientDetailsSlice.reducer;
