/* eslint-disable react-hooks/exhaustive-deps */
import { IonCard, IonCardContent } from "@ionic/react";
import "./Payment.scss";

interface AmountCardPropsType {
  amount: number;
}

const AmountCard: React.FC<any> = (props: AmountCardPropsType) => {
  return (
    <div className="alignmentcenter">
      <IonCard className="amountcard">
        <IonCardContent>
          <p>
            <strong className="alignmentcenter amount">{props.amount}</strong>
            <span className="alignmentcenter cardtext">Estimated Value</span>
          </p>
        </IonCardContent>
      </IonCard>
    </div>
  );
};

export default AmountCard;
