import React from "react";
// import { ReactComponent as ReactLogo } from "../../../icons/Menu/Census.svg";

import "./IconSvg.scss";

type IconSvgProps = {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};

export const IconSvg: React.FC<IconSvgProps> = ({ Icon }) => {
  return (
    <div className="svg-icon">
      <div className="icon-inner">
        <Icon />
      </div>
    </div>
  );
};
