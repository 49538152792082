/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonContent,
  useIonRouter,
  IonIcon,
  IonRippleEffect,
} from "@ionic/react";
import "./MFALogin.scss";

import { useAppSelector } from "../../store/hooks";
import { selectUser } from "../../store/reducers/user";
import { mfaMailOTPSend, mfaOTPSend } from "../../helper/backendHelper";

import { Layout } from "../../components/Layout/Layout";
import { phonePortraitOutline, mailOutline } from "ionicons/icons";

const MFALogin: React.FC = () => {
  const userData = useAppSelector(selectUser);

  const [isLoading, setIsLoading] = useState(false);

  const router = useIonRouter();

  const onMfaSendMobile = async () => {
    setIsLoading(true);
    const mfaRes = await mfaOTPSend({ "mfa-token": userData.mfatoken });

    if (mfaRes?.status === "success") {
      setIsLoading(false);
      router.push("/otpVerify", "back");
    } else {
      setIsLoading(false);
    }
  };

  const onMfaSendMail = async () => {
    setIsLoading(true);
    const mfaRes = await mfaMailOTPSend({ "mfa-token": userData.mfatoken });

    if (mfaRes?.status === "success") {
      setIsLoading(false);
      router.push("/otpVerify", "back");
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userData.otpType && userData.otpType !== null) {
      router.push("/otpVerify", "back");
    }
  }, [userData.otpType]);

  return (
    <Layout isLoading={isLoading} isError={userData?.isError}>
      <IonContent className="ion-padding login-page mfa-login-page">
        <div className="main-bg">
          <div className="login-form-holder">
            <div className="login-form">
              <div className="header-holder">
                <h1 className="page-title">Select OTP Option</h1>
                <p className="page-sub-title">
                  Select which contact details should we use to MFA Login
                </p>
              </div>
              <div className="selection-holder ">
                <div
                  className="seclection-card ion-activatable ripple-parent"
                  onClick={onMfaSendMobile}
                >
                  <div className="card-inner">
                    <div className="icon-holder">
                      <IonIcon icon={phonePortraitOutline} />
                    </div>
                    <div className="content-holder">
                      <p>via phone: </p>
                      <p>{userData.user?.mobile}</p>
                    </div>
                  </div>
                  <IonRippleEffect></IonRippleEffect>
                </div>
                <div
                  className="seclection-card ion-activatable ripple-parent"
                  onClick={onMfaSendMail}
                >
                  <div className="card-inner">
                    <div className="icon-holder">
                      <IonIcon icon={mailOutline} />
                    </div>
                    <div className="content-holder">
                      <p>via email:</p>
                      <p>{userData.user?.email}</p>
                    </div>
                  </div>
                  <IonRippleEffect></IonRippleEffect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </Layout>
  );
};
export default MFALogin;
