import React, { useEffect, useState } from "react";
// import { CChartBar } from "@coreui/react-chartjs";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { memo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart: React.FC<any> = ({ datas, lables, dataLable }) => {
  const [date, setDate] = useState<any>();

  useEffect(() => {
    let daysAgo: any = [];
    for (let i = 3; i >= 0; i--) {
      daysAgo.push(moment().subtract(i, "days").format("MMM DD"));
    }
    setDate(daysAgo);
    // console.log(daysAgo)
  }, []);
  return (
    <div>
      <div>
        <Bar
          // style={{ backgroundColor: '#231C30', minHeight: '200px' }}
          data={{
            labels: lables ? lables : date,
            datasets: [
              {
                label: dataLable ? dataLable : "GitHub Commits",
                backgroundColor: "#9BE8E2",
                data: datas ? datas : [10, 20, 12, 39],
                barPercentage: 0.5,
                borderRadius: 10,
                borderWidth: 2,
                borderSkipped: false,
              },
            ],
          }}
          options={{
            elements: {
              line: {
                tension: 0.4,
              },
              point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3,
              },
            },
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                grid: {
                  color: "#4F4F4F",
                },
                display: true,
              },
              y: {
                grid: {
                  color: "#4F4F4F",
                },
                beginAtZero: true,
                display: true,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default memo(BarChart);
