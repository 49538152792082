import { TextFieldTypes } from "@ionic/core";
import React from "react";
import "./InputField2.scss";

const InputField2: React.FC<{ label: string; type: TextFieldTypes }> = ({
  label,
  type,
}) => {
  return (
    <div className="input-text-container">
      <label htmlFor={label} className="input-text-label">
        {label}
      </label>
      <input type={type} id={label} name={label} className="input-text" />
    </div>
  );
};

export default InputField2;
