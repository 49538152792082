// import { CChart } from "@coreui/react-chartjs";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { createColorArray } from "../../../helper/formater";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

// import distinctColors from 'distinct-colors'

const InfoPieChart: React.FC<any> = ({ datas, lables, dataLable }) => {
  let backgroundColorArray = [
    ...["#1b334b", "#f4be37", "#44d4c9", "#ff9f40"],
    ...createColorArray(datas?.length),
  ];

  //   console.log(distinctColors({ count: datas?.length }))

  return (
    <div className="info-pie-chart">
      <div>
        <IonGrid>
          <IonRow>
            {lables && (
              <IonCol size="4">
                <div className="chart-content">
                  {lables?.map(
                    (
                      item:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined,
                      index: any
                    ) => (
                      <div className="chart-list" key={index}>
                        <div className="chart-item">
                          <div className="chart-bullet">
                            <div
                              className="bullet"
                              style={{
                                background: backgroundColorArray[index],
                              }}
                            ></div>
                          </div>
                          <div className="inner-text list-item">{item}</div>
                        </div>
                        <div className="chart-value">{datas[index]}</div>
                      </div>
                    )
                  )}
                </div>
              </IonCol>
            )}

            <IonCol size={lables ? "8" : "12"}>
              <div className="pei-holder">
                <Chart
                  type="doughnut"
                  data={{
                    //   labels: lables ? lables : [],
                    datasets: [
                      {
                        backgroundColor: backgroundColorArray,
                        data: datas ? datas : [40, 20, 80, 10],
                        label: dataLable ? dataLable : "",
                      },
                    ],
                  }}
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default InfoPieChart;
