import { useState } from "react";
import { selectUser } from "../store/reducers/user";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { useAppSelector } from "../store/hooks";
export interface UserPhoto {
  filepath: string;
  webviewPath?: string;
}

export function usePhotoCapture(type: any) {
  const [photos, setPhotos] = useState<UserPhoto>();
  const userData = useAppSelector(selectUser);
  const currentFacility = useAppSelector(
    (state) => state.census.censusFacility
  );
  const takePhoto = async () => {
    //This is used only for getting the size of photo using Base64 format
    const photoForSize = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
      quality: 100,
    });
    let fileSize = photoForSize?.base64String?.length
      ? photoForSize?.base64String?.length * (3 / 4) - 2
      : 0;

    //This is the actual photo returned
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100,
    });

    //Create Random string
    const randomString = (length: number) => {
      let text = "";

      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }

      return text;
    };
    //Filename
    let fileName = "";
    if (type === "encounter") {
      fileName =
        userData?.provider?._id +
        "_" +
        new Date().getTime() +
        randomString(6) +
        ".jpeg";
    } else if (type === "census") {
      fileName = currentFacility?._id + "_" + randomString(19) + ".jpeg";
    } else {
      fileName =
        userData?.provider?._id +
        "_" +
        new Date().getTime() +
        randomString(6) +
        ".jpeg";
    }

    const newPhotos = {
      filepath: fileName,
      webviewPath: photo.webPath,
      size: fileSize,
    };
    setPhotos(newPhotos);
  };

  return {
    photos,
    takePhoto,
    setPhotos,
  };
}
