import { AddPatientsPayload } from "../types/AddPatientsPayload";
import { CodeTemplatesType, FavProceduresType } from "../types/AddVisitType";
import { DataEntity } from "../types/MedicalchartTemplatesType";
import { AddPatientFormValueProps } from "../types/Types";
import { VisitsByServicedate } from "../types/VisitsByServicedate";
import { Filesystem, Directory } from "@capacitor/filesystem";
import moment from "moment";

// import { FileTransfer } from "@ionic-native/file-transfer";
// import { File as NativeFile } from "@ionic-native/file";
// import { Capacitor } from "@capacitor/core";

export const codeTemplatesSelectDataFormat = (
  codeTemplates: CodeTemplatesType[]
) => {
  return ["Personal favorites", ...codeTemplates.map((code) => code.name)];
};

export const proceduresTxtArrayToObj = (
  codeArray: string[],
  proceduresCode: FavProceduresType[]
) => {
  return proceduresCode.filter((code) => codeArray.includes(code.code));
};

export const totalPaymentAndRUVFromVisits = (vists: VisitsByServicedate[]) => {
  let paymentTotal = 0;
  for (let i = 0; i < vists.length; i++) {
    paymentTotal += vists[i].patient_paid_amount;
  }
  let rvuTotal = 0;
  for (let i = 0; i < vists.length; i++) {
    rvuTotal += vists[i].rvu;
  }

  return {
    paymentTotal,
    rvuTotal,
  };
};

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  var b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  let fileOfBlob = new File([b], fileName, {
    type: "image/jpeg",
  });

  //Cast to a File() type
  return fileOfBlob;
};

export const wrapWithSelectionProps = (vists: VisitsByServicedate[]) => {
  let withSelection = vists.map((visit) => ({ ...visit, isSelected: false }));
  return withSelection;
};

export const removeFacilityDuplication = (array: any[]) => {
  const result = array.filter(
    (thing, index, self) =>
      index === self.findIndex((t) => t.facility_id === thing.facility_id)
  );

  return result;
};

export const addUniqFlagToArray = (array: any[]) => {
  const result = array.map((item, index) => ({ ...item, _id: index }));
  return result;
};

export const seperateInpatientEnconter = (
  visit: any[],
  statuses: any[],
  thisProvider?: any
) => {
  let inpatients = [];
  let encounters = [];

  let tempVisit = [...visit];

  // console.log({ tempVisit });

  try {
    for (let tmp of tempVisit) {
      // console.log({ tmp });

      if (tmp.activities.length > 0) {
        tmp = {
          ...tmp,
          last_activity:
            tmp.activities[tmp.activities.length - 1].activity_date,
        };
      } else {
        tmp = { ...tmp, last_activity: "" };
      }
      if (
        tmp.diagnosis_code_primary &&
        tmp.diagnosis_code_primary.description
      ) {
        if (tmp.diagnosis_code_primary.description.length > 20) {
          tmp = {
            ...tmp,
            diagnosis_code_primary: {
              description:
                tmp.diagnosis_code_primary.description.substring(0, 20) +
                " ...",
              code: tmp.diagnosis_codes?.[0],
            },
          };
        }
      } else {
        if (tmp.diagnosis_codes && tmp.diagnosis_codes?.[0]) {
          tmp = {
            ...tmp,
            diagnosis_code_primary: {
              code: tmp.diagnosis_codes?.[0],
              description: "",
            },
          };
        }
      }
      if (tmp.rendering_provider_name) {
        let names = tmp.rendering_provider_name.split(" ");
        let firstname = names?.[1];
        let lastname = names?.[0];
        tmp = { ...tmp, rendering_initial: lastname?.[0] };

        if (firstname) {
          tmp.rendering_initial = tmp.rendering_initial + firstname?.[0];
        }
        tmp.rendering_initial = tmp.rendering_initial.toUpperCase();
      }

      tmp = { ...tmp, statusGroup: statuses[tmp.status]?.group };

      if (tmp?.rendering_provider_id === thisProvider._id) {
        inpatients.push(tmp);
        // console.log({ tmp, name: "inpatients" });
      } else {
        // console.log({ tmp, name: "encounters" });

        encounters.push(tmp);
      }

      // if (statuses[tmp.status] && statuses[tmp.status]?.group === "Available") {
      //   inpatients.push(tmp);
      // } else if (statuses[tmp.status]?.group !== "Available") {
      //   encounters.push(tmp);
      // }
    }
  } catch (e) {
    console.log({ e });
  }

  // console.log({ inpatients, encounters });

  return { inpatients, encounters };
};

export const segregatePatients = (visit: any[], statuses: any[]) => {
  let patientsList = [];
  let tempVisit = [...visit];

  try {
    for (let tmp of tempVisit) {
      if (tmp.activities.length > 0) {
        tmp = {
          ...tmp,
          last_activity:
            tmp.activities[tmp.activities.length - 1].activity_date,
        };
      } else {
        tmp = { ...tmp, last_activity: "" };
      }
      if (
        tmp.diagnosis_code_primary &&
        tmp.diagnosis_code_primary.description
      ) {
        if (tmp.diagnosis_code_primary.description.length > 20) {
          tmp = {
            ...tmp,
            diagnosis_code_primary: {
              description:
                tmp.diagnosis_code_primary.description.substring(0, 20) +
                " ...",
              code: tmp.diagnosis_codes?.[0],
            },
          };
        }
      } else {
        if (tmp.diagnosis_codes && tmp.diagnosis_codes?.[0]) {
          tmp = {
            ...tmp,
            diagnosis_code_primary: {
              code: tmp.diagnosis_codes?.[0],
              description: "",
            },
          };
        }
      }
      if (tmp.rendering_provider_name) {
        let names = tmp.rendering_provider_name.split(" ");
        let firstname = names?.[1];
        let lastname = names?.[0];
        tmp = { ...tmp, rendering_initial: lastname?.[0] };

        if (firstname) {
          tmp.rendering_initial = tmp.rendering_initial + firstname?.[0];
        }
        tmp.rendering_initial = tmp.rendering_initial.toUpperCase();
      }

      tmp = { ...tmp, statusGroup: statuses[tmp.status]?.group };
      patientsList.push(tmp);

      // if (statuses[tmp.status] && statuses[tmp.status]?.group === "Available") {
      //   inpatients.push(tmp);
      // } else if (statuses[tmp.status]?.group !== "Available") {
      //   encounters.push(tmp);
      // }
    }
  } catch (e) {
    console.log({ e });
  }

  return { patientsList };
};

export const addPatientPayloadFromater = (values: AddPatientFormValueProps) => {
  let payload: AddPatientsPayload = {
    admission_date: new Date(values.DOI).toDateString(),
    facility_id: values.selectedFacility?._id
      ? values.selectedFacility?._id
      : "",
    facility_mrn: values.FacilityMRN,
    room: values.Room,
    patient: {
      dob: values.DOB !== "" ? new Date(values.DOB).toDateString() : "",
      gender: values.gender,
      patient_firstname: values.firstname,
      patient_lastname: values.lastname,
      patient_middlename: values.middlename,
    },
  };

  return payload;
};

export const formatProgressBarItems = (template: DataEntity[]) => {
  let ProgressBarItems = template.map((item, index) => ({
    id: index + 2,
    name: item.title,
    completed: false,
  }));

  ProgressBarItems.push({
    id: ProgressBarItems.length + 2,
    name: "Note Preview",
    completed: false,
  });

  return ProgressBarItems;
};

export const getFacilityByVisits = (
  visit: VisitsByServicedate[],
  facilitiesList: any[]
) => {
  const visitUniq = visit.map((visit) => visit.facility_id);

  const newFilteredData = facilitiesList.filter((item) =>
    visitUniq.includes(item?._id)
  );

  return facilitiesList;
};

export const downloadFile = (Blob: any) => {
  Filesystem.writeFile({
    data: Blob,
    path: "progressNote.png",
    directory: Directory.Documents,
  })
    .then((data: any) => {
      console.log({ data });
    })
    .catch((e: any) => {
      console.log({ e });
    });

  const downloadLink = document.createElement("a");
  downloadLink.href = Blob;
  downloadLink.setAttribute("download", "progressNote.png");
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  console.log({ Filesystem, downloadLink });
};

export const createColorArray = (length: any) => {
  var colors = [];
  while (colors.length < length) {
    do {
      var color: any = Math.floor(Math.random() * 1000000 + 1);
    } while (colors.indexOf(color) >= 0);
    colors.push("#" + ("000000" + color.toString(16)).slice(-6));
  }

  return colors;
};

export function toMonthName(monthNumber: number) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString("en-US", {
    month: "short",
  });
}

export const facilityInfoChartDataFormat = (data: any, type: any) => {
  let total_count = 0;

  let dataArray = data?.map(
    (item: { total_count: any; total_count_of: any }) => {
      if (item?.total_count) {
        total_count += item?.total_count;
      } else {
        total_count += item?.total_count_of;
      }

      return item?.total_count
        ? parseFloat(item?.total_count).toFixed(2)
        : parseFloat(item?.total_count_of).toFixed(2);
    }
  );

  let lable;

  let lableArray = data?.map(
    (
      item: {
        _id: {
          month: number;
          admission_date: moment.MomentInput;
          service_date_from: moment.MomentInput;
        };
      },
      index: number
    ) => {
      switch (type) {
        case "year":
          lable = item?._id?.month ? toMonthName(item?._id?.month) : "month";
          return lable;
        case "month":
          lable = "week";
          return lable + " " + (index + 1);
        case "week":
          if (item?._id?.admission_date) {
            lable = moment(item?._id?.admission_date).format("MMM DD");
            return lable;
          } else {
            lable = moment(item?._id?.service_date_from).format("MMM DD");
            return lable;
          }

        case "today":
          if (item?._id?.admission_date) {
            lable = moment(item?._id?.admission_date).format("MMM DD");
            return lable;
          } else {
            lable = moment(item?._id?.service_date_from).format("MMM DD");
            return lable;
          }

        default:
          return 0;
      }
    }
  );

  return {
    lableArray,
    dataArray,
    total_count: Math.round((total_count + Number.EPSILON) * 100) / 100,
  };
};
