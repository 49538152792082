/* eslint-disable react-hooks/exhaustive-deps */
import { IonItem, IonLabel, IonRadio, IonRadioGroup } from "@ionic/react";
import React, { useEffect, useState } from "react";
import CheckBox from "../../components/Common/CheckBox/CheckBox";
import { dynamicInputGenerator } from "../../helper/dynamicInputGenerator";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  handleRegroupCode,
  setDiagnosisCode,
  setProcedureCode,
} from "../../store/reducers/progressNote";
import {
  OptionsEntity,
  SectionsEntity,
} from "../../types/MedicalchartTemplatesType";
import DiagnosisCard from "../Encounter/DiagnosisCard/DiagnosisCard";
import EncounterCard from "../Encounter/EncounterCard/EncounterCard";
import ProcedureCard from "../Encounter/ProcedureCard/ProcedureCard";

type DynamicInputFieldsProps = {
  section: SectionsEntity | null;
  index: number;
  wholeData?: (SectionsEntity | null)[] | null;
  inputDataOnChange?: (data: any) => void;
  disabled?: boolean;
  isRequired?: boolean;
};

type DynamicInputProps = {
  option: OptionsEntity | null;
  wholeData?: (OptionsEntity | null)[] | null;
  index: number;
  inputObjOnChange?: (data: any) => void;
  disabled?: boolean;
  isRequired?: boolean;
};

type RadioButtonFieldsProps = {
  options?: (OptionsEntity | null)[] | null;
  inputObjOnChange?: (data: any) => void;
  section: SectionsEntity | null;
  disabled?: boolean;
};

const DynamicInput: React.FC<DynamicInputProps> = ({
  option,
  index,
  wholeData,
  inputObjOnChange,
  disabled,
  isRequired,
}) => {
  const inputOnChange = (dataVal: any) => {
    if (wholeData) {
      let tempData: any = [...wholeData];

      tempData[index] = dataVal;

      if (inputObjOnChange) {
        inputObjOnChange(tempData);
      }
    }
  };

  const [thisInput, setThisInput] = useState<any>();

  useEffect(() => {
    setThisInput(
      dynamicInputGenerator(option, inputOnChange, disabled, isRequired)
    );
  }, [option]);

  return <>{thisInput}</>;
};

const RadioButtonFields: React.FC<RadioButtonFieldsProps> = ({
  options,
  section,
  inputObjOnChange,
  disabled,
}) => {
  const onRadioCheck = (value: any) => {
    if (inputObjOnChange) {
      inputObjOnChange({ ...section, value });
    }
  };

  return (
    <div className="checkbox-holder">
      <IonRadioGroup
        onIonChange={(e) => onRadioCheck(e.detail.value)}
        value={section?.value}
      >
        {options?.map((option, index) => (
          <IonItem key={index}>
            <IonLabel>{option?.label}</IonLabel>
            <IonRadio value={option?.label} disabled={disabled} />
          </IonItem>
        ))}
      </IonRadioGroup>
    </div>
  );
};

const DynamicInputFields: React.FC<DynamicInputFieldsProps> = ({
  section,
  wholeData,
  index,
  inputDataOnChange,
  disabled,
  isRequired,
}) => {
  const dispatch = useAppDispatch();
  const progressNoteState = useAppSelector((state) => state.progressNote);

  const onDiagnosisCodeSet = (code: any) => {
    dispatch(setDiagnosisCode(code));
  };

  const handleCodeRegroup = (regroupData: any) => {
    dispatch(handleRegroupCode(regroupData));
  };

  const onProcedureCodeSet = (code: any) => {
    dispatch(setProcedureCode(code));
  };

  const [isCheckBox, setIsCheckBox] = useState(false);

  const inputObjOnChange = (dataVal: any) => {
    if (wholeData) {
      let tempData: any = [...wholeData];

      if (tempData[index].options) {
        tempData[index] = { ...tempData[index], options: dataVal };
      }

      if (inputDataOnChange) {
        inputDataOnChange(tempData);
      }
    }
  };

  const inputRadioObjOnChange = (dataVal: any) => {
    if (wholeData) {
      let tempData: any = [...wholeData];

      tempData[index] = dataVal;

      // console.log({ tempData });

      if (inputDataOnChange) {
        inputDataOnChange(tempData);
      }
    }
  };

  return (
    <div className="input-group-holder">
      {section?.checkbox ? (
        <CheckBox
          className="input-box"
          checked={isCheckBox}
          onCheck={(data) => setIsCheckBox(data)}
          strictMode={true}
          label={section?.label}
          labelClassName={`txtbox-lbl d-block`}
          disabled={disabled}
        />
      ) : (
        section?.label && (
          <IonLabel className="txtbox-lbl">{section?.label}</IonLabel>
        )
      )}

      {isCheckBox || !section?.checkbox ? (
        <>
          {section?.type === "input" ? (
            section?.options?.map((option, index) => (
              <DynamicInput
                key={index}
                option={option}
                wholeData={section?.options}
                index={index}
                inputObjOnChange={inputObjOnChange}
                disabled={disabled}
                isRequired={isRequired}
              />
            ))
          ) : section?.type === "radio" ? (
            <RadioButtonFields
              options={section?.options}
              inputObjOnChange={inputRadioObjOnChange}
              section={section}
              disabled={disabled}
            />
          ) : section?.type === "diagnosis" ? (
            <>
              <EncounterCard title="Diagnosis codes" isIcon>
                <DiagnosisCard
                  selectedCode={progressNoteState.diagnosisCodesSelected}
                  onSetCode={onDiagnosisCodeSet}
                  handleRegroup={handleCodeRegroup}
                  disabled={disabled}
                />
              </EncounterCard>
            </>
          ) : section?.type === "procedures" ? (
            <>
              <EncounterCard title="Procedure codes" isIcon>
                <ProcedureCard
                  selectedCode={progressNoteState.procedureCodesSelected}
                  onSetCode={onProcedureCodeSet}
                  handleRegroup={handleCodeRegroup}
                  disabled={disabled}
                />
              </EncounterCard>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default DynamicInputFields;
