import React from "react";
import { Route, Redirect } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";
import Provider from "../pages/Provider/Provider";
import Practice from "../pages/Practice/Practice";

const SelectorRoutes: React.FC = () => {
  return (
    <>
      <Route
        exact
        path="*"
        render={() => {
          return <Redirect to="/selectPractice" />;
        }}
      />

      <IonRouterOutlet basePath="/" id="selectorRoute">
        <Route exact path="/selectProvider">
          <Provider switchUser={false} />
        </Route>
        <Route exact path="/selectPractice">
          <Practice switchUser={false} />
        </Route>

        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to="/selectPractice" />;
          }}
        />
      </IonRouterOutlet>
    </>
  );
};

export default SelectorRoutes;
