/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonButton,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonFooter,
  IonGrid,
  IonIcon,
  IonList,
  IonRow,
  IonText,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import {
  add,
  documentTextOutline,
  medkitOutline,
  personOutline,
} from "ionicons/icons";
import { groupBy, mapValues, omit } from "lodash";
import { useEffect, useRef, useState } from "react";
import { VisitsByServicedate } from "../../types/VisitsByServicedate";
import CheckBox from "../Common/CheckBox/CheckBox";
import SwapCard from "./SwapCard";
import DoubleTapWraper from "./DoubleTapWraper";
import Button from "../Common/Buttons/Buttons";
import SearchBox from "../Common/SearchBox/SearchBox";
import { debounce } from "underscore";
import useLongPress from "../../hooks/useLongPress";
import { useAppSelector } from "../../store/hooks";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import Toast, { newToast } from "../Common/Toaster/Toast";
import DocUpload from "./DocUpload";
import BulkActions from "./BulkActions";

type EncountersProps = {
  visits: VisitsByServicedate[];
  handleVoid: () => void;
  setNewEncounterOpen: (val: boolean) => void;
  searchTxt: string;
  setSearch: (txt: string) => void;
};

type ToastedList = {
  id: number;
  description: string;
};
const Encounters: React.FC<EncountersProps> = ({
  visits,
  setNewEncounterOpen,
  searchTxt,
}) => {
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [slectionMode, setSlectionMode] = useState<boolean>(false);
  const [status, setStatus] = useState("");
  const [searchText, setSearchText] = useState("");
  const [visitsData, setVisitsData] = useState<{
    [x: string]: Pick<VisitsByServicedate, keyof VisitsByServicedate>[];
  }>();
  const [toastedMessage, setToastedMessage] = useState<ToastedList[]>([]);
  const [onLongPressMode, setOnLongPressMode] = useState<boolean>(false);
  const [openDocumentUpload, setOpenDocumentUpload] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);

  const [selectedVisits, setSelectedVisits] = useState<any[]>([]);

  const router = useIonRouter();
  const currentFacility = useAppSelector(
    (state) => state.census.censusFacility
  );
  const [bulkSelected, setBulkSelected] = useState<boolean>(false);

  const fabRef = useRef(null);
  useOutsideClick(fabRef); //Custom hook to close an element with ref on clicking outside

  // const [selectedFIlter, setSelectedFIlter] = useState<string>("notStartedBtn");

  useEffect(() => {
    //logic to set search result
    //console.log("Visitsin-encounters",visits)
    let searchVisit = visits?.filter(
      (item: any) =>
        item?.patient_lastname
          ?.toLowerCase()
          ?.includes(searchText.toLowerCase()) ||
        item?.patient_firstname
          ?.toLowerCase()
          ?.includes(searchText.toLowerCase())
    );
    // console.log("Results", searchVisit);

    let groupedVisit = mapValues(groupBy(searchVisit, "statusGroup"), (clist) =>
      clist.map((visit) => omit(visit, "statusGroup"))
    );

    // console.log("Grouped-visits-",groupedVisit)

    if (searchText === "") {
      setVisitsData(
        mapValues(groupBy(visits, "statusGroup"), (clist) =>
          clist.map((visit) => omit(visit, "statusGroup"))
        )
      );
    } else {
      setVisitsData(groupedVisit);
    }
  }, [searchText]);

  useEffect(() => {
    let newSelectedList: any[] = [];

    if (visitsData?.Available) {
      visitsData?.Available.forEach((visit) => {
        if (visit?.isSelected) {
          newSelectedList.push(visit);
        }
      });
    }
    if (visitsData?.Incomplete) {
      visitsData?.Incomplete.forEach((visit) => {
        if (visit?.isSelected) {
          newSelectedList.push(visit);
        }
      });
    }
    if (visitsData?.Completed) {
      visitsData?.Completed.forEach((visit) => {
        if (visit?.isSelected) {
          newSelectedList.push(visit);
        }
      });
    }

    // console.log({ newSelectedList, visitsData });

    setSelectedVisits(newSelectedList);
  }, [visitsData]);

  // useEffect(() => {
  //   console.log("SEARCTXTTTTT",searchTxt)
  //   let searchVisit = visits?.filter(
  //     (item: any) =>
  //       item?.patient_lastname
  //         ?.toLowerCase()
  //         ?.includes(searchTxt.toLowerCase()) ||
  //       item?.patient_firstname
  //         ?.toLowerCase()
  //         ?.includes(searchTxt.toLowerCase())
  //   );
  //   console.log("Results------",searchVisit)

  //   let groupedVisit = mapValues(groupBy(searchVisit, "statusGroup"), (clist) =>
  //     clist.map((visit) => omit(visit, "statusGroup"))
  //   );

  //   console.log("Grouped-visits-------------",groupedVisit)

  //   if (searchTxt === "") {
  //     setVisitsData(
  //       mapValues(groupBy(visits, "statusGroup"), (clist) =>
  //         clist.map((visit) => omit(visit, "statusGroup"))
  //       )
  //     );
  //   } else {
  //     console.log("sate")
  //     setVisitsData(groupedVisit);
  //   }

  // },[searchTxt])

  // useEffect(() => {
  //   let tempCount1 = visitsData?.Incomplete?.filter(
  //     (visit) => visit.isSelected === true
  //   );
  //   let tempCount2 = visitsData?.Completed?.filter(
  //     (visit) => visit.isSelected === true
  //   );

  //   // console.log({ visitsData });

  //   if (tempCount1 || tempCount2) {
  //     setSelectedCount(
  //       (tempCount1?.length ? tempCount1?.length : 0) +
  //         (tempCount2?.length ? tempCount2?.length : 0)
  //     );
  //   }
  // }, [visitsData]);
  const onBulkCheck = () => {
    // console.log(visitsData);
    let tempVisit: any = { ...visitsData };
    let tempCount1 = [];
    let tempCount2 = [];
    let tempCount3 = [];
    tempCount1 = tempVisit?.Incomplete?.filter(
      (visit: any) => visit.isSelected === true
    );
    tempCount2 = tempVisit?.Completed?.filter(
      (visit: any) => visit.isSelected === true
    );
    tempCount3 = tempVisit?.Available?.filter(
      (visit: any) => visit.isSelected === true
    );
    if (
      (tempCount1?.length ?? 0) +
        (tempCount2?.length ?? 0) +
        (tempCount3?.length ?? 0) >
      0
    ) {
      setBulkSelected(true);
    } else {
      setBulkSelected(false);
    }
    // console.log(tempCount1, tempCount2, tempCount3);
    //let visitdata = Object.values(visitsData);
    //setBulkSelected(visitsData.filter(x=>x.isSelected === true));
  };
  const onVisitSelect = (
    visit: VisitsByServicedate,
    index: number,
    isSelected: boolean,
    group?: string
  ) => {
    let tempVisit: any = { ...visitsData };
    // console.log({ visit, index, isSelected, group, tempVisit });
    if (group) {
      tempVisit[group][index] = { ...visit, isSelected: isSelected };
    }
    setVisitsData(tempVisit);
    onBulkCheck();
    // console.log({ visit, index, isSelected, group, tempVisit });
  };

  const onClearSelect = () => {
    let groupedVisit = mapValues(groupBy(visits, "statusGroup"), (clist) =>
      clist.map((visit) => omit(visit, "statusGroup"))
    );
    setVisitsData(groupedVisit);
  };

  const deactiveteSelectionMode = () => {
    setSlectionMode(false);
    setOnLongPressMode(false);
    onClearSelect();
  };

  const activeteSelectionMode = () => {
    setSlectionMode(true);
    setOnLongPressMode(true);
  };

  const getText = (txt: string) => {
    let searchVisit = visits?.filter(
      (item: any) =>
        item?.patient_lastname?.toLowerCase()?.includes(txt.toLowerCase()) ||
        item?.patient_firstname?.toLowerCase()?.includes(txt.toLowerCase())
    );
    // console.log("Results------",searchVisit)

    let groupedVisit = mapValues(groupBy(searchVisit, "statusGroup"), (clist) =>
      clist.map((visit) => omit(visit, "statusGroup"))
    );

    // console.log("Grouped-visits-------------",groupedVisit)

    if (txt === "") {
      setVisitsData(
        mapValues(groupBy(visits, "statusGroup"), (clist) =>
          clist.map((visit) => omit(visit, "statusGroup"))
        )
      );
    } else {
      setVisitsData(groupedVisit);
    }
  };

  useEffect(() => {
    onClearSelect();
    if (searchTxt !== "") {
      getText(searchTxt);
    } else {
      setSearchText("");
    }
    setStatus("");
    // showCards("");
  }, [visits]);

  // const showCards = (btnText: string) => {
  //   switch (btnText) {
  //     case (btnText = "notstarted"): {
  //       if (notStartedBtn === "secondary") {
  //         setNotStartedbtn("");
  //         setCompletedBtn("secondary");
  //         setDraftBtn("secondary");
  //         setStatus("notstarted");
  //       } else {
  //         setNotStartedbtn("secondary");
  //         setStatus("");
  //       }
  //       break;
  //     }
  //     case (btnText = "completed"): {
  //       if (completedBtn === "secondary") {
  //         setCompletedBtn("");
  //         setNotStartedbtn("secondary");
  //         setDraftBtn("secondary");
  //         setStatus("completed");
  //       } else {
  //         setCompletedBtn("secondary");
  //         setStatus("");
  //       }
  //       break;
  //     }
  //     case (btnText = "draft"): {
  //       if (draftBtn === "secondary") {
  //         setDraftBtn("");
  //         setCompletedBtn("secondary");
  //         setNotStartedbtn("secondary");
  //         setStatus("draft");
  //       } else {
  //         setDraftBtn("secondary");
  //         setStatus("");
  //       }
  //       break;
  //     }
  //     default: {
  //       setNotStartedbtn("secondary");
  //       setCompletedBtn("secondary");
  //       setDraftBtn("secondary");
  //       setStatus("");
  //       //statements;
  //       break;
  //     }
  //   }
  // };

  const onLongPress = () => {
    activeteSelectionMode();
  };

  const onClick = () => {};

  const defaultOptions = {
    shouldPreventDefault: false,
    delay: 1000,
  };
  // const [present, dismiss] = useIonToast();
  const openNewEncounter = () => {
    if (currentFacility?._id == undefined) {
      let toastMsg = newToast(
        "Facility  is empty , please select & continue",
        "Info"
      );
      setToastedMessage([...toastedMessage, toastMsg]);
    }
    setNewEncounterOpen(true);
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  // console.log({ visitsData });

  return (
    <>
      <Toast
        toastList={toastedMessage}
        position="top-right"
        autoDelete={true}
        autoDeleteTime={4000}
      />
      <div className="tool-bar-header">
        {slectionMode}
        {
          //slectionMode || onLongPressMode ? (
          slectionMode && (
            <div className="selection-handle-holder">
              <div className="slection-handle" onClick={onClearSelect}>
                <CheckBox
                  minus
                  value="visitSlection"
                  checked={true}
                  strictMode
                />
                <p>{selectedCount} selected</p>
              </div>
              <IonFooter className="discharge_footer">
                <div className="footer-btn-holder">
                  <IonButton
                    className="discharge_footer_button"
                    onClick={deactiveteSelectionMode}
                  >
                    <p>Void</p>
                  </IonButton>
                  <IonButton
                    className="discharge_footer_button primary"
                    onClick={deactiveteSelectionMode}
                  >
                    <p>Multiple visits</p>
                  </IonButton>
                </div>
              </IonFooter>
            </div>
          )
          //  : (
          //   <div className="search-box-container">
          //     <div className="search-box-census">
          //       <SearchBox
          //         variant="light-round-Corner"
          //         onChange={debounce(setSearchText, 1000)}
          //         placeholder={"Search patient"}
          //         value={searchText}
          //       />
          //     </div>
          //   </div>
          // )
        }
      </div>

      {visits?.length > 0 && (
        <div className="group-holder">
          {visitsData?.Available && (
            <>
              {status === "notstarted" || status === "" ? (
                <>
                  {/* <div className="subheading-counter-container">
                    <h5>Available</h5>
                    <div className="count-circle">
                      <IonText className="count black-count">
                        {visitsData?.Available?.length}
                      </IonText>
                    </div>
                  </div> */}
                  <div className="card-list">
                    {visitsData?.Available?.map((visit, idx) => {
                      return (
                        <DoubleTapWraper
                          key={idx}
                          onDoubleTap={() =>
                            router.push(
                              `/patientDetails/${visit._id}`,
                              "forward"
                            )
                          }
                        >
                          {/* <div {...longPressEvent}> */}
                          <SwapCard
                            cardtype={5}
                            key={idx}
                            warning
                            visit={visit}
                            slectionMode={slectionMode}
                            onSelect={onVisitSelect}
                            onLongPressMode={onLongPressMode}
                            index={idx}
                          />
                          {/*  </div> */}
                        </DoubleTapWraper>
                      );
                    })}
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )}
          {visitsData?.Incomplete && (
            <>
              {status === "draft" || status === "" ? (
                <>
                  {/* <div className="subheading-counter-container">
                    <h5>Draft</h5>
                    <div className="count-circle">
                      <IonText className="count black-count">
                        {visitsData?.Incomplete?.length}
                      </IonText>
                    </div>
                  </div> */}
                  <div className="card-list">
                    {visitsData?.Incomplete?.map((visit, idx) => {
                      return (
                        <DoubleTapWraper
                          key={idx}
                          onDoubleTap={() =>
                            router.push(
                              `/patientDetails/${visit._id}`,
                              "forward"
                            )
                          }
                        >
                          {/* <div {...longPressEvent}> */}
                          <SwapCard
                            cardtype={6}
                            key={idx}
                            warning
                            visit={visit}
                            slectionMode={slectionMode}
                            onSelect={onVisitSelect}
                            onLongPressMode={onLongPressMode}
                            index={idx}
                          />
                          {/*  </div> */}
                        </DoubleTapWraper>
                      );
                    })}
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )}
          {visitsData?.Completed && (
            <>
              {status === "completed" || status === "" ? (
                <>
                  {/* {" "} */}
                  {/* <div className="subheading-counter-container">
                    <h5>Completed</h5>
                    <div className="count-circle success">
                      <IonText className="count black-count">
                        {visitsData?.Completed?.length}
                      </IonText>
                    </div>
                  </div> */}
                  <div className="card-list">
                    {visitsData?.Completed?.map((visit, idx) => {
                      return (
                        <DoubleTapWraper
                          key={idx}
                          onDoubleTap={() =>
                            router.push(
                              `/patientDetails/${visit._id}`,
                              "forward"
                            )
                          }
                        >
                          {/* <div {...longPressEvent}> */}
                          <SwapCard
                            cardtype={7}
                            key={visit._id}
                            visit={visit}
                            slectionMode={slectionMode}
                            onSelect={onVisitSelect}
                            onLongPressMode={onLongPressMode}
                            index={idx}
                          />
                          {/*  </div> */}
                        </DoubleTapWraper>
                      );
                    })}
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )}
          {!visitsData?.Available &&
            !visitsData?.Incomplete &&
            !visitsData?.Completed && (
              <p className="text-display user-message">No result found</p>
            )}
        </div>
      )}

      <div>
        {!slectionMode && (
          <IonFab
            className="floating-btn"
            vertical="bottom"
            horizontal="end"
            slot="fixed"
            ref={fabRef}
          >
            {bulkSelected === false && (
              <IonFabButton>
                <IonIcon icon={add} />
              </IonFabButton>
            )}
            {bulkSelected === true && (
              <IonFabButton className="bulk-fab">
                <IonIcon icon={add} />
                BULK ACTION
              </IonFabButton>
            )}
            <IonFabList side="top">
              {bulkSelected === true ? (
                <BulkActions
                  selectedVisits={selectedVisits}
                  setSelectedVisits={setSelectedVisits}
                  setToastedMessage={setToastedMessage}
                  toastedMessage={toastedMessage}
                />
              ) : (
                <div className="encounter-popup">
                  <IonGrid>
                    <IonRow>
                      <IonCol className="encounter-popup-col">
                        <button className="encounter-popup-btn">
                          <IonIcon
                            className="encounter-popup-icon"
                            icon={personOutline}
                          ></IonIcon>
                          <IonText
                            onClick={() =>
                              router.push("/addNewPatient", "forward")
                            }
                            className="encounter-popup-text"
                          >
                            New patient
                          </IonText>
                        </button>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="encounter-popup-col">
                        <button
                          className="encounter-popup-btn"
                          onClick={openNewEncounter}
                        >
                          <IonIcon
                            className="encounter-popup-icon"
                            icon={medkitOutline}
                          ></IonIcon>
                          <IonText className="encounter-popup-text">
                            New encounter
                          </IonText>
                        </button>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="encounter-popup-col">
                        <button
                          className="encounter-popup-btn"
                          onClick={() => {
                            setOpenDocumentUpload(true);
                          }}
                        >
                          <IonIcon
                            className="encounter-popup-icon"
                            icon={documentTextOutline}
                          ></IonIcon>
                          <IonText className="encounter-popup-text">
                            New doument
                          </IonText>
                        </button>
                      </IonCol>
                    </IonRow>
                    {/* <IonRow>
                    <IonCol className="encounter-popup-col">
                      {slectionMode ? (
                        <button
                          className="encounter-popup-btn"
                          onClick={deactiveteSelectionMode}
                        >
                          <IonIcon
                            className="encounter-popup-icon"
                            icon={returnDownBackOutline}
                          ></IonIcon>
                          <IonText className="encounter-popup-text">
                            Back to normal
                          </IonText>
                        </button>
                      ) : (
                        <button
                          className="encounter-popup-btn"
                          onClick={activeteSelectionMode}
                        >
                          <IonIcon
                            className="encounter-popup-icon"
                            icon={albumsOutline}
                          ></IonIcon>
                          <IonText className="encounter-popup-text">
                            Multiple selection
                          </IonText>
                        </button>
                      )}
                    </IonCol>
                  </IonRow> */}
                  </IonGrid>
                </div>
              )}
            </IonFabList>
          </IonFab>
        )}
      </div>

      <DocUpload
        openDocumentUpload={openDocumentUpload}
        setOpenDocumentUpload={setOpenDocumentUpload}
        files={files}
        setFiles={setFiles}
      />
    </>
  );
};

export default Encounters;
