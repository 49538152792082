import { createAsyncThunk } from "@reduxjs/toolkit";
import { Base64 } from "js-base64";
import {
  currentFeatures,
  currentPermissions,
  currentPractice,
  currentProvider,
  currentRoles,
  currentSettings,
  getPractice,
  getProviders,
  login,
  mfaOTPVerify,
  providerlogin,
} from "../../helper/backendHelper";
import { socket,socket1 } from "../../socket";
import {
  loginDataPayload,
  mfaOTPVerifyPayload,
  ProviderLoginData,
} from "../../types/Types";
// import { TokenType } from "../../types/Types";

type Credentials = {
  userName: any;
  password: any;
  platform: any
};

export const userLogin = createAsyncThunk(
  //action type string
  "user/login",
  // callback function
  async (credentials: Credentials, thunkAPI) => {
    let loginData: loginDataPayload = {
      name: Base64.encode(credentials?.userName),
      password: Base64.encode(credentials?.password),
      platform: credentials?.platform,
      appVersion: "1.0",
      appName: "Claimocity",
    };

    const loginRes = await login(loginData);

    if (loginRes?.status === 400) {
      return thunkAPI.rejectWithValue("Server Error===Invalid credentials");
    } else {
      if (loginRes?.status === "success") {        

        await socket.connect();
        await socket.emit('join-room', loginRes.user._id);
        
        await socket1.connect();
        await  socket1.emit('join-room',loginRes.user._id);
        
        
        socket1.on('connect', () => 
        {
          
          socket1.emit('join-room',loginRes.user._id);
        });
        socket1.on('connect_error', (err:any) => {});
        
        if (loginRes?.authToken) {
          let Token = {
            authRefreshToken: loginRes?.authRefreshToken,
            authToken: loginRes?.authToken,
            user: loginRes?.user,
            roles: loginRes?.roles,
            permissions: loginRes?.permissions,
          };

          localStorage.setItem("Token", JSON.stringify(Token));

          const practiceRes = await getPractice().then((res) => res?.results);
          const providerRes = await getProviders().then((res) => res?.results);

          return { ...loginRes, practiceRes, providerRes };
        } else if (loginRes?.mfatoken) {
          return {
            ...loginRes,
            isMFAEnabled: true,
            username: credentials?.userName,
          };
        } else {
          return loginRes;
        }
      } else {
        return thunkAPI.rejectWithValue("Server Error==="+loginRes?.message);
      }
    }
  }
);

export const mfaLogin = createAsyncThunk(
  //action type string
  "user/mfaLogin",
  // callback function
  async (credentials: mfaOTPVerifyPayload, thunkAPI) => {
    let verificationDetails = {
      code: credentials.code,
      "mfa-token": credentials["mfa-token"],
    };

    const loginRes = await mfaOTPVerify(verificationDetails);

    if (loginRes?.status === 400) {
      return thunkAPI.rejectWithValue("Server Error==="+loginRes?.message);
    } else {
      if (loginRes?.status === "success") {
        if (loginRes?.authToken) {
          let Token = {
            authRefreshToken: loginRes?.authRefreshToken,
            authToken: loginRes?.authToken,
            user: loginRes?.user,
            roles: loginRes?.roles,
            permissions: loginRes?.permissions,
          };

          localStorage.setItem("Token", JSON.stringify(Token));

          const practiceRes = await getPractice().then((res) => res?.results);
          const providerRes = await getProviders().then((res) => res?.results);

          return { ...loginRes, practiceRes, providerRes };
        } else {
          return thunkAPI.rejectWithValue("Server Error==="+loginRes?.message);
        }
      } else {
        return thunkAPI.rejectWithValue("Server Error==="+loginRes?.message);
      }
    }
  }
);

export const rememberAuth = createAsyncThunk(
  //action type string
  "user/rememberAuth",
  // callback function
  async (token, thunkAPI) => {
    let Token = localStorage.getItem("Token");
    let TokenObj: any;

    if (typeof Token === "string") {
      TokenObj = JSON.parse(Token);
    }

    const practiceRes = await getPractice().then((res) => res?.results);
    const providerRes = await getProviders().then((res) => res?.results);

    const settingsRes = await currentSettings().then((res) => res?.results);
    const rolesRes = await currentRoles().then((res) => res?.results);
    const permissionsRes = await currentPermissions().then(
      (res) => res?.results
    );
    const featuresRes = await currentFeatures().then((res) => res);

    const curentProviderRes = await currentProvider().then(
      (res) => res?.results
    );
    const curentPracticeRes = await currentPractice().then(
      (res) => res?.result
    );

    if (practiceRes?.length > 0 && providerRes?.length) {
      return {
        ...TokenObj,
        practiceRes,
        providerRes,
        curentProviderRes,
        curentPracticeRes,
        settingsRes,
        rolesRes,
        permissionsRes,
        featuresRes,
      };
    } else {
      // localStorage.removeItem("Token");
      return thunkAPI.rejectWithValue("Server Error");
    }
  }
);

export const providerLogin = createAsyncThunk(
  //action type string
  "user/providerLogin",
  // callback function
  async (credentials: ProviderLoginData, thunkAPI) => {
    let Token = await localStorage.getItem("Token");
    let TokenObj: any;

    if (typeof Token === "string") {
      TokenObj = JSON.parse(Token);
    }

    const logRes = await providerlogin({
      practice_id: credentials.practice_id,
      provider_id: credentials.provider_id,
    });

    if (logRes?.status === "success" && logRes?.result !== null) {
      let newToken = await { ...TokenObj, authToken: logRes?.result };
      await localStorage.setItem("Token", JSON.stringify(newToken));
            
      const providerRes = await currentProvider().then((res) => res?.results).catch((err)=>{});
      const practiceRes = await currentPractice().then((res) => res?.result).catch((err)=>{});
      const settingsRes = await currentSettings().then((res) => res?.results).catch((err)=>{});
      const rolesRes = await currentRoles().then((res) => res?.results).catch((err)=>{});
      const permissionsRes = await currentPermissions().then(
        (res) => res?.results
      ).catch((err)=>{});
      const featuresRes = await currentFeatures().then((res) => res).catch((err)=>{});

      return {
        ...newToken,
        practiceRes,
        providerRes,
        settingsRes,
        rolesRes,
        permissionsRes,
        featuresRes,
      };
    } else {
      // localStorage.removeItem("Token");
      //return thunkAPI.rejectWithValue("Server Error");
      return thunkAPI.rejectWithValue("Server Error==="+logRes?.message);
    }
  }
);
