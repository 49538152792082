import React, { useState } from "react";
import "./CustomButton.scss";

type CustomButtonProps = {
  text: string;
  defultSelected?: boolean;
  isDirectSelect?: boolean;
  onSelect?: (code: string) => void;
  deSelect?: (code: string) => void;
};

const CustomButton: React.FC<CustomButtonProps> = ({
  text,
  defultSelected,
  onSelect,
  deSelect,
  isDirectSelect,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(
    defultSelected ? true : false
  );

  const onClickSelect = () => {
    setIsSelected(() => !isSelected);
    if (onSelect && deSelect) {
      if (isDirectSelect && !defultSelected) {
        onSelect(text);
      } else {
        deSelect(text);
      }
    }
  };

  return (
    <button
      onClick={onClickSelect}
      className={
        (isDirectSelect ? defultSelected : isSelected)
          ? "common selected-btn"
          : "common btn"
      }
    >
      {text}
    </button>
  );
};

export default CustomButton;
