import React from "react";
import { IonCard, IonCardSubtitle } from "@ionic/react";

import "./FacilityInformation.scss";

interface Props {
  SelectedData: any;
}
const FacilityInformation: React.FC<Props> = ({ SelectedData }) => {
  let data = null;
  data = {
    admissionDate: SelectedData?.admission_date,
    serviceDate: SelectedData?.service_date_from,
    dischargeDate: "",
    facility: SelectedData?.facility_name,
    facilityMRN: SelectedData?.facility_mrn,
    claimocityMRN: "",
  };
  return (
    <IonCard className="facilityInfoCard">
      <IonCardSubtitle className="facilityInfoCardTitle">
        Facility information
      </IonCardSubtitle>

      <div className="facilityInfoCardContent">
        <div className="singleInfo">
          <div className="facilityInfoLabel">
            <p>Admission date</p>
          </div>
          <div className="facilityInfo">
            <p>
              {data?.admissionDate?.trim()?.length === 0 ||
              data?.admissionDate === null ||
              data?.admissionDate === undefined
                ? "-"
                : data?.admissionDate}
            </p>
          </div>
        </div>
        <div className="singleInfo">
          <div className="facilityInfoLabel">
            <p>Service date</p>
          </div>
          <div className="facilityInfo">
            <p>
              {data?.serviceDate?.trim()?.length === 0 ||
              data?.serviceDate === null ||
              data?.serviceDate === undefined
                ? "-"
                : data?.serviceDate}
            </p>
          </div>
        </div>
        <div className="singleInfo">
          <div className="facilityInfoLabel">
            <p>Discharge date</p>
          </div>
          <div className="facilityInfo">
            <p>
              {data?.dischargeDate?.trim()?.length === 0 ||
              data?.dischargeDate === null ||
              data?.dischargeDate === undefined
                ? "-"
                : data?.dischargeDate}
            </p>
          </div>
        </div>
        <div className="singleInfo">
          <div className={`facilityInfoLabel`}>
            <p>Facility</p>
          </div>
          <div
            className={`facilityInfo ${data?.facility?.length > 15 && "extra"}`}
          >
            <p>
              {data?.facility?.trim()?.length === 0 ||
              data?.facility === null ||
              data?.facility === undefined
                ? "-"
                : data?.facility}
            </p>
          </div>
        </div>
        <div className="singleInfo">
          <div className="facilityInfoLabel">
            <p>Facility MRN</p>
          </div>
          <div className="facilityInfo extra">
            <p>
              {data?.facilityMRN?.trim()?.length === 0 ||
              data?.facilityMRN === null ||
              data?.facilityMRN === undefined
                ? "-"
                : data?.facilityMRN}
            </p>
          </div>
        </div>
        {/* <div className="singleInfo">
          <div className="facilityInfoLabel">
            <p>Claimocity MRN</p>
          </div>
          <div className="facilityInfo">
            <p>
              {data?.claimocityMRN?.trim()?.length === 0 ||
              data?.claimocityMRN === null ||
              data?.claimocityMRN === undefined
                ? "-"
                : data?.claimocityMRN}
            </p>
          </div>
        </div> */}
      </div>
    </IonCard>
  );
};

export default FacilityInformation;
