/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Census.scss";

import MobisrollCalendar from "./MobiscrollCalendar";
import { DailysummaryType } from "../../types/DailysummaryType";
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonText,
  useIonRouter,
} from "@ionic/react";
import {
  caretDownOutline,
  closeOutline,
  chevronDownOutline,
} from "ionicons/icons";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IconSvg } from "../Common/IconSvg/IconSvg";
import { SortIcon } from "../../icons/icons";
import { debounce } from "underscore";
import SearchBox from "../Common/SearchBox/SearchBox";

type SelectWithScrollProps = {
  dropdown_data: DailysummaryType[];
  perSelected: DailysummaryType;
  onChange: (items: DailysummaryType) => void;
  toggleDropDown: () => void;
  isOpen: boolean;
};

export const SelectWithScroll: React.FC<SelectWithScrollProps> = ({
  dropdown_data,
  perSelected,
  isOpen,
  toggleDropDown,
  onChange,
}) => {
  const dispatch = useAppDispatch();

  const onControlClick = (event: any) => {
    event.preventDefault();
    const $slide: any = document.querySelector(
      event?.target?.getAttribute("href")
    );
    if (!$slide) return;
    if ($slide.scrollIntoViewIfNeeded) {
      $slide.scrollIntoViewIfNeeded();
    } else if ($slide.scrollIntoView) {
      $slide.scrollIntoView();
    }
  };

  useEffect(() => {
    if (document) {
      document
        .querySelector("#slideControls")
        ?.addEventListener("click", onControlClick);
    }

    return () => {
      document
        .querySelector("#slideControls")
        ?.removeEventListener("click", onControlClick);
    };
  }, []);

  useEffect(() => {
    const $slide: any = document.querySelector(`#snap_item_0`);
    if (!$slide) return;

    if ($slide.scrollIntoViewIfNeeded) {
      $slide.scrollIntoViewIfNeeded();
    } else if ($slide.scrollIntoView) {
      $slide.scrollIntoView();
    }
  }, [dropdown_data]);

  const onClick = (items: DailysummaryType) => {
    toggleDropDown();
    onChange(items);
  };
  return (
    <div className="c-select-holder">
      <div className="c-selected-text">{perSelected?.facility_name}</div>
      <div
        className={`c-options-holder scroll ${isOpen ? "active" : ""}`}
        id="slideControls"
      >
        <div className="c-option-wraper">
          {dropdown_data.map((items: any, index) => {
            let isActive = items?._id === perSelected?._id;
            return (
              <a
                key={`${items?._id} ${index}`}
                className={`options ${isActive ? "active" : ""}`}
                href={`#snap_item_${index}`}
                onClick={(e) => {
                  e.preventDefault();
                  onClick(items);
                }}
              >
                {items?.name}
              </a>
            );
          })}
        </div>
      </div>
      <div
        className={`over-lay ${isOpen ? "active" : ""}`}
        onClick={() => toggleDropDown()}
      ></div>
    </div>
  );
};

type CensusHeaderProps = {
  facilities: DailysummaryType[];
  onDateChange: (date: Date) => void;
  showBellNotification: boolean;
  issuesCount: number;
  dateValue: string;
  getSelectedFacility: (selectedFacility: any) => void;
  currentFacility: any;
  resetShowBellNotification: () => void;
};

const CensusHeader: React.FC<CensusHeaderProps> = ({
  facilities,
  onDateChange,
  showBellNotification,
  issuesCount,
  dateValue,
  getSelectedFacility,
  currentFacility,
  resetShowBellNotification,
}) => {
  // console.log("facilities-cenheader",facilities)
  // console.log("current-facilty-header",currentFacility)
  // const [swiper, setSwiper] = useState<any | null>(null);
  const router = useIonRouter();
  const selectedFacility = currentFacility;

  const encounterList = useAppSelector((state) => state.census.patientsList);

  let facilityFromDashboard = useParams<{ id?: string }>();

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [scrollActive, setScrollActive] = useState(false);

  const toggleDropDown = () => {
    setIsDropDownOpen(false);
  };

  const onFacilitySelect = (items: DailysummaryType) => {
    getSelectedFacility(items);
  };

  const handleNotificationBox = () => {
    router.push("/providerhold", "forward");
    resetShowBellNotification();
  };

  const handleScrollSet = (e: any, item: any) => {
    if (scrollActive) {
      getSelectedFacility(item);
    }
    setScrollActive(false);
  };

  const handleDateChange = (date: any) => {
    setIsDropDownOpen(false);
    onDateChange(date);
  };
  const [newFacilityOpen, setNewFacilityOpen] = useState(false);
  // useEffect(() => {
  //   getSelectedFacility(selectedFacility);
  // }, [selectedFacility]);

  // console.log({ selectedFacility });
  const closeModal = () => {
    setNewFacilityOpen(false);
  };
  return (
    <>
      <div className="top-container">
        {showBellNotification && (
          <div
            className="bell-notification-box"
            onClick={handleNotificationBox}
          >
            <IonText className="notification-text bold">
              You have {issuesCount} Issues
            </IonText>
            <br />
            <IonText className="notification-text">
              Apr. $1,560 receivables are missing.
            </IonText>
          </div>
        )}
        <div className="calander">
          <MobisrollCalendar
            onChange={(date) => handleDateChange(date)}
            dateValue={dateValue}
            setIsDropDownOpen={setIsDropDownOpen}
          />
        </div>
        <div className="scoll-shadow-holder">
          <div
            className={isDropDownOpen ? "snap-non-scroll" : "snap-scroll"}
            onScrollCapture={() => {
              setScrollActive(true);
            }}
          >
            {/* {facilities.map((item: any, i) => {
              return (
                <div
                  onMouseEnter={(e: any) => handleScrollSet(e, item)}
                  onTouchMove={(e: any) => handleScrollSet(e, item)}
                  className="snap-item"
                  id={`snap_item_${i}`}
                  key={`${item?._id} ${i}`}
                >
                  <div className="slide-item">
                    <div className="my-thin-row1-census">
                      <div className="my-thin-col1r1-census">
                        <h2
                          className="ion-text-census"
                          onClick={() => setIsDropDownOpen(true)}
                        >
                          {item?.name}
                        </h2>
                        <div className="icon-holder">
                          <IonIcon icon={caretDownOutline} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })} */}
            {selectedFacility && (
              <div
                onMouseEnter={(e: any) => handleScrollSet(e, selectedFacility)}
                onTouchMove={(e: any) => handleScrollSet(e, selectedFacility)}
                className="snap-item"
              >
                <div className="slide-item">
                  <div className="my-thin-row1-census">
                    <div className="my-thin-col1r1-census">
                      <h2
                        className="ion-text-census"
                        onClick={() => setNewFacilityOpen(true)}
                      >
                        {selectedFacility?.name}
                      </h2>
                      <div className="icon-holder">
                        <IonIcon
                          onClick={() => setNewFacilityOpen(true)}
                          className="backwardIconDownFilter"
                          icon={chevronDownOutline}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <IonModal
            className="newencounter-modal"
            isOpen={newFacilityOpen}
            onDidDismiss={closeModal}
            breakpoints={[0, 0.3, 0.4, 1]}
            initialBreakpoint={0.8}
            // backdropBreakpoint={0.2}
            style={{ overflowY: "scroll" }}
          >
            <div className="newencounter-opened-modal">
              <div className="header-holder">
                <div className="title-holder">
                  <IonLabel className="labelTitle-Facilitys">
                    Change Facility
                  </IonLabel>
                </div>
                <div className="btn-holder">
                  <IonIcon
                    className="close-icon-facilitys"
                    icon={closeOutline}
                    onClick={() => setNewFacilityOpen(false)}
                  ></IonIcon>
                </div>
              </div>

              <div className="c-select-holder">
                <div
                  style={{ height: "90vh", paddingTop: "15px" }}
                  className={"c-options-holder scroll active"}
                  id="slideControls"
                >
                  <div className="c-facility-wraper ">
                    <IonList className="ionlist">
                      {facilities.map((items: any, index) => {
                        let isActive = items?._id === selectedFacility?._id;
                        console.log(isActive, "isActive");
                        return (
                          <IonItem
                            key={`${items?._id} ${index} `}
                            color={isActive ? "primary" : ""}
                            lines="none"
                            className="ionitem"
                            onClick={(e) => {
                              e.preventDefault();
                              onFacilitySelect(items);
                              setNewFacilityOpen(false);
                            }}
                          >
                            <IonLabel className="filter-item-text">
                              {" "}
                              {items?.name}{" "}
                            </IonLabel>
                          </IonItem>
                        );
                      })}
                    </IonList>
                  </div>
                </div>
              </div>
            </div>
          </IonModal>

          {/* <div className="dropdown-holder">
            <SelectWithScroll
              dropdown_data={facilities}
              perSelected={selectedFacility}
              onChange={onFacilitySelect}
              toggleDropDown={toggleDropDown}
              isOpen={isDropDownOpen}
            />
          </div> */}
        </div>
        <div className="census-address-wrapper">
          {selectedFacility && (
            <p className="address-text">
              {`${selectedFacility?.address?.street_line_1} ${
                selectedFacility?.address?.street_line_1.length > 0 ? "," : ""
              }
                ${selectedFacility?.address?.street_line_2} ${
                selectedFacility?.address?.street_line_2.length ? 0 && "," : ""
              }
                ${selectedFacility?.address?.city}${
                selectedFacility?.address?.city.length > 0 ? "," : ""
              } ${selectedFacility?.address?.state}
                ${
                  selectedFacility?.address?.zip.length > 0
                    ? selectedFacility.address.zip.length > 5
                      ? ` ${selectedFacility.address.zip
                          .split("")
                          .splice(0, 5)
                          .join("")} - ${selectedFacility.address.zip
                          .split("")
                          .splice(5, selectedFacility.address.zip.length)
                          .join("")}`
                      : `${selectedFacility.address.zip
                          .split("")
                          .splice(0, 5)
                          .join("")}`
                    : ""
                }
              `}
            </p>
          )}
        </div>

        <div className="census-list-count-container">
          <div className="census-list-item-container">
            <div className="census-count">{encounterList.length}</div>
            <div className="census-head">All</div>
          </div>
          <div className="seperator"></div>
          <div className="census-list-item-container">
            <div className="census-count">
              {
                encounterList.filter(
                  (item: any) => item.statusGroup === "Completed"
                ).length
              }
            </div>
            <div className="census-head">Completed</div>
          </div>
          <div className="seperator"></div>
          <div className="census-list-item-container">
            <div className="census-count">
              {
                encounterList.filter(
                  (item: any) => item.statusGroup === "Incomplete"
                ).length
              }
            </div>
            <div className="census-head">Incompleted</div>
          </div>
        </div>

        {/* <div className="search-bar-header">

          <div className="search-box-container-Header">
            <div className="search-box-census-Header">
              <SearchBox
                variant="light-round-Corner"
                onChange={debounce(setSearchText, 1000)}
                placeholder={"Search patient"}
                value={searchText}
              />

            </div>
            <div>
              <div
                style={{
                  width: 40,
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #626468",
                  borderRadius: "16px",
                  marginTop: "10px",
                  marginLeft: "10px",

                }}

              >
                <IconSvg Icon={SortIcon} />
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="price-holder">
          ${paymentTotal}
          <IonText className="price-holder-dimtext">(est) │</IonText>
          {rvuTotal}
          <IonText className="price-holder-dimtext">RVUs</IonText>
        </div> */}
      </div>
    </>
  );
};

export default CensusHeader;
