/* eslint-disable react-hooks/exhaustive-deps */
import { IonIcon } from "@ionic/react";
import { starOutline, starSharp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import "./FavoriteBtn.scss";

type FavoriteBtnProps = {
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  onCheck?: (isChecked: boolean) => void;
  smallDark?: boolean;
};

const FavoriteBtn: React.FC<FavoriteBtnProps> = ({
  disabled,
  checked,
  className,
  onCheck,
  smallDark,
}) => {
  const [isChecked, setIsChecked] = useState(checked ? true : false);

  useEffect(() => {
    if (onCheck) {
      onCheck(isChecked);
    }
  }, [isChecked]);

  return disabled ? (
    <div>-</div>
  ) : (
    <div
      className={`fav-btn-holder ${className ? className : ""} ${
        disabled ? "disabled" : ""
      }`}
    >
      <button
        className={`fav-btn ${smallDark ? "small-black-icon" : ""}`}
        onClick={() => (!disabled ? setIsChecked(!isChecked) : null)}
      >
        <IonIcon icon={isChecked ? starSharp : starOutline} />
      </button>
    </div>
  );
};

export default FavoriteBtn;
