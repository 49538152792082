/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { IonContent, IonIcon, IonRow } from "@ionic/react";
import "./OtpVerify.scss";
import { arrowBack } from "ionicons/icons";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectUser } from "../../store/reducers/user";
import { mfaLogin } from "../../store/thunk/userThunk";
import { Layout } from "../../components/Layout/Layout";
const OtpVerify: React.FC = () => {
  const userData = useAppSelector(selectUser);

  const dispatch = useAppDispatch();

  const [pin, setPin] = useState("");
  const [pinOne, setPinOne] = useState("pinOff");
  const [pinTwo, setPinTwo] = useState("pinOff");
  const [pinThree, setPinThree] = useState("pinOff");
  const [pinFour, setPinFour] = useState("pinOff");
  const [pinFive, setPinFive] = useState("pinOff");
  const [pinSix, setPinSix] = useState("pinOff");
  const [error, setError] = useState({ status: false, message: "" });

  const getPin = (data: string) => {
    if (pin.length < 6) {
      setPin(pin + data);
      if ((pin + data).length === 1) {
        setPinOne("pinOn");
      }
      if ((pin + data).length === 2) {
        setPinTwo("pinOn");
      }
      if ((pin + data).length === 3) {
        setPinThree("pinOn");
      }
      if ((pin + data).length === 4) {
        setPinFour("pinOn");
      }
      if ((pin + data).length === 5) {
        setPinFive("pinOn");
      }
      if ((pin + data).length === 6) {
        setPinSix("pinOn");
      }
    }
  };

  useEffect(() => {
    if (pin.length === 6) {
      checkOTP();
    }
  }, [pin]);

  const checkOTP = async () => {
    setError({ status: false, message: "" });
    let res = await dispatch(
      mfaLogin({ "mfa-token": userData.mfatoken, code: pin })
    );
    if (res?.payload === "invalid otp") {
      setError({ status: true, message: res?.payload });
      setPin("");
      setPinOne("pinOff");
      setPinTwo("pinOff");
      setPinThree("pinOff");
      setPinFour("pinOff");
      setPinFive("pinOff");
      setPinSix("pinOff");
    } else {
      setError({ status: false, message: "" });
    }
  };

  console.log({ userData });

  const removePin = () => {
    setError({ status: false, message: "" });
    setPin(pin.slice(0, -1));
    if (pin.slice(0, -1).length === 5) {
      setPinSix("pinOff");
    }
    if (pin.slice(0, -1).length === 4) {
      setPinFive("pinOff");
    }
    if (pin.slice(0, -1).length === 3) {
      setPinFour("pinOff");
    }
    if (pin.slice(0, -1).length === 2) {
      setPinThree("pinOff");
    }
    if (pin.slice(0, -1).length === 1) {
      setPinTwo("pinOff");
    }
    if (pin.slice(0, -1).length === 0) {
      setPinOne("pinOff");
    }
  };

  return (
    <Layout isLoading={userData?.isloading} isError={userData?.isError}>
      <IonContent text-center className="login-page">
        <div className="page-holder">
          <p className="labelTitle">Verify your Otp!</p>

          <div className="pins">
            <div className={pinOne}></div>
            <div className={pinTwo}></div>
            <div className={pinThree}></div>
            <div className={pinFour}></div>
            <div className={pinFive}></div>
            <div className={pinSix}></div>
          </div>

          {error?.status && (
            <IonRow>
              <span className="errormsg">{error?.message} !</span>
            </IonRow>
          )}
          <div className="keypadHolder">
            <div className="keypad">
              <button onClick={() => getPin("1")}>1</button>
              <button onClick={() => getPin("2")}>2</button>
              <button onClick={() => getPin("3")}>3</button>
              <button onClick={() => getPin("4")}>4</button>
              <button onClick={() => getPin("5")}>5</button>
              <button onClick={() => getPin("6")}>6</button>
              <button onClick={() => getPin("7")}>7</button>
              <button onClick={() => getPin("8")}>8</button>
              <button onClick={() => getPin("9")}>9</button>
              <button onClick={() => getPin("0")}>0</button>
              <button onClick={() => removePin()}>
                <IonIcon icon={arrowBack} className="ion-icon"></IonIcon>
              </button>
            </div>
          </div>
        </div>
      </IonContent>
    </Layout>
  );
};

export default OtpVerify;
