import {
  getEncounterData,
  getFacilities,
  getPatientsData,
  getRevenueDatas,
  getRvuData,
  getVisitStatuses,
  getVistByServiceDate,
} from "../../helper/backendHelper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { segregatePatients } from "../../helper/formater";

export const getPracticeOverview = createAsyncThunk(
  "dashboard/paracticeoverview",
  async (reqData: any, thunkAPI) => {
    const { currentDate, type } = reqData;

    const resPatients = await getPatientsData(currentDate, type);
    const resEncounter = await getEncounterData(currentDate, type);
    const resRevenue = await getRevenueDatas(currentDate, type);
    const resRvu = await getRvuData(currentDate, type);

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    if (resPatients?.status === 500) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      let responce = {};
      if (resPatients?.status === "SUCCESS") {
        let patientCount = 0,
          prevPatientCount = 0,
          ComparisonPercentage = 0;

        let tempPatients: any = [];
        resPatients?.result[0]?.previous?.forEach((row: any) => {
          prevPatientCount += row.total_count;
        });
        resPatients?.result[1]?.current?.forEach((row: any) => {
          patientCount += row.total_count;
          tempPatients.push(row.total_count);
        });

        ComparisonPercentage =
          ((patientCount - prevPatientCount) / prevPatientCount) * 100;

        responce = {
          ...responce,
          patients: {
            totalPatientCount: patientCount,
            prevTotalCount: prevPatientCount,
            data: tempPatients,
            comparisonPercentage: ComparisonPercentage
              ? Math.round((ComparisonPercentage + Number.EPSILON) * 100) / 100
              : 0,
          },
        };
      }

      if (resEncounter?.status === "SUCCESS") {
        let encountersCount = 0,
          prevEncountersCount = 0,
          ComparisonPercentage = 0;

        let tempEncounter: any = [];
        resEncounter?.result[0]?.previous?.forEach((row: any) => {
          prevEncountersCount += row.total_count;
        });
        resEncounter?.result[1]?.current?.forEach((row: any) => {
          encountersCount += row.total_count;
          tempEncounter.push(row.total_count);
        });

        ComparisonPercentage =
          ((encountersCount - prevEncountersCount) / prevEncountersCount) * 100;

        responce = {
          ...responce,
          encounters: {
            totalencountersCount: encountersCount,
            prevTotalCount: prevEncountersCount,
            data: tempEncounter,
            comparisonPercentage: ComparisonPercentage
              ? Math.round((ComparisonPercentage + Number.EPSILON) * 100) / 100
              : 0,
          },
        };
      }

      if (resRevenue?.status === "SUCCESS") {
        let revenueCount = 0,
          prevRevenueCount = 0,
          ComparisonPercentage = 0;

        let tempRevenue: any = [];
        resRevenue?.result[0]?.previous?.forEach((row: any) => {
          prevRevenueCount += row.total_count;
        });
        resRevenue?.result[1]?.currrent?.forEach((row: any) => {
          revenueCount += row.total_count;
          tempRevenue.push(row.total_count);
        });

        ComparisonPercentage =
          ((revenueCount - prevRevenueCount) / prevRevenueCount) * 100;

        responce = {
          ...responce,
          revenue: {
            totalrevenueCount: formatter.format(revenueCount),
            prevTotalCount: prevRevenueCount,
            data: tempRevenue,
            comparisonPercentage: ComparisonPercentage
              ? Math.round((ComparisonPercentage + Number.EPSILON) * 100) / 100
              : 0,
          },
        };
      }
      if (resRvu?.status === "SUCCESS") {
        let rvuCount = 0,
          prevrvuCount = 0,
          ComparisonPercentage = 0;

        let temprvu: any = [];
        resRvu?.result[0]?.previous?.forEach((row: any) => {
          prevrvuCount += row.total_count_of;
        });
        resRvu?.result[1]?.current?.forEach((row: any) => {
          rvuCount += row.total_count_of;
          temprvu.push(row.total_count_of);
        });

        ComparisonPercentage = ((rvuCount - prevrvuCount) / prevrvuCount) * 100;

        responce = {
          ...responce,
          rvu: {
            totalrvuCount: Math.round((rvuCount + Number.EPSILON) * 100) / 100,
            prevTotalCount:
              Math.round((prevrvuCount + Number.EPSILON) * 100) / 100,
            data: temprvu,
            comparisonPercentage: ComparisonPercentage
              ? Math.round((ComparisonPercentage + Number.EPSILON) * 100) / 100
              : 0,
          },
        };
      }

      return responce;
    }
  }
);

export const getFacilitiesList = createAsyncThunk(
  "census/getFacilities",
  async (_, thunkAPI) => {
    try {
      const resFacilities = await getFacilities();
      // console.log('getFacilities', resFacilities)
      if (resFacilities?.status === 500) {
        return thunkAPI.rejectWithValue("Server Error");
      } else {
        if (resFacilities?.status === "success") {
          // console.log(resFacilities.results)
          return {
            allFacilitiesList: resFacilities.results.filter(
              (fac: { enabled: any }) => fac.enabled
            ),
          };
        }
      }
    } catch (err) {
      return thunkAPI.rejectWithValue("Server Error");
    }
  }
);

type getVisitsCensusProps = {
  servicedate: string;
  thisProvider?: any;
};

export const getInpatientsEncounters = createAsyncThunk(
  //action type string
  "census/getInpatientsEncounters",
  // callback function
  async (data: getVisitsCensusProps, thunkAPI) => {
    let resVisits = await getVistByServiceDate(data.servicedate);
    let resStatuses = await getVisitStatuses();

    if (resVisits?.status === 500) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      if (resVisits?.status === "success") {
        let finalList = segregatePatients(
          resVisits?.resData,
          resStatuses?.results?.[0].values
        );

        return {
          patientsList: finalList?.patientsList,
        };
      }
    }
  }
);
