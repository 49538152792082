/* eslint-disable react-hooks/exhaustive-deps */
import { IonIcon, IonRow } from "@ionic/react";
import { checkmarkDone } from "ionicons/icons";
import React from "react";

const ProgressBarVertical: React.FC<any> = (props) => {
  const { data, activeId } = props;

  return (
    <>
      <IonRow>
        {data.map((item: any, index: number) => (
          <>
            <div key={item.id}>
              <div
                className={`icon-container-main alignmentcenter ${
                  item.completed || item.id === activeId
                    ? "sucessfull-icon-container"
                    : "unsucessfull-icon-container"
                }`}
              >
                {item.completed === true ? (
                  <IonIcon icon={checkmarkDone}></IonIcon>
                ) : (
                  <strong>{index + 1}</strong>
                )}
              </div>
            </div>
            <div
              className={`label-text ${
                item.completed || item.id === activeId
                  ? "sucessfull-label"
                  : "unsucessfull-label"
              }`}
            >
              {item.name}
            </div>
            {index < data.length - 1 && <div className="process-path"></div>}
          </>
        ))}
      </IonRow>
    </>
  );
};

export default ProgressBarVertical;
