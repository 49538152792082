import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  useIonLoading,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonText,
  IonFooter,
} from "@ionic/react";
import {
  checkmarkOutline,
  chevronBackOutline,
  closeOutline,
  star,
  starOutline,
  trashOutline,
} from "ionicons/icons";
import "./RemoveDiagnosis.scss";
import { useAppSelector } from "../../../store/hooks";
// import Toast, { newToast } from "../../../components/Common/Toaster/Toast";

// type ToastedList= {
//   id: number;
//   description: string,
// };

const RemoveDiagnosis: React.FC<any> = ({
  isOpen,
  setIsOpen,
  selectedDiagnosis,
  setSelectedDiagnosis,
}) => {
  const [present, dismiss] = useIonLoading();
  // const [toastedMessage,setToastedMessage] = useState<ToastedList[]>([]);
  const [selected, setSelected] = useState<any>([]);
  const darkThemeBoolean = useAppSelector((state) => state.layout.darkTheme);

  useEffect(() => {
    if (isOpen) {
      present({
        message: "Loading...",
        duration: 500,
      });
      dismiss();
      // let toastMsg =  newToast("Loading...","Info");
      //     setToastedMessage([...toastedMessage, toastMsg]); 
    }
  }, [isOpen]);

  // useEffect(() => {
  //   setSelected([...selectedDiagnosis]);
  // }, [selectedDiagnosis]);

  const closeModal = () => {
    setIsOpen(false);
  };
  const onSelect = (i: any) => {
    let isSelected = selected.some((code: any) => i.code === code.code);

    if (isSelected) {
      let temp = selected?.filter((code: any) => {
        let isSelected = i.code !== code.code;

        return isSelected;
      });
      setSelected(temp);
    } else {
      setSelected([...selected, i]);
    }
  };

  const removeSelected = () => {
    setSelectedDiagnosis(selected);
    setSelected([]);
    setIsOpen(false);
  };

  return (
    <>
    {/* <Toast toastList={toastedMessage} position="bottom-left" autoDelete={true} autoDeleteTime={3000}/> */}
      {selectedDiagnosis && selectedDiagnosis.length > 0 && (
        <IonIcon
          className="trash-icon"
          onClick={() => setIsOpen(true)}
          icon={trashOutline}
          size="sm"
        ></IonIcon>
      )}
      <IonModal
        className="remove_diagnosis"
        isOpen={isOpen}
        onDidDismiss={closeModal}
        initialBreakpoint={0.8}
        // backdropBreakpoint={0.2}
      >
        <IonHeader mode="ios" className="remove_diagnosis_header">
          <IonToolbar className="remove_diagnosis_toolbar">
            <IonTitle className="remove_diagnosis_title">
              Remove diagnosis codes
            </IonTitle>
            <IonButtons>
              <IonButton
                onClick={(e) => {
                  setIsOpen(false);
                }}
              >
                <IonIcon icon={chevronBackOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className="remove_diagnosis_content">
          <div className="header_text_wrapper">
            <IonText className="content_header_text">
              Tap to select codes
            </IonText>
          </div>

          <div className="content_list">
            {selectedDiagnosis?.map((item: any, i: any) => (
              <div
                className={
                  selected?.some((code: any) => item.code === code.code)
                    ? "content_list_item selected"
                    : "content_list_item"
                }
                key={i}
                onClick={(e) => {
                  onSelect(item);
                }}
              >
                <div
                  className={
                    item?.favorite
                      ? "content_list_itemIcon favourite"
                      : "content_list_itemIcon"
                  }
                >
                  <IonIcon
                    icon={
                      item?.favorite === true
                        ? star
                        : !darkThemeBoolean
                        ? starOutline
                        : star
                    }
                  />
                </div>
                <div className="content_list_description">
                  <IonText className="content_list_itemText">
                    {item?.code}
                  </IonText>
                  <IonText className="content_list_itemPara">
                    {item?.description}
                  </IonText>
                </div>
                <div className="content_list_itemCheckIcon">
                  {selected?.some((code: any) => item.code === code.code) && (
                    <IonIcon icon={checkmarkOutline} />
                  )}
                </div>
              </div>
            ))}
          </div>
          <IonFooter className="rm_diag_footer">
            <IonButton
              className="rm_diag_footer_button"
              onClick={(e) => {
                removeSelected();
              }}
            >
              <IonIcon icon={closeOutline} size="12" />
              <p>Remove diagnosis</p>
              <div className="rm_diag_footer_num">{selected.length}</div>
            </IonButton>
          </IonFooter>
        </IonContent>
      </IonModal>
    </>
  );
};

export default RemoveDiagnosis;
