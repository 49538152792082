import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { selectUser } from "../../store/reducers/user";

type FeatureProviderProps = {
    feature?: string;
  };
  
  const FeatureProvider: React.FC<FeatureProviderProps> = ({
    children,
    feature,
  }) => {
    const [isGranded, setIsGranded] = useState(false);
    const userData = useAppSelector(selectUser);
    useEffect(() => {
        let featureData = userData.features;
        if (featureData.includes(feature)==true) {            
          setIsGranded(true);
        }
      }, [feature]);
      
    return (<>{isGranded ? children : null}</>);
  };
  export default FeatureProvider;
  