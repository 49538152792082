import React from "react";
import Chart from "../Chart/Chart";

interface BarsDisplayProps {
  income?: any;
  cardData?: any;
}

const BarsDisplay: React.FC<BarsDisplayProps> = ({ income, cardData }) => {
  return <Chart dataPoints={cardData} />;
};

export default BarsDisplay;
