/* eslint-disable react-hooks/exhaustive-deps */
import { IonCol, IonRow } from "@ionic/react";
import "./Payment.scss";

interface DataPropsType {
  description: string;
  date: string;
  days: number;
}
interface HistoryTablePropsType {
  header: string[];
  columnSize: string[];
  data: DataPropsType[];
}

const HistoryTable: React.FC<any> = (props: HistoryTablePropsType) => {
  return (
    <>
      <IonRow className="table-heading">
        {props.header.map((item: any, index: number) => (
          <IonCol
            key={`${item}+${index}`}
            size={props.columnSize[index]}
            className="table-sub-heading"
          >
            {item}
          </IonCol>
        ))}
      </IonRow>

      {props.data.map((item: any, index: any) => (
        <div key={`${item.description} ${index}`}>
          <div className="table-border"></div>
          <IonRow className="table-item">
            <IonCol size="2">{item.date}</IonCol>
            <IonCol size="8">{item.description}</IonCol>
            <IonCol className="alignmentright" size="1">
              {item.days?.length === 0 ? "-" : item.days}
            </IonCol>
          </IonRow>
        </div>
      ))}
    </>
  );
};

export default HistoryTable;
