/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonSlides,
  IonSlide,
  IonLabel,
  IonSelect,
  IonSelectOption,
  // IonTextarea,
  IonTitle,
  IonToolbar,
  IonItem,
  IonToast,
  useIonRouter,
  IonSegment,
  IonSegmentButton,
  // useIonRouter,
} from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";

import "./ProgressNote.scss";
import Button from "../../components/Common/Buttons/Buttons";
// import SkipModal from "./skipModal";
// import ProgressNoteCard from "./progressNoteCard";
import progressbarData from "../../mock/progressbarData.json";
import ProgressBar from "../../components/Common/ProgressBar/ProgressBar";
import {
  billVisitSubmit,
  putProgressNoteDiagnosiscodes,
  putProgressNoteProcedures,
  saveChartProgressNote,
  signChartProgressNote,
} from "../../helper/backendHelper";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  resetProgressNoteState,
  setHaveChart,
  setInvalide,
  // setProgressBarItems,
  setSelectedTemplate,
} from "../../store/reducers/progressNote";
import { useParams } from "react-router";
import { getPatient } from "../../store/thunk/patientThunk";
import {
  getChartData,
  getInitialPayload,
} from "../../store/thunk/progressNoteThunk";
import DynamicInputFields from "./DynamicInputFields";
import { DataEntity } from "../../types/MedicalchartTemplatesType";
import CheckBox from "../../components/Common/CheckBox/CheckBox";
import { formatProgressBarItems } from "../../helper/formater";
import { Layout } from "../../components/Layout/Layout";
import moment from "moment";

type TabInputGroupProps = {
  item: DataEntity;
  wholeData?: (DataEntity | null)[] | null;
  index: number;
  onChange: (data: any) => void;
  disable?: boolean;
};

const TabInputGroup: React.FC<TabInputGroupProps> = ({
  item,
  index,
  wholeData,
  onChange,
  disable,
}) => {
  const [excludeSection, setExcludeSection] = useState(
    item.skip ? false : true
  );
  const onCheckboxClick = (data: boolean) => {
    if (wholeData) {
      let tempData: any = [...wholeData];
      tempData[index] = { ...item, skip: !data };
      // console.log({ ...item, skip: !data });
      // console.log(tempData[index]);

      onChange(tempData);
    }

    setExcludeSection(data);
  };

  const inputDataOnChange = (dataVal: any) => {
    // console.log({ dataVal });

    if (wholeData) {
      let tempData: any = [...wholeData];
      tempData[index] = { ...item, sections: dataVal };
      onChange(tempData);
    }
  };

  // console.log({ item });

  return (
    <>
      {item.optional && (
        <div className="input-group-holder" style={{ marginTop: "1rem" }}>
          <CheckBox
            className="input-box"
            checked={excludeSection}
            onCheck={(data) => onCheckboxClick(data)}
            strictMode={true}
            label={`Include section`}
            disabled={disable}
          />
        </div>
      )}

      {excludeSection || !item.optional ? (
        item.sections?.map((section, index) => (
          <DynamicInputFields
            key={index}
            index={index}
            section={section}
            wholeData={item.sections}
            inputDataOnChange={inputDataOnChange}
            disabled={disable}
            isRequired={!item.optional}
          />
        ))
      ) : (
        <div className="status-badge">
          <IonItem key={index}>
            <IonLabel> This section is excluded from the note </IonLabel>
          </IonItem>
        </div>
      )}
    </>
  );
};

const ProgressNote: React.FC = () => {
  let quary = useParams<{ id?: string }>();

  const dispatch = useAppDispatch();
  const router = useIonRouter();

  const progressNoteState = useAppSelector((state) => state.progressNote);

  const [selectedSegment, setSelectedSegment] = useState("intial");
  const [initialEncounter, setInitialEncounter] = useState(true);
  const [btnval, setBtnVal] = useState(false);
  const [progressBarItems, setprogressBarData] = useState(progressbarData);
  const [isOpen, setIsOpen] = useState(false);
  const [showUploadCard, setShowUploadCard] = useState(false);

  const [isSubmited, setIsSubmited] = useState(false);

  const [toastMsg, setToastMsg] = useState("Progress Note saved Succesfully");

  const [slideOpts, setSlideOpts] = useState({
    initialSlide: 1,
    speed: 400,
    navigation: false,
  });

  const sliderRef = useRef<any>(null);
  const currentProvider: any = useAppSelector((state) => state.user.provider);

  // console.log({ selectedSegment });

  useEffect(() => {
    if (quary?.id) {
      if (quary?.id !== null) {
        dispatch(getInitialPayload(quary?.id));
      }
    }
    return () => {
      dispatch(resetProgressNoteState());
      setprogressBarData(progressbarData);
    };
  }, [quary?.id]);

  const onSelectTemplate = (temp: string) => {
    if (!progressNoteState.finalPayload) {
      let thisTemp = progressNoteState.medicalchartTemplates?.find(
        (item) => item._id === temp
      );

      if (thisTemp) {
        let newProgress = { ...progressbarData };

        newProgress.data = [
          ...newProgress.data,
          ...formatProgressBarItems(thisTemp?.data ? thisTemp?.data : []),
        ];

        setprogressBarData(newProgress);
        dispatch(setSelectedTemplate(thisTemp));
      }
    }
  };

  useEffect(() => {
    if (progressNoteState.isHaveChart) {
      let newProgress = { ...progressbarData };

      newProgress.data = [
        ...newProgress.data,
        ...formatProgressBarItems(
          progressNoteState.selectedTemplate?.data
            ? progressNoteState.selectedTemplate?.data
            : []
        ),
      ];
      dispatch(setHaveChart(false));

      setprogressBarData(newProgress);
    }
  }, [progressNoteState.selectedTemplate, progressNoteState.isHaveChart]);

  useEffect(() => {
    if (progressNoteState?.finalPayload !== null) {
      if (progressNoteState.finalPayload?.initial_encounter) {
        setInitialEncounter(true);
        setSelectedSegment("intial");
      } else {
        setInitialEncounter(false);
        setSelectedSegment("subsequent");
      }
    }
  }, [progressNoteState.finalPayload?.initial_encounter]);

  const onDataChange = (data: any) => {
    // console.log({ data });

    dispatch(
      setSelectedTemplate({ ...progressNoteState.selectedTemplate, data })
    );
  };

  // console.log({ initialEncounter });

  // useEffect(() => {
  //   console.log({ progressNoteState, progressBarItems });
  // }, [progressNoteState, progressBarItems]);

  const onSliderSwip = async (e: any) => {
    let activeIndex = await e?.target?.getActiveIndex();
    let previousIndex = await e?.target?.getPreviousIndex();

    let newProgress = { ...progressBarItems };

    newProgress.data[activeIndex + 1].completed = true;

    if (activeIndex < previousIndex) {
      newProgress.data[previousIndex + 1].completed = false;
    }

    if (
      progressBarItems.activeId >= 2 &&
      progressBarItems.data.length > progressBarItems.activeId
    ) {
      if (!progressNoteState.isInputDisable && quary?.id) {
        let progressPayload = {
          patient_id: progressNoteState.thisPatient?._id,
          initial_encounter: initialEncounter ? "true" : "false",
          diagnosisCodes: progressNoteState.diagnosisCodesSelected,
          procedureCodes: progressNoteState.procedureCodesSelected,
          ...progressNoteState.selectedTemplate,
        };

        console.log({ progressPayload });

        saveChartProgressNote(quary?.id, progressPayload).then((res) => {
          if (res?.status === "success") {
            setIsSubmited(true);
            if (
              quary?.id &&
              progressBarItems.activeId === progressBarItems.data.length - 2
            ) {
              dispatch(getChartData(quary?.id));
            }
          }
        });
      }
    }

    newProgress.activeId = activeIndex + 2;

    setprogressBarData(newProgress);

    // if (!$slide) return;
  };

  const onNext = () => {
    if (progressBarItems.activeId === 1) {
      let newProgress = { ...progressBarItems };

      newProgress.data[1].completed = true;

      newProgress.activeId = 2;

      setprogressBarData(newProgress);
    } else if (
      progressBarItems.activeId >= 2 &&
      progressBarItems.data.length > progressBarItems.activeId
    ) {
      let newProgress = { ...progressBarItems };

      if (!progressNoteState.isInputDisable && quary?.id) {
        let progressPayload = {
          patient_id: progressNoteState.thisPatient?._id,
          initial_encounter: initialEncounter ? "true" : "false",
          diagnosisCodes: progressNoteState.diagnosisCodesSelected,
          procedureCodes: progressNoteState.procedureCodesSelected,
          ...progressNoteState.selectedTemplate,
        };

        saveChartProgressNote(quary?.id, progressPayload).then((res) => {
          if (res?.status === "success") {
            setIsSubmited(true);
            if (
              quary?.id &&
              progressBarItems.activeId === progressBarItems.data.length - 2
            ) {
              dispatch(getChartData(quary?.id));
            }
          }
        });
      }

      newProgress.data[progressBarItems.activeId].completed = true;

      newProgress.activeId = progressBarItems.activeId + 1;

      setprogressBarData(newProgress);

      sliderRef.current.slideTo(progressBarItems.activeId - 1);
    }
  };

  useEffect(() => {
    if (
      progressBarItems.activeId >= 2 &&
      progressBarItems.data.length > progressBarItems.activeId
    ) {
      const $slide: any = document.querySelector(
        `#progress-scroller-${progressBarItems.activeId}`
      );

      if (!$slide) return;

      if ($slide.scrollIntoViewIfNeeded) {
        $slide.scrollIntoViewIfNeeded();
      } else if ($slide.scrollIntoView) {
        $slide.scrollIntoView();
      }
    }
  }, [progressBarItems.activeId]);

  useEffect(() => {
    if (sliderRef.current != null && progressBarItems.activeId === 2) {
      sliderRef.current.slideTo(0);
    }
  }, [sliderRef.current, progressBarItems.activeId]);

  const onSignAndBill = () => {
    setToastMsg("Progress Note signed and Bill submited");
    if (quary?.id) {
      signChartProgressNote(quary?.id)
        .then((res) => {
          if (res?.status === "success") {
            billVisitSubmit(quary?.id).then((resp) => {
              if (resp?.status === "success") {
                setIsSubmited(true);
                router.goBack();
              } else {
                router.goBack();
              }
            });
          }
        })
        .catch((e) => {
          router.goBack();
        });
    }
  };

  useEffect(() => {
    let diagnosis_codes = progressNoteState.diagnosisCodesSelected.map(
      (item) => item?.code
    );

    if (
      !progressNoteState.isloading &&
      quary?.id &&
      !progressNoteState.isInputDisable &&
      progressNoteState.diagnosisCodesSelected.length > 0
    ) {
      putProgressNoteDiagnosiscodes(quary?.id, { diagnosis_codes }).then(
        (res) => {
          if (res?.status === "success" && quary?.id) {
            dispatch(getPatient(quary?.id));
          }
        }
      );
    }
  }, [
    progressNoteState.diagnosisCodesSelected,
    progressNoteState.isInputDisable,
  ]);

  useEffect(() => {
    let procedures = progressNoteState.procedureCodesSelected.map((item) => ({
      code: item?.code,
      modifiers: item?.modifiers,
    }));

    if (
      !progressNoteState.isloading &&
      quary?.id &&
      !progressNoteState.isInputDisable &&
      progressNoteState.procedureCodesSelected.length > 0
    ) {
      putProgressNoteProcedures(quary?.id, { procedures }).then((res) => {
        if (res?.status === "success" && quary?.id) {
          dispatch(getPatient(quary?.id));
        }
      });
    }
  }, [
    progressNoteState.procedureCodesSelected,
    progressNoteState.isInputDisable,
  ]);

  useEffect(() => {
    if (
      progressNoteState.procedureCodesSelected.length < 1 ||
      progressNoteState.diagnosisCodesSelected.length < 1
    ) {
      dispatch(setInvalide(false));
    } else {
      dispatch(setInvalide(true));
    }
  }, [
    progressNoteState.diagnosisCodesSelected,
    progressNoteState.procedureCodesSelected,
  ]);

  useEffect(() => {
    if (sliderRef != null) {
      sliderRef.current?.lockSwipes(!progressNoteState.isValidate);
    }
  }, [progressNoteState.isValidate]);

  // console.log({ progressBarItems });
  const selectionHandler = (value: any) => {
    if (value === "intial") {
      setSelectedSegment("intial");
      setInitialEncounter(true);
    } else if (value === "subsequent") {
      setSelectedSegment("subsequent");
      setInitialEncounter(false);
    }
  };

  // console.log({ progressNoteState });

  // console.log(
  //   "test",
  //   moment(progressNoteState.thisPatient?.service_date_from).isAfter(
  //     moment(),
  //     "day"
  //   )
  // );

  // console.log(
  //   moment(progressNoteState.thisPatient?.service_date_from).endOf("day"),
  //   "vs",
  //   moment().endOf("day")
  // );

  return (
    <Layout
      isLoading={progressNoteState?.isloading}
      isError={progressNoteState?.isError}
      errorMsg={progressNoteState.errorMsg}
    >
      <IonHeader>
        <IonToolbar className="practice-toolbar" mode="ios">
          <IonButtons slot="start">
            <IonBackButton text={``} className="back-btn" />
          </IonButtons>
          <IonTitle className="title-header-main">
            {progressNoteState.thisPatient?.patient_firstname},
            {progressNoteState.thisPatient?.patient_lastname}
          </IonTitle>
          <div slot="end" className="progress-ellips">
            <IonIcon icon={ellipsisVertical}></IonIcon>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonToast
        isOpen={isSubmited}
        onDidDismiss={() => setIsSubmited(false)}
        message={toastMsg}
        duration={1000}
      />
      <IonContent className="pn-content-holder pn-content">
        <ProgressBar
          variant="scroll"
          data={progressBarItems}
          useFile="ProgressNote"
          handleNext={(data, activeId) => {}}
        />
        {progressBarItems.activeId === 1 ? (
          <>
            <IonCard className="template-card">
              <div className="display-temp">
                <IonLabel className="template-lbl" slot="start">
                  Select template
                </IonLabel>
                <IonLabel className="skip-lbl" onClick={() => setIsOpen(true)}>
                  Skip
                </IonLabel>
                {/* <SkipModal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  setShowUploadCard={setShowUploadCard}
                /> */}
              </div>

              <IonCard className="pn-accordian-card">
                <IonSelect
                  placeholder={"Select Template"}
                  interface="popover"
                  className="templateSelect"
                  onIonChange={(e) => onSelectTemplate(e.detail.value)}
                  value={progressNoteState.selectedTemplate?._id}
                  disabled={progressNoteState.finalPayload ? true : false}
                >
                  {progressNoteState.medicalchartTemplates?.map(
                    (item, i: any) => (
                      <IonSelectOption value={item._id} key={i}>
                        {item.template_name}
                      </IonSelectOption>
                    )
                  )}
                </IonSelect>
              </IonCard>
            </IonCard>
            {progressBarItems.activeId < progressBarItems.data.length - 1 ? (
              <div className="intail-encounter-segment">
                <IonSegment
                  className="intial-segment"
                  mode="ios"
                  value={selectedSegment}
                  onIonChange={(e: any) => selectionHandler(e.detail.value)}
                >
                  <IonSegmentButton
                    className="ion-segment-btn"
                    value="intial"
                    mode="ios"
                  >
                    Intial
                  </IonSegmentButton>
                  <IonSegmentButton
                    className="ion-segment-btn"
                    value="subsequent"
                  >
                    Subsequent
                  </IonSegmentButton>
                </IonSegment>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div className="slide-tab-holder">
            <IonSlides
              pager={true}
              options={slideOpts}
              ref={sliderRef}
              onIonSlideWillChange={onSliderSwip}
            >
              {progressNoteState.selectedTemplate?.data?.map((item, index) => (
                <IonSlide key={index}>
                  <IonCard className="template-card-two">
                    <div className="slide-holder">
                      <IonLabel className="template-lbl-one">
                        {item.title}
                        <i className="imp-lbl">
                          {item.optional ? "" : "(Required)"}
                        </i>
                      </IonLabel>

                      <TabInputGroup
                        item={item}
                        index={index}
                        wholeData={progressNoteState.selectedTemplate?.data}
                        onChange={onDataChange}
                        disable={progressNoteState.isInputDisable}
                      />
                    </div>
                  </IonCard>
                </IonSlide>
              ))}
              <IonSlide>
                <IonCard className="template-card-two">
                  <div className="slide-holder">
                    <IonLabel className="template-lbl-one">
                      Note Preview
                    </IonLabel>
                    <div className="img-holder">
                      <picture>
                        {progressNoteState.progressImg && (
                          <img src={progressNoteState.progressImg} alt="Note" />
                        )}
                      </picture>
                    </div>
                  </div>
                </IonCard>
              </IonSlide>
            </IonSlides>
          </div>
        )}

        <div className="pn-action-holder">
          {progressBarItems.activeId === progressBarItems.data.length - 1 &&
          !progressNoteState?.isloading &&
          progressNoteState?.selectedTemplate != null ? (
            <div>
              {moment(progressNoteState.thisPatient?.service_date_from).isAfter(
                moment(),
                "day"
              ) ||
              progressNoteState.diagnosisCodesSelected.length === 0 ||
              (!currentProvider.medical_coding &&
                progressNoteState.procedureCodesSelected.length === 0) ? (
                <>
                  <div className="sumbit-error-msg">
                    <IonLabel className="lbl-continue">
                      Please resolve all errors to sign note and bill the visit
                    </IonLabel>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          ) : null}

          <>
            {!progressNoteState.isloading && selectedSegment !== "" && (
              <div className="pn-btn-holder">
                {progressBarItems.activeId <
                progressBarItems.data.length - 1 ? (
                  <Button
                    fullWidth
                    onClick={onNext}
                    disabled={
                      progressBarItems.data.length < 3 ||
                      !progressNoteState.isValidate
                    }
                  >
                    <IonLabel className="lbl-continue">Continue</IonLabel>
                  </Button>
                ) : !progressNoteState.isInputDisable &&
                  progressNoteState.selectedTemplate !== null ? (
                  <Button
                    fullWidth
                    onClick={onSignAndBill}
                    disabled={
                      progressNoteState.progressImg ||
                      progressNoteState.diagnosisCodesSelected.length !== 0 ||
                      (currentProvider.medical_coding &&
                        progressNoteState.procedureCodesSelected.length !== 0)
                        ? moment(
                            progressNoteState.thisPatient?.service_date_from
                          ).isAfter(moment(), "day") ||
                          progressNoteState.diagnosisCodesSelected.length ===
                            0 ||
                          (!currentProvider.medical_coding &&
                            progressNoteState.procedureCodesSelected.length ===
                              0)
                          ? true
                          : false
                        : true
                    }
                  >
                    <IonLabel className="lbl-continue">
                      Sign note and bill
                    </IonLabel>
                  </Button>
                ) : (
                  progressNoteState.finalPayload !== null && (
                    <>
                      <div className="status-msg">
                        This progress note already signed
                      </div>
                    </>
                  )
                )}
              </div>
            )}
          </>
        </div>
      </IonContent>
    </Layout>
  );
};

export default ProgressNote;
