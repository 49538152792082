/* eslint-disable react-hooks/exhaustive-deps */
import { IonIcon, IonRow } from "@ionic/react";
import { checkmarkDone } from "ionicons/icons";

const ProgressBarHorizontal: React.FC<any> = (props) => {
  const { data, activeId } = props;

  return (
    <>
      {/* <IonRow> */}
      {data?.map((item: any, index: number) => (
        <IonRow className="step-gap" key={item.id}>
          <span>
            <div
              className={`icon-container-main alignmentcenter ${
                item.completed || item.id === activeId
                  ? "sucessfull-icon-container"
                  : "unsucessfull-icon-container"
              }`}
            >
              {item.completed === true ? (
                <IonIcon icon={checkmarkDone}></IonIcon>
              ) : (
                <strong>{index + 1}</strong>
              )}
            </div>
          </span>
          <span
            className={`label-text ${
              item.completed || item.id === activeId
                ? "sucessfull-label"
                : "unsucessfull-label"
            }`}
          >
            {item.name}
          </span>
        </IonRow>
      ))}
    </>
  );
};
export default ProgressBarHorizontal;
