import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
} from "@ionic/react";
import { ellipsisHorizontal } from "ionicons/icons";
import moment from "moment";
import React, { useState, memo, useEffect } from "react";
import EditWidget from "../../../components/Common/EditWidget/EditWidget";
import { getTotalPatientsChartView } from "../../../helper/backendHelper";
import { dateRage, graphType } from "../TodayFacilityInfo";
import BarChart from "./BarChart";
import InfoPieChart from "./InfoPieChart";
import LineChart from "./LineChart";
import { facilityInfoChartDataFormat } from "../../../helper/formater";

interface TotalPatientsProps {
  todaysFacList: any[];
}

const TotalPatients: React.FC<TotalPatientsProps> = ({ todaysFacList }) => {
  const [editWidgetVisible, setEditWidgetVisible] = useState<boolean>(false);
  const [radioButtonData, setRadioButtonData] = useState<any>({
    buttons: dateRage,
    value: "1",
  });
  const [radioChartData, setRadioChartData] = useState<any>({
    buttons: graphType,
    value: "3",
  });
  const [chartData, setChartData] = useState({
    dataArray: [],
    lableArray: [],
    total_count: 0,
  });

  useEffect(() => {
    let currentDate = moment().format("YYYY-MM-DD");
    let type = radioButtonData?.buttons?.find(
      (item: any) => item?.value === radioButtonData?.value
    )?.key;
    let facility_id = todaysFacList
      ?.filter((item) => item?.selected === true)
      ?.map((item) => item?._id);

    if (facility_id?.length > 0) {
      getTotalPatientsChartView(currentDate, type, { facility_id }).then(
        (res: any) => {
          if (res?.status === "SUCCESS") {
            if (res?.result?.[0]?.previous?.length > 0) {
              setChartData(() =>
                facilityInfoChartDataFormat(res?.result?.[0]?.previous, type)
              );
            } else {
              setChartData(() => facilityInfoChartDataFormat([], type));
            }
          }
        }
      );
    } else {
      setChartData({ dataArray: [], lableArray: [], total_count: 0 });
    }
  }, [radioButtonData?.value, todaysFacList]);

  const handleUpdateFilter = (values: any, type: any) => {
    let options = {};
    if (type === "date") {
      options = { ...radioButtonData, value: values };
      setRadioButtonData(options);
    } else {
      options = { ...radioChartData, value: values };
      setRadioChartData(options);
    }
  };

  return (
    <div className="total-patients">
      <IonCard className="graph-card chart-card">
        <IonCardHeader className="graphcard-title-container">
          <IonCardTitle className=" chart-text-title mx-3 mt-3">
            <div className="left-section">
              <span>Total Patients</span>{" "}
              <span className="chart-text-title-count">
                {chartData?.total_count}
              </span>
            </div>
            <IonIcon
              className="graphcard-ellipsis"
              src={ellipsisHorizontal}
              onClick={() => setEditWidgetVisible(true)}
            ></IonIcon>
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <div className="chart">
            {radioChartData?.value === "1" ? (
              <InfoPieChart
                datas={chartData?.dataArray}
                lables={chartData?.lableArray}
                dataLable={"Patients"}
              />
            ) : radioChartData?.value === "2" ? (
              <BarChart
                datas={chartData?.dataArray}
                lables={chartData?.lableArray}
                dataLable={"Patients"}
              />
            ) : (
              <LineChart
                withHorizontalLabel={true}
                datas={chartData?.dataArray}
                lables={chartData?.lableArray}
                dataLable={"Patients"}
              />
            )}
          </div>
        </IonCardContent>
      </IonCard>

      <div className="edit-widget-container">
        <EditWidget
          openEditWidget={editWidgetVisible}
          setOpenEditWidget={setEditWidgetVisible}
          radioButtonData={radioButtonData}
          radioChartData={radioChartData}
          updateFilter={handleUpdateFilter}
        />
      </div>
    </div>
  );
};

export default memo(TotalPatients);
