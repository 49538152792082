/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonSearchbar,
  IonList,
  IonItem,
  IonIcon,
  IonModal,
  useIonLoading,
  IonLabel,
} from "@ionic/react";
import "./FacilitySearch.scss";
import { close } from "ionicons/icons";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getFacility } from "../../../store/thunk/facilityThunk";
import { pencilOutline } from "ionicons/icons";

import { FacilityObjType } from "../../../types/FacilityType";
// import Toast, { newToast } from "../Toaster/Toast";

type FacilitySearchProps = {
  onSelect: (item: FacilityObjType) => void;
};

// type ToastedList= {
//   id: number;
//   description: string,
// };
const FacilitySearch: React.FC<FacilitySearchProps> = ({ onSelect }) => {
  const facilityList: any = useAppSelector(
    (state) => state?.facility
  )?.facilities;
  const isLoading: Boolean = useAppSelector(
    (state) => state?.facility
  )?.isloading;

  const [searchText, setSearchText] = useState(""); //state for user input in search bar
  const [searchResult, setSearchResult] = useState(facilityList); //state to hold the result which we get after searching
  const [present, dismiss] = useIonLoading();
  const [open, setOpen] = useState(false);
  // const [toastedMessage,setToastedMessage] = useState<ToastedList[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    //logic to set search result
    setSearchResult(() =>
      facilityList?.filter((item: any) =>
        item?.name?.toLowerCase()?.includes(searchText.toLowerCase())
      )
    );
  }, [searchText, facilityList]);

  useEffect(() => {
    if (open) {
      present({
        message: "Loading...",
        duration: 500,
      });
      // let toastMsg =  newToast("Loading..","Info");
      //     setToastedMessage([...toastedMessage, toastMsg]);
      dispatch(getFacility());
      dismiss();
    }
  }, [open]);

  const setFacility = (item: FacilityObjType) => {
    onSelect(item);
    setSearchText("");
    setOpen(false);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <IonIcon
        onClick={() => setOpen(true)}
        icon={pencilOutline}
        size="sm"
      ></IonIcon>
      <IonModal
        className="facility dateModal"
        isOpen={open}
        onDidDismiss={closeModal}
        breakpoints={[0, 0.2, 0.5, 1]}
        initialBreakpoint={0.5}
        backdropBreakpoint={0.2}
      >
        <div>
          <IonHeader>
            <div className="header-wraper">
              <div className="header-holder">
                <div className="title-holder">
                  <IonLabel className="labelTitle">Facility</IonLabel>
                </div>

                <div className="btn-holder">
                  <IonIcon
                    icon={close}
                    className="ion-icon"
                    onClick={() => setOpen(false)}
                  ></IonIcon>
                </div>
              </div>
              <br />
              <IonSearchbar
                className="ionsearchbar"
                value={searchText}
                onIonChange={(e) => setSearchText(e.detail.value!)}
                showCancelButton="focus"
                cancelButtonText="X"
              ></IonSearchbar>
            </div>
          </IonHeader>
          <IonContent fullscreen>
            {isLoading === true ? (
              <></>
            ) : searchResult?.length > 0 && isLoading === false ? (
              <IonList className="ionlist">
                {searchResult.map((item: any, i: any) => (
                  <IonItem
                    key={i}
                    className="ionitem"
                    onClick={() => {
                      setFacility(item);
                    }}
                  >
                    <p>{item?.name.toUpperCase()}</p>
                  </IonItem>
                ))}
              </IonList>
            ) : (
              <div id="noResults">
                <p>No results found</p>
              </div>
            )}
          </IonContent>
        </div>
      </IonModal>
      {/* <Toast toastList={toastedMessage} position="bottom-left" autoDelete={true} autoDeleteTime={3000}/> */}
    </>
  );
};

export default FacilitySearch;
