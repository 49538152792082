import {
  IonAccordion,
  IonAccordionGroup,
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";
import Button from "../../components/Common/Buttons/Buttons";
import ProgressBar from "../../components/Common/ProgressBar/ProgressBar";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setProgressBarItems } from "../../store/reducers/progressNote";
import "../Subjective/Subjective.scss";
const Objective: React.FC = () => {
  const getProgressBarItems = useAppSelector(
    (state) => state.progressNote
  ).progressBarItems;
  const processbarData = getProgressBarItems;
  const dispatch = useAppDispatch();
  let newData = JSON.parse(JSON.stringify(processbarData));
  newData.data[0].completed = true;
  newData.data[1].completed = true;
  newData.data[2].completed = true;
  newData.data[3].completed = true;
  newData.activeId = newData?.data[4]?.id;
  const router = useIonRouter();
  const goToAssesment = () => {
    dispatch(setProgressBarItems(newData));
    router.push("/assesment", "back");
  };
  return (
    <IonPage className="subjective-page">
      <IonHeader mode="ios" className="subjective-header">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={""} className="back-icon" />
          </IonButtons>
          <IonTitle className="header-title">Smith, Andrew</IonTitle>
          <div slot="end" className="ellips-icon">
            <IonIcon icon={ellipsisVertical} />
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="subjective-content">
        <ProgressBar
          variant="scroll"
          data={processbarData}
          useFile="Objective"
          handleNext={(data, activeId) =>
            //console.log(data, activeId)
            {}
          }
        />
        <IonAccordionGroup>
          <IonCard className="accordian-card">
            <IonAccordion className="accordian">
              <IonItem className="title-container" lines="none" slot="header">
                <IonLabel className="card-title">Review of system</IonLabel>
              </IonItem>
              <IonList slot="content" className="card-content"></IonList>
            </IonAccordion>
          </IonCard>
          <IonCard className="accordian-card">
            <IonAccordion className="accordian">
              <IonItem className="title-container" lines="none" slot="header">
                <IonLabel className="card-title">Physical exam</IonLabel>
              </IonItem>
              <IonList slot="content" className="card-content"></IonList>
            </IonAccordion>
          </IonCard>
          <IonCard className="accordian-card">
            <IonAccordion className="accordian">
              <IonItem className="title-container" lines="none" slot="header">
                <IonLabel className="card-title">Data section</IonLabel>
              </IonItem>
              <IonList slot="content" className="card-content"></IonList>
            </IonAccordion>
          </IonCard>
        </IonAccordionGroup>
      </IonContent>
      <IonFooter className="subjective-footer">
        <Button fullWidth onClick={goToAssesment}>
          <IonLabel className="continue-txt">Continue</IonLabel>
        </Button>
        <div className="preview-btn">
          <IonLabel className="preview-txt">Note preview</IonLabel>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default Objective;
