import { useEffect } from "react";
import "./ProgressBar.scss"

type ProgressBarProps = {
    colors: string[];
    data: any;
}


export default function ProgressBarComp(props: ProgressBarProps) {

    useEffect(() => { }, [props.data])

    return (
        <div className="progress-container">
            {props.data && <div className="progress-content">
                {
                    props.data.map((item: any, index: number) => {
                        return <div key={index} style={{
                            width: `${item.percentage + "%"}`, background: `${props.colors[index]}`, height: "10px", borderTopRightRadius: `${index === props.data.length - 1 ? "4px" : "0px"}`,
                            borderBottomRightRadius: `${index === props.data.length - 1 ? "4px" : "0px"}`, borderTopLeftRadius: `${index === 0 ? "4px" : "0px"}`, borderBottomLeftRadius: `${index === 0 ? "4px" : "0px"}`
                        }}></div>
                    })
                }
            </div>}
        </div>
    )
}