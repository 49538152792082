import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import React, { useState } from "react";
import "./togglebuttonFilled.scss";

const ToggleButtonFilled: React.FC<any> = (props) => {
  const [selected, setSelected] = useState("Male");
  return (
    <IonSegment
      value={selected}
      className="buttnSegment"
      onIonChange={(e: any) => {
        setSelected(e.detail.value);
      }}
    >
      {props.data.arr.map((item: any, i: any) => (
        <IonSegmentButton
          value={item?.name}
          className={
            selected === item?.name ? "togglebttn active" : "togglebttn"
          }
        >
          <IonLabel>{item?.name}</IonLabel>
        </IonSegmentButton>
      ))}
    </IonSegment>
  );
};

export default ToggleButtonFilled;
