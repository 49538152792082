import React, { useState } from "react";
import LineChart from "../TodayFacilityInfo/LineChart";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from "@ionic/react";

// import "../DashBoard.scss";
import "./PracticeOverview.scss";
import { useAppSelector } from "../../../store/hooks";

const PracticeOverview = (props: any) => {
  const { patients, encounters, revenue, rvu } = useAppSelector(
    (state) => state.dashboard
  );
  const rolesCondition =
    props.roles.findIndex(
      (item: any) => item.groupname === "Claimocity Provider Revenue"
    ) >= 0
      ? true
      : false;

  return (
    <div className="total-patient">
      <IonCard className="graph-card chart-card ">
        <IonCardHeader className="graphcard-title-container ">
          <IonCardTitle className=" chart-text-title mx-3 mt-3">
            <span>Patients</span>
            <span className="chart-text-practice-title-count">
              {patients?.totalPatientCount}
            </span>
          </IonCardTitle>
          <IonCardSubtitle className="sub-title">
            {patients?.comparisonPercentage > 0 && "+"}
            {patients?.comparisonPercentage}% since last
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <div className="chart">
            <LineChart
              withHorizontalLabel={false}
              data={patients?.data}
              //   datas={chartData?.dataArray}
              //   lables={chartData?.lableArray}
              dataLable={"Patients"}
            />
          </div>
        </IonCardContent>
        <div className="see-details">See details</div>
      </IonCard>
      <IonCard className="graph-card chart-card">
        <IonCardHeader className="graphcard-title-container ">
          <IonCardTitle className=" chart-text-title mx-3 mt-3 ">
            <span>Encounters </span>
            <span className="chart-text-practice-title-count">
              {encounters?.totalencountersCount}
            </span>
          </IonCardTitle>
          <IonCardSubtitle className="sub-title">
            {encounters?.comparisonPercentage > 0 && "+"}
            {encounters?.comparisonPercentage}% since last{" "}
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <div className="chart">
            <LineChart
              withHorizontalLabel={false}
              data={encounters.data}
              //   datas={chartData?.dataArray}
              //   lables={chartData?.lableArray}
              dataLable={"Patients"}
            />
          </div>
        </IonCardContent>
        <div className="see-details">See details</div>
      </IonCard>
      {rolesCondition && (
        <IonCard className="graph-card chart-card">
          <IonCardHeader className="graphcard-title-container">
            <IonCardTitle className=" chart-text-title mx-3 mt-3">
              <span>Revenue </span>
              <span className="chart-text-practice-title-count ">
                {revenue?.totalrevenueCount}
              </span>
            </IonCardTitle>
            <IonCardSubtitle className="sub-title">
              {revenue?.comparisonPercentage > 0 && "+"}
              {revenue?.comparisonPercentage}% since last
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <div className="chart">
              <LineChart
                withHorizontalLabel={false}
                data={revenue.data}
                //   datas={chartData?.dataArray}
                //   lables={chartData?.lableArray}
                dataLable={"Patients"}
              />
            </div>
          </IonCardContent>
          <div className="see-details">See details</div>
        </IonCard>
      )}

      <IonCard className="graph-card chart-card">
        <IonCardHeader className="graphcard-title-container">
          <IonCardTitle className=" chart-text-title mx-3 mt-3">
            <span>RVU </span>
            <span className="chart-text-practice-title-count">
              {rvu?.totalrvuCount}
            </span>
          </IonCardTitle>
          <IonCardSubtitle className="sub-title">
            {rvu?.comparisonPercentage > 0 && "+"}
            {rvu?.comparisonPercentage}% since last{" "}
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <div className="chart">
            <LineChart
              withHorizontalLabel={false}
              data={rvu.data}
              //   datas={chartData?.dataArray}
              //   lables={chartData?.lableArray}
              dataLable={"Patients"}
            />
          </div>
        </IonCardContent>
        <div className="see-details">See details</div>
      </IonCard>
      {/* <IonGrid>
        <div>
          <LineChart />
          <LineChart />
        </div>
      </IonGrid> */}
    </div>
  );
};
export default PracticeOverview;
