import React from "react";
import { useDoubleTap } from "../../hooks/useDoubleTap";

type DoubleTapWraperProps = {
  onDoubleTap: () => void;
};

const DoubleTapWraper: React.FC<DoubleTapWraperProps> = ({
  children,
  onDoubleTap,
}) => {
  const bind = useDoubleTap((event) => {
    onDoubleTap();
  });
  return <div {...bind}>{children}</div>;
};

export default DoubleTapWraper;
