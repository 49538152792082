import { useState } from "react";
import { selectUser } from "../store/reducers/user";
import {
  Camera,
  CameraResultType,
  CameraSource
} from "@capacitor/camera";
import { useAppSelector } from "../store/hooks";
export interface UserPhoto {
  filepath: string;
  webviewPath?: string;
}

export function usePhotoGallery() {
  const [photos, setPhotos] = useState<UserPhoto[]>([]);
  const userData = useAppSelector(selectUser);
  const takePhoto = async () => {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100,
    });
    //Create Random string
    const randomString = (length: number) => {
      let text = "";

      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }

      return text;
    };
     //Filename 
    const fileName = userData?.provider?._id+"_" + new Date().getTime()+randomString(6) + ".jpeg";
    const newPhotos = [
      {
        filepath: fileName,
        webviewPath: photo.webPath,
      },
      ...photos,
    ];
    setPhotos(newPhotos);
  };

  return {
    photos,
    takePhoto,
    setPhotos,
  };
}
