import React, { useState } from "react";
import Slider from "react-rangeslider";
import "./ScaleProgress.scss";

interface ScaleProps {
  min?: number;
  max?: number;
  defaultValue?: number;
}

const ScaleProgress: React.FC<ScaleProps> = ({
  min,
  max,
  defaultValue = 0,
}) => {
  const [progress, setProgress] = useState<number>(defaultValue);

  const handleChangeStart = () => {};
  const handleChangeComplete = () => {};

  return (
    <div className="slider">
      <Slider
        min={min}
        max={max}
        value={progress}
        onChangeStart={handleChangeStart}
        onChange={(value) => setProgress(value)}
        onChangeComplete={handleChangeComplete}
      />
    </div>
  );
};

export default ScaleProgress;
