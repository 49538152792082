import SelectorRoutes from "./SelectorRoutes";
import AuthRoutes from "./AuthRoutes";
import PublicRoutes from "./PublicRoutes";

type RouterType = {
  isAuth: boolean;
  itsInSelection: boolean;
};

const Router: React.FC<RouterType> = ({ isAuth, itsInSelection }) => {
  return isAuth ? (
    itsInSelection ? (
      <SelectorRoutes />
    ) : (
      <AuthRoutes />
    )
  ) : (
    <PublicRoutes />
  );
};

export default Router;
