import React from "react";
import { IonIcon, IonText } from "@ionic/react";
import "./ProcedureCard.scss";
import { checkmarkOutline, star, starOutline } from "ionicons/icons";
import { useAppSelector } from "../../../store/hooks";
import CheckBox from "../../../components/Common/CheckBox/CheckBox";

interface ProcedureItem {
  code: string;
  description: string;
  long_description: string;
  enabled: boolean;
  favorite: boolean;
  isSelected: boolean;
}

type ProcedureCardProps = {
  item: ProcedureItem;
  favoriteHandler: any;
  cardSelectHandler: any;
  cptClick: any;
  isNoSelect?: boolean;
};

const ProcedureCard: React.FC<ProcedureCardProps> = ({
  item,
  favoriteHandler,
  cardSelectHandler,
  cptClick,
  isNoSelect,
}) => {
  const darkThemeBoolean = useAppSelector((state) => state.layout.darkTheme);

  // console.log({ cptClick });

  return (
    <div
      className={`procedure-code-card-container ${
        item.isSelected ? "cardselected" : ""
      }`}
    >
      <div
        onClick={() => favoriteHandler(item)}
        className={item?.favorite ? "staricon favourite" : "staricon"}
      >
        <IonIcon
          icon={
            item?.favorite === true
              ? star
              : !darkThemeBoolean
              ? starOutline
              : star
          }
        />
      </div>
      <div
        className="addedDiagnosisInfo-newdesign"
        onClick={() =>
          isNoSelect && cptClick ? cptClick(item) : cardSelectHandler(item)
        }
      >
        <IonText className={`addedDiagnosisText-newdesign`}>
          {item.code}
        </IonText>
        <IonText className={`addedDiagnosisPara-newdesign`}>
          {item.description}
        </IonText>
      </div>
      {!isNoSelect && (
        <>
          {item.isSelected ? (
            <div
              onClick={() => cardSelectHandler(item)}
              className="inputcheckbox"
            >
              {/* <CheckBox value="on" onCheck={(data) => {}} checked /> */}
              <IonIcon icon={checkmarkOutline} />
            </div>
          ) : (
            cptClick && (
              <div onClick={() => cardSelectHandler(item)} className="checkbox">
                <CheckBox />
              </div>
            )
          )}
        </>
      )}
    </div>
  );
};

export default ProcedureCard;
