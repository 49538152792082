/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonSelect,
  IonList,
  IonSelectOption,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import CensusHeader from "../../components/Census/CensusHeader";
import Tab, { TabNav, TabRouter } from "../../components/Common/Tab/Tab";
// import Inpatient from "../../components/Census/Inpatient";
import Encounters from "../../components/Census/Encounters";
// import dailysummary from "../../mock/dailysummary.json";
// import visitsByDate2 from "../../mock/visitsByDate.json";
import { wrapWithSelectionProps } from "../../helper/formater";
import Button from "../../components/Common/Buttons/Buttons";
import {
  cameraOutline,
  mic,
  close,
  notifications,
  ellipsisVertical,
} from "ionicons/icons";
import { debounce } from "underscore";

import { socket, socket1 } from "../../socket";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getVisitsCensus } from "../../store/thunk/censusThunk";
import { Layout } from "../../components/Layout/Layout";
import {
  getFacilities,
  getproviderholdCount,
  getVisitsByDateAndFacility,
} from "../../helper/backendHelper";
import {
  setActiveDate,
  setCensusFacility,
  setFacilitiesList,
  setFacilitiesListAddon,
} from "../../store/reducers/census";
import { downloadFile } from "../../utils/fileDownloader";
import { useParams } from "react-router";
import { selectUser } from "../../store/reducers/user";
import SelectBox from "../../components/Common/SelectBox/SelectBox";
import CheckBox from "../../components/Common/CheckBox/CheckBox";
import Select from "../../components/Common/Select/Select";
import Card from "../../components/Common/Card/Card";
import "../../components/Census/Census.scss";
import SearchBox from "../../components/Common/SearchBox/SearchBox";
import { groupBy, mapValues, omit } from "lodash";
import { IconSvg } from "../../components/Common/IconSvg/IconSvg";
import { SortIcon } from "../../icons/icons";

const Census: React.FC = () => {
  const router = useIonRouter();
  const dispatch = useAppDispatch();
  let quary = useParams<{ id?: string }>();
  const thisDate = useAppSelector((state) => state.census.activeDate);
  const [showBellNotification, setShowBellNotification] =
    useState<boolean>(false);
  const [issuesCount, setIssuesCount] = useState<number>(0);
  const facilitiesList = useAppSelector((state) => state.census.facilitiesList);
  const currentFacility = useAppSelector(
    (state) => state.census.censusFacility
  );
  const [facility, setFacility] = useState<any>(
    currentFacility ? currentFacility._id : "All Facilty"
  );
  const [pdata, setPdata] = useState<any>("-1");
  const allNoteStatus = [
    { _id: "-1", name: "Select One..." },
    { _id: "0", name: "All" },
    { _id: "1", name: "No Notes" },
    { _id: "2", name: "Drafted" },
    { _id: "3", name: "Completed" },
  ];

  const [noteStatusTemp, setNoteStatusTemp] = useState<any>("-1");
  const [noteStatus, setNoteStatus] = useState<any>("-1");

  console.log("facility", facilitiesList);

  const [selFilterFacility, setSelFilterFacility] = useState<any>([]);
  const onCalenderDateChange = (date: Date) => {
    dispatch(setCensusFacility(facilitiesList[0]));
    dispatch(setActiveDate(moment(date).format("YYYY-MM-DD")));
    dispatch(
      getVisitsCensus({
        servicedate: moment(date).format("YYYY-MM-DD"),
        // thisProvider: userData.provider,
        facilityId: currentFacility?._id,
      })
    );
  };
  const censusData = useAppSelector((state) => state.census);
  const selectedPractice = useAppSelector((state) => state.user)?.practice;
  // const [censusData, setCensusData] = useState<any>(censusData2);
  // const [newData, setNewData] = useState<any>([]);

  // let visits: any = censusData?.encounters;
  const visitList = useAppSelector((state) => state.census.patientsList);
  const [totalVisitsPerFacility, setTotalVisitsPerFacility] =
    useState(visitList);

  useEffect(() => {
    if (noteStatus === "-1" || noteStatus === "0")
      setTotalVisitsPerFacility(visitList);
    else {
      switch (noteStatus) {
        case "1":
          setTotalVisitsPerFacility(visitList.filter((item) => !item.chart_id));
          break;

        case "2":
          setTotalVisitsPerFacility(
            visitList.filter((item) => item.chart_id && !item.chart.signed_on)
          );
          break;

        case "3":
          setTotalVisitsPerFacility(
            visitList.filter((item) => item.chart_id && item.chart.signed_on)
          );
          break;

        default:
          setTotalVisitsPerFacility(visitList);
          break;
      }
    }
  }, [visitList, noteStatus]);

  const [newEncounterOpen, setNewEncounterOpen] = useState(false);

  console.log("?", totalVisitsPerFacility);

  const closeModal = () => {
    setNewEncounterOpen(false);
  };

  const joinRoom = (practiceId: any, selectedDate: any) => {
    socket1.emit("join-visits", { practice_id: practiceId, dos: selectedDate });
  };

  const [vistsFac, setVisitsFac] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [tabId, setTabId] = useState<string>("0");
  useEffect(() => {
    const inpatientsDataPerFacility = totalVisitsPerFacility?.filter(
      (item: any) => item.facility_id === currentFacility?.facility_id
    );
    setVisitsFac(wrapWithSelectionProps(inpatientsDataPerFacility));
  }, [totalVisitsPerFacility]);

  useEffect(() => {
    //logic to set search result
    let searchVisit =
      vistsFac.length > 0 &&
      vistsFac?.filter(
        (item: any) =>
          item?.patient_lastname
            ?.toLowerCase()
            ?.includes(searchText.toLowerCase()) ||
          item?.patient_firstname
            ?.toLowerCase()
            ?.includes(searchText.toLowerCase())
      );

    let groupedVisit = mapValues(groupBy(searchVisit, "statusGroup"), (clist) =>
      clist.map((visit) => omit(visit, "statusGroup"))
    );

    if (searchText === "") {
      setVisitsFac(
        mapValues(groupBy(vistsFac, "statusGroup"), (clist) =>
          clist.map((visit) => omit(visit, "statusGroup"))
        )
      );
    } else {
      setVisitsFac(groupedVisit);
    }
  }, [searchText]);

  useEffect(() => {
    joinRoom(selectedPractice?._id, moment(thisDate).format("YYYY-MM-DD"));

    dispatch(
      getVisitsCensus({
        servicedate: moment(thisDate).format("YYYY-MM-DD"),
        // thisProvider: userData.provider,
        facilityId: currentFacility?._id,
      })
    );
  }, [currentFacility]); //removed thisDate from dependancy array because, change state and api handle was done for the same in censusCalendar.tsx
  const [newFilterSetting, setNewFilterSetting] = useState(false);
  const closeModalFilter = () => {
    setNewFilterSetting(false);
  };

  // useEffect(() => {
  //
  //   socket1.on("visit-changes", (data: any) => {
  //
  //     // alert(data);
  //
  //     dispatch(getVisitsCensus(moment(thisDate).format("YYYY-MM-DD")));
  //   });
  // }, [socket1]);

  // console.log(
  //   "data",
  //   censusData.encounters.filter(
  //     (item: any) => item.facility_id === currentFacility?.facility_id
  //   )
  // );

  //const handleFilterChange = (sort: String) => {
  //  setSortMode(true);

  // if (item === "atoz") {
  //   const sortedInPatientData = newData.sort((a: any, b: any) =>
  //     a.patient_firstname > b.patient_firstname
  //       ? 1
  //       : b.patient_firstname > a.patient_firstname
  //       ? -1
  //       : 0
  //   );
  //   // setCensusData({ ...censusData, inpatients: sortedInPatientData });
  // } else if (item === "roomnumber") {
  //   const sortedInPatientData = newData.sort((a: any, b: any) =>
  //     a.room > b.room ? 1 : b.room > a.room ? -1 : 0
  //   );
  //   // setCensusData({ ...censusData, inpatients: sortedInPatientData });
  // }
  //  };

  useEffect(() => {
    const getFacilitiesList = async () => {
      let resFacilities = await getFacilities();
      if (resFacilities.status === "success") {
        dispatch(setFacilitiesList(resFacilities?.results));
        dispatch(setCensusFacility(resFacilities?.results?.[0]));
      }
    };
    getFacilitiesList();
  }, []);

  useEffect(() => {
    if (quary?.id) {
      dispatch(setFacilitiesListAddon(currentFacility));
    } else {
      dispatch(setCensusFacility(facilitiesList?.[0]));
    }
    // setShowBellNotification(false);
  }, [quary?.id]);

  // useEffect(() => {
  //   const objpatient = censusData2.inpatients[0];
  //   const newObj = {
  //     ...objpatient,
  //     patient_lastname: "Arnol",
  //     patient_firstname: "David",
  //     room: "103",
  //   };
  //   const newObj2 = {
  //     ...objpatient,
  //     patient_lastname: "Zeb",
  //     patient_firstname: "Jojo",
  //     room: "102",
  //   };
  //   const newObj3 = {
  //     ...objpatient,
  //     patient_lastname: "Tej",
  //     patient_firstname: "Pal",
  //     room: "101",
  //   };
  //   const patientData: any = [
  //     ...censusData2.inpatients,
  //     newObj,
  //     newObj2,
  //     newObj3,
  //   ];
  //   setNewData(patientData);
  // }, []);
  // useEffect(() => {
  //   setCensusData({ ...censusData, inpatients: newData });
  // }, [newData]);
  // const onDateChange = async (servicedate: string) => {
  //   dispatch(getVisitsCensus(servicedate));
  // };

  const goToPatientSearch = () => {
    router.push("/censusEncounter", "forward");
    setNewEncounterOpen(false);
  };

  const goTovisitdetails = () => {
    router.push("/visitdetails", "back");
    setNewEncounterOpen(false);
  };

  useEffect(() => {
    const getIssuesCount = async () => {
      let res = await getproviderholdCount();
      if (res.status === "success") {
        setIssuesCount(res.result);
      }
    };
    getIssuesCount();
  }, []);

  const handleBellNotification = () => {
    setShowBellNotification(!showBellNotification);
  };

  let facilityFromDashboard = useParams<{ id?: string }>();

  useEffect(() => {
    if (facilityFromDashboard?.id && facilitiesList.length > 0) {
      // var indexofEl = -1;

      let dashFac = facilitiesList?.find((item: any, i: any) => {
        // if (item.facility_id === facilityFromDashboard?.id) {
        //   indexofEl = i;
        // }
        return item.facility_id === facilityFromDashboard?.id;
      });

      // console.log({ dashFac });

      if (dashFac) {
        // if (indexofEl > -1) {
        //   const $slide: any = document.querySelector(`#snap_item_${indexofEl}`);
        //   if (!$slide) return;

        //   if ($slide.scrollIntoViewIfNeeded) {
        //     $slide.scrollIntoViewIfNeeded();
        //   } else if ($slide.scrollIntoView) {
        //     $slide.scrollIntoView();
        //   }
        // }

        dispatch(setCensusFacility(dashFac));
      } else {
        dispatch(setCensusFacility(facilitiesList[0]));
      }
    }
  }, [facilityFromDashboard?.id, facilitiesList]);

  // const [itsFacilityDispatched, setItsFacilityDispatched] = useState(false);

  // useEffect(() => {
  //   if (facilityFromDashboard?.id) {
  //     if (!itsFacilityDispatched) {
  //       dispatch(setFacilitiesListAddon(facilityFromDashboard?.id));
  //       setItsFacilityDispatched(true);
  //     }
  //   } else {
  //     dispatch(setCensusFacility(facilitiesList[0]));
  //   }

  //   return () => {
  //     setItsFacilityDispatched(false);
  //   };
  // }, [facilityFromDashboard?.id, allFacilitiesList]);

  const getSelectedFacility = (selectedFacility: any) => {
    setFacility(selectedFacility._id);
    dispatch(setCensusFacility(selectedFacility));
  };

  const downloadHandler = () => {
    let url =
      "https://collector-dev.claimocity.io/charges/export/charts?&facilityId=5aed3c833d9692be4a722d92&serviceDate=2022-08-01&fileFormat=pdf";
    downloadFile(url);
  };

  // const selFacilDispatcher = (facId:string) => {
  //   console.log("FACID",facId)
  // }

  const getFacilityInfo = (fac_name: string, fac_indx: number) => {
    const foundFacility = facilitiesList.filter(
      (itm: any, index: number) => index === fac_indx && itm._id === fac_name
    );
    if (foundFacility.length > 0) {
      setFacility(foundFacility[0]?._id);
      setSelFilterFacility(foundFacility);
    }
  };

  const handleFilter = () => {
    // if (selFilterFacility.length > 0) {
    //   closeModalFilter();
    //   dispatch(setCensusFacility(selFilterFacility[0]));
    // }

    closeModalFilter();
    dispatch(
      setCensusFacility(facilitiesList.find((item) => item._id === facility))
    );

    setNoteStatus(noteStatusTemp);
  };

  const handleTabIs = (id: number) => {
    // alert(id)
    setTabId(id.toString());
    //alert(id)
  };

  const handleFilterApply = () => {
    if (currentFacility) {
      setFacility(currentFacility._id);
    }
    setNewFilterSetting(true);
  };

  const handleNoteStataus = (option: any) => {
    setNoteStatusTemp(option);
  };

  return (
    <Layout
      isLoading={censusData?.isloading}
      isError={censusData?.isError}
      errorMsg={censusData.errorMsg}
      className="census-page"
    >
      <IonHeader className="no-shadow no-border">
        <IonToolbar className="d-pad-05" mode="ios">
          <IonMenuButton slot="start" className="ion-icon-size-dashboard" />
          <IonTitle className="title-header">Census</IonTitle>

          <div className="bell-notification">
            <IonButtons
              className="bell-item"
              slot="end"
              onClick={handleBellNotification}
            >
              <IonIcon className="bell-icon" icon={notifications}></IonIcon>
              <span className="bell-text">{issuesCount}</span>
            </IonButtons>
          </div>
          <div className="ellips-icon-holder" slot="end">
            <IonIcon
              className="census-ellips"
              onClick={() => setNewFilterSetting(true)}
              icon={ellipsisVertical}
              slot="end"
            ></IonIcon>
          </div>
        </IonToolbar>
      </IonHeader>

      <div>
        <IonContent className="inPatientTab2">
          <CensusHeader
            facilities={facilitiesList}
            onDateChange={onCalenderDateChange}
            showBellNotification={showBellNotification}
            resetShowBellNotification={() => setShowBellNotification(false)}
            issuesCount={issuesCount}
            dateValue={thisDate}
            getSelectedFacility={getSelectedFacility}
            currentFacility={currentFacility}
          />

          {/*  */}
          <div className="search-bar-header">
            <div className="search-box-container-Header">
              <div className="search-box-census-Header">
                <SearchBox
                  variant="light-round-Corner"
                  onChange={debounce(setSearchText, 1000)}
                  placeholder={"Search patient"}
                  value={searchText}
                />
              </div>
              <div>
                <div
                  style={{
                    width: 40,
                    height: 32.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #626468",
                    borderRadius: "16px",
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                >
                  <div onClick={() => handleFilterApply()}>
                    <IconSvg Icon={SortIcon} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Encounters
            visits={wrapWithSelectionProps(totalVisitsPerFacility)}
            setNewEncounterOpen={setNewEncounterOpen}
            searchTxt={searchText}
            handleVoid={function (): void {}}
            setSearch={function (txt: string): void {
              throw new Error("Function not implemented.");
            }}
          />
          {/* <TabNew>
            <TabRouterNew>
              <TabNavNew
                component={Encounters}
                visits={wrapWithSelectionProps(inpatientsDataPerFacility)}
                setNewEncounterOpen={setNewEncounterOpen}
                searchTxt={searchText}
                tabId={tabId}
                handleTabIs={handleTabIs}
              >
                <div className={`in-tab-container`} slot="fixed">
                  Your patients
                  <div className="tab-number-circle">
                    <p className="tab-number-style">
                      {
                        inpatientsDataPerFacility?.filter((item) => item.billed)
                          .length
                      }
                      /{inpatientsDataPerFacility?.length}
                    </p>
                  </div>
                </div>
              </TabNavNew>
              <TabNavNew
                component={Encounters}
                visits={wrapWithSelectionProps(encounterssDataPerFacility)}
                setNewEncounterOpen={setNewEncounterOpen}
                searchTxt={searchText}
                tabId={tabId}
                handleTabIs={handleTabIs}
              >
                <div className="in-tab-container" slot="fixed">
                  Total patients
                  <div className="tab-number-circle">
                    <p className="tab-number-style">
                      {encounterssDataPerFacility?.length}
                    </p>
                  </div>
                </div>
              </TabNavNew>
            </TabRouterNew>
          </TabNew> */}

          <>
            <IonModal
              className="newencounter-modal"
              isOpen={newEncounterOpen}
              onDidDismiss={closeModal}
              breakpoints={[0, 0.3, 0.4, 1]}
              initialBreakpoint={0.4}
              backdropBreakpoint={0.2}
              style={{ overflowY: "scroll" }}
            >
              <div className="newencounter-opened-modal">
                <div className="header-holder">
                  <div className="title-holder">
                    <IonLabel className="labelTitle">New encounter</IonLabel>
                  </div>
                  <div className="btn-holder">
                    <IonIcon
                      icon={close}
                      className="close-icon"
                      onClick={() => setNewEncounterOpen(false)}
                    ></IonIcon>
                  </div>
                </div>
                <div className="content-holder">
                  <div className="content-text-holder">
                    <IonText className="content-text">
                      Please select a method to start a new <br /> encounter.
                    </IonText>
                  </div>
                  <div className="three-btn-holder">
                    <Button
                      fullWidth={true}
                      tallbutton
                      color="secondary"
                      onClick={() => goToPatientSearch()}
                    >
                      <IonText className="content-btn-text no-left-margin">
                        T
                      </IonText>
                      <IonText className="content-btn-text">Text</IonText>
                    </Button>
                  </div>
                  <div className="three-btn-holder">
                    <Button
                      fullWidth={true}
                      tallbutton
                      color="secondary"
                      onClick={() => goTovisitdetails()}
                    >
                      <IonIcon
                        icon={cameraOutline}
                        className="content-btn-icon"
                      ></IonIcon>
                      <IonText className="content-btn-text">Scan</IonText>
                    </Button>
                  </div>
                  <div className="three-btn-holder">
                    <Button fullWidth={true} tallbutton color="secondary">
                      <IonIcon
                        icon={mic}
                        className="content-btn-icon"
                      ></IonIcon>
                      <IonText className="content-btn-text">Voice</IonText>
                    </Button>
                  </div>
                </div>
              </div>
            </IonModal>
            <IonModal
              className="newfilter-modal"
              isOpen={newFilterSetting}
              onDidDismiss={closeModalFilter}
              breakpoints={[0, 0.3, 0.4, 1]}
              initialBreakpoint={1.0}
              backdropBreakpoint={0.2}
            >
              <div className="newfilter-opened-modal">
                <div className="header-holder-filter">
                  <div className="title-holder-filter">
                    <IonLabel className="labelTitle-filter">
                      Filter setting
                    </IonLabel>
                  </div>
                  <div className="btn-holder-close">
                    <IonIcon
                      icon={close}
                      className="close-icon-filter"
                      onClick={() => setNewFilterSetting(false)}
                    ></IonIcon>
                  </div>
                </div>

                <div className="">
                  <p>Selected Filters</p>
                </div>

                <IonLabel className="patient-sub">Facility</IonLabel>
                <div className="select-box-container">
                  <SelectBox
                    options={facilitiesList}
                    selected={facility}
                    setSelected={setFacility}
                    getFacil={getFacilityInfo}
                  />
                </div>
                <IonLabel className="patient-sub">Patient Status</IonLabel>
                <div className="select-box-container">
                  <SelectBox
                    options={[
                      { name: "All", _id: "-1" },
                      { name: "Admitted", _id: "0" },
                      { name: "Option 1", _id: "1" },
                      { name: "Option 2", _id: "2" },
                      { name: "Option 3", _id: "3" },
                    ]}
                    selected={pdata}
                    setSelected={setPdata}
                  />
                </div>

                <IonLabel className="patient-sub">Note Status</IonLabel>
                <div className="select-box-container">
                  <SelectBox
                    options={allNoteStatus}
                    selected={noteStatusTemp}
                    setSelected={handleNoteStataus}
                  />
                </div>

                <div className="">
                  <p>More Filters</p>
                </div>

                <div
                  className={`c-options-holder scroll active`}
                  id="slideControls"
                >
                  <div className="c-facility-wraper ">
                    <IonList className="ionlist-filter">
                      <IonItem>
                        <div className="filter-checkbox">
                          <CheckBox />
                        </div>
                        <IonLabel> Date</IonLabel>
                      </IonItem>
                      <IonItem>
                        <div className="filter-checkbox">
                          <CheckBox />
                        </div>
                        <IonLabel> Patient Status</IonLabel>
                      </IonItem>
                      <IonItem>
                        <div className="filter-checkbox">
                          <CheckBox />
                        </div>
                        <IonLabel> Facility</IonLabel>
                      </IonItem>
                      <IonItem>
                        <div className="filter-checkbox">
                          <CheckBox />
                        </div>
                        <IonLabel> Last Date of Service</IonLabel>
                      </IonItem>
                      <IonItem>
                        <div className="filter-checkbox">
                          <CheckBox />
                        </div>
                        <IonLabel> Provider</IonLabel>
                      </IonItem>
                      <IonItem>
                        <div className="filter-checkbox">
                          <CheckBox />
                        </div>
                        <IonLabel> Room</IonLabel>
                      </IonItem>
                      <IonItem>
                        <div className="filter-checkbox">
                          <CheckBox />
                        </div>
                        <IonLabel> Name(DOB)</IonLabel>
                      </IonItem>
                      <IonItem>
                        <div className="filter-checkbox">
                          <CheckBox />
                        </div>
                        <IonLabel> Census Type</IonLabel>
                      </IonItem>
                    </IonList>
                    <div className="filter-height-dummy"></div>
                  </div>

                  <div className="btn-holder-filter">
                    <Button
                      fullWidth
                      boldfont
                      color="primary"
                      onClick={() => handleFilter()}
                    >
                      Set filters
                    </Button>
                  </div>
                </div>
              </div>
            </IonModal>
          </>
        </IonContent>
      </div>
    </Layout>
  );
};

export default Census;
