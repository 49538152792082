import React from "react";
import { IonIcon, IonCol, IonRow, IonText, IonGrid } from "@ionic/react";
import "./ProviderHold.scss";
import "../../components/Census/Census.scss";
import { alertOutline } from "ionicons/icons";
import { Warning } from "../../icons/icons";
import { IconSvg } from "../../components/Common/IconSvg/IconSvg";
interface PatientCardInterface {
  cardtype?: number;
  warning?: boolean;
  patientdata?: any;
  key?: number;
  list?: any;
}

const PatientCard: React.FC<PatientCardInterface> = ({
  cardtype,
  warning,
  patientdata,
  list,
}) => {
  return (
    <div className="slide-item-holder">
      <div className="patient-card-item ">
        <div className="card-item">
          <div>
            <IonCol className="alert-icon-area" size="1">
              {" "}
              <div className="alert-icon-holder">
                {" "}
                <IonIcon
                  class="ion-align-self-left"
                  icon={alertOutline}
                  className="alert-icon"
                />
              </div>
            </IonCol>
          </div>
          <IonGrid>
            {list == 2 ? (
              <IonRow className="census-card-top-area">
                <IonCol className="title" size="3.5">
                  {patientdata?.patient_name}
                </IonCol>
                <IonCol size=".3">|</IonCol>
                <IonCol size="2.8">{patientdata?.facility_mrn}</IonCol>
                <IonCol size=".8">
                  <div className="icon-holder">
                    <IconSvg Icon={Warning} />
                  </div>
                </IonCol>
                <IonCol size="1.7">
                  <span className="census-card-highlight">
                  {"  "}  {/* AC */}
                  </span>
                </IonCol>
              </IonRow>
            ) : (
              <IonRow className="census-card-top-area">
                <IonCol className="title" size="4.3">
                  {patientdata?.patient_name}
                </IonCol>
                <IonCol size=".5">|</IonCol>
                <IonCol size="3.2">{patientdata?.facility_mrn}</IonCol>
                <IonCol size="1.9">
                  <span className="census-card-highlight">AC</span>
                </IonCol>
              </IonRow>
            )}
            <IonRow className="census-card-middle-area">
              <IonCol className="text-overflow" size="6.2">
                <IonText className="middle-area-text destructice">
                  {patientdata?.reason_codes?.length > 1
                    ? patientdata?.reason_codes[0] +
                      " +" +
                      (patientdata?.reason_codes.length - 1)
                    : patientdata?.reason_codes}
                </IonText>
              </IonCol>
              <IonCol size="2">{}</IonCol>
              <IonCol size="1.5">{}</IonCol>
              <IonCol size="2.2">
                {patientdata?.procedure_code_primary?.code}
              </IonCol>
            </IonRow>
            <IonRow className="census-card-bottom-area">
              <IonCol className="text-overflow" size="6">
                <IonText className="bottom-area-text destructive">
                  &nbsp;{/* $80 receivables missing */}
                </IonText>
              </IonCol>
              <IonCol size="2">{}</IonCol>
              <IonCol size="1.5">{}</IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    </div>
  );
};

export default PatientCard;
