import { IonIcon, IonText } from "@ionic/react";

import "./DiagnosisSearchCard.scss";
import { checkmarkOutline, star, starOutline } from "ionicons/icons";
import React from "react";
import { useAppSelector } from "../../../store/hooks";
import { toast } from "@mobiscroll/react";
import CheckBox from "../../../components/Common/CheckBox/CheckBox";


const DiagnosisCodeSearchCard: React.FC<any> = ({
  item,
  favoriteHandler,
  cardSelectHandler,
  diagnosisSelected,
  selectedDiagnosis,
  dxClick,
  // favoriteButton,
}) => {
  const isItemSelected = selectedDiagnosis
    .map((e: any) => e.code)
    .includes(item.code);
  const selectedCard = diagnosisSelected.includes(item.code);
  const darkThemeBoolean = useAppSelector((state) => state.layout.darkTheme);

  return (
    <div
      className={`addedDiagnosis-newdesign ${
        item.isSelected ? "cardselected" : ""
      }`}
    >
      <div
        onClick={() => favoriteHandler(item)}
        className={
          item?.favorite
            ? "content_list_itemIcon-diagnosiscode-newdesign favourite"
            : "content_list_itemIcon-diagnosiscode-newdesign"
        }
      >
        <IonIcon
          icon={
            item?.favorite === true
              ? star
              : !darkThemeBoolean
              ? starOutline
              : star
          }
        />
      </div>

      <div
        className="addedDiagnosisInfo-newdesign"
        onClick={() =>
          isItemSelected
            ? toast({ message: "This Diagnosis code is already selected" })
            : cardSelectHandler(item)
        }
      >
        <IonText className={`addedDiagnosisText-newdesign`}>
          {item.code}
        </IonText>
        <IonText className={`addedDiagnosisPara-newdesign`}>
          {item.description}
        </IonText>
      </div>
      {item.isSelected ? (
        // <div onClick={() => cardSelectHandler(item)} className="inputcheckbox">
        //   <CheckBox value="on" onCheck={(data) => {}} checked />
        // </div>
        <div onClick={() => cardSelectHandler(item)} className="content_addlist_itemCheckIcon">
          <IonIcon icon={checkmarkOutline} />
        </div>
      ) : (dxClick && 
        
          <div onClick={() => cardSelectHandler(item)} className="checkbox">
          <CheckBox/>
          </div>
      )}
    </div>
  );
};

export default DiagnosisCodeSearchCard;
