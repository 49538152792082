import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import React from "react";
import PatientSearch from "../../../components/Common/PatientSearch/PatientSearch";
// import { PatientType } from "../../../types/PatientType";
import "./CensusEncounter.scss";

import { setPatientDetail } from "../../../store/reducers/newEncounter";
import { useAppDispatch } from "../../../store/hooks";

const CensusEncounter: React.FC = () => {
  // const [curentPatient, setCurentPatient] = useState<PatientType | null>(null);

  const dispatch = useAppDispatch();
  const router = useIonRouter();

  const handleSelectPatient = (data: any) => {
    router.push("/addNewEncounter", "forward");
    dispatch(setPatientDetail(data));
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className="patientSearchHeaderNewEncounter">
        <IonToolbar className="patientSearchToolbarNewEncounter" mode="ios">
          <IonButtons slot="start">
            <IonBackButton text={""} className="setting-backbutton" />
          </IonButtons>

          {/*  <IonMenuButton
            slot="start"
            className={"ion-icon-size-cencusEncounter"}
          /> */}

          <IonTitle>New encounter</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div className="newEncounterContent">
        <div className="patientsearch-container">
          <p className="headerLabel">Patient search</p>
        </div>
      </div>
      <PatientSearch onSelect={(data) => handleSelectPatient(data)} />
      {/* <IonFooter className="patientSearchNewEncounterFooter" translucent>
      </IonFooter> */}
    </IonPage>
  );
};

export default CensusEncounter;
