import { createAsyncThunk } from "@reduxjs/toolkit";
import { Base64 } from "js-base64";
import {
  currentFeatures,
  currentPermissions,
  currentPractice,
  currentProvider,
  currentRoles,
  currentSettings,
  getConversations,
  getCurrentUser,
  getPatientsVisitDetailsID,
  getPicture,
  getPractice,
  getProviders,
  getReadConversation,
  getReason,
  getVisitStatuses,
  listContactList,
  login,
  mfaOTPVerify,
  providerlogin,
  putFavoriteUser,
} from "../../helper/backendHelper";
import { socket, socket1 } from "../../socket";
import {
  loginDataPayload,
  mfaOTPVerifyPayload,
  ProviderLoginData,
} from "../../types/Types";
// import { TokenType } from "../../types/Types";

type Credentials = {
  userName: any;
  password: any;
  platform: any;
};

let Token: any = localStorage.getItem("Token");
let currentUserId = JSON.parse(Token)?.user?._id;

export const getMessageList = createAsyncThunk(
  //action type string
  "message/getContacts",
  // callback function
  async (payload: any, thunkAPI) => {
    let res = await getConversations(payload);
    if (res?.status === 400) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      if (res?.status === "success" || res?.status === "failure") {
        if (res?.data) {
          for (let i = 0; i < res?.data.length; i++) {
            res.data[i] = processDateTime(res?.data[i]);
          }
          res.data = messageListAvatar(res?.data);

          if (payload?.type === "messages") {
            return {data: res?.data, type: payload?.type, status: res?.status}
          } else if (payload?.type === "archive") {
            return {data: res?.data, type: payload?.type, status: res?.status}

          } else if (payload?.type === "draft") {
            return {data: res?.data, type: payload?.type, status: res?.status}

          }
          else if (payload?.type === "all") {
            return {data: res?.data, type: payload?.type, status: res?.status}

          }
        }
      } 
      else {
        return thunkAPI.rejectWithValue("Server Error");
      }
    }
  }
);

function groupBy(arr: any, criteria: any) {
  const newObj = arr.reduce(function (acc: any, currentValue: any) {
    if (!acc[currentValue[criteria]]) {
      acc[currentValue[criteria]] = [];
    }
    acc[currentValue[criteria]].push(currentValue);
    return acc;
  }, {});
  return newObj;
}

export const getContactList = createAsyncThunk(
  //action type string
  "message/getContactList",
  // callback function
  async (payload: any, thunkAPI) => {
    let res: any = await listContactList();

    if (res?.status === 400) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      if (res?.status === "success") {
        try{
          let currentUser:any= await getCurrentUser();
          console.log(currentUser)
          for(let i=0;i<currentUser?.message_favorites?.length;i++){
            for(let j=0;j<res?.data?.length;j++){
              if(currentUser?.message_favorites[i]?.user_id === res?.data[j]?.user_id && currentUser?.message_favorites[i]?.status===true){
                res.data[j]={...res?.data[j],favorites:true}
              }
            }
          }
        }
        catch(err){
          console.log(err);
          
        }
        let practiceUser = res?.data?.filter((item: any) => {
          return item?.isInternal === false;
        });
        let supportUser = res?.data?.filter((item: any) => {
          return item?.isInternal === true;
        });
        let distributionGroup = groupBy(res?.data, "user_title");
        return {data: res?.data, status: res?.status, practiceUser: practiceUser, supportUser:supportUser, distributionGroup:distributionGroup}

      }
      else {
        return thunkAPI.rejectWithValue("Server Error");
      }
    }
  }
);

export const setFavUser = createAsyncThunk(
  //action type string
  "message/setFavUser",
  // callback function
  async (payload: any, thunkAPI) => {
    console.log("paylaod",payload);
    
    let body = {
      status:payload?.status,
      user_id:payload?.user_id
    }
    let res=await putFavoriteUser(body);

    if (res?.status === 400) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      if (res?.status === "success") {
        let contacts:any=payload?.contactList?.map((item:any)=>{
          if(item?.user_id===payload?.user_id){
            return item={...item,favorites:payload?.status}
          }
          else{
            return item;
          }
        });
        console.log("contacts",contacts);
  
        let practiceUser = contacts?.filter((item: any) => {
          return item?.isInternal === false;
        });
        let supportUser = contacts?.filter((item: any) => {
          return item?.isInternal === true;
        });
        console.log("paraccc",practiceUser,supportUser);

        return {practiceUser: practiceUser, supportUser:supportUser, contactList:contacts}

      }
      else {
        return thunkAPI.rejectWithValue("Server Error");
      }
    }
  }
);

export const getChatMessages = createAsyncThunk(
  //action type string
  "message/getChatMessages",
  // callback function
  async (conversationId: any, thunkAPI) => {
    let res = await getReadConversation(conversationId?.id);

    if (res?.status === 400) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      if (res?.status === "success") {
        //participants avatar
        for (let i = 0; i < res?.data[0]?.participantss?.length; i++) {
          let participant = res?.data[0]?.participantss[i];
          if (participant?.username) {
            if (participant?.username.indexOf(" ") == -1) {
              participant.username_character = participant?.username
                ?.charAt(0)
                .toUpperCase();
            } else {
              let x = participant?.username.substr(
                participant?.username?.indexOf(" ") + 1
              );
              participant.username_character =
                participant?.username?.charAt(0).toUpperCase() +
                x.charAt(0).toUpperCase();
            }
          }
          if (participant?.practice_visibility == false) {
            participant.pv = "assets/imgs/avatar-claimocity.png";
          }
        }
        //for type 2 message
        for (let i = 0; i < res?.data[0]?.messagess?.length; i++) {
          if (
            res?.data[0]?.messagess[i]?.message_type == 2 ||
            res?.data[0]?.messagess[i]?.message_type == 3
          ) {
            if (res?.data[0]?.messagess[i]?.file_name?.length > 0) {
              try {
                let result = await getPicture(
                  res?.data[0]?.messagess[i]?.file_name
                );
                res.data[0].messagess[i]["fileurl"] = result?.data;
              } catch (err) {}
            }
          }
        }
        //for type 3 message
        for (let i = 0; i < res?.data[0]?.messagess?.length; i++) {
          if (res?.data[0]?.messagess[i]?.message_type == 3) {
            res.data[0].messagess[i].playPauseAudio = false;
          }
        }
        return {data: res?.data, status: res?.status}

      }
      else {
        return thunkAPI.rejectWithValue("Server Error");
      }
    }
  }
);

const processDateTime =(item:any) => {
  if (item.conversations_type[0] != "draft" && item.latest_message) {
    let comingDate = new Date(item.latest_message.message_datetime);
    if (new Date(comingDate).getFullYear() == new Date().getFullYear()) {
      if (new Date(comingDate).getMonth() + 1 == new Date().getMonth() + 1) {
        if (new Date(comingDate).getDate() == new Date().getDate()) {
          let ampm = comingDate.getHours() >= 12 ? 'pm' : 'am';
          item.latest_message.messageDay = comingDate.getHours() + ":" + comingDate.getMinutes() + " " + ampm;  // getSeconds
        } else if (new Date(comingDate).getDate() == new Date().getDate() - 1) {
          item.latest_message.messageDay = "Yesterday";
        } else {
          item.latest_message.messageDay = new Date(comingDate).getMonth() + 1 + "/" + new Date(comingDate).getDate() + "/" + new Date(comingDate).getFullYear();
        }
      } else {
        item.latest_message.messageDay = new Date(comingDate).getMonth() + 1 + "/" + new Date(comingDate).getDate() + "/" + new Date(comingDate).getFullYear();
      }

    } else {
      item.latest_message.messageDay = new Date(comingDate).getMonth() + 1 + "/" + new Date(comingDate).getDate() + "/" + new Date(comingDate).getFullYear();
    }
  }
  return item;
}

export const loadMore = createAsyncThunk(
  //action type string
  "message/loadMore",
  // callback function
  async (payload: any, thunkAPI) => {
    let res = await getConversations(payload);

    if (res?.status === 400) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      if (res?.status === "success" || res?.status === "failure") {
        if (res?.data) {
          for (let i = 0; i < res?.data.length; i++) {
            res.data[i] = processDateTime(res?.data[i]);
          }
          res.data = messageListAvatar(res?.data);

          if (payload?.type === "messages") {
            return {data: res?.data, type: payload?.type, status: res?.status}
          } else if (payload?.type === "archive") {
            return {data: res?.data, type: payload?.type, status: res?.status}

          } else if (payload?.type === "draft") {
            return {data: res?.data, type: payload?.type, status: res?.status}

          }
          else if (payload?.type === "all") {
            return {data: res?.data, type: payload?.type, status: res?.status}

          }
        }
      } 
      else {
        return thunkAPI.rejectWithValue("Server Error");
      }
    }
  }
);

const messageListAvatar = (data: any) => {
  let messageList = data;
  messageList.map((conversation: any, t: any) => {
    if (conversation.participants) {
      let allAvatarDisplay: any = {};
      let isPracticeInvisibleExists = false;
      conversation.participants = conversation.participants.filter(
        (elem: any) => {
          if (elem.displayname) {
            return true;
          }
        }
      );
      conversation.participants.forEach((participant: any) => {
        if (!allAvatarDisplay[participant.user_id]) {
          if (participant.practice_visibility === true) {
            participant = avatarFormat(participant);
            allAvatarDisplay[participant.user_id] = participant;
          } else if (!isPracticeInvisibleExists) {
            participant = avatarFormat(participant);
            allAvatarDisplay[participant.user_id] = participant;
            if (participant.practice_visibility === false) {
              isPracticeInvisibleExists = true;
            }
          }
        }
      });
      let avatar_participants = [];
      let total = 2;
      if (Object.keys(allAvatarDisplay).length < total) {
        total = Object.keys(allAvatarDisplay).length;
      } else if (Object.keys(allAvatarDisplay).length == 2) {
        if (allAvatarDisplay[currentUserId]) {
          delete allAvatarDisplay[currentUserId];
          total = 1;
        }
      } else {
        delete allAvatarDisplay[currentUserId];
      }
      let participants = Object.assign({}, allAvatarDisplay);
      conversation.partipantcount = Object.keys(allAvatarDisplay).length - 2;
      for (let i = 0; i < total; i++) {
        if (
          conversation.message_users[i] &&
          participants[conversation.message_users[i]]
        ) {
          avatar_participants.push(participants[conversation.message_users[i]]);
          delete participants[conversation.message_users[i]];
        } else {
          if (Object.keys(participants).length > 0) {
            let key = Object.keys(participants)[0];
            avatar_participants.push(participants[key]);
            delete participants[key];
          }
        }
      }
      conversation.display_message_logo = avatar_participants;
      return conversation;
    }
  });
  return messageList;
};
const avatarFormat = (participant: any) => {
  let inialDisplay = participant.displayname ? participant.displayname : "";
  let splitInitial = inialDisplay
    .split(" ")
    .map(function (getVal: any) {
      return getVal[0];
    })
    .join("");
  let displayLetters;
  if (splitInitial.length > 2) {
    displayLetters = splitInitial.slice(-2);
  } else {
    displayLetters = splitInitial;
  }
  participant["displayInitials"] = displayLetters;
  if (participant.practice_visibility == true) {
    participant["showIcon"] = false;
  } else {
    participant["showIcon"] = true;
  }
  return participant;
};

export const mfaLogin = createAsyncThunk(
  //action type string
  "user/mfaLogin",
  // callback function
  async (credentials: mfaOTPVerifyPayload, thunkAPI) => {
    let verificationDetails = {
      code: credentials.code,
      "mfa-token": credentials["mfa-token"],
    };

    const loginRes = await mfaOTPVerify(verificationDetails);

    if (loginRes?.status === 400) {
      return thunkAPI.rejectWithValue(loginRes?.message);
    } else {
      if (loginRes?.status === "success") {
        if (loginRes?.authToken) {
          let Token = {
            authRefreshToken: loginRes?.authRefreshToken,
            authToken: loginRes?.authToken,
            user: loginRes?.user,
            roles: loginRes?.roles,
            permissions: loginRes?.permissions,
          };

          localStorage.setItem("Token", JSON.stringify(Token));

          const practiceRes = await getPractice().then((res) => res?.results);
          const providerRes = await getProviders().then((res) => res?.results);

          return { ...loginRes, practiceRes, providerRes };
        } else {
          return thunkAPI.rejectWithValue(loginRes?.message);
        }
      } else {
        return thunkAPI.rejectWithValue(loginRes?.message);
      }
    }
  }
);

export const rememberAuth = createAsyncThunk(
  //action type string
  "user/rememberAuth",
  // callback function
  async (token, thunkAPI) => {
    let Token = localStorage.getItem("Token");
    let TokenObj: any;

    if (typeof Token === "string") {
      TokenObj = JSON.parse(Token);
    }

    const practiceRes = await getPractice().then((res) => res?.results);
    const providerRes = await getProviders().then((res) => res?.results);

    const settingsRes = await currentSettings().then((res) => res?.results);
    const rolesRes = await currentRoles().then((res) => res?.results);
    const permissionsRes = await currentPermissions().then(
      (res) => res?.results
    );
    const featuresRes = await currentFeatures().then((res) => res);

    const curentProviderRes = await currentProvider().then(
      (res) => res?.results
    );
    const curentPracticeRes = await currentPractice().then(
      (res) => res?.result
    );

    if (practiceRes?.length > 0 && providerRes?.length) {
      return {
        ...TokenObj,
        practiceRes,
        providerRes,
        curentProviderRes,
        curentPracticeRes,
        settingsRes,
        rolesRes,
        permissionsRes,
        featuresRes,
      };
    } else {
      // localStorage.removeItem("Token");
      return thunkAPI.rejectWithValue("Server Error");
    }
  }
);

export const providerLogin = createAsyncThunk(
  //action type string
  "user/providerLogin",
  // callback function
  async (credentials: ProviderLoginData, thunkAPI) => {
    let Token = await localStorage.getItem("Token");
    let TokenObj: any;

    if (typeof Token === "string") {
      TokenObj = JSON.parse(Token);
    }

    const logRes = await providerlogin({
      practice_id: credentials.practice_id,
      provider_id: credentials.provider_id,
    });

    if (logRes?.status === "success" && logRes?.result !== null) {
      let newToken = await { ...TokenObj, authToken: logRes?.result };
      await localStorage.setItem("Token", JSON.stringify(newToken));

      const providerRes = await currentProvider()
        .then((res) => res?.results)
        .catch((err) => {});
      const practiceRes = await currentPractice()
        .then((res) => res?.result)
        .catch((err) => {});
      const settingsRes = await currentSettings()
        .then((res) => res?.results)
        .catch((err) => {});
      const rolesRes = await currentRoles()
        .then((res) => res?.results)
        .catch((err) => {});
      const permissionsRes = await currentPermissions()
        .then((res) => res?.results)
        .catch((err) => {});
      const featuresRes = await currentFeatures()
        .then((res) => res)
        .catch((err) => {});

      return {
        ...newToken,
        practiceRes,
        providerRes,
        settingsRes,
        rolesRes,
        permissionsRes,
        featuresRes,
      };
    } else {
      // localStorage.removeItem("Token");
      return thunkAPI.rejectWithValue("Server Error");
    }
  }
);
