import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonImg,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import Button from "../Buttons/Buttons";
import CheckBox from "../../../components/Common/CheckBox/CheckBox";
import EllipseGroupBright from "../../../icons/Other/EllipseGroupBright.svg";
import EllipseGroupDim from "../../../icons/Other/EllipseGroupDim.svg";
import "./AddFilter.scss";

interface AddFilterProps {
  openAddFilter: boolean;
  setOpenAddFilter: (item: boolean) => void;
  facilityList: any;
  onApply: (item: any) => void;
}

interface ItemReorderEventDetail {
  from: number;
  to: number;
  complete: (data?: boolean | any[]) => any;
}

const AddFilter: React.FC<AddFilterProps> = ({
  openAddFilter,
  setOpenAddFilter,
  facilityList,
  onApply,
}) => {
  const [addedFacilityList, setAddedFacilityList] = useState<any>([]);
  const [notAddedFacilityList, setNotAddedFacilityList] = useState<any>([]);

  useEffect(() => {
    const list = facilityList.filter((item: any) => item.selected === true);
    const notList = facilityList.filter((item: any) => item.selected === false);
    setAddedFacilityList(list);
    setNotAddedFacilityList(notList);
  }, [facilityList]);

  const addFacility = (id: any, data: boolean) => {
    let index = addedFacilityList.findIndex((item: any) => item._id === id);
    if (index >= 0) {
      let temp = [...addedFacilityList];
      temp[index].selected = data;
      setAddedFacilityList(temp);
    } else {
      let indexNew = notAddedFacilityList.findIndex(
        (item: any) => item._id === id
      );
      if (indexNew >= 0) {
        let tempNew = [...notAddedFacilityList];
        tempNew[indexNew].selected = data;
        setNotAddedFacilityList(tempNew);
      }
    }
  };

  const handleApply = (array: any) => {
    setOpenAddFilter(false);
    onApply(array);
  };

  function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
    event.detail.complete();
    handleFacilityReorder({
      from: event.detail.from,
      to: event.detail.to,
    });
  }

  const handleFacilityReorder = (reorderData: any) => {
    let newArray = [...addedFacilityList];
    const element = newArray.splice(reorderData.from, 1)[0];
    newArray.splice(reorderData.to, 0, element);
    setAddedFacilityList([...newArray]);
  };

  return (
    <>
      <IonModal
        className="addfacility-modal"
        isOpen={openAddFilter}
        onDidDismiss={() => setOpenAddFilter(false)}
        initialBreakpoint={0.9}
      >
        <IonHeader className="addfacility-header">
          <IonToolbar className="addfacility-toolbar">
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  setOpenAddFilter(false);
                }}
              >
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle className="addfacility-title">Add facility</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="addfacility-content">
          <div className="body-container-scroll">
            <div className="drag-container">
              <p>Drag to reorder</p>
              <IonReorderGroup
                disabled={false}
                onIonItemReorder={doReorder}
                className="reorderComponent"
              >
                {addedFacilityList.map((items: any, index: number) => (
                  <div className="list-container" key={items?._id}>
                    <span className="check-margin">
                      <CheckBox
                        value={items?._id}
                        checked={items?.selected}
                        onCheck={(data) => addFacility(items?._id, data)}
                      />
                    </span>
                    <div className="item-text-container">
                      <span className="item-text">{items?.name}</span>
                      <span className="item-subtext">
                        {items?.address?.street_line_1}
                      </span>
                    </div>
                    <IonReorder
                      children={
                        <IonImg
                          src={EllipseGroupBright}
                          className="item-right-icon"
                        />
                      }
                    />
                  </div>
                ))}
              </IonReorderGroup>
            </div>

            {notAddedFacilityList.length > 0 && (
              <div className="select-container">
                <p>Select to add</p>
                {notAddedFacilityList.map((item: any) => {
                  return (
                    <div className="list-container" key={item?._id}>
                      <span className="check-margin">
                        <CheckBox
                          value={item?._id}
                          checked={item?.selected}
                          onCheck={(data) => addFacility(item?._id, data)}
                        />
                      </span>
                      <div className="item-text-container">
                        <span className="item-text">{item?.name}</span>
                        <span className="item-subtext">
                          {item?.address?.street_line_1}
                        </span>
                      </div>
                      <IonImg
                        src={EllipseGroupDim}
                        className="item-right-icon"
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </IonContent>

        <IonFooter className="addfacility-footer">
          <Button
            tallbutton
            boldfont
            fullWidth
            color="primary"
            onClick={() =>
              handleApply([...addedFacilityList, ...notAddedFacilityList])
            }
          >
            <div className="button-text">Apply</div>
          </Button>
        </IonFooter>
      </IonModal>
    </>
  );
};

export default AddFilter;
