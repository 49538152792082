import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PatientData } from "../../types/Types";
import { RootState } from "../store";
// import { getFacility } from "../thunk/facilityThunk";
type UserState = {
  selectedPatient: PatientData;
};

const initialState: UserState = {
  selectedPatient: null,
};

export const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    setSelectedPatient: (state, action: PayloadAction<PatientData>) => {
      state.selectedPatient = action.payload;
    },
  },
});

export const patientSelected = (state: RootState) =>
  state.patient.selectedPatient;
export const { setSelectedPatient } = patientSlice.actions;
export const patientReducer = patientSlice.reducer;
