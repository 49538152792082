import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonRadioGroup,
  IonTitle,
  IonToolbar,
  // useIonToast,
} from "@ionic/react";
import React, { useState } from "react";
import { recallSubmission } from "../../helper/backendHelper";
import { VisitsByServicedate } from "../../types/VisitsByServicedate";
import RadioButton from "../Common/RadioButton/RadioButton";
import Toast, { newToast } from "../Common/Toaster/Toast";
import "./RecallBtnSecondPopup.scss";

interface RecallBtnSecondPopupType {
  secondPopupISOpen: boolean;
  setSecondPopupISOpen: (item: boolean) => void;
  visit?: VisitsByServicedate;
}

type ToastedList= {
  id: number;
  description: string,
};

const RecallBtnSecondPopup: React.FC<RecallBtnSecondPopupType> = ({
  secondPopupISOpen,
  setSecondPopupISOpen,
  visit,
}) => {
  const [selectedOption, setSelectedOption] = useState<any[]>([
    "billed the wrong patient",
  ]);
  // const [present, dismiss] = useIonToast();


  const [toastedMessage,setToastedMessage] = useState<ToastedList[]>([]);
  const closeModal = () => {
    setSecondPopupISOpen(false);
  };

  const onOk = (visit?: any) => {
    const getrecallSubmissionWithMsg = async () => {
      let res = await recallSubmission(visit?._id, selectedOption);
      if (res.status == "success") {
        visit.status = "Void";
        let toastMsg =  newToast("successfully updated procedure codes","Success");
            setToastedMessage([...toastedMessage, toastMsg]);
        return visit;
      } else {
        let toastMsg =  newToast("Failed to update status","Danger");
            setToastedMessage([...toastedMessage, toastMsg]);
      }
    };
    getrecallSubmissionWithMsg();
    setSecondPopupISOpen(false);
  };
  return (
    <><Toast toastList={toastedMessage} position="top-right" autoDelete={true} autoDeleteTime={4000}/>
      <IonModal
        className="SecondPopup_modal"
        isOpen={secondPopupISOpen}
        onDidDismiss={closeModal}
      >
        <IonHeader mode="ios" className="SecondPopup_modal_header">
          <IonToolbar className="SecondPopup_modal_toolbar">
            <IonTitle className="SecondPopup_modal_title">
              Please select the reason <br /> for recalling this visit:{" "}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="SecondPopup_modal_content">
          <div className="radio-container">
            <IonRadioGroup
              value={selectedOption}
              onIonChange={(e) => setSelectedOption(e.detail.value)}
            >
              <IonItem lines="none" className="radio-item">
                <RadioButton
                  value="billed the wrong patient"
                  className="fm-radio-btn"
                />
                <IonLabel className="radio-item-text">
                  billed the wrong patient
                </IonLabel>
              </IonItem>
              <IonItem lines="none" className="radio-item">
                <RadioButton
                  value="billed the wrong DOS"
                  className="fm-radio-btn"
                />
                <IonLabel className="radio-item-text">
                  billed the wrong DOS
                </IonLabel>
              </IonItem>
              <IonItem lines="none" className="radio-item">
                <RadioButton
                  value="billed in error (visit did not happen)"
                  className="fm-radio-btn"
                />
                <IonLabel className="radio-item-text">
                  billed in error (visit did not happen)
                </IonLabel>
              </IonItem>
            </IonRadioGroup>
          </div>

          <div className="modalButtons">
            <div
              className="cancelButton btn btn-primary"
              onClick={() => closeModal()}
            >
              <span>Cancel</span>
            </div>
            <div
              className="SecondPopupButton btn btn-primary"
              onClick={() => onOk(visit)}
            >
              <span>OK</span>
            </div>
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default RecallBtnSecondPopup;
