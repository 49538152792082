import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import layout from "./reducers/layout";
import { userReducer } from "./reducers/user";
import { facilityReducer } from "./reducers/facility";
import { patientReducer } from "./reducers/patient";
import forgotPassword from "./reducers/forgotPassword";
import { addVisitReducer } from "./reducers/addVisit";
import { censusReducer } from "./reducers/census";
import { newEncounterReducer } from "./reducers/newEncounter";
import { patientDetailsReducer } from "./reducers/patientDetails";
import { progressNoteReducer } from "./reducers/progressNote";
import { editProcedureAndDiagnosisReducer } from "./reducers/editProcedureAndDiagnosis";
import { messageReducer } from "./reducers/message";
import { dashboardSliceReducer } from "./reducers/dashboard";
export const store = configureStore({
  reducer: {
    layout: layout,
    user: userReducer,
    facility: facilityReducer,
    patient: patientReducer,
    patientDetails: patientDetailsReducer,
    forgotPassword: forgotPassword,
    addVisit: addVisitReducer,
    census: censusReducer,
    newEncounter: newEncounterReducer,
    progressNote: progressNoteReducer,
    editProcedureAndDiagnosis: editProcedureAndDiagnosisReducer,
    messages: messageReducer,
    dashboard: dashboardSliceReducer,
  },
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
