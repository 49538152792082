import { IonText } from "@ionic/react";

import "./CptModalItemCard.scss";
import React from "react";

const CptModalItemCard: React.FC<any> = ({
  item,
  diagnosisSelected,
  // favoriteButton,
}) => {
  return (
    <div className={`cptmodal-design `}>
      <div className="cpt-modal-card">
        <div
          className="cpt-modal-card"
          // onClick={() => cardSelectHandler(item)}
        >
          <IonText className={`cpt-modal-text`}>
            {`${item.code} - ${item.description}`}
          </IonText>
        </div>
      </div>
    </div>
  );
};

export default CptModalItemCard;
