import { createSlice } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";
import moment from "moment";
import { DailysummaryType } from "../../types/DailysummaryType";
import { VisitsByServicedate } from "../../types/VisitsByServicedate";
import { VisitStatusesTypes } from "../../types/VisitStatusesType";
import { RootState } from "../store";
import {
  getCensusDailysummary,
  getVisitsCensus,
  // updateCPTVisit,
} from "../thunk/censusThunk";

type UserState = {
  isloading: boolean;
  isError: boolean;
  errorMsg: any;
  dailysummary: DailysummaryType[];
  visitsByDate: VisitsByServicedate[];
  // encounters: VisitsByServicedate[];
  // inpatients: VisitsByServicedate[];
  patientsList: VisitsByServicedate[];
  paymentTotal: number;
  rvuTotal: number;
  serviceDate: string;
  visitStatuses: VisitStatusesTypes[];
  activeDate: string;
  censusFacility: any;
  facilitiesList: any[];
  allFacilitiesList: any[];
};

const initialState: UserState = {
  isloading: false,
  isError: false,
  errorMsg: "",
  dailysummary: [],
  visitsByDate: [],
  paymentTotal: 0,
  rvuTotal: 0,
  serviceDate: moment(new Date()).format("YYYY-MM-DD"),
  visitStatuses: [],
  // encounters: [],
  // inpatients: [],
  patientsList: [],
  activeDate: moment(new Date()).format("YYYY-MM-DD"),
  censusFacility: {},
  facilitiesList: [],
  allFacilitiesList: [],
};

export const censusSlice = createSlice({
  name: "census",
  initialState,
  reducers: {
    closeError: (state) => {
      state.isError = false;
      state.errorMsg = "";
    },
    closeLoading: (state) => {
      state.isloading = false;
    },
    setLoading: (state) => {
      state.isloading = true;
    },
    reSetAllData: (state) => {
      state.dailysummary = initialState.dailysummary;
      // state.encounters = initialState.encounters;
      state.errorMsg = initialState.errorMsg;
      // state.inpatients = initialState.inpatients;
      state.patientsList = initialState.patientsList;
      state.isError = initialState.isError;
      state.isloading = initialState.isloading;
      state.paymentTotal = initialState.paymentTotal;
      state.rvuTotal = initialState.rvuTotal;
      state.serviceDate = initialState.serviceDate;
      state.visitStatuses = initialState.visitStatuses;
      state.visitsByDate = initialState.visitsByDate;
    },

    setActiveDate: (state, action) => {
      state.activeDate = action.payload;
    },

    setCensusFacility: (state, action) => {
      state.censusFacility = action.payload;
    },

    setFacilitiesList: (state, action) => {
      state.facilitiesList = action.payload;
    },

    setFacilitiesListAddon: (state, action) => {
      // state.facilitiesList = action.payload;

      let currentFacility = state.allFacilitiesList.filter(
        (item) => item?._id === action.payload
      );

      let newSetFacility = [...state.facilitiesList, ...currentFacility];

      state.facilitiesList = uniqBy(newSetFacility, "_id");

      state.censusFacility = currentFacility[0];
    },
    setVisitStatusesCensus: (state, action) => {
      // state.facilitiesList = action.payload;

      state.visitStatuses = action.payload;
    },
    // setSocketCensus: (state, action) => {

    //   let indexEncounter = state.encounters.findIndex((item : any) => item._id === action.payload._id);
    //   let indexInpatient = state.inpatients.findIndex((item : any) => item._id === action.payload._id);

    //   if (indexEncounter >= 0) {
    //     let newData =JSON.parse(JSON.stringify(state.encounters));
    //     newData[indexEncounter] = action.payload;
    //     state.encounters = newData;
    //   }

    //    if (indexInpatient >= 0) {
    //     let newData =JSON.parse(JSON.stringify(state.inpatients));
    //     newData[indexInpatient] = action.payload;
    //     state.encounters = newData;
    //   }
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCensusDailysummary.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(getCensusDailysummary.fulfilled, (state, action) => {
        state.isloading = false;
        state.dailysummary = action.payload;
      })
      .addCase(getCensusDailysummary.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload;
        } else {
          state.errorMsg = action.error.message?.toString();
        }
      })
      .addCase(getVisitsCensus.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(getVisitsCensus.fulfilled, (state, action) => {
        state.isloading = false;
        state.visitsByDate = action.payload?.visitsByDate;
        state.rvuTotal = action.payload?.rvuTotal
          ? action.payload?.rvuTotal
          : 0;
        state.paymentTotal = action.payload?.paymentTotal
          ? action.payload?.paymentTotal
          : 0;
        state.patientsList = action.payload?.patientsList
          ? action.payload?.patientsList
          : [];
        state.serviceDate = action.payload?.serviceDate
          ? action.payload?.serviceDate
          : initialState.serviceDate;
        state.visitStatuses =
          action?.payload?.visitStatuses?.results[0]?.values;
      })
      .addCase(getVisitsCensus.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload;
        } else {
          state.errorMsg = action.error.message?.toString();
        }
      });
    // .addCase(updateCPTVisit.pending, (state, action) => {
    //   state.isloading = true;
    // })
    // .addCase(updateCPTVisit.fulfilled, (state, action) => {
    //   state.isloading = false;
    //   state.visitsByDate = action.payload?.visitList;
    //   state.rvuTotal = totalPaymentAndRUVFromVisits(
    //     action.payload?.visitList
    //   )?.rvuTotal;
    //   state.paymentTotal = totalPaymentAndRUVFromVisits(
    //     action.payload?.visitList
    //   )?.paymentTotal;
    //   state.serviceDate = action.payload?.serviceDate
    //     ? action.payload?.serviceDate
    //     : initialState.serviceDate;
    // })
    // .addCase(updateCPTVisit.rejected, (state, action) => {
    //   state.isloading = false;
    //   state.isError = true;
    //   if (action.payload) {
    //     // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
    //     state.errorMsg = action.payload;
    //   } else {
    //     state.errorMsg = action.error.message?.toString();
    //   }
    // })
  },
});

export const {
  reSetAllData,
  closeError,
  closeLoading,
  setActiveDate,
  setCensusFacility,
  setFacilitiesList,
  setLoading,
  setFacilitiesListAddon,
  setVisitStatusesCensus,
} = censusSlice.actions;

export const selectCensus = (state: RootState) => state.census;
export const censusReducer = censusSlice.reducer;
