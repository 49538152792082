import { IonCol, IonIcon, IonRow } from "@ionic/react";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { IconSvg } from "../Common/IconSvg/IconSvg";
import React, { useEffect, useState } from "react";
import './Analytics.scss';
import moment from "moment";

type AnalyticsHeadProps = {
    sliderContent: {
        sliderName: string,
        iconName?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    }[],
    getData?: (start: any, end: any) => void,
    getEncounter?: (start: any, end: any) => void,
    getRvu?: (start: any, end: any) => void,
    getSortedName?: (val: any) => void,
    getEncounterData?: (start: any, end: any) => void,
    getAvgRevenue?: (start:any, end:any) => void
}

export default function AnalyticsSlider({ sliderContent, getData, getEncounter, getRvu, getAvgRevenue, getEncounterData, getSortedName }: AnalyticsHeadProps) {
    const [count, setCount] = useState(0)
    const [selDate, setSelDate] = useState<AnalyticsHeadProps | unknown>([sliderContent[count]])

    const sliderData = sliderContent && sliderContent.filter((itm, index) => index === count && itm)
    const handleNext = () => {
        if (count <= sliderContent.length) {
            if (count === sliderContent.length - 1) {
            } else {
                setCount(count + 1)
                setSelDate(sliderContent[count])
            }
        }
    }

    const handlePrev = () => {
        if (count > 0 && count !== -1) {
            setCount(count - 1)
        }
    }

    const getRevenueByDate = async (start: string, end: string) => getData?.(start, end)
    const getEncounterByDate = async (start: string, end: string) => getEncounter?.(start, end)
    const getRvuByDate = async (start: string, end: string) => getRvu?.(start, end)
    const getDaysPerEnc = async (start: string, end: string) => getEncounterData?.(start, end)
    const getAvgRevenueByDate = async (start: string, end: string) =>  getAvgRevenue?.(start, end)
    
    useEffect(() => {
        if (sliderData[0].sliderName === "This Week") {
            const startWeekDate = moment().startOf("week").format("YYYY-MM-DD");
            const endWeekDate = moment().endOf("week").format("YYYY-MM-DD");
            getRevenueByDate(startWeekDate, endWeekDate)
        } else if (sliderData[0].sliderName === "Previous Month") {
            const prevStartDateOfMonth = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
            const prevEndDateOfMonth = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
            getRevenueByDate(prevStartDateOfMonth, prevEndDateOfMonth)
        } else if (sliderData[0].sliderName === "This Month") {
            const startDateOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD');
            const endDateOfCurrentMonth = moment().endOf('month').format('YYYY-MM-DD');
            getRevenueByDate(startDateOfCurrentMonth, endDateOfCurrentMonth)
        } else if (sliderData[0].sliderName === "This Year") {
            const startDateOfTheYear = moment().startOf('year').format("YYYY-MM-DD");
            const endDateOfTheYear = moment().endOf('year').format("YYYY-MM-DD");
            getRevenueByDate(startDateOfTheYear, endDateOfTheYear)
        }

        if (sliderData[0].sliderName === "This Week") {
            const startWeekDate = moment().startOf("week").format("YYYY-MM-DD");
            const endWeekDate = moment().endOf("week").format("YYYY-MM-DD");
            getEncounterByDate(startWeekDate, endWeekDate)
        } else if (sliderData[0].sliderName === "Previous Month") {
            const prevStartDateOfMonth = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
            const prevEndDateOfMonth = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
            getEncounterByDate(prevStartDateOfMonth, prevEndDateOfMonth)
        } else if (sliderData[0].sliderName === "This Month") {
            const startDateOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD');
            const endDateOfCurrentMonth = moment().endOf('month').format('YYYY-MM-DD');
            getEncounterByDate(startDateOfCurrentMonth, endDateOfCurrentMonth)
        } else if (sliderData[0].sliderName === "This Year") {
            const startDateOfTheYear = moment().startOf('year').format("YYYY-MM-DD");
            const endDateOfTheYear = moment().endOf('year').format("YYYY-MM-DD");
            getEncounterByDate(startDateOfTheYear, endDateOfTheYear)
        }

        if (sliderData[0].sliderName === "This Week") {
            const startWeekDate = moment().startOf("week").format("YYYY-MM-DD");
            const endWeekDate = moment().endOf("week").format("YYYY-MM-DD");
            getRvuByDate(startWeekDate, endWeekDate)
        } else if (sliderData[0].sliderName === "This Month") {
            const startDateOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD');
            const endDateOfCurrentMonth = moment().endOf('month').format('YYYY-MM-DD');
            getRvuByDate(startDateOfCurrentMonth, endDateOfCurrentMonth)
        } else if (sliderData[0].sliderName === "Previous Month") {
            const prevStartDateOfMonth = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
            const prevEndDateOfMonth = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
            getRvuByDate(prevStartDateOfMonth, prevEndDateOfMonth)
        } else if (sliderData[0].sliderName === "This Year") {
            const startDateOfTheYear = moment().startOf('year').format("YYYY-MM-DD");
            const endDateOfTheYear = moment().endOf('year').format("YYYY-MM-DD");
            getRvuByDate(startDateOfTheYear, endDateOfTheYear)
        }
        if (sliderData[0].sliderName === "This Week") {
            const startWeekDate = moment().startOf("week").format("YYYY-MM-DD");
            const endWeekDate = moment().endOf("week").format("YYYY-MM-DD");
            getDaysPerEnc(startWeekDate, endWeekDate)
        } else if (sliderData[0].sliderName === "Previous Month") {
            const prevStartDateOfMonth = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
            const prevEndDateOfMonth = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
            getDaysPerEnc(prevStartDateOfMonth, prevEndDateOfMonth)
        } else if (sliderData[0].sliderName === "This Month") {
            const startDateOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD');
            const endDateOfCurrentMonth = moment().endOf('month').format('YYYY-MM-DD');
            getDaysPerEnc(startDateOfCurrentMonth, endDateOfCurrentMonth)
        } else if (sliderData[0].sliderName === "This Year") {
            const startDateOfTheYear = moment().startOf('year').format("YYYY-MM-DD");
            const endDateOfTheYear = moment().endOf('year').format("YYYY-MM-DD");
            getDaysPerEnc(startDateOfTheYear, endDateOfTheYear)
        }

        if (sliderData[0].sliderName === "This Week") {
            const startWeekDate = moment().startOf("week").format('YYYY-MM-DD');
            const endWeekDate = moment().endOf("week").format('YYYY-MM-DD');
            getAvgRevenueByDate(startWeekDate, endWeekDate)
        } else if (sliderData[0].sliderName === "Previous Month") {
            const prevStartDateOfMonth = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
            const prevEndDateOfMonth = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
            getAvgRevenueByDate(prevStartDateOfMonth, prevEndDateOfMonth)
        } else if (sliderData[0].sliderName === "This Month") {
            const startDateOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD');
            const endDateOfCurrentMonth = moment().endOf('month').format('YYYY-MM-DD');
            getAvgRevenueByDate(startDateOfCurrentMonth, endDateOfCurrentMonth)
        } else if (sliderData[0].sliderName === "This Year") {
            const startDateOfTheYear = moment().startOf('year').format("YYYY-MM-DD");
            const endDateOfTheYear = moment().endOf('year').format("YYYY-MM-DD");
            getAvgRevenueByDate(startDateOfTheYear, endDateOfTheYear)
        }


        getSortedName?.(sliderData[0].sliderName)
    }, [count])


    return (
        <IonRow>
            <IonCol size="3">
                <IonIcon onClick={() => handlePrev()} className="backwardIcon" icon={chevronBackOutline} />
            </IonCol>
            <IonCol size="6" className="slider_dispaly ion-no-padding">
                {sliderData.map((itm, i) => <div className="slider-title" key={i}>{itm?.iconName && <div> <IconSvg Icon={itm.iconName} /></div>}{itm.sliderName}</div>)}
            </IonCol>
            <IonCol size="3" className=" ion-no-padding">
                <IonIcon onClick={() => handleNext()} className="forwardIcon" icon={chevronForwardOutline} />
            </IonCol>

        </IonRow>
    )
}