import React from "react";
import "./SearchFilter.scss";

interface SearchFilterProps {
  selected?: string;
  onSearchFilter?: any;
  filtOptions?: object;
}

export const SearchFilter: React.FC<SearchFilterProps> = (props: any) => {
  return (
    <div className="search-filter">
      <div className="search-filter__control">
        <select
          className="search-filter__control_option"
          value={props.selected}
          onChange={(e) => props.onSearchFilter(e.target.value)}
          id="searchFiltSelect"
        >
          {props.filtOptions.map((item: string) => (
            <option value={item}>{item}</option>
          ))}
        </select>
      </div>
    </div>
  );
};
