import { useState, useEffect } from "react";
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonModal
} from "@ionic/react";
import { IonCol } from "@ionic/react";
import {
  chevronForwardOutline
} from "ionicons/icons";

import Buttons from "../Common/Buttons/Buttons";
import { closeOutline } from "ionicons/icons";
import "../../pages/PracticeIQ/PracticeIQ.scss";
interface FilterModalPropsType {
  isFilterOpen: boolean;
  handleFilterModal: (item: boolean) => void;
  handleFilterChange: (sort: String) => any;
  optionList: any;
  selectedFilterOptionList?: any;
}
const FilterModalPracticeIQ = (props: FilterModalPropsType) => {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [selectedList, setSelectedList] = useState<string>("");
  const [optionLists, setOptionLists] = useState<any>(props.optionList.map((itm: any) => {
    if (itm.id === "placeofservice") {
      let pocSublist: Array<any> = []
      return {
        ...itm, sublist: itm.sublist.filter((v: any) => {
          if (pocSublist.length === 0) {
            pocSublist.push(v)
            return v
          } else {
            if (pocSublist.filter((u: any) => u.value !== v.value) !== undefined) {
              pocSublist.push(v)
              return v
            }
          }
          //return pocSublist
        })
      }
    } else return itm
  }));

  const handleFilterModal = (item: boolean) => {
    setIsFilterOpen(item);
  };
  // const handleFilterChange = (sortOptionData: String) => {
  //   //console.log("sort", sortOptionData)
  // };
  const [selectedSortOption, setSelectedSortOption] = useState("");
  const [selectedFilterOption, setSelectedFilterOption] = useState<any>(props.selectedFilterOptionList);
  // const selectEvent =[{insurancecompany1:"amazon1"},{insurancecompany2:"amazon2"},{insurancecompany3:"amazon3"}];
  const handleDone = () => {
    props.handleFilterChange(selectedFilterOption);
    /* props.handleFilterChange({
      sort: selectedSortOption,
      filter: selectedFilterOption,
    }); */
    props.handleFilterModal(false);
    // props.handleFilterChange(selectedFilterOption);
  };
  const existendanceCheck = (name: string, id: any) => {
    if (selectedFilterOption[name]['item'].some((x: any) => x.id == id)) {
      return true;
    } else {
      return false;
    }
  }
  const onSearchClear = () => {
    setSelectedFilterOption({ "insurance": { 'item': [] }, "provider": { 'item': [] }, "placeofservice": { 'item': [] }, "facility": { 'item': [] }, "codeGroup": { 'item': [] }, "code": { 'item': [] } });
  };
  const filterhandler = async (name: string, item: any, value: boolean) => {
    if (value) {
      let temp = await JSON.parse(JSON.stringify(selectedFilterOption));
      await temp[name]['item'].push(item);
      await setSelectedFilterOption(temp);
    } else {
      let temp = await JSON.parse(JSON.stringify(selectedFilterOption));
      temp[name]['item'] = await temp[name]['item'].filter(
        (itemx: any) => itemx.id !== item.id
      );
      await setSelectedFilterOption(temp);
    }
  };

  const handleListClick = (id: string) => {
    if (selectedList !== id) {
      setIsListOpen(true);
      setSelectedList(id);

    } else {
      setIsListOpen(false);
      setSelectedList("");
    }
  };
  useEffect(() => {
    setSelectedFilterOption(props.selectedFilterOptionList)
    setOptionLists(props.optionList)
  }, [props.selectedFilterOptionList])
  return (
    <IonModal
      className="filter-modal"
      isOpen={props.isFilterOpen}
      onDidDismiss={() => {
        props.handleFilterModal(false);
      }}
      breakpoints={[0, 0.3, 0.4, 0.8, 1]}
      initialBreakpoint={0.8}
      style={{ overflowY: "scroll" }}
    // backdropBreakpoint={0.2}
    >
      <div className="modal-content-container">
        <div className="modal-heading-container">
          <div style={{}} className="modal-heading-filter">Filter</div>
          <div className="modal-icon-holder">
            <IonIcon
              onClick={() => props.handleFilterModal(false)}
              className="close-icon"
              icon={closeOutline}
            />
          </div>
        </div>
        <div className="">
          <div className="filter-container">
            {optionLists != undefined && optionLists?.map((itm: any) => {
              if (itm.id === "placeofservice") {
                let pocSublist: Array<any> = []
                return {
                  ...itm, sublist: itm.sublist.filter((v: any) => {
                    if (pocSublist.length === 0) {
                      pocSublist.push(v)
                      return v
                    } else {
                      if (pocSublist.find((u: any) => u.value !== v.value) !== undefined) {
                        pocSublist.push(v)
                        return v
                      }
                    }
                  })
                }
              } else return itm
            }).map((data: any, index: number) => {
              if (!data.ids) {
                return (
                  <>
                    <IonItem
                      lines="none"
                      className="filter-item"
                      key={index}
                      onClick={() => handleListClick(data.id)}
                    >
                      <IonLabel className="filter-item-text ">
                        {data.value}
                      </IonLabel>
                      <IonCol><small className="filter-item-inner-texts" style={{ 'float': 'left' }}>{selectedFilterOption[data.id] && selectedFilterOption[data.id]['item'].map((x: any) => x.value).join(",")}
                      </small></IonCol>
                      <IonCol
                        className="left_right_icon ion-no-padding"
                        // onClick={handlePrevDate}
                        size="1"
                      >
                        {(isListOpen && selectedList === data.id) ? <IonIcon
                          className="backwardIconDownFilter"
                          icon={chevronForwardOutline}
                        /> : <IonIcon
                          className="backwardIconFilter"
                          icon={chevronForwardOutline}
                        />}
                      </IonCol>
                    </IonItem>
                    {data ?.sublist.length > 0 ? data.sublist.map((item: any, i: number) => {
                      return (
                        <>

                          {isListOpen && selectedList === data.id && (
                            <IonItem key={i}
                              color={
                                existendanceCheck(data.id, item.id) ? "primary" : ""
                              }
                              lines="none"
                              className="filter-item"
                              onClick={() => {
                                filterhandler(data.id, item, !existendanceCheck(data.id, item.id));
                              }}

                            >

                              <IonLabel className="filter-item-text-texts">
                                {item.value}
                              </IonLabel>
                            </IonItem>
                          )}

                        </>
                      );
                    }) : (isListOpen && selectedList === data.id) ?  
                    <IonItem  lines="none" className="filter-item">
                      <IonLabel className="filter-item-text-texts">
                        No record found
                      </IonLabel>
                    </IonItem> : "" }

                  </>
                );
              }
            })}
          </div>

          <div className="event-buttons">
            <Buttons fullWidth color="secondary" onClick={onSearchClear}>
              <div className="left-btn-holder">Clear all</div>
            </Buttons>
            <Buttons fullWidth color="primary" onClick={handleDone}>
              <div className="right-btn-holder">Done</div>
            </Buttons>
          </div>
        </div>
      </div>
      {/* </div> */}
    </IonModal>
  );
};

export default FilterModalPracticeIQ;
