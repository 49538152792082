import { IonAccordion, IonAccordionGroup, IonIcon } from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";
import moment from "moment";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  handleRegroupCode,
  setDateOfService,
  setDiagnosisCode,
  setProcedureCode,
} from "../../store/reducers/newEncounter";
import AdditionalInfoCard from "../Encounter/AdditionalCard/AdditionalInfo";
import DateOfServiceCard from "../Encounter/DateOfServiceCard/DateOfServiceCard";
import DiagnosisCard from "../Encounter/DiagnosisCard/DiagnosisCard";
import ProcedureCard from "../Encounter/ProcedureCard/ProcedureCard";

interface uploadFileType {
  fileType?: string;
  fileName?: string;
  fileSize?: string;
  status?: string;
  originalFileInfo?: any;
}

const PatientDetails = () => {
  const thisEncounterState = useAppSelector((state) => state.newEncounter);
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<uploadFileType[]>([]);
  const onDateSelect = (date: any) => {
    dispatch(setDateOfService(moment(date).format("YYYY-MM-DD")));
  };

  const onDiagnosisCodeSet = (code: any) => {
    dispatch(setDiagnosisCode(code));
  };

  const onProcedureCodeSet = (code: any) => {
    dispatch(setProcedureCode(code));
  };

  const handleCodeRegroup = (regroupData: any) => {
    dispatch(handleRegroupCode(regroupData));
  };
  return (
    <div className="patient-details">
      <IonAccordionGroup>
        <IonAccordion className="accordian" value={"Patient Details"}>
          <div slot="header">
            <div className="header-holder">
              <div className="content">
                <h2 className="card-title">Patient Details</h2>
                <p className="sub-title">
                  Will be processed based on voice note
                </p>
              </div>
              <div className="icon-holder">
                <IonIcon icon={chevronDownOutline} />
              </div>
            </div>
          </div>
          <div slot="content">
            <h1>Patient Details</h1>
          </div>
        </IonAccordion>
        <IonAccordion className="accordian" value={"Date of service"}>
          <div slot="header">
            <div className="header-holder">
              <div className="content">
                <h2 className="card-title">Date of service</h2>
                <p className="sub-title">
                  Will be processed based on voice note
                </p>
              </div>
              <div className="icon-holder">
                <IonIcon icon={chevronDownOutline} />
              </div>
            </div>
          </div>
          <div slot="content">
            <DateOfServiceCard
              onSelect={onDateSelect}
              dateValue={thisEncounterState?.dateOfService}
            />
          </div>
        </IonAccordion>
        <IonAccordion className="accordian" value={"Diagnosis codes"}>
          <div slot="header">
            <div className="header-holder">
              <div className="content">
                <h2 className="card-title">Diagnosis codes</h2>
                <p className="sub-title">
                  Will be processed based on voice note
                </p>
              </div>
              <div className="icon-holder">
                <IonIcon icon={chevronDownOutline} />
              </div>
            </div>
          </div>
          <div slot="content">
            <DiagnosisCard
              selectedCode={thisEncounterState.diagnosisCodesSelected}
              onSetCode={onDiagnosisCodeSet}
              handleRegroup={handleCodeRegroup}
            />
          </div>
        </IonAccordion>
        <IonAccordion className="accordian" value={"Procedure codes"}>
          <div slot="header">
            <div className="header-holder">
              <div className="content">
                <h2 className="card-title">Procedure codes</h2>
                <p className="sub-title">
                  Will be processed based on voice note
                </p>
              </div>
              <div className="icon-holder">
                <IonIcon icon={chevronDownOutline} />
              </div>
            </div>
          </div>
          <div slot="content">
            <ProcedureCard
              selectedCode={thisEncounterState.procedureCodesSelected}
              onSetCode={onProcedureCodeSet}
              handleRegroup={handleCodeRegroup}
            />
          </div>
        </IonAccordion>
        <IonAccordion className="accordian" value={"Additional info"}>
          <div slot="header">
            <div className="header-holder">
              <div className="content">
                <h2 className="card-title">Additional info</h2>
                <p className="sub-title"></p>
              </div>
              <div className="icon-holder">
                <IonIcon icon={chevronDownOutline} />
              </div>
            </div>
          </div>
          <div slot="content">
            <AdditionalInfoCard files={files} setFiles={setFiles} />
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </div>
  );
};

export default PatientDetails;
