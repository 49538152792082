import { createAsyncThunk } from "@reduxjs/toolkit";
import { forgotpwd, verifyCode } from "../../helper/backendHelper";
import { ForgotpwdOtp, VerifyUsernameType } from "../../types/Types";

export const verifyUsername = createAsyncThunk(
  //action type string
  "forgotPassword/verifyUsername",
  // callback function
  async (data: VerifyUsernameType, thunkAPI) => {
    const verifyUsernameRes = await forgotpwd(data);
    
    if (verifyUsernameRes?.status === 500) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      if (verifyUsernameRes?.userNotFind 
          || verifyUsernameRes?.status === "failure" 
          || verifyUsernameRes?.name === "ValidationError") {
        return thunkAPI.rejectWithValue("Username Not Found");
      } else {
        return { ...verifyUsernameRes, username: data.username };
      }
    }
  }
);

export const verifyForgotpwdOtp = createAsyncThunk(
  //action type string
  "forgotPassword/verifyForgotpwdOtp",
  // callback function
  async (data: ForgotpwdOtp, thunkAPI) => {
    const verifyOtpRes = await verifyCode(data);

    if (verifyOtpRes?.status === 500) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      if (verifyOtpRes?.status !== "failure") {
        return {...verifyOtpRes,otp:data.otp};
      } else {
        return thunkAPI.rejectWithValue("Username Not Found");
      }
    }
  }
);
