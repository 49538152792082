import React from "react";
import ToggleButtonBordered from "./togglebuttonbordered";
import ToggleButtonBorderedLabel from "./togglebuttonborderedlabel";
import ToggleButtonFilled from "./togglebuttonfilled";
import ToggleButtonFilledLabel from "./togglebuttonfilledlabel";
import "./viewtogglebutton.scss";

const ToggleButtons: React.FC = () => {
  let data = {
    arr: [
      {
        name: "Male",
      },
      {
        name: "Female",
      },
      {
        name: "Unknown",
      },
    ],
    buttonType: {
      type: "filled",
    },
    labelName: {
      name: "Gender",
    },
  };

  return (
    <div className="main-toggle-button">
      <div className="left-toggle-button">
        <ToggleButtonFilled data={data} />
        <ToggleButtonFilledLabel data={data} />
      </div>
      <div className="right-toggle-button">
        <ToggleButtonBordered data={data} />
        <ToggleButtonBorderedLabel data={data} />
      </div>
    </div>
  );
};

export default ToggleButtons;
