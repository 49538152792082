import React from "react";
import { IonLoading, IonPage } from "@ionic/react";
// import { useAppDispatch } from "../../store/hooks";
// import { closeError } from "../../store/reducers/user";

type LayoutType = {
  isLoading: boolean;
  isError?: boolean;
  errorMsg?: string;
  className?: string;
};

export const Layout: React.FC<LayoutType> = ({
  children,
  isLoading,
  errorMsg,
  isError,
  className,
}) => {
  // const [present, dismiss] = useIonLoading();

  // const dispatch = useAppDispatch();

  return (
    <IonPage className={`${className ? className : ""}`}>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={isLoading}
        message={"Please wait..."}
      />
      {children}
      {/* <IonToast
        isOpen={isError ? true : false}
        onDidDismiss={() => dispatch(closeError())}
        message={errorMsg}
        position="bottom"
        duration={5}
      /> */}
    </IonPage>
  );
};
