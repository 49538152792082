import React from "react";
import { checkmarkOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import "./VisitHistory.scss";
import { visitDetailsSeriesType } from "../../../types/VisitDetailsSeriesType";
import moment from "moment";

type VisitHistoryProps = {
  data?: visitDetailsSeriesType[];
};

const VisitHistory: React.FC<VisitHistoryProps> = (props) => {
  return (
    <div className="table-container">
      <table className="visit-history-table">
        <thead className="table-head">
          <tr>
            <th></th>
            <th>Date</th>
            <th>CPT</th>
            <th>Physician</th>
            <th>Facility</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {props?.data?.map((item: visitDetailsSeriesType, i: any) => (
            <tr key={i}>
              <td>
                <IonIcon
                  className="check-icon"
                  icon={checkmarkOutline}
                ></IonIcon>
              </td>
              <td>{moment(item?.service_date_from)?.format("MM/DD dd")}</td>
              <td>
                {item.procedure_code_primary?.code
                  ? item.procedure_code_primary?.code
                  : "--"}
              </td>
              <td>
                {item?.rendering_provider_name?.split(" ")[0]?.charAt(0) +
                  item?.rendering_provider_name?.split(" ")[1]?.charAt(0)}
              </td>
              <td>
                {item.facility_shortname
                  ? item.facility_shortname + " - " + item.facility_name
                  : item.facility_name}
              </td>
            </tr>
          ))}
          {/* <tr>
            <td>
              <IonIcon className="check-icon" icon={checkmarkOutline}></IonIcon>
            </td>
            <td>2/26 We</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="alert-icon" icon={alertOutline}></IonIcon>
            </td>
            <td>2/25 Tu</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="person-icon" icon={person}></IonIcon>
            </td>
            <td>2/24 Mo</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="check-icon" icon={checkmarkOutline}></IonIcon>
            </td>
            <td>2/26 We</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="alert-icon" icon={alertOutline}></IonIcon>
            </td>
            <td>2/25 Tu</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="person-icon" icon={person}></IonIcon>
            </td>
            <td>2/24 Mo</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="check-icon" icon={checkmarkOutline}></IonIcon>
            </td>
            <td>2/26 We</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="alert-icon" icon={alertOutline}></IonIcon>
            </td>
            <td>2/25 Tu</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="person-icon" icon={person}></IonIcon>
            </td>
            <td>2/24 Mo</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="check-icon" icon={checkmarkOutline}></IonIcon>
            </td>
            <td>2/26 We</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="alert-icon" icon={alertOutline}></IonIcon>
            </td>
            <td>2/25 Tu</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="person-icon" icon={person}></IonIcon>
            </td>
            <td>2/24 Mo</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="check-icon" icon={checkmarkOutline}></IonIcon>
            </td>
            <td>2/26 We</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="alert-icon" icon={alertOutline}></IonIcon>
            </td>
            <td>2/25 Tu</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="person-icon" icon={person}></IonIcon>
            </td>
            <td>2/24 Mo</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="check-icon" icon={checkmarkOutline}></IonIcon>
            </td>
            <td>2/26 We</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="alert-icon" icon={alertOutline}></IonIcon>
            </td>
            <td>2/25 Tu</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr>
          <tr>
            <td>
              <IonIcon className="person-icon" icon={person}></IonIcon>
            </td>
            <td>2/24 Mo</td>
            <td>99232</td>
            <td>AC</td>
            <td>Bushwood Medical...</td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
};

export default VisitHistory;
