import { IonCol, IonGrid, IonIcon, IonImg, IonRow } from "@ionic/react";
import React, { useState } from "react";
import AdmissionStatus from "./TodayFacilityInfo/AdmissionStatus";
import PossibleRVU from "./TodayFacilityInfo/PossibleRVU";
import TotalPatients from "./TodayFacilityInfo/TotalPatients";
import DaughnutChartImg from "../../images/DaughnutChart.svg";
import ColumnChartImg from "../../images/ColumnChart.svg";
import LineChartImg from "../../images/LineChart.svg";
import { close } from "ionicons/icons";
import AddFilter from "../../components/Common/AddFilter/AddFilter";

export const dateRage = [
  { id: "0", name: "Today", value: "1", key: "today" },
  { id: "1", name: "Last week", value: "2", key: "week" },
  { id: "2", name: "Last month", value: "3", key: "month" },
  { id: "3", name: "Year to date", value: "4", key: "year" },
];

export const graphType = [
  { id: "0", name: "Doughnut chart", value: "1", image: DaughnutChartImg },
  { id: "1", name: "Column chart", value: "2", image: ColumnChartImg },
  { id: "2", name: "Line chart", value: "3", image: LineChartImg },
];

interface TodayFacilityInfoProps {
  todaysFacList: any[];
  setTodaysFacList: (item: any[]) => void;
}

const TodayFacilityInfo: React.FC<TodayFacilityInfoProps> = ({
  todaysFacList,
  setTodaysFacList,
}) => {
  const [openAddFilter, setOpenAddFilter] = useState(false);

  const closeFacility = (facilityId: any) => {
    let newFacList = [...todaysFacList];
    const index = todaysFacList.findIndex(
      (item: any) => item._id === facilityId
    );
    if (index >= 0) {
      newFacList[index].selected = false;
      setTodaysFacList(newFacList);
    }
  };

  const handlesetFacility = (array: any) => {
    setTodaysFacList(array);
  };

  return (
    <div className="facility-info">
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <div className="facility-info-header">
              <div className="facility-info-header-container">
                <span className="facility-info-heading">
                  Today&apos;s facility information
                </span>
                <div className="facility-info-content">
                  <div className="facility-scroll-holder">
                    <div className="facility-scroll">
                      {todaysFacList &&
                        todaysFacList
                          .filter((item: any) => item.selected === true)
                          .map((item: any, index: number) => (
                            <>
                              {index <= 1 && (
                                <div key={item._id}>
                                  <div className="facility-card-container">
                                    <span>{item?.name}</span>
                                    <IonIcon
                                      icon={close}
                                      onClick={() => closeFacility(item?._id)}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          ))}

                      {/* {facilityList?.filter((item) => item.selected === true)
                    ?.length -
                    2 >
                    0 && (
                    <span>
                      +
                      {facilityList?.filter((item) => item.selected === true)
                        ?.length - 2}{" "}
                      more{" "}
                      {facilityList?.filter((item) => item.selected === true)
                        ?.length -
                        2 ===
                      1
                        ? "facility"
                        : "facilities"}
                    </span>
                  )} */}
                      <span
                        className="add-facility"
                        onClick={() => {
                          setOpenAddFilter(true);
                        }}
                      >
                        Add +
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <div className="scroll-holder">
              <div className="chart-scroller">
                <div className="charts-holder">
                  <TotalPatients todaysFacList={todaysFacList} />
                  <AdmissionStatus
                    selectedFacilities={todaysFacList
                      .filter((item) => item.selected === true)
                      .map((item) => item.facility_id)}
                  />
                  <PossibleRVU todaysFacList={todaysFacList} />
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      <AddFilter
        openAddFilter={openAddFilter}
        setOpenAddFilter={setOpenAddFilter}
        facilityList={todaysFacList}
        onApply={(array) => handlesetFacility(array)}
      />
    </div>
  );
};

export default TodayFacilityInfo;
