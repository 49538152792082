import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getMipsmeasures,
  getPatientsSearchByID,
  getProgressNoteImage,
  getProgressNoteTemplates,
  getVisitCharts,
  getVisitDetails,
  getVisitDiagnosis,
  getVisitProcedures,
} from "../../helper/backendHelper";

type InitialDataResponceType = {
  chartData?: any;
  progressImg?: any;
  diagnosisData?: any;
  proceduresData?: any;
  visitDetail?: any;
  patientDetail?: any;
  templates?: any;
  mips?: any;
};

export const getInitialPayload = createAsyncThunk(
  //action type string
  "getProgressNote/initialPayload",
  // callback function
  async (visitid: string, thunkAPI) => {
    const resTemplate = await getProgressNoteTemplates();
    const resMipsmeasures = await getMipsmeasures();
    const resImg = await getProgressNoteImage(visitid);
    const resDiagnosis = await getVisitDiagnosis(visitid);
    const resProcedures = await getVisitProcedures(visitid);
    const resVisitDetail = await getVisitDetails({ id: visitid });
    const resPatient = await getPatientsSearchByID({ id: visitid });

    const res = await getVisitCharts(visitid);

    if (resTemplate?.status === 500) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      let responce: InitialDataResponceType = {};
      if (resTemplate?.status === "success") {
        responce = {
          templates: resTemplate?.results,
          mips: resMipsmeasures?.results,
          patientDetail: resPatient?.result?.[0],
          visitDetail: resVisitDetail?.resData?.[0],
          diagnosisData: resDiagnosis?.results,
          proceduresData: resProcedures?.results,
        };
      }

      if (res?.status === "success" && resVisitDetail?.status === "success") {
        responce = {
          ...responce,
          chartData: res?.data,
          progressImg: resImg?.data,
        };
      }
      return responce;
    }
  }
);

export const getChartData = createAsyncThunk(
  //action type string
  "getProgressNote/chartData",
  // callback function
  async (visitid: string, thunkAPI) => {
    const resImg = await getProgressNoteImage(visitid);

    if (resImg?.status === 500) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      let responce: InitialDataResponceType = {};

      if (resImg?.status === "success") {
        responce = {
          progressImg: resImg?.data,
        };
      }
      return responce;
    }
  }
);
