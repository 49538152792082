/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Tab.scss";

type TabRouterProps = {
  children?: any | any[];
};

type TabNavProps = {
  component: React.FC;
};

type tabType = {
  id: number;
  child: React.ReactChild | React.ReactChild[];
  component: React.FC;
  element: any;
  props: any;
};

export const Tab: React.FC = ({ children }) => {
  return <div className="tab-view">{children}</div>;
};

export const TabRouter: React.FC<TabRouterProps> = ({ children }) => {
  // let tabList: tabType[] = [];

  const [tabList, setTabList] = useState<tabType[]>([]);

  const [activeTab, setActiveTab] = useState<tabType | undefined>();

  useEffect(() => {
    let tempList: tabType[] = [];

    React.Children.map(children, (child, index) => {
      tempList.push({
        id: index,
        child: child?.props?.children,
        component: child?.props?.component,
        props: child?.props,
        element: child?.props?.element,
      });
    });

    setTabList(tempList);

    return () => {
      setTabList([]);
    };
  }, [children]);

  useEffect(() => {
    setActiveTab(tabList[0]);

    return () => {
      setActiveTab(undefined);
    };
  }, [tabList]);

  return (
    <div className="tab-container">
      <div className="tab-nav-holder">
        <div className="tab-nav">
          {tabList.map((tab, index) => {
            let isActive = tab.id === activeTab?.id;
            return (
              <div
                className={`tab-nav-items ${isActive ? "active" : ""}`}
                onClick={() => setActiveTab(tab)}
                key={index}
              >
                {tab.child}
              </div>
            );
          })}
        </div>
      </div>

      <div className="tab-component-holder">
        {activeTab?.component ? (
          <activeTab.component {...activeTab.props} />
        ) : null}
      </div>
    </div>
  );
};

export const TabNav: React.FC<any> = () => {
  return <></>;
};

export default Tab;
