import { IonText } from "@ionic/react";
import { useEffect, useState } from "react";
import DataTable from "../../pages/PracticeIQ/DataTable";

type FilterProps = {
    data: {
        code: {
            item: { id: string, value: string }[]
        },
        codcodeGroup: {
            item: { id: string, value: string }[]
        },
        facility: {
            item: { id: string, value: string }[]
        },
        insurance: {
            item: { id: string, value: string }[]
        },
        placeofservice: {
            item: { id: string, value: string }[]
        },
        provider: {
            item: { id: string, value: string }[]
        },
    },
    resInfo: {
        code: string;
        code_group: string;
        facility: string;
        first_name: string;
        last_name: string;
        payment_type: string;
        payor: string;
        percentage: number;
        practice_id: string;
        practice_name: string;
        provider_id: string;
        revenue: number;
        time: string;
        title: string;
    }[];
    allData: {
        proc_code_name: string;
        proc_group_name: string;
        total_count: number;
        _id: {
            facility_id: string;
            payment_type: string;
            payor: string;
            proc_code: string;
            proc_code_group: string;
            provider_id: string;
            service_date_from: string;
        }
    }[],
    colors: string[];
    slider2Data: string;
    revenue?: boolean;
    encounter?: boolean;
    rvu?: boolean;
    compnent: string;

}


export default function FilterTable({ data, resInfo, allData, colors, slider2Data, revenue, encounter, compnent }: FilterProps) {

    const [filtData, setFiltData] = useState<any>([])

    const getDataByFilterVal = async (info: any, actualInfo: any) => {

        const allIds: Array<string> = []
        const values: Array<string> = []
        for (let key in info) {
            info[key].item.map((itm: any) => {
                if (itm?.id) {
                    allIds.push(itm.id)
                    values.push(itm.value)
                }
            })
        }

        let fullDataArr = await JSON.parse(JSON.stringify(actualInfo));
        if (info["insurance"].item.length > 0) {
            let insurenceidArr = await info["insurance"].item.map((x: any) => x.id);
            fullDataArr = await fullDataArr.filter((x: any) => {
                if (insurenceidArr.includes(x._id.payor.toString())) {
                    return x;
                }
            });
        }
        if (info["provider"].item.length > 0) {
            let provideridArr = await info["provider"].item.map((x: any) => x.id);
            fullDataArr = await fullDataArr.filter((x: any) => {
                if (provideridArr.includes(x._id.provider_id.toString())) {
                    return x;
                }
            });
        }
        if (info["facility"].item.length > 0) {
            let facilityArr = await info["facility"].item.map((x: any) => x.id);
            fullDataArr = await fullDataArr.filter((x: any) => {
                if (facilityArr.includes(x._id.facility_id.toString())) {
                    return x;
                }
            });
        }
        if (info["codeGroup"].item.length > 0) {
            let codegroupArr = await info["codeGroup"].item.map((x: any) => x.id);
            fullDataArr = await fullDataArr.filter((x: any) => {
                if (codegroupArr.includes(x._id.proc_code_group.toString())) {
                    return x;
                }
            });
        }
        if (info["code"].item.length > 0) {
            let codeArr = await info["code"].item.map((x: any) => x.id);
            fullDataArr = await fullDataArr.filter((x: any) => {
                if (codeArr.includes(x._id.proc_code.toString())) {
                    return x;
                }
            });
        }
        setFiltData(fullDataArr)
    }
    useEffect(() => {
        if (allData.length > 0) {
            getDataByFilterVal(data, allData)
        }
    }, [data, allData])
    return (
        <div>
            {filtData.length > 0 ?
                <DataTable
                    header={["Type", compnent === 'daysperenc'  ? "" : compnent === 'avgRevenue' ? "" : compnent , compnent === 'daysperenc' ? 'Avg.Days' : compnent === 'avgRevenue' ? 'Average' : '%' ]}
                    columnSize={["5", "4", "2"]}
                    revRes={compnent != 'daysperenc' ? filtData : []}
                    daysPerEncRes={compnent == 'daysperenc' ? filtData : []}
                    colors={colors}
                    slider2Data={slider2Data}
                    displayType="filter"
                    compnent={compnent}
                /> : <div> <IonText className="text-icon "> No Record Found</IonText></div>
            }
        </div>
    )
}