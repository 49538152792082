import React, { useEffect, useState } from "react";
// import { CChartLine } from "@coreui/react-chartjs";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { memo } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart: React.FC<any> = (props) => {
  const [date, setDate] = useState<any>();

  useEffect(() => {
    let daysAgo = [];
    for (let i = 4; i >= 0; i--) {
      daysAgo.push(moment().subtract(i, "days").format("MMM DD"));
    }
    setDate(daysAgo);
  }, []);

  // console.log({ props });

  return (
    <div>
      {props.withHorizontalLabel ? (
        <div>
          <Line
            className="line-chart-total"
            // type="line"
            data={{
              labels: props?.lables ? props?.lables : date,
              datasets: [
                {
                  label: props?.dataLable ? props?.dataLable : "",
                  borderWidth: 1,
                  backgroundColor: "rgba(220, 220, 220, 0.2)",
                  borderColor: "#9BE8E2",
                  pointBackgroundColor: "rgba(220, 220, 220, 1)",
                  pointBorderColor: "#fff",
                  data: props?.datas ? props?.datas : [10, 20, 12, 39, 10],
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
              elements: {
                // line: {
                //   tension: -0.5,
                // },
                point: {
                  radius: 1,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  grid: {
                    color: "#4F4F4F",
                  },
                  display: true,
                },
                y: {
                  grid: {
                    color: "#4F4F4F",
                  },
                  beginAtZero: true,
                  display: true,
                },
              },
            }}
          />
        </div>
      ) : (
        <div>
          <Line
            // type="line"
            data={{
              labels: props?.lables
                ? props?.lables
                : props?.data?.length > 0 &&
                  props?.data?.reduce((a: any, b: any) => a + b, 0) > 0
                ? props?.data?.map((item: number) => {
                    if (item >= 0) {
                      return "";
                    }
                  })
                : ["", "", "", "", ""],
              datasets: [
                {
                  label: props?.lable ? props?.lable : "",
                  borderWidth: 1,
                  backgroundColor: "rgba(220, 220, 220, 0.2)",
                  borderColor: "#9BE8E2", //'rgba(220, 220, 220, 1)',
                  pointBackgroundColor: "rgba(220, 220, 220, 1)",
                  pointBorderColor: "#fff",
                  data: props.data || [],
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
              elements: {
                // line: {
                //   tension: 1.4,
                // },
                point: {
                  radius: 1,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  grid: {
                    color: "#4F4F4F",
                  },
                  display: true,
                },
                y: {
                  grid: {
                    color: "#4F4F4F",
                  },
                  beginAtZero: true,
                  display: true,
                },
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default LineChart;
