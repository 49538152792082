/* eslint-disable react-hooks/exhaustive-deps */
import { IonInput, IonLabel, IonTextarea } from "@ionic/react";
import React, { useEffect, useState } from "react";
import CheckBox from "../components/Common/CheckBox/CheckBox";
import { useAppDispatch } from "../store/hooks";
import { setInvalide } from "../store/reducers/progressNote";
import {
  InputsEntity,
  OptionsEntity,
} from "../types/MedicalchartTemplatesType";

export const dynamicInputGenerator = (
  data: OptionsEntity | null,
  onChange: (data: any) => void,
  disabled?: boolean,
  isRequired?: boolean
) => {
  let InputComp;

  const onTextChange = (inputs: any) => {
    onChange({ ...data, inputs: inputs });
  };

  // console.log({ disabled });

  switch (data?.inputType) {
    case "textarea":
      InputComp = (
        <TextArea
          label={data.label}
          placeholder={data.placeholder ? data.placeholder : undefined}
          onChange={onChange}
          data={data}
          disabled={disabled}
          isRequired={isRequired}
        />
      );
      break;
    case "custom":
      if (data.checkbox && data.inputs) {
        InputComp = (
          <CheckboxFields
            label={data.label}
            onChange={onTextChange}
            hasInput={data.inputs}
            data={data}
            checked={data?.isChecked}
            disabled={disabled}
          />
        );
      }
      break;
    case "checkbox":
      InputComp = (
        <CheckboxFields
          label={data.label}
          onChange={onChange}
          hasInput={data.inputs}
          data={data}
          checked={data?.isChecked}
          disabled={disabled}
        />
      );
      break;
    case "label":
      InputComp = <IonLabel className="txtbox-lbl">{data?.label}</IonLabel>;

      break;

    default:
      InputComp = Dummy;
      break;
  }

  return InputComp;
};

const Dummy: React.FC = () => {
  return <div></div>;
};

type TextAreaProps = {
  value?: string;
  className?: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  data?: any;
  onChange: (data: any) => void;
  isRequired?: boolean;
};

const TextArea: React.FC<TextAreaProps> = ({
  label,
  placeholder,
  onChange,
  data,
  disabled,
  isRequired,
}) => {
  const dispatch = useAppDispatch();

  const testChange = (e: any) => {
    if (isRequired) {
      if (e.detail.value !== "" && data?.value !== "") {
        dispatch(setInvalide(true));
      } else {
        dispatch(setInvalide(false));
      }
    }

    onChange({
      ...data,
      value: e.detail.value,
    });
  };

  useEffect(() => {
    // console.log({ data, isRequired });

    if (data?.value && data?.value !== "") {
      dispatch(setInvalide(true));
    } else {
      dispatch(setInvalide(false));
    }
  }, [data?.value, isRequired]);

  return (
    <div className="input-group">
      {label && <IonLabel className="txtbox-lbl">{label}</IonLabel>}

      <IonTextarea
        className="input-box"
        onIonChange={testChange}
        value={data?.value}
        placeholder={placeholder}
        disabled={disabled}
      ></IonTextarea>
    </div>
  );
};

type CheckboxFieldsProps = {
  value?: string;
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  minus?: boolean;
  label?: string;
  strictMode?: boolean;
  hasInput?: InputsEntity[] | null;
  data?: any;
  onChange: (data: any) => void;
};

type RadioButtonFieldsProps = {
  value: string;
  className?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  onCheck?: (isChecked: boolean) => void;
  lable?: string;
};

const CheckboxFields: React.FC<CheckboxFieldsProps> = (props) => {
  const [isEnable, setisEnable] = useState(false);

  const onCheck = (data: boolean) => {
    if (props.hasInput) {
      setisEnable(data);
    } else {
      props.onChange({
        ...props.data,
        value: data,
      });
    }
  };

  const onKeyType = (value: any, index: any) => {
    if (props.hasInput) {
      let tempData = [...props.hasInput];
      tempData[index] = value;
      props.onChange(tempData);
    }
  };

  return (
    <div className="checkbox-holder">
      <CheckBox className="input-box" {...props} onCheck={onCheck} />
      {isEnable &&
        props.hasInput?.map((input, index) => (
          <IonInput
            className={`dy-input-box`}
            onKeyUp={(e) =>
              onKeyType({ ...input, value: e.currentTarget.value }, index)
            }
            placeholder={input.placeholder}
            value={input?.value}
            type={input.inputType === "numpad-decimal" ? "number" : "text"}
            disabled={props.disabled}
          ></IonInput>
        ))}
    </div>
  );
};
