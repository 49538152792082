import React, { useState } from "react";
import {
  IonLabel,
  IonInput,
  IonGrid,
  IonRow,
  IonRadioGroup,
  IonCol,
  IonIcon,
} from "@ionic/react";

import "./SubjectiveSocial.scss";
import CheckBox from "../../components/Common/CheckBox/CheckBox";
import InputFieldNew from "../../components/Common/InputFieldNew/InputFiledNew";
import RadioButton from "../../components/Common/RadioButton/RadioButton";
import moment from "moment";
import { calendarClearOutline } from "ionicons/icons";
import { Datepicker } from "@mobiscroll/react";

const SubjectiveSocial: React.FC = () => {
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [tempDate, setTempDate] = useState("");

  const show = () => {
    setDatePickerOpen(true);
  };
  const onClose = () => {
    setDatePickerOpen(false);
  };
  const handleDateSelect = (e: any) => {
    setTempDate(moment(e.date).format("DD/MM/YYYY"));
  };

  return (
    <>
      <IonGrid className="subjectivesocial-ion-grid">
        <IonRow className="subjectivesocial-ion-row">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="social"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-content">
            The patient’s family history was reviewed and does not contribute to
            the current problem/conditon
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row">
          <IonLabel className="subjectivesocial-ion-occupation">
            {" "}
            Occupation{" "}
          </IonLabel>
        </IonRow>

        <IonRow>
          <IonLabel className="subjectivesocial-ion-input">
            <InputFieldNew type="text" value="" />
          </IonLabel>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-header">
          <IonLabel className="subjectivesocial-ion-label">
            {" "}
            Lives with{" "}
          </IonLabel>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="spouse"
                checked={true}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            spouse
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="sibling"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            sibling{" "}
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="child"
                checked={true}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            child{" "}
          </IonCol>
        </IonRow>
      </IonGrid>
      <p className="subjectivesocial-row-bottom-border"></p>
      <IonGrid className="subjectivesocial-ion-grid">
        <IonRow className="subjectivesocial-ion-row-header">
          <IonLabel className="subjectivesocial-ion-label">
            {" "}
            Marital status{" "}
          </IonLabel>
        </IonRow>
        <IonRadioGroup>
          <IonRow className="subjectivesocial-ion-row-MS">
            <IonCol size="1">
              <IonLabel className="subjectivesocial-ion-radio-border">
                <RadioButton
                  value="married"
                  className="subjectivesocial-ion-radio-MS"
                />
              </IonLabel>
            </IonCol>
            <IonCol className="subjectivesocial-ion-radio-MS-label">
              married
            </IonCol>
          </IonRow>
          <IonRow className="subjectivesocial-ion-row-MS">
            <IonCol size="1">
              <IonLabel>
                <RadioButton
                  value="single"
                  className="subjectivesocial-ion-radio-MS"
                />
              </IonLabel>
            </IonCol>
            <IonCol className="subjectivesocial-ion-radio-MS-label">
              single
            </IonCol>
          </IonRow>
          <IonRow className="subjectivesocial-ion-row-MS">
            <IonCol size="1">
              <IonLabel>
                <RadioButton
                  value="widowed"
                  className="subjectivesocial-ion-radio-MS"
                />
              </IonLabel>
            </IonCol>
            <IonCol className="subjectivesocial-ion-radio-MS-label">
              widowed{" "}
            </IonCol>
          </IonRow>
        </IonRadioGroup>
      </IonGrid>
      <p className="subjectivesocial-row-bottom-border"></p>
      <IonGrid className="subjectivesocial-ion-grid">
        <IonRow className="subjectivesocial-ion-row-header">
          <IonLabel className="subjectivesocial-ion-label">
            {" "}
            Home setting{" "}
          </IonLabel>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="house"
                checked={true}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            house
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="apartment"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            apartment{" "}
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="steps to enter home"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            steps to enter home{" "}
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="elevator"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            elevator{" "}
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="other"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            other
            <IonInput
              type="text"
              className="subjectivesocial-ion-input-other"
            />
          </IonCol>
        </IonRow>
      </IonGrid>
      <p className="subjectivesocial-row-bottom-border"></p>
      <IonGrid className="subjectivesocial-ion-grid">
        <IonRow className="subjectivesocial-ion-row-header">
          <IonLabel className="subjectivesocial-ion-label">
            {" "}
            Prior level of function{" "}
          </IonLabel>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="independent with ADLs and ambulation"
                checked={true}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            independent with ADLs and ambulation
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="mod I with AD cane"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            mod I with AD cane{" "}
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="total assist"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            total assist{" "}
          </IonCol>
        </IonRow>

        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="other"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            other
            <IonInput
              type="text"
              className="subjectivesocial-ion-input-other"
            />
          </IonCol>
        </IonRow>
      </IonGrid>
      <p className="subjectivesocial-row-bottom-border"></p>
      <IonGrid className="subjectivesocial-ion-grid">
        <IonRow className="subjectivesocial-ion-row-header">
          <IonLabel className="subjectivesocial-ion-label">
            {" "}
            Assist device{" "}
          </IonLabel>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="none/ independent"
                checked={true}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            none/ independent
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="cane"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            cane{" "}
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="walker"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            walker{" "}
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="front wheel walker"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            front wheel walker{" "}
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="rollator"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            rollator{" "}
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="wheelchair"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            wheelchair{" "}
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="scooter"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            scooter{" "}
          </IonCol>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-liveswith">
          <IonCol size="1">
            <IonLabel>
              <CheckBox
                value="other"
                checked={false}
                strictMode
                className="subjectivesocial-ion-checkbox-liveswith"
              />
            </IonLabel>
          </IonCol>
          <IonCol className="subjectivesocial-ion-checkbox-liveswith-label">
            {" "}
            other
            <IonInput
              type="text"
              className="subjectivesocial-ion-input-other"
            />
          </IonCol>
        </IonRow>
      </IonGrid>
      <p className="subjectivesocial-row-bottom-border"></p>
      <IonGrid className="subjectivesocial-ion-grid">
        <IonRow className="subjectivesocial-ion-row-header">
          <IonLabel className="subjectivesocial-ion-label">
            {" "}
            Smoking status{" "}
          </IonLabel>
        </IonRow>
        <IonRadioGroup>
          <IonRow className="subjectivesocial-ion-row-MS">
            <IonCol size="1">
              <IonLabel>
                <RadioButton
                  value="current smoker (packs per day)"
                  className="subjectivesocial-ion-radio-MS"
                />
              </IonLabel>
            </IonCol>
            <IonCol className="subjectivesocial-ion-radio-MS-label">
              current smoker (packs per day)
            </IonCol>
          </IonRow>
          <IonRow className="subjectivesocial-ion-row-MS">
            <IonCol size="1">
              <IonLabel>
                <RadioButton
                  value="social smoker"
                  className="subjectivesocial-ion-radio-MS"
                />
              </IonLabel>
            </IonCol>
            <IonCol className="subjectivesocial-ion-radio-MS-label">
              social smoker
            </IonCol>
          </IonRow>
          <IonRow className="subjectivesocial-ion-row-MS">
            <IonCol size="1">
              <IonLabel>
                <RadioButton
                  value="non-smoker"
                  className="subjectivesocial-ion-radio-MS"
                />
              </IonLabel>
            </IonCol>
            <IonCol className="subjectivesocial-ion-radio-MS-label">
              non-smoker{" "}
            </IonCol>
          </IonRow>
          <IonRow className="subjectivesocial-ion-row-MS">
            <IonCol size="1">
              <IonLabel>
                <RadioButton
                  value="former smoker"
                  className="subjectivesocial-ion-radio-MS"
                />
              </IonLabel>
            </IonCol>
            <IonCol className="subjectivesocial-ion-radio-MS-label">
              former smoker{" "}
            </IonCol>
          </IonRow>
        </IonRadioGroup>
        <IonRow className="subjectivesocial-ion-row-MS">
          <IonLabel className="subjectivesocial-quitdate">Quit date</IonLabel>
        </IonRow>
        <IonRow className="subjectivesocial-ion-row-MS">
          <IonLabel
            className="subjectivesocial-ion-label-icon"
            onClick={() => show()}
          >
            <IonInput type="text" placeholder="DD/MM/YYYY" value={tempDate} />
            <IonIcon icon={calendarClearOutline} />
          </IonLabel>
          {isDatePickerOpen && (
            <Datepicker
              controls={["calendar"]}
              showOnClick={false}
              showOnFocus={false}
              isOpen={isDatePickerOpen}
              onClose={onClose}
              touchUi={true}
              value={tempDate}
              onCellClick={handleDateSelect}
            />
          )}
        </IonRow>
      </IonGrid>
      <p className="subjectivesocial-row-bottom-border"></p>
      <IonGrid className="subjectivesocial-ion-grid">
        <IonRow className="subjectivesocial-ion-row-header">
          <IonLabel className="subjectivesocial-ion-label">
            {" "}
            Alcohol consumption{" "}
          </IonLabel>
        </IonRow>
        <IonRadioGroup>
          <IonRow className="subjectivesocial-ion-row-MS">
            <IonCol size="1">
              <IonLabel>
                <RadioButton
                  value="no"
                  className="subjectivesocial-ion-radio-MS"
                />
              </IonLabel>
            </IonCol>
            <IonCol className="subjectivesocial-ion-radio-MS-label">no</IonCol>
          </IonRow>
          <IonRow className="subjectivesocial-ion-row-MS">
            <IonCol size="1">
              <IonLabel>
                <RadioButton
                  value="yes (drinks per week)"
                  className="subjectivesocial-ion-radio-MS"
                />
              </IonLabel>
            </IonCol>
            <IonCol className="subjectivesocial-ion-radio-MS-label">
              yes (drinks per week)
            </IonCol>
          </IonRow>
        </IonRadioGroup>
      </IonGrid>
      <p className="subjectivesocial-row-bottom-border"></p>
      <IonGrid className="subjectivesocial-ion-grid">
        <IonRow className="subjectivesocial-ion-row-header">
          <IonLabel className="subjectivesocial-ion-label">
            {" "}
            Drug user{" "}
          </IonLabel>
        </IonRow>
        <IonRadioGroup>
          <IonRow className="subjectivesocial-ion-row-MS">
            <IonCol size="1">
              <IonLabel>
                <RadioButton
                  value="history of drug use"
                  className="subjectivesocial-ion-radio-MS"
                />
              </IonLabel>
            </IonCol>
            <IonCol className="subjectivesocial-ion-radio-MS-label">
              history of drug use
            </IonCol>
          </IonRow>
          <IonRow className="subjectivesocial-ion-row-MS">
            <IonCol size="1">
              <IonLabel>
                <RadioButton
                  value="no/denies"
                  className="subjectivesocial-ion-radio-MS"
                />
              </IonLabel>
            </IonCol>
            <IonCol className="subjectivesocial-ion-radio-MS-label">
              no/denies
            </IonCol>
          </IonRow>
        </IonRadioGroup>
      </IonGrid>
    </>
  );
};
export default SubjectiveSocial;
