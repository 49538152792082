import { IonIcon, IonModal, IonItemSliding, useIonToast } from "@ionic/react";
import "./MoreBtnModal.scss";
import Buttons from "../Common/Buttons/Buttons";
import { closeOutline } from "ionicons/icons";
import { VisitsByServicedate } from "../../types/VisitsByServicedate";
import { useState } from "react";
import {
  callConfirmVisit,
  dischargeVisit,
  skipVisit,
  undoSkipVisit,
  voidBill,
} from "../../helper/backendHelper";
import moment from "moment";
import { useAppSelector } from "../../store/hooks";
import { visitHighlight } from "../../services/CensusProvider";
import multisort from "multisort";
import { socket, socket1 } from "../../socket";
import { getVisitsCensus } from "../../store/thunk/censusThunk";
import { useAppDispatch } from "../../store/hooks";
import { selectUser } from "../../store/reducers/user";
import Toast, { newToast } from "../Common/Toaster/Toast";
interface MoreBtnModalPropsType {
  isMoreModal: boolean;
  handleMoreBtnModal: (item: boolean) => void;
  visit?: VisitsByServicedate;
}

type ToastedList = {
  id: number;
  description: string;
};

const MoreBtnModal: React.FC<MoreBtnModalPropsType> = ({
  isMoreModal,
  handleMoreBtnModal,
  visit,
}) => {
  const [isLastVisit, setIsLastVisit] = useState<boolean>(false);

  const [toastedMessage, setToastedMessage] = useState<ToastedList[]>([]);
  const serviceDate = useAppSelector((state) => state.census)?.serviceDate;
  const visitStatuses = useAppSelector((state) => state.census)?.visitStatuses;
  // const [present, dismiss] = useIonToast();
  const thisDate = useAppSelector((state) => state.census.activeDate);
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectUser);
  const currentFacility = useAppSelector(
    (state) => state.census.censusFacility
  );
  const isNoteRequired =
    (userData?.provider?.note_mode || userData?.practice?.note_mode) ===
    "required";
  const joinRoom = (practiceId: any, selectedDate: any) => {
    socket1.emit("join-visits", { practice_id: practiceId, dos: selectedDate });
  };
  const visitUpdateEvent = (visit: any) => {
    let _visit = Object.assign({}, visit);
    delete _visit.series;
    let data = {
      visit: _visit,
      room: _visit.practice_id + "_" + _visit.service_date_from,
    };

    let tmp_data = JSON.parse(JSON.stringify(data));
    // this.censusProvider.visitsObject[visit._id] = visit;
    // this.socketVisitService.emit("visit-update", tmp_data);
    socket1.emit("visit-update", tmp_data);
    dispatch(
      getVisitsCensus({
        servicedate: moment(thisDate).format("YYYY-MM-DD"),
        // thisProvider: userData.provider,
        facilityId: currentFacility?._id,
      })
    );
  };

  const undoLastVisitAction = async (visit?: any) => {
    let payload = {
      key: "last_visit",
      value: false,
    };
    let data = await dischargeVisit(payload, visit?._id);
    if (data.status == "success") {
      visit.statuses_index.last_visit = false;
      // // no need to regroup
      visitUpdateEvent(visit); // emit visit-update event
      // this.visitHighlight(visit);
      // this.hapticFeedBack();
      // present("Success...", 5000);
      let toastMsg = newToast("Success", "Success");
      setToastedMessage([...toastedMessage, toastMsg]);
      return visit;
    } else {
      // present("Oops... Failed to update status. Please try again...", 5000);
      let toastMsg = newToast(data.message, "Danger");
      setToastedMessage([...toastedMessage, toastMsg]);
      //console.log("Oops... Failed to update status. Please try again...");
    }
  };

  const undoLastVisit = async (
    visit_item?: any,
    slidingItem?: any,
    event?: Event,
    typeCall = ""
  ) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    // if (typeCall == '') {
    //   slidingItem.close();
    //   event.stopPropagation();
    // }

    handleMoreBtnModal(false);
    setIsLastVisit(false);
    console.log(visit);
    let cvisit = JSON.parse(JSON.stringify(visit));
    await undoLastVisitAction(cvisit);
  };

  // const undoSkipVisitHandler = async (visit_item?: any) => {
  //   handleMoreBtnModal(false);
  //   let visit = JSON.parse(JSON.stringify(visit_item));
  //   if (visit?.status == "Not seen") {
  //     let body = {
  //       status: "Scheduled",
  //       // ,
  //       // last_visit: visit.statuses_index.last_visit,
  //       // description: visit.diagnosis_code_primary.description,
  //     };

  //     let res = await undoSkipVisit(body, visit?._id);
  //     if (res.status === "success") {
  //       dispatch(
  //         getVisitsCensus({
  //           servicedate: moment(thisDate).format("YYYY-MM-DD"),
  //           // thisProvider: userData.provider,
  //           facilityId: currentFacility?._id,
  //         })
  //       );

  //       // present("Success...", 5000);
  //       let toastMsg = newToast("Success", "Success");
  //       setToastedMessage([...toastedMessage, toastMsg]);
  //     } else {
  //       // present("Oops... Failed to update. Please try again...", 5000);
  //       let toastMsg = newToast(res.message, "Danger");
  //       setToastedMessage([...toastedMessage, toastMsg]);
  //     }
  //   }
  // };

  const isAction = (visitstatus: any, action: any) => {
    if (
      (action == "Bill" || action == "Bill SALT") &&
      moment(serviceDate) > moment()
    ) {
      return false;
    } else {
      let config: any = visitStatuses;
      let available_Actions = [];
      if (config[visitstatus] && config[visitstatus].actions) {
        available_Actions = config[visitstatus].actions;
      }
      if (available_Actions.indexOf(action) > -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  const voidVisitHandler = (visit_item: any) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    if (isAction(visit.status, "Void")) {
      voidBill(visit._id).then((data: any) => {
        if (data.status == "success" && data.result._id == visit._id) {
          visit.status = data.result.status;
          visit.statuses = data.result.statuses;
          visit.statuses_index = data.result.statuses_index;
          visitUpdateEvent(visit);
        } else {
          visitHighlight(visit);
        }
      });
      // present("Success...", 5000);
      let toastMsg = newToast("Success", "Success");
      setToastedMessage([...toastedMessage, toastMsg]);
    } else {
      visitHighlight(visit);
      // return present(
      //   "Oops... Failed to update status. Please try again...",
      //   5000
      // );
      let toastMsg = newToast("failed to update status", "Danger");
      setToastedMessage([...toastedMessage, toastMsg]);
    }
  };

  return (
    <>
      <IonModal
        className="morebtn-modal"
        isOpen={isMoreModal}
        onDidDismiss={() => {
          handleMoreBtnModal(false);
        }}
      >
        <div className="modal-content-container">
          <div className="modal-heading-container">
            <div className="modal-heading">More options</div>
            <div className="modal-icon-holder">
              <IonIcon
                onClick={() => handleMoreBtnModal(false)}
                className="close-icon"
                icon={closeOutline}
              />
            </div>
          </div>
          <div className="button-container">
            {isAction(visit?.status, "Undo discharge") &&
              visit?.statuses_index?.last_visit &&
              moment(visit?.service_date_from) <= moment() && (
                <Buttons
                  fullWidth
                  tallbutton
                  color="secondary"
                  onClick={() => undoLastVisit(visit)}
                >
                  <div className="button-text">Undo discharge</div>
                </Buttons>
              )}

            {visit?.status == "Void" && (
              <Buttons
                fullWidth
                tallbutton
                color="secondary"
                onClick={() => voidVisitHandler(visit)}
              >
                <div className="button-text">Void encounter</div>
              </Buttons>
            )}
          </div>
        </div>
      </IonModal>
      <Toast
        toastList={toastedMessage}
        position="top-right"
        autoDelete={true}
        autoDeleteTime={4000}
      />
    </>
  );
};

export default MoreBtnModal;
