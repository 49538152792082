import {
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonText,
    IonTitle,
    IonToast,
    IonToolbar,
    useIonRouter,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";

import React, { useEffect, useState } from "react";
import Button from "../../components/Common/Buttons/Buttons";
import { putProfileUser } from "../../helper/backendHelper";
import { editProfilePayloadValidater } from "../../helper/Validation";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { userLogin } from "../../store/thunk/userThunk";
import Toast, {newToast} from "../../components/Common/Toaster/Toast";


import "../AddPatient/AddPatient.scss";

interface TextInputProps {
    label: string;
    divclass: string;
    textclass: string;
}

// common input field component
export const TextInputComp: React.FC<TextInputProps> = ({
    label,
    divclass,
    textclass,
    children,
}) => {
    return (
        <div className={divclass}>
            <IonText className={textclass}>{label}</IonText>
            {children}
        </div>
    );
};
type ToastedList= {
    id: number;
    description: string,
  };

const EditProfile: React.FC = () => {
    const router = useIonRouter();
    const dispatch = useAppDispatch();
    const [toastedMessage, setToastedMessage] = useState<ToastedList[]>([]);
    const thisProfiles = useAppSelector((state) => state.user);
    const thisProfile = useAppSelector((state) => state.user.user);
    const [isValidate, setIsValidate] = useState(false);
    let initialProfileDetails = {
        username: thisProfile?.username || '',
        displayname: thisProfile?.displayname || '',
        user_title: thisProfile?.user_title || '',
        email: thisProfile?.email || '',
        phone: thisProfile?.mobile || '',
        practice_visibility: thisProfile?.practice_visibility,
        is_internal: thisProfile?.isInternal,
        brand: thisProfiles?.brand,

    };
    const [values, setValues] = useState(initialProfileDetails);
    const [validateError, setValidateError] = useState({
        status: false,
        msg: "",
    });
    const onInputChange = (e: any | React.ChangeEvent<HTMLInputElement>) => {
        if (
            e.currentTarget?.name === "username" ||
            e.currentTarget?.name === "displayname" ||
            e.currentTarget?.name === "user_title") {
            if (e.currentTarget?.value?.length < 2) {
                setValidateError({
                    status: true,
                    msg: `${e.currentTarget?.name} should be minimum 2 characters`,
                });
            }
            else {
                setValidateError({
                    status: false,
                    msg: ``,
                });
            }
        }
        if (
            e.currentTarget?.name === "email" && e.currentTarget?.value != '') {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.currentTarget.value)) {
                setValidateError({
                    status: true,
                    msg: `Invalid email address`,
                });
            }
            else {
                setValidateError({
                    status: false,
                    msg: ``,
                });
            }
        }
        if (
            e.currentTarget?.name === "email" && e.currentTarget?.value == '') {
            setValidateError({
                status: false,
                msg: ``,
            });
        }
        if (
            e.currentTarget?.name === "phone" && e.currentTarget?.value != '') {
            if (!/^[+0]{0,2}(91)?[0-9]{10}$/i.test(e.currentTarget.value)) {
                setValidateError({
                    status: true,
                    msg: `Invalid mobile number`,
                });
            }
            else {
                setValidateError({
                    status: false,
                    msg: ``,
                });
            }
        } if (
            e.currentTarget?.name === "phone" && e.currentTarget?.value == '') {
            setValidateError({
                status: false,
                msg: ``,
            });
        }

        setValues({
            ...values,
            [e.currentTarget?.name]: e.currentTarget?.value.split(" ").join(""),
        });
    };
    const onCancel = () => {
        setValues(initialProfileDetails);
        router.goBack();
    };
    const handleSavePatient = async () => {
        await putProfileUser(thisProfile?._id, values)
            .then((res) => {
                if (res.status === "success") {
                    // if (thisProfile?._id) {
                    //     //  dispatch((thisProfile?._id));
                    // }
                   let toastMsg =  newToast("Profile details updated","Success");
                    setToastedMessage([...toastedMessage, toastMsg]);
                    // router.push(
                    //     `/patientDetails/${thisProfile?._id}`,
                    //     "forward"
                    // );
                }else {
                    let toastMsg =  newToast(res.message,"Danger");
                    setToastedMessage([...toastedMessage, toastMsg]);
                }
            })
            .catch((response) => {
                let toastMsg =  newToast(response.response.data.message,"Danger");
                setToastedMessage([...toastedMessage, toastMsg]);
            });
    };
    useEffect(() => {
        setIsValidate(editProfilePayloadValidater(values).validate);
    }, [values]);

    return (
        <IonPage className="add-patient">
            <IonHeader className="newpatient-header">
                <IonToolbar className="ion-toolbar-dashboard" mode="ios">
                    <IonIcon
                        onClick={() => router.goBack()}
                        className="left-icon"
                        icon={chevronBack}
                    />
                    <IonTitle className="title-header-dashboard">Edit Profile</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="addpatient-content">
                <div className="newpatient-container">
                    <p>Profile Information</p>
                    {validateError.status && (
                        <>
                            <p className="error-red">{validateError.msg}</p>
                        </>
                    )}
                    <TextInputComp
                        label="Username"
                        divclass="patient-names-row-2"
                        textclass="patient-sublabel"
                    >
                        <input
                            type="text"
                            name="username"
                            id="username"
                            className="name-input"
                            value={values.username}
                            onChange={onInputChange}

                        />
                    </TextInputComp>
                    <TextInputComp
                        label="Displayname"
                        divclass="patient-names-row-2"
                        textclass="patient-sublabel"
                    >
                        <input
                            type="text"
                            name="displayname"
                            id="displayname"
                            className="name-input"
                            value={values.displayname}
                            onChange={onInputChange}
                        />
                    </TextInputComp>
                    <TextInputComp
                        label="Title"
                        divclass="patient-names-row-2"
                        textclass="patient-sublabel"
                    >
                        <input
                            type="text"
                            name="user_title"
                            id="user_title"
                            className="name-input"
                            value={values.user_title}
                            onChange={onInputChange}
                        />
                    </TextInputComp>
                    <TextInputComp
                        label="Email address"
                        divclass="patient-names-row-2"
                        textclass="patient-sublabel"
                    >
                        <input
                            type="text"
                            name="email"
                            id="email"
                            className="name-input"
                            value={values.email}
                            onChange={onInputChange}
                        />
                    </TextInputComp>
                    <TextInputComp
                        label="Phone"
                        divclass="patient-names-row-2"
                        textclass="patient-sublabel"
                    >
                        <input
                            type="text"
                            name="phone"
                            id="phone"
                            className="name-input"
                            value={values.phone}
                            onChange={onInputChange}
                        />
                    </TextInputComp>


                </div>
                
                <div className="event-buttons">
                    <div className="left-btn-holder">
                        <Button boldfont color="secondary" fullWidth onClick={onCancel} >
                            Cancel
                        </Button>
                    </div>
                    <div className="right-btn-holder">
                        <Button
                            boldfont
                            fullWidth
                            onClick={handleSavePatient}
                            disabled={!isValidate || validateError.status}
                        >
                            Save Profile
                        </Button>
                    </div>
                    <Toast
                        toastList={toastedMessage}
                        position="top-right"
                    />
                </div>
            </IonContent>
        </IonPage>
    );
};

export default EditProfile;
