/* eslint-disable react-hooks/exhaustive-deps */
import { IonIcon, IonRow } from "@ionic/react";
import { checkmarkDone } from "ionicons/icons";
import React from "react";

const ProgressBarHorizontalDetailed: React.FC<any> = (props) => {
  const { data, activeId } = props;

  return (
    <>
      {data.map((item: any, index: number) => (
        <div className="step-gap" key={item.id}>
          <IonRow className="content-gap">
            <span>
              <div
                className={`icon-container-main alignmentcenter ${
                  item.completed || item.id === activeId
                    ? "sucessfull-icon-container"
                    : "unsucessfull-icon-container"
                }`}
              >
                {item.completed === true ? (
                  <IonIcon icon={checkmarkDone}></IonIcon>
                ) : (
                  <strong>{index + 1}</strong>
                )}
              </div>
            </span>
            <div
              className={`label-text ${
                item.completed || item.id === activeId
                  ? "sucessfull-label"
                  : "unsucessfull-label"
              }`}
            >
              {item.name}
            </div>
          </IonRow>
          {item.id === activeId &&
            item.content.map((item: string) => (
              <IonRow className="item-content">{item}</IonRow>
            ))}
        </div>
      ))}
    </>
  );
};

export default ProgressBarHorizontalDetailed;
