import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getDailysummary,
  getFacilities,
  getVisitsByDateAndFacility,
  getVisitStatuses,
  getVistByServiceDate,
} from "../../helper/backendHelper";
import {
  getFacilityByVisits,
  segregatePatients,
  seperateInpatientEnconter,
  totalPaymentAndRUVFromVisits,
} from "../../helper/formater";
import { DailysummaryApiArg } from "../../types/DailysummaryType";

export const getCensusDailysummary = createAsyncThunk(
  //action type string
  "census/dailysummary",
  // callback function
  async (data: DailysummaryApiArg, thunkAPI) => {
    const dailysummaryRes = await getDailysummary(data);
    if (dailysummaryRes?.status === 500) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      if (dailysummaryRes?.status === "success") {
        return dailysummaryRes?.result;
      }
    }
  }
);

export const getVisitStatusesList = createAsyncThunk(
  //action type string
  "census/getVisitStatusesList",
  // callback function
  async (_, thunkAPI) => {
    let res = await getVisitStatuses();
    if (res?.status === 500) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      if (res?.status === "success") {
        return res?.results;
      }
    }
  }
);

type getVisitsCensusProps = {
  servicedate: string;
  facilityId: string;
};

export const getVisitsCensus = createAsyncThunk(
  //action type string
  "census/getVistByServiceDate",
  // callback function
  async (data: getVisitsCensusProps, thunkAPI) => {
    let resStatuses = await getVisitStatuses();
    let resVisits = await getVisitsByDateAndFacility(
      data.servicedate,
      data.facilityId
    );

    if (resVisits?.status === 500) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      if (resVisits?.status === "success") {
        let finalList = segregatePatients(
          resVisits?.resData,
          resStatuses?.results?.[0].values
        );

        let total = totalPaymentAndRUVFromVisits(resVisits?.resData);

        return {
          visitsByDate: resVisits?.resData,
          ...finalList,
          ...total,
          serviceDate: data.servicedate,
          visitStatuses: resStatuses,
          patientsList: finalList?.patientsList,
        };
      }
    }
  }
);

// export const updateCPTVisit = createAsyncThunk(
//   //action type string
//   "census/updateCPTVisit",
//   // callback function
//   async (
//     data: { data: any; patientId: string; servicedate: string },
//     thunkAPI
//   ) => {
//     let cptres = await putCptChange(data.patientId, data.data);
//     if (cptres?.status === 500) {
//       return thunkAPI.rejectWithValue("Server Error");
//     } else {
//       if (cptres?.status === "success") {
//         let res = await getVistByServiceDate(data.servicedate);
//         if (res?.status === 500) {
//           return thunkAPI.rejectWithValue("Server Error");
//         } else {
//           if (cptres?.status === "success") {
//             return { visitList: res?.resData, serviceDate: data.servicedate };
//           }
//         }
//       }
//     }
//   }
// );
