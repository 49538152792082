/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Switch.scss";

type SwitchProps = {
  name: string;
  label?: string;
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  small?: boolean;
  onCheck?: (isChecked: boolean) => void;
};

const Switch: React.FC<SwitchProps> = ({
  name,
  checked,
  onCheck,
  small,
  disabled,
}) => {
  const [isChecked, setIsChecked] = useState(checked ? true : false);

  function handleKeyPress(e: React.KeyboardEvent<HTMLLabelElement>) {
    if (e.keyCode !== 32) return;

    e.preventDefault();

    if (onCheck) {
      setIsChecked(!isChecked);
    }
  }

  useEffect(() => {
    if (onCheck) {
      onCheck(isChecked);
    }
  }, [isChecked]);

  return (
    <div className={"toggle-switch" + (small ? " small-switch" : "")}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={name}
        checked={isChecked}
        onChange={(e) => setIsChecked(e.target.checked)}
        disabled={disabled}
      />
      {name ? (
        <label
          className="toggle-switch-label"
          tabIndex={disabled ? -1 : 1}
          onKeyDown={(e) => handleKeyPress(e)}
          htmlFor={name}
        >
          <span
            className={
              disabled
                ? "toggle-switch-inner toggle-switch-disabled"
                : "toggle-switch-inner"
            }
            tabIndex={-1}
          />
          <span
            className={
              disabled
                ? "toggle-switch-switch toggle-switch-disabled"
                : "toggle-switch-switch"
            }
            tabIndex={-1}
          />
        </label>
      ) : null}
    </div>
  );
};

export default Switch;
