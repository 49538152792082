import {
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  useIonRouter,
} from "@ionic/react";
import {
  arrowBackCircleOutline,
  cameraOutline,
  chevronBackOutline,
  mic,
  star,
  textOutline,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import Button from "../../components/Common/Buttons/Buttons";
import Tab, { TabNav, TabRouter } from "../../components/Common/Tab/Tab";
import { getPatientPayment, getVisitDetails } from "../../helper/backendHelper";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setDateOfService,
  setDiagnosisCode,
  setProcedureCode,
} from "../../store/reducers/newEncounter";
import { paymentDetailsType } from "../../types/VisitDetailsSeriesType";
import Payment from "../Payment/Payment";
import PatientDetails from "./PatientDetails";
import "./VisitDetails.scss";
import VisitHistory from "./VisitHistory/VisitHistory";

const VisitDetails: React.FC = (props: any) => {
  const router = useIonRouter();
  const dispatch = useAppDispatch();
  const thisEncounterState = useAppSelector((state) => state.newEncounter);

  const [visitDetailsResult, setVisitDetailsResult] = useState<any>([]);
  const [paymentDetailsResult, setPaymentDetailsResiult] =
    useState<paymentDetailsType>();
  // const id = new URLSearchParams(props?.location?.search).get("id");

  const id = "62bac1e305f9122526505df2";

  useEffect(() => {
    const visitDetails = async () => {
      let res = await getVisitDetails({ id });
      setVisitDetailsResult(res?.resData);
    };

    const paymentData = async () => {
      try {
        let res = await getPatientPayment({ id });
        setPaymentDetailsResiult(res.result[0]);
      } catch (error) {}
    };

    if (id !== null) {
      visitDetails();
      paymentData();
    }
  }, [id]);

  const backButtonHandler = () => {
    dispatch(setDiagnosisCode(thisEncounterState.diagnosisCodesSelected));
    dispatch(setProcedureCode([]));
    dispatch(setDateOfService(""));
    router.push("/", "back");
  };

  return (
    <IonPage>
      <IonHeader className="visit-details-header d-pad ">
        <div className="content-holder">
          <div className="back-icon-container">
            <IonIcon
              onClick={backButtonHandler}
              icon={chevronBackOutline}
            ></IonIcon>
          </div>
          <div className="user-details-holder">
            <div className="star-icon-container">
              <IonIcon icon={star}></IonIcon>
            </div>
            <div className="name-container">
              <div className="name">{visitDetailsResult[0]?.patient_name}</div>
            </div>
          </div>
          <div className="multiple-icon-container">
            <div className="single-icon-container">
              <IonIcon icon={textOutline}></IonIcon>
            </div>
            <div className="single-icon-container">
              <IonIcon icon={cameraOutline}></IonIcon>
            </div>
            <div className="single-icon-container active-icon">
              <IonIcon icon={mic}></IonIcon>
            </div>
          </div>
        </div>
      </IonHeader>
      <IonContent className="visit-details-content">
        <Tab>
          <TabRouter>
            <TabNav component={PatientDetails}>Patient Details</TabNav>
            <TabNav component={Payment} data={paymentDetailsResult}>
              Payment
            </TabNav>
            <TabNav component={VisitHistory} data={visitDetailsResult}>
              Visit History
            </TabNav>
          </TabRouter>
        </Tab>
      </IonContent>
      <IonFooter className="visit-details-footer">
        <Button
          onClick={() => {
            router.goBack();
          }}
          fullWidth
          boldfont
          variant="destructive"
          color="secondary"
        >
          <div className="button-content-container">
            <div className="undo-icon">
              <IonIcon
                className="ion-button"
                icon={arrowBackCircleOutline}
              ></IonIcon>
            </div>
            <div className="undo-text">Undo visit confirmation</div>
          </div>
        </Button>
      </IonFooter>
    </IonPage>
  );
};

export default VisitDetails;
