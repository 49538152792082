import React, { useState } from "react";
import {
  IonCard,
  IonCardSubtitle,
  IonCol,
  IonRow
} from "@ionic/react";

import "./EncounterHistory.scss";
import MarkedData from "./MarkedData";
import CalendarEncounterHistory from "./CalendarEncounterHistory";
import moment from "moment";

const EncounterHistory = (props: any) => {
  const [calendarType, setCalendarType] = useState<string>("month");

  const [dataMatchCal, setDataMatchCal] = useState([]);

  const [visitHistoryMonth, setVisitHistoryMonth] = useState<any>([]);

  const [firstLastDate, setFirstLastDate] = useState({
    firstDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    lastDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  });

  const handleMonthChangeFunction = (args: any) => {
    setFirstLastDate({ firstDate: args.firstDay, lastDate: args.lastDay });
  };
  const options = {
    cssClass: "my-custom-interface",
  };

  return (
    <IonCard className="EncounterHistory-card-container">
      <IonCardSubtitle>
        <IonRow class="ion-nowrap">
          <IonCol className="EncounterHistory-heading ion-nowrap" size="7">
            Encounter history
          </IonCol>

          <IonCol size="3">
            {/* <IonSelect
              interface="popover"
              interfaceOptions={options}
              value={calendarType}
              onIonChange={(e) => setCalendarType(e.detail.value)}
              className="EncounterHistory-select ion-no-margin ion-no-padding"
            >
              <IonSelectOption value="month" class="black-option">
                Month
              </IonSelectOption>
              <IonSelectOption value="week" class="black-option">
                Week
              </IonSelectOption>
            </IonSelect> */}
          </IonCol>
        </IonRow>
      </IonCardSubtitle>

      <div className="EncounterHistory-calendar">
        <CalendarEncounterHistory
          item={calendarType}
          data={props.data}
          handleMonthChange={handleMonthChangeFunction}
        />
      </div>
      <div className="ion-no-margin ion-no-padding">
        <MarkedData
          data={props.data
            ?.filter(
              (item: any) =>
                moment(item.service_date_from).isSameOrAfter(
                  moment(firstLastDate.firstDate)
                ) &&
                moment(item.service_date_from).isSameOrBefore(
                  moment(firstLastDate.lastDate)
                )
            )
            .sort((a: any, b: any) =>
              moment(a.service_date_from).diff(moment(b.service_date_from))
            )}
        />
      </div>
    </IonCard>
  );
};
export default EncounterHistory;
