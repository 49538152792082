/* eslint-disable @typescript-eslint/no-unused-expressions */
import axios from "axios";
import { ENV } from "../../env";

export const environment = {
  production: false,
  HostName: ENV.HostName,
  Settings_Host: ENV.Settings_Host,
  SocketUrl: ENV.SocketUrl,
};

export const COLLECTOR_URL = environment.HostName;
export const SETTINGS_URL = environment.Settings_Host;
export const SOCKET_URL = environment.SocketUrl;

export const getToken = async () => {
  const Token: String | null = localStorage.getItem("Token");
  let TokenObj: any;
  if (typeof Token === "string") {
    TokenObj = JSON.parse(Token);
  }
  return TokenObj?.authToken;
};

export const getRefreshToken = async () => {
  const Token: String | null = localStorage.getItem("Token");
  let TokenObj: any;
  if (typeof Token === "string") {
    TokenObj = JSON.parse(Token);
  }
  return TokenObj?.authRefreshToken;
};

export const getUserFromLocal = async () => {
  const Token: String | null = localStorage.getItem("Token");
  let TokenObj: any;
  if (typeof Token === "string") {
    TokenObj = JSON.parse(Token);
  }
  return TokenObj?.user;
};

const onRequest = async (config: any) => {
  const Token = await getToken();

  Token ? (config.headers["authorization"] = Token) : null;
  // config.headers["origin"] = "claimocity";
  return config;
};

const onRequestError = (error: any) => {
  return Promise.reject(error);
};

const onResponse = (response: any) => {
  return response;
};

const onResponseError = async (error: any) => {

  if (error.response) {
    // Access Token was expired
    if (
      error.response.status === 401 &&
      error.response.data.message === "Error: Authorization-expired"
    ) {
      try {
        const Token = await getToken();
        const refreshToken = await getRefreshToken();
        const user = await getUserFromLocal();

        const loginRes = await axios.post(`${COLLECTOR_URL}/refreshtoken`, {
          refreshToken: refreshToken,
          authToken: Token,
          userId: user?._id,
        });

        if (loginRes?.data?.status === "failed") {
          localStorage.removeItem("Token");
          window ? window.location.replace("/") : null;

          return Promise.reject(loginRes?.data?.message);
        } else {

          let tokenStr = localStorage.getItem("Token");

          let TokenObj: any;

          if (typeof tokenStr === "string") {
            TokenObj = JSON.parse(tokenStr);
          }



          localStorage.setItem(
            "Token",
            JSON.stringify({
              ...TokenObj,
              authToken: loginRes?.data?.authToken,
            })
          );

          error.config.headers["authorization"] = loginRes?.data?.authToken;

          let retry = await axios.request(error?.config);

          return retry;
        }
      } catch (error: any) {

        localStorage.removeItem("Token");

        if (error?.response?.data?.error === "Authorization-noheader") {
          window ? window.location.replace("/") : null;
        }

        return Promise.reject(error);
      }
    } else {
      if (error?.response?.data?.error === "Authorization-noheader") {
        window ? window.location.replace("/") : null;
      }

      return Promise.reject(error);
    }
  } else {
    return Promise.reject(error);
  }


};

export const setupInterceptorsTo = (axiosInstance: any) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
