/* eslint-disable react-hooks/exhaustive-deps */
import { IonCol, IonGrid, IonLabel, IonRow, IonTextarea } from "@ionic/react";
import React from "react";
import "./DateOfService.scss";
import { useState, useEffect } from "react";
import moment from "moment";
import { dateValidation } from "../../../helper/Validation";
interface dateProps {
  pastDateTrue?: boolean;
  futureDateTrue?: boolean;
  dimbgcolor?: boolean;
}
const DateBox: React.FC<dateProps> = ({
  children,
  pastDateTrue,
  futureDateTrue,
  dimbgcolor,
}) => {
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");

  const checkDateLength = () => {
    let fullDate = month + "/" + day + "/" + year;
    dateValidation(pastDateTrue!, futureDateTrue!, fullDate);
  };
  useEffect(() => {
    if (month.length === 2 && day.length === 2 && year.length === 4) {
      let fullDate = month + "/" + day + "/" + year;
      let result = moment(fullDate, "MM/DD/YYYY", true).isValid();
      if (result === false) {
        alert("Date is incorrect");
      } else {
        //call validation function
        dateValidation(pastDateTrue!, futureDateTrue!, fullDate);
      }
    }
  }, [month, day, year]);

  return (
    <IonGrid>
      <IonRow>
        <IonCol size="3">
          <IonLabel className={`labelSubTitle ${dimbgcolor ? "dim-text" : ""}`}>
            Month
          </IonLabel>
        </IonCol>
        <IonCol size="3">
          <IonLabel className={`labelSubTitle ${dimbgcolor ? "dim-text" : ""}`}>
            Day
          </IonLabel>
        </IonCol>
        <IonCol size="6">
          <IonLabel className={`labelSubTitle ${dimbgcolor ? "dim-text" : ""}`}>
            Year
          </IonLabel>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="3">
          <IonTextarea
            value={month}
            onIonChange={(e) => setMonth(e.detail.value!)}
            maxlength={2}
            className={`monthDayTextArea ${dimbgcolor ? "dimbgcolor" : ""}`}
            inputmode="numeric"
            placeholder="MM"
          ></IonTextarea>
        </IonCol>
        <IonCol size="3">
          <IonTextarea
            value={day}
            onIonChange={(e) => setDay(e.detail.value!)}
            maxlength={2}
            className={`monthDayTextArea ${dimbgcolor ? "dimbgcolor" : ""}`}
            inputmode="numeric"
            placeholder="DD"
          ></IonTextarea>
        </IonCol>
        <IonCol size="6">
          <IonTextarea
            value={year}
            onIonChange={(e) => setYear(e.detail.value!)}
            maxlength={4}
            onIonBlur={() => checkDateLength()}
            className={`yearTextArea ${dimbgcolor ? "dimbgcolor" : ""}`}
            inputmode="numeric"
            placeholder="YYYY"
          ></IonTextarea>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
export default DateBox;
