import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonModal,
  useIonLoading,
  IonToolbar,
  IonTitle,
  IonText,
  useIonRouter,
} from "@ionic/react";
import "./providerModal.scss";
import { channgeStatus } from "../../../helper/backendHelper";
import { useAppDispatch } from "../../../store/hooks";
import { setMessages, setRedirectedFrom, setSelectedChat } from "../../../store/reducers/message";
// import Toast, { newToast } from "../../../components/Common/Toaster/Toast";


// type ToastedList= {
//   id: number;
//   description: string,
// };

const ProviderModal: React.FC<any> = ({ isOpen, setIsOpen,charge_id}) => {
  const [present, dismiss] = useIonLoading();
  // const [toastedMessage,setToastedMessage] = useState<ToastedList[]>([]);
  const router = useIonRouter();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
      present({
        message: "Loading...",
        duration: 500,
      });
      dismiss();
      // let toastMsg =  newToast("Loading...","Info");
      //     setToastedMessage([...toastedMessage, toastMsg]); 
    }
  }, [isOpen]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const completeAction =async () => {
    let data = await channgeStatus(charge_id);
      if (data.status == "success") {
        setIsOpen(false);
        setTimeout(async() => {
          await dispatch(setSelectedChat({}));
          await dispatch(setRedirectedFrom(""))
          await dispatch(setMessages([]))
          router.push("/messagelist","back")
        }, 500);
      }
    
  }

  const onSkip = () => {
    setIsOpen(false);
    setTimeout(async() => {
      await dispatch(setSelectedChat({}));
      await dispatch(setRedirectedFrom(""))
      await dispatch(setMessages([]))
      router.push("/messagelist","back")
    }, 500);
  };

  return (
    <>
    {/* <Toast toastList={toastedMessage} position="bottom-left" autoDelete={true} autoDeleteTime={3000}/> */}
      <IonModal
        className="skip_modal"
        isOpen={isOpen}
        onDidDismiss={closeModal}
        // backdropBreakpoint={0.2}
      >
        <IonHeader mode="ios" className="skip_modal_header">
          <IonToolbar className="skip_modal_toolbar">
            <IonTitle className="skip_modal_title">
              Complete provider hold?{" "}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="skip_modal_content">
          <div className="skip_modal_text">
            <IonText>
              Do you want to mark the provider hold item completed?
            </IonText>
          </div>

          <div className="modalButtons">
          <div
              className="skipButton btn btn-primary"
              onClick={() => completeAction()}
            >
              <span>Yes</span>
            </div>
            <div
              className="closeButton btn btn-primary"
              onClick={() => onSkip()}
            >
              <span>No</span>
            </div>
           
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default ProviderModal;