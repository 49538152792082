import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { getAdmissionStatusChartView } from "../../../helper/backendHelper";
import InfoPieChart from "./InfoPieChart";

const AdmissionStatus: React.FC<any> = ({ selectedFacilities }) => {
  const [admissionData, setAdmissionData] = useState<any>({
    newPatients: 0,
    assignedToMe: 0,
    recAdmitted: 0,
    recDischarged: 0,
  });

  const getAdmissionStatusData = async () => {
    let currentDate = moment().format("YYYY-MM-DD");
    let admissionPayload = {
      facilities: selectedFacilities,
      patient_created_date: currentDate,
      service_date: currentDate,
    };

    let res = await getAdmissionStatusChartView(admissionPayload);
    let data = {};
    // console.log("sa", res?.result[0]);
    if (res?.status === "SUCCESS") {
      // console.log("sa", res?.result[0]);
      data = {
        ...admissionData,
        newPatients: res?.result[0]?.totalPatients
          ? res?.result[0]?.totalPatients
          : 0,
        assignedToMe: res?.result[0]?.assignedToMe
          ? res?.result[0]?.assignedToMe
          : 0,
        recAdmitted: res?.result[0]?.recentlyAdmitted
          ? res?.result[0]?.recentlyAdmitted
          : 0,
        recDischarged: res?.result[0]?.recentlyDischarged
          ? res?.result[0]?.recentlyDischarged
          : 0,
      };
      setAdmissionData(data);
    }
  };

  useEffect(() => {
    getAdmissionStatusData();
  }, [selectedFacilities]);

  // console.log("admission", admissionData);

  return (
    <div className="admission-status">
      <IonCard className="graph-card chart-card">
        <IonCardHeader className="graphcard-title-container">
          <IonCardTitle
            className="chart-text-title mx-3 mt-3"
            style={{ padding: "0 15px" }}
          >
            Distribution of admission status
          </IonCardTitle>
          {/* <CImage
                className="graphcard-ellipsis"
                src={Ellipsis}
                onClick={() => setEditWidgetVisible(true)}
              ></CImage> */}
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol size="5">
                <div className="chart-content">
                  <div className="chart-list">
                    <div className="chart-item">
                      <div className="chart-bullet">
                        <div className="bullet"></div>
                      </div>
                      <div className="inner-text list-item">New patients</div>
                    </div>
                    <div className="chart-value">
                      {admissionData?.newPatients}
                    </div>
                  </div>
                  <div className="chart-list">
                    <div className="chart-item">
                      <div className="chart-bullet">
                        <div className="bullet"></div>
                      </div>
                      <div className="inner-text list-item">Assigned to me</div>
                    </div>
                    <div className="chart-value">
                      {admissionData?.assignedToMe}
                    </div>
                  </div>
                  <div className="chart-list">
                    <div className="chart-item">
                      <div className="chart-bullet">
                        <div className="bullet"></div>
                      </div>
                      <div className="inner-text list-item">
                        Recently admitted
                      </div>
                    </div>
                    <div className="chart-value">
                      {admissionData?.recAdmitted}
                    </div>
                  </div>
                  <div className="chart-list">
                    <div className="chart-item">
                      <div className="chart-bullet">
                        <div className="bullet"></div>
                      </div>
                      <div className=" inner-text list-item">
                        Recently discharged
                      </div>
                    </div>
                    <div className="chart-value">
                      {admissionData?.recDischarged}
                    </div>
                  </div>
                </div>
              </IonCol>
              <IonCol size="7">
                <InfoPieChart chartData={admissionData} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </div>
  );
};

export default memo(AdmissionStatus);
