import { IonButton } from "@ionic/react";
import "./Button.scss";
interface ButtonProps {
  classname?: string;
  color?: string;
  onClick?: React.MouseEventHandler<HTMLIonButtonElement> | undefined;
  variant?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  boldfont?: boolean;
  tallbutton?: boolean;
  spaceAround?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  variant,
  disabled,
  color = "primary",
  fullWidth,
  boldfont,
  tallbutton,
  spaceAround,
  classname = "",
  ...rest
}) => {
  return (
    <IonButton
      className={
        `claim-btn ${color} ${variant} ${
          fullWidth ? "full-width" : "padding"
        } ${tallbutton ? "tall-button" : ""}` +
        (disabled ? " disabled" : "") +
        (boldfont ? " boldfont" : "") +
        (spaceAround ? "space-around-btn" : "") +
        (classname ? classname : "")
      }
      onClick={onClick}
      color={color}
      disabled={disabled ? true : false}
      {...rest}
    >
      {" "}
      {children}
    </IonButton>
  );
};

export default Button;
