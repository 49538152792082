import { IonSpinner } from "@ionic/react";
import React from "react";

const Loader: React.FC<any> = (props) => {
  return (
    <IonSpinner name={props?.data?.name} color={props?.data?.color} />
  );
};

export default Loader;

//name - "bubbles" ｜ "circles" ｜ "circular" ｜ "crescent" ｜ "dots" ｜ "lines" ｜ "lines-sharp" ｜ "lines-sharp-small" ｜ "lines-small" ｜ undefined
//color - "danger" ｜ "dark" ｜ "light" ｜ "medium" ｜ "primary" ｜ "secondary" ｜ "success" ｜ "tertiary" ｜ "warning" ｜ string & Record<never, never> ｜ undefined
