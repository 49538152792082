/* eslint-disable react-hooks/exhaustive-deps */
import { IonIcon } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";
import React from "react";

const ProgressBarScroll: React.FC<any> = (props) => {
  const { data, activeId } = props;

  return (
    <div>
      <div className="facilities-card-scroll">
        {data.map((item: any, index: number) => {
          return (
            <div
              key={item.id}
              className="scroll-names"
              id={`progress-scroller-${index}`}
            >
              <div
                className={`icon-container-main alignmentcenter ${
                  item.completed || item.id === activeId
                    ? "sucessfull-icon-container"
                    : "unsucessfull-icon-container"
                }`}
              >
                {item.completed === true ? (
                  <IonIcon icon={checkmarkOutline}></IonIcon>
                ) : (
                  <strong>{index + 1}</strong>
                )}
              </div>
              <div
                className={`label-text ${
                  item.completed || item.id === activeId
                    ? "sucessfull-label"
                    : "unsucessfull-label"
                }`}
              >
                {item.name}
              </div>
              {index < data.length - 1 && <div className="process-path"></div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressBarScroll;
