import { IonCol, IonIcon, IonRow, IonText, useIonRouter } from "@ionic/react";
import { Datepicker } from "@mobiscroll/react";
import {
  chevronBackOutline,
  chevronForwardOutline,
  notifications,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import "./Census.scss";
import moment from "moment";
import { IconSvg } from "../Common/IconSvg/IconSvg";
import { CalendarIcon } from "../../icons/icons";
import { getDailysummary } from "../../helper/backendHelper";

interface MobiScrollProps {
  onChange?: (txtdata: any) => void;
  bellcounter?: boolean;
  dateValue: string;
  setIsDropDownOpen?: any;
}

const MobisrollCalendar: React.FC<MobiScrollProps> = ({
  onChange,
  bellcounter,
  dateValue,
  setIsDropDownOpen,
}) => {
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  // const [dateStamp, setDateStamp] = useState<any>({ date: "", day: "" });
  const dateTimeStamp = dateValue;
  // const [tempDate, setTempDate] = useState(moment(new Date()));

  const tempDate = dateValue;
  const [firstLastDate, setFirstLastDate] = useState({
    firstDate: "",
    lastDate: "",
  });

  const [dailyCensusData, setDailyCensusData] = useState([]);

  const router = useIonRouter();

  const show = () => {
    setIsDropDownOpen(false);
    router.push("/censusCalendar", "forward");
    // setDatePickerOpen(true);
  };
  const onClose = () => {
    setDatePickerOpen(false);
  };

  const dateDayFormater = (date: any) => {
    let newdate = moment(date).format("Do MMMM");
    let day = moment(date).calendar(null, {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      lastDay: "[Yesterday]",
      lastWeek: "dddd",
      sameElse: "dddd",
    });
    return { newdate, day };
  };

  const onCalendarChange = (e: any) => {
    const { newdate, day } = dateDayFormater(e.value);
    //  setDateTimeStamp(e.value);
    // setDateStamp({ day: day, date: newdate });

    if (onChange) {
      onChange(e.value);
    }
  };

  const handlePrevDate = (e: any) => {
    let prevDate =
      dateTimeStamp !== ""
        ? moment(dateTimeStamp).subtract(1, "days").toDate()
        : moment().subtract(1, "days").toDate();
    const { newdate, day } = dateDayFormater(prevDate);
    //  setDateTimeStamp(e.value);
    // setDateStamp({ day: day, date: newdate });

    if (onChange) {
      onChange(prevDate);
    }
  };

  const handleNextDate = (e: any) => {
    let nextDate =
      dateTimeStamp !== ""
        ? moment(dateTimeStamp).add(1, "days").toDate()
        : moment().add(1, "days").toDate();
    const { newdate, day } = dateDayFormater(nextDate);
    //  setDateTimeStamp(e.value);
    // setDateStamp({ day: day, date: newdate });

    if (onChange) {
      onChange(nextDate);
    }
  };

  const handleDateSelect = (e: any) => {
    // setTempDate(e.date);
  };

  const handleMonthChange = (args: any, inst: any) => {
    setFirstLastDate({
      firstDate: args.firstDay,
      lastDate: args.lastDay,
    });
  };

  useEffect(() => {
    const { newdate, day } = dateDayFormater(moment(new Date()));
    // setDateStamp({ day: day, date: newdate });
  }, []);

  useEffect(() => {
    if (firstLastDate?.firstDate !== "" && firstLastDate?.lastDate !== "") {
      dailyCensusListHandler(firstLastDate);
    }
  }, [firstLastDate]);

  const dailyCensusListHandler = async (firstLastDate: any) => {
    try {
      let res = await getDailysummary({
        date_from: moment(firstLastDate.firstDate).format("YYYY-MM-DD"),
        date_to: moment(firstLastDate.lastDate).format("YYYY-MM-DD"),
      });

      setDailyCensusData(res.result);
    } catch (error) {}
  };

  const displayDailyCensus = () => {
    const newData = dailyCensusData.filter((item: any) =>
      moment(item.service_date_from).isSame(tempDate)
    );

    return (
      <>
        {newData.map((item: any) => (
          <div style={{ color: "white" }}>{item.facility_name}</div>
        ))}
      </>
    );
  };

  return (
    <>
      <IonRow>
        <IonCol className="left_right_icon ion-no-padding" size="1">
          <IonIcon
            className="backwardIcon"
            icon={chevronBackOutline}
            onClick={handlePrevDate}
          />
        </IonCol>
        <IonCol className="calander_dispaly ion-no-padding">
          <div className="calender-title" onClick={show}>
            <IconSvg Icon={CalendarIcon} />
            <span className="day">{`${
              dateDayFormater(dateTimeStamp).day
            },`}</span>
            <span className="date">{`${
              dateDayFormater(dateTimeStamp).newdate
            }`}</span>
          </div>
          {bellcounter ? (
            <div className="count-circle calander">
              <IonIcon
                icon={notifications}
                className="count-circle-icon"
              ></IonIcon>
              <IonText className="count">14</IonText>
            </div>
          ) : null}
          {/* <IonModal
            style={{ zIndex: "10" }}
            isOpen={isDatePickerOpen}
            breakpoints={[0, 0.2, 0.5, 1]}
            initialBreakpoint={0.5}
            backdropBreakpoint={0.2}
          >
            {displayDailyCensus()}
          </IonModal> */}
          <Datepicker
            controls={["calendar"]}
            showOnClick={false}
            showOnFocus={false}
            isOpen={isDatePickerOpen}
            onClose={onClose}
            onChange={onCalendarChange}
            onCellClick={handleDateSelect}
            onPageChange={handleMonthChange}
            touchUi={true}
            value={tempDate}
          />
        </IonCol>
        <IonCol className="left_right_icon ion-no-padding" size="1">
          <IonIcon
            className="forwardIcon"
            icon={chevronForwardOutline}
            onClick={handleNextDate}
          />
        </IonCol>
      </IonRow>
    </>
  );
};

export default MobisrollCalendar;
