import React, { useState } from "react";
import { IonIcon, IonInput, IonLabel } from "@ionic/react";
import { calendarClearOutline } from "ionicons/icons";
import moment from "moment";
import { Datepicker } from "@mobiscroll/react";
import "./DateOfServiceCard.scss";

type DateOfServiceCardProps = {
  onSelect: (date: string) => void;
  dateValue: any;
};

const DateOfServiceCard: React.FC<DateOfServiceCardProps> = ({
  onSelect,
  dateValue,
}) => {
  const onDateChange = (date: any) => {
    if (onSelect) {
      onSelect(date);
    }
  };

  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  // const [tempDate, setTempDate] = useState(new Date());

  const show = () => {
    setDatePickerOpen(true);
  };
  const onClose = (e: any) => {
    setDatePickerOpen(false);
    // setTempDate(dateValue);
  };

  const onSet = (e: any) => {
    setDatePickerOpen(false);
    onSelect(e.value);
  };

  const handleDateSelect = (e: any) => {
    // setTempDate(e.date);
  };

  return (
    <div className="dateOfServiceCardContent">
      <p>Visit time</p>
      <IonLabel className="dateOfServiceCardInput" onClick={() => show()}>
        <IonInput
          type="text"
          placeholder="DD/MM/YYYY"
          value={dateValue ? moment(dateValue).format("DD/MM/YYYY") : ""}
        />
        <IonIcon icon={calendarClearOutline} />
      </IonLabel>
      {isDatePickerOpen && (
        <Datepicker
          controls={["calendar"]}
          showOnClick={false}
          showOnFocus={false}
          isOpen={isDatePickerOpen}
          onClose={onClose}
          onChange={onSet}
          touchUi={true}
          value={dateValue}
          onCellClick={handleDateSelect}
          className="calendardiv-encounterHistory"
        />
      )}
    </div>
  );
};

export default DateOfServiceCard;
