import React from "react";
import "./Facilities.scss";
import Card from "../../../components/Common/Card/Card";

import { IonText, IonImg, useIonRouter } from "@ionic/react";
import Loader from "../../../components/Common/Loaders/Loader";

interface FacilitiesProps {
  items?: any;
  isLoading?: boolean;
}

const slideOpts = {
  speed: 800,
  slidesPerView: 3,
  //initialSlide: 1,
  centeredSlides: true,
  loop: true,
  direction: "horizontal",
  spaceBetween: 100,
  lockSwipeToNext: true,
};

const FacilitiesList: React.FC<FacilitiesProps> = (props: any) => {
  const router = useIonRouter();

  const goToCensus = (item: any) => {
    router.push("census/" + item?.facility_id, "forward");
  };
  
  return (
    <div>
      <IonText className="ion-facility-text card-title">Facilities</IonText>
      <div className="facilities-card-scroll">
        {props?.isLoading === true ? (
          <div className="emptyPatients">
            <Loader
              data={{
                name: "crescent",
                color: "primary",
              }}
            />
          </div>
        ) : props?.isLoading === false && props?.items?.length > 0 ? (
          props?.items?.map((item: any, i: any) => (
            <div key={i}>
              <Card className="card1-facilities ion-no-padding ion-no-margin">
                <div onClick={(e) => goToCensus(item)}>
                  {" "}
                  <IonImg
                    src="./assets/icon/medikit.png"
                    className="ion-image-facilities"
                  />
                  <IonText className="ion-facility-text1">{item?.name}</IonText>
                </div>
              </Card>
            </div>
          ))
        ) : props?.isLoading === false && props?.items?.length === 0 ? (
          <div className="emptyFacility">
          <p className="list__fallback">No result found</p>
        </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default FacilitiesList;
