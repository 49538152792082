import { IonCard, IonGrid, IonText, IonImg } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./Hint.scss";
import { VisitsByServicedate } from "../../../types/VisitsByServicedate";
import moment from "moment";

interface HintProps {
  patients: VisitsByServicedate[];
}

const Hint: React.FC<HintProps> = ({ patients }) => {
  const [patientsWithAvailable, setPatientsWithAvailable] = useState<
    VisitsByServicedate[]
  >([]);

  useEffect(() => {
    if (patients?.length > 0 && patientsWithAvailable?.length < 1) {
      let tempList = patients.filter(
        (item: any) =>
          item?.statusGroup === "Available" &&
          moment(new Date()).diff(moment(item?.service_date_from), "days") > 3
      );

      setPatientsWithAvailable(tempList);
    }
  }, [patients]);

  return (
    <div className="hint-container">
      <IonGrid>
        <div className="header-container">
          <IonText className="Hint-heading">Hints for you</IonText>
        </div>
        <div className="card-bottom">
          <IonCard className="bottomcards">
            <div>
              <div className="card-text1">Patient not visited</div>
              <div className="card-text1">for 3 days</div>
              {patientsWithAvailable.map((item: any) => (
                <div key={item?._id}>
                  <div className="card-text2">{item?.patient_name}</div>
                  <div className="card-text3">{item?.facility_name}</div>
                  <div className="card-text3">
                    {" "}
                    Last seen {moment(item.service_date_from).format("MM/DD")}
                  </div>
                </div>
              ))}
            </div>
          </IonCard>
          <IonCard className="bottomcards second-card">
            <div className="box">
              <div className="right div-1">How to use the Progress note?</div>
              <div className="right div-2">3 minutes</div>
              <div className="left1 div-img">
                <IonImg
                  src="./assets/icon/Lista.png"
                  alt="no-image"
                  className="ion-image-bottomcards"
                />
              </div>
            </div>
          </IonCard>
        </div>
      </IonGrid>
    </div>
  );
};
export default Hint;
