import React from "react";
import "./PatientItem.scss";
import Card from "../../../components/Common/Card/Card";
import { alertOutline } from "ionicons/icons";
import {
  IonIcon,
  IonRow,
  IonCol,
  IonGrid,
  IonText,
  IonLabel,
  useIonRouter,
} from "@ionic/react";
import { Warning } from "../../../icons/icons";
import { IconSvg } from "../../../components/Common/IconSvg/IconSvg";
interface PatientItemProps {
  key?: any;
  name?: any;
  patientID?:any;
  pnumber?: any;
  ptext1?: any;
  ptext2?: any;
}

const PatientItem: React.FC<PatientItemProps> = (props: any) => {
  const router=useIonRouter();
  return (
    <Card className="patient-item">
      <IonGrid onClick={(e) => {
              router.push("patientDetails/" + props.patientID, "forward");
            }}>
        <IonRow>
          <IonCol size="1"> </IonCol>
          <IonCol
            class="ion-align-self-start"
            className="patient-item__description"
            size="5.1"
          >
            <IonText>{props.name} </IonText>
          </IonCol>
          <IonCol size=".1">|</IonCol>
          <IonCol size="2.1"></IonCol>
          <IonCol size=".1">
            <div className="icon-holder">
              <IconSvg Icon={Warning} />
            </div>
          </IonCol>
          <IonCol size="1.5">
            <IonLabel className="ion-text-AC">AC</IonLabel>
          </IonCol>
          <IonCol class="ion-align-self-end" size="1"></IonCol>
        </IonRow>
        <IonRow>
          <IonCol class="ion-align-self-left" size=".8">
            <IonIcon
              class="ion-align-self-left"
              icon={alertOutline}
              className="patientitem-alert"
            />
          </IonCol>
          <IonCol className="ptext1" size="5">
            {props.ptext1}
          </IonCol>
          <IonCol size="2.1">{}</IonCol>
          <IonCol size="2.1">{}</IonCol>
          <IonCol class="ion-align-self-end" size="1.7">
            {props.pnumber}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size=".9"> </IonCol>
          <IonCol size="6" className="ptext2">
            &nbsp;{/* $80 receivables missing */}
          </IonCol>
          <IonCol class="my-thin-col-patientitem"> </IonCol>
        </IonRow>
      </IonGrid>
    </Card>
  );
};

export default PatientItem;
