import moment from "moment";
import { AddPatientFormValueProps, EditPatientFormValueProps, EditProfileFormValueProps, } from "../types/Types";
// import React from "react";

// interface dateProps  {
//     pastDateTrue?: boolean;
//     futureDateTrue?: boolean;
//     date?:string;
//   }
export const dateValidation = (
  pastDateTrue: boolean,
  futureDateTrue: boolean,
  date: string
) => {
  if (date.length < 10) {
    alert("Invalid date format");
  }
  if (pastDateTrue) {
    let getDiff = moment().diff(date, "days");
    if (getDiff > 0) {
      alert("Past dates not allowed");
    }
  }
  if (futureDateTrue) {
    let getDiff = moment().diff(date, "days");
    if (getDiff < 0) {
      alert("Future dates not allowed");
    }
  }
};
export const futureDateValidation = () => { };

export const addPatientPayloadValidater = (
  values: AddPatientFormValueProps
) => {

  if (
    values.DOI !== "" &&
    values.FacilityMRN !== "" &&
    values.Room !== "" &&
    values.gender !== "" &&
    values.lastname !== "" &&
    values.selectedFacility !== null &&
    values.firstname !== "" &&
    values.lastname.length > 1 &&
    values.firstname.length > 1
  ) {
    return { validate: true };
  } else {
    return { validate: false };
  }
};

export const editPatientPayloadValidater = (
  values: EditPatientFormValueProps
) => {

  if (
    values.gender !== "" &&
    values.last_name !== "" &&
    values.first_name !== "" &&
    values.last_name.length > 1 &&
    values.first_name.length > 1
  ) {
    return { validate: true };
  } else {
    return { validate: false };
  }
};
export const editProfilePayloadValidater = (
  values: EditProfileFormValueProps
) => {

  if (
    values.username !== "" &&
    values.displayname !== "" &&
    values.user_title !== null &&
    // values?.email !== "" &&
    // values?.phone !== "" &&
    values.username.length > 2 &&
    values.displayname.length > 2 &&
    values.user_title.length > 2
  ) {
    return { validate: true };
  } else {
    return { validate: false };
  }
};
