
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { IonCol, IonRow } from "@ionic/react";
import "../Payment/Payment.scss";
import ProgressBarComp from "../../components/Analytics/ProgressBarComp";
import "./PracticeIQ.scss";
import { useAppSelector } from "../../store/hooks";
import { getPlaceOfservice, getProvidername } from "../../services/PracticeIQ";

interface DataPropsType {
    first_name: string;
    last_name: string;
    percentage: number;
    code: string;
    code_group: string;
    facility: string;
    payment_type: string;
    payor: string;
    time: string;
}
interface DataTablePropsType {
    header: DataPropsType[];
    columnSize: string[];
    //  data: DataPropsType[];
    colors: string[];
    slider2Data: string;
    revRes?: {
        proc_group_name: string
        proc_code_name: string
        total_count: number
        _id: {
            provider_id: string,
            service_date_from: string,
            proc_code: string,
            facility_id: string,
            payment_type: string,
            payor: string,
            proc_code_group: string
        }
    }[],
    daysPerEncRes?: {
        proc_group_name: string
        proc_code_name: string
        total_count: number
        _id: {
            provider_id: string,
            service_date_from: string,
            proc_code: string,
            facility_id: string,
            payment_type: string,
            payor: string,
            proc_code_group: string
        }
    }[],
    revenue?: boolean,
    encounter?: boolean,
    rvu?: boolean,
    compnent: string,
    avgRevenue?: boolean,
    daysperenc: string
}

type okObj = {
    percentage?: number | string;
    encounter?: number | string;
    rvu?: number | string;
    revenue?: number | string;
    avgRevenue?: number | string;
    daysperenc?: number | string;
    totalv_count?: number | string;
    visit_count?: number | string;
}

const DataTable: React.FC<any> = (props: DataTablePropsType) => {

    const [filteredData, setFilteredData] = useState<any>([]);
    const [totalCount, setTotalCount] = useState<any>([])
    const getmappedProviders: any = useAppSelector((state) => state.user.practice)

    const caluclateTotal = (data: any, a = 0) => {
        let newoBJ: okObj = { percentage: 0, encounter: 0, rvu: 0, revenue: 0, daysperenc: 0, avgRevenue: 0, totalv_count: 0, visit_count: 0 }
        if (data.length !== 0) {
            for (let i = 0; i < data.length; i++) {
                newoBJ.encounter = data[i].hasOwnProperty("encounter") ? newoBJ.encounter + data[i].encounter : ""
                newoBJ.totalv_count = data[i].hasOwnProperty("totalv_count") ? newoBJ.totalv_count + data[i].totalv_count : 0
                newoBJ.visit_count = data[i].hasOwnProperty("visit_count") ? newoBJ.visit_count + data[i].visit_count : 0
                newoBJ.percentage = newoBJ.percentage + (Number.isNaN(data[i].percentage) ? 0 : data[i].percentage)
                newoBJ.rvu = data[i].hasOwnProperty("rvu") ? newoBJ.rvu + data[i].rvu : ""
                newoBJ.revenue = data[i].hasOwnProperty("revenue") ? newoBJ.revenue + data[i].revenue : ""
                newoBJ.avgRevenue = data[i].hasOwnProperty("avgRevenue") ? newoBJ.avgRevenue + data[i].avgRevenue : ""
                newoBJ.daysperenc = data[i].hasOwnProperty("daysperenc") ? newoBJ.daysperenc + data[i].daysperenc : ""
            }
        }
        if (a === 1) {
            let newObje: any = Object.assign({}, newoBJ);
            newObje.percentage = newObje.totalv_count / newObje.visit_count;
            setTotalCount([newObje])
        } else {
            let new_objs: okObj = {}
            const getObjects = (itm: okObj) => {
                let key: keyof okObj
                for (key in itm) {
                    if (Number.isNaN(itm[key]) !== true && itm[key] !== "") {
                        if (itm[key] === "percentage") {
                            new_objs[key] = itm[key]
                        } else new_objs[key] = itm[key]?.toString()
                    }
                }
            }
            getObjects(newoBJ)
            setTotalCount([new_objs])
        }

    }


    useEffect(() => {
        const getData = () => {
            if (props?.revRes?.length !== 0 && props?.revRes !== undefined) {
                if (props?.revRes?.length !== 0 && props.slider2Data === "Payor") {
                    let mycount = 0
                    let revnueData: Array<any> = [];
                    props?.revRes?.map((item: any, index: number) => {
                        mycount = mycount + item.total_count
                        let obj = { name: "", revenue: 0, id: "", encounter: 0, rvu: 0 }
                        if (index === 0) {
                            obj.name = item._id.payor
                            obj.id = item._id.payor
                            obj.revenue = item.total_count
                            obj.encounter = item.total_count
                            obj.rvu = item.total_count
                            revnueData.push(obj)
                        } else {
                            const foundIndx = revnueData.findIndex(itm => itm.id === item._id.payor)

                            if (foundIndx == -1 || false) {
                                obj.name = item._id.payor
                                obj.id = item._id.payor
                                obj.revenue = item.total_count
                                obj.encounter = item.total_count
                                obj.rvu = item.total_count
                                revnueData.push(obj)
                            }
                            else {
                                revnueData[foundIndx].revenue = revnueData[foundIndx].revenue + item.total_count
                                revnueData[foundIndx].encounter = revnueData[foundIndx].encounter + item.total_count
                                revnueData[foundIndx].rvu = revnueData[foundIndx].rvu + item.total_count
                            }
                        }
                    })
                    if (props.encounter) {
                        revnueData.sort(function (a: any, b: any) { return b.encounter - a.encounter });
                    } else if (props.rvu) {
                        revnueData.sort(function (a: any, b: any) { return b.rvu - a.rvu });
                    } else {
                        revnueData.sort(function (a: any, b: any) { return b.revenue - a.revenue });
                    }
                    setFilteredData([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.revenue / mycount * 100 }
                    })])
                    caluclateTotal([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.revenue / mycount * 100 }
                    })])
                } else if (props.revRes.length !== 0 && props.slider2Data === "Name") {
                    let mycount = 0
                    let revnueData: Array<any> = [];
                    props.revRes.map((item: any, index: number) => {
                        mycount = mycount + item.total_count
                        let obj = { name: "", revenue: 0, id: "", encounter: 0, rvu: 0, avgRevenue: 0, visit_count: 0 }
                        if (index === 0) {
                            obj.name = getProvidername(item._id.provider_id)
                            obj.id = item._id.provider_id
                            obj.revenue = item.total_count
                            obj.encounter = item.total_count
                            obj.rvu = item.total_count
                            obj.avgRevenue = item.total_amount
                            obj.visit_count = item.count
                            revnueData.push(obj)
                        } else {
                            const foundIndx = revnueData.findIndex(itm => itm.id === item._id.provider_id)

                            if (foundIndx == -1 || false) {
                                obj.name = getProvidername(item._id.provider_id)
                                obj.id = item._id.provider_id
                                obj.revenue = item.total_count
                                obj.encounter = item.total_count
                                obj.rvu = item.total_count
                                obj.avgRevenue = item.total_amount
                                obj.visit_count = item.count
                                revnueData.push(obj)
                            }
                            else {
                                revnueData[foundIndx].revenue = revnueData[foundIndx].revenue + item.total_count
                                revnueData[foundIndx].encounter = revnueData[foundIndx].encounter + item.total_count
                                revnueData[foundIndx].rvu = revnueData[foundIndx].rvu + item.total_count
                                revnueData[foundIndx].avgRevenue = revnueData[foundIndx].avgRevenue + item.total_amount
                                revnueData[foundIndx].visit_count = revnueData[foundIndx].visit_count + item.count
                            }
                        }
                    })
                    if (props.encounter) {
                        revnueData.sort(function (a: any, b: any) { return b.encounter - a.encounter });
                    } else if (props.rvu) {
                        revnueData.sort(function (a: any, b: any) { return b.rvu - a.rvu });
                    } else if (props.compnent === "avgRevenue") {
                        revnueData.sort(function (a: any, b: any) { return (b.avgRevenue / b.visit_count) - (a.avgRevenue / a.visit_count) });
                    } else {
                        revnueData.sort(function (a: any, b: any) { return b.revenue - a.revenue });
                    }
                    setFilteredData([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.revenue ? itm.revenue / mycount * 100 : itm.avgRevenue / itm.visit_count }
                    })])
                    caluclateTotal([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.revenue ? itm.revenue / mycount * 100 : itm.avgRevenue / itm.visit_count }
                    })])
                }
                else if (props.revRes.length !== 0 && props.slider2Data === "Payment type") { //payment_type
                    let mycount = 0
                    let revnueData: Array<any> = [];
                    props.revRes.map((item: any, index: number) => {
                        mycount = mycount + item.total_count
                        let obj = { name: "", revenue: 0, id: "", encounter: 0, rvu: 0 }
                        if (index === 0) {
                            obj.name = item._id.payment_type
                            obj.id = item._id.payment_type
                            obj.revenue = item.total_count
                            obj.encounter = item.total_count
                            obj.rvu = item.total_count
                            revnueData.push(obj)
                        } else {
                            const foundIndx = revnueData.findIndex(itm => itm.id === item._id.payment_type)

                            if (foundIndx == -1 || false) {
                                obj.name = item._id.payment_type
                                obj.id = item._id.payment_type
                                obj.revenue = item.total_count
                                obj.encounter = item.total_count
                                obj.rvu = item.total_count
                                revnueData.push(obj)
                            }
                            else {
                                revnueData[foundIndx].revenue = revnueData[foundIndx].revenue + item.total_count
                                revnueData[foundIndx].encounter = revnueData[foundIndx].encounter + item.total_count
                                revnueData[foundIndx].rvu = revnueData[foundIndx].rvu + item.total_count
                            }
                        }
                    })
                    if (props.encounter) {
                        revnueData.sort(function (a: any, b: any) { return b.encounter - a.encounter });
                    } else if (props.rvu) {
                        revnueData.sort(function (a: any, b: any) { return b.rvu - a.rvu });
                    } else if (props.avgRevenue) {
                        revnueData.sort(function (a: any, b: any) { return b.avgRevenue - a.avgRevenue });
                    } else {
                        revnueData.sort(function (a: any, b: any) { return b.revenue - a.revenue });
                    }
                    setFilteredData([...revnueData.map((itm: any) => {
                        return { ...itm, percentage:  itm.revenue / mycount * 100 }
                    })])
                    caluclateTotal([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.revenue / mycount * 100 }
                    })])
                } else if (props.revRes.length !== 0 && props.slider2Data === "Place") {
                    let mycount = 0
                    let avgRevenueCount = 0
                    let revnueData: Array<any> = [];
                    props.revRes.map((item: any, index: number) => {
                        mycount = mycount + item.total_count
                        avgRevenueCount = avgRevenueCount + item.count
                        let obj = { name: "", revenue: 0, id: "", encounter: 0, rvu: 0, avgRevenue: 0, visit_count: 0 }
                        if (index === 0) {
                            obj.name = getPlaceOfservice(item._id.facility_id)
                            obj.id = item._id.facility_id
                            obj.revenue = item.total_count
                            obj.encounter = item.total_count
                            obj.rvu = item.total_count
                            obj.avgRevenue = item.total_amount
                            obj.visit_count = item.count
                            revnueData.push(obj)
                        } else {
                            const foundIndx = revnueData.findIndex(itm => itm.name === getPlaceOfservice(item._id.facility_id))

                            if (foundIndx == -1 || false) {
                                obj.name = getPlaceOfservice(item._id.facility_id)
                                obj.id = item._id.facility_id
                                obj.revenue = item.total_count
                                obj.encounter = item.total_count
                                obj.rvu = item.total_count
                                obj.avgRevenue = item.total_amount
                                obj.visit_count = item.count
                                revnueData.push(obj)
                            }
                            else {
                                revnueData[foundIndx].revenue = revnueData[foundIndx].revenue + item.total_count
                                revnueData[foundIndx].encounter = revnueData[foundIndx].encounter + item.total_count
                                revnueData[foundIndx].rvu = revnueData[foundIndx].rvu + item.total_count
                                revnueData[foundIndx].avgRevenue = revnueData[foundIndx].avgRevenue + item.total_amount
                                revnueData[foundIndx].visit_count = revnueData[foundIndx].visit_count + item.count
                            }
                        }
                    })
                    if (props.encounter) {
                        revnueData.sort(function (a: any, b: any) { return b.encounter - a.encounter });
                    } else if (props.rvu) {
                        revnueData.sort(function (a: any, b: any) { return b.rvu - a.rvu });
                    } else if (props.compnent === "avgRevenue") {
                        revnueData.sort(function (a: any, b: any) { return (b.avgRevenue / b.visit_count) - (a.avgRevenue / a.visit_count) });
                    } else {
                        revnueData.sort(function (a: any, b: any) { return b.revenue - a.revenue });
                    }
                    setFilteredData([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.revenue ? itm.revenue / mycount * 100 : itm.avgRevenue / itm.visit_count }
                    })])
                    caluclateTotal([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.revenue ? itm.revenue / mycount * 100 : itm.avgRevenue / itm.visit_count }
                    })])
                } else if (props.revRes.length !== 0 && props.slider2Data === "Code group") {
                    let mycount = 0
                    let revnueData: Array<any> = [];
                    props.revRes.map((item: any, index: number) => {
                        mycount = mycount + item.total_count
                        let obj = { name: "", revenue: 0, id: "", encounter: 0, rvu: 0, avgRevenue: 0, visit_count: 0 }
                        if (index === 0) {
                            obj.name = item.proc_group_name
                            obj.id = item._id.proc_code_group
                            obj.revenue = item.total_count
                            obj.encounter = item.total_count
                            obj.rvu = item.total_count
                            obj.avgRevenue = item.total_amount
                            obj.visit_count = item.count
                            revnueData.push(obj)
                        } else {
                            const foundIndx = revnueData.findIndex(itm => itm.id === item._id.proc_code_group)

                            if (foundIndx == -1 || false) {
                                obj.name = item.proc_group_name
                                obj.id = item._id.proc_code_group
                                obj.revenue = item.total_count
                                obj.encounter = item.total_count
                                obj.rvu = item.total_count
                                obj.avgRevenue = item.total_amount
                                obj.visit_count = item.count
                                revnueData.push(obj)
                            }
                            else {
                                revnueData[foundIndx].revenue = revnueData[foundIndx].revenue + item.total_count
                                revnueData[foundIndx].encounter = revnueData[foundIndx].encounter + item.total_count
                                revnueData[foundIndx].rvu = revnueData[foundIndx].rvu + item.total_count
                                revnueData[foundIndx].avgRevenue = revnueData[foundIndx].avgRevenue + item.total_amount
                                revnueData[foundIndx].visit_count = revnueData[foundIndx].visit_count + item.count
                            }
                        }
                    })
                    if (props.encounter) {
                        revnueData.sort(function (a: any, b: any) { return b.encounter - a.encounter });
                    } else if (props.rvu) {
                        revnueData.sort(function (a: any, b: any) { return b.rvu - a.rvu });
                    } else if (props.compnent === "avgRevenue") {
                        revnueData.sort(function (a: any, b: any) { return (b.avgRevenue / b.visit_count) - (a.avgRevenue / a.visit_count) });
                    } else {
                        revnueData.sort(function (a: any, b: any) { return b.revenue - a.revenue });
                    }
                    setFilteredData([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.revenue ? itm.revenue / mycount * 100 : itm.avgRevenue / itm.visit_count }
                    })])
                    caluclateTotal([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.revenue ? itm.revenue / mycount * 100 : itm.avgRevenue / itm.visit_count }
                    })])
                } else if (props.revRes.length !== 0 && props.slider2Data === "Code") { //proc_code
                    let mycount = 0
                    let revnueData: Array<any> = [];
                    props.revRes.map((item: any, index: number) => {
                        mycount = mycount + item.total_count
                        let obj = { name: "", revenue: 0, id: "", encounter: 0, rvu: 0, avgRevenue: 0, visit_count: 0 }
                        if (index === 0) {
                            obj.name = `${item._id.proc_code} (${item.proc_code_name})`
                            obj.id = item._id.proc_code
                            obj.revenue = item.total_count
                            obj.encounter = item.total_count
                            obj.rvu = item.total_count
                            obj.avgRevenue = item.total_amount
                            obj.visit_count = item.count
                            revnueData.push(obj)
                        } else {
                            const foundIndx = revnueData.findIndex(itm => itm.id === item._id.proc_code)

                            if (foundIndx == -1 || false) {
                                obj.name = `${item._id.proc_code} (${item.proc_code_name})`
                                obj.id = item._id.proc_code
                                obj.revenue = item.total_count
                                obj.encounter = item.total_count
                                obj.rvu = item.total_count
                                obj.avgRevenue = item.total_amount
                                obj.visit_count = item.count
                                revnueData.push(obj)
                            }
                            else {
                                revnueData[foundIndx].revenue = revnueData[foundIndx].revenue + item.total_count
                                revnueData[foundIndx].encounter = revnueData[foundIndx].encounter + item.total_count
                                revnueData[foundIndx].rvu = revnueData[foundIndx].rvu + item.total_count
                                revnueData[foundIndx].avgRevenue = revnueData[foundIndx].avgRevenue + item.total_amount
                                revnueData[foundIndx].visit_count = revnueData[foundIndx].visit_count + item.count
                            }
                        }
                    })

                    if (props.encounter) {
                        revnueData.sort(function (a: any, b: any) { return b.encounter - a.encounter });
                    } else if (props.rvu) {
                        revnueData.sort(function (a: any, b: any) { return b.rvu - a.rvu });
                    } else if (props.compnent === "avgRevenue") {
                        revnueData.sort(function (a: any, b: any) { return (b.avgRevenue / b.visit_count) - (a.avgRevenue / a.visit_count) });
                    } else {
                        revnueData.sort(function (a: any, b: any) { return b.revenue - a.revenue });
                    }
                    setFilteredData([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.revenue ? itm.revenue / mycount * 100 : itm.avgRevenue / itm.visit_count }
                    })])
                    caluclateTotal([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.revenue ? itm.revenue / mycount * 100 : itm.avgRevenue / itm.visit_count }
                    })])
                } else if (props.revRes.length !== 0 && props.slider2Data === "Facility") { //facility_id
                    let mycount = 0
                    let revnueData: Array<any> = [];
                    props.revRes.map((item: any, index: number) => {
                        mycount = mycount + item.total_count
                        let obj = { name: "", revenue: 0, id: "", encounter: 0, rvu: 0, avgRevenue: 0, visit_count: 0 }
                        if (index === 0) {
                            obj.name = item._id.facility_id
                            obj.id = item._id.facility_id
                            obj.revenue = item.total_count
                            obj.encounter = item.total_count
                            obj.rvu = item.total_count
                            obj.avgRevenue = item.total_amount
                            obj.visit_count = item.count
                            revnueData.push(obj)
                        } else {
                            const foundIndx = revnueData.findIndex(itm => itm.id === item._id.facility_id)

                            if (foundIndx == -1 || false) {
                                obj.name = item._id.facility_id
                                obj.id = item._id.facility_id
                                obj.revenue = item.total_count
                                obj.encounter = item.total_count
                                obj.rvu = item.total_count
                                obj.avgRevenue = item.total_amount
                                obj.visit_count = item.count
                                revnueData.push(obj)
                            }
                            else {
                                revnueData[foundIndx].revenue = revnueData[foundIndx].revenue + item.total_count
                                revnueData[foundIndx].encounter = revnueData[foundIndx].encounter + item.total_count
                                revnueData[foundIndx].rvu = revnueData[foundIndx].rvu + item.total_count
                                revnueData[foundIndx].avgRevenue = revnueData[foundIndx].avgRevenue + item.total_amount
                                revnueData[foundIndx].visit_count = revnueData[foundIndx].visit_count + item.count
                            }
                        }
                    })
                    if (props.encounter) {
                        revnueData.sort(function (a: any, b: any) { return b.encounter - a.encounter });
                    } else if (props.rvu) {
                        revnueData.sort(function (a: any, b: any) { return b.rvu - a.rvu });
                    } else if (props.compnent === "avgRevenue") {
                        revnueData.sort(function (a: any, b: any) { return (b.avgRevenue / b.visit_count) - (a.avgRevenue / a.visit_count) });
                    } else {
                        revnueData.sort(function (a: any, b: any) { return b.revenue - a.revenue });
                    }
                    setFilteredData([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.revenue ? itm.revenue / mycount * 100 : itm.avgRevenue / itm.visit_count }
                    })])
                    caluclateTotal([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.revenue ? itm.revenue / mycount * 100 : itm.avgRevenue / itm.visit_count }
                    })])
                } else if (props.revRes.length !== 0 && props.slider2Data === "Time") {
                    let mycount = 0
                    let revnueData: Array<any> = [];
                    props.revRes.map((item: any, index: number) => {
                        mycount = mycount + item.total_count
                        let obj = { name: "", revenue: 0, id: "", encounter: 0, rvu: 0, avgRevenue: 0, visit_count: 0 }
                        if (index === 0) {
                            obj.name = item._id.service_date_from
                            obj.id = item._id.service_date_from
                            obj.revenue = item.total_count
                            obj.encounter = item.total_count
                            obj.rvu = item.total_count
                            obj.avgRevenue = item.total_amount
                            obj.visit_count = item.count
                            revnueData.push(obj)
                        } else {
                            const foundIndx = revnueData.findIndex(itm => itm.id === item._id.service_date_from)

                            if (foundIndx == -1 || false) {
                                obj.name = item._id.service_date_from
                                obj.id = item._id.service_date_from
                                obj.revenue = item.total_count
                                obj.encounter = item.total_count
                                obj.rvu = item.total_count
                                obj.avgRevenue = item.total_amount
                                obj.visit_count = item.count
                                revnueData.push(obj)
                            }
                            else {
                                revnueData[foundIndx].revenue = revnueData[foundIndx].revenue + item.total_count
                                revnueData[foundIndx].encounter = revnueData[foundIndx].encounter + item.total_count
                                revnueData[foundIndx].rvu = revnueData[foundIndx].rvu + item.total_count
                                revnueData[foundIndx].avgRevenue = revnueData[foundIndx].avgRevenue + item.total_amount
                                revnueData[foundIndx].visit_count = revnueData[foundIndx].visit_count + item.count
                            }
                        }
                    })
                    if (props.encounter) {
                        revnueData.sort(function (a: any, b: any) { return b.encounter - a.encounter });
                    } else if (props.rvu) {
                        revnueData.sort(function (a: any, b: any) { return b.rvu - a.rvu });
                    } else if (props.avgRevenue) {
                        revnueData.sort(function (a: any, b: any) { return b.avgRevenue - a.avgRevenue });
                    } else {
                        revnueData.sort(function (a: any, b: any) { return a.revenue - b.revenue });
                    }
                    setFilteredData([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.revenue ? itm.revenue / mycount * 100 : itm.avgRevenue / itm.visit_count }
                    }).reverse()])
                    caluclateTotal([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.revenue ? itm.revenue / mycount * 100 : itm.avgRevenue / itm.visit_count }
                    }).reverse()])
                }
                else {
                    if (props.encounter) {
                        props.revRes.sort(function (a: any, b: any) { return b.encounter - a.encounter });
                    } else if (props.rvu) {
                        props.revRes.sort(function (a: any, b: any) { return b.rvu - a.rvu });
                    } else {
                        props.revRes.sort(function (a: any, b: any) { return b.revenue - a.revenue });
                    }
                    setFilteredData(props.revRes)
                    caluclateTotal(props.revRes)
                }
                //service_date_from

            } else if (props?.daysPerEncRes?.length !== 0) {
                if (props?.daysPerEncRes?.length !== 0 && props.slider2Data === "Name") {
                    let mycount = 0
                    let revnueData: Array<any> = [];
                    props?.daysPerEncRes?.map((item: any, index: number) => {
                        mycount = mycount + 1;
                        let obj = { name: "", revenue: 0, id: "", encounter: 0, rvu: 0, daysperenc: 0, visit_count: 0, totalv_count: 0 }
                        if (index === 0) {
                            obj.name = getProvidername(item._id.provider_id)
                            obj.id = item._id.provider_id
                            obj.revenue = item.count
                            obj.encounter = item.count
                            obj.rvu = item.count
                            obj.daysperenc = item.count
                            revnueData.push(obj)
                        } else {
                            const foundIndx = revnueData.findIndex(itm => itm.id === item._id.provider_id)

                            if (foundIndx == -1 || false) {
                                obj.name = getProvidername(item._id.provider_id)
                                obj.id = item._id.provider_id
                                obj.visit_count = 0 + item.count;
                                obj.totalv_count = 0 + item.total_days_to_bill;
                                revnueData.push(obj)
                            }
                            else {
                                revnueData[foundIndx].daysperenc = revnueData[foundIndx].daysperenc + item.total_days_to_bill
                                revnueData[foundIndx].visit_count = revnueData[foundIndx].visit_count + item.count;
                                revnueData[foundIndx].totalv_count = revnueData[foundIndx].totalv_count + item.total_days_to_bill;
                            }


                        }
                    })
                    setFilteredData([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.totalv_count / itm.visit_count }
                    }).sort(function (a: any, b: any) { return b.percentage - a.percentage })])
                    caluclateTotal([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.totalv_count / itm.visit_count }
                    })], 1)
                } else if (props?.daysPerEncRes?.length !== 0 && props.slider2Data === "Place") {
                    let mycount = 0
                    let revnueData: Array<any> = [];
                    props?.daysPerEncRes?.map((item: any, index: number) => {
                        mycount = mycount + 1;
                        let obj = { name: "", revenue: 0, id: "", encounter: 0, rvu: 0, daysperenc: 0, visit_count: 0, totalv_count: 0 }
                        if (index === 0) {
                            obj.name = getPlaceOfservice(item._id.facility_id)
                            obj.id = item._id.facility_id
                            obj.revenue = item.count
                            obj.encounter = item.count
                            obj.rvu = item.count
                            obj.daysperenc = item.count
                            revnueData.push(obj)
                        } else {
                            const foundIndx = revnueData.findIndex(itm => itm.name === getPlaceOfservice(item._id.facility_id))

                            if (foundIndx == -1 || false) {
                                obj.name = getPlaceOfservice(item._id.facility_id)
                                obj.id = item._id.facility_id
                                obj.visit_count = 0 + item.count;
                                obj.totalv_count = 0 + item.total_days_to_bill;
                                revnueData.push(obj)
                            }
                            else {
                                revnueData[foundIndx].daysperenc = revnueData[foundIndx].daysperenc + item.total_days_to_bill
                                revnueData[foundIndx].visit_count = revnueData[foundIndx].visit_count + item.count;
                                revnueData[foundIndx].totalv_count = revnueData[foundIndx].totalv_count + item.total_days_to_bill;
                            }
                        }
                    })
                    setFilteredData([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.totalv_count / itm.visit_count }
                    }).sort(function (a: any, b: any) { return b.percentage - a.percentage })])
                    caluclateTotal([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.totalv_count / itm.visit_count }
                    })], 1)
                } else if (props?.daysPerEncRes?.length !== 0 && props.slider2Data === "Code group") {
                    let mycount = 0
                    let revnueData: Array<any> = [];
                    props?.daysPerEncRes?.map((item: any, index: number) => {
                        mycount = mycount + 1;
                        let obj = { name: "", revenue: 0, id: "", encounter: 0, rvu: 0, daysperenc: 0, visit_count: 0, totalv_count: 0 }
                        if (index === 0) {
                            obj.name = item.proc_group_name
                            obj.id = item._id.proc_code_group
                            obj.revenue = item.count
                            obj.encounter = item.count
                            obj.rvu = item.count
                            obj.daysperenc = item.count
                            revnueData.push(obj)
                        } else {
                            const foundIndx = revnueData.findIndex(itm => itm.id === item._id.proc_code_group)

                            if (foundIndx == -1 || false) {
                                obj.name = item.proc_group_name
                                obj.id = item._id.proc_code_group
                                obj.visit_count = 0 + item.count;
                                obj.totalv_count = 0 + item.total_days_to_bill;
                                revnueData.push(obj)
                            }
                            else {
                                revnueData[foundIndx].daysperenc = revnueData[foundIndx].daysperenc + item.total_days_to_bill
                                revnueData[foundIndx].visit_count = revnueData[foundIndx].visit_count + item.count;
                                revnueData[foundIndx].totalv_count = revnueData[foundIndx].totalv_count + item.total_days_to_bill;
                            }
                        }
                    })
                    setFilteredData([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.totalv_count / itm.visit_count }
                    }).sort(function (a: any, b: any) { return b.percentage - a.percentage })])
                    caluclateTotal([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.totalv_count / itm.visit_count }
                    })], 1)
                } else if (props?.daysPerEncRes?.length !== 0 && props.slider2Data === "Code") { //proc_code
                    let mycount = 0
                    let revnueData: Array<any> = [];
                    props?.daysPerEncRes?.map((item: any, index: number) => {
                        mycount = mycount + 1;
                        let obj = { name: "", revenue: 0, id: "", encounter: 0, rvu: 0, daysperenc: 0, visit_count: 0, totalv_count: 0 }
                        if (index === 0) {
                            obj.name = `${item._id.proc_code} (${item.proc_code_name})`
                            obj.id = item._id.proc_code
                            obj.revenue = item.count
                            obj.encounter = item.count
                            obj.rvu = item.count
                            obj.daysperenc = item.count
                            revnueData.push(obj)
                        } else {
                            const foundIndx = revnueData.findIndex(itm => itm.id === item._id.proc_code)

                            if (foundIndx == -1 || false) {
                                obj.name = `${item._id.proc_code} (${item.proc_code_name})`
                                obj.id = item._id.proc_code
                                obj.visit_count = 0 + item.count;
                                obj.totalv_count = 0 + item.total_days_to_bill;
                                revnueData.push(obj)
                            }
                            else {
                                revnueData[foundIndx].daysperenc = revnueData[foundIndx].daysperenc + item.total_days_to_bill
                                revnueData[foundIndx].visit_count = revnueData[foundIndx].visit_count + item.count;
                                revnueData[foundIndx].totalv_count = revnueData[foundIndx].totalv_count + item.total_days_to_bill;
                            }
                        }
                    })

                    setFilteredData([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.totalv_count / itm.visit_count }
                    }).sort(function (a: any, b: any) { return b.percentage - a.percentage })])
                    caluclateTotal([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.totalv_count / itm.visit_count }
                    })], 1)
                } else if (props?.daysPerEncRes?.length !== 0 && props.slider2Data === "Facility") { //facility_id
                    let mycount = 0
                    let revnueData: Array<any> = [];
                    props?.daysPerEncRes?.map((item: any, index: number) => {
                        mycount = mycount + 1;
                        let obj = { name: "", revenue: 0, id: "", encounter: 0, rvu: 0, daysperenc: 0, visit_count: 0, totalv_count: 0 }
                        if (index === 0) {
                            obj.name = item._id.facility_id
                            obj.id = item._id.facility_id
                            obj.revenue = item.count
                            obj.encounter = item.count
                            obj.rvu = item.count
                            obj.daysperenc = item.count
                            revnueData.push(obj)
                        } else {
                            const foundIndx = revnueData.findIndex(itm => itm.id === item._id.facility_id)

                            if (foundIndx == -1 || false) {
                                obj.name = item._id.facility_id
                                obj.id = item._id.facility_id
                                obj.visit_count = 0 + item.count;
                                obj.totalv_count = 0 + item.total_days_to_bill;
                                revnueData.push(obj)
                            }
                            else {
                                revnueData[foundIndx].daysperenc = revnueData[foundIndx].daysperenc + item.total_days_to_bill
                                revnueData[foundIndx].visit_count = revnueData[foundIndx].visit_count + item.count;
                                revnueData[foundIndx].totalv_count = revnueData[foundIndx].totalv_count + item.total_days_to_bill;
                            }
                        }
                    })
                    setFilteredData([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.totalv_count / itm.visit_count }
                    }).sort(function (a: any, b: any) { return b.percentage - a.percentage })])
                    caluclateTotal([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.totalv_count / itm.visit_count }
                    })], 1)
                } else if (props?.daysPerEncRes?.length !== 0 && props.slider2Data === "Time") {
                    let mycount = 0
                    let revnueData: Array<any> = [];
                    props?.daysPerEncRes?.map((item: any, index: number) => {
                        mycount = mycount + 1;
                        let obj = { name: "", revenue: 0, id: "", encounter: 0, rvu: 0, daysperenc: 0, visit_count: 0, totalv_count: 0 }
                        if (index === 0) {
                            obj.name = item._id.service_date_from
                            obj.id = item._id.service_date_from
                            obj.revenue = item.count
                            obj.encounter = item.count
                            obj.rvu = item.count
                            obj.daysperenc = item.count
                            revnueData.push(obj)
                        } else {
                            const foundIndx = revnueData.findIndex(itm => itm.id === item._id.service_date_from)

                            if (foundIndx == -1 || false) {
                                obj.name = item._id.service_date_from
                                obj.id = item._id.service_date_from
                                obj.visit_count = 0 + item.count;
                                obj.totalv_count = 0 + item.total_days_to_bill;
                                revnueData.push(obj)
                            }
                            else {
                                revnueData[foundIndx].daysperenc = revnueData[foundIndx].daysperenc + item.total_days_to_bill
                                revnueData[foundIndx].visit_count = revnueData[foundIndx].visit_count + item.count;
                                revnueData[foundIndx].totalv_count = revnueData[foundIndx].totalv_count + item.total_days_to_bill;
                            }
                        }
                    })
                    setFilteredData([...revnueData.map((itm: any) => {

                        return { ...itm, percentage: itm.totalv_count / itm.visit_count }
                    }).sort(function (a: any, b: any) { return b.percentage - a.percentage })])
                    caluclateTotal([...revnueData.map((itm: any) => {
                        return { ...itm, percentage: itm.totalv_count / itm.visit_count }
                    })], 1)
                } else {
                    if (props.daysperenc) {
                        props?.daysPerEncRes?.sort(function (a: any, b: any) { return b.daysperenc - a.daysperenc });
                    }
                    setFilteredData(props?.daysPerEncRes)
                    caluclateTotal(props?.daysPerEncRes)
                }
            }

        }
        getData()

    }, [props.slider2Data, props?.revRes])

    function commify(num: any) {
        var parts = num.toString().split(".");
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
    }

    const getColors = (indx: any, colors: any, num: any) => {
        const cal = Math.round(colors.length / indx + num)
        if (indx > colors.length) {
            return colors[cal]
        }
    }

    return (
        <div>
            <div style={{ width: "100%" }}>
                {filteredData.length !== 0 && <ProgressBarComp colors={props.colors} data={filteredData} />}
            </div>
            <div className="ln-content">
                <IonRow className="table-heading">
                    {props.header.map((item: any, index: number) => (
                        <IonCol
                            key={`${item}+${index}`}
                            size={props.columnSize[index]}
                            className="table-sub-heading"
                        >
                            {item}
                        </IonCol>
                    ))}
                </IonRow>
                {filteredData.length !== 0 && filteredData.map((item: any, index: any) => (
                    < div key={`${item.name} ${index}`}>
                        <div className="table-border"></div>
                        <IonRow className="table-item" style={{ borderLeft: "5px solid " + `${index < props.colors.length ? props.colors[index] : getColors(index, props.colors, 1)}`, }}>
                            <IonCol size="5"><span style={{ fontWeight: "600" }}>{item.name}</span></IonCol>
                            <IonCol size="4">{props.compnent === "encounter" ? item.encounter : props.compnent === "revenue" ? parseFloat(item.rvu).toFixed(2) : props.compnent === "rvu" && `${commify(parseFloat(item.revenue).toFixed(2))}`}</IonCol>
                            <IonCol className="alignmentright" size="2">
                                {props.compnent === 'avgRevenue' ? '$' + parseFloat(Number.isNaN(item.percentage) === true ? "0" : item.percentage).toFixed(2) :
                                    props?.compnent !== 'daysperenc' ? parseFloat(Number.isNaN(item.percentage) === true ? "0" : item.percentage).toFixed(2) + '%' :
                                        props?.compnent == 'daysperenc' && parseFloat(Number.isNaN(item.percentage) === true ? "0" : item.percentage).toFixed(2)}
                            </IonCol>
                        </IonRow>
                    </div>
                ))}
                <div className="table-border"></div>
                {
                    totalCount && filteredData && totalCount.map((item: any, index: number) => <IonRow key={index} className="table-item" style={{ marginTop: "0.1rem",paddingBottom:"1rem"}} >
                       <IonCol style={{ fontWeight: "600", fontSize: "13px" }} size="5">{props?.compnent === 'daysperenc'  ? 'Average' :  props?.compnent === 'avgRevenue' ? 'Average' : 'Total'}</IonCol>
                        <IonCol style={{ fontWeight: "600", fontSize: "13px" }} size="4">{item?.encounter && props.compnent === "encounter" && item.encounter || item?.revenue && props.compnent === "revenue" && `$${commify(parseFloat(item.revenue).toFixed(2))}` || item?.rvu && props.compnent === "rvu" && `${parseFloat(item.rvu).toFixed(2)}`}</IonCol>
                        <IonCol style={{ fontWeight: "600", fontSize: "13px" }} className="alignmentright" size="2">
                            {props?.compnent === 'avgRevenue' ? '$' + (item.avgRevenue / item.visit_count).toFixed(2) :
                                props?.compnent !== 'daysperenc' ? Math.round(item.percentage) + '%' :
                                    (item.percentage).toFixed(2)}
                        </IonCol>
                    </IonRow>)
                }
                {filteredData.length && totalCount.length === 0 && <div>No Record Found</div>}
            </div>
        </div>
    );
};

export default DataTable;