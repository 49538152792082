import React from "react";
import "./CustomCard.scss";
import { IonAccordion } from "@ionic/react";

const Card: React.FC<{
  title: string;
  step?: number | string | null | undefined;
  isStep?: boolean;
  isValid?: boolean;
}> = (props) => {
  return (
    <div
      className={props.isValid ? "card-wrapper card-border" : "card-wrapper"}
    >
      {props.isStep && (
        <div
          className={
            props.isValid
              ? "step-container bg-valid-step-container"
              : "step-container bg-step-container"
          }
        >
          <p className="step-number">{props.step}</p>
        </div>
      )}
      <IonAccordion className="accordian" value={props.title}>
        <div slot="header">
          <h2 className="card-title">{props.title}</h2>
        </div>
        <div slot="content">{props.children}</div>
      </IonAccordion>
    </div>
  );
};

export default Card;
