/* eslint-disable react-hooks/exhaustive-deps */

import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRow,
  IonText,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { timeOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { getPatientsSearch } from "../../helper/backendHelper";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectUser } from "../../store/reducers/user";
import "../SideMenu/sidemenu.scss";
import moment from "moment";
import { IconSvg } from "../Common/IconSvg/IconSvg";
import {
  CensusIcon,
  ChartIcon,
  CubeIcon,
  MedkitIcon,
  SettingsIcon,
  UserIcon,
  ListIcon,
  MailIcon,
  Edit2,
} from "../../icons/icons";
import PermissionProvider from "../PermissionProvider/PermissionProvider";
import SearchBox from "../Common/SearchBox/SearchBox";
import { setActiveDate } from "../../store/reducers/census";
import { setPatientDetail } from "../../store/reducers/newEncounter";
import Loader from "../Common/Loaders/Loader";
import { toast } from "@mobiscroll/react";
import FeatureProvider from "../PermissionProvider/FeatureProvider";

interface Props {
  icon: any;
  text: string;
  routerlink: string;
  isForward?: boolean;
}

const TogglemenuDrawer: React.FC<Props> = ({
  icon,
  text,
  routerlink,
  isForward,
}) => {
  const dispatch = useAppDispatch();
  return (
    <IonMenuToggle auto-hide="false">
      <IonItem
        button
        routerLink={routerlink}
        routerDirection={isForward ? "forward" : "back"}
        onClick={() =>
          dispatch(setActiveDate(moment(new Date()).format("YYYY-MM-DD")))
        }
      >
        <div className="icon-holder">
          <IconSvg Icon={icon} />
        </div>
        <IonText className="Drawer-Text">{text}</IonText>
      </IonItem>
    </IonMenuToggle>
  );
};

const SideMenu = () => {
  const router = useIonRouter();
  const userData = useAppSelector(selectUser);
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [patientName, setPatientName] = useState<string>("");
  const [loading, setIsLoading] = useState<Boolean>(false);

  const patientListHandler = async (name: string) => {
    setIsLoading(true);
    if (name !== "") {
      let res = await getPatientsSearch({ name });
      setSearchResult(res.resData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (patientName?.trim()?.length === 0 && searchResult?.length > 0) {
      setSearchResult([]);
    }
  }, [searchResult]);

  const dispatch = useAppDispatch();

  const onSearchClear = () => {
    setPatientName("");
    patientListHandler("");
  };

  const goToPatient = (item: any) => {
    setPatientName("");
    patientListHandler("");
    setSearchResult([]);
    if (item?.charge_id) {
      dispatch(setPatientDetail(item));
      router.push("patientDetails/" + item?.charge_id, "forward"); //item?.references?.first_visit_id
    } else {
      toast({
        message: "No visit availaible, create visit first",
      });
    }
  };

  const onSidemenuClose = () => {
    setPatientName("");
  };

  return (
    <>
      <IonMenu
        className="menu-inner"
        contentId="main"
        onIonDidClose={onSidemenuClose}
      >
        {/* Don't remove this menuHolder div */}
        <div className="menuHolder">
          <div className="menu-section">
            <div className="Header">
              <IonHeader className="ion-no-border">
                <IonToolbar className="Toolbar">
                  <IonMenuToggle>
                    <div onClick={() => router.push(`/editProfile`, "forward")}>
                      <h3 className="User-title">
                        {userData.user?.displayname}
                      </h3>
                      {/* <IconSvg Icon={Edit2} /> */}
                    </div>
                  </IonMenuToggle>

                  {/* <IonCol size="2">
                    <div className="edit-icon" onClick={() =>
                      router.push(`/editProfile`, "forward")
                    }>
                      <IconSvg Icon={Edit2} />
                    </div>
                  </IonCol> */}
                </IonToolbar>
                {/* <IonText className="Search-heading">Patient search</IonText>
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="SearchBar"
                  onChange={(e) => {
                    patientListHandler(e.target.value);
                    setPatientName(e.target.value);
                  }}
                /> */}
                <div className="searchBarContainer">
                  <SearchBox
                    placeholder="Patient search"
                    value={patientName}
                    onChange={(txtdata) => {
                      patientListHandler(txtdata);
                      setPatientName(txtdata);
                    }}
                  />
                </div>
              </IonHeader>
            </div>
            <IonContent className="content-holder">
              <div className="list-holder">
                <IonList className="content-list-1" lines="none">
                  {loading === true ? (
                    <div className="loaderSideMenu">
                      <Loader
                        data={{
                          name: "crescent",
                          color: "primary",
                        }}
                      />
                    </div>
                  ) : searchResult?.length > 0 &&
                    loading === false &&
                    patientName?.trim()?.length > 0 ? (
                    searchResult?.map((item, i) => (
                      <IonMenuToggle key={i}>
                        <IonItem
                          class="borderclr ion-focusable"
                          key={i}
                          onClick={(e) => goToPatient(item)}
                        >
                          <IonGrid>
                            <IonRow>
                              <IonCol className="ion-text-nowrap ion-no-padding">
                                <span className="ion-text-uppercase">
                                  <strong>{item?.last_name}</strong>,{" "}
                                  {item?.first_name} {item?.middle_name}
                                </span>
                              </IonCol>
                              <IonCol
                                className="ion-no-padding ion-text-right searchpatientcolumn"
                                size="auto"
                              >
                                <strong>
                                  {item?.procedure_code_primary?.custom_code
                                    ? item?.procedure_code_primary?.custom_code
                                        ?.custom_code
                                    : item?.procedure_code_primary?.code}
                                </strong>
                                <IonText class="ion-color ion-color-medium">
                                  <small>
                                    {item &&
                                      item?.rendering_provider_name &&
                                      item?.rendering_provider_name
                                        .split(" ")
                                        .map(function (item: any) {
                                          return item[0];
                                        })
                                        .join("")
                                        .toUpperCase()}
                                  </small>
                                </IonText>
                              </IonCol>
                            </IonRow>
                            <IonRow className="mt-1">
                              <IonCol className="text-hidden ion-no-padding">
                                <IonText className="ion-color ion-color-medium">
                                  <small>
                                    DOB:{" "}
                                    {moment(item?.dob).format("MM/DD/YYYY")}
                                  </small>
                                </IonText>
                              </IonCol>
                              <IonCol
                                size="auto"
                                className="ion-no-padding ion-text-right md searchpatientcolumn"
                              >
                                <IonText className="ion-color ion-color-medium">
                                  <small>
                                    <IonIcon
                                      className="timelineicon"
                                      icon={timeOutline}
                                      color="#ADAEAF"
                                    ></IonIcon>
                                    {moment(item?.service_date_from).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </small>
                                </IonText>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonItem>
                      </IonMenuToggle>
                    ))
                  ) : (
                    patientName?.trim()?.length > 0 &&
                    searchResult?.length === 0 &&
                    loading === false && (
                      <IonText>
                        No existing patient found with this name
                      </IonText>
                    )
                  )}

                  <PermissionProvider
                    onSearchClear={onSearchClear}
                    children={
                      <TogglemenuDrawer
                        icon={CubeIcon}
                        text="Dashboard"
                        routerlink="/dashBoard"
                      />
                    }
                    permission={"Dashboard Tasks View"}
                  />

                  {/* <PermissionProvider
                    onSearchClear={onSearchClear}
                    children={
                      <TogglemenuDrawer
                        icon={CubeIcon}
                        text="Visit Details"
                        routerlink="/visitdetails"
                      />
                    }
                    permission={"Dashboard Tasks View"}
                  /> */}

                  <PermissionProvider
                    onSearchClear={onSearchClear}
                    children={
                      <TogglemenuDrawer
                        icon={CensusIcon}
                        text="Census"
                        routerlink="/census"
                      />
                    }
                    permission={"Census view"}
                  />
                  {/* 
                  <PermissionProvider
                    onSearchClear={onSearchClear}
                    children={
                      <TogglemenuDrawer
                        icon={MedkitIcon}
                        text="Provider toolkit"
                        routerlink="/"
                      />
                    }
                    permission={"Provider authentication"}
                  /> */}

                  <PermissionProvider
                    onSearchClear={onSearchClear}
                    children={
                      <FeatureProvider
                        children={
                          <TogglemenuDrawer
                            icon={MailIcon}
                            text="Messaging"
                            routerlink="/messagelist"
                          />
                        }
                        feature={"Messaging"}
                      />
                    }
                    permission={"Dashboard Tasks View"}
                  />

                  <PermissionProvider
                    onSearchClear={onSearchClear}
                    children={
                      <TogglemenuDrawer
                        icon={ChartIcon}
                        text="Practice IQ"
                        routerlink="/practice-iq"
                      />
                    }
                    permission={"PracticeIQ - Revenue"}
                  />

                  <PermissionProvider
                    onSearchClear={onSearchClear}
                    children={
                      <TogglemenuDrawer
                        icon={ListIcon}
                        text="Provider Hold"
                        routerlink="/providerhold"
                        isForward
                      />
                    }
                    permission={"Provider authentication"}
                  />

                  <PermissionProvider
                    onSearchClear={onSearchClear}
                    children={
                      <TogglemenuDrawer
                        icon={SettingsIcon}
                        text="Settings"
                        routerlink="/settings"
                        isForward
                      />
                    }
                    permission={"Practice Setting Admin"}
                  />
                  {/* 
                  <PermissionProvider
                    children={
                      <TogglemenuDrawer
                        icon={CensusIcon}
                        text="addNewEncounter"
                        routerlink="/addNewEncounter"
                      />
                    }
                    permission={"Reporting Practice Encounters"}
                  />

                  <TogglemenuDrawer
                    icon={ColorPaletteIcon}
                    text="Ui Components"
                    routerlink="/ui-components"
                  />

                  <PermissionProvider
                    children={
                      <TogglemenuDrawer
                        icon={CensusIcon}
                        text="Patient details"
                        routerlink="/patientDetails"
                      />
                    }
                    permission={"Portal Settings Admin"}
                  /> */}
                </IonList>
                <IonList className="content-list-2" lines="none">
                  <PermissionProvider
                    onSearchClear={onSearchClear}
                    children={
                      <TogglemenuDrawer
                        icon={UserIcon}
                        text="Switch user"
                        routerlink="/selectPractice"
                      />
                    }
                    permission={"Practice Management Admin"}
                  />
                </IonList>
              </div>
            </IonContent>
          </div>
        </div>
      </IonMenu>
    </>
  );
};

export default SideMenu;
