import React from "react";
import { IonCard, IonCardSubtitle, IonIcon, IonText } from "@ionic/react";
import "./progressNotesCard.scss";
import { attachOutline, searchOutline } from "ionicons/icons";

type ProgressNotesCard = {
  SelectedData?: any;
  progressText?: any;
};

const ProgressNotesCard: React.FC<ProgressNotesCard> = ({
  SelectedData,
  progressText,
}) => {
  let progressNoteObj = progressText
    .split("\n")
    .reduce(function (obj: any, str: any, index: number) {
      //To split as key and value by seperating ':'
      let strParts = str.split(":");
      if (strParts[0] && strParts[1]) {
        obj[strParts[0].replace(/\s+/g, "")] = strParts[1].trim();
      }

      //To split 'signed by' by seperating '-'
      let signedByPart = str.split("by");
      if (signedByPart[0] && signedByPart[1]) {
        obj[signedByPart[0].replace(/\s+/g, "").split("-")[1]] = signedByPart[1]
          .trim()
          .split(",")[0];
      }
      return obj;
    }, {});

  return (
    <IonCard className="progressNotesCard">
      <IonCardSubtitle className="progressNotesCardTitle">
        <p>Progress Notes</p>
        <IonIcon className="search-icon" icon={searchOutline} />
      </IonCardSubtitle>
      {progressText ? (
        <div className="progressNotesCardContent">
          <div className="singlenote">
            <div className="singleNoteContentTitle">
              <IonText className="title1">Progress note title</IonText>
              <IonIcon className="attach-icon" icon={attachOutline} />
            </div>
            <p>Created by {progressNoteObj.signed}</p>
            <p>{progressNoteObj.FACILITY}</p>
            <p>{progressNoteObj.SERVICEDATE}</p>
          </div>

          {/* <div className="singlenote">
          <div className="singleNoteContentTitle">
            <IonText className="title2">Progress note title</IonText>
            <IonIcon className="attach-icon" icon={attachOutline} />
          </div>
          <p>Created by Al Czervic</p>
          <p>Capital Medical Nursing Center</p>
          <p>12/12/2020</p>
        </div> */}

          {/* <div className="progressNotesCardTitle">
          <p className="footerNotes">See all</p>
          <p className="footerNotes">Billing notes</p>
        </div> */}
        </div>
      ) : (
        <p>-Progress notes not yet created-</p>
      )}
    </IonCard>
  );
};

export default ProgressNotesCard;
