/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonImg,
  IonGrid,
  IonRow,
  IonRouterLink,
  useIonRouter,
  IonLabel,
  IonButton,
  IonIcon,
  IonText,
} from "@ionic/react";
import "./Login.scss";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectTheme } from "../../store/reducers/layout";
import { InputBoxType } from "../../types/Types";
import { userLogin } from "../../store/thunk/userThunk";
import Button from "../../components/Common/Buttons/Buttons";
import { selectUser } from "../../store/reducers/user";
import { Layout } from "../../components/Layout/Layout";
import CheckBox from "../../components/Common/CheckBox/CheckBox";
import Toast , {newToast} from "../../components/Common/Toaster/Toast";

import {
  AvailableResult,
  Credentials,
  NativeBiometric,
} from "capacitor-native-biometric";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import InputFieldNew from "../../components/Common/InputFieldNew/InputFiledNew";
import { COLLECTOR_URL } from "../../helper/apiHelper/apiHelper";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { getPlatforms } from '@ionic/react';
//import socket from '../../socket';

type ToastedList= {
  id: number;
  description: string,
};

const Login: React.FC = () => {
  const isDarkTheme = useAppSelector(selectTheme);
  const userData = useAppSelector(selectUser);
  const router = useIonRouter();
  const [isBiomatric, setIsBiomatric] = useState<boolean>(false);
  const [isRememberPass, setIsRememberPass] = useState<boolean>(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const [userName, setUsername] = useState<InputBoxType>("");
  const [password, setPassword] = useState<InputBoxType>("");
  const [platform, setPlatform] = useState("");
  const [invalidemail, setInvalidemail] = useState<boolean>(false);
  const [invalidpassword, setInvalidpassword] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [toastedMessage,setToastedMessage] = useState<ToastedList[]>([]);
  const [isSubmited, setIsSubmited] = useState(false);

  const [token] = useLocalStorage("Token");

  const onUserLogin = () => {
    if (password && userName && isBiomatric && isRememberPass) {
      // Save user's credentials
      NativeBiometric.setCredentials({
        username: userName.toString(),
        password: password.toString(),
        server: COLLECTOR_URL,
      }).then(() => {
        setIsSubmited(true);
        dispatch(userLogin({ userName, password,platform }));
        localStorage.setItem("isBiomatric", "true");
      });
    } else {
      setIsSubmited(true);
      dispatch(userLogin({ userName, password,platform })).then((res) => {
        let resMessage = res?.payload?.split("===");
        if (resMessage[0] === "Server Error") {
          let toastMsg =  newToast(resMessage[1],'Error');
          setToastedMessage([...toastedMessage, toastMsg]);
          setError(true);
        } else {
          setError(false);
        }
      });
      localStorage.setItem("isBiomatric", "false");
    }
  };
  useEffect(()=>{
  let getUsetPlatform = getPlatforms();
  if(getUsetPlatform.includes('android')){
    setPlatform('Android');
  }else if(getUsetPlatform.includes('ios')){
    setPlatform('IOS');
  }else if(getUsetPlatform.includes('desktop')){
    setPlatform('Web');
  }
  },[])
  useEffect(() => {
    setBiomatric(isSubmited);

    return () => {
      // setIsSubmited(false);
      setIsBiomatric(false);
    };
  }, [isSubmited, token?.authToken]);

  const setBiomatric = (isSubmited: any) => {
    NativeBiometric.isAvailable().then(
      (result: AvailableResult) => {
        const isAvailable = result.isAvailable;
        setIsBiomatric(isAvailable);

        let alreadyRemember = localStorage.getItem("isBiomatric");

        if (!isSubmited) {
          if (isAvailable && alreadyRemember === "true") {
            // Get user's credentials
            NativeBiometric.getCredentials({
              server: COLLECTOR_URL,
            }).then((credentials: Credentials) => {
              // Authenticate using biometrics before logging the user in
              console.log({
                isSubmited,
                token,
                isAvailable,
                alreadyRemember,
                credentials,
              });

              if (!token) {
                if (
                  credentials?.username !== "" &&
                  credentials?.password !== ""
                ) {
                  NativeBiometric.verifyIdentity({
                    reason: "For easy log in",
                    title: "Log in",
                    subtitle: "",
                    description: "",
                  }).then(
                    () => {
                      // Authentication successful

                      setIsSubmited(true);
                      dispatch(
                        userLogin({
                          userName: credentials.username,
                          password: credentials.password,
                          platform: platform,
                        })
                      );
                    },
                    (error) => {
                      // Failed to authenticate
                    }
                  );
                }
              }
            });
          }
        }
      },
      (error) => {
        // Couldn't check availability
      }
    );
  };

  useEffect(() => {
    if (userData?.isMFAEnabled && isSubmited) {
      router.push("/mfa", "back");
    }
  }, [userData]);

  const onEmailChange = (name: any) => {
    setUsername(name);
    setError(false);

    if (name.length > 2) {
      setInvalidemail(false);
    } else {
      setInvalidemail(true);
    }
  };

  const onPasswordChange = (password: any) => {
    setPassword(password);
    setError(false);

    if (password.length >= 7) {
      setInvalidpassword(false);
    } else {
      setInvalidpassword(true);
    }
  };

  return (
    <Layout
      isLoading={userData?.isloading}
      isError={userData?.isError}
      errorMsg={userData.errorMsg}
    >
      <Toast toastList={toastedMessage} position="top-right" autoDelete={true} autoDeleteTime={6000}/>
      <IonContent className="ion-padding login-page">
        <div className="main-bg">
          <div className="login-form-holder">
            <div className="login-form">
              <IonGrid class="ion-justify-content-center" fixed={true}>
                <IonRow className="ionrow">
                  <IonImg
                    src={
                      isDarkTheme
                        ? "./assets/Logo.svg"
                        : "./assets/Logo-light.svg"
                    }
                    className="image"
                  />
                </IonRow>
                <IonRow className="ionrowtext page-heading">Claimocity</IonRow>
              </IonGrid>

              <IonGrid className="iongrid">
                <IonRow>
                  <IonLabel className="ionlabel"> Username </IonLabel>
                </IonRow>

                <IonRow>
                  <InputFieldNew
                    type="email"
                    value={userName}
                    onKeyUp={(e) => onEmailChange(e.currentTarget?.value)}
                    invalid={invalidemail ? true : false}
                  />
                </IonRow>

                {invalidemail && (
                  <IonRow>
                    <span className="errormsg">
                      Please enter a valid username
                    </span>
                  </IonRow>
                )}

                <IonRow>
                  <IonLabel className="ionlabel"> Password </IonLabel>
                </IonRow>

                <IonRow>
                  <div className="password-holder">
                    <InputFieldNew
                      type={isPasswordVisible ? "text" : "password"}
                      value={password}
                      onKeyUp={(e) => onPasswordChange(e.currentTarget?.value)}
                      invalid={invalidpassword ? true : false}
                    />

                    <IonButton
                      className="passwordHideShow eye-holder"
                      fill="clear"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    >
                      <IonIcon
                        className="show-icon"
                        icon={!isPasswordVisible ? eyeOffOutline : eyeOutline}
                      ></IonIcon>
                    </IonButton>
                  </div>
                </IonRow>

                {invalidpassword && (
                  <IonRow>
                    <span className="errormsg">
                      Please enter a valid password
                    </span>
                  </IonRow>
                )}

                {/* {isSubmited && error && (
                  <IonRow>
                    <span className="errormsg">Invalid credentials</span>
                  </IonRow>
                )} */}


                {isBiomatric && (
                  <IonRow>
                    <div className="input-holder">
                      <CheckBox
                        value="remember"
                        onCheck={(data) => setIsRememberPass(data)}
                      />
                      <p>Remember me</p>
                    </div>
                  </IonRow>
                )}
              </IonGrid>

              <div className="btn-holder">
                <Button
                  // disabled={!userName || !password}
                  onClick={onUserLogin}
                  fullWidth
                >
                  <IonText className="login-btn-text">Log in</IonText>
                </Button>
              </div>

              <div className="ion-router-link">
                <IonRouterLink
                  routerLink="/passwordRecovery"
                  color="medium"
                  className="center"
                >
                  {" "}
                  <IonText className="forgot-password-text">
                    Forgot your password?
                  </IonText>{" "}
                </IonRouterLink>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </Layout>
  );
};

export default Login;
