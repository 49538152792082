import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import {
  getFacilitiesList,
  getInpatientsEncounters,
  getPracticeOverview,
} from "../thunk/DashboardThunk";

const initialState: any = {
  censusFacility: {},
  facilitiesList: [],
  allFacilitiesList: [],
  activeFacility: null,
  isloading: false,
  isError: false,
  errorMsg: "",
  patientsList: [],
  activeDateDashboard: moment(new Date()).format("YYYY-MM-DD"),
  patients: {
    totalPatientCount: 0,
    prevTotalCount: 0,
    comparisonPercentage: 0,
    data: 0,
  },
  encounters: {
    totalencountersCount: 0,
    prevTotalCount: 0,
    comparisonPercentage: 0,
    data: 0,
  },
  revenue: {
    totalrevenueCount: 0,
    prevTotalCount: 0,
    comparisonPercentage: 0,
    data: 0,
  },
  rvu: {
    totalrvuCount: 0,
    prevTotalCount: 0,
    comparisonPercentage: 0,
    data: 0,
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setFacilitiesList: (state: any, action) => {
      state.facilitiesList = action.payload;
    },
    setActiveFacility: (state, action) => {
      state.activeFacility = action.payload;
    },
    setActiveDateDashboard: (state, action) => {
      state.activeDateDashboard = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPracticeOverview.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(getPracticeOverview.fulfilled, (state, action) => {
        return { ...state, ...action?.payload, isloading: false };
      })
      .addCase(getPracticeOverview.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;
        if (action.payload) {
          state.errorMsg = action.payload;
        } else {
          state.errorMsg = action.error.message?.toString();
        }
      })
      .addCase(getFacilitiesList.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(getFacilitiesList.fulfilled, (state, action) => {
        state.isloading = false;
        state.allFacilitiesList = action.payload?.allFacilitiesList
          ? action.payload?.allFacilitiesList
          : [];
      })
      .addCase(getFacilitiesList.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;
        if (action.payload) {
          state.errorMsg = action.payload;
        } else {
          state.errorMsg = action.error.message?.toString();
        }
      })
      .addCase(getInpatientsEncounters.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(getInpatientsEncounters.fulfilled, (state, action) => {
        state.isloading = false;
        state.patientsList = action.payload?.patientsList
          ? action.payload?.patientsList
          : [];
      })
      .addCase(getInpatientsEncounters.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload;
        } else {
          state.errorMsg = action.error.message?.toString();
        }
      });
  },
});

export const { setFacilitiesList, setActiveFacility, setActiveDateDashboard } =
  dashboardSlice.actions;
export const selectDashbord = (state: any) => state.dashboard;
export const dashboardSliceReducer = dashboardSlice.reducer;
