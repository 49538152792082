import React from "react";
import "./PatientCard.scss";

import PropTypes from "prop-types";
import moment from "moment";
import { alertCircle } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

const PatientCards = ({ patient }) => {
  return (
    <div className="patient-card">
      <div className="column-one">
        <div>
          <span className="name-bold">{patient?.patient_lastname}, </span>
          <span className="name-regular">{patient?.patient_firstname}</span>
          <span className="division-line"> | </span>
          <span className="status"> {patient?.status}</span>
        </div>
        <div>
          <div>
            <span className="location">{patient?.place_of_service} |</span>
            <span className="diagnosis-code">
              {patient?.diagnosis_code_primary?.code} -{" "}
            </span>
            <span className="diagnosis-description">
              {patient?.diagnosis_code_primary?.description}
            </span>
          </div>
          <div>
            {(patient?.status === "Bill rejected" ||
              patient?.status === "Collections review" ||
              patient?.status === "Claim provider hold") && (
              <div className="missing-message">
                <span>
                  <IonIcon
                    className="warning-icon"
                    icon={alertCircle}
                  ></IonIcon>
                </span>
                <span> Data is not valid or missing.</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="column-two">
        {patient?.statusGroup === "Completed" && (
          <div className="status-with-bg-green">COMPLETED</div>
        )}
        {patient?.statusGroup === "Incomplete" &&
          patient?.status !== "Bill rejected" &&
          patient?.status !== "Collections review" &&
          patient?.status !== "Claim provider hold" && (
            <div className="status-with-bg-yellow">IN PROGRESS</div>
          )}
        {(patient?.status === "Bill rejected" ||
          patient?.status === "Collections review" ||
          patient?.status === "Claim provider hold") && (
          <div className="status-with-bg-red">PROVIDERHOLD</div>
        )}
        <div className="code">99233 </div>
        {patient?.admission_date && (
          <div className="admission">
            {moment(patient?.admission_date).format("MM-DD-YYYY")}
          </div>
        )}
      </div>
    </div>
  );
};

PatientCards.propTypes = {
  patient: PropTypes.any,
};
export default PatientCards;
