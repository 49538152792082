import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import React, { useState } from "react";
import "./togglebuttonfilledlabel.scss";

const ToggleButtonFilledLabel: React.FC<any> = (props) => {
  const [selected, setSelected] = useState("Male");
  return (
    <div className="segment-wrapper">
      <IonLabel className="segment-label">
        {props?.data?.labelName?.name}
      </IonLabel>
      <IonSegment
        value={selected}
        className="buttnSegment-filled"
        onIonChange={(e: any) => {
          setSelected(e.detail.value);
        }}
      >
        {props.data.arr.map((item: any, i: any) => (
          <IonSegmentButton
            value={item?.name}
            className={
              selected === item?.name
                ? "togglebttn-filled active"
                : "togglebttn-filled"
            }
          >
            <IonLabel>{item?.name}</IonLabel>
          </IonSegmentButton>
        ))}
      </IonSegment>
    </div>
  );
};

export default ToggleButtonFilledLabel;
