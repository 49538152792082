import {
  IonContent,
  IonFooter,
  IonIcon,
  IonImg,
  IonLabel,
  IonModal,
  IonPage,
} from "@ionic/react";
import { close, download } from "ionicons/icons";
import React from "react";
import { downloadFile } from "../../helper/formater";
import useIsMobile from "../../hooks/useIsMobile";
import Button from "../Common/Buttons/Buttons";

import { isPlatform } from "@ionic/react";
import { getPlatforms } from "@ionic/react";

interface ProgressPopupPropsType {
  isOpen: boolean;
  closeModal: () => void;
  image: any;
}

const ProgressPopup: React.FC<ProgressPopupPropsType> = ({
  closeModal,
  isOpen,
  image,
}) => {
  // const isMobile = useIsMobile();

  const isDesktop = isPlatform("desktop");

  // console.log(getPlatforms());

  // console.log({ isDesktop });

  return (
    <IonModal
      className="addendum-modal"
      isOpen={isOpen}
      onDidDismiss={closeModal}
      // breakpoints={[0, 0.3, 0.4, 1]}
      initialBreakpoint={0.8}
      backdropBreakpoint={0.2}
    >
      <IonContent fullscreen className="progress-popup">
        <div className="header-holder">
          <div>
            {isDesktop && (
              <IonIcon
                icon={download}
                className="download-icon"
                mode="md"
                onClick={() => downloadFile(image)}
              ></IonIcon>
            )}
          </div>
          <div className="title-holder">
            <IonLabel className="labelTitle">Progress Note</IonLabel>
          </div>
          <div className="btn-holder">
            <IonIcon
              icon={close}
              className="close-icon"
              onClick={closeModal}
            ></IonIcon>
          </div>
        </div>
        <div className="content-addendum" style={{ marginBottom: "15rem" }}>
          <IonImg src={image} alt="dummy-image" className="addendum-image" />
          {isDesktop && (
            <Button onClick={() => downloadFile(image)}>Download Notes</Button>
          )}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ProgressPopup;
