/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  useIonRouter,
  IonFooter,
  IonIcon,
  IonToast,
} from "@ionic/react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import "./Practice.scss";
import { setPractice, userLogout } from "../../store/reducers/user";
import { PracticeType } from "../../types/Types";
import SearchBox from "../../components/Common/SearchBox/SearchBox";
import { chevronBackOutline } from "ionicons/icons";
interface PracticeProps {
  switchUser?: boolean;
}

const Practice: React.FC<PracticeProps> = ({ switchUser }) => {
  //get praticeList from user reducer
  const practice = useAppSelector((state) => state.user)?.practiceList;
  const selectedProvider = useAppSelector((state) => state.user)?.provider;
  const selectedPractice = useAppSelector((state) => state.user)?.practice;

  const [searchText, setSearchText] = useState(""); //state for user input in search bar
  const [searchResult, setSearchResult] = useState(practice); //state to hold the result which we get after searching

  const [isSelected, setIsSelected] = useState(false);
  const [practiceCount, setPracticeCont] = useState(false);
  const practicesGroupWithAlphabet = practice.reduce((r: any, e: any) => {
    let group = e.organization_name[0].toUpperCase();
    if (!r[group]) r[group] = { group, children: [e] };
    else r[group].children.push(e);
    return r;
  }, {});

  const practicesWithoutSearch = Object.values(practicesGroupWithAlphabet);

  const router = useIonRouter();
  //instance of useAppDispatch
  const dispatch = useAppDispatch();

  //function which sets practice value in user reducer
  const handlePracticeClick = async(practice: PracticeType) => {
    await dispatch(setPractice(practice));
    setSearchText("");
    setIsSelected(true);
    if (switchUser) {
      router.push("/selectProvider", "forward", "push");
    } else {
      if (selectedProvider === null) {
        router.push("/selectProvider", "forward", "push");
      } else {
        router.goBack();
      }
    }
  };

  useEffect(() => {
    if (practicesWithoutSearch.length === 0) {
      setPracticeCont(true);
      setTimeout(() => {
        dispatch(userLogout());
      }, 2000);
    }
    if (switchUser) {
      if (practice?.length === 1) {
        handlePracticeClick(practice[0]);
      }
    } else {
      if (selectedPractice !== null) {
        if (selectedProvider === null) {
          router.push("/selectProvider", "forward", "push");
        }
      }
    }
  }, []);

  const handleCancel = () => {
    router.goBack();
  };

  //this useEffect will run everytime when searchText value will change, hence logic will work accordingly!!
  useEffect(() => {
    //logic to set search result

    setSearchResult(() =>
      practice?.filter((item: any) =>
        item?.organization_name
          ?.toLowerCase()
          ?.includes(searchText.toLowerCase())
      )
    );
  }, [searchText]);

  return (
    <IonPage>
      <IonToast
        isOpen={practiceCount}
        message="No practice mapped for this user"
        position="bottom"
        duration={2000}
      />
      <IonHeader className="practice-header">
        <IonToolbar mode="ios" className="practice-toolbar">
          <IonTitle className="practice-title">Select Practice</IonTitle>
          <IonButtons slot="secondary">
            <IonButton onClick={handleCancel}>
              <IonIcon
                className="practice-back-icon"
                icon={chevronBackOutline}
              ></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="practice-content">
        <div className="practice-searchbar-container">
          <SearchBox
            placeholder="Search practice"
            onChange={(value) => setSearchText(value)}
            value={searchText}
            filtOptions={["All", "Patient", "Doctors"]}
            // isSearchFilter={true}
            // isError={true}
          />
        </div>
        {/*check if there is any search results or not, if yes then map else show no results found!! */}
        <div className="practice-list-container">
          {searchResult?.length > 0 ? (
            <>
              {searchText === "" ? (
                <>
                  {practicesWithoutSearch.map((item: any) => (
                    <div key={item.group}>
                      <div className="group-label">{item.group}</div>
                      {item.children.map((element: any) => (
                        <div key={element?.practice_id}>
                          <div
                            className="practice-list-item"
                            onClick={() => handlePracticeClick(element)}
                          >
                            <div className="practice-text">
                              {element?.organization_name.toUpperCase()}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {searchResult.map((item) => (
                    <div key={item.practice_id}>
                      <div
                        className="practice-list-item"
                        onClick={() => handlePracticeClick(item)}
                      >
                        <div className="practice-text">
                          {item?.organization_name.toUpperCase()}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <div id="noResults">
              <p>No results found</p>
            </div>
          )}
        </div>
      </IonContent>
      <IonFooter className="practice-footer"></IonFooter>
    </IonPage>
  );
};

export default Practice;
