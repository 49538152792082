import React, { useEffect, useState } from "react";
import { IonIcon, IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";
import "./AdditionalInfo.scss";
import { cameraOutline, closeOutline, cloudUploadSharp } from "ionicons/icons";
import { usePhotoGallery } from "../../../hooks/usePhotoGallery";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { useAppSelector } from "../../../store/hooks";
import { uploadFiles } from "../../../helper/backendHelper";
import axios from "axios";
import {
  COLLECTOR_URL,
  setupInterceptorsTo,
} from "../../../helper/apiHelper/apiHelper";
import { usePhotoCapture } from "../../../hooks/usePhotoCapture";
import { blobToFile } from "../../../helper/formater";
const collectorApi = setupInterceptorsTo(
  axios.create({
    baseURL: COLLECTOR_URL,
  })
);
/* const AdditionalInfoCard: React.FC <any> = (props:any) => {

  

  const [files, setFiles] = useState([
    {
      fileType: "JPG",
      fileName: "smith_documentation_visit_16/01/2022",
      fileSize: "25kb",
      status: "uploaded",
    },
    {
      fileType: "PDF",
      fileName: "andrew_smith_visit_16/01/2022",
      fileSize: "25kb",
      status: "uploading",
    },
    {
      fileType: "JPG",
      fileName: "andrew_smith_visit_05/02/2022",
      fileSize: "25kb",
      status: "uploading",
    },
  ]); */
//import { closeOutline, cloudUploadSharp } from "ionicons/icons";

const AdditionalInfoCard: React.FC<any> = (props) => {
  const { photos, takePhoto } = usePhotoCapture("encounter");
  const [uploads, setUploads] = useState<any[]>([]);
  const darkThemeBoolean = useAppSelector((state) => state.layout.darkTheme);
  const [isPictureShow, setIsPictureShow] = useState<boolean>(false);
  const selectedProvider = useAppSelector((state) => state.user)?.provider;

  const [orcResponce, setOrcResponce] = useState<string>("");

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  // console.log({ photos });

  const randomString = (length: any) => {
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  };

  useEffect(() => {
    if (photos) {
      upload_direct(photos);
    }
  }, [photos]);

  const upload_direct = (photos: any) => {
    let formData = new FormData();

    let file = blobToFile(new Blob([photos?.webviewPath]), photos?.filepath);

    // console.log({ file });

    formData.append("file", file);
    formData.append("fileName", photos?.filepath);

    collectorApi.post("/dailycensus/image", formData).then((res: any) => {
      if (res?.data) {
        setUploads([...uploads, photos]);
        console.log(res?.data);

        setOrcResponce(JSON.stringify(res?.data));
      }
    });

    //const formHeaders = formData.getHeaders();

    // let res = await uploadFiles(file,formData);

    // let res = await uploadFiles(file);
  };

  const uploadImage = (e: any) => {
    //note: to get the progress bar while uploading, change status to uploading, once uploaded change status to uploaded
    // const doc = e.target.files;

    // props.setFiles([
    //   ...props?.files,
    //   {
    //     fileType: doc[0]?.name
    //       ?.split(".")
    //       [doc[0]?.name?.split(".")?.length - 1]?.toUpperCase(),
    //     fileName: doc[0]?.name,
    //     fileSize: formatBytes(doc[0]?.size),
    //     status: "uploading",
    //     originalFileInfo: doc[0],
    //   },
    // ]);
    // setTimeout(() => {
    //   props.setFiles([
    //     ...props?.files,
    //     {
    //       fileType: doc[0]?.name
    //         ?.split(".")
    //         [doc[0]?.name?.split(".")?.length - 1]?.toUpperCase(),
    //       fileName: doc[0]?.name,
    //       fileSize: formatBytes(doc[0]?.size),
    //       status: "uploaded",
    //       originalFileInfo: doc[0],
    //     },
    //   ]);
    // }, 1000);
    const file = e.target.files[0];

    const reader = new FileReader();
    let fileExt = file?.name?.split(".")?.pop();
    let fileName =
      selectedProvider?._id +
      "_" +
      new Date().getTime() +
      randomString(6) +
      "." +
      fileExt;

    let fileData = null;

    reader.readAsDataURL(file);
    reader.onloadend = async (ev: any) => {
      // note using fat arrow function here if we intend to point at current Class context.
      fileData = file;
      let cameraImage = {
        key: fileName, //file.name,
        size: file.size,
        file_type: fileExt,
        checksum: null, //checksumval,
        // __localUrl: this.domSanitizer.bypassSecurityTrustStyle(imageDataURL),// blobURL, //this.file.dataDirectory + newFileName,
        // img:  this.domSanitizer.bypassSecurityTrustStyle(`url(${ev.target.result})`) //'data:image/bmp;base64,'+ this.base64.encode(blob) //blob.toString()
        file: file,
        img: reader.result,
      };

      let formData = new FormData();

      // console.log({ file });

      formData.append("file", file);
      formData.append("fileName", fileName);
      collectorApi.post("/dailycensus/image", formData).then((res: any) => {
        if (res?.data) {
          setOrcResponce(JSON.stringify(res?.data));
        }
      });
      let tempFiles = [];
      tempFiles.push(cameraImage);
      props.setFiles([...props?.files, ...tempFiles]);
      // upload_direct(e.target.files, fileName);
      // addpatientProvider.upload_direct(file, fileName).subscribe(data => {
      //
      //   this.images.push(cameraImage);
      // }, error => {

      // });
    };
  };

  const removeFileHandler = (i: number) => {
    let newdata = props?.files.filter((item: any, index: any) => index !== i);
    props.setFiles(newdata);
    setOrcResponce("");
  };

  const capturePhoto = () => {
    defineCustomElements(window);
    takePhoto();
  };
  const deletePicture = (fp: any) => {
    setUploads(uploads.filter((photoPath) => photoPath.filepath !== fp));
    setOrcResponce("");
  };

  return (
    <>
      <div className="additionalInfoCardContent">
        <p>Add a photo or a document to new encounter</p>
        <div className="additionalCardInfoSubContent">
          {props?.files?.length === 0 && (
            <div className="fileUpload btn btn-primary">
              <IonIcon icon={cloudUploadSharp} />
              <span>Upload file</span>
              <input
                type="file"
                className="upload"
                onChange={(e) => {
                  uploadImage(e);
                }}
              />
            </div>
          )}

          <div className="uploadingFiles">
            {props?.files?.map(
              (item: any, i: any) => (
                // item?.status === "uploaded" ? (
                <div className={`singleUploadingFileuploaded`} key={i}>
                  <div className="fileTypeInfo">
                    <p>{item?.file_type}</p>
                  </div>

                  <div className="fileInfo">
                    <div className="fileName">{item?.key}</div>

                    <div className="fileInfoFleSize">{item?.size}</div>
                  </div>

                  <div
                    onClick={() => removeFileHandler(i)}
                    className="fileCancelButton"
                  >
                    <IonIcon icon={closeOutline} />
                  </div>
                </div>
              )
              // ) : (
              //   <div className={`singleUploadingFileuploading`} key={i}>
              //     <div className="fileTypeInfo">
              //       <p>{item?.fileType}</p>
              //     </div>

              //     <div className="fileInfo">
              //       <div className="fileOtherInfo">
              //         <div className={`fileName`}>{item?.fileName}</div>

              //         <div className="fileInfoFleSize">{item?.fileSize}</div>
              //       </div>
              //       <IonProgressBar
              //         className="add-newencounter-additional-info-progressbar"
              //         value={0.5}
              //       />
              //     </div>
              //   </div>
              // )
            )}
          </div>
        </div>

        <IonGrid>
          <IonRow>
            <IonCol size="10">
              {props?.files?.length > 0 && (
                <div className="bottomBtn">
                  <div className="fileUpload btn btn-primary">
                    <IonIcon icon={cloudUploadSharp} />
                    <span>Add More</span>
                    <input
                      type="file"
                      className="upload"
                      onChange={(e) => {
                        uploadImage(e);
                      }}
                    />
                  </div>
                </div>
              )}
            </IonCol>
            <IonCol size="2" className="photoCamera">
              <IonIcon
                icon={cameraOutline}
                onClick={capturePhoto}
                size="medium"
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <IonGrid>
        <IonRow>
          {uploads?.map((photo: any, index: any) => (
            <>
              <IonCol size="3" key={index}>
                <IonImg src={photo?.webviewPath} />
              </IonCol>
              <IonCol size="1">
                <IonIcon
                  icon={closeOutline}
                  onClick={(e) => deletePicture(photo?.filepath)}
                />
              </IonCol>
            </>
          ))}
        </IonRow>
      </IonGrid>
      {/* {orcResponce !== "" && (
        <div className="additionalInfoCardContent">
          <p>Responce from OCR</p>
          <p>{orcResponce}</p>
        </div>
      )} */}
    </>
  );
};

export default AdditionalInfoCard;
