import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { getCptList, getProcedureList } from "../../helper/backendHelper";
import { visitDetail } from "../../services/CensusProvider";
import { VisitsByServicedate } from "../../types/VisitsByServicedate";
import "./Census.scss";
import CptModalItemCard from "./CptModalItemCard";

//type dropdown_data = typeof dropdown_data[number];

interface CptModalPropsType {
  openModal: boolean;
  handleClick: (item: any) => void;
  closeModal: () => void;
  visit?: VisitsByServicedate;
}

const CptModal: React.FC<CptModalPropsType> = ({
  openModal,
  handleClick,
  closeModal,
  visit,
}) => {
  const [cptListResult, setCptListResult] = useState([]);
  const settingsCptList = async () => {
    try {
      let res = await getCptList("", visit?.place_of_service);
      if (res.status === "success" && res.results.length > 0) {
        setCptListResult(res.results);
      } else {
        visitDetail(visit);
      }
    } catch (error) {}
  };

  const collectorCptList = async () => {
    try {
      let res = await getProcedureList(visit?._id);
      if (res.status === "success" && res.results.length > 0) {
        setCptListResult(res.results);
      } else {
        visitDetail(visit);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (
      visit?.status !== "Last" &&
      visit?.status !== "Checked In" &&
      visit?.status !== "Scheduled"
    ) {
      visitDetail(visit);
    }
    if (openModal === true && visit?.place_of_service) {
      settingsCptList();
    }

    // if (openModal === true && !visit?.procedureCodes) {
    //   settingsCptList();
    // } else {
    //   collectorCptList();
    // }
    return () => {
      setCptListResult([]);
    };
  }, [openModal]);

  // console.log({ cptListResult, visit });

  return (
    <>
      <IonModal
        className="add_diagnosis-newdesign"
        isOpen={openModal}
        onDidDismiss={closeModal}
        initialBreakpoint={1}
      >
        <IonHeader className="add_diagnosis_header-newdesign">
          <IonToolbar className="add_diagnosis_toolbar-newdesign">
            <IonButtons slot="start">
              <IonButton onClick={closeModal}>
                <IonIcon icon={chevronBackOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle className="add_diagnosis_title-newdesign">
              Change CPT
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="add-diagnosis-content">
          {cptListResult.map((item: any, index) => (
            <IonRow
              onClick={() => {
                handleClick(item);
              }}
              key={index}
            >
              <CptModalItemCard item={item} />
              {/* {`${item?.code} - ${item?.description}`} */}
            </IonRow>
          ))}
          {/* {diagnosisData?.length > 0 &&
              diagnosisData.map(
                (item: DiagnosisSearchInterface, index: number) => {
                  let isSelected = diagnosisSelected?.some(
                    (code: any) => item.code === code.code
                  );
                  return (
                    <IonRow key={`${item.code}+${index}`}>
                      <DiagnosisCodeSearchCard
                        favoriteHandler={favoriteHandler}
                        cardSelectHandler={cardSelectHandler}
                        item={{ ...item, isSelected }}
                        diagnosisSelected={diagnosisSelected}
                        // favoriteButton={favoriteButton}
                      />
                    </IonRow>
                  );
                }
              )} */}
          {/* </IonGrid> */}
        </IonContent>
      </IonModal>
    </>
    // <IonModal isOpen={props.openModal}>
    //   <IonContent>
    //     <IonList>
    //       {cptListResult.map((item: any, index) => (
    //         <IonItem
    //           onClick={() => {
    //             props.handleClick(item);
    //           }}
    //           key={index}
    //         >
    //           {`${item?.code} - ${item?.description}`}
    //         </IonItem>
    //       ))}
    //     </IonList>
    //   </IonContent>
    // </IonModal>
  );
};

export default CptModal;
