import axios from "axios";
import { COLLECTOR_URL, setupInterceptorsTo } from "./apiHelper";
// import createCancelTokenHandler from "./axiosUtils";

const collectorApi = setupInterceptorsTo(
  axios.create({
    baseURL: COLLECTOR_URL,
    headers: {
      "Content-Type": "application/json",
      // Origin: "claimocity",
    },
  })
);

const collectorSkipInterceptorApi = setupInterceptorsTo(
  axios.create({
    baseURL: COLLECTOR_URL,
  })
);
const token: any = localStorage.getItem("Token");
const authToken = JSON.parse(token)?.authToken;
export async function getCollector(url: any, config = {}) {
  return await collectorApi
    .get(url, {
      ...config,
    })
    .then((response: { data: any }) => response.data);
}

export async function postCollector(url: any, data: any, config = {}) {
  return collectorApi
    .post(url, { ...data }, { ...config })
    .then((response: { data: any }) => response.data)
    .catch((e: any) => {
      return e;
    });
}

export async function postCollectorBulk(url: any, data: any, config = {}) {
  return collectorApi
    .post(url, data, { ...config })
    .then((response: { data: any }) => response.data)
    .catch((e: any) => {
      return e;
    });
}

export async function postSkipInterceptorCollector(
  url: any,
  data: any,
  config: any = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }
) {
  return collectorSkipInterceptorApi
    .post(url, { ...data }, { ...config })
    .then((response: { data: any }) => response.data);
}

export async function putCollector(url: any, data: any, config = {}) {
  return collectorApi
    .put(url, { ...data }, { ...config })
    .then((response: { data: any }) => response.data);
}

export async function delCollector(url: any, config = {}) {
  return await collectorApi
    .delete(url, { ...config })
    .then((response: { data: any }) => response.data);
}
