import React, { useEffect } from "react";
import { Layout } from "../../components/Layout/Layout";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  IonContent,
  IonGrid,
  IonText,
  IonRow,
  IonCol,
  useIonRouter,
  IonImg,
} from "@ionic/react";
import Button from "../../components/Common/Buttons/Buttons";
import { selectTheme } from "../../store/reducers/layout";
import { setUserName } from "../../store/reducers/forgotPassword";

const ResetPasswordSuccessful: React.FC = () => {
  const thisState = useAppSelector((state) => state.forgotPassword);
  const router = useIonRouter();
  const isDarkTheme = useAppSelector(selectTheme);
  const dispatch = useAppDispatch();
  const redirectToLogin = () => {
    router.push("/login", "forward", "push");
  };
  useEffect(() => {
    dispatch(setUserName(""))
  },[])
  return (
    <Layout
      isLoading={thisState.isloading}
      isError={thisState.isError}
      errorMsg={thisState.errorMsg}
    >
      <IonContent className="forgot-password-page ion-padding">
        <div className="main-bg">
          <div className="centered-div-holder">
            <div className="reset-password-container">
              <IonGrid className="ion-grid1" fixed>
                <IonRow className="heading-row-updatepswrd">
                  <IonText className="heading-updatepswrd">
                    {" "}
                    Password reset successful
                  </IonText>
                </IonRow>

                <IonRow className="ion-row">
                  <IonText color="medium" className="ion-text page-sub-txt">
                    You have successfully reset your password. Please use your
                    new password to logging in.
                  </IonText>
                </IonRow>

                <IonRow className="successful-image">
                  <IonImg
                    src={
                      isDarkTheme
                        ? "./assets/celebration.svg"
                        : "./assets/celebration-dark.png"
                    }
                  />
                </IonRow>

                <IonRow className="btn-verification-row">
                  <IonCol className="btn-verification">
                    <Button onClick={() => redirectToLogin()} fullWidth>
                      <span className="lbl-continue">Login</span>
                    </Button>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </div>
      </IonContent>
    </Layout>
  );
};

export default ResetPasswordSuccessful;
