import React, { useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardSubtitle,
  IonCol,
  IonDatetime,
  IonFabList,
  IonGrid,
  IonIcon,
  IonItemSliding,
  IonRow,
  IonText,
  useIonRouter,
  useIonToast,
} from "@ionic/react";

import "./otherInfo.scss";
import { add, close, documentOutline, medkitOutline } from "ionicons/icons";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import moment from "moment";
import {
  callConfirmVisit,
  changeDateOfService,
  channgeStatus,
  dischargeVisit,
  getVisitCharts,
  getVisitDiagnosis,
  getVisitProcedures,
  putCptChange,
  recallSubmission,
  skipVisit,
  submitBillVisit,
  undoSkipVisit,
  voidBill,
} from "../../../helper/backendHelper";
import multisort from "multisort";
import {
  hapticFeedBack,
  processVisits,
  selectedDate,
  statusIcon,
  updateVisit,
  visitClone,
  visitHighlight,
  visitProcedureMenu,
  visitStatus,
} from "../../../services/CensusProvider";
import { socket1 } from "../../../socket";
import RecallBtnFirstPopup from "../../../components/Census/RecallBtnFirstPopup";
import RecallBtnSecondPopup from "../../../components/Census/RecallBtnSecondPopup";
import CptModal from "../../../components/Census/CptModal";
import { setPatientDetail } from "../../../store/reducers/newEncounter";
import { selectUser } from "../../../store/reducers/user";
import { getPatient } from "../../../store/thunk/patientThunk";
import { useParams } from "react-router";
import FeatureProvider from "../../../components/PermissionProvider/FeatureProvider";
import { getVisitsCensus } from "../../../store/thunk/censusThunk";
import { setRedirectedFrom } from "../../../store/reducers/message";
import Toast, { newToast } from "../../../components/Common/Toaster/Toast";
type OtherInformationProp = {
  visitId?: string;
  data?: any;
  SelectedData?: any;
};

type ToastedList = {
  id: number;
  description: string;
};

const OtherInformation: React.FC<OtherInformationProp> = ({
  visitId,
  data,
  SelectedData,
}) => {
  const dispatch = useAppDispatch();
  let quary = useParams<{ id?: string }>();

  // const [present, dismiss] = useIonToast();
  const [toastedMessage, setToastedMessage] = useState<ToastedList[]>([]);
  const [visit, setVisit] = useState(data);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openCPTModal, setOpenCPTModal] = useState<boolean>(false);
  const [isLastVisit, setIsLastVisit] = useState<boolean>(false);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [showDate, setShowDate] = useState<boolean>(false);
  const [cloneVisitDate, setCloneVisitDate] = useState<any>();
  const [dosDate, setDosDate] = useState<any>();
  const [recallFirstPopupISOpen, setRecallFirstPopupISOpen] =
    useState<boolean>(false);
  const [recallSecondPopupISOpen, setRecallSecondPopupISOpen] =
    useState<boolean>(false);
  const serviceDate = useAppSelector((state) => state.census)?.serviceDate;
  const thisDate = useAppSelector((state) => state.census.activeDate);
  const visitStatuses = useAppSelector(
    (state) => state.patientDetails
  )?.visitStatuses;
  const userData = useAppSelector(selectUser);
  const isNoteRequired =
    (userData?.provider?.note_mode || userData?.practice?.note_mode) ===
    "required";
  const fabBtnRef = useRef(null);
  const fabListRef = useRef(null);
  const currentFacility = useAppSelector(
    (state) => state.census.censusFacility
  );

  useEffect(() => {
    setVisit(data);
  }, [data]);

  const isAction = (visitstatus: any, action: any) => {
    if (
      action == "Bill" ||
      action == "Bill SALT"
      //&&
      //moment(serviceDate) > moment()
    ) {
      return false;
    } else {
      let config: any = visitStatuses;
      let available_Actions = [];
      if (config[visitstatus] && config[visitstatus].actions) {
        available_Actions = config[visitstatus].actions;
      }
      if (available_Actions.indexOf(action) > -1) {
        return true;
      } else {
        return false;
      }
    }
  };
  const router = useIonRouter();

  const visitUpdateEvent = (visit_item: any) => {
    // console.log({ visit_item });

    let visit = JSON.parse(JSON.stringify(visit_item));
    let _visit = Object.assign({}, visit);
    delete _visit.series;
    let data = {
      visit: _visit,
      room: _visit.practice_id + "_" + _visit.service_date_from,
    };

    let tmp_data = JSON.parse(JSON.stringify(data));
    // this.censusProvider.visitsObject[visit._id] = visit;
    // this.socketVisitService.emit("visit-update", tmp_data);
    socket1.emit("visit-update", tmp_data);
    if (visit._id) {
      dispatch(getPatient(visit._id));
      dispatch(
        getVisitsCensus({
          servicedate: moment(thisDate).format("YYYY-MM-DD"),
          // thisProvider: userData.provider,
          facilityId: currentFacility?._id,
        })
      );
    }

    // router.push(`/patientDetails/${visit._id}`, "forward");
  };

  const refreshCodes = async (visit_item: any) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    // load descriptions for all the diagnosis and procedure codes in the visit
    if (!visit.diagnosisCodes) {
      // visit.expanded &&
      visit.diagnosisCodes = [];
      let data = await getVisitDiagnosis(visit?._id);
      if (data.status == "success" && data.results.length > 0) {
        visit.diagnosisCodes = data.results;
        let toastMsg = newToast("Updated diagnosis code", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        let toastMsg = newToast("Failed to update diagnosis codes", "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    }
    if (!visit.procedureCodes) {
      // visit.expanded &&
      visit.procedureCodes = [];
      let data = await getVisitProcedures(visit?._id);
      if (data.status == "success" && data.results.length > 0) {
        visit.procedureCodes = data.results;
        let toastMsg = newToast("updated procedure codes", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        let toastMsg = newToast("Failed to update procedure codes", "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    }
  };

  const visitNoteSliding = async (
    visit_item?: any,
    slidingItem?: any,
    event?: Event
  ) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    // if (slidingItem) {
    //   slidingItem.close();
    // }
    if (visit.status == "Last") {
      let callConfirmVisitData = {
        status: "Not seen",
        service_date: moment(serviceDate).format("YYYY-MM-DD"),
      };
      let data = await callConfirmVisit(callConfirmVisitData, visit?._id);
      if (data.status == "success") {
        visit.clone_id = visit._id;
        visit._id = data.result._id;
        visit.status = data.result.status;
        visit.statuses = data.result.statuses;
        visit.statuses_index = data.result.statuses_index;
        visit.activities = data.result.activities;
        visit.service_date_from = data.result.service_date_from;
        visit.legal_entity_id = data.result.legal_entity_id;
        if (visit.series) {
          visit.series.push(visit);
          visit.series = multisort(visit.series, ["!service_date_from"]); // Sorting visit history data
          visit.series.forEach((el: any) => {
            let year_arr: any = [];
            let year = el.service_date_from;
            year = year.split("-");
            year = year[0];
            if (year != moment(new Date()).format("YYYY")) {
              if (year_arr.indexOf(year) > -1) {
                el.year = "";
              } else {
                el.year = year;
                year_arr.push(year);
              }
            } else {
              el.year = "";
            }
          });
        }
        statusIcon(visit);
        // this.groupVisits(this.visitEventsProvider.visits, visit);
        // hapticFeedBack();
        visitHighlight(visit);
        visitUpdateEvent(visit);
        refreshCodes(visit);
        // this.navparams.setParams({
        //   visit: visit,
        //   codeTemplateList: this.codeTemplateList
        // });
        // this.navCtrl.navigateForward('visit-note');
        let toastMsg = newToast("updated the visit", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        visitHighlight(visit);
        let toastMsg = newToast("Failed to update visit ", "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    } else if (visit.chart_id && !visit.chart) {
      refreshCodes(visit);
      let result = await getVisitCharts(visit?._id);
      if (result.status == "success") {
        visit.chart = result.data;
        let diagPlan = visit.chart.data.find((x: any) =>
          x.sections.some((section: any) => section.type == "diagnosisPlan")
        );
        if (diagPlan && diagPlan.sections) {
          let section = diagPlan.sections.find(
            (x: any) => x.type == "diagnosisPlan"
          );
          if (section && section.options && section.options.length > 0)
            visit.diagnosisCodes = section.options;
        }
        // this.navparams.setParams({
        //   visit: visit,
        //   codeTemplateList: this.codeTemplateList
        // });
        // this.navCtrl.navigateForward('visit-note');
        let toastMsg = newToast("updated diagnosis codes", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    } else {
      refreshCodes(visit);
      // this.navparams.setParams({
      //   visit: visit,
      //   codeTemplateList: this.codeTemplateList
      // });
      // this.navCtrl.navigateForward('visit-note');
    }
  };

  const postBillVisitChanges = (data: any, visit_item: any) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    visit.status = data.status;
    visit.statuses = data.statuses;
    visit.statuses_index = data.statuses.reduce((obj: any, item: any) => {
      obj[item.key] = item.value;
      return obj;
    }, {});
    visit.statuses_index.submit_bill = true;
    visit.activities = data.activities;
    visit.rendering_provider_id = data.rendering_provider_id;
    visit.rendering_provider_name = data.rendering_provider_name;
    // updaing dos for visit on any update
    visit.service_date_from = data.service_date_from;
    visit.recallExpires = moment().add(60, "s");
    visit.estimate_amount = data.estimate_amount;
    visit.insurance_estimate_amount = data.insurance_estimate_amount;
    visit.patient_estimate_amount = data.patient_estimate_amount;
    visit.insurance_estimate_date = data.insurance_estimate_date;
    visit.patient_estimate_date = data.patient_estimate_date;
    visit.insurance = data.insurance ? data.insurance : [];
    visit.rvu = data.rvu;
    statusIcon(visit);
    // for (let currentVisit of this.visits) {
    //   if (currentVisit._id == visit._id) {
    //     currentVisit.billed = true;
    //     break;
    //   }
    // }
    // hapticFeedBack();
    visitHighlight(visit);
    visitUpdateEvent(visit);
    // this.groupVisits(this.visits, visit);
    // this.getVisitSeries(visit);
    let toastMsg = newToast("Submitted successfully", "Success");
    setToastedMessage([...toastedMessage, toastMsg]);
  };

  const billVisit = (visit_item: any, slidingItem: any) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    if (visit?.status != "Superbill") {
      if (visit?.status == "Bill rejected") {
        channgeStatus(visit?._id).then((response) => {
          if (response?.status == 400) {
            // Set Error Message
            let toastMsg = newToast("server error", "Info");
            setToastedMessage([...toastedMessage, toastMsg]);
          } else {
            if (response?.status == "success") {
              // if (response.taskDocument) {
              //   visit.taskstatus_id = response.taskDocument.taskstatus_id;
              // }
              postBillVisitChanges(response?.result, visit);
              // present("Success...", 5000);
            } else {
              visitHighlight(visit);
              let toastMsg = newToast("failed to bill the visit", "Danger");
              setToastedMessage([...toastedMessage, toastMsg]);
            }
          }
        });
      } else {
        submitBillVisit(visit?._id).then((response) => {
          if (response?.status == 400) {
            // Set Error Message
            let toastMsg = newToast("Server error", "Danger");
            setToastedMessage([...toastedMessage, toastMsg]);
          } else {
            if (response?.status == "success") {
              postBillVisitChanges(response?.result, visit);
              let toastMsg = newToast("", "Success");
              setToastedMessage([...toastedMessage, toastMsg]);
            } else {
              visitHighlight(visit);
              let toastMsg = newToast("Failed to bill the visit", "Danger");
              setToastedMessage([...toastedMessage, toastMsg]);
            }
          }
        });
      }
    } else {
      // hapticFeedBack();
      visitHighlight(visit);
      visit.statuses_index.submit_bill = true;
      statusIcon(visit);
      // this.billed += 1;
      // visit.recallExpires = moment().add(60, "s");
      // this.groupCountUpdate(visit);
      let toastMsg = newToast("Already submitted", "info");
      setToastedMessage([...toastedMessage, toastMsg]);
    }
  };

  const billSalt = (
    visit_item: any,
    slidingItem: any,
    last_visit?: boolean
  ) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    // if (slidingItem) {
    //   event.stopPropagation();
    //   slidingItem.close();
    // }

    if (visit.status == "Last") {
      // Disable SALT for initial diagnosis visit E&M codes -- this should be ultimately DB config driven
      let exclude = ["99218", "99219", "99220", "99221", "99222", "99223"]; //for testing purpose
      //       let exclude = initialProcedures;
      if (exclude.indexOf(visit.procedure_code_primary.code) > -1) {
        return visitProcedureMenu(visit, null, undefined, "");
      }

      visitClone(
        visit._id,
        "Checked In",
        moment(selectedDate).format("YYYY-MM-DD")
      ).then((data: any) => {
        if (data.status == "success") {
          visit.clone_id = visit._id;
          visit._id = data.result._id;
          visit.status = data.result.status;
          visit.statuses = data.result.statuses;
          visit.statuses_index = data.result.statuses_index;
          visit.activities = data.result.activities;
          // this.visitEventsProvider.seen += 1;
          // this.visitEventsProvider.scheduled += 1;
          // updaing dos for visit on any update
          visit.service_date_from = data.result.service_date_from;
          if (visit.series) {
            visit.series.push(visit);
            // visit.series = multisort(visit.series, ['!service_date_from']); // Sorting visit history data
            visit.series.forEach((el: any) => {
              let year_arr: any[] = [];
              let year = el.service_date_from;
              year = year.split("-");
              year = year[0];
              if (year != moment(new Date()).format("YYYY")) {
                if (year_arr.indexOf(year) > -1) {
                  el.year = "";
                } else {
                  el.year = year;
                  year_arr.push(year);
                }
              } else {
                el.year = "";
              }
            });
          }
          //   this.visitEventsProvider.statusIcon(visit);
          submitBillVisit(visit?._id).then((data) => {
            if (data.status == "success") {
              //   this.hapticFeedBack();
              //   this.visitHighlight(visit);
              visit.status = "Superbill";
              visit.statuses_index.submit_bill = true;

              visit.status = data.result.status;
              visit.statuses = data.result.statuses;
              visit.statuses_index = data.result.statuses.reduce(
                (obj: any, item: any) => {
                  obj[item.key] = item.value;
                  return obj;
                },
                {}
              );
              visit.activities = data.result.activities;

              visit.rendering_provider_id = data.result.rendering_provider_id;
              visit.rendering_provider_name =
                data.result.rendering_provider_name;
              // let provider = (visit.rendering_provider_name ? visit.rendering_provider_name : '');
              // let initials = provider.split(' ').map(function (item) { return item[0] }).join('');
              // visit.rendering_provider_initials = initials;
              // this.visitEventsProvider.billed += 1; // comment need to recheck
              //   this.groupCountUpdate(visit);

              visit.recallExpires = moment().add(60, "s");

              visit.estimate_amount = data.result.estimate_amount;
              visit.insurance_estimate_amount =
                data.result.insurance_estimate_amount;
              visit.patient_estimate_amount =
                data.result.patient_estimate_amount;
              visit.insurance_estimate_date =
                data.result.insurance_estimate_date;
              visit.patient_estimate_date = data.result.patient_estimate_date;
              visit.insurance = data.result.insurance
                ? data.result.insurance
                : [];

              visit.rvu = data.result.rvu;
              updateVisit(visit);
              processVisits();
              //   this.visitEventsProvider.statusIcon(visit);
              //   this.groupCountUpdate(visit);
              // this.visitEventsProvider.groupVisits(this.visits, visit);
              //   this.getVisitSeries(visit); // why are we loading the series again?
              visitUpdateEvent(visit);

              // emit visit-update event
              //   if (!slidingItem && this.navCtrl.back.name == 'VisitDetailsPage') {
              //     this.navCtrl.pop();
              //   }
            } else {
              visitHighlight(visit);

              let toastMsg = newToast("Failed to update visit", "Danger");
              setToastedMessage([...toastedMessage, toastMsg]);
            }
          });
        } else {
          visitHighlight(visit);

          let toastMsg = newToast("failed to update status", "Danger");
          setToastedMessage([...toastedMessage, toastMsg]);
        }
      });
    } else {
      visitHighlight(visit);
      let toastMsg = newToast("failed to update status", "Danger");
      setToastedMessage([...toastedMessage, toastMsg]);
    }
  };

  const confirmVisit = (
    visit_item: any,
    slidingItem: any,
    typeCall = "",
    groupindex: any
  ) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    // handleMoreBtnModal(false);

    // need to implement
    //visit.status='Admit';
    /* if (slidingItem && typeCall == '') {
      e.stopPropagation();
      slidingItem.close();
    } */
    if (visit.status == "Last") {
      visitClone(
        visit._id,
        "Checked In",
        moment(selectedDate).format("YYYY-MM-DD")
      ).then((data: any) => {
        if (data.status == "success") {
          visit.clone_id = visit._id;
          visit._id = data.result._id;
          visit.rendering_provider_id = data.result.rendering_provider_id;
          visit.rendering_provider_name = data.result.rendering_provider_name;
          visit.status = data.result.status;
          visit.statuses = data.result.statuses;
          visit.statuses_index = data.result.statuses_index;
          visit.activities = data.result.activities;
          //statusIcon(visit);
          // this.seen += 1;
          // this.scheduled += 1;
          // updaing dos for visit on any update
          visit.service_date_from = data.result.service_date_from;
          visit.legal_entity_id = data.result.legal_entity_id;
          if (visit.series) {
            visit.series.push(visit);
            visit.series = multisort(visit.series, ["!service_date_from"]); // Sorting visit history data
          }
          // if (this.groupedVisits[groupindex]) {
          //   this.groupedVisits[groupindex].scheduled += 1;
          // }
          //groupVisits(visit, visit);
          //hapticFeedBack();
          //visitHighlight(visit);
          visitUpdateEvent(visit);
          // this.progressMethod();
          let toastMsg = newToast(".", "Success");
          setToastedMessage([...toastedMessage, toastMsg]);
        } else {
          //visitHighlight(visit);
          //showToast("Oops... Failed to update status. Please try again...");
          let toastMsg = newToast("failed to update status", "Danger");
          setToastedMessage([...toastedMessage, toastMsg]);
        }
      });
    } else if (
      visit.status == "Scheduled" ||
      visit.status == "Not seen" ||
      visit.status == "Admit"
    ) {
      visitStatus(visit._id, "Checked In").then((data: any) => {
        if (data.status == "success" && data.result._id == visit._id) {
          // if (visit.status == 'Not seen') {
          //   this.seen += 1;
          //   this.scheduled += 1;
          // }
          visit.status = data.result.status;
          visit.statuses = data.result.statuses;
          visit.statuses_index = data.result.statuses_index;
          visit.activities = data.result.activities;
          // this.seen += 1;
          // this.scheduled += 1;
          //visit = statusIcon(visit);
          // if (this.groupedVisits[groupindex]) {
          //   this.groupedVisits[groupindex].scheduled += 1;
          // }
          //groupVisits(visit, visit);
          // hapticFeedBack();
          //visitHighlight(visit);
          visitUpdateEvent(visit);
          let toastMsg = newToast(".", "Success");
          setToastedMessage([...toastedMessage, toastMsg]);
        } else {
          // visitHighlight(visit);
          //showToast("Oops... Failed to update status. Please try again...");
          let toastMsg = newToast("Failed to update status", "Danger");
          setToastedMessage([...toastedMessage, toastMsg]);
        }
      });
    } else {
      //showToast("Oops... Failed to update status. Please try again...");
      let toastMsg = newToast("Failed to update status", "Danger");
      setToastedMessage([...toastedMessage, toastMsg]);
    }
  };

  const stopVisitTrackingAction = async (
    visit_item?: any,
    slidingItem?: any,
    event?: Event,
    group?: any,
    groupindex?: any
  ) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    if (visit?.status == "Last") {
      let callConfirmVisitData = {
        status: "Not seen",
        service_date: moment(serviceDate).format("YYYY-MM-DD"),
        last_visit: true,
      };
      let data = await callConfirmVisit(callConfirmVisitData, visit?._id);

      if (data.status == "success") {
        visit.clone_id = visit._id;
        visit._id = data.result._id;
        visit.status = data.result.status;
        visit.statuses = data.result.statuses;
        visit.statuses_index = data.result.statuses_index;
        visit.activities = data.result.activities;
        visit.statuses_index.last_visit = true;
        // this.statusIcon(visit);
        // // this.scheduled = this.scheduled - 1;
        // // if (this.groupedVisits[groupindex])
        // //   this.groupedVisits[groupindex].scheduled -= 1;
        // // updaing dos for visit on any update
        visit.service_date_from = data.result.service_date_from;
        visit.legal_entity_id = data.result.legal_entity_id;
        if (visit.series) {
          visit.series.push(visit);
          visit.series = multisort(visit.series, ["!service_date_from"]); // Sorting visit history data
        }
        // this.groupVisits(this.visits, visit);
        // this.hapticFeedBack();
        // this.visitHighlight(visit);
        // this.visitUpdateEvent(visit);
        visitUpdateEvent(visit);

        let toastMsg = newToast("success", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        // this.visitHighlight(visit);
        let toastMsg = newToast("Failed to update status", "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    } else if (
      visit?.status == "Scheduled" ||
      visit?.status == "Checked In" ||
      visit?.status == "Admit"
    ) {
      let skipVisitData = {
        status: "Not seen",
        last_visit: true,
      };
      let data = await skipVisit(visit._id, skipVisitData);

      if (data.status == "success" && data.result._id == visit._id) {
        visit.status = data.result.status;
        visit.statuses = data.result.statuses;
        // visit.statuses_index.last_visit = true; // data.result.statuses_index;
        // this.statusIcon(visit);
        // visit.statuses_index.last_visit = true;
        // this.groupVisits(this.visits, visit);
        // this.hapticFeedBack();
        // this.visitHighlight(visit);
        // this.visitUpdateEvent(visit);
        visitUpdateEvent(visit);
        let toastMsg = newToast("success", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        // this.visitHighlight(visit);
        let toastMsg = newToast("Failed to update status", "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    } else {
      let dischargeVisitData = {
        key: "last_visit",
        value: true,
      };
      let data = await dischargeVisit(dischargeVisitData, visit?._id);

      if (data.status == "success") {
        visit.statuses_index.last_visit = true;
        // this.groupVisits(this.visits, visit);
        // this.hapticFeedBack();
        // this.visitHighlight(visit);
        // this.visitUpdateEvent(visit);
        visitUpdateEvent(visit);
        let toastMsg = newToast("success", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        // this.visitHighlight(visit);
        let toastMsg = newToast("Failed to update status", "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    }
  };

  const stopVisitTracking = async (
    visit_item?: any,
    slidingItem?: any,
    event?: Event,
    group?: any,
    typeCall: any = "",
    groupindex?: any
  ) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    // this.visitEventsProvider.visitHighlight(visit);
    // if (typeCall == "") {
    //   slidingItem.close();
    //   event.stopPropagation();
    // }
    // handleMoreBtnModal(false);
    setIsLastVisit(true);

    // await stopVisitTrackingAction(visit, this.aa, null, group, groupindex)
    await stopVisitTrackingAction(visit);
  };

  const undoLastVisitAction = async (visit_item?: any) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    let payload = {
      key: "last_visit",
      value: false,
    };
    let data = await dischargeVisit(payload, visit?._id);
    if (data.status == "success") {
      visit.statuses_index.last_visit = false;
      // // no need to regroup
      visitUpdateEvent(visit); // emit visit-update event
      // this.visitHighlight(visit);
      // this.hapticFeedBack();
      let toastMsg = newToast("Success", "Success");
      setToastedMessage([...toastedMessage, toastMsg]);
      return visit;
    } else {
      let toastMsg = newToast("Failed to update status", "Danger");
      setToastedMessage([...toastedMessage, toastMsg]);
    }
  };

  const undoLastVisit = async (
    visit_item?: any,
    slidingItem?: any,
    event?: Event,
    typeCall = ""
  ) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    // if (typeCall == '') {
    //   slidingItem.close();
    //   event.stopPropagation();
    // }

    // handleMoreBtnModal(false);
    setIsLastVisit(false);
    await undoLastVisitAction(visit);
  };

  const handleSkipVisitAction = async (
    visit_item?: any,
    slidingItem?: any,
    event?: Event,
    groupindex?: any
  ) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    if (isAction(visit?.status, "Skip")) {
      if (visit?.status == "Last") {
        let callConfirmVisitData = {
          status: "Not seen",
          service_date: moment(serviceDate).format("YYYY-MM-DD"),
        };
        let data = await callConfirmVisit(callConfirmVisitData, visit?._id);
        if (data.status === "success") {
          dispatch(getPatient(visit._id));
          // visit.clone_id = visit._id;
          // visit._id = data.result._id;
          // visit.rendering_provider_id = data.result.rendering_provider_id;
          // visit.rendering_provider_name = data.result.rendering_provider_name;
          // visit.status = data.result.status;
          // visit.statuses = data.result.statuses;
          // visit.statuses_index = data.result.statuses_index;
          // visit.activities = data.result.activities;
          // this.statusIcon(visit);
          // visit.service_date_from = data.result.service_date_from;
          // visit.legal_entity_id = data.result.legal_entity_id;
          // if (visit.series) {
          //   visit.series.push(visit);
          //   visit.series = multisort(visit.series, ['!service_date_from']); // Sorting visit history data
          // }
          // this.groupVisits(this.visits, visit);
          // this.hapticFeedBack();
          // this.visitUpdateEvent(visit);
          // this.visitHighlight(visit);
          let toastMsg = newToast("Success", "Success");
          setToastedMessage([...toastedMessage, toastMsg]);
        } else {
          let toastMsg = newToast("Failed to update status", "Danger");
          setToastedMessage([...toastedMessage, toastMsg]);
        }
      } else {
        let skipVisitData = {
          status: "Not seen",
        };
        let data = await skipVisit(visit?._id, skipVisitData);
        if (data.status === "success" && data.result._id === visit?._id) {
          dispatch(getPatient(visit._id));
          // visit.status = data.result.status;
          // visit.statuses = data.result.statuses;
          // visit.statuses_index = data.result.statuses_index;
          // visit = this.statusIcon(visit);
          // this.groupVisits(this.visits, visit);
          // this.hapticFeedBack();
          // this.visitUpdateEvent(visit);
          // this.visitHighlight(visit);
          let toastMsg = newToast("Success", "Success");
          setToastedMessage([...toastedMessage, toastMsg]);
        } else {
          let toastMsg = newToast("Failed to update status", "Danger");
          setToastedMessage([...toastedMessage, toastMsg]);
        }
      }
    } else {
      let toastMsg = newToast("Action can't be completed", "Warning");
      setToastedMessage([...toastedMessage, toastMsg]);
    }
  };

  const handleSkipVisit = async (
    visit_item?: any,
    slidingItem?: any,
    event?: Event,
    typeCall: any = "",
    groupindex?: any
  ) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    // if (typeCall == "") {
    //   slidingItem.close();
    //   event.stopPropagation();
    // }
    // handleMoreBtnModal(false);

    //await handleSkipVisitAction(visit,slidingItem,null,groupindex)
    await handleSkipVisitAction(visit);
  };

  const undoSkipVisitHandler = async (visit_item?: any) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    // handleMoreBtnModal(false);
    if (visit?.status === "Not seen") {
      let body = {
        status: "Scheduled",
        // ,
        // last_visit: visit.statuses_index.last_visit,
        // description: visit.diagnosis_code_primary?.description,
      };

      let res = await undoSkipVisit(body, visit?._id);
      if (res.status === "success") {
        dispatch(getPatient(visit._id));
        let toastMsg = newToast("Success", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        let toastMsg = newToast("Failed to update ", "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    }
  };

  const billRecall = (visit_item?: any) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    // handleMoreBtnModal(false);

    if (visit?.status == "Claim assigned") {
      setRecallFirstPopupISOpen(true);
    } else {
      const getrecallSubmissionWithoutMsg = async () => {
        let res = await recallSubmission(visit?._id);
        if (res.status == "success") {
          if (visit.status == "Claim assigned") {
            visit.status = "Void"; // This is wrong ???
          } else {
            visit.status = "Checked In";
          }
          visit.statuses_index.submit_bill = false;
          // this.statusIcon(visit);
          // this.getVisitSeries(visit);
          // this.billed -= 1;
          // delete visit.recallExpires;
          // for (let currentVisit of this.visits) {
          //   if (currentVisit._id == visit._id) {
          //     currentVisit.billed = false;
          //     break
          //   }
          // }
          // this.groupVisits(this.visits, visit);
          visitUpdateEvent(visit); // emit visit-update event
          // this.hapticFeedBack();
          // this.visitHighlight(visit);
          let toastMsg = newToast("success", "Success");
          setToastedMessage([...toastedMessage, toastMsg]);
        } else {
          let toastMsg = newToast("Failed to recall bill visit", "Danger");
          setToastedMessage([...toastedMessage, toastMsg]);
        }
        return visit;
      };
      getrecallSubmissionWithoutMsg();
    }
  };

  const handleCloseCPTModal = () => {
    setOpenCPTModal(false);
  };

  // console.log({ data });

  const handleCPTModalItemClick = (data: any) => {
    // console.log({ data });
    // console.log({ visit });

    quickBill(data);

    setOpenCPTModal(false);
    // dispatch(
    //   updateCPTVisit({
    //     patientId,
    //     data,
    //     servicedate: serviceDate ? serviceDate : "",
    //   })
    // );
  };

  const quickBill = async (data: any) => {
    // let codes: any = await visit?.procedureCodes?.map((x: any) => {
    //   let res = {
    //     code: x.code,
    //     modifiers: x.modifiers ? x.modifiers : [],
    //   };
    //   return res;
    // });

    // console.log({ data, visit });

    let codes: any = [];

    if (visit?.procedureCodes) {
      visit?.procedureCodes.map((x: { code: any; modifiers: any }) => {
        let res = {
          code: x.code,
          modifiers: x.modifiers ? x.modifiers : [],
        };

        codes.push(res);
      });
    }

    // console.log({ codes });

    codes.splice(0, 1, { code: data.code, modifiers: data.modifiers || [] });
    // console.log({ codes });
    if (visit?.status === "Last") {
      visitClone(
        visit._id,
        "Scheduled",
        moment(selectedDate).format("YYYY-MM-DD"),
        // null,
        undefined
        // codes
      ).then((data: any) => {
        if (data.status === "success") {
          let tempVist = { ...visit };
          tempVist.clone_id = visit._id;
          tempVist._id = data.result._id;
          tempVist.status = data.result.status;
          tempVist.statuses = data.result.statuses;
          tempVist.statuses_index = data.result.statuses_index;
          tempVist.activities = data.result.activities;
          tempVist.service_lines = data.result.service_lines;
          tempVist.service_date_from = data.result.service_date_from;
          tempVist.legal_entity_id = data.result.legal_entity_id;
          tempVist.procedure_codes = codes;
          tempVist.procedureCodes?.splice(0, 1, data);
          if (codes.length > 0) {
            tempVist.procedure_code_primary = data;
          }
          // else {
          //   visit.procedure_code_primary = undefined;
          // }
          tempVist.rendering_provider_id = data.result.rendering_provider_id;
          tempVist.rendering_provider_name =
            data.result.rendering_provider_name;
          let provider = visit.rendering_provider_name
            ? visit.rendering_provider_name
            : "";
          let initials = provider
            .split(" ")
            .map(function (item: any[]) {
              return item[0];
            })
            .join("");
          tempVist.rendering_provider_initials = initials;

          // visitEventsProvider.seen += 1;
          statusIcon(visit);
          // groupCountUpdate(visit);
          visitHighlight(visit);
          visitUpdateEvent(visit); // emit visit-update event
          let toastMsg = newToast("Primary procedure changed", "Success");
          setToastedMessage([...toastedMessage, toastMsg]);
        } else {
          let toastMsg = newToast(
            "Failed to change primary procedure",
            "Danger"
          );
          setToastedMessage([...toastedMessage, toastMsg]);
        }
      });
    } else if (visit?._id) {
      // console.log(codes);

      let cptres = await putCptChange(visit?._id, codes);
      if (cptres?.status === "success") {
        if (visit) {
          let tempVist = { ...visit };
          tempVist.activities = cptres.result.activities;
          tempVist.procedure_code_primary = codes;

          visitUpdateEvent(tempVist);
          let toastMsg = newToast("Primary procedure changed", "Success");
          setToastedMessage([...toastedMessage, toastMsg]);
        }
        // visit
      } else {
        let toastMsg = newToast("Failed to update CPT", "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    }
  };

  // const handleCPTModalItemClick = async (code: any) => {
  //   let cptres = await putCptChange(data?._id, code);
  //   if (cptres?.status === "success") {
  //     if (data) {
  //       data.activities = cptres.result.activities;
  //       data.procedure_code_primary = code;

  //       visitUpdateEvent(data);
  //     }
  //     present("Successfully updated CPT...", 5000);
  //     // visit
  //   } else {
  //     present("Oops... Failed to update CPT. Please try again...", 5000);
  //   }
  //   setOpenCPTModal(false);
  //   // dispatch(
  //   //   updateCPTVisit({
  //   //     patientId,
  //   //     data,
  //   //     servicedate: serviceDate ? serviceDate : "",
  //   //   })
  //   // );
  // };

  const voidVisit = async (
    visit_item?: any,
    slidingItem?: any,
    event?: Event,
    groupindex?: any
  ) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    if (isAction(visit.status, "Void")) {
      let data = await voidBill(visit._id);
      if (data.status == "success" && data.result._id == visit._id) {
        visit.status = data.result.status;
        visit.statuses = data.result.statuses;
        visit.statuses_index = data.result.statuses_index;
        statusIcon(visit);
        // groupVisits(this.visits, visit);
        // hapticFeedBack();
        visitUpdateEvent(visit);
        // visitHighlight(visit);
        let toastMsg = newToast("..", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        let toastMsg = newToast("Failed to update status", "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
        // this.showToast('Oops... Failed to update status. Please try again...');
      }
    } else {
      let toastMsg = newToast("Action can't be completed", "Danger");
      setToastedMessage([...toastedMessage, toastMsg]);
      // this.showToast('Sorry, this action cannot be comleted');
    }
  };

  const cloneVisit = async (visit_item: any, visit_date: any) => {
    let visit = JSON.parse(JSON.stringify(visit_item));

    let visitCloneData = {
      status: "Checked In",
      service_date: moment(visit_date).format("YYYY-MM-DD"),
      last_visit: null,
      description: undefined,
      procedures: undefined,
      diagnosis_codes: undefined,
      facility_id: undefined,
      diagnosis_notes: undefined,
      telehealth: undefined,
      legal_entity_id: undefined,
      incident_to_provider_id: undefined,
    };
    let data = await callConfirmVisit(visit?._id, visitCloneData);
    if (data.status == "success") {
      data.result.facility_short_name = visit?.facility_short_name;
      data.result.facility_name = visit?.facility_name;
      statusIcon(data?.result);
      visit?.series?.push(data.result);
      visitUpdateEvent(visit); // emit visit-update event

      // visit.series = multisort(visit.series, ['!service_date_from']); // Sorting visit history data
      // groupVisits(visits, data.result);
      // loadvisit(data.result._id);
      let toastMsg = newToast("Visit created", "Success");
      setToastedMessage([...toastedMessage, toastMsg]);
    } else if (data.message) {
      let toastMsg = newToast(data.message, "Danger");
      setToastedMessage([...toastedMessage, toastMsg]);
    } else {
      // return this.showToast('Oops... Failed to update status. Please try again...');
    }
  };

  const calendarDay = async (visit_item: any, visit_date: any) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    if (visit_date != visit?.service_date_from) {
      let changeDateOfServiceData = {
        service_date: moment(visit_date).format("YYYY-MM-DD"),
      };
      let data = await changeDateOfService(visit?._id, changeDateOfServiceData);
      if (data.status == "success") {
        visit.service_date_from = data?.result?.service_date_from;
        // visit?.series.forEach(element => {
        //   if (this.visit._id == element._id) {
        //     element.service_date_from = this.visit.service_date_from;
        //     this.visitEventsProvider.scheduled = this.visitEventsProvider.scheduled - 1;
        //     return element;
        //   }
        // });
        // for (let i in this.visitEventsProvider.visits) {
        //   if (this.visitEventsProvider.visits[i]._id == this.visit._id) {
        //     this.visitEventsProvider.visits.splice(Number(i), 1);
        //   //   this.visitEventsProvider.groupVisits(this.visitEventsProvider.visits, this.visit);
        //   }
        // }
        // this.visitEventsProvider.initialGroupVisits(this.visitEventsProvider.visits, null);
        visitUpdateEvent(visit); // emit visit-update event
        // visitHighlight(visit);
      } else {
      }
      let toastMsg = newToast(data.message, "Danger");
      setToastedMessage([...toastedMessage, toastMsg]);
    }
  };

  const HandleOutsideClick = (fabBtnRef?: any, fabListRef?: any) => {
    useEffect(() => {
      //Close an element if clicked outside the element
      function handleClickOutside(event: any) {
        if (
          openPopup &&
          !fabBtnRef?.current.contains(event.target) &&
          !fabListRef?.current.contains(event.target)
        ) {
          setOpenPopup(!openPopup);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [openPopup, fabBtnRef, fabListRef]);
  };

  HandleOutsideClick(fabBtnRef, fabListRef);

  return (
    <IonCard className="otherInfoCard">
      <IonButton
        className="otherInfoCardButton"
        onClick={() => {
          setOpenPopup(!openPopup);
          setShowCalendar(false);
          setShowDate(false);
        }}
        ref={fabBtnRef}
      >
        {openPopup === false ? (
          <IonIcon icon={add}></IonIcon>
        ) : (
          <IonIcon icon={close}></IonIcon>
        )}
      </IonButton>
      {showCalendar && (
        <IonDatetime
          presentation="date"
          onIonChange={(e) => {
            console.log(e.detail.value);
            setShowCalendar(false);
            setCloneVisitDate(e.detail.value);
            cloneVisit(data, e.detail.value);
          }}
        ></IonDatetime>
      )}
      {showDate && (
        <IonDatetime
          min="2017-01-01T00:00:00"
          max={moment().format()}
          presentation="date"
          onIonChange={(e) => {
            console.log(e.detail.value);
            setShowDate(false);
            setDosDate(e.detail.value);
            calendarDay(data, e.detail.value);
          }}
        ></IonDatetime>
      )}
      {openPopup === true ? (
        <div className="otherInfo-popup" ref={fabListRef}>
          <IonGrid>
            <IonRow>
              <IonCol className="otherInfo-popup-col">
                <button className="otherInfo-popup-btn">
                  <IonIcon
                    className="otherInfo-popup-icon"
                    icon={medkitOutline}
                  ></IonIcon>
                  <IonText
                    className="otherInfo-popup-text"
                    onClick={() => {
                      setOpenPopup(false);
                      dispatch(setPatientDetail(SelectedData));
                      router.push("/addNewEncounter", "forward");
                    }}
                  >
                    New encounter
                  </IonText>
                </button>
              </IonCol>
            </IonRow>

            <FeatureProvider
              children={
                <IonRow>
                  <IonCol className="otherInfo-popup-col">
                    <button className="otherInfo-popup-btn">
                      <IonIcon
                        className="otherInfo-popup-icon"
                        icon={documentOutline}
                      ></IonIcon>
                      <IonText
                        className="otherInfo-popup-text"
                        onClick={() => {
                          setOpenPopup(false);
                          router.push(
                            `/progressnote/${visitId ? visitId : ""}`,
                            "forward"
                          );
                        }}
                      >
                        New progress note
                      </IonText>
                    </button>
                  </IonCol>
                </IonRow>
              }
              feature={"VisitNotes POC"}
            />
            {/* {data?.status} */}

            {/* {isAction(data?.status, "Visit note") && (
              <IonRow>
                <IonCol className="otherInfo-popup-col">
                  <button className="otherInfo-popup-btn">
                    <IonIcon
                      className="otherInfo-popup-icon"
                      icon={documentOutline}
                    ></IonIcon>
                    <IonText
                      className="otherInfo-popup-text"
                      onClick={() => {
                        setOpenPopup(false);
                        visitNoteSliding(data);
                      }}
                    >
                      Visit note
                    </IonText>
                  </button>
                </IonCol>
              </IonRow>
            )} */}

            {isAction(data?.status, "Change CPT") &&
              !isNoteRequired &&
              data?.procedure_code_primary &&
              data?.procedure_code_primary?.code &&
              moment(data?.service_date_from).isBefore(new Date()) && (
                <IonRow>
                  <IonCol className="otherInfo-popup-col">
                    <button className="otherInfo-popup-btn">
                      <IonIcon
                        className="otherInfo-popup-icon"
                        icon={documentOutline}
                      ></IonIcon>
                      <IonText
                        className="otherInfo-popup-text"
                        onClick={() => {
                          setOpenPopup(false);
                          setOpenCPTModal(true);
                        }}
                      >
                        CPT change
                      </IonText>
                    </button>
                  </IonCol>
                </IonRow>
              )}

            {isAction(data?.status, "Bill") &&
              !isNoteRequired &&
              data?.procedure_code_primary &&
              data?.procedure_code_primary?.code &&
              moment(data?.service_date_from).isBefore(new Date()) && (
                <IonRow>
                  <IonCol className="otherInfo-popup-col">
                    <button className="otherInfo-popup-btn">
                      <IonIcon
                        className="otherInfo-popup-icon"
                        icon={documentOutline}
                      ></IonIcon>
                      <IonText
                        className="otherInfo-popup-text"
                        onClick={() => {
                          setOpenPopup(false);
                          billVisit(data, IonItemSliding);
                        }}
                      >
                        Bill visit
                      </IonText>
                    </button>
                  </IonCol>
                </IonRow>
              )}

            {isAction(data?.status, "Bill SALT") &&
              !data?.statuses_index?.recalled &&
              !isNoteRequired &&
              data?.procedure_code_primary &&
              data?.procedure_code_primary?.code &&
              moment(data?.service_date_from).isBefore(new Date()) && (
                <IonRow>
                  <IonCol className="otherInfo-popup-col">
                    <button className="otherInfo-popup-btn">
                      <IonIcon
                        className="otherInfo-popup-icon"
                        icon={documentOutline}
                      ></IonIcon>
                      <IonText
                        className="otherInfo-popup-text"
                        onClick={() => {
                          setOpenPopup(false);
                          billSalt(data, IonItemSliding);
                        }}
                      >
                        Bill SALT
                      </IonText>
                    </button>
                  </IonCol>
                </IonRow>
              )}

            {isAction(data?.status, "Confirm") && (
              <IonRow>
                <IonCol className="otherInfo-popup-col">
                  <button className="otherInfo-popup-btn">
                    <IonIcon
                      className="otherInfo-popup-icon"
                      icon={documentOutline}
                    ></IonIcon>
                    <IonText
                      className="otherInfo-popup-text"
                      onClick={() => {
                        setOpenPopup(false);
                        confirmVisit(data, IonItemSliding, "", "");
                      }}
                    >
                      My census
                    </IonText>
                  </button>
                </IonCol>
              </IonRow>
            )}

            {isAction(data?.status, "Discharge") &&
              !data?.statuses_index?.last_visit &&
              moment(data?.service_date_from) <= moment() && (
                <IonRow>
                  <IonCol className="otherInfo-popup-col">
                    <button className="otherInfo-popup-btn">
                      <IonIcon
                        className="otherInfo-popup-icon"
                        icon={documentOutline}
                      ></IonIcon>
                      <IonText
                        className="otherInfo-popup-text"
                        onClick={() => {
                          setOpenPopup(false);
                          stopVisitTracking(data);
                        }}
                      >
                        Discharge
                      </IonText>
                    </button>
                  </IonCol>
                </IonRow>
              )}

            {isAction(data?.status, "Undo discharge") &&
              data?.statuses_index?.last_visit &&
              moment(data?.service_date_from) <= moment() && (
                <IonRow>
                  <IonCol className="otherInfo-popup-col">
                    <button className="otherInfo-popup-btn">
                      <IonIcon
                        className="otherInfo-popup-icon"
                        icon={documentOutline}
                      ></IonIcon>
                      <IonText
                        className="otherInfo-popup-text"
                        onClick={() => {
                          setOpenPopup(false);
                          undoLastVisit(data);
                        }}
                      >
                        Undo discharge
                      </IonText>
                    </button>
                  </IonCol>
                </IonRow>
              )}

            {isAction(data?.status, "Skip") && (
              <IonRow>
                <IonCol className="otherInfo-popup-col">
                  <button className="otherInfo-popup-btn">
                    <IonIcon
                      className="otherInfo-popup-icon"
                      icon={documentOutline}
                    ></IonIcon>
                    <IonText
                      className="otherInfo-popup-text"
                      onClick={() => {
                        setOpenPopup(false);
                        handleSkipVisit(data);
                      }}
                    >
                      Skip visit
                    </IonText>
                  </button>
                </IonCol>
              </IonRow>
            )}

            {data?.status === "Not seen" && (
              <IonRow>
                <IonCol className="otherInfo-popup-col">
                  <button className="otherInfo-popup-btn">
                    <IonIcon
                      className="otherInfo-popup-icon"
                      icon={documentOutline}
                    ></IonIcon>
                    <IonText
                      className="otherInfo-popup-text"
                      onClick={() => {
                        setOpenPopup(false);
                        undoSkipVisitHandler(data);
                      }}
                    >
                      Undo skip
                    </IonText>
                  </button>
                </IonCol>
              </IonRow>
            )}

            {isAction(data?.status, "Recall bill") &&
              !data?.statuses_index?.recalled && (
                <IonRow>
                  <IonCol className="otherInfo-popup-col">
                    <button className="otherInfo-popup-btn">
                      <IonIcon
                        className="otherInfo-popup-icon"
                        icon={documentOutline}
                      ></IonIcon>
                      <IonText
                        className="otherInfo-popup-text"
                        onClick={() => {
                          setOpenPopup(false);
                          billRecall(data);
                        }}
                      >
                        Recall submission
                      </IonText>
                    </button>
                  </IonCol>
                </IonRow>
              )}

            {isAction(data?.status, "Conversation") &&
              SelectedData?.conversation_id && (
                <IonRow>
                  <FeatureProvider
                    children={
                      <IonCol className="otherInfo-popup-col">
                        <button className="otherInfo-popup-btn">
                          <IonIcon
                            className="otherInfo-popup-icon"
                            icon={documentOutline}
                          ></IonIcon>
                          <IonText
                            className="otherInfo-popup-text"
                            onClick={async () => {
                              await dispatch(
                                setRedirectedFrom("patientDetails")
                              );
                              setOpenPopup(false);
                              router.push(
                                `/messagethread/${SelectedData?.conversation_id}`,
                                "forward"
                              );
                            }}
                          >
                            Messages
                          </IonText>
                        </button>
                      </IonCol>
                    }
                    feature={"Messaging"}
                  />
                </IonRow>
              )}
            {isAction(data?.status, "Void") && (
              <IonRow>
                <IonCol className="otherInfo-popup-col">
                  <button className="otherInfo-popup-btn">
                    <IonIcon
                      className="otherInfo-popup-icon"
                      icon={documentOutline}
                    ></IonIcon>
                    <IonText
                      className="otherInfo-popup-text"
                      onClick={() => {
                        setOpenPopup(false);
                        voidVisit(data);
                      }}
                    >
                      Void Encounter
                    </IonText>
                  </button>
                </IonCol>
              </IonRow>
            )}
            {isAction(data?.status, "Clone visit") && (
              <IonRow>
                <IonCol className="otherInfo-popup-col">
                  <button className="otherInfo-popup-btn">
                    <IonIcon
                      className="otherInfo-popup-icon"
                      icon={documentOutline}
                    ></IonIcon>
                    <IonText
                      className="otherInfo-popup-text"
                      onClick={() => {
                        setOpenPopup(false);
                        setShowCalendar(true);
                      }}
                    >
                      Clone Encounter
                    </IonText>
                  </button>
                </IonCol>
              </IonRow>
            )}
            {isAction(data?.status, "Change DOS") && (
              <IonRow>
                <IonCol className="otherInfo-popup-col">
                  <button className="otherInfo-popup-btn">
                    <IonIcon
                      className="otherInfo-popup-icon"
                      icon={documentOutline}
                    ></IonIcon>
                    <IonText
                      className="otherInfo-popup-text"
                      onClick={() => {
                        setOpenPopup(false);
                        setShowDate(true);
                      }}
                    >
                      Change DOS
                    </IonText>
                  </button>
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </div>
      ) : (
        <></>
      )}
      <IonCardSubtitle className="otherInfoCardTitle">
        Other information
      </IonCardSubtitle>

      <IonFabList side="top"></IonFabList>

      <div className="otherInfoCardContent">
        <div className="singleInfo">
          <div className="otherInfoLabel">
            <p>Room number</p>
          </div>
          <div className="otherInfoRoomNum">
            <p>{SelectedData?.room ? SelectedData?.room : "-"}</p>
          </div>
        </div>

        <div className="singleInfo">
          <div className="otherInfoLabel">
            <p>Billing notes</p>
          </div>
          <div className="otherInfo">
            <p>
              {SelectedData?.code_assist?.billing_notes
                ? SelectedData?.code_assist?.billing_notes
                : "-"}
            </p>
          </div>
        </div>
      </div>

      <RecallBtnFirstPopup
        firstPopupISOpen={recallFirstPopupISOpen}
        setFirstPopupISOpen={setRecallFirstPopupISOpen}
        setSecondPopupISOpen={setRecallSecondPopupISOpen}
      />

      <RecallBtnSecondPopup
        visit={SelectedData}
        secondPopupISOpen={recallSecondPopupISOpen}
        setSecondPopupISOpen={setRecallSecondPopupISOpen}
      />

      <CptModal
        visit={SelectedData}
        openModal={openCPTModal}
        closeModal={handleCloseCPTModal}
        handleClick={handleCPTModalItemClick}
      />
      <Toast
        toastList={toastedMessage}
        position="top-right"
        autoDelete={true}
        autoDeleteTime={4000}
      />
    </IonCard>
  );
};

export default OtherInformation;
