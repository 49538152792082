import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPatientsSearchByID,
  getVisitDiagnosis,
  getVisitProcedures,
} from "../../helper/backendHelper";

export const setInitialCode = createAsyncThunk(
  //action type string
  "editProcedureAndDiagnosis/setInitialCode",
  // callback function
  async (visitid: string, thunkAPI) => {
    const resDiagnosis = await getVisitDiagnosis(visitid);
    const resProcedures = await getVisitProcedures(visitid);
    const resPatient = await getPatientsSearchByID({ id: visitid });

    if (
      resDiagnosis?.status === 500 ||
      resProcedures?.status === 500 ||
      resPatient?.status === 500
    ) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      if (
        resDiagnosis?.status === "success" &&
        resProcedures?.status === "success" &&
        resPatient?.status === "success"
      ) {
        return {
          diagnosisData: resDiagnosis?.results,
          proceduresData: resProcedures?.results,
          patientDetail: resPatient?.result?.[0],
        };
      }
    }
  }
);
