/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import "./ProviderHold.scss";
import SearchBox from "../../components/Common/SearchBox/SearchBox";
import { close, filter } from "ionicons/icons";
import Tab, { TabNav, TabRouter } from "../../components/Common/Tab/Tab";
import PatientCard from "./PatientCard";
import { getProviderHold } from "../../helper/backendHelper";
import { debounce } from "../../utils/debounce";
import DoubleTapWraper from "../../components/Census/DoubleTapWraper";
import Loader from "../../components/Common/Loaders/Loader";
import Button from "../../components/Common/Buttons/Buttons";
import { useAppSelector } from "../../store/hooks";
import { selectUser } from "../../store/reducers/user";

const ProviderHold: React.FC = () => {
  const [count, setCount] = useState();

  const patientListHandler = async () => {
    try {
      let res = await getProviderHold({
        startIndex: 0,
        recordCount: 15,
        scope: "practice",
      });
      setCount(res.data.total);
    } catch (error) {}
  };

  useEffect(() => {
    patientListHandler();
  }, []);

  return (
    <div className="provider-hold">
      <div className="top-container"></div>
      <div>
        <Tab>
          <TabRouter>
            <TabNav component={Inpatient}>
              <IonRow>
                <IonCol className="in-tab-container">Returned</IonCol>
                <div className="number-circle">
                  <p className="number-style">{count}</p>
                </div>
              </IonRow>
            </TabNav>
            <TabNav component={Encounters}>
              <IonRow>
                <IonCol className="in-tab-container">Draft</IonCol>
                <div className="number-circle">
                  <p className="number-style">0</p>
                </div>
              </IonRow>
            </TabNav>
          </TabRouter>
        </Tab>
      </div>
    </div>
  );
};

export const Encounters = () => {
  return (
    <IonContent className="inPatientTab1">
      <h1>Drafts</h1>
    </IonContent>
  );
};

export const Inpatient = () => {
  const [data, setData] = useState<any[]>();
  const userData = useAppSelector(selectUser);

  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState<any>([]);
  const [dataToMap, setDataToMap] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [count, setCount] = useState<number>(15);
  const [filterBy, setFilterBy] = useState<string | null>(null);
  const router = useIonRouter();

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    setDataToMap(data);
  }, []);

  let testData: any[];
  useEffect(() => {
    patientListHandler(this);
  }, []);

  const patientListHandler = async (e: any) => {
    if (!e) {
      setLoading(true);
    }
    try {
      let res = await getProviderHold({
        startIndex: 0,
        recordCount: count,
        scope: "practice",
      });
      if (res?.data?.length === 0) {
        setInfiniteDisabled(true);
      } else {
        setData(res.data.result);
        setDataToMap(res.data.result);
        setCount(count + 15);
      }
      setLoading(false);
      e?.target?.complete();
    } catch (error) {}
  };

  useEffect(() => {
    setSearchResult(data);
    setSearchText("");
  }, [data]);

  useEffect(() => {
    if (filterBy === "provider") {
      let newList = data?.filter(
        (item) => userData.provider?._id === item?.rendering_provider_id
      );
      setSearchResult(newList);
      setDataToMap(newList);
    } else {
      setSearchResult(data);
      setDataToMap(data);
    }
  }, [data, filterBy]);

  useEffect(() => {
    //logic to set search result

    setSearchResult(() =>
      data?.filter(
        (item: any) =>
          item?.patient_name
            .replace(/,/g, "")
            ?.toLowerCase()
            ?.includes(searchText.toLowerCase()) ||
          item?.facility_name
            ?.toLowerCase()
            ?.includes(searchText.toLowerCase()) ||
          String(item?.reason_codes)
            ?.toLowerCase()
            ?.includes(searchText.toLowerCase())
      )
    );
  }, [searchText]);

  const loadData = async (ev: any) => {
    let ldata: any = await getProviderHold({
      startIndex: 0,
      recordCount: count,
      scope: "practice",
    });
    if (ldata?.data?.length === 0) {
      setInfiniteDisabled(true);
    } else {
      setDataToMap(ldata.data.result);
      setData(ldata.data.result);
      setCount(count + 15);
    }
    ev.target.complete();
  };

  return (
    <div>
      <IonGrid className="filter-group-holder">
        <IonRow className="search-box-container">
          <IonCol className="search-box-provider" size="10">
            <SearchBox
              onChange={debounce(setSearchText, 1000)}
              placeholder={" Search in Issues..."}
              variant="round-corner"
            />
          </IonCol>
          <IonCol className="provider-button" size="2">
            <div
              className="provider-filter-icon-holder"
              onClick={() => setIsFilterOpen(true)}
            >
              <IonIcon icon={filter} className="provider-filter-icon" />
              <div className="filter-small-icon"></div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <hr></hr>
      <div>
        {" "}
        <IonContent className="inPatientTab1">
          {/* <div className="total-label">
            {!loading && (
              <IonRow>
                <IonCol>Patient</IonCol>
                <div className="text-circle">
                  <p className="text-style">{searchResult?.length}</p>
                </div>
              </IonRow>
            )}
          </div> */}
          <IonList className="card-list">
            {loading ? (
              <div className="providerHoldLoader">
                <Loader
                  data={{
                    name: "crescent",
                    color: "primary",
                  }}
                />
              </div>
            ) : searchResult?.length > 0 ? (
              searchText === "" ? (
                dataToMap?.map((list: any, idx: number) => {
                  return (
                    <DoubleTapWraper
                      key={idx}
                      onDoubleTap={() =>
                        router.push(`/patientDetails/${list._id}`, "forward")
                      }
                    >
                      <PatientCard
                        patientdata={data?.[idx]}
                        list={list}
                        key={idx}
                      />
                    </DoubleTapWraper>
                  );
                })
              ) : (
                searchResult.map((list: any, idx: number) => {
                  return (
                    <DoubleTapWraper
                      key={idx}
                      onDoubleTap={() =>
                        router.push(`/patientDetails/${list._id}`, "forward")
                      }
                    >
                      <PatientCard
                        patientdata={searchResult?.[idx]}
                        list={list}
                        key={idx}
                      />
                    </DoubleTapWraper>
                  );
                })
              )
            ) : (
              <p className="text-display user-message"> </p>
            )}
            <IonInfiniteScroll
              threshold="100px"
              id="infinite-scroll"
              disabled={isInfiniteDisabled}
              onIonInfinite={loadData}
            >
              <IonInfiniteScrollContent loadingSpinner="bubbles"></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </IonList>
          {/* <div>
            {!loading && (
              <IonRow>
                <IonCol>Visit</IonCol>
                <div className="text-circle-visit">
                  <p className="text-style">{searchResult?.length}</p>
                </div>
              </IonRow>
            )}
          </div> */}
          {/* <IonList className="card-list">
            {loading ? (
              <div className="providerHoldLoader">
                <Loader
                  data={{
                    name: "crescent",
                    color: "primary",
                  }}
                />
              </div>
            ) : searchResult?.length > 0 ? (
              searchText === "" ? (
                dataToMap?.map((list: any, idx: number) => {
                  return (
                    <DoubleTapWraper
                      key={idx}
                      onDoubleTap={() =>
                        router.push(`/patientDetails/${list._id}`, "forward")
                      }
                    >
                      <PatientCard
                        patientdata={data?.[idx]}
                        list={list}
                        key={idx}
                      />
                    </DoubleTapWraper>
                  );
                })
              ) : (
                searchResult.map((list: any, idx: number) => {
                  return (
                    <DoubleTapWraper
                      key={idx}
                      onDoubleTap={() =>
                        router.push(`/patientDetails/${list._id}`, "forward")
                      }
                    >
                      <PatientCard
                        patientdata={searchResult?.[idx]}
                        list={list}
                        key={idx}
                      />
                    </DoubleTapWraper>
                  );
                })
              )
            ) : (
              <p className="text-display user-message"> </p>
            )}
          </IonList> */}
        </IonContent>
        <>
          <IonModal
            className="newencounter-modal"
            isOpen={isFilterOpen}
            onDidDismiss={() => setIsFilterOpen(false)}
            breakpoints={[0, 0.3, 0.4, 1]}
            initialBreakpoint={0.4}
            backdropBreakpoint={0.2}
          >
            <div className="newencounter-opened-modal">
              <div className="header-holder">
                <div className="title-holder">
                  <IonLabel className="labelTitle">Issues Filter</IonLabel>
                </div>
                <div className="btn-holder">
                  <IonIcon
                    icon={close}
                    className="close-icon"
                    onClick={() => setIsFilterOpen(false)}
                  ></IonIcon>
                </div>
              </div>
              <div className="content-holder">
                <div className="content-text-holder">
                  {/* <IonText className="content-text">
                    Please select a method to start a new <br /> encounter.
                  </IonText> */}
                </div>
                <div className="three-btn-holder">
                  <Button
                    fullWidth={true}
                    tallbutton
                    color="secondary"
                    onClick={() => {
                      setFilterBy("provider");
                      setIsFilterOpen(false);
                    }}
                  >
                    <IonText className="content-btn-text">
                      Curent Provider
                    </IonText>
                  </Button>
                </div>
                <div className="three-btn-holder">
                  <Button
                    fullWidth={true}
                    tallbutton
                    color="secondary"
                    onClick={() => {
                      setFilterBy(null);
                      setIsFilterOpen(false);
                    }}
                  >
                    <IonText className="content-btn-text">All </IonText>
                  </Button>
                </div>
              </div>
            </div>
          </IonModal>
        </>
      </div>
    </div>
  );
};

export default ProviderHold;
