/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonText,
  useIonRouter,
  IonToast,
  useIonLoading,
  useIonToast,
  IonBackButton,
} from "@ionic/react";

import { cameraOutline, micSharp } from "ionicons/icons";
//import "./AddNewEncounter.scss";
import DiagnosisCard from "../../Encounter/DiagnosisCard/DiagnosisCard";
import ProcedureCard from "../../Encounter/ProcedureCard/ProcedureCard";
import AdditionalInfoCard from "../../Encounter/AdditionalCard/AdditionalInfo";
import Button from "../../../components/Common/Buttons/Buttons";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import {
  diagnosisUpdate,
  documentUpdate,
  procedureUpdate,
} from "../../../helper/backendHelper";

import EncounterCard from "../../Encounter/EncounterCard/EncounterCard";
import { getPatient } from "../../../store/thunk/patientThunk";
import { useParams } from "react-router";
import {
  handleRegroupCode,
  resetThisState,
  setDiagnosisCode,
  setProcedureCode,
} from "../../../store/reducers/editProcedureAndDiagnosis";
import { setInitialCode } from "../../../store/thunk/editProcedureAndDiagnosis";
import { Layout } from "../../../components/Layout/Layout";
import { getVisitsCensus } from "../../../store/thunk/censusThunk";
import moment from "moment";
import { selectUser } from "../../../store/reducers/user";
import Toast, { newToast } from "../../../components/Common/Toaster/Toast";

interface uploadFileType {
  key: string;
  size: any;
  file_type: string;
  checksum: null; //checksumval,
  file: any;
  img: any;
}

type ToastedList = {
  id: number;
  description: string;
};
const EditProcedureAndDiagnosis: React.FC = () => {
  const [files, setFiles] = useState<uploadFileType[]>([]);
  let quary = useParams<{ id?: string }>();

  const [toastedMessage, setToastedMessage] = useState<ToastedList[]>([]);
  const thisDate = useAppSelector((state) => state.census.activeDate);
  const userData = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  // const [present, dismiss] = useIonLoading();
  // const [toastPresent, toastDismiss] = useIonToast();
  const thisEditState = useAppSelector(
    (state) => state.editProcedureAndDiagnosis
  );
  // const facility = useAppSelector((state) => state.facility);
  // const patientDetail = useAppSelector(selectPatientDetail);
  const thisPatient = useAppSelector(
    (state) => state.editProcedureAndDiagnosis
  ).thisPatient;
  const currentFacility = useAppSelector(
    (state) => state.census.censusFacility
  );
  // const [visitID, setVisitID] = useState<any>();
  const [icons, setIcons] = useState([
    {
      name: (
        <div className="custom_icon">
          <span className="text">T</span>
        </div>
      ),
      isIcon: false,
      selected: true,
    },
    {
      name: cameraOutline,
      isIcon: true,
      selected: false,
    },
    {
      name: micSharp,
      isIcon: true,
      selected: false,
    },
  ]);
  const selectedPractice = useAppSelector((state) => state.user)?.practice;

  useEffect(() => {
    // console.log(thisPatient?.procedureCodes);
    if (quary.id) {
      dispatch(setInitialCode(quary?.id));
    }

    return () => {
      dispatch(resetThisState());
    };
  }, [quary.id]);

  // const onDateSelect = (date: any) => {
  //   dispatch(setDateOfService(moment(date).format("YYYY-MM-DD")));
  // };

  const [isValidate, setIsValidate] = useState(true);

  const onDiagnosisCodeSet = (code: any) => {
    dispatch(setDiagnosisCode(code));
  };

  const handleCodeRegroup = (regroupData: any) => {
    dispatch(handleRegroupCode(regroupData));
  };

  const onProcedureCodeSet = (code: any) => {
    dispatch(setProcedureCode(code));
  };

  const handleUpdateVisit = async () => {
    //update Diagnosis
    let data: any = [];
    thisEditState.diagnosisCodesSelected.map((item: any, index: any) => {
      data.push(thisEditState.diagnosisCodesSelected[index].code);
    });
    if (quary?.id) {
      let cptres = await diagnosisUpdate(quary?.id, data);
      if (cptres?.status === "success") {
        procedureEditUpdate();
        dispatch(
          getVisitsCensus({
            servicedate: moment(thisDate).format("YYYY-MM-DD"),
            // thisProvider: userData.provider,
            facilityId: currentFacility?._id,
          })
        );
      } else {
        console.log("Oops... Failed to update Procedures. Please try again...");
      }
    }
  };

  const procedureEditUpdate = async () => {
    let dataProcedures: { code: string; modifiers: any[] }[] = [];

    thisEditState.procedureCodesSelected.map((item: any, index: any) => {
      dataProcedures.push({
        code: thisEditState.procedureCodesSelected[index].code,
        modifiers: [],
      });
    });
    if (quary?.id) {
      let procres = await procedureUpdate(quary?.id, dataProcedures);
      if (procres?.status === "success") {
        dispatch(
          getVisitsCensus({
            servicedate: moment(thisDate).format("YYYY-MM-DD"),
            // thisProvider: userData.provider,
            facilityId: currentFacility?._id,
          })
        );
        //upload files
        if (files.length > 0) {
          documentEditUpdate();
        } else {
          if (quary?.id) {
            dispatch(getPatient(quary?.id));
          }
          dispatch(resetThisState());

          router.goBack();
          // toastPresent("Successfully updated procedure and diagnosis...", 5000);
          // present({
          //   message: "Loading...",
          //   duration: 500,
          // });
          // dismiss();
          let toastMsg = newToast(
            "successfully submitted procedure and diagnosis",
            "Success"
          );
          setToastedMessage([...toastedMessage, toastMsg]);
        }
      } else {
        // toastPresent(
        //   "Oops... Failed to update Procedures. Please try again...",
        //   5000
        // );
        let toastMsg = newToast(procres.message, "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    }
  };
  const documentEditUpdate = async () => {
    // payload
    let documentDetails: {
      files: {
        key: string;
        checksum: any;
        size: number;
      }[];
      chargeid: any;
      practice_id: any;
      patient_id: any;
    };
    let uploadFiles: {
      key: string;
      checksum: any;
      size: number;
    }[] = [];
    files.map((item: any, index: any) => {
      uploadFiles.push({
        key: files[index]?.key,
        checksum: files[index]?.checksum,
        size: files[index]?.size,
      });
    });
    documentDetails = {
      files: uploadFiles,
      chargeid: quary?.id,
      practice_id: selectedPractice?._id,
      patient_id: thisPatient?.patient_id,
    };
    if (quary?.id) {
      let procres = await documentUpdate(documentDetails);
      if (procres?.status === "success") {
        if (quary?.id) {
          dispatch(getPatient(quary?.id));
        }
        dispatch(resetThisState());

        router.goBack();
        // toastPresent("Successfully updated procedure and diagnosis...", 5000);
        // present({
        //   message: "Loading...",
        //   duration: 500,
        // });
        // dismiss();
        let toastMsg = newToast(
          "successfully update procedure and diagnosis",
          "Success"
        );
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        // toastPresent(
        //   "Oops... Failed to update documents. Please try again...",
        //   5000
        // );
        let toastMsg = newToast(procres.message, "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    }
    setIsValidate(true);
  };
  useEffect(() => {
    if (
      thisEditState.diagnosisCodesSelected.length > 0 &&
      thisEditState.procedureCodesSelected.length > 0 &&
      thisEditState.procedureCodesSelected[0].code != undefined
    ) {
      setIsValidate(false);
    } else {
      setIsValidate(true);
    }
  }, [
    thisEditState.procedureCodesSelected,
    thisEditState.diagnosisCodesSelected,
  ]);

  return (
    <Layout
      isLoading={thisEditState?.isloading}
      isError={thisEditState?.isError}
      errorMsg={thisEditState.errorMsg}
    >
      <IonHeader mode="ios">
        <IonToolbar className="addnewencounter-toolbar-background">
          <IonButtons slot="start">
            <IonBackButton text={""} className="setting-backbutton" />
          </IonButtons>
          <IonTitle className=".addnewencounter-toolbar-patientname-text">
            {thisPatient?.patient_name}
          </IonTitle>
          <div className="encounterheadericons">
            {icons.map((item: any, i) =>
              item.isIcon === true ? (
                <div
                  key={i}
                  className={`headerIcons ${
                    item.selected === true && "active"
                  }`}
                >
                  <IonIcon icon={item.name} />
                </div>
              ) : (
                <div
                  key={i}
                  className={`headerIcons ${
                    item.selected === true && "active"
                  }`}
                >
                  <IonText>{item.name}</IonText>
                </div>
              )
            )}
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="addNewEncounterContent">
        <EncounterCard title="Diagnosis codes" isIcon>
          <DiagnosisCard
            selectedCode={thisEditState.diagnosisCodesSelected}
            onSetCode={onDiagnosisCodeSet}
            handleRegroup={handleCodeRegroup}
          />
        </EncounterCard>

        <EncounterCard title="Procedure codes" isIcon>
          <ProcedureCard
            selectedCode={thisEditState.procedureCodesSelected}
            onSetCode={onProcedureCodeSet}
            handleRegroup={handleCodeRegroup}
          />
        </EncounterCard>

        <EncounterCard title="Additional info">
          <AdditionalInfoCard files={files} setFiles={setFiles} />
        </EncounterCard>

        {/* <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastmessage}
          duration={1000}
        /> */}
        <div className="billvisit-btn-text">
          <Button
            boldfont={true}
            fullWidth={true}
            onClick={handleUpdateVisit}
            disabled={isValidate}
          >
            <IonText>Update visit</IonText>
          </Button>
        </div>
      </IonContent>
      <Toast
        toastList={toastedMessage}
        position="top-right"
        autoDelete={true}
        autoDeleteTime={4000}
      />
    </Layout>
  );
};

export default EditProcedureAndDiagnosis;
