import axios from "axios";
import { setupInterceptorsTo, SOCKET_URL } from "./apiHelper";

const socketApi = setupInterceptorsTo(
  axios.create({
    baseURL: SOCKET_URL,
    headers: {
      "Content-Type": "application/json",
      // Origin: "claimocity",
    },
  })
);

export async function getSocket(url: any, config = {}) {
  return await socketApi
    .get(url, { ...config })
    .then((response: { data: any }) => response.data);
}

export async function postSocket(url: any, data: any, config = {}) {
  return socketApi
    .post(url, { ...data }, { ...config })
    .then((response: { data: any }) => response.data);
}

export async function putSocket(url: any, data: any, config = {}) {
  return socketApi
    .put(url, { ...data }, { ...config })
    .then((response: { data: any }) => response.data);
}

export async function delSocket(url: any, config = {}) {
  return await socketApi
    .delete(url, { ...config })
    .then((response: { data: any }) => response.data);
}

export async function postParticipantSocket(url: any, data: any, config = {}) {
  return socketApi
    .post(url, [ ...data ], { ...config })
    .then((response: { data: any }) => response.data);
}
