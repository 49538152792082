import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  checkmarkOutline,
  chevronBackOutline,
  closeOutline,
  star,
  starOutline,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import "./RemoveProcedures.scss";

interface procedure {
  code: string;
  description: string;
  long_description: string;
  enabled: boolean;
  favorite: boolean;
  selected?: boolean;
}

const RemoveProcedures: React.FC<any> = ({
  isOpen,
  setIsOpen,
  selectedCodes,
  onRemoveCode,
}) => {
  const [thisSelectedCodes, setThisSelectedCodes] = useState<procedure[]>([]);
  const darkThemeBoolean = useAppSelector((state) => state.layout.darkTheme);

  const removeSelected = () => {
    const codesArr = selectedCodes?.filter((item: procedure, i: any) => {
      let isSelected = thisSelectedCodes.includes(item);
      return isSelected !== true;
    });

    onRemoveCode(codesArr);
  };

  useEffect(() => {
    setThisSelectedCodes([]);
  }, [selectedCodes]);

  const onSelect = (code: procedure) => {
    let isSelected = thisSelectedCodes.includes(code);

    if (isSelected) {
      let temp = thisSelectedCodes?.filter((item: procedure) => {
        let isSelected = item.code !== code.code;

        return isSelected;
      });
      setThisSelectedCodes(temp);
    } else {
      setThisSelectedCodes([...thisSelectedCodes, code]);
    }
  };

  return (
    <IonModal
      className="remove-code"
      isOpen={isOpen}
      onDidDismiss={() => {
        setIsOpen(false);
      }}
      initialBreakpoint={0.85}
    >
      <IonPage className="remove-code-page">
        <IonHeader mode="ios" className="remove-code-header">
          <IonToolbar className="remove-code-toolbar">
            <IonTitle className="remove-code-title">
              Remove procedure codes
            </IonTitle>
            <IonButtons>
              <IonButton
                onClick={(e) => {
                  setIsOpen(false);
                }}
              >
                <IonIcon
                  className="remove-code-back"
                  icon={chevronBackOutline}
                />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className="remove-code-content">
          <div className="content-header">
            <IonText className="content-header-text">
              Tap to select codes
            </IonText>
          </div>
          <div className="content-list">
            {selectedCodes?.map((item: procedure, i: any) => {
              let isSelected = thisSelectedCodes.includes(item);
              return (
                <div
                  className={
                    isSelected
                      ? "content-list-item selected"
                      : "content-list-item"
                  }
                  key={i}
                  onClick={() => {
                    onSelect(item);
                  }}
                >
                  <div className="favourite-btn-container">
                    <IonIcon
                      className={`${item.favorite && "favourite"}`}
                      icon={
                        item?.favorite === true
                          ? star
                          : !darkThemeBoolean
                          ? starOutline
                          : star
                      }
                    />
                  </div>
                  <div className="procedure-info">
                    <div className="procedure-title">{item.code}</div>
                    <div className="procedure-desc">{item.description}</div>
                  </div>
                  <div className="checked-marked-container">
                    {isSelected && <IonIcon icon={checkmarkOutline} />}
                  </div>
                </div>
              );
            })}
          </div>
          <IonFooter className="remove-code-footer ion-no-border">
            <IonButton
              className="remove-code-button"
              onClick={() => {
                removeSelected();
                setIsOpen(false);
              }}
            >
              <IonIcon icon={closeOutline} size="12" />
              <p className="remove-txt">Remove procedure</p>
              <div className="remove-num">{thisSelectedCodes.length}</div>
            </IonButton>
          </IonFooter>
        </IonContent>
      </IonPage>
    </IonModal>
  );
};

export default RemoveProcedures;
