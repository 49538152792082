/* eslint-disable react-hooks/exhaustive-deps */
import { IonIcon, IonRow } from "@ionic/react";
import { checkmark } from "ionicons/icons";
import "./Payment.scss";

import {
  flagOutline,
  heartOutline,
  personOutline,
  starOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";

interface PaymentProgressPropsType {
  status: string;
}

const PaymentProgress: React.FC<any> = (props: PaymentProgressPropsType) => {
  const [data, setData] = useState([
    { name: "bill", success: false, icon: starOutline },
    { name: "insurance", success: false, icon: heartOutline },
    { name: "patient", success: false, icon: personOutline },
    { name: "settled", success: false, icon: flagOutline },
  ]);

  useEffect(() => {
    let newArray = [...data];
    switch (props.status) {
      case "Bill":
        const index = 0;
        newArray[index].success = true;
        setData(newArray);
        break;

      case "Insurance":
        for (let i = 0; i <= 1; i++) {
          let index = i;
          newArray[index].success = true;
        }
        setData(newArray);
        break;

      case "Patient":
        for (let i = 0; i <= 2; i++) {
          let index = i;
          newArray[index].success = true;
        }
        setData(newArray);
        break;

      case "Settled":
        for (let i = 0; i < data.length; i++) {
          let index = i;
          newArray[index].success = true;
        }
        setData(newArray);
        break;

      default:
        return;
    }
  }, [props.status]);

  return (
    <IonRow className="big-screen payment-progress">
      {data.map((item: any, index: number) => (
        <>
          <div key={`${item.name} ${index}`}>
            <div
              className={`icon-container-main ${
                item.success
                  ? "sucessfull-icon-container"
                  : "unsucessfull-icon-container"
              }`}
            >
              {item.success && (
                <IonIcon className="success-tick" icon={checkmark}></IonIcon>
              )}
              <span className="icon-container-sub">
                <IonIcon
                  className={`iconic ${item.success && "sucessfull-icon"}`}
                  icon={item.icon}
                ></IonIcon>
              </span>
            </div>
            <div
              className={`icon-label ${
                item.success ? "sucessfull-label" : "unsucessfull-label"
              }`}
            >
              {item.name}
            </div>
          </div>
          {index < data.length - 1 && (
            <div
              className={`process-path ${
                item.success === true && data[index + 1].success === true
                  ? "sucessfull-progressPath"
                  : "unsucessfull-progressPath"
              }`}
            ></div>
          )}
        </>
      ))}
    </IonRow>
  );
};

export default PaymentProgress;
