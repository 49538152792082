
let practiceProvider: any = [];

let mappedProvidersObject: any = {};
let mappedFacilitiesObject: any = {};
let mappedFacilitiesNameObject: any = {};

export const GetNames = (x: any) => {
    practiceProvider = x;
    const mappedproviders: any = practiceProvider?.mappedProviders;
    const mappedFacilities: any = practiceProvider?.mappedFacilities;
    if (practiceProvider && practiceProvider.mappedProviders) {
        for (let provider of mappedproviders) {
            mappedProvidersObject[provider._id] = provider.organization_name ? provider.organization_name : provider.last_name + ', ' + provider.first_name;
        }
        for (let facility of mappedFacilities) {
            mappedFacilitiesObject[facility._id] = {
                name: facility.name,
                place_of_service: facility.place_of_service
            };
            mappedFacilitiesNameObject[facility.name] = {
                name: facility.name,
                place_of_service: facility.place_of_service
            };
        }
    }
    //mappedProvidersObject = mappedProvidersObject;
    //mappedFacilitiesObject = mappedFacilitiesObject;
}
export const getProvidername = (_id: string) => {
    // console.log(_id, mappedProvidersObject[_id])
    return mappedProvidersObject[_id] ? mappedProvidersObject[_id] : 'Others';
}
export const getFacilityname = (_id: string) => {
    // console.log(_id, mappedFacilitiesObject[_id]);
    return mappedFacilitiesObject[_id] ? mappedFacilitiesObject[_id].name : 'Others';
}
export const getPlaceOfservice = (_id: string) => {

    return (mappedFacilitiesNameObject[_id] && mappedFacilitiesNameObject[_id].place_of_service) ? mappedFacilitiesNameObject[_id].place_of_service : 'Others';

}