import React, { useEffect } from "react";
import "./PatientList.scss";
import Sort from "../../../../../../assets/icons/sort-three-line.svg";

import { useState } from "react";

import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import closeDark from "../../../../../../assets/icons/Close-Dark.svg";
import PatientCards from "./Components/PatientCard/PatientCard";
import { IconSvg } from "../../../../components/Common/IconSvg/IconSvg";
import { SortIcon } from "../../../../icons/icons";

import SearchBox from "../../../../components/Common/SearchBox/SearchBox";
import Button from "../../../../components/Common/Buttons/Buttons";
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";

import { chevronForwardOutline, close } from "ionicons/icons";
import CheckBox from "../../../../components/Common/CheckBox/CheckBox";

import { pencilSharp } from "ionicons/icons";
import { debounce } from "underscore";
import CalendarMobiscroll from "../../MobiscrollCalendar";
import { setActiveFacility } from "../../../../store/reducers/dashboard";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getDailysummary } from "../../../../helper/backendHelper";
import { VisitsByServicedate } from "../../../../types/VisitsByServicedate";

interface PatientListProps {
  allFacilitiesList: any;
  patients: VisitsByServicedate[];
}
const PatientList: React.FC<PatientListProps> = ({
  allFacilitiesList,
  patients,
}) => {
  const dispatch = useAppDispatch();
  const [filterModalShow, setFilterModalShow] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const { activeFacility } = useAppSelector((state) => state.dashboard);
  const [data, setData] = useState<any[]>([]);
  const [inPatients, setInPatients] = useState<any>([]);
  const [patientStatus, setPatientStatus] = useState([
    { id: "0", name: "Assigned to me", selected: false, count: 0 },
    { id: "1", name: "Admitted", selected: false, count: 0 },
    { id: "2", name: "Discharged", selected: false, count: 0 },
    // { id: '3', name: 'New', selected: false, count: 0 },
  ]);
  const [calenderType, setCalenderType] = useState("week");
  // const inPatients: any = [
  //   {
  //     patient_lastname: "Baker",
  //     patient_firstname: "Neomi",
  //     status: "Bill rejected",
  //     place_of_service: "194",
  //     diagnosis_code_primary: {
  //       code: "N18.9",
  //       description: "Chronic kidney disease",
  //     },
  //     statusGroup: "Incomplete",
  //     admission_date: new Date("December 17, 1995 03:24:00"),
  //   },
  //   {
  //     patient_lastname: "Anderson",
  //     patient_firstname: "Olivia",
  //     status: "Admitted",
  //     place_of_service: "194",
  //     diagnosis_code_primary: {
  //       code: "MF18.9",
  //       description: "Chronic kidney disease",
  //     },
  //     statusGroup: "Completed",
  //     admission_date: new Date("December 17, 1995 03:24:00"),
  //   },
  //   {
  //     patient_lastname: "Darring",
  //     patient_firstname: "Robert",
  //     status: "Admitted",
  //     place_of_service: "23",
  //     diagnosis_code_primary: {
  //       code: "N9385",
  //       description: "Chronic kidney disease",
  //     },
  //     statusGroup: "Incomplete",
  //     admission_date: new Date("December 17, 1995 03:24:00"),
  //   },
  //   {
  //     patient_lastname: "Harris",
  //     patient_firstname: "John",
  //     status: "New Intake",
  //     place_of_service: "194",
  //     diagnosis_code_primary: {
  //       code: "",
  //       description: "",
  //     },
  //     statusGroup: "",
  //     admission_date: "",
  //   },
  //   {
  //     patient_lastname: "Baker",
  //     patient_firstname: "Neomi",
  //     status: "Bill regected",
  //     place_of_service: "194",
  //     diagnosis_code_primary: {
  //       code: "N18.9",
  //       description: "Chronic kidney disease",
  //     },
  //     statusGroup: "Incomplete",
  //     admission_date: new Date("December 17, 1995 03:24:00"),
  //   },
  // ];

  const toggleEvent = () => {
    setCalenderType(calenderType === "week" ? "month" : "week");
  };

  useEffect(() => {
    dispatch(setActiveFacility(allFacilitiesList[0]?.facility_id));
  }, [allFacilitiesList]);

  const handleFacilitySelect = (facilityId: any) => {
    dispatch(setActiveFacility(facilityId));
  };

  useEffect(() => {
    let searchResult = patients.filter(
      (item) => item.facility_id === activeFacility
    );
    setInPatients(searchResult);
  }, [patients, activeFacility]);

  return (
    <div className="patient-list">
      <div className="header-container">
        <span>
          <span className="header-component">Patient List</span>
          <span className="header-count">{inPatients?.length}</span>
        </span>
      </div>

      <div className="facility-holder">
        {/* <div className="facility-scroll-holder">
          <div className="facility-scroll">
            <div className="facility-item">
              <div className="title">Bushwood Medical Center</div>
              <div className="count">89</div>
            </div>
            <div className="facility-item">
              <div className="title">Bushwood Medical Center</div>
              <div className="count">89</div>
            </div>
            <div className="facility-item">
              <div className="title">Bushwood Medical Center</div>
              <div className="count">89</div>
            </div>
          </div>
        </div> */}

        <IonList className="facility-list">
          <IonItem className="facility-item">
            <IonSelect
              className="facility-select "
              interface="popover"
              placeholder={allFacilitiesList[0]?.name}
              onIonChange={(e) => handleFacilitySelect(e.detail.value)}
            >
              {allFacilitiesList.map((facility: any) => (
                <IonSelectOption value={facility?.facility_id}>
                  {facility?.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonList>

        {/* <div className="action">
          <div className="edit-btn">
            <IonIcon
              onClick={() => console.log("hi")}
              className="close-icon"
              icon={pencilSharp}
            />
          </div>
        </div> */}
      </div>
      <div className="dashboard-calendar-holder">
        <CalendarMobiscroll calendarType={calenderType} />
        {calenderType === "week" ? (
          <IonText className="ion-text-center iconFilter">
            <span>monthly view </span>
            <IonIcon
              onClick={() => toggleEvent()}
              className="backwardIconDownFilter"
              icon={chevronForwardOutline}
            />
          </IonText>
        ) : (
          <IonText className="ion-text-center iconFilter">
            <span>weekly view </span>
            <IonIcon
              onClick={() => toggleEvent()}
              className="backwardIconFilter"
              icon={chevronForwardOutline}
            />
          </IonText>
        )}
      </div>
      <div className="search-bar-header-dashboard">
        <div className="search-box-container-Header">
          <div className="search-box-census-Header">
            <SearchBox
              variant="search-holder-dashboard"
              onChange={debounce(setSearchText, 1000)}
              placeholder={"Search patient"}
              value={searchText}
            />
          </div>
          <div>
            <div className="filter-icon-container">
              <div
                className="filter-padding"
                onClick={() => setFilterModalShow(true)}
              >
                <IconSvg Icon={SortIcon} />
              </div>
              <div className="filter-small-icon-one"></div>
            </div>
          </div>
        </div>
      </div>

      {inPatients?.length > 0 ? (
        <div className="patient-list-card-scroll">
          {inPatients?.map((item: any) => (
            <PatientCards key={item?.id} patient={item} />
          ))}
        </div>
      ) : (
        <div className="noResults-list">
          <p>No results found</p>
        </div>
      )}

      <IonModal
        className="add-filter-modal"
        isOpen={filterModalShow}
        onDidDismiss={() => {
          setFilterModalShow(false);
        }}
        breakpoints={[0, 0.3, 0.4, 1]}
        initialBreakpoint={0.5}
        backdropBreakpoint={0.2}
      >
        <IonContent className="newfilter-opened-modal">
          <div className="header-holder-filter">
            <div className="title-holder-filter">
              <IonLabel className="labelTitle-filter">Filter setting</IonLabel>
            </div>
            <div className="btn-holder-close">
              <IonIcon
                icon={close}
                className="close-icon-filter"
                onClick={() => setFilterModalShow(false)}
              ></IonIcon>
            </div>
          </div>

          <div>
            <div className="c-facility-wraper ">
              <IonList className="ionlist-filter">
                <div className="item-list">
                  <div className="filter-checkbox">
                    <CheckBox />
                  </div>
                  <IonLabel className="item-label">All</IonLabel>
                </div>
                {patientStatus.map((item) => (
                  <div className="item-list">
                    <div className="filter-checkbox">
                      <CheckBox />
                    </div>
                    <IonLabel className="item-label"> {item.name}</IonLabel>
                  </div>
                ))}
              </IonList>
            </div>
          </div>

          <div className="btn-holder-filters">
            <Button
              fullWidth
              boldfont
              color="primary"
              onClick={() => setFilterModalShow(false)}
            >
              Set filters
            </Button>
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
};

export default PatientList;
