import { useIonRouter } from "@ionic/react";
import React from "react";
import Loader from "../../../components/Common/Loaders/Loader";
import PatientItem from "./PatientItem";
import "./PatientList.scss";

interface PatientListProps {
  items?: any;
  isLoading?: boolean;
}

const PatientList: React.FC<PatientListProps> = (props: any) => {
  const router = useIonRouter();
  return (
    <div className="patient-list">
      <div style={{ clear: "both" }}>
        <p className="ion-issues-text alignleft-patient card-title">
          Issues <big className="patient-count">{props?.items?.issueCount}</big>{" "}
        </p>
        {props?.isLoading === true ? (
          <p className="ion-seemore-text alignright-patient"></p>
        ) : props?.isLoading === false &&
          props?.items?.providerHold?.length > 0 ? (
          <p
            className="ion-seemore-text alignright-patient"
            onClick={(e) => {
              router.push("/providerhold", "back");
            }}
          >
            See more
          </p>
        ) : props?.isLoading === false &&
          props?.items?.providerHold?.length === 0 ? (
          <></>
        ) : (
          <></>
        )}
      </div>
      {props?.isLoading === true ? (
        <div className="emptyPatients">
          <Loader
            data={{
              name: "crescent",
              color: "primary",
            }}
          />
        </div>
      ) : props?.isLoading === false &&
        props?.items?.providerHold?.length > 0 ? (
        props.items.providerHold?.slice(0, 2)?.map((PData: any, idx: any) => (
          <div key={idx}>
            <PatientItem
              key={PData?._id}
              name={PData?.patient_name}
              patientID={PData?._id}
              ptext1={
                PData?.reason_codes?.length > 1
                  ? PData?.reason_codes[0] +
                    " +" +
                    (PData?.reason_codes.length - 1)
                  : PData?.reason_codes
              }
              ptext2={PData?.ptext2}
              pnumber={PData?.procedure_code_primary?.code}
            />
          </div>
        ))
      ) : props?.isLoading === false &&
        props?.items?.providerHold?.length === 0 ? (
        <div className="emptyPatients">
          <p className="list__fallback">No result found</p>
        </div>
      ) : (
        <div className="emptyPatients"></div>
      )}
    </div>
  );
};

export default PatientList;
