import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonPage,
  IonMenuButton,
  useIonRouter,
  IonButtons,
  IonText,
  IonIcon
} from "@ionic/react";
import "../DashBoard/DashBoard.scss";
import Button from "../../components/Common/Buttons/Buttons";
import CardDisplay from "./CardDisplay/CardDisplay";
import Issues from "./PatientIssues/PatientList";
import Facilities from "./Facilities/Facilities";
import ForYou from "./ForYou/ForYou";
import BottomCards from "./BottomCards/BottomCardsList";
import { Datepicker } from "@mobiscroll/react";
import moment from "moment";
import { IconSvg } from "../../components/Common/IconSvg/IconSvg";
import { ChartIcon, MailIcon, Pencil } from "../../icons/icons";
import {
  getFacilities,
  getProviderHold,
  //getproviderholdCount,
} from "../../helper/backendHelper";
import {
  starOutline
} from "ionicons/icons";
import { useAppSelector } from "../../store/hooks";
import AddendumPopup from "../../components/Census/AddendumPopup";
import FeatureProvider from "../../components/PermissionProvider/FeatureProvider";
import AddendumModal from "../../components/Census/Addendum";


const TOPCARD_DETAILS = [
  {
    id: "1",
    ptext1: "Estimated income",
    ptext2: "Pending income",
    count_icon: "$",
    scope: "currentpractice",
  },
  {
    id: "2",
    ptext1: "Completed encounters",
    ptext2: "Pending encounters",
    count_icon: "",
    scope: "currentpractice",
  },
  {
    id: "3",
    ptext1: "Estimated income",
    ptext2: "Pending income",
    count_icon: "$",
    scope: "currentprovider",
  },
  {
    id: "4",
    ptext1: "Completed encounters",
    ptext2: "Pending encounters",
    count_icon: "",
    scope: "currentprovider",
  },
];
const DUMMY_DETAILS = [
  {
    id: "1",
    name: "Cook, Barbara | 0785220",
    ptext1: "Missing demographic data",
    ptext2: "$140 receivables missing",
    pnumber: "99231",
  },
  {
    id: "2",
    name: "Abbot, Naomi | 0705529",
    ptext1: "Missing diagnosis",
    ptext2: "apr. $64 receivables missing",
    pnumber: "99231",
  },
];

const Facility_DETAILS = [
  {
    id: "1",
    Fname: "Bushwood Medical Center",
    FacilityImg: "./assets/icon/medikit.png",
  },
  {
    id: "2",
    Fname: "Bushwood Medical Center Rehab",
    FacilityImg: "./assets/icon/sitting.png",
  },
  {
    id: "3",
    Fname: "Bushwood Medical Center",
    FacilityImg: "./assets/icon/medikit.png",
  },
  {
    id: "4",
    Fname: "Bushwood Medical Center Rehab",
    FacilityImg: "./assets/icon/sitting.png",
  },
  {
    id: "4",
    Fname: "Bushwood Medical Center",
    FacilityImg: "./assets/icon/medikit.png",
  },
  {
    id: "5",
    Fname: "Bushwood Medical Center Rehab",
    FacilityImg: "./assets/icon/sitting.png",
  },
  {
    id: "6",
    Fname: "Bushwood Medical Center",
    FacilityImg: "./assets/icon/medikit.png",
  },
  {
    id: "7",
    Fname: "Bushwood Medical Center Rehab",
    FacilityImg: "./assets/icon/sitting.png",
  },
  {
    id: "8",
    Fname: "Bushwood Medical Center",
    FacilityImg: "./assets/icon/medikit.png",
  },
  {
    id: "9",
    Fname: "Bushwood Medical Center Rehab",
    FacilityImg: "./assets/icon/sitting.png",
  },
  {
    id: "10",
    Fname: "Bushwood Medical Center",
    FacilityImg: "./assets/icon/medikit.png",
  },
  {
    id: "11",
    Fname: "Bushwood Medical Center Rehab",
    FacilityImg: "./assets/icon/sitting.png",
  },
];

const BottomCard_DETAILS = [
  {
    id: "1",
    Bname: "Are you tired of administrating?",
    Btext: "Try Claimocity Pro!",
  },
  {
    id: "2",
    Bname: "How to use the ICD lookup?",
    Btext: "3 minutes",
  },

  {
    id: "3",
    Bname: "How to use the ICD lookup?",
    Btext: "3 minutes",
  },
  {
    id: "4",
    Bname: "Are you tired of administrating?",
    Btext: "Try Claimocity Pro!",
  },
  {
    id: "5",
    Bname: "How to use the ICD lookup?",
    Btext: "3 minutes",
  },

  {
    id: "6",
    Bname: "How to use the ICD lookup?",
    Btext: "3 minutes",
  },
  {
    id: "7",
    Bname: "Are you tired of administrating?",
    Btext: "Try Claimocity Pro!",
  },
  {
    id: "8",
    Bname: "How to use the ICD lookup?",
    Btext: "3 minutes",
  },

  {
    id: "9",
    Bname: "How to use the ICD lookup?",
    Btext: "3 minutes",
  },
];
interface AddendumModalprops {
  isFilterOpen: boolean;
  handleFilterModal: (item: boolean) => void;
  handleFilterChange: (sort: String) => any;
  data: any;
  selectedFilterOptionList?: any;

}

const DashBoard: React.FC = () => {
  let startDate = moment().startOf("week").format("YYYY-MM-DD");
  let endDate = moment().endOf("week").format("YYYY-MM-DD");

  const [dummyData, setDummyData] = useState(DUMMY_DETAILS);
  const [topCardItems, setTopCardItems] = useState(TOPCARD_DETAILS);
  const [facilityData, setFacilityData] = useState(Facility_DETAILS);
  const [bottomCardsData, setBottomCardsData] = useState(BottomCard_DETAILS);
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState([
    startDate,
    endDate,
  ]);
  const [issuesCount, setIssuesCount] = useState<number>(0);
  const [data, setData] = useState<any>();
  const [facilityIsLoading, setFacilityIsLoading] = useState<boolean>(false);
  const [providerHoldIsLoading, setProviderHoldIsLoading] =
    useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);


  const router = useIonRouter();
  const selectedProvider = useAppSelector((state) => state.user)?.provider;
  const userData = useAppSelector((state) => state.user);

  const [addendumOpen, setAddendumOpen] = useState(false);

  const handleAddendumModal = () => {
    setAddendumOpen(false);
  };
  const pickerChange = (ev: any) => {
    setSelectedDateRange(ev.value);
  };

  const show = () => {
    setDatePickerOpen(true);
  };
  const onClose = () => {
    setDatePickerOpen(false);
  };

  const onGoToCensus = () => {
    router.push("census", "back");
  };

  const onGoToMessaging = () => {
    router.push("/messagelist");
  };

  const onGoToPracticeIQ = () => {
    router.push("/practice-iq");
  };

  const getFacilityList = async () => {
    setFacilityIsLoading(true);
    let res = await getFacilities();
    setFacilitiesList(res?.results);
    setFacilityIsLoading(false);
  };

  // const getIssuesCount = async () => {
  //   let res = await getproviderholdCount();
  //   if (res.status === "success") {
  //     setIssuesCount(res.result);
  //   }
  // };
  const thisPateint = useAppSelector((state) => state.patientDetails);

  const patientListHandler = async () => {
    setProviderHoldIsLoading(true);
    try {
      let res = await getProviderHold({ startIndex: 0, recordCount: 2, scope: "provider" });
      setData(res.data.result);
      setIssuesCount(res.data.total);
    } catch (error) { }
    setProviderHoldIsLoading(false);
  };

  useEffect(() => {
    getFacilityList();
    // getIssuesCount();
  }, []);
  useEffect(()=>{
    patientListHandler();
  },[thisPateint.thisPatient?.procedure_code_primary])
  const handleFilterChange = (text: String) => {


  }
  const handleAddendumModal1 = (isShow: boolean) => {
    setIsPopupOpen(isShow)
  }
  const data1 = {
    name: "Anderson, Olivia",
    dob: "10/15/1954",
    serviceDate: "7/14/2022",
    Facility: "Bushwood Medical Center",
    signedBy: "Documentum e-signed by Al Czervik"
  }

  return (
    <IonPage className="ion-page-dashboard">
      <IonHeader className="ion-header-dashboard d-pad-05">
        <IonToolbar className="ion-toolbar-dashboard" mode="ios">
          <IonMenuButton slot="start" className="ion-icon-size-dashboard" />
          <IonTitle className="title-header">
            {selectedProvider?.organization_name}
          </IonTitle>
          <IonButtons slot="end" className="action-holder">
            
            <div onClick={() => onGoToPracticeIQ()}>
              <IconSvg Icon={ChartIcon} />
            </div>
            <FeatureProvider
              children={
                <div onClick={() => onGoToMessaging()}>
                  <IconSvg Icon={MailIcon} />
                </div>
              }
              feature={"Messaging"}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="chart-card d-pad dash-row">
          {isDatePickerOpen && (
            <Datepicker
              controls={["calendar"]}
              showOnClick={false}
              showOnFocus={false}
              isOpen={isDatePickerOpen}
              onClose={onClose}
              touchUi={true}
              select="range"
              onChange={pickerChange}
              // value={moment(new Date())}
              value={selectedDateRange}
              dateFormat="YYYY-MM-DD"
              returnFormat="locale"
            />
          )}

          <div className="ion-toolbar-dashboard my-1">
            <div className="card-title">This week</div>
            {/* <div className="icon-holder" onClick={() => show()}>
              <IconSvg Icon={CalendarIcon} />
            </div> */}
          </div>

          <CardDisplay items={topCardItems} />
        </div>

        <div className="issue-card d-pad dash-row">
          <Issues
            items={{ issueCount: issuesCount, providerHold: data }}
            isLoading={providerHoldIsLoading}
          />
        </div>

        <div className="facilities-card s-pad dash-row">
          <Facilities items={facilitiesList} isLoading={facilityIsLoading} />
        </div>

        <div className="for-you-card d-pad dash-row">
          <ForYou />
        </div>

        <BottomCards items={bottomCardsData} />

        <div className="btn-holder-dashboard">
          <Button onClick={onGoToCensus} fullWidth>
            <IonText className="btn-dashboard-text">Go to Census</IonText>
          </Button>
        </div>
      </IonContent>

      <AddendumPopup addendumOpen={addendumOpen} closeModal={handleAddendumModal} />
      <AddendumModal
        handleFilterModal={handleAddendumModal1}
        isFilterOpen={isPopupOpen}
        handleFilterChange={handleFilterChange}
        data1={data1}
      />



    </IonPage>
  );
};

export default DashBoard;
