/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChartPayloadType } from "../../types/ChartPayloadType";
import { MedicalchartTemplatesType } from "../../types/MedicalchartTemplatesType";
import { MIPSType } from "../../types/MIPSType";
import { PatientDetailsType } from "../../types/PatientDetailsType";
import { VisitDetailType } from "../../types/VisitDetailType";
import { getChartData, getInitialPayload } from "../thunk/progressNoteThunk";

let progressBarItems = {
  data: [
    {
      id: "0",
      name: "Encounter info",
      completed: true,
      content: ["Date of Service", "Diagnosis codes", "Procedure codes"],
    },
    {
      id: "1",
      name: "Progress note",
      completed: false,
      content: ["Template", "Chief complaint"],
    },
    {
      id: "2",
      name: "Subjective",
      completed: false,
      content: [
        "HPI",
        "Past medical Hx",
        "Famaly medical Hx",
        "Social history",
      ],
    },
    {
      id: "3",
      name: "Objective",
      completed: false,
      content: ["Review of system", "Physical exam", "Date section"],
    },
    {
      id: "4",
      name: "Assesment",
      completed: false,
      content: ["Procedure", "Status and management plan"],
    },
    {
      id: "5",
      name: "Plan",
      completed: false,
      content: ["E&M and procedure codes", "MIPS", "Additional info"],
    },
  ],
  activeId: "1",
};

type ProgressNoteState = {
  isloading: boolean;
  isError: boolean;
  errorMsg: any;
  progressBarItems: any;
  isHaveChart: boolean;
  thisPatient: PatientDetailsType | null;
  medicalchartTemplates: MedicalchartTemplatesType[] | null;
  selectedTemplate: MedicalchartTemplatesType | null;
  diagnosisCodesSelected: any[];
  procedureCodesSelected: any[];
  finalPayload: ChartPayloadType | null;
  mips: MIPSType[];
  progressImg: string | null;
  visitDetails: VisitDetailType | null;
  isInputDisable: boolean;
  isValidate: boolean;
};

const initialState: ProgressNoteState = {
  isloading: true,
  isError: false,
  errorMsg: "",
  progressBarItems,
  isHaveChart: false,
  thisPatient: null,
  progressImg: null,
  medicalchartTemplates: null,
  selectedTemplate: null,
  diagnosisCodesSelected: [],
  procedureCodesSelected: [],
  finalPayload: null,
  visitDetails: null,
  mips: [],
  isInputDisable: false,
  isValidate: true,
};

export const progressNoteSlice = createSlice({
  name: "progressNote",
  initialState,
  reducers: {
    setProgressBarItems: (state, action) => {
      state.progressBarItems = action.payload;
    },
    setSelectedTemplate: (state, action) => {
      let newTemplateData = action.payload?.data?.map((item: any) => {
        if (item?.title === "MIPS" && item?.sections?.length < 1) {
          return { ...item, sections: state.mips };
        } else {
          return item;
        }
      });

      state.selectedTemplate = { ...action.payload, data: newTemplateData };
    },
    setHaveChart: (state, action) => {
      state.isHaveChart = action.payload;
    },
    setInvalide: (state, action) => {
      state.isValidate = action.payload;
    },

    setProcedureCode: (state, action: PayloadAction<any>) => {
      state.procedureCodesSelected = action.payload;
    },

    setDiagnosisCode: (state, action: PayloadAction<any>) => {
      action.payload.forEach((item: any) => {
        if (
          state.diagnosisCodesSelected
            .map((item) => item.code)
            .includes(item.code)
        ) {
          state.diagnosisCodesSelected = state.diagnosisCodesSelected.filter(
            (element) => element.code !== item.code
          );
        } else
          state.diagnosisCodesSelected = [
            ...state.diagnosisCodesSelected,
            item,
          ];
      });
    },

    handleRegroupCode: (state, action: any) => {
      if (action.payload.code === "diagnosis") {
        let newArray = [...state.diagnosisCodesSelected];
        const element = newArray.splice(action.payload.from, 1)[0];
        newArray.splice(action.payload.to, 0, element);
        state.diagnosisCodesSelected = [...newArray];
      }

      if (action.payload.code === "procedure") {
        let newArray = [...state.procedureCodesSelected];
        const element = newArray.splice(action.payload.from, 1)[0];
        newArray.splice(action.payload.to, 0, element);
        state.procedureCodesSelected = [...newArray];
      }
    },
    resetProgressNoteState: (state) => {
      state.selectedTemplate = initialState.selectedTemplate;
      state.diagnosisCodesSelected = initialState.diagnosisCodesSelected;
      state.errorMsg = initialState.errorMsg;
      state.isError = initialState.isError;
      state.isloading = initialState.isloading;
      state.medicalchartTemplates = initialState.medicalchartTemplates;
      state.procedureCodesSelected = initialState.procedureCodesSelected;
      state.progressBarItems = initialState.progressBarItems;
      state.selectedTemplate = initialState.selectedTemplate;
      state.thisPatient = initialState.thisPatient;
      state.finalPayload = initialState.finalPayload;
      state.isInputDisable = initialState.isInputDisable;
      state.isValidate = initialState.isValidate;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitialPayload.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(getInitialPayload.fulfilled, (state, action) => {
        state.isloading = false;
        state.progressImg = action.payload?.progressImg
          ? action.payload?.progressImg
          : null;
        state.thisPatient = action.payload?.patientDetail
          ? action.payload?.patientDetail
          : null;
        state.diagnosisCodesSelected = action.payload?.diagnosisData
          ? action.payload?.diagnosisData
          : [];
        state.procedureCodesSelected = action.payload?.proceduresData
          ? action.payload?.proceduresData
          : [];
        state.visitDetails = action.payload?.visitDetail
          ? action.payload?.visitDetail
          : null;
        state.finalPayload = action.payload?.chartData
          ? action.payload?.chartData
          : null;

        state.medicalchartTemplates = action.payload?.templates;
        state.mips = action.payload?.mips;

        if (action.payload?.chartData) {
          state.isHaveChart = true;
          // console.log(action.payload?.chartData?.signed_on);

          state.isInputDisable = action.payload?.chartData?.signed_on
            ? true
            : false;
          let thisTemp = action.payload?.templates?.find(
            (item: any) => item.template === action.payload?.chartData?.template
          );
          if (thisTemp) {
            state.selectedTemplate = {
              ...thisTemp,
              data: action.payload?.chartData?.data,
            };
          }
        }
        state.isloading = false;
      })
      .addCase(getInitialPayload.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload;
        } else {
          state.errorMsg = action.error.message?.toString();
        }
      })

      .addCase(getChartData.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(getChartData.fulfilled, (state, action) => {
        state.isloading = false;
        state.progressImg = action.payload?.progressImg
          ? action.payload?.progressImg
          : null;
      })
      .addCase(getChartData.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload;
        } else {
          state.errorMsg = action.error.message?.toString();
        }
      });
  },
});

export const {
  setProgressBarItems,
  setSelectedTemplate,
  setProcedureCode,
  setDiagnosisCode,
  setHaveChart,
  handleRegroupCode,
  resetProgressNoteState,
  setInvalide,
} = progressNoteSlice.actions;
export const progressNoteReducer = progressNoteSlice.reducer;
