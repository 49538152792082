import io from "socket.io-client";
import { ENV } from "./env";

const socket = (io(ENV.SocketUrl+'conversation',
{transports: ['polling']}
) as any);
const socket1 = (io(ENV.SocketUrl+'visit',
{transports: ['polling']}
) as any);


export { socket, socket1 };

export const disconnectSocket = () => {
   
  if (socket) socket.disconnect();
  
  if(socket1) socket1.disconnect();
}