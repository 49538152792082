import React from "react";
import { CalendarNav } from "@mobiscroll/react";
import { Datepicker, CalendarPrev, CalendarNext } from "@mobiscroll/react";
import "./Census.scss";
import { IonCol, IonRow } from "@ionic/react";
interface CalendarCensusPageProps {
  item?: any;
  data?: any[];
  handleMonthChange?: (args: any) => void;
  handleDateChange?: (date: any) => void;
  dateValue?: string;
  selectedFacility: string;
}

const CalendarCensusPage: React.FC<CalendarCensusPageProps> = (props: any) => {
  let callendarData: any[] = [];
  let labelData: any[] = [];

  const data =
    props.selectedFacility === ""
      ? props.data
      : props.data.filter(
          (item: any) => item.facility_id === props.selectedFacility
        );

  data.forEach((item: any) => {
    callendarData = [
      ...callendarData,
      { date: item?.service_date_from, color: item?.color },
    ];
  });

  data.forEach((item: any) => {
    labelData = [
      ...labelData,
      { date: item?.service_date_from, color: item?.color, text: item?.count },
    ];
  });

  callendarData = [
    ...callendarData,
    {
      recurring: {
        repeat: "yearly",
        month: 4,
        day: 1,
      },
      color: "ffc400",
    },
  ];

  const handleMonthChange = (args: any, init: any) => {
    props.handleMonthChange(args);
  };

  const handleDateSelect = (e: any) => {
    if (props.handleDateChange) props.handleDateChange(e.date);
  };

  const customWithNavButtons = () => {
    return (
      <>
        <div>
          <IonRow class="ion-no-margin ion-no-padding">
            <IonCol class="ion-align-self-start" size="1">
              <CalendarPrev />
            </IonCol>
            <IonCol class="ion-align-self-center">
              <CalendarNav />
            </IonCol>
            <IonCol class="ion-align-self-end" size="1">
              <CalendarNext />
            </IonCol>
          </IonRow>
        </div>
      </>
    );
  };

  return (
    <>
      {props.selectedFacility === "" ? (
        <Datepicker
          className="calendardiv-encounterHistory"
          controls={["calendar"]}
          display="inline"
          calendarType={"month"}
          pages={1}
          renderCalendarHeader={customWithNavButtons}
          showWeekNumbers={false}
          touchUi={true}
          value={props.dateValue ? props.dateValue : ""}
          showOuterDays={true}
          showRangeLabels={false}
          onPageChange={handleMonthChange}
          onCellClick={handleDateSelect}
          responsive={{
            custom: {
              // Custom breakpoint
              // breakpoint: 2000,
              display: "center",
            },
          }}
          marked={callendarData}
        />
      ) : (
        <Datepicker
          className="calendardiv-encounterHistory"
          controls={["calendar"]}
          display="inline"
          calendarType={"month"}
          pages={1}
          renderCalendarHeader={customWithNavButtons}
          showWeekNumbers={false}
          touchUi={true}
          value={props.dateValue ? props.dateValue : ""}
          showOuterDays={true}
          showRangeLabels={false}
          onPageChange={handleMonthChange}
          onCellClick={handleDateSelect}
          responsive={{
            custom: {
              // Custom breakpoint
              // breakpoint: 2000,
              display: "center",
            },
          }}
          labels={labelData}
        />
      )}
    </>
  );
};

export default CalendarCensusPage;
