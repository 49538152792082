import {
  IonCol,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRadioGroup,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import ToggleButtons from "../../components/Common/ToggleButton/viewToggleButtons";
import Button from "../../components/Common/Buttons/Buttons";
import CheckBox from "../../components/Common/CheckBox/CheckBox";
import FavoriteBtn from "../../components/Common/FavoriteBtn/FavoriteBtn";
import ProgressBar from "../../components/Common/ProgressBar/ProgressBar";
import RadioButton from "../../components/Common/RadioButton/RadioButton";
import ScaleProgress from "../../components/Common/ScaleProgress/ScaleProgress";
import SharedInputFields from "../../components/Common/SharedInputFields/SharedInputFields";
import Switch from "../../components/Common/Switch/Switch";
import TypoGraphy from "../../components/Common/TypoGraphy/TypoGraphy";
import SearchBox from "../../components/Common/SearchBox/SearchBox";
import progressbarData from "../../mock/progressbarData.json";

import "./UiComponents.scss";
import { IonIcon } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
// import SelectInput from "../../components/Common/Select/SelectInput";
import { IconSvg } from "../../components/Common/IconSvg/IconSvg";
import { CensusIcon } from "../../icons/icons";
import ProcedureCode from "../Encounter/ProcedureCode/ProcedureCode";
import DiagnosisCode from "../Encounter/DiagnosisCode/DiagnosisCode";

const UiComponents: React.FC = () => {
  const processbarData = progressbarData;
  const [searchText, setSearchText] = useState(""); //state for user input in search bar
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isDModalOpen, setDModalOpen] = useState<boolean>(false);
  return (
    <IonPage className="demo-page">
      <IonHeader>
        <IonToolbar mode="ios">
          <IonMenuButton slot="start" />
          <IonTitle>Ui Components</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <ToggleButtons />
        <p style={{ fontWeight: 700 }}>Open Procedure Code</p>
        <Button
          fullWidth={false}
          color="primary"
          onClick={() => setModalOpen(!isModalOpen)}
        >
          Open Procedure Code
        </Button>

        <p style={{ fontWeight: 700 }}>Open Diagnosis Code</p>
        <Button
          fullWidth={false}
          color="primary"
          onClick={() => setDModalOpen(!isDModalOpen)}
        >
          Open Diagnosis Code
        </Button>
        {/*TypoGraphy desktop , mobile*/}
        <div style={{ padding: "1rem" }}>
          <p style={{ color: "blue", fontWeight: 700 }}>
            TypoGraphyDesktopExample
          </p>
          <div className="icon-holder">
            <IconSvg Icon={CensusIcon} />
          </div>
          <IonRow>
            <div style={{ marginBottom: 60 }}>
              <TypoGraphy displayMode="desktop" contentType="desktopHeadOne">
                Desktop H1- Gilroy Bold 48px
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginTop: 60 }}>
              <TypoGraphy displayMode="desktop" contentType="desktopHeadTwo">
                Desktop H2- Gilroy Bold 24px
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginTop: 60 }}>
              <TypoGraphy displayMode="desktop" contentType="desktopHeadThree">
                Desktop H3- Gilroy Medium 24px
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginTop: 60 }}>
              <TypoGraphy displayMode="desktop" contentType="desktopHeadFour">
                Desktop H4- Gilroy Bold 16px
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginTop: 60 }}>
              <TypoGraphy displayMode="desktop" contentType="desktopHeadFive">
                Desktop H5- Gilroy Semi Bold 14px
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginBottom: 70, marginTop: 50 }}>
              <TypoGraphy displayMode="desktop" contentType="desktopBodyOne">
                Desktop Body 1 - Gilroy Regular 16px Loremipsum dolor sit amet,
                consectetur adipiscing elit. Vivamus metus massa, iaculis quis
                commodo sit amet, pulvinar non lectus.
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginBottom: 70 }}>
              <TypoGraphy displayMode="desktop" contentType="desktopBodyTwo">
                Desktop Body 2 - Inter Regular 14px Loremipsum dolor sit amet,
                consectetur adipiscing elit. Vivamus metus massa, iaculis quis
                commodo sit amet, pulvinar non lectus.
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginBottom: 70 }}>
              <TypoGraphy displayMode="desktop" contentType="desktopSubTitle">
                Desktop Subtitle- Gilroy Light 14px Loremipsum dolor sit amet,
                consectetur adipiscing elit. Vivamus metus massa, iaculis quis
                commodo sit amet, pulvinar non lectus.
              </TypoGraphy>
            </div>
          </IonRow>
          <p style={{ color: "blue", fontWeight: 700 }}>
            TypoGraphyMobileExample
          </p>
          <IonRow>
            <div style={{ marginBottom: 50 }}>
              <TypoGraphy displayMode="mobile" contentType="mobileHeadOne">
                Mobile H1 - Gilroy Bold 20px
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginBottom: 50 }}>
              <TypoGraphy displayMode="mobile" contentType="mobileHeadTwo">
                Mobile H2- Gilroy Bold 18px
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginBottom: 50 }}>
              <TypoGraphy displayMode="mobile" contentType="mobileHeadThree">
                Mobile H3- Gilroy Medium 16px{" "}
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginBottom: 50 }}>
              <TypoGraphy
                displayMode="mobile"
                contentType="mobileHeadThreeSmall"
              >
                Mobile H3- Gilroy Medium 14px
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginBottom: 70 }}>
              <TypoGraphy displayMode="mobile" contentType="mobileBodyOne">
                Mobile Body 1 - Gilroy Regular 16px Loremipsum dolor sit amet,
                consectetur adipiscing elit. Vivamus metus massa, iaculis quis
                commodo sit amet, pulvinar non lectus.{" "}
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginBottom: 70 }}>
              <TypoGraphy displayMode="mobile" contentType="mobileBodyTwo">
                Mobile Body 2 - Gilroy Light 14px Loremipsum dolor sit amet,
                consectetur adipiscing elit. Vivamus metus massa, iaculis quis
                commodo sit amet, pulvinar non lectus.
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginBottom: 20 }}>
              <TypoGraphy displayMode="mobile" contentType="mobileSubTitle">
                Mobile Subtitle - Inter Medium 11 Loremipsum dolor sit amet,
                consectetur adipiscing elit. Vivamus metus massa, iaculis quis
                commodo sit amet, pulvinar non lectus.
              </TypoGraphy>
            </div>
          </IonRow>
          <p style={{ color: "blue", fontWeight: 700 }}>Button typography</p>
          <IonRow>
            <div style={{ marginBottom: 20 }}>
              <TypoGraphy displayMode="button" contentType="buttonText">
                Button text _Inter Semi Bold 14
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginBottom: 20 }}>
              <TypoGraphy displayMode="link" contentType="linkText">
                Link text
              </TypoGraphy>
            </div>
          </IonRow>
          <p style={{ color: "blue", fontWeight: 700 }}>Input typography</p>
          <IonRow>
            <div style={{ marginBottom: 20 }}>
              <TypoGraphy displayMode="input" contentType="inputFill">
                Input fill text _Inter Medium 14
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginBottom: 20 }}>
              <TypoGraphy displayMode="input" contentType="inputTitle">
                Input title text _Inter Medium 12
              </TypoGraphy>
            </div>
          </IonRow>
          <IonRow>
            <div style={{ marginBottom: 20 }}>
              <TypoGraphy displayMode="input" contentType="placeholder">
                Input plaeholder text _Inter Regular 12
              </TypoGraphy>
            </div>
          </IonRow>
        </div>

        {/* Scale */}
        <div style={{ padding: "1rem" }}>
          <p>Scale</p>
          <ScaleProgress min={0} max={10} defaultValue={4} />
        </div>

        {/* Search Box */}
        <div style={{ padding: "1rem" }}>
          <p>Search</p>
          <SearchBox
            placeholder="Search by code or description..."
            onChange={(value) => setSearchText(value)}
            value={searchText}
            filtOptions={["All"]}
            isEmpty={true}
            // isSearchFilter={true}
            // isError={true}
          />
          <SearchBox
            placeholder="Search by code or description..."
            onChange={(value) => setSearchText(value)}
            value={searchText}
            filtOptions={["All"]}
            // isSearchFilter={true}
          />
          <SearchBox
            placeholder="Search by code or description..."
            onChange={(value) => setSearchText(value)}
            value={searchText}
            filtOptions={["All"]}
            disabled={true}
            // isSearchFilter={true}
            // isError={true}
          />
          <SearchBox
            placeholder="Search by code or description..."
            onChange={(value) => setSearchText(value)}
            value={searchText}
            filtOptions={["All"]}
            // isSearchFilter={true}
            isError={true}
          />
          <SearchBox
            placeholder="Search by code or description..."
            onChange={(value) => setSearchText(value)}
            value={searchText}
            filtOptions={["All", "Doctor", "Patient", "Nurse"]}
            isSearchFilter={true}
            // isError={true}
          />
        </div>

        {/* RADIO BUTTONS, CHECK BOX, SWITCH, FAVORITE */}
        <div style={{ padding: "1rem" }}>
          <p>Check Box</p>
          <IonRow>
            <div className="input-holder">
              <CheckBox value="off" onCheck={(data) => {}} />
            </div>
            <div className="input-holder">
              <CheckBox value="on" onCheck={(data) => {}} checked />
            </div>
            <div className="input-holder">
              <CheckBox
                value="off - disabled"
                onCheck={(data) => {}}
                disabled
              />
            </div>
            <div className="input-holder">
              <CheckBox
                value="on - disabled"
                onCheck={(data) => {}}
                checked
                disabled
              />
            </div>
            <div className="input-holder">
              <CheckBox minus value="off" onCheck={(data) => {}} />
            </div>
            <div className="input-holder">
              <CheckBox value="on" onCheck={(data) => {}} checked minus />
            </div>
            <div className="input-holder">
              <CheckBox minus value="off" onCheck={(data) => {}} disabled />
            </div>
            <div className="input-holder">
              <CheckBox
                value="on"
                onCheck={(data) => {}}
                checked
                minus
                disabled
              />
            </div>
          </IonRow>
          <p>Radio button</p>
          <IonRow>
            <IonRadioGroup value={"on"}>
              <div className="input-holder">
                <RadioButton
                  value="on"
                  defaultChecked={true}
                  onCheck={(data) => {}}
                />
              </div>
              <div className="input-holder">
                <RadioButton value="off" onCheck={(data) => {}} />
              </div>
            </IonRadioGroup>
            <IonRadioGroup value={"on - disabled"}>
              <div className="input-holder">
                <RadioButton
                  value="on - disabled"
                  defaultChecked={true}
                  onCheck={(data) => {}}
                  disabled
                />
              </div>
              <div className="input-holder">
                <RadioButton
                  value="off - disabled"
                  onCheck={(data) => {}}
                  disabled
                />
              </div>
            </IonRadioGroup>
          </IonRow>
          <p>Switch</p>
          <IonRow>
            <div className="input-holder">
              <Switch name="on" checked onCheck={(data) => {}} />
            </div>
            <div className="input-holder">
              <Switch name="off" onCheck={(data) => {}} />
            </div>
            <div className="input-holder">
              <Switch
                name="on-disabled"
                checked
                onCheck={(data) => {}}
                disabled
              />
            </div>
            <div className="input-holder">
              <Switch name="off-disabled" onCheck={(data) => {}} disabled />
            </div>
          </IonRow>
          <p>Favorite</p>
          <IonRow>
            <div className="input-holder">
              <FavoriteBtn checked onCheck={(data) => {}} />
            </div>
            <div className="input-holder">
              <FavoriteBtn onCheck={(data) => {}} />
            </div>
            <div className="input-holder">
              <FavoriteBtn checked onCheck={(data) => {}} disabled />
            </div>
            <div className="input-holder">
              <FavoriteBtn onCheck={(data) => {}} disabled />
            </div>
          </IonRow>

          <p className="item-border-progressbar">Progress bar - vertical</p>
          <IonRow>
            <ProgressBar
              variant="vertical"
              data={processbarData}
              useFile=""
              handleNext={(data, activeId) => {}}
            />
          </IonRow>

          <p className="item-border-progressbar">Progress bar - horizontal</p>
          <IonRow>
            <ProgressBar
              variant="horizontal"
              data={processbarData}
              useFile=""
              handleNext={(data, activeId) => {}}
            />
          </IonRow>

          <p className="item-border-progressbar">
            Progress bar - horizontal detailed
          </p>
          <IonRow>
            <ProgressBar
              variant="horizontal-detailed"
              data={processbarData}
              useFile=""
              handleNext={(data, activeId) => {}}
            />
          </IonRow>
          <p>Button Large</p>
          <p>Varient:Normal</p>
          <IonRow>
            <IonCol>
              <Button fullWidth={true} color="primary">
                Primary
              </Button>
            </IonCol>
            <IonCol>
              <Button fullWidth={true} color="secondary">
                Secondary
              </Button>
            </IonCol>
            <IonCol>
              <Button fullWidth={true} color="tertiary">
                Tertiary
              </Button>
            </IonCol>
          </IonRow>
          <p>Varient:Disabled</p>
          <IonRow>
            <IonCol>
              <Button
                fullWidth={true}
                color="primary"
                variant="disabled"
                disabled={true}
              >
                Primary
              </Button>
            </IonCol>
            <IonCol>
              <Button
                fullWidth={true}
                color="secondary"
                variant="disabled"
                disabled={true}
              >
                Secondary
              </Button>
            </IonCol>
            <IonCol>
              <Button
                fullWidth={true}
                color="tertiary"
                variant="disabled"
                disabled={true}
              >
                Tertiary
              </Button>
            </IonCol>
          </IonRow>
          <p>Varient:Compressed</p>
          <IonRow>
            <IonCol>
              <Button fullWidth={true} color="primary" variant="compressed">
                Primary
              </Button>
            </IonCol>
            <IonCol>
              <Button fullWidth={true} color="secondary" variant="compressed">
                Secondary
              </Button>
            </IonCol>
            <IonCol>
              <Button fullWidth={true} color="tertiary" variant="compressed">
                Tertiary
              </Button>
            </IonCol>
          </IonRow>
          <p>Varient:Destructive</p>
          <IonRow>
            <IonCol>
              <Button fullWidth={true} color="primary" variant="destructive">
                Primary
              </Button>
            </IonCol>
            <IonCol>
              <Button fullWidth={true} color="secondary" variant="destructive">
                Secondary
              </Button>
            </IonCol>
            <IonCol>
              <Button fullWidth={true} color="tertiary" variant="destructive">
                Tertiary
              </Button>
            </IonCol>
          </IonRow>

          <p>Button Small</p>
          <p>Varient:Normal</p>
          <IonRow>
            <IonCol>
              <Button fullWidth={false} color="primary">
                Primary
              </Button>
            </IonCol>
            <IonCol>
              <Button fullWidth={false} color="secondary">
                Secondary
              </Button>
            </IonCol>
            <IonCol>
              <Button fullWidth={false} color="tertiary">
                Tertiary
              </Button>
            </IonCol>
          </IonRow>
          <p>Varient:Disabled</p>
          <IonRow>
            <IonCol>
              <Button
                fullWidth={false}
                color="primary"
                variant="disabled"
                disabled={true}
              >
                Primary
              </Button>
            </IonCol>
            <IonCol>
              <Button
                fullWidth={false}
                color="secondary"
                variant="disabled"
                disabled={true}
              >
                Secondary
              </Button>
            </IonCol>
            <IonCol>
              <Button
                fullWidth={false}
                color="tertiary"
                variant="disabled"
                disabled={true}
              >
                Tertiary
              </Button>
            </IonCol>
          </IonRow>
          <p>Varient:Compressed</p>
          <IonRow>
            <IonCol>
              <Button fullWidth={false} color="primary" variant="compressed">
                Primary
              </Button>
            </IonCol>
            <IonCol>
              <Button fullWidth={false} color="secondary" variant="compressed">
                Secondary
              </Button>
            </IonCol>
            <IonCol>
              <Button fullWidth={false} color="tertiary" variant="compressed">
                Tertiary
              </Button>
            </IonCol>
          </IonRow>
          <p>Varient:Destructive</p>
          <IonRow>
            <IonCol>
              <Button fullWidth={false} color="primary" variant="destructive">
                Primary
              </Button>
            </IonCol>
            <IonCol>
              <Button fullWidth={false} color="secondary" variant="destructive">
                Secondary
              </Button>
            </IonCol>
            <IonCol>
              <Button fullWidth={false} color="tertiary" variant="destructive">
                Tertiary
              </Button>
            </IonCol>
          </IonRow>
        </div>

        {/* Input Fields */}

        <div className="div-sharedinput">
          <p>INPUT FIELDS BIG</p>
          <p>Empty</p>
          <IonRow>
            <IonCol>
              <b className="Label-style-sharedfield">Room </b>
              <SharedInputFields
                value={""}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty"
              />
            </IonCol>
            <IonCol>
              <b className="Label-style-sharedfield">
                Room
                <IonIcon
                  slot="start"
                  icon={informationCircleOutline}
                  className="ion-icon-sharedfield"
                />
              </b>
              <SharedInputFields
                value={""}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty"
              />
            </IonCol>
          </IonRow>

          <p>Hint</p>
          <IonRow>
            <IonCol>
              <b className="Label-style-sharedfield">
                Code list for procedures & diagnosis
              </b>
              <SharedInputFields
                value={""}
                placeHolder={"Personal favourites"}
                type="text"
                className="ioninput-sharedfield-empty"
              />
            </IonCol>
            <IonCol>
              <b className="Label-style-sharedfield">
                Code list for procedures & diagnosis
                <IonIcon
                  slot="start"
                  icon={informationCircleOutline}
                  className="ion-icon-sharedfield"
                />
              </b>
              <SharedInputFields
                value={""}
                placeHolder={"Personal favourites"}
                type="text"
                className="ioninput-sharedfield-empty"
              />
            </IonCol>
          </IonRow>
          <p>Focus</p>
          <IonRow>
            <IonCol>
              <b className="Label-style-sharedfield">Last name</b>
              <SharedInputFields
                value={""}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-focus"
              />
            </IonCol>
            <IonCol>
              <b className="Label-style-sharedfield">
                Room
                <IonIcon
                  slot="start"
                  icon={informationCircleOutline}
                  className="ion-icon-sharedfield"
                />
              </b>
              <SharedInputFields
                value={""}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-focus"
              />
            </IonCol>
          </IonRow>
          <p>Filled</p>
          <IonRow>
            <IonCol>
              <b className="Label-style-sharedfield">
                Code list for procedures & diagnosis
              </b>
              <SharedInputFields
                value={"Bachmann"}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty"
              />
            </IonCol>
            <IonCol>
              <b className="Label-style-sharedfield">
                Code list for procedures & diagnosis
                <IonIcon
                  slot="start"
                  icon={informationCircleOutline}
                  className="ion-icon-sharedfield"
                />
              </b>
              <SharedInputFields
                value={"Personal favourites"}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty"
              />
            </IonCol>
          </IonRow>

          <p>Disabled</p>
          <IonRow>
            <IonCol>
              <b className="Label-style-sharedfield-disabled">
                Code list for procedures & diagnosis
              </b>
              <SharedInputFields
                value={"Bachmann"}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty-disabled"
                disabled={true}
              />
            </IonCol>
            <IonCol>
              <b className="Label-style-sharedfield-disabled">
                Code list for procedures & diagnosis
                <IonIcon
                  slot="start"
                  icon={informationCircleOutline}
                  className="ion-icon-sharedfield"
                />
              </b>
              <SharedInputFields
                value={"Personal favourites"}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty-disabled"
                disabled={true}
              />
            </IonCol>
          </IonRow>
          <p>Error</p>
          <IonRow>
            <IonCol>
              <b className="Label-style-sharedfield">
                Code list for procedures & diagnosis
              </b>
              <SharedInputFields
                value={"Bachmann"}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty-invalid"
              />
            </IonCol>
            <IonCol>
              <b className="Label-style-sharedfield">
                Code list for procedures & diagnosis
                <IonIcon
                  slot="start"
                  icon={informationCircleOutline}
                  className="ion-icon-sharedfield"
                />
              </b>
              <SharedInputFields
                value={"Personal favourites"}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty-invalid"
              />
            </IonCol>
          </IonRow>
        </div>
        <div className="div-sharedinput">
          <p>INPUT FIELDS SMALL</p>
          <p>Empty</p>
          <IonRow>
            <IonCol>
              <b className="Label-style-sharedfield">Room </b>
              <SharedInputFields
                value={""}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty-small"
              />
            </IonCol>
            <IonCol>
              <b className="Label-style-sharedfield">
                Room
                <IonIcon
                  slot="start"
                  icon={informationCircleOutline}
                  className="ion-icon-sharedfield"
                />
              </b>
              <SharedInputFields
                value={""}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty-small"
              />
            </IonCol>
          </IonRow>

          <p>Hint</p>
          <IonRow>
            <IonCol>
              <b className="Label-style-sharedfield">
                Code list for procedures & diagnosis
              </b>
              <SharedInputFields
                value={""}
                placeHolder={"Personal favourites"}
                type="text"
                className="ioninput-sharedfield-empty-small"
              />
            </IonCol>
            <IonCol>
              <b className="Label-style-sharedfield">
                Code list for procedures & diagnosis
                <IonIcon
                  slot="start"
                  icon={informationCircleOutline}
                  className="ion-icon-sharedfield"
                />
              </b>
              <SharedInputFields
                value={""}
                placeHolder={"Personal favourites"}
                type="text"
                className="ioninput-sharedfield-empty-small"
              />
            </IonCol>
          </IonRow>
          <p>Focus</p>
          <IonRow>
            <IonCol>
              <b className="Label-style-sharedfield">Last name</b>
              <SharedInputFields
                value={""}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-focus-small"
              />
            </IonCol>
            <IonCol>
              <b className="Label-style-sharedfield">
                Room
                <IonIcon
                  slot="start"
                  icon={informationCircleOutline}
                  className="ion-icon-sharedfield"
                />
              </b>
              <SharedInputFields
                value={""}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-focus-small"
              />
            </IonCol>
          </IonRow>
          <p>Filled</p>
          <IonRow>
            <IonCol>
              <b className="Label-style-sharedfield">
                Code list for procedures & diagnosis
              </b>
              <SharedInputFields
                value={"Bachmann"}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty-small"
              />
            </IonCol>
            <IonCol>
              <b className="Label-style-sharedfield">
                Code list for procedures & diagnosis
                <IonIcon
                  slot="start"
                  icon={informationCircleOutline}
                  className="ion-icon-sharedfield"
                />
              </b>
              <SharedInputFields
                value={"Personal favourites"}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty-small"
              />
            </IonCol>
          </IonRow>

          <p>Disabled</p>
          <IonRow>
            <IonCol>
              <b className="Label-style-sharedfield-disabled">
                Code list for procedures & diagnosis
              </b>
              <SharedInputFields
                value={"Bachmann"}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty-small-disabled"
                disabled={true}
              />
            </IonCol>
            <IonCol>
              <b className="Label-style-sharedfield-disabled">
                Code list for procedures & diagnosis
                <IonIcon
                  slot="start"
                  icon={informationCircleOutline}
                  className="ion-icon-sharedfield"
                />
              </b>
              <SharedInputFields
                value={"Personal favourites"}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty-small-disabled"
                disabled={true}
              />
            </IonCol>
          </IonRow>
          <p>Error</p>
          <IonRow>
            <IonCol>
              <b className="Label-style-sharedfield">
                Code list for procedures & diagnosis
              </b>
              <SharedInputFields
                value={"Bachmann"}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty-small-invalid"
              />
            </IonCol>
            <IonCol>
              <b className="Label-style-sharedfield">
                Code list for procedures & diagnosis
                <IonIcon
                  slot="start"
                  icon={informationCircleOutline}
                  className="ion-icon-sharedfield"
                />{" "}
              </b>
              <SharedInputFields
                value={"Personal favourites"}
                placeHolder={""}
                type="text"
                className="ioninput-sharedfield-empty-small-invalid"
              />
            </IonCol>
          </IonRow>
        </div>

        {/* <SelectInput /> */}
      </IonContent>
      <ProcedureCode isOpen={isModalOpen} setIsOpen={setModalOpen} />
      <DiagnosisCode isOpen={isDModalOpen} setIsOpen={setDModalOpen} />
    </IonPage>
  );
};

export default UiComponents;
