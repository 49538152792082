import React from "react";
import { Route, Redirect } from "react-router-dom";
import { IonRouterOutlet, IonSplitPane } from "@ionic/react";
import SideMenu from "../components/SideMenu/sidemenu";
import Home from "../pages/Home/Home";

import VisitPage from "../pages/VisitPage/VisitPage";
import Settings from "../pages/Settings/Settings";
import Provider from "../pages/Provider/Provider";
import PracticeIQ from "../pages/PracticeIQ/PracticeIQ";
import Payment from "../pages/Payment/Payment";
import Census from "../pages/Census/Census";
import AddNewEncounter from "../pages/Encounter/AddNewEncounter";
import DashBoard from "../pages/DashBoard/DashBoard";
import DashBoardNew from "../pages/DashBoardNew/DashBoard";
import UiComponents from "../pages/UiComponents/UiComponents";
import TabPOC from "../pages/TabPOC/TabPOC";
import VisitDetailsOutline from "../pages/VisitDetailOutline/VisitDetailsOutline";
import VisitDetails from "../pages/VisitDetails/VisitDetails";
import Practice from "../pages/Practice/Practice";
import ProviderTab from "../pages/ProviderHold/ProviderTab";
import CensusEncounter from "../pages/Encounter/CensusEncounter/CensusEncounter";
import PatientDetail from "../pages/PatientDetails/PatientDetails";
import ProgressNote from "../pages/ProgressNote/ProgressNote";
import AddPatient from "../pages/AddPatient/AddPatient";
import Subjective from "../pages/Subjective/Subjective";
import Objective from "../pages/Objective/objective";
import Assesment from "../pages/Assesment/Assesment";
import Plan from "../pages/Plan/Plan";
import EditPatient from "../pages/EditPatient/EditPatient";
import CensusCalendar from "../components/Census/CensusCalendar";
import MessageList from "../pages/Messaging/MessageList/MessageList";
import PracticeIQAnalytics from "../pages/PracticeIQ/PracticeIQAnalytics";
import ICD10DiagnosisLookup from "../pages/ICD10DiagnosisLookup/Icd10DiagnosisLookup";
import MessageThread from "../pages/Messaging/MessageThread/MessageThread";
import EditProcedureAndDiagnosis from "../pages/PatientDetails/EditProcedureAndDiagnosis/EditProcedureAndDiagnosis";
import ContactList from "../pages/Messaging/ContactList/contactList";
import Documents from "../pages/Documents/Documents";
import EditProfile from "../pages/EditProfile/EditProfile";

const AuthRoutes: React.FC = () => {
  return (
    <>
      {
        <Route
          exact
          path="*"
          render={() => {
            return <Redirect to="/dashBoard" />;
          }}
        />
      }
      <IonRouterOutlet basePath="/" id="authRoute">
        <IonSplitPane contentId="main">
          <SideMenu />

          <IonRouterOutlet basePath="/dashBoard" id="main">
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/settings">
              <Settings />
            </Route>

            {/* <Route exact path="/addNewPatient">
              <Newpatient />
            </Route> */}

            <Route exact path="/selectProvider">
              <Provider switchUser={true} />
            </Route>

            <Route exact path="/selectPractice">
              <Practice switchUser={true} />
            </Route>

            <Route exact path="/payment">
              <Payment />
            </Route>

            <Route exact path="/visitOutline">
              <VisitDetailsOutline />
            </Route>

            <Route exact path="/dashBoardOld">
              <DashBoard />
            </Route>

            <Route exact path="/dashBoard">
              <DashBoardNew />
            </Route>

            <Route exact path="/">
              <Redirect to="/dashBoard" />
            </Route>

            <Route exact path="/practice-iq">
              <PracticeIQ />
            </Route>

            <Route exact path="/ui-components">
              <UiComponents />
            </Route>

            <Route exact path="/visit">
              <VisitPage />
            </Route>
            <Route exact path="/census">
              <Census />
            </Route>
            <Route exact path="/census/:id">
              <Census />
            </Route>
            <Route exact path="/providerhold">
              <ProviderTab />
            </Route>

            <Route path="/progressnote/:id" component={ProgressNote} />

            <Route exact path="/addNewEncounter">
              <AddNewEncounter />
            </Route>
            <Route
              path="/editProcedureAndDiagnosis/:id"
              component={EditProcedureAndDiagnosis}
            />

            <Route exact path="/addNewPatient">
              <AddPatient />
            </Route>
            <Route exact path="/censusCalendar">
              <CensusCalendar />
            </Route>

            <Route path="/patientDetails/:id" component={PatientDetail} />
            <Route path="/patientEdit/:id" component={EditPatient} />

            <Route path="/documents/:id" component={Documents} />

            <Route exact path="/censusEncounter">
              <CensusEncounter />
            </Route>

            <Route exact path="/progressNote">
              <ProgressNote />
            </Route>

            <Route exact path="/subjective">
              <Subjective />
            </Route>
            <Route exact path="/objective">
              <Objective />
            </Route>
            <Route exact path="/assesment">
              <Assesment />
            </Route>
            <Route exact path="/plan">
              <Plan />
            </Route>
            <Route exact path="/practice-iq-analytics">
              <PracticeIQAnalytics />
            </Route>
            <Route exact path="/editProfile">
              <EditProfile />
            </Route>
            <Route path="/tab" component={TabPOC} />
            <Route path="/visitdetails" component={VisitDetails} />
            <Route path="/messagelist" component={MessageList} />
            <Route path="/messagethread/:id" component={MessageThread} />
            {/* <Route path="/message-thread/:id" component={MessageThreade} /> */}
            <Route path="/contact-list" component={ContactList} />
            <Route path="/contact-list/:id" component={ContactList} />
            <Route exact path="/ico10DiagnosisLookup">
              <ICD10DiagnosisLookup />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonRouterOutlet>
    </>
  );
};

export default AuthRoutes;
