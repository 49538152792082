import React from "react";
import "./Chart.scss";
import ChartBar from "./ChartBar";

interface ChartProps {
  dataPoints?: any[];
  dataPoint?: any;
}

const Chart: React.FC<ChartProps> = (props: any) => {
  const dataPointValues = props.dataPoints.map(
    (dataPoint: any) => dataPoint.value
  );

  // const totalMaximum = dataPointValues.reduce((prevVal, currentValue)=>prevVal+currentValue);

  // const totalMaximum = 10;

  const totalMaximum = Math.max(...dataPointValues);

  return (
    <div className="chart">
      {props.dataPoints.map((dataPoint: any) => (
        <ChartBar
          key={dataPoint.label}
          value={dataPoint.value}
          maxValue={totalMaximum}
          label={dataPoint.label}
        />
      ))}
    </div>
  );
};

export default Chart;
