/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";

import { chevronBackOutline } from "ionicons/icons";

import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getVisitsCensus } from "../../store/thunk/censusThunk";
import { Layout } from "../../components/Layout/Layout";
import { getVisitByMonth } from "../../helper/backendHelper";
import CensusList from "./censusList";
import { setActiveDate, setCensusFacility } from "../../store/reducers/census";
import CalendarCensusPage from "./calendarCensusPage";
import { selectUser } from "../../store/reducers/user";

const CensusCalender: React.FC = () => {
  const router = useIonRouter();
  const dispatch = useAppDispatch();
  const facilitiesList = useAppSelector((state) => state.census.facilitiesList);
  const thisDate = useAppSelector((state) => state.census.activeDate);
  const [censusData, setCensusData] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState("");
  const userData = useAppSelector(selectUser);
  const currentFacility = useAppSelector(
    (state) => state.census.censusFacility
  );

  useEffect(() => {
    getData(
      moment(thisDate).startOf("month").format("YYYY-MM-DD"),
      moment(thisDate).endOf("month").format("YYYY-MM-DD")
    );
  }, []);

  const getData = async (firstDate: any, lastDate: any) => {
    let res = await getVisitByMonth(firstDate, lastDate);
    setCensusData(res.result);
  };

  const handleMonthChangeFunction = (args: any) => {
    getData(
      moment(args.firstDay).format("YYYY-MM-DD"),
      moment(args.lastDay).format("YYYY-MM-DD")
    );
  };

  const handleDateChangeFunction = (date: any) => {
    dispatch(setCensusFacility(facilitiesList[0]));
    dispatch(
      getVisitsCensus({
        servicedate: moment(date).format("YYYY-MM-DD"),
        // thisProvider: userData.provider,
        facilityId: currentFacility?._id,
      })
    );
    dispatch(setActiveDate(moment(date).format("YYYY-MM-DD")));
    router.push("/census", "back");
  };

  const backButtonHandler = () => {
    router.push("/census", "back");
  };

  let uniqueList: any[] = [];
  censusData.forEach((element: any) => {
    if (
      !uniqueList.map((item) => item.facility_id).includes(element.facility_id)
    )
      uniqueList.push(element);
  });

  const colorList = [
    "#2A7B9B",
    "#EDDD53",
    "#00BAAD",
    "#FFC300",
    "#57C785",
    "#FF5733",
    "#ADD45C",
    "#FF8D1A",
    "#C70039",
    "#900C3F",
    "#511849",
    "#3D3D6B",
  ];

  let uniqueWithColor: any[] = [];
  uniqueList.forEach((item: any, index: number) => {
    uniqueWithColor = [
      ...uniqueWithColor,
      {
        ...item,
        color: index < colorList.length ? colorList[index] : "#AAAAAA",
      },
    ];
  });

  let censusDataWithColor: any[] = [];
  censusData.forEach((element: any) => {
    const index = uniqueWithColor.findIndex(
      (item) => item.facility_id === element.facility_id
    );
    if (index >= 0) {
      censusDataWithColor = [
        ...censusDataWithColor,
        { ...element, color: uniqueWithColor[index].color },
      ];
    }
  });

  const handleSelection = (selected: string) => {
    if (selected === selectedFacility) setSelectedFacility("");
    else setSelectedFacility(selected);
  };

  return (
    <Layout
      isLoading={false}
      isError={false}
      errorMsg={"No data"}
      className="census-page"
    >
      <IonHeader className="no-shadow no-border">
        <IonToolbar className="d-pad-05" mode="ios">
          <IonButtons slot="start">
            {/* <IonBackButton
              text={""}
              className="backbutton-color-addnewencounter"
            /> */}
            <IonButton onClick={backButtonHandler}>
              <IonIcon
                className="backbutton-color-addnewencounter"
                icon={chevronBackOutline}
              />
            </IonButton>
          </IonButtons>
          <IonTitle className="title-header">Census Calendar</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div className="census-list">
        <CalendarCensusPage
          item={"month"}
          data={censusDataWithColor}
          dateValue={thisDate}
          handleMonthChange={handleMonthChangeFunction}
          handleDateChange={handleDateChangeFunction}
          selectedFacility={selectedFacility}
        />
      </div>

      <div className="scroll">
        <CensusList
          data={uniqueWithColor}
          onSelection={handleSelection}
          selected={selectedFacility}
        />
      </div>
    </Layout>
  );
};

export default CensusCalender;
