/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { disconnectSocket } from "../../socket";
import { PracticeType, ProviderType, UserType } from "../../types/Types";
import { RootState } from "../store";
import {
  mfaLogin,
  providerLogin,
  rememberAuth,
  userLogin,
} from "../thunk/userThunk";

type UserState = {
  authToken: String | null;
  mfatoken: String | null;
  isMFAEnabled: boolean;
  secure_code: String[] | null;
  authRefreshToken: String | null;
  roles: any;
  permissions: any;
  settings: any;
  features: any;
  user: UserType | null;
  practice: PracticeType | null;
  provider: ProviderType | null;
  practiceList: PracticeType[];
  providerList: ProviderType[];
  isloading: boolean;
  isError: boolean;
  errorMsg: any;
  username: String | null;
  otp: String | null;
  otpType: String | null;
  isProviderLogin: boolean;
  brand: String;
};

const initialState: UserState = {
  authToken: null,
  mfatoken: null,
  isMFAEnabled: false,
  secure_code: null,
  authRefreshToken: null,
  roles: null,
  permissions: null,
  settings: null,
  features: null,
  user: null,
  practice: null,
  provider: null,
  practiceList: [],
  providerList: [],
  isloading: false,
  isError: false,
  errorMsg: "",
  username: null,
  otp: null,
  otpType: null,
  isProviderLogin: false,
  brand: ""
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setProvider: (state, action: PayloadAction<ProviderType>) => {
      state.provider = action.payload;
    },
    setPractice: (state, action: PayloadAction<PracticeType>) => {
      state.practice = action.payload;
      // let filteredProviders = current(state)?.providerList?.filter(
      //   (item: any, i: any) => {
      //     return item?.practice_id === current(state)?.practice?.practice_id;
      //   }
      // );
      // if (filteredProviders?.length === 1) {
      //   state.provider = filteredProviders[0];
      // } else if (filteredProviders?.length === 0) {
      //   state.provider = null;
      // }
    },
    clearProvider: (state) => {
      state.provider = initialState.provider;
    },
    clearPractice: (state) => {
      state.practice = initialState.practice;
    },
    userLogout: (state) => {
      disconnectSocket();
      localStorage.removeItem("Token");
      state.authToken = initialState.authToken;
      state.authRefreshToken = initialState.authRefreshToken;
      state.brand = initialState.brand;
      state.user = initialState.user;
      state.roles = initialState.roles;
      state.features = initialState.features;
      state.settings = initialState.settings;
      state.permissions = initialState.permissions;
      state.otp = initialState.otp;
      state.providerList = initialState.providerList;
      state.practiceList = initialState.practiceList;
      state.provider = initialState.provider;
      state.practice = initialState.practice;
      state.isProviderLogin = initialState.isProviderLogin;
      window ? window.location.replace("/") : null;
    },
    closeError: (state) => {
      state.isError = false;
      state.errorMsg = "";
    },
    closeLoading: (state) => {
      state.isloading = false;
    },
    clearMfa: (state) => {
      state.isMFAEnabled = initialState?.isMFAEnabled;
      state.mfatoken = initialState?.mfatoken;
      state.secure_code = initialState?.secure_code;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.isloading = false;

        if (action.payload?.isMFAEnabled) {
          state.isMFAEnabled = action.payload?.isMFAEnabled;
          state.mfatoken = action.payload?.mfatoken;
          state.secure_code = action.payload?.secure_code;
          state.username = action.payload?.username;
          state.otpType = action.payload?.["otp-type"];
          state.user = action.payload?.user;
        } else {
          state.authToken = action.payload?.authToken
            ? action.payload?.authToken
            : null;
          state.authRefreshToken = action.payload?.authRefreshToken;
          state.brand = action.payload?.brand;
          state.user = action.payload?.user;
          state.roles = action.payload?.roles;
          state.permissions = action.payload?.permissions;
          state.otp = action.payload?.passwordIsOtp;
          state.providerList = action.payload?.providerRes;
          state.practiceList = action.payload?.practiceRes?.filter(
            (item: any, i: any) => {
              return item?.enabled === true;
            }
          );
          if (state.practiceList?.length === 1) {
            state.practice = state.practiceList[0];
            let filteredProviders = state.providerList.filter(
              (item: any, i: any) => {
                return item.practice_id === state.practice?.practice_id;
              }
            );
            if (filteredProviders?.length === 1) {
              state.provider = filteredProviders[0];
            } else if (filteredProviders?.length === 0) {
              state.provider === null;
            }
          }
        }
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;

        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload;
        } else {
          state.errorMsg = action.error.message?.toString();
        }
      })
      .addCase(mfaLogin.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(mfaLogin.fulfilled, (state, action) => {
        state.isloading = false;
        state.authToken = action.payload?.authToken
          ? action.payload?.authToken
          : null;
        state.authRefreshToken = action.payload?.authRefreshToken;
        state.brand = action.payload?.brand;
        state.user = action.payload?.user;
        state.roles = action.payload?.roles;
        state.permissions = action.payload?.permissions;
        state.otp = action.payload?.passwordIsOtp;
        state.providerList = action.payload?.providerRes;
        state.practiceList = action.payload?.practiceRes?.filter(
          (item: any, i: any) => {
            return item?.enabled === true;
          }
        );
        if (state.practiceList?.length === 1) {
          state.practice = state.practiceList[0];
          let filteredProviders = state.providerList.filter(
            (item: any, i: any) => {
              return item.practice_id === state.practice?.practice_id;
            }
          );
          if (filteredProviders?.length === 1) {
            state.provider = filteredProviders[0];
          } else if (filteredProviders?.length === 0) {
            state.provider === null;
          }
        }
      })
      .addCase(mfaLogin.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;

        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload;
        } else {
          state.errorMsg = action.error.message?.toString();
        }
      })
      .addCase(rememberAuth.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(rememberAuth.fulfilled, (state, action) => {
        state.isloading = false;
        state.authToken = action.payload?.authToken;
        state.authRefreshToken = action.payload?.authRefreshToken;
        state.brand = action.payload.brand;
        state.user = action.payload?.user;
        state.roles = action.payload?.roles;
        state.permissions = action.payload?.permissions;
        state.otp = action.payload?.passwordIsOtp;
        state.providerList = action.payload?.providerRes;
        state.practiceList = action.payload?.practiceRes;
        state.provider = action.payload?.curentProviderRes;
        state.practice = action.payload?.curentPracticeRes;
        state.roles = action.payload?.rolesRes;
        state.settings = action.payload?.settingsRes;
        state.features = action.payload?.featuresRes;
        state.permissions = action.payload?.permissionsRes;
      })
      .addCase(rememberAuth.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;

        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload;
        } else {
          state.errorMsg = action.error.message?.toString();
        }
      })
      .addCase(providerLogin.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(providerLogin.fulfilled, (state, action) => {
        state.isloading = false;
        state.authToken = action.payload?.authToken;
        state.provider = action.payload?.providerRes;
        state.practice = action.payload?.practiceRes;
        state.isProviderLogin = true;
        state.roles = action.payload?.rolesRes;
        state.settings = action.payload?.settingsRes;
        state.features = action.payload?.featuresRes;
        state.permissions = action.payload?.permissionsRes;
      })
      .addCase(providerLogin.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;

        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload;
        } else {
          state.errorMsg = action.error.message?.toString();
        }
      });
  },
});

export const {
  clearPractice,
  userLogout,
  clearProvider,
  clearMfa,
  setProvider,
  setPractice,
  closeError,
} = userSlice.actions;
export const selectUser = (state: RootState) => state.user;
export const userReducer = userSlice.reducer;
