/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonContent,
  IonPage,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  IonAccordionGroup,
  IonMenuButton,
} from "@ionic/react";
import {
  informationCircle,
  reorderTwoOutline,
  closeOutline,
} from "ionicons/icons";
import "./VisitPage.scss";
import { pencilOutline } from "ionicons/icons";
import FacilitySearch from "../../components/Common/FacilitySearch/FacilitySearch";
import DateOfService from "../../components/Common/DateOfService/DateOfService";
import PatientSearch from "../../components/Common/PatientSearch/PatientSearch";
import CustomButton from "../../components/Common/CustomButton/CustomButton";
import CustomCard from "../../components/Common/CustomCard/CustomCard";

import SelectBox from "../../components/Common/SelectBox/SelectBox";
import InputField2 from "../../components/Common/InputField2/InputField2";
import { PatientType } from "../../types/PatientType";
import demoPatient from "../../mock/patient.json";
import demoFacility from "../../mock/facility.json";
import { FacilityObjType } from "../../types/FacilityType";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  changeCodeTemplates,
  initialConfig,
} from "../../store/thunk/addVisitThunk";
import ProceduresBlock from "./ProceduresBlock";
import { setSelectedProcedures } from "../../store/reducers/addVisit";

const VisitPage: React.FC = () => {
  const thisState = useAppSelector((state) => state.addVisit);

  const dispatch = useAppDispatch();

  // const [appearModal, setAppearModal] = useState(false);
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  const [curentPatient, setCurentPatient] = useState<PatientType | null>(
    demoPatient
  );

  const [curentFacility, setCurentFacility] = useState<FacilityObjType | null>(
    demoFacility
  );

  const [personalFav, setPersonalFav] = useState("Personal favourite");
  const [physician, setPhysician] = useState("Select Physician");

  useEffect(() => {
    dispatch(initialConfig());
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonMenuButton slot="start" />
          <IonTitle>Add Visit</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="addvisit-page">
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="cards border">
                <IonGrid>
                  <IonRow className="visitionrow">
                    <IonCol size={showPatientSearch ? "12" : "10"}>
                      <IonCardHeader>
                        <IonCardSubtitle>Patient</IonCardSubtitle>
                      </IonCardHeader>

                      <IonCardContent>
                        {showPatientSearch === true ? (
                          <PatientSearch
                            onSelect={(data) => setCurentPatient(data)}
                            onClose={setShowPatientSearch}
                          />
                        ) : (
                          <>
                            <p>
                              <strong>{curentPatient?.first_name}</strong>,{" "}
                              {curentPatient?.last_name} | 0005122
                            </p>
                            <p>G89.4 - Chronic Pain Syndrome</p>
                          </>
                        )}
                      </IonCardContent>
                    </IonCol>
                    {!showPatientSearch && (
                      <IonCol className="ioncoledit">
                        <IonIcon
                          icon={pencilOutline}
                          size="sm"
                          onClick={() => setShowPatientSearch(true)}
                        ></IonIcon>
                      </IonCol>
                    )}
                  </IonRow>
                  <div className="ionstepContainer bgValid">
                    <p className="ionstepNumber">1</p>
                  </div>
                </IonGrid>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div className="cards border">
                <IonGrid>
                  <IonRow>
                    <IonCol size="10">
                      <IonCardHeader>
                        <IonCardSubtitle>Date Of Service</IonCardSubtitle>
                      </IonCardHeader>

                      <IonCardContent>
                        <p>
                          <strong>Today, 01/02/2021</strong>
                        </p>
                      </IonCardContent>
                    </IonCol>
                    <IonCol className="ioncoledit">
                      <DateOfService />
                    </IonCol>
                  </IonRow>
                  <div className="ionstepContainer bgValid">
                    <p className="ionstepNumber">2</p>
                  </div>
                </IonGrid>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div className="cards border">
                <IonGrid>
                  <IonRow>
                    <IonCol size="10">
                      <IonCardHeader>
                        <IonCardSubtitle>Facility</IonCardSubtitle>
                      </IonCardHeader>

                      <IonCardContent>
                        <p>
                          <strong>{curentFacility?.name}</strong>
                        </p>
                      </IonCardContent>
                    </IonCol>
                    <IonCol className="ioncoledit">
                      <FacilitySearch
                        onSelect={(data) => setCurentFacility(data)}
                      />
                    </IonCol>
                  </IonRow>
                  <div className="ionstepContainer bgValid">
                    <p className="ionstepNumber">3</p>
                  </div>
                </IonGrid>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonAccordionGroup>
          <IonGrid>
            {/* <IonButton color="primary" onClick={toggleThemeEvent}>
          Toggle Theme
        </IonButton> */}
            <ProceduresBlock
              codeTemplates={thisState.codeTemplates}
              proceduresCode={thisState.favProcedures}
              onsetSelectedProcedures={(codes) =>
                dispatch(setSelectedProcedures(codes))
              }
              selectedProcedureCodes={thisState.selectedProcedures}
              onSetChangeTemplate={(codes) =>
                dispatch(changeCodeTemplates(codes))
              }
            />

            <IonRow>
              <IonCol>
                <CustomCard
                  isStep={true}
                  title="Diagnosis"
                  step={5}
                  isValid={true}
                >
                  <div className="children-wrapper">
                    <div className="content-wrapper">
                      <div>
                        <p className="card-sub">Add diagnosis for the visit</p>
                        <p
                          className="informative-text"
                          style={{ fontStyle: "italic" }}
                        >
                          Select the main diagnosis first
                          <IonIcon
                            className="informative-icon"
                            icon={informationCircle}
                          />
                        </p>
                      </div>

                      <p className="more-btn">More</p>
                    </div>
                    <div className="procedures-container">
                      <CustomButton text="E11.9" />
                      <CustomButton text="G89.4" />
                      <CustomButton text="G04.39" />
                      <CustomButton text="N39.0" />
                      <CustomButton text="E66.01" />
                      <CustomButton text="G72.81" />
                      <CustomButton text="G93.40" />
                      <CustomButton text="G93.41" />
                      <CustomButton text="R53.81" />
                    </div>

                    <div className="informative-text">
                      <p className="informative-text-content">Main diagnosis</p>
                    </div>

                    <div className="list-items">
                      <div className="list-item ">
                        <div>
                          <IonIcon
                            className="reorder-icon"
                            icon={reorderTwoOutline}
                          ></IonIcon>
                        </div>
                        <div>G89.4 - Chronic pain syndrome</div>
                        <div className="close-icon">
                          <IonIcon icon={closeOutline}></IonIcon>
                        </div>
                      </div>
                    </div>

                    <div className="informative-text">
                      <p className="informative-text-content">
                        Additional diagnosis
                      </p>
                    </div>
                    <div className="list-items">
                      <div className="list-item">
                        <div>
                          <IonIcon
                            className="reorder-icon"
                            icon={reorderTwoOutline}
                          ></IonIcon>
                        </div>
                        <div>
                          N39.0 - Urinary tract infection, site not specified
                        </div>
                        <div className="close-icon">
                          <IonIcon icon={closeOutline}></IonIcon>
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomCard>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <CustomCard
                  isStep={true}
                  title="Additional Info"
                  step="+"
                  isValid={true}
                >
                  <div className="children-wrapper">
                    <div className="input-field-container">
                      <InputField2 label="Room" type="text" />
                      <InputField2 label="Billing notes" type="text" />
                    </div>
                    <div className="select-box-container">
                      <div>
                        <label className="select-box-label">
                          Assign to physician
                        </label>
                      </div>
                      <SelectBox
                        options={["option1", "opyion2", "option3"]}
                        selected={physician}
                        setSelected={setPhysician}
                      />
                    </div>
                  </div>
                </CustomCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonAccordionGroup>
      </IonContent>
    </IonPage>
  );
};

export default VisitPage;
