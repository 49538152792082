import React from "react";
import {
  IonCol,
  IonRow,
  IonLabel,
} from "@ionic/react";

type MarkedDataProps = {
  data?: any[];
  onSelection: (selected: string) => void;
  selected: string;
};

const CensusList: React.FC<MarkedDataProps> = (props) => {
  let data = props.data ? props.data : [];

  return (
    <>
      {data?.map((item: any, index: any) => (
        <IonRow
          className={` census-list-container ${
            props.selected === item.facility_id
              ? "census-list-item-selected"
              : ""
          }`}
          key={`${item.facility_id} ${index}`}
          onClick={() => props.onSelection(item.facility_id)}
        >
          <IonCol size="0">
            <div
              className="color-icon"
              style={{
                backgroundColor: `${item.color}`,
              }}
            ></div>
          </IonCol>
          <IonCol size="8" className="census-list-text-contanier">
            <IonLabel className="census-list-text">
              {item?.facility_name}
            </IonLabel>
          </IonCol>
        </IonRow>
      ))}
    </>
  );
};
export default CensusList;
