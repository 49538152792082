/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonToast,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import "./Provider.scss";

// import { useHistory } from "react-router";

import { selectUser, userLogout } from "../../store/reducers/user";
import { useEffect, useState } from "react";
import { ProviderType } from "../../types/Types";
import SearchBox from "../../components/Common/SearchBox/SearchBox";
import { providerLogin } from "../../store/thunk/userThunk";
import { Layout } from "../../components/Layout/Layout";
import { chevronBackOutline } from "ionicons/icons";

interface ProviderProps {
  switchUser?: boolean;
}

const Provider: React.FC<ProviderProps> = ({ switchUser }) => {
  const providerList = useAppSelector((state) => state.user.providerList);
  const selectedPractice = useAppSelector((state) => state.user)?.practice;
  const selectedProvider = useAppSelector((state) => state.user)?.provider;

  const [isSelected, setIsSelected] = useState(false);
  const [isError, setisError] = useState(false);
  const userData = useAppSelector(selectUser);

  const practiceProviders = providerList.filter(
    (x) =>
      x.practice_id ===
      (selectedPractice?.practice_id
        ? selectedPractice?.practice_id
        : selectedPractice?._id)
  );

  const [searchData, setSearchData] = useState("");
  const [searchResult, setSearchResult] = useState(practiceProviders);

  const dataGroupWithAlphabet = practiceProviders.reduce((r: any, e: any) => {
    let group = e.last_name[0].toUpperCase();
    if (!r[group]) r[group] = { group, children: [e] };
    else r[group].children.push(e);
    return r;
  }, {});

  const dataWithoutSearch = Object.values(dataGroupWithAlphabet);

  const router = useIonRouter();

  // const history = useHistory();
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    router.goBack();
  };

  const handleProviderClick = async(newProvider: ProviderType) => {
     await dispatch(
      providerLogin({
        practice_id: newProvider?.practice_id,
        provider_id: newProvider?.provider_id,
      })
    );
    setIsSelected(true);
  };

  const searchHandler = (searchValue: any) => {
    setSearchResult(() =>
      practiceProviders?.filter(
        (item: any) =>
          item?.first_name
            ?.toLowerCase()
            ?.includes(searchValue.toLowerCase()) ||
          item?.last_name?.toLowerCase()?.includes(searchValue.toLowerCase())
      )
    );
    setSearchData(searchValue);
  };

  useEffect(() => {
    if (selectedPractice !== null && isSelected) {
      if (selectedProvider !== null && userData?.isProviderLogin) {
        router.push("/", "forward", "push");
      }
    }
  }, [selectedProvider, selectedPractice, isSelected,userData?.isProviderLogin]);

  useEffect(() => {
    if (practiceProviders?.length === 0) {
      setisError(true);
      setTimeout(() => {
        dispatch(userLogout());
      }, 2000);
    } else if (practiceProviders?.length === 1) {
      handleProviderClick(practiceProviders[0]);
    }
  }, []);

  return (
    <Layout isLoading={userData?.isloading} isError={userData?.isError}>
      <IonToast
        isOpen={isError}
        message="No provider mapped for this practice"
        position="bottom"
        duration={2000}
      />
      <IonHeader className="provider-page">
        <IonToolbar mode="ios" className="toolbar">
          <IonTitle className="provider-title">Select Provider</IonTitle>
          <IonButtons slot="secondary">
            <IonButton onClick={handleCancel}>
              <IonIcon className="toolbar" icon={chevronBackOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="provider-page">
        <div className="searchbar-container">
          <SearchBox
            placeholder="Search provider"
            onChange={(value) => searchHandler(value)}
            value={searchData}
          />
        </div>
        <div className="organization-name">
          {selectedPractice?.organization_name}
        </div>

        {searchResult.length >= 1 ? (
          <>
            {searchData === "" ? (
              <>
                {dataWithoutSearch.map((item: any) => (
                  <div key={item.group}>
                    <div className="alphabet">{item.group}</div>
                    {item.children.map((element: any) => (
                      <div key={element.provider_id}>
                        <div
                          className="provider-card"
                          onClick={() => handleProviderClick(element)}
                        >
                          <p className="provider-name">
                            <strong className="lastName">{`${element.last_name.toUpperCase()}, `}</strong>{" "}
                            {element.first_name.toLowerCase()}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </>
            ) : (
              <>
                {searchResult.map((item) => (
                  <div key={item.provider_id}>
                    <div
                      className="provider-card"
                      onClick={() => handleProviderClick(item)}
                    >
                      <p className="provider-name">
                        <strong className="lastName">{`${item.last_name.toUpperCase()}, `}</strong>{" "}
                        {item.first_name.toLowerCase()}
                      </p>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        ) : (
          <p className="noDataMessage">- No Match Found -</p>
        )}
      </IonContent>
      <IonFooter className="provider-footer"></IonFooter>
    </Layout>
  );
};

export default Provider;
