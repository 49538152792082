import React from "react";
import { IonCard, IonCardSubtitle } from "@ionic/react";

import "./PatientInformation.scss";
interface Props {
  SelectedData: any;
}
const PatientInformation: React.FC<Props> = ({ SelectedData }) => {
  let data = null;
  data = {
    lastName: SelectedData?.patient_lastname,
    middleName: SelectedData?.patient_middlename,
    firstName: SelectedData?.patient_firstname,
    phoneNumber: "-",
    dob: SelectedData?.patient_dob,
    gender: SelectedData?.gender,
  };

  return (
    <IonCard className="patientInfoCard">
      <IonCardSubtitle className="patientInfoCardTitle">
        Patient information
      </IonCardSubtitle>

      <div className="patientInfoCardContent">
        <div className="singleInfo">
          <div className="patientInfoLabel">
            <p>Last name</p>
          </div>
          <div className="patientInfo">
            <p>
              {data?.lastName?.trim()?.length === 0 ||
              data?.lastName === null ||
              data?.lastName === undefined
                ? "-"
                : data?.lastName}
            </p>
          </div>
        </div>
        <div className="singleInfo">
          <div className="patientInfoLabel">
            <p>Middle name</p>
          </div>
          <div className="patientInfo">
            <p>
              {data?.middleName?.trim()?.length === 0 ||
              data?.middleName === null ||
              data?.middleName === undefined
                ? "-"
                : data?.middleName}
            </p>
          </div>
        </div>
        <div className="singleInfo">
          <div className="patientInfoLabel">
            <p>First name</p>
          </div>
          <div className="patientInfo">
            <p>
              {data?.firstName?.trim()?.length === 0 ||
              data?.firstName === null ||
              data?.firstName === undefined
                ? "-"
                : data?.firstName}
            </p>
          </div>
        </div>
        {/* <div className="singleInfo">
          <div className="patientInfoLabel">
            <p>Phone number</p>
          </div>
          <div className="patientInfo">
            <p>
              {data?.phoneNumber?.trim()?.length === 0 ||
              data?.phoneNumber === null ||
              data?.phoneNumber === undefined
                ? "-"
                : data?.phoneNumber}
            </p>
          </div>
        </div> */}
        <div className="singleInfo">
          <div className="patientInfoLabel">
            <p>Date of birth</p>
          </div>
          <div className="patientInfo">
            <p>
              {data?.dob?.trim()?.length === 0 ||
              data?.dob === null ||
              data?.dob === undefined
                ? "-"
                : data?.dob}
            </p>
          </div>
        </div>
        <div className="singleInfo">
          <div className="patientInfoLabel">
            <p>Gender</p>
          </div>
          <div className="patientInfo">
            <p>
              {data?.gender == "F"
                ? data?.gender?.trim()?.length === 0 ||
                  data?.gender === null ||
                  data?.gender === undefined
                  ? "-"
                  : "Female"
                : data?.gender?.trim()?.length === 0 ||
                  data?.gender === null ||
                  data?.gender === undefined
                ? "-"
                : "Male"}
            </p>
          </div>
        </div>
      </div>
    </IonCard>
  );
};

export default PatientInformation;
