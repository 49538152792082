import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AditionalInfoType,
  CodeTemplatesType,
  FavDiagnosisType,
  FavProceduresType,
} from "../../types/AddVisitType";
import { FacilityObjType } from "../../types/FacilityType";
import { PatientType } from "../../types/PatientType";
import {
  changeCodeTemplates,
  initialConfig,
  // setCodeTemplates,
} from "../thunk/addVisitThunk";

type AddVisitState = {
  isloading: boolean;
  isError: boolean;
  errorMsg: any;
  selectedPatient: PatientType | null;
  selectedFacility: FacilityObjType | null;
  codeTemplates: CodeTemplatesType[];
  selectedCodeTemplates: string | null;
  favDiagnosis: FavDiagnosisType[];
  favProcedures: FavProceduresType[];
  selectedDiagnosis: FavDiagnosisType[];
  selectedProcedures: FavProceduresType[];
  aditionalInfo: AditionalInfoType | null;
  dateOfService: string;
};

const initialState: AddVisitState = {
  isloading: false,
  isError: false,
  errorMsg: "",
  selectedPatient: null,
  selectedFacility: null,
  codeTemplates: [],
  selectedCodeTemplates: null,
  favDiagnosis: [],
  favProcedures: [],
  selectedDiagnosis: [],
  selectedProcedures: [],
  aditionalInfo: null,
  dateOfService: new Date().toString(),
};

export const addVisitSlice = createSlice({
  name: "addVisit",
  initialState,
  reducers: {
    closeError: (state) => {
      state.isError = false;
      state.errorMsg = "";
    },
    closeLoading: (state) => {
      state.isloading = false;
    },
    setSelectedProcedures: (
      state,
      action: PayloadAction<FavProceduresType[]>
    ) => {
      state.selectedProcedures = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialConfig.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(initialConfig.fulfilled, (state, action) => {
        state.isloading = false;
        state.codeTemplates = action.payload?.codeTemplates;
        state.favProcedures = action.payload?.favProcedures;
        state.favDiagnosis = action.payload?.favDiagnosis;
      })
      .addCase(initialConfig.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload;
        } else {
          state.errorMsg = action.error.message?.toString();
        }
      })
      .addCase(changeCodeTemplates.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(changeCodeTemplates.fulfilled, (state, action) => {
        state.isloading = false;
        state.selectedCodeTemplates = action.payload?.selectedCodeTemplates;
        state.favProcedures = action.payload?.favProcedures;
        state.favDiagnosis = action.payload?.favDiagnosis;
      })
      .addCase(changeCodeTemplates.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload;
        } else {
          state.errorMsg = action.error.message?.toString();
        }
      });
  },
});

export const { setSelectedProcedures, closeError } = addVisitSlice.actions;

export const addVisitReducer = addVisitSlice.reducer;
