import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonModal,
  IonRadioGroup,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useState } from "react";
import Button from "../Buttons/Buttons";
import RadioButton from "../RadioButton/RadioButton";
import "./EditWidget.scss";

interface EditWidgetProps {
  openEditWidget: boolean;
  setOpenEditWidget: (item: boolean) => void;
  radioButtonData: any;
  radioChartData: any;
  updateFilter: any;
}

const EditWidget: React.FC<EditWidgetProps> = ({
  openEditWidget,
  setOpenEditWidget,
  radioButtonData,
  radioChartData,
  updateFilter,
}) => {
  const [selectedChartValue, setSelectedChartValue] = useState(
    radioChartData?.value
  );
  const [selectedDateValue, setSelectedDateValue] = useState(
    radioButtonData?.value
  );

  const handleApply = () => {
    updateFilter(selectedChartValue, "graph");
    updateFilter(selectedDateValue, "date");
    setOpenEditWidget(false);
  };
  return (
    <>
      <IonModal
        className="editwidget-modal"
        isOpen={openEditWidget}
        onDidDismiss={() => setOpenEditWidget(false)}
        // breakpoints={[0, 0.9]}
        initialBreakpoint={0.9}
        // backdropBreakpoint={0.2}
        // style={{ overflowY: "scroll" }}
      >
        <IonHeader className="editwidget-header">
          <IonToolbar className="editwidget-toolbar">
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  setOpenEditWidget(false);
                }}
              >
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle className="editwidget-title">Edit widget</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="editwidget-content">
          <div className="body-container-scroll">
            <div className="date-section">
              <p>Date range</p>
              <IonRadioGroup
                value={selectedDateValue}
                onIonChange={(e) => setSelectedDateValue(e.detail.value)}
                className="radio-group"
              >
                {radioButtonData.buttons.map((item: any) => (
                  <div className="radio-item">
                    <RadioButton value={item?.value} className="fm-radio-btn" />
                    <IonLabel className="radio-item-text">
                      {item?.name}
                    </IonLabel>
                  </div>
                ))}
              </IonRadioGroup>
            </div>

            <div className="graph-section">
              <p>Graph type</p>
              <IonRadioGroup
                value={selectedChartValue}
                onIonChange={(e) => setSelectedChartValue(e.detail.value)}
                className="radio-group"
              >
                {radioChartData.buttons.map((item: any) => (
                  <div
                    key={item.id}
                    className={`radiochart-item-container ${
                      selectedChartValue === item?.value ? "checked" : ""
                    }`}
                  >
                    <div className="radio-wrapper">
                      <RadioButton
                        value={item?.value}
                        className="fm-radio-btn"
                      />
                      <span className="item-name">{item?.name}</span>
                    </div>
                    <div className="image-wrapper">
                      <IonImg src={item?.image} />
                    </div>
                  </div>
                ))}
              </IonRadioGroup>
            </div>
          </div>
        </IonContent>

        <IonFooter className="editwidget_footer">
          <Button
            tallbutton
            boldfont
            fullWidth
            color="primary"
            onClick={handleApply}
          >
            <div className="button-text">Apply</div>
          </Button>
        </IonFooter>
      </IonModal>
    </>
  );
};

export default EditWidget;
