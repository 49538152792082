import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Tab, { TabNav, TabRouter } from "../../components/Common/Tab/Tab";

const TabPOC: React.FC = () => {
  return (
    <IonPage>
      <IonHeader className="no-shadow no-border">
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton text={""} />
          </IonButtons>
          <IonTitle>Census</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <Tab>
          <TabRouter>
            <TabNav component={Tab1}>Inpatient</TabNav>
            <TabNav component={Tab2}>Encounters</TabNav>
          </TabRouter>
        </Tab>
      </IonContent>
    </IonPage>
  );
};

export const Tab1: React.FC = () => {
  return <div>Inpatient</div>;
};
export const Tab2: React.FC = () => {
  return <div>Encounters</div>;
};

export default TabPOC;
