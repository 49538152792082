/* eslint-disable react-hooks/exhaustive-deps */
import { IonApp, setupIonicReact } from "@ionic/react";
import * as mobiscroll from "@mobiscroll/react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import Router from "./router/Router";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { selectTheme, switchTheme } from "./store/reducers/layout";
import { useEffect } from "react";
import { selectUser } from "./store/reducers/user";
import { providerLogin, rememberAuth } from "./store/thunk/userThunk";
import { useLocalStorage } from "./hooks/useLocalStorage";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { socket, socket1 } from "./socket";
import { updateVisit, visitsObject } from "./services/CensusProvider";
import { Plugins } from "@capacitor/core";
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

// import { TokenType } from "./types/Types";
// import  Login  from "./pages/Login/Login";

setupIonicReact();

const { StatusBar } = Plugins;

const App: React.FC = () => {
  const isDarkTheme = useAppSelector(selectTheme);
  const userData = useAppSelector(selectUser);

  const [token] = useLocalStorage("Token");
  const [darkTheme] = useLocalStorage("darkTheme");

  const dispatch = useAppDispatch();

  useEffect(() => {
    socket.on("latest_message");
    socket.on("push-notification-messages-lists");
    socket.on("users-changed");

    // socket.on("visit-update", (data: any) => {

    //   let room = data.room;
    //   socket.to(room).emit("visit-changes", data.visit);
    // });
    socket.removeAllListeners("visit-changes");
    socket1.on("visit-changes", (data: any) => {
      if (data.silentUpdate) {
        for (let tmpVisitId in visitsObject) {
          if (tmpVisitId == data._id) {
            visitsObject[tmpVisitId] = Object.assign(
              visitsObject[tmpVisitId],
              data
            );
          }
        }
      } else {
        updateVisit(data);
      }
    });

    return () => {
      socket.off("latest_message");
      socket.off("push-notification-messages-lists");
      socket.off("users-changed");
      socket1.off("visit-changes");
    };
  }, [socket1]);

  // console.log({ StatusBar });

  useEffect(() => {
    if (StatusBar?.setBackgroundColor) {
      StatusBar.setBackgroundColor({
        color: isDarkTheme ? "#170435" : "#ffffff",
      });

      if (StatusBar?.setStyle) {
        StatusBar.setStyle({ style: isDarkTheme ? "DARK" : "LIGHT" });
      }
    }
  }, [isDarkTheme]);

  useEffect(() => {
    if (token?.authToken) {
      dispatch(rememberAuth());
    }
  }, [token?.authToken]);

  useEffect(() => {
    if (darkTheme !== undefined) {
      dispatch(switchTheme(darkTheme));
    }
  }, [darkTheme]);

  useEffect(() => {
    if (isDarkTheme) {
      document?.body?.classList?.add("dark");
      mobiscroll.setOptions({ themeVariant: "dark" });
    } else {
      document?.body?.classList?.remove("dark");
      mobiscroll.setOptions({ themeVariant: "light" });
    }
  }, [isDarkTheme]);

  // useEffect(() => {
  //   if (
  //     userData?.authToken !== null &&
  //     userData?.practice !== null &&
  //     userData?.provider !== null &&
  //     !userData?.isProviderLogin
  //   ) {
  //
  //     dispatch(
  //       providerLogin({
  //         practice_id: userData?.provider?.practice_id
  //           ? userData?.provider?.practice_id
  //           : "",
  //         provider_id: userData?.provider?.provider_id
  //           ? userData?.provider?.provider_id
  //           : userData?.provider?._id,
  //       })
  //     );
  //   }
  // }, [userData?.practice, userData?.provider]);

  return (
    <IonApp>
      <Router
        isAuth={userData?.authToken !== null}
        itsInSelection={
          userData?.authToken !== null &&
          (userData?.practice === null || userData?.provider === null)
        }
      />
    </IonApp>
  );
};

export default App;
