import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface LayoutState {
  darkTheme: boolean;
}

const initialState: LayoutState = {
  darkTheme: true,
};

export const layout = createSlice({
  name: "layout",
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.darkTheme = !state.darkTheme;
      localStorage.setItem("darkTheme", JSON.stringify(!state.darkTheme));
    },
    switchTheme: (state, action: PayloadAction<boolean>) => {
      state.darkTheme = action.payload;
      localStorage.setItem("darkTheme", JSON.stringify(action.payload));
    },
  },
});

export const { toggleTheme, switchTheme } = layout.actions;

export const selectTheme = (state: RootState) => state.layout.darkTheme;

export default layout.reducer;
