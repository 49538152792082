import React from "react";
import { IonCol, IonRow, IonGrid, IonText, useIonRouter } from "@ionic/react";
import "./MarkedData.scss";
import moment from "moment";

const markedEncounterData = [
  {
    day: 12,
    Diagnosis: "Missing",
    Procedures: 99232,
    Status: "Returned",
    Loggedby: "AI Czervic",
  },
  {
    day: 17,
    Diagnosis: "Missing",
    Procedures: 99232,
    Status: "Returned",
    Loggedby: "AI Czervic",
  },
  {
    day: 18,
    Diagnosis: "Missing",
    Procedures: 99232,
    Status: "Returned",
    Loggedby: "AI Czervic",
  },
];

type MarkedDataProps = {
  data?: any[];
};

const MarkedData: React.FC<MarkedDataProps> = (props) => {
  const router = useIonRouter();
  const encounterHistroyClick = (_id: any) => {
    router.push(`/patientDetails/${_id}`, "forward");
  };

  return (
    <IonGrid className="row-data-encounterhistory ion-no-margin ion-no-padding">
      {props.data?.map((item: any) => (
        <IonRow
          className="row-gap-encounterhistory ion-no-margin ion-no-padding ion-nowrap"
          key={item?._id}
          onClick={() => encounterHistroyClick(item?._id)}
        >
          <IonCol className="col-data-encounterhistory" size="1">
            <p className="col-p-encounterhistory">
              {moment(item?.service_date_from).date()}{" "}
            </p>
          </IonCol>
          <IonCol size="2.5">
            <p className="row-heading-encounterhistory ion-no-padding ion-no-margin">
              {" "}
              Diagnosis{" "}
            </p>
            <p className="ion-no-margin row-p-data-encounterhistory">
              {" "}
              {item?.diagnosis_code_primary?.description}{" "}
            </p>
          </IonCol>
          <IonCol size="2.5">
            <p className="row-heading-encounterhistory ion-no-padding ion-no-margin">
              Procedures{" "}
            </p>
            <p className="ion-no-margin row-p-data-encounterhistory">
              {" "}
              {item?.procedure_code_primary?.code}{" "}
            </p>
          </IonCol>
          <IonCol size="2.5">
            <p className="row-heading-encounterhistory ion-no-padding ion-no-margin">
              Status{" "}
            </p>
            <p className="ion-no-margin row-p-data-encounterhistory">
              {" "}
              {item?.status}{" "}
            </p>
          </IonCol>
          <IonCol size="3">
            <p className="row-heading-encounterhistory ion-no-padding ion-no-margin">
              {" "}
              Logged by{" "}
            </p>
            <IonText className="ion-no-margin row-p-data-encounterhistory">
              {" "}
              {item?.rendering_provider_name}{" "}
            </IonText>
          </IonCol>
        </IonRow>
      ))}
    </IonGrid>
  );
};
export default MarkedData;
