import { useEffect, useState } from "react";
import { IonIcon, IonModal, IonTextarea } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import Buttons from "../Common/Buttons/Buttons";
import "./Census.scss";
import { addendumText } from "../../helper/backendHelper";
import { setError } from "../../store/reducers/forgotPassword";
interface AddendumModalprops {
    isFilterOpen: boolean;
    handleFilterModal: (item: boolean) => void;
    handleFilterChange: (sort: String) => any;
    data1: any;
    selectedFilterOptionList?: any;
}

const AddendumModal = (props: AddendumModalprops) => {
    const [textArea, setTextArea] = useState("");
    //console.log("chargeid",props.data1);
    const saveeAddendum = () => {
        let body = {
            addendum: textArea
        }
        addendumText(props.data1.chargeid, body).then((res: any) => {
            if (res?.status == 400) {
                setError("Server Error");
            } else {
                if (res?.status == "success") {
                    console.log("successs addendum");

                } else {
                    setError(res?.message ? res?.message : "Update failed");
                }
            }
        });
        props.handleFilterModal(false);
    };
    return (
        <IonModal
            className="filter-modal"
            isOpen={props.isFilterOpen}
            onDidDismiss={() => {
                props.handleFilterModal(false);
                setTextArea("");
            }}
            breakpoints={[0, 0.3, 0.4, 0.8, 1]}
            initialBreakpoint={0.8}
            style={{ overflowY: "scroll", height: "100vh" }}
        >
            <div className="modal-content-container" style={{ overflow: "hidden" }}>
                <div className="modal-heading-container">
                    <div className="modal_heading_filter">New addendum</div>
                    <div className="modal-icon-holder">
                        <IonIcon
                            onClick={() => props.handleFilterModal(false)}
                            className="close-icon"
                            icon={closeOutline}
                        />
                    </div>
                </div>

                <div style={{ height: "60vh", margin: "5%" }} className="label-content">
                    <label className="textareaheading">{props.data1.name} | </label>
                    <label className="textarealabel">{props.data1.dob}</label>
                    <br />
                    <label className="textarealabel">
                        Service date: {props.data1.serviceDate}
                    </label>
                    <br />
                    <label className="textarealabel">
                        Facility: {props.data1.Facility}
                    </label>
                    <br />
                    <label className="textarealabel">{props.data1.signedBy}</label>
                    <br />

                    <h3 className="textarea_label"> Addendum</h3>
                    <div>
                        <IonTextarea
                            className="input-box"
                            onIonChange={(e: any) => {
                                setTextArea(e.target.value);
                            }}
                            value={textArea}
                        ></IonTextarea>
                    </div>
                </div>
                <div className="addendummodalbtn">
                    <div className="left-btn-holder">
                        <Buttons boldfont
                            disabled={!textArea ? true : false}
                            fullWidth
                            color="primary"
                            onClick={saveeAddendum}>
                            Create Addendum
                        </Buttons>
                    </div>
                    {/* <div className="left-btn-holder">
                        <Buttons
                            boldfont
                            fullWidth
                            color="secondary"
                        >
                            View last signed note
                        </Buttons>
                    </div> */}
                    {/* <Toast
                        toastList={testList}
                        position="top-right"
                    /> */}
                </div>

            </div>
        </IonModal>
    );
};
export default AddendumModal;
