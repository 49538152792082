import { IonGrid, IonIcon, IonInput, IonLabel } from "@ionic/react";
import React, { useState } from "react";
import { informationCircleOutline, addOutline } from "ionicons/icons";
import "./PastMedicalCard.scss";

const PastMedicalCard: React.FC = () => {
  const [dropDown, setDropDown] = useState(false);
  const expandCard = () => {
    setDropDown(true);
  };
  const closeCard = () => {
    setDropDown(false);
  };
  return (
    <React.Fragment>
      <IonGrid className="pastmedical-ion-grid">
        <div>
          <div className="txtbox-grp">
            <IonLabel className="textbox-medical-label">
              Medical history
            </IonLabel>
            <IonInput className="txtbox-input"></IonInput>
            <IonLabel className="textbox-label">Past surgical history</IonLabel>
            <IonInput className="txtbox-input"></IonInput>
            <IonLabel className="current-label">Current medications</IonLabel>
            <IonInput className="square-checkbox">
              <IonLabel className="review-lbl">
                Reviewed in facility chart / EMR
              </IonLabel>{" "}
              <div className="info-style">
                <IonIcon
                  icon={informationCircleOutline}
                  className="icon-style-past"
                ></IonIcon>
              </div>
            </IonInput>
            <IonLabel className="textbox-label">Pertinent for</IonLabel>
            <IonInput className="txtbox-input"></IonInput>
            <IonLabel className="current-label">Allergies</IonLabel>
            <IonInput className="square-checkbox">
              <IonLabel className="review-lbl">NKDA</IonLabel>{" "}
              <div className="info-style">
                <IonIcon
                  className="review-lbl-icon"
                  icon={informationCircleOutline}
                ></IonIcon>
              </div>
            </IonInput>
            <div className="add-one">
              <IonIcon className="add-circle" icon={addOutline}></IonIcon>
              <IonLabel className="add-one-lbl">Add new allergy</IonLabel>
            </div>
          </div>
        </div>
      </IonGrid>
    </React.Fragment>
  );
};
export default PastMedicalCard;
