import React from "react";
import { Route, Redirect } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";
import MFALogin from "../pages/MFA/MFALogin";
import Login from "../pages/Login/Login";
import OtpVerify from "../pages/OtpVerify/OtpVerify";
import VerificationCode from "../pages/ForgotPassword/VerificationCode";
import PasswordRecovery from "../pages/ForgotPassword/PasswordRecovery";
import SetNewPassword from "../pages/ForgotPassword/SetNewPassword";
// import UiComponents from "../pages/UiComponents/UiComponents";
// import ProgressNote from "../pages/ProgressNote/ProgressNote";
import ResetPasswordSuccessful from "../pages/ForgotPassword/ResetPasswordSuccessful";

const PublicRoutes: React.FC = () => {
  return (
    <>
      {
        <Route
          exact
          path="*"
          render={() => {
            return <Redirect to="/login" />;
          }}
        />
      }
      <IonRouterOutlet basePath="/" id="publicRoute">
        {/* <Redirect from="/" to="/login" /> */}
        <Route exact path="/mfa">
          <MFALogin />
        </Route>
        <Route exact path="/otpVerify">
          <OtpVerify />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>

        <Route exact path="/">
          <Redirect to="/login" />
        </Route>

        <Route exact path="/verificationCode">
          <VerificationCode />
        </Route>

        <Route exact path="/passwordRecovery">
          <PasswordRecovery />
        </Route>

        <Route exact path="/setNewPassword">
          <SetNewPassword />
        </Route>

        <Route exact path="/resetPasswordSuccessful">
          <ResetPasswordSuccessful />
        </Route>
      </IonRouterOutlet>
    </>
  );
};

export default PublicRoutes;
