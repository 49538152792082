import {
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  arrowUndoOutline,
  cameraOutline,
  chevronBackOutline,
  createOutline,
  micCircle,
  starOutline,
} from "ionicons/icons";
import React from "react";
import Buttons from "../../components/Common/Buttons/Buttons";
import Tab, { TabNav, TabRouter } from "../../components/Common/Tab/Tab";
import "./VisitDetailsOutline.scss";

export const VisitDetailsOutline: React.FC = () => {
  // const [value, setValue] = React.useState(0);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="header-ion">
            <IonIcon icon={starOutline}></IonIcon>
            Smith, Andrew
          </IonTitle>
          <IonIcon slot="start" icon={chevronBackOutline}></IonIcon>
          <div slot="end" className="ion-icon">
            <div>
              <IonIcon slot="end" icon={createOutline}></IonIcon>
              <IonIcon slot="end" icon={cameraOutline}></IonIcon>
              <IonIcon slot="end" icon={micCircle}></IonIcon>
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <div className="ion-tab">
        <IonRow>
          <IonContent fullscreen>
            <Tab>
              <TabRouter>
                <TabNav component={Tab1}>Patient Details</TabNav>
                <TabNav component={Tab2}>Payment</TabNav>
                <TabNav component={Tab3}>Visit History</TabNav>
              </TabRouter>
            </Tab>
          </IonContent>
        </IonRow>
      </div>
      <IonFooter>
        <div className="btn-div">
          <Buttons fullWidth variant="destructive" color="secondary">
            <IonRow>
              <IonCol>
                <IonIcon className="btn-ion" icon={arrowUndoOutline}></IonIcon>
              </IonCol>
              <IonCol className="text">Undo visit confirmation</IonCol>
            </IonRow>
          </Buttons>
        </div>
      </IonFooter>
    </IonPage>
  );
};
export const Tab1: React.FC = () => {
  return <div>Patient Details</div>;
};
export const Tab2: React.FC = () => {
  return <div>payment</div>;
};
export const Tab3: React.FC = () => {
  return <div>Visit History</div>;
};
export default VisitDetailsOutline;
