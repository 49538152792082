import { IonCol, IonFooter, IonRow, IonText } from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Card from "../../../components/Common/Card/Card";
import { callDashboardTopCard } from "../../../helper/backendHelper";
import { useAppSelector } from "../../../store/hooks";
import { selectTheme } from "../../../store/reducers/layout";
import BarsDisplay from "./BarsDisplay";
import "./CardDisplay.scss";

interface ScopeWiseCardProps {
  items?: any;
}

const ScopeWiseCard: React.FC<ScopeWiseCardProps> = ({ items }) => {
  const isDarkTheme = useAppSelector(selectTheme);
  const selectedPractice = useAppSelector((state) => state.user)?.practice;
  const selectedProvider = useAppSelector((state) => state.user)?.provider;
  let startDate = moment().startOf("week").format("YYYY-MM-DD");
  let endDate = moment().endOf("week").format("YYYY-MM-DD");

  const [selectedDateRange, setSelectedDateRange] = useState([
    startDate,
    endDate,
  ]);
  const [topcardData, setTopcardData] = useState<any>([]);

  useEffect(() => {
    getAPIData(selectedDateRange, items);
  }, [selectedDateRange, items]);

  const getAPIData = async (selectedDateRange: any, items: any) => {
    // For filtering Practice_daily object from encounters API
    // let filteredPractice_dailyEncountersData = [];

    let callDashboardTopCardData = {
      scope: items.scope,
      practice_id: selectedPractice?._id,
      provider_id: selectedProvider?._id,
      service_date: {
        start: selectedDateRange[0],
        end: selectedDateRange[1],
      },
    };
    let topcard_data = await callDashboardTopCard(callDashboardTopCardData);

    if (topcard_data.status === "SUCCESS") {
      setTopcardData(topcard_data.data);
    }
  };

  let chartDataPoints = [
    { label: "Sun", value: { income: 0, encounter: 0 } },
    { label: "Mon", value: { income: 0, encounter: 0 } },
    { label: "Tue", value: { income: 0, encounter: 0 } },
    { label: "Wed", value: { income: 0, encounter: 0 } },
    { label: "Thu", value: { income: 0, encounter: 0 } },
    { label: "Fri", value: { income: 0, encounter: 0 } },
    { label: "Sat", value: { income: 0, encounter: 0 } },
  ];

  topcardData?.daily_report?.forEach((item: any) => {
    let index = chartDataPoints.findIndex(
      (e: any) => e.label === moment(item?._id.service_date_from).format("ddd")
    );
    if (index >= 0) {
      chartDataPoints[index].value.income += item.insurancePendingAmt;
      chartDataPoints[index].value.encounter += item.pendingEncounters;
    }
  });

  let maxIncomePoint = Math.max(
    ...chartDataPoints.map((item: any) => item.value.income)
  );
  let minIncomePoint = Math.min(
    ...chartDataPoints.map((item: any) => item.value.income)
  );
  let maxEncounterPoint = Math.max(
    ...chartDataPoints.map((item: any) => item.value.encounter)
  );
  let minEncounterPoint = Math.min(
    ...chartDataPoints.map((item: any) => item.value.encounter)
  );

  let chartDataPointsTotal: any = { totalIncome: 0, totalEncounter: 0 };
  let length = chartDataPoints.length;

  chartDataPoints.forEach((item: any) => {
    chartDataPointsTotal.totalIncome += item.value.income;
    chartDataPointsTotal.totalEncounter += item.value.encounter;
  });

  let avgIncomePoint = parseInt(chartDataPointsTotal.totalIncome) / length;
  let avgEncounterPoint = parseFloat(
    (chartDataPointsTotal.totalEncounter / length).toFixed(2)
  );

  let arrayIncome: any = [];
  chartDataPoints.forEach((item: any) => {
    arrayIncome = [
      ...arrayIncome,
      { label: item.label, value: item.value.income },
    ];
  });

  let arrayEncounter: any = [];
  chartDataPoints.forEach((item: any) => {
    arrayEncounter = [
      ...arrayEncounter,
      { label: item.label, value: item.value.encounter },
    ];
  });

  return (
    <Card
      className={`bars-cardsdisplay ${!isDarkTheme ? "bg-light-mode" : ""}`}
    >
      <IonRow class="ion-grid-columns:4">
        <IonCol
          class="ion-align-self-start"
          size="auto"
          className="col-pendingestimated"
        >
          <IonText className="text-style-pendingestimated">
            {items?.count_icon}
            {items?.id == "1" || items?.id == "3"
              ? topcardData?.insuranceEstimatedAmt
              : topcardData?.completedEncounters}
            <IonFooter
              className="text-style1-pendingestimated"
              class="ion-no-padding ion-no-margin"
            >
              {items?.ptext1}
            </IonFooter>
          </IonText>
        </IonCol>
        <IonCol class="ion-align-self-start, my-thin-col"></IonCol>
        <IonCol class="ion-align-self-start, my-thin-col"></IonCol>
        <IonCol class="ion-align-self-start, my-thin-col"></IonCol>
        <IonCol
          class="ion-align-self-start"
          size="auto"
          className="col-pendingestimated"
        >
          <IonText className="text-style-pending">
            {items?.count_icon}
            {items?.id == "1" || items?.id == "3"
              ? topcardData?.insurancePendingAmt
              : topcardData?.pendingEncounters}
            {/* {topcardData?.pendingEncounters} */}
            <IonFooter className="text-style1-pendingestimated">
              {items?.ptext2}
            </IonFooter>
          </IonText>
        </IonCol>
      </IonRow>

      <IonRow class="ion-grid-columns:col-12">
        <IonCol
          class="ion-align-self-start"
          className="ion-text-cardsdisplay"
          size="auto"
        >
          {topcardData?.totalRvn?.toFixed(2)}{" "}
        </IonCol>
        <IonCol
          class="ion-align-self-start"
          className="ion-RVUs-text-cardsdisplay"
          size="auto"
        >
          {" "}
          RVUs
        </IonCol>
      </IonRow>

      <IonRow class="ion-grid-columns:6, align-items-end">
        <IonCol class="ion-align-self-start" size="9.5"></IonCol>
        <IonCol class="ion-align-self-right" size="2">
          <div className="text-800-color">
            {items?.count_icon}
            {items?.id == "1" || items?.id == "3"
              ? maxIncomePoint
              : maxEncounterPoint}
          </div>
        </IonCol>
        <IonCol class="ion-align-self-start, my-thin-col"></IonCol>
      </IonRow>

      <IonRow class="ion-grid-columns:6">
        <IonCol class="ion-align-self-start" size="auto">
          <BarsDisplay
            cardData={
              items?.id == "1" || items?.id == "3"
                ? arrayIncome
                : arrayEncounter
            }
          />
        </IonCol>
        <IonCol class="ion-align-self-start, my-thin-col"></IonCol>
        <IonCol class="ion-align-self-start, my-thin-col"></IonCol>
        <IonCol class="ion-align-self-start, my-thin-col"></IonCol>

        <IonCol class="ion-align-self-right">
          <div className="ion-bar-amount-carddisplay">
            {items?.count_icon}
            {items?.id == "1" || items?.id == "3"
              ? avgIncomePoint
              : avgEncounterPoint}
          </div>
          <div className="ion-bar-avg-carddisplay ">avg</div>
          <div className="ion-bar-zero-carddisplay">
            {items?.id == "1" || items?.id == "3" ? "$" : ""}
            {items?.id == "1" || items?.id == "3"
              ? minIncomePoint
              : minEncounterPoint}
          </div>
        </IonCol>
        <IonCol class="ion-align-self-start, my-thin-col"></IonCol>
      </IonRow>
      <IonRow>
        <IonText className="ion-below-text-cardsdisplay">
          {items?.scope == "currentpractice" ? "Practice" : "Provider"}
        </IonText>
      </IonRow>
    </Card>
  );
};

export default ScopeWiseCard;
