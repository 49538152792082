/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "../Buttons/Buttons";
import ProgressBarHorizontal from "./ProgressBarHorizontal";
import ProgressBarHorizontalDetailed from "./ProgressBarHorizontalDetailed";
import ProgressBarVertical from "./ProgressBarVertical";

import "./ProgressBar.scss";
import ProgressBarScroll from "./ProgressBarScroll";

type ProgressBarProps = {
  variant: "vertical" | "horizontal" | "horizontal-detailed" | "scroll";
  data: any;
  useFile: string;
  handleNext: (data: any, activeId: string) => void;
};

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const [data, setData] = useState<any>(props.data.data);
  const [activeId, setActiveId] = useState(props.data.activeId);

  const handleDone = () => {
    const index = data.findIndex((item: any) => item.id === activeId);
    if (index > 0) {
      let newData = JSON.parse(JSON.stringify(data));
      newData[index].completed = true;

      setData(newData);
      if (index < newData.length - 1) setActiveId(newData[index + 1].id);
      if (index === newData.length - 1) setActiveId("-1");
    }
  };
  const handleStep = () => {
    // const index = data.findIndex((item: any) => item.id === activeId);
    // if (props.useFile == "ProgressNote") {
    //   let newData = JSON.parse(JSON.stringify(data));
    //   newData[0].completed = true;

    //   setData(newData);
    //   setActiveId(newData[1].id);
    // }
    // if (props.useFile == "Subjective") {
    //   let newData = JSON.parse(JSON.stringify(data));
    //   newData[0].completed = true;
    //   newData[1].completed = true;

    //   setData(newData);
    //   setActiveId(newData[2].id);
    // }
    // if (props.useFile == "Objective") {
    //   let newData = JSON.parse(JSON.stringify(data));
    //   newData[0].completed = true;
    //   newData[1].completed = true;
    //   newData[2].completed = true;
    //   setData(newData);
    //   setActiveId(newData[3].id);
    // }
    // if (props.useFile == "Assesment") {
    //   let newData = JSON.parse(JSON.stringify(data));
    //   newData[0].completed = true;
    //   newData[1].completed = true;
    //   newData[2].completed = true;
    //   newData[3].completed = true;
    //   setData(newData);
    //   setActiveId(newData[4].id);
    // }
    // if (props.useFile == "Plan") {
    //   let newData = JSON.parse(JSON.stringify(data));
    //   newData[0].completed = true;
    //   newData[1].completed = true;
    //   newData[2].completed = true;
    //   newData[3].completed = true;
    //   newData[4].completed = true;
    //   setData(newData);
    //   setActiveId(newData[5].id);
    // }
  };

  useEffect(() => {
    setData(props.data.data);
    setActiveId(props.data.activeId);
    handleStep();
  }, [props.data]);

  useEffect(() => {
    props.handleNext(data, activeId);
  }, [data, activeId]);

  return (
    <div className="process-bar">
      <div className="alignmentcenter">
        {props.useFile ? (
          <></>
        ) : (
          <Button onClick={handleDone} disabled={activeId === "-1"}>
            Done
          </Button>
        )}
      </div>
      {props.variant === "scroll" && (
        <ProgressBarScroll data={data} activeId={activeId} />
      )}
      {props.variant === "vertical" && (
        <ProgressBarVertical data={data} activeId={activeId} />
      )}
      {props.variant === "horizontal" && (
        <ProgressBarHorizontal data={data} activeId={activeId} />
      )}
      {props.variant === "horizontal-detailed" && (
        <ProgressBarHorizontalDetailed data={data} activeId={activeId} />
      )}
    </div>
  );
};

export default ProgressBar;
