/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  useIonRouter,
  IonFooter,
  IonIcon,
  IonGrid,
  IonRow,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import { useAppSelector } from "../../store/hooks";
import "./Icd10DiagnosisLookup.scss";
import SearchBox from "../../components/Common/SearchBox/SearchBox";
import { chevronBackOutline } from "ionicons/icons";
import {
  favoriteDiagnosis,
  getDiagnosisSortLimit,
  unFavoriteDiagnosis,
} from "../../helper/backendHelper";
import { star, starOutline } from "ionicons/icons";
import Loader from "../../components/Common/Loaders/Loader";

interface DiagnosisSearchInterface {
  code: string;
  description: string;
  long_description: string;
  enabled: boolean;
  favourite: boolean;
  isSelected?: boolean;
}

const ICD10DiagnosisLookup: React.FC<any> = () => {
  const [searchValue, setsearchValue] = useState("");
  const [diagnosisData, setDiagnosisData] = useState<
    DiagnosisSearchInterface[]
  >([]);
  const [loading, setLoading] = useState(true);
  const router = useIonRouter();
  const darkThemeBoolean = useAppSelector((state) => state.layout.darkTheme);
  let favArray: any = [];

  const [disableInfiniteScroll, setDisableInfiniteScroll] =
    useState<boolean>(false);
  const [count, setCount] = useState<number>(10);

  const handleCancel = () => {
    router.goBack();
  };

  const diagnosisListHandler = async (codeSearch: string, count: number) => {
    try {
      let res = await getDiagnosisSortLimit({
        search: codeSearch,
        startIndex: 0,
        count: count,
      });
      let newResult: any[] = [];
      res.results.forEach((item: any) => {
        newResult = [...newResult, item];
      });
      setDiagnosisData(newResult);
      setLoading(false);
      if (res.results.length === res.total) setDisableInfiniteScroll(true);
    } catch (error) { }
  };

  useEffect(() => {
    diagnosisListHandler(searchValue, count);
  }, [searchValue, count]);

  const searchhandler = (e: string) => {
    setsearchValue(e);
  };

  const favoriteHandler = (item: any) => {
    let newArray: DiagnosisSearchInterface[] = diagnosisData.map(
      (list: DiagnosisSearchInterface, idx) => {
        if (list.code === item.code) {
          diagnosisFavoriteHandler({ ...item, favorite: !item.favorite });
          return { ...item, favorite: !item.favorite };
        } else {
          return list;
        }
      }
    );
    setDiagnosisData(newArray);
    //setSelectedData(newArray.filter((list:any) => list?.isSelected === true));
  };

  const diagnosisFavoriteHandler = async (item: any) => {
    let res: any;

    favArray.push(item.code);

    if (item.favorite) {
      res = await favoriteDiagnosis(favArray);
    } else {
      res = await unFavoriteDiagnosis(item.code);
    }
  };

  const onPageLoad = (e: any) => {
    setCount(count + 10);
    setTimeout(() => {
      e?.target?.complete();
    }, 200);
  };

  // const infiniteLoaderHandler = async (e: any) => {
  //   let res = await getDiagnosisSearch(searchValue);
  //   let newResult: any[] = [];
  //   res.results.forEach((item: any) => {
  //     newResult = [...newResult, item];
  //   });
  //   setDiagnosisData(newResult);
  //   if (newResult.length < 10) {
  //     setDisableInfiniteScroll(true);
  //     (e.target as HTMLIonInfiniteScrollElement).complete();
  //   }
  // };

  return (
    <IonPage className="icd10-diagnosis-lookup">
      <IonHeader className="icd-header">
        <IonToolbar mode="ios" className="icd-toolbar">
          <IonTitle className="icd-title">ICD10 Diagnosis Lookup</IonTitle>
          <IonButtons slot="secondary">
            <IonButton onClick={handleCancel}>
              <IonIcon
                className="icd-back-icon"
                icon={chevronBackOutline}
              ></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="icd-content">
        <IonGrid className="icd-content-grid">
          <IonRow className="searchbar-container-holder">
            <div className="searchbar-container">
              <SearchBox
                placeholder="Search by code or description..."
                onChange={searchhandler}
                value={searchValue}
              />
            </div>
          </IonRow>
          <IonRow className="icd-content-holder">
            {loading ? (
              <div className="loaderPatientSearch">
                <Loader
                  data={{
                    name: "crescent",
                    color: "primary",
                  }}
                />
              </div>
            ) : (
              <>
                {diagnosisData?.length === 0 && (
                  <div className="no-result-message">
                    <p className="selectcodetext user-message">
                      No result found
                    </p>
                  </div>
                )}

                {diagnosisData?.length > 0 &&
                  diagnosisData.map((item: any, index: number) => {
                    return (
                      <div className="addedDiagnosis-newdesign" key={index}>
                        <div
                          onClick={() => favoriteHandler(item)}
                          className={`content_list_itemIcon-diagnosiscode-newdesign ${item.favorite && "favourite"
                            }`}
                        >
                          <IonIcon
                            icon={
                              item?.favorite === true
                                ? star
                                : !darkThemeBoolean
                                  ? starOutline
                                  : star
                            }
                          />
                        </div>

                        <div className="addedDiagnosisInfo-newdesign">
                          <IonText className={`addedDiagnosisText-newdesign`}>
                            {item.code}
                          </IonText>
                          <IonText className={`addedDiagnosisPara-newdesign`}>
                            {item.description}
                          </IonText>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
            <IonInfiniteScroll
              threshold="100px"
              id="infinite-scroll"
              disabled={disableInfiniteScroll}
              onIonInfinite={(e: any) => onPageLoad(e)}
            >
              <IonInfiniteScrollContent loadingSpinner="bubbles"></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter className="icd-footer"></IonFooter>
    </IonPage>
  );
};

export default ICD10DiagnosisLookup;
