import React from 'react';
import './Card.scss';

import {
  IonCard,
  } from "@ionic/react";

interface CardProps  {
  
  className ?: any;
  
};

const Card:React.FC <CardProps>  = (props:any) => {
  const classes = 'card-bars ' + props.className;
  return (
    <IonCard className={classes}>
      {props.children}
    </IonCard>
  )
};

export default Card;
