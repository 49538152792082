import React from "react";
import { IonText, IonIcon, IonRow, IonCol, useIonRouter } from "@ionic/react";
import { pulseOutline, chevronForwardOutline } from "ionicons/icons";
import "./ForYou.scss";

const ForYou: React.FC = () => {
  const router = useIonRouter();
  const handleICD10Lookup = () => {
    router.push("/ico10DiagnosisLookup", "forward");
  };
  return (
    <div>
      <IonText className="ion-foryou-text">For you</IonText>
      <div className="ion-grid-foryou" onClick={handleICD10Lookup}>
        <IonRow className="card-inner">
          <IonCol class="ion-align-self-start my-thin-col1-foryou">
            <IonIcon
              class="ion-align-self-start"
              icon={pulseOutline}
              className="ion-icon1-foryou"
            />
          </IonCol>
          <IonCol
            class="ion-align-self-start"
            className="ion-text-foryou"
            size="auto"
          >
            ICD 10 lookup and favourites
          </IonCol>
          <IonCol class="ion-align-self-end my-thin-col1-foryou">
            <IonIcon
              class="ion-align-self-end"
              icon={chevronForwardOutline}
              className="ion-icon-foryou"
            />
          </IonCol>
        </IonRow>
      </div>
    </div>
  );
};

export default ForYou;
