import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import "./Home.scss";

import { Datepicker } from "@mobiscroll/react";
import {
  Eventcalendar,
  getJson,
  toast,
  MbscCalendarEvent,
  MbscEventcalendarView,
} from "@mobiscroll/react";

const Home: React.FC = () => {
  const router = useIonRouter();

  const [myDate, setMyDate] = useState<Date | null>(null);
  const onMyDateChange = (event: any) => {
    setMyDate(event.value);
  };

  const [myEvents, setEvents] = useState<MbscCalendarEvent[]>([]);

  useEffect(() => {
    getJson(
      "https://trial.mobiscroll.com/events/?vers=5",
      (events: MbscCalendarEvent[]) => {
        setEvents(events);
      },
      "jsonp"
    );
  }, []);

  const onEventClick = useCallback((event) => {
    toast({
      message: event.event.title,
    });
  }, []);

  const view = useMemo<MbscEventcalendarView>(() => {
    return {
      calendar: { type: "week" },
      agenda: { type: "day" },
    };
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonMenuButton slot="start" />
          <IonTitle>Home</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonButton onClick={() => router.push("payment", "back")}>
          Payment Page
        </IonButton>
        <IonButton onClick={() => router.push("visitoutline", "back")}>
          VisitDetailsOutline
        </IonButton>
        <IonButton onClick={() => router.push("addNewEncounter", "back")}>
          Add New Encounter Page
        </IonButton>
        <IonButton onClick={() => router.push("tab", "back")}>Tab</IonButton>
        <IonButton onClick={() => router.push("visitdetails", "back")}>
          Visit Details
        </IonButton>

        <Datepicker
          onChange={onMyDateChange}
          value={myDate}
          inputComponent={IonInput}
        />

        <Eventcalendar
          theme="ios"
          themeVariant="light"
          data={myEvents}
          view={view}
          onEventClick={onEventClick}
        />
      </IonContent>
    </IonPage>
  );
};

export default Home;
