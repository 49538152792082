import {
    IonAvatar,
    IonButton,
    IonCol,
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonItemOptions,
    IonItemSliding,
    IonList,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSearchbar,
    IonSegment,
    IonSegmentButton,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    RefresherEventDetail,
    useIonRouter,
    useIonToast,
} from "@ionic/react";
import { toast } from "@mobiscroll/react";
import {
    add,
    chatbubbles,
    chevronDownCircleOutline,
    chevronForward,
    fileTray,
    pencil,
} from "ionicons/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../components/Common/Loaders/Loader";
import {
    getConversations,
    updateArchiveStatus,
} from "../../../helper/backendHelper";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProviderLoginData } from "../../../types/Types";
import "./MessageList.scss";
import { providerLogin } from "../../../store/thunk/userThunk";
import { getMessageList, loadMore } from "../../../store/thunk/messageThunk";
import { setMessages, setRedirectedFrom, setSelectedChat } from "../../../store/reducers/message";
import SearchBox from "../../../components/Common/SearchBox/SearchBox";
import { debounce } from "lodash";
// import Toast, { newToast } from "../../../components/Common/Toaster/Toast";

// type ToastedList = {
//     id: number;
//     description: string,
// };
const Drafts: React.FC = (props: any) => {
    const [selectedSegment, setSelectedSegment] = useState("draft");
    const messageLists = useAppSelector((state) => state.messages)?.messagesList;
    const archiveLists = useAppSelector((state) => state.messages)?.archiveList;
    const draftLists = useAppSelector((state) => state.messages)?.draftList;
    const selectedChat = useAppSelector((state) => state.messages)?.selectedChat;
    const [messages, setmessages] = useState<any>(JSON.parse(JSON.stringify(messageLists)));
    const [archived, setArchived] = useState<any>(archiveLists);
    const [draft, setDraft] = useState<any>(draftLists);
    const [searchResult, setSearchResult] = useState([]);
    const [searchStatus, setSearchStatus] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
    const [page, setPage] = useState(0);
    const [archivePage, setArchivePage] = useState(0);
    const [draftPage, setDraftPage] = useState(0);
    const [present, dismiss] = useIonToast();
    // const [toastedMessage, setToastedMessage] = useState<ToastedList[]>([]);
    const dispatch = useAppDispatch();

    let Token: any = localStorage.getItem("Token");
    let currentUserId = JSON.parse(Token)?.user?._id;

    const router = useIonRouter();

    useEffect(() => {
        if (selectedSegment === "messages") {
            setmessages(messageLists);
        } else if (selectedSegment === "archive") {
            setArchived(archiveLists);
        } else if (selectedSegment === "draft") {
            setDraft(draftLists);
        }
    }, [messageLists, archiveLists, draftLists])

    const loadData = async (messageType: any, e?: any) => {
        let data: any = {
            q: "",
            page:
                selectedSegment === "messages"
                    ? page + 15
                    : selectedSegment === "archive"
                        ? archivePage + 15
                        : selectedSegment === "draft"
                            ? draftPage + 15
                            : 0,
            type: messageType,
        };
        await dispatch(setSelectedChat({}))
        let res: any = await dispatch(loadMore(data));

        if (res?.payload?.status === 'success' || res?.payload?.status === 'failure') {
            if (messageType === "messages") {
                if (res?.payload?.data?.length === 0) {
                    setInfiniteDisabled(true);
                } else {
                    setPage(page + 15);
                }
            } else if (messageType === "archive") {
                if (res?.payload?.data?.length === 0) {
                    setInfiniteDisabled(true);
                } else {
                    setArchivePage(archivePage + 15);
                }
            } else if (messageType === "draft") {
                if (res?.payload?.data?.length === 0) {
                    setInfiniteDisabled(true);
                } else {
                    setDraftPage(draftPage + 15);
                }
            }
        }
        e?.target?.complete();
    };

    const getConversation = async (messageType: any, e?: any) => {
        if (!e) {
            setLoading(true);
        }
        let data: any = {
            q: "",
            page: 0,
            type: messageType,
        };
        await dispatch(setSelectedChat({}))
        let res: any = await dispatch(getMessageList(data));

        if (res?.payload?.status === 'success' || res?.payload?.status === 'failure') {
            if (messageType === "messages") {
                if (res?.payload?.data?.length === 0) {
                    setInfiniteDisabled(true);
                }
            } else if (messageType === "archive") {
                if (res?.payload?.data?.length === 0) {
                    setInfiniteDisabled(true);
                }
            } else if (messageType === "draft") {
                if (res?.payload?.data?.length === 0) {
                    setInfiniteDisabled(true);
                }
            }
        }

        setLoading(false);
        e?.target?.complete();
    };

    useEffect(() => {
        getConversation(selectedSegment);
    }, []);

    const selectionHandler = (value: any) => {
        if (value === "messages") {
            setArchivePage(0);
            setDraftPage(0);
        } else if (value === "archive") {
            setPage(0);
            setDraftPage(0);
        } else if (value === "draft") {
            setPage(0);
            setArchivePage(0);
        }
        setSelectedSegment(value);
        getConversation(value);
    };

    const filterIt = (terms: any, arr: any) => {
        if ("" === terms) return arr;
        const words = terms?.match(/\w+|"[^"]+"/g);
        if (words !== null) {
            words?.push(terms);
            return arr.filter((a: any) => {
                const v = JSON.stringify(
                    a?.latest_message?.message_text
                )?.toLowerCase();
                const f = JSON.stringify(
                    a?.latest_message?.message_datetime
                )?.toLowerCase();
                return words?.every((val: any) => f?.includes(val) || v?.includes(val));
            });
        } else {
            return arr;
        }
    };

    const filterList = (searchedValue: any) => {
        const search = searchedValue.toLowerCase();
        const updatedList = filterIt(
            search,
            selectedSegment === "messages"
                ? messages
                : selectedSegment === "archive"
                    ? archived
                    : selectedSegment === "draft"
                        ? draft
                        : []
        );
        setSearchResult(updatedList);
        if (searchedValue === "") {
            setSearchStatus(false);
        } else {
            setSearchStatus(true);
        }
    };



    let FilterList = searchStatus
        ? searchResult
        : selectedSegment === "messages"
            ? messages
            : selectedSegment === "archive"
                ? archived
                : selectedSegment === "draft"
                    ? draft
                    : [];

    const searchHandler = (searchedValue: any) => {
        filterList(searchedValue);
    };

    const archieveHandler = async (item: any) => {
        let req = {
            id: item?._id,
            status: "archive",
        };

        let res = await updateArchiveStatus(req);
        if (res) {
            // getConversation(selectedSegment, true);
            let msg = JSON.parse(JSON.stringify(messages))
            for (let i = 0; i < msg?.length; i++) {
                if (msg[i]?._id === item?._id) {
                    msg[i] = { ...msg[i], archived: true };
                }
            }
            setmessages([...msg]);
        }
    };
    const messageHandler = async (item: any) => {
        let req = {
            id: item?._id,
            status: "message",
        };

        let res = await updateArchiveStatus(req);
        if (res) {
            getConversation(selectedSegment, true);
        }
    };

    const undoArchiveHandler = async (item: any) => {
        let req = {
            id: item?._id,
            status: "message",
        };

        let res = await updateArchiveStatus(req);
        if (res) {
            let msg = JSON.parse(JSON.stringify(messages))
            for (let i = 0; i < msg?.length; i++) {
                if (msg[i]?._id === item?._id) {
                    delete msg[i]?.archived;
                    msg[i] = { ...msg[i] };
                }
            }
            setmessages([...msg]);
        }
    };

    const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
        await getConversation(selectedSegment);
        event.detail.complete();
    };
    const currentPractice: any = useAppSelector((state) => state.user.practice);
    const currentProvider: any = useAppSelector((state) => state.user.provider);
    const Practice: any = useAppSelector((state) => state.user.practiceList);
    const Provider: any = useAppSelector((state) => state.user.providerList);

    const conversationSelected = async (conversation: any, index: any) => {
        present({ message: "Please wait", duration: 3000 });
        // let toastMsg = newToast("Please wait...", "Info");
        // setToastedMessage([...toastedMessage, toastMsg]);
        let loggedInPractice = currentPractice;
        let loggedInProvider = currentProvider;
        let isProviderMapped = false;
        let ispracticeMapped = false;
        let dailyCensusAccessFlag = false;
        if (
            conversation.practice_id &&
            conversation.practice_id == loggedInPractice?._id
        ) {
            if (
                conversation.provider_id &&
                conversation.provider_id == loggedInProvider?._id
            ) {
                dailyCensusAccessFlag = true;
                conversationThread(conversation);
            } else {
                dailyCensusAccessFlag = false;
                Provider.forEach((provider: any) => {
                    if (provider.provider_id == conversation.provider_id) {
                        isProviderMapped = true;
                    }
                });
                if (isProviderMapped) {
                    let res = await dispatch(
                        providerLogin({
                            practice_id: conversation.practice_id,
                            provider_id: conversation.provider_id,
                        })
                    ).then((data: any) => {
                        dailyCensusAccessFlag = true;
                        conversationThread(conversation);
                    });
                } else {
                    conversationThread(conversation);
                }
            }
        } else {
            dailyCensusAccessFlag = false;
            Practice.forEach((practice: any) => {
                if (practice.practice_id == conversation.practice_id) {
                    ispracticeMapped = true;
                }
            });
            if (ispracticeMapped) {
                Provider.forEach((provider: any) => {
                    if (provider.provider_id == conversation.provider_id) {
                        isProviderMapped = true;
                    }
                });

                if (isProviderMapped) {
                    let res = await dispatch(
                        providerLogin({
                            practice_id: conversation.practice_id,
                            provider_id: conversation.provider_id,
                        })
                    ).then((data: any) => {
                        toast({
                            message: "Practice changed to " + conversation.practice_name,
                        });
                        // showToast('Practice changed to ' + conversation.practice_name);
                        dailyCensusAccessFlag = true;
                        conversationThread(conversation);
                    });
                } else {
                    conversationThread(conversation);
                }
            } else {
                conversationThread(conversation);
            }
        }
        dismiss();
    };

    const conversationThread = async (conversation: any) => {
        await dispatch(setMessages([]));
        await dispatch(setSelectedChat(conversation));
        await dispatch(setRedirectedFrom(""))
        dismiss();
        router.push(`/messagethread/${conversation?._id}`, "forward");
    };

    return (
        <div>
            <p className="ion-text1-search" style={{ color: "#77787A", marginLeft: "10px" }}>Search</p>

            <IonSearchbar className="ion-toolbar-dashboard"
                placeholder="Search in messages..."
                // showCancelButton="focus"
                // cancelButtonText="X111"
                value={searchText}
                onIonChange={(e) => {
                    setSearchText(e.detail.value!);
                    searchHandler(e.detail.value);
                }}
            ></IonSearchbar>
            <div>
                <IonContent className="MessagingScroller">

                    <IonRefresher
                        onIonRefresh={doRefresh}
                        slot="fixed"
                        pullMin={80}
                        pullMax={150}
                    >
                        <IonRefresherContent
                            pullingIcon={chevronDownCircleOutline}
                            pullingText="Pull to refresh"
                            refreshingSpinner="circles"
                            refreshingText=""
                        ></IonRefresherContent>
                    </IonRefresher>

                    <IonList class="main-card-wrapper">
                        {loading === true ? (
                            <div className="chatLoader">
                                <Loader />
                            </div>
                        ) : loading === false &&
                            FilterList?.length > 0 &&
                            (selectedSegment === "messages" ||
                                selectedSegment === "archive") ? (
                            FilterList?.map((item: any, i: any) =>
                                !item?.archived ? (
                                    <IonItemSliding className="convoSliding" key={i}>
                                        <IonItem
                                            onClick={(e) => {
                                                conversationSelected(item, i);
                                            }}
                                            className="singleconvo"
                                        >
                                            <div className="profilePicture">
                                                {item?.display_message_logo?.length == 0 ||
                                                    (item?.display_message_logo?.length == 1 &&
                                                        item?.display_message_logo[0]?.showIcon == true) ? (
                                                    <IonAvatar className="avatar-style md hydrated">
                                                        <img src={"./assets/Logo.svg"} />
                                                    </IonAvatar>
                                                ) : item?.display_message_logo?.length == 1 &&
                                                    item?.display_message_logo[0]?.showIcon == false ? (
                                                    <IonAvatar className="avatar-style-single-initial">
                                                        <span className="avatar-single-text">
                                                            {item?.display_message_logo[0]?.displayInitials}
                                                        </span>
                                                    </IonAvatar>
                                                ) : item?.display_message_logo?.length > 1 ? (
                                                    <IonAvatar className="avatar-style-div md hydrated">
                                                        {item?.display_message_logo[0]?.showIcon == false && (
                                                            <span className="upper_circle_background">
                                                                {item?.display_message_logo[0]?.displayInitials}
                                                            </span>
                                                        )}
                                                        {item?.display_message_logo[0]?.showIcon == true && (
                                                            <img
                                                                className="upper_circle_background"
                                                                src={"./assets/Logo.svg"}
                                                            />
                                                        )}
                                                        <br />
                                                        {item?.display_message_logo[1]?.showIcon == false && (
                                                            <span className="lower_circle_background">
                                                                {item?.display_message_logo[1]?.displayInitials}
                                                            </span>
                                                        )}
                                                        {item?.display_message_logo[1]?.showIcon == true && (
                                                            <img
                                                                className="lower_circle_background"
                                                                src={"./assets/Logo.svg"}
                                                            />
                                                        )}
                                                        {item?.partipantcount > 0 && (
                                                            <p className="twoMoreText">
                                                                + {item?.partipantcount} more
                                                            </p>
                                                        )}
                                                    </IonAvatar>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className="convoInfo">
                                                <div className="convoInfo-title-wrapper">
                                                    <div className="convoInfo-title-content">
                                                        <IonText className="title">
                                                            <div className="title-wrapper">
                                                                <strong className="title-text">{item?.title}</strong>
                                                            </div>
                                                        </IonText>
                                                        <div className="badgeTextWrapper">
                                                            <p className="badgeText">New</p>
                                                        </div>
                                                    </div>
                                                    <div className="message-time-wrapper">
                                                        <IonText>
                                                            <small className="message-time-text">
                                                                {
                                                                    item?.latest_message?.messageDay
                                                                }
                                                            </small>
                                                        </IonText>
                                                    </div>
                                                </div>
                                                <div className="message-container">
                                                    <IonText>
                                                        <div>
                                                            <small className="message-content">
                                                                {item?.latest_message?.message_type === 1
                                                                    ? item?.latest_message?.message_text
                                                                    : item?.latest_message?.message_type === 2
                                                                        ? "Attachment Image"
                                                                        : item?.latest_message?.message_type === 3
                                                                            ? "Attachment Audio"
                                                                            : ""}
                                                            </small>
                                                        </div>
                                                    </IonText>

                                                </div>
                                                {/* <div className="otherInfo">
                        <IonText>{item?.practice_name}</IonText>
                      </div> */}
                                            </div>
                                            {/* <div className="convoIcon">
                      <IonIcon icon={chevronForward} />
                    </div> */}
                                        </IonItem>
                                        {(selectedSegment === "messages" ||
                                            selectedSegment === "archive") && (
                                                <IonItemOptions>
                                                    {selectedSegment === "messages" ? (
                                                        <IonButton
                                                            onClick={() => archieveHandler(item)}
                                                            className="archiveBtn"
                                                        >
                                                            <IonIcon icon={fileTray} />
                                                            <IonText>Archive</IonText>
                                                        </IonButton>
                                                    ) : selectedSegment === "archive" ? (
                                                        <IonButton
                                                            onClick={() => messageHandler(item)}
                                                            className="archiveBtn"
                                                        >
                                                            <IonIcon icon={fileTray} />
                                                            <IonText>Message</IonText>
                                                        </IonButton>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </IonItemOptions>
                                            )}
                                    </IonItemSliding>
                                ) : (
                                    <IonItemSliding className="convoSliding" key={i}>
                                        <IonItem className="singleconvo">
                                            <div
                                                className="convoInfo"
                                                onClick={() => {
                                                    undoArchiveHandler(item);
                                                }}
                                            >
                                                <IonText className="title">
                                                    <strong>Message Archived (Click to undo)</strong>
                                                </IonText>
                                            </div>
                                        </IonItem>
                                    </IonItemSliding>
                                )
                            )
                        ) : loading === false &&
                            FilterList?.length > 0 &&
                            selectedSegment === "draft" ? (
                            FilterList?.map((item: any, i: any) => (
                                <IonItemSliding className="convoSliding" key={i}>
                                    <IonItem
                                        onClick={async (e) => {
                                            await dispatch(setMessages([]));
                                            await dispatch(setSelectedChat(item));
                                            await dispatch(setRedirectedFrom("draft"))
                                            router.push(`/messagethread/${item?._id}`, "forward");
                                        }}
                                        className="singleconvo"
                                    >
                                        <div className="profilePicture">
                                            <IonAvatar className="avatar-style md hydrated">
                                                <img src={"./assets/Logo.svg"} />
                                            </IonAvatar>
                                        </div>
                                        <div className="convoInfo">
                                            <IonText className="title">
                                                <strong>{item?.title}</strong>
                                            </IonText>
                                        </div>
                                    </IonItem>
                                </IonItemSliding>
                            ))
                        ) : loading === false && FilterList?.length === 0 ? (
                            <div className="convoSliding">
                                <div style={{ height: "81px", marginLeft: "21px" }} className="singleconvo">
                                    <div style={{ width: "61px" }} className="profilePicture">
                                        <IonAvatar className="avatar-style md hydrated">
                                            <div className="vectormain">
                                                <img style={{
                                                    marginTop: "10px", marginRight: "15px",
                                                    width: "32px",
                                                    height: "32px"
                                                }} src={"./assets/Vector.svg"} /></div>
                                        </IonAvatar>
                                    </div>
                                    <div style={{ height: "51px" }} className="convo">
                                        <div className="convomsgt">No messages </div>
                                        <div style={{ height: "36px" }} className="convomsgc">You have no messages yet...</div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        <IonInfiniteScroll
                            onIonInfinite={(e) => loadData(selectedSegment, e)}
                            threshold="100px"
                            disabled={isInfiniteDisabled}
                        >
                            <IonInfiniteScrollContent
                                loadingSpinner="bubbles"
                                loadingText="Loading more data..."
                            ></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                    </IonList>

                    <IonFab className="floating-btn"
                        vertical="bottom"
                        horizontal="end"
                        slot="fixed">
                        <IonFabButton onClick={() => router.push("/contact-list")}>
                            <IonIcon icon={add} />
                        </IonFabButton>
                    </IonFab>
                </IonContent>
            </div>
        </div>
    );
};

export default Drafts;