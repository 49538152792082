import {
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonHeader,
  IonToolbar,
  IonPage,
  IonContent,
  IonText,
  useIonRouter,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import "./PracticeIQ.scss";
import Buttons from "../../components/Common/Buttons/Buttons";
import CardDisplay from "../DashBoard/CardDisplay/CardDisplay";
import { callDashboardTopCard, getPayorData } from "../../helper/backendHelper";
import { useAppSelector } from "../../store/hooks";
import moment from "moment";
import {
  caretUp,
  caretDownOutline,
  calendarClearOutline,
  informationCircle,
} from "ionicons/icons";
import { is } from "immer/dist/internal";

const DUMMY_DETAILS = [
  {
    id: "1",
    ptext1: "Estimated income",
    ptext2: "Pending income",
    count_icon: "$",
    scope: "currentpractice",
  },
  {
    id: "2",
    ptext1: "Completed encounters",
    ptext2: "Pending encounters",
    count_icon: "",
    scope: "currentpractice",
  },
  {
    id: "3",
    ptext1: "Estimated income",
    ptext2: "Pending income",
    count_icon: "$",
    scope: "currentprovider",
  },
  {
    id: "4",
    ptext1: "Completed encounters",
    ptext2: "Pending encounters",
    count_icon: "",
    scope: "currentprovider",
  },
];

export const PracticeIQ: React.FC = () => {
  const [revanue, setRevanue] = useState(false);
  const [encounters, setEncounters] = useState(false);
  const [dummyData, setDummyData] = useState(DUMMY_DETAILS);
  const [payorData, setPayerData] = useState([]);
  const router = useIonRouter();
  const onGoToAnalytics = () => {
    router.push("/practice-iq-analytics", "back");
  };
  const selectedPractice = useAppSelector((state) => state.user)?.practice;
  const selectedProvider = useAppSelector((state) => state.user)?.provider;
  const [topcardData, setTopcardData] = useState<any>([0]);
  const [topcardData1, setTopcardData1] = useState<any>([0]);

  const generateRange = async (type: string) => {
    if (type === 'week') {
      let startDate = moment().startOf("week").format("YYYY-MM-DD");
      let endDate = moment().endOf("week").format("YYYY-MM-DD");
      let previousStartDate = moment().subtract(1, 'weeks').startOf("week").format("YYYY-MM-DD");
      let previousEndDate = moment().subtract(1, 'weeks').endOf("week").format("YYYY-MM-DD");
      let callDashboardTopCardData = {
        scope: DUMMY_DETAILS[0].scope,
        practice_id: selectedPractice?._id,
        provider_id: selectedProvider?._id,
        service_date: {
          start: startDate,
          end: endDate,
        },
      };
      let topcard_data = await callDashboardTopCard(callDashboardTopCardData);
      if (topcard_data.status === "SUCCESS") {
        setTopcardData(topcard_data.data);
      }
      let callDashboardTopCardData1 = {
        scope: DUMMY_DETAILS[0].scope,
        practice_id: selectedPractice?._id,
        provider_id: selectedProvider?._id,
        service_date: {
          start: previousStartDate,
          end: previousEndDate,
        },
      };
      let topcard_data1 = await callDashboardTopCard(callDashboardTopCardData1);
      if (topcard_data1.status === "SUCCESS") {
        setTopcardData1(topcard_data1.data);
      }

      let getpayer = await getPayorData(startDate, endDate);
      if (getpayer.status === 'SUCCESS')
        setPayerData(getpayer.result)
    }
  }
  let diff = topcardData?.totalRvn - topcardData1?.totalRvn;
  let difference = topcardData?.totalEncounters - topcardData1?.totalEncounters;
  const rvuDiff = (diff / topcardData1?.totalRvn) * 100;
  const encounterDiff = (difference / topcardData1?.totalEncounters) * 100;
  useEffect(() => {
    generateRange("week")
  }, []);
  return (
    <IonPage className="ion-page-dashboard">
      <IonHeader class="ion-no-border" className="ion-header-dashboard">
        <IonToolbar className="ion-toolbar-dashboard">
          <IonMenuButton slot="start" className="ion-icon-size-practice-iq" />
          <IonTitle className="title-header-dashboard">Practice IQ</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonToolbar className="ion-toolbar-dashboard">
          <IonText className="ion-text1-dashboard">This week</IonText>
        </IonToolbar>

        <CardDisplay items={dummyData} />
        <div className="table-container-practice-iq">
          <table className="practice-iq-table">
            <tbody className="table-body-practice-iq">
              <tr className="tr-practice-iq">
                <td className="td-text-practice-iq">
                  Compared to previous week
                </td>

                <td className="td-text1-practice-iq">%</td>
              </tr>
              <tr className="tr-practice-iq">
                <td className="td1-practice-iq">RVU</td>

                <td className="td2-practice-iq">
                  {(rvuDiff > 0) ? <IonIcon icon={caretUp} color="primary"></IonIcon> : <IonIcon color="danger" icon={caretDownOutline}></IonIcon>}
                  <IonText>{isNaN(rvuDiff) ? 0 : rvuDiff.toFixed(2)}% </IonText>
                </td>
              </tr>
              <tr className="table-row-practice-iq">
                <td className="td1-practice-iq">Encounters</td>

                <td className="td2-practice-iq">
                  {(encounterDiff > 0) ? <IonIcon icon={caretUp} color="primary"></IonIcon> : <IonIcon color="danger" icon={caretDownOutline}></IonIcon>}
                  <IonText>{isNaN(encounterDiff) ? 0 : encounterDiff.toFixed(2)}%</IonText>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="div-content-practice-iq">
          <div>
            <IonIcon icon={informationCircle} color="#FFFFFF" />
          </div>
          <div className="div-width-practice-iq">
            <IonText className="ion-text-practice-iq">
              The red and green triangles are indicators of how the percentage’s
              changed compared to the previous week/month/year.
            </IonText>
          </div>
        </div>
        <IonText className="ion-text1-practice-iq">
          Your top paying insurance companies
        </IonText>

        {payorData?.length > 0 ? (<div className="table-container-practice-iq">
          <table className="practice-iq-table">
            <tbody className="table-body-practice-iq">
              <tr className="tr-practice-iq">
                <td className="td1-practice-iq">Name</td>

                <td className="td2-practice-iq">
                  <IonText>$</IonText>
                </td>
              </tr>
              {payorData && payorData.map((payer: any, index: number) =>
                <tr key={index} className="tr-practice-iq">
                  <td className="td1-practice-iq">{payer?.payor}</td>

                  <td className="td2-practice-iq">
                    <IonIcon color="primary" icon={caretUp}></IonIcon>
                    <IonText>${payer?.total_count.toFixed(2)}</IonText>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>) : <div className="ion-text-practice-iq content nodata">{'No record found'}</div>
        }
        <div className="btn-holder-practice-iq">
          <Buttons onClick={onGoToAnalytics} fullWidth boldfont>
            <IonText className="btn-practiceiq-text"> See more analytics</IonText>
          </Buttons>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default PracticeIQ;
