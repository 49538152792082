import { IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import React from "react";
import AlertDark from "../../../icons/Other/AlertDark.svg";
import "./ProviderHoldCard.scss";

interface ProviderHoldsCardProps {
  item: any;
}

const ProviderHoldsCard: React.FC<ProviderHoldsCardProps> = ({ item }) => {
  return (
    <div className="issuescard-container">
      <IonGrid>
        <IonRow>
          <IonCol size="1.5">
            <div className="issuescard-icon-container">
              <div className="icon-wrapper">
                <IonImg className="notification-icon" src={AlertDark} />
              </div>
            </div>
          </IonCol>

          <IonCol size="8.5">
            <div className="issuescard-data-container">
              <div className="issuescard-heading">
                <span className="issuescard-heading bold">
                  {item?.patient_lastname},{" "}
                </span>
                <span className="issuescard-heading">
                  {item?.patient_firstname} | {item?.room}
                </span>
              </div>
              <span className="issuescard-subheading">
                {item?.facility_name}
              </span>
              {item?.reason_codes &&
                item?.reason_codes.length > 1 &&
                item?.reason_codes.map((reasons: string, index: number) => (
                  <span key={index} className="issuescard-description">
                    {reasons}
                  </span>
                ))}
              {item?.reason_codes && item?.reason_codes.length === 1 && (
                <span className="issuescard-description">
                  {item?.reason_codes[0]}
                </span>
              )}
              {!item?.reason_codes && (
                <span className="issuescard-description">-</span>
              )}
            </div>
          </IonCol>

          <IonCol size="2" className="cpt-section">
            <span className="issuescard-CPT">
              {item?.procedure_code_primary?.code}
            </span>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default ProviderHoldsCard;
