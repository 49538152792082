import React from "react";
import { IonInput } from "@ionic/react";
import "./SharedInputFields.scss";
import { InputBoxType } from "../../../types/Types";
import { TextFieldTypes } from "@ionic/core";

type TextBoxType = {
  value: InputBoxType;
  type: TextFieldTypes | undefined;
  placeHolder?: string;
  className?: any;
  disabled?: boolean;
  invalid?: boolean;
};

const SharedInputFields: React.FC<TextBoxType> = ({
  value,
  type,
  placeHolder,
  className,
  disabled,
  invalid,
}) => {
  return (
    <IonInput
      value={value}
      placeholder={placeHolder}
      type={type}
      className={className}
      disabled={disabled}
    />
  );
};

export default SharedInputFields;
