import React, { Fragment, useState } from "react";
import { IonIcon, IonText, IonReorderGroup, IonReorder } from "@ionic/react";
import { addOutline, star, reorderTwo, starOutline } from "ionicons/icons";
import RemoveDiagnosis from "../RemoveDiagnosis/RemoveDiagnosis";
import DiagnosisCodeFullList from "../DiagnosisCode/DiagnosisCodeFullList";
import DiagnosisCode from "../DiagnosisCode/DiagnosisCode";
import { useAppSelector } from "../../../store/hooks";
import "./DiagnosisCard.scss";
interface ItemReorderEventDetail {
  from: number;
  to: number;
  complete: (data?: boolean | any[]) => any;
}
type DiagnosisCardProps = {
  selectedCode?: any[];
  disabled?: boolean;
  onSetCode: (code: any) => void;
  handleRegroup?: (regroupData: any) => void;
};

const DiagnosisCard: React.FC<DiagnosisCardProps> = ({
  selectedCode,
  onSetCode,
  handleRegroup,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fullListOpen, setFullListOpen] = useState(false);
  const [addNewOpen, setAddNewOpen] = useState(false);

  const selectedDiagnosis = selectedCode ? selectedCode : [];
  const darkThemeBoolean = useAppSelector((state) => state.layout.darkTheme);

  function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
    event.detail.complete();
    if (handleRegroup) {
      handleRegroup({
        from: event.detail.from,
        to: event.detail.to,
        code: "diagnosis",
      });
    }
  }

  // useEffect(() => {
  //   if (selectedCode) {
  //     setSelectedDiagnosis(selectedCode);
  //   }

  //   return () => {
  //     setSelectedDiagnosis([]);
  //   };
  // }, [selectedCode]);

  const emptySerial = () => {
    let indexArray = [];
    for (let i = selectedDiagnosis.length + 1; i <= 4; i++) {
      indexArray.push(i);
    }

    return (
      <>
        {indexArray.map((item, index) => {
          return (
            <div key={index} className="procedure-number visibility"></div>
          );
        })}
      </>
    );
  };

  const addItem = (disabled?: boolean) => {
    let indexArray = [];
    for (let i = selectedDiagnosis.length + 1; i <= 4; i++) {
      indexArray.push(i);
    }

    return (
      <>
        {indexArray.map((item, index) => {
          return (
            <div className="reorderComponent" key={index}>
              <button
                key={item}
                className={`addDiagnosis ${index !== 0 && "inActive"}`}
                onClick={() => setAddNewOpen(true)}
                disabled={disabled}
              >
                <IonIcon icon={addOutline} />
                <p className="addDiagnosisText">Add diagnosis</p>
              </button>
            </div>
          );
        })}
      </>
    );
  };

  const addDiagnosisData = (item: any) => {
    onSetCode(item);
    setAddNewOpen(false);
  };

  return (
    <div className="diagnosisCardContent">
      <div className="diagnosisCardContentHeader">
        <p>Selected diagnosis codes ({selectedDiagnosis.length})</p>
        {!disabled && (
          <>
            <RemoveDiagnosis
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              selectedDiagnosis={selectedDiagnosis}
              setSelectedDiagnosis={onSetCode}
            />
            <DiagnosisCodeFullList
              isOpen={fullListOpen}
              doReorder={doReorder}
              setIsOpen={setFullListOpen}
              selectedDiagnosis={selectedDiagnosis}
              setSelectedDiagnosis={onSetCode}
            />
            <DiagnosisCode
              isOpen={addNewOpen}
              setIsOpen={setAddNewOpen}
              numberPermited={12 - selectedDiagnosis.length}
              addDiagnosis={addDiagnosisData}
              selectedDiagnosis={selectedDiagnosis}
            />
          </>
        )}
      </div>
      <div className="diagnosisCardSubContent">
        <div className="leftContent">
          {selectedDiagnosis.length >= 4 ? (
            <>
              {selectedDiagnosis?.map((item: any, index: any) => (
                <Fragment key={index}>
                  {index < 4 ? (
                    <div key={index} className="procedure-number">
                      {index + 1}
                    </div>
                  ) : null}
                </Fragment>
              ))}
            </>
          ) : (
            <>
              {selectedDiagnosis?.map((item: any, index: any) => (
                <div key={index} className="procedure-number">
                  {index + 1}
                </div>
              ))}
              {emptySerial()}
            </>
          )}
        </div>
        <div className="rightContent">
          <IonReorderGroup
            disabled={disabled}
            onIonItemReorder={doReorder}
            className="reorderComponent"
          >
            {selectedDiagnosis &&
              selectedDiagnosis.map(
                (item: any, index: any) =>
                  item?.code != null && (
                    <Fragment key={index}>
                      {index < 4 && (
                        <div
                          className="addedDiagnosis"
                          key={`${item.code} ${index}`}
                        >
                          <div
                            className={`addedDiagnosisIcon ${
                              item.favorite && "favourite"
                            }`}
                          >
                            <IonIcon
                              icon={
                                item?.favorite === true
                                  ? star
                                  : !darkThemeBoolean
                                  ? starOutline
                                  : star
                              }
                            />
                          </div>
                          <div className="addedDiagnosisInfo">
                            <IonText className="addedDiagnosisText">
                              {item.code}
                            </IonText>
                            <IonText className="addedDiagnosisPara">
                              {item.description}
                            </IonText>
                          </div>
                          <div className="addedDiagnosisReorderIcon">
                            <IonReorder
                              children={
                                <IonIcon icon={reorderTwo} size="large" />
                              }
                            />
                          </div>
                        </div>
                      )}
                    </Fragment>
                  )
              )}
          </IonReorderGroup>
          {selectedDiagnosis.length < 4 ? addItem(disabled) : null}
        </div>
      </div>
      {selectedDiagnosis.length > 4 && (
        <div className="seeAllText" onClick={() => setFullListOpen(true)}>
          <IonText> See all</IonText>
        </div>
      )}

      {selectedDiagnosis.length >= 4 && (
        <button
          className="addDiagnosisButton"
          disabled={selectedDiagnosis.length >= 12 || disabled}
          onClick={() => setAddNewOpen(true)}
        >
          <div
            className={`buttonAndContent ${
              selectedDiagnosis.length >= 12
                ? "buttonAndContent-disabled"
                : "buttonContent-active"
            }`}
          >
            <IonIcon icon={addOutline} />
            <IonText>Add diagnosis</IonText>
          </div>
        </button>
      )}
    </div>
  );
};

export default DiagnosisCard;
