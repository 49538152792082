import {
  IonContent,
  IonGrid,
  IonHeader,
  IonButton,
  IonFooter,
  IonRow,
  IonTitle,
  IonIcon,
  IonModal,
  IonToolbar,
  IonButtons,
} from "@ionic/react";
import "../../../components/Common/ProcedureSearch/ProcedureSearch.scss";
import { useEffect, useState } from "react";
import "./ProcedureCode.scss";
import ProcedureCard from "./ProcedureCard";

import { chevronBackOutline, addOutline } from "ionicons/icons";
import ProcedureButton from "./ProcedureButton/ProcedureButton";
import {
  favoriteProcedure,
  getFavProceduresSearch,
  getProcedures,
  unFavoriteProcedure,
} from "../../../helper/backendHelper";
import { debounce } from "../../../utils/debounce";
import SearchBox from "../../../components/Common/SearchBox/SearchBox";

interface ProcedureSearchInterface {
  code: string;
  description: string;
  long_description: string;
  enabled: boolean;
  favorite: boolean;
  isSelected?: boolean;
}

interface ProcedureCodeInterface {
  isOpen?: boolean;
  setIsOpen: (val: boolean) => void;
  onSelectCode?: (code: any) => void;
  selectedCodes?: any;
  addProcedure?: any;
  cptClick?: any;
}

const ProcedureCode: React.FC<ProcedureCodeInterface> = ({
  isOpen,
  setIsOpen,
  onSelectCode,
  selectedCodes,
  cptClick,
}) => {
  const [procedureData, setProcedureData] = useState<
    ProcedureSearchInterface[]
  >([]);
  const [searchData, setSearchData] = useState<ProcedureSearchInterface[]>([]);
  const [isSearchEnabled, setIsSeach] = useState(false);
  const [selectedData, setSelectedData] = useState<ProcedureSearchInterface[]>(
    []
  );
  const [favoriteButton, setFavoriteButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedCount, setSelectedCount] = useState(0);

  let finalData = isSearchEnabled ? searchData : procedureData;

  useEffect(() => {
    setSelectedData(selectedCodes);
  }, [selectedCodes]);

  useEffect(() => {
    setSelectedCount(selectedData.length - selectedCodes.length);
  }, [selectedCodes, selectedData]);

  useEffect(() => {
    setLoading(true);
    if (!favoriteButton) {
      const getProcuderesList = async () => {
        let res = await getProcedures("");
        let newResult: any[] = [];

        res.results.forEach((item: any) => {
          if (
            !selectedCodes.map((e: { code: any }) => e.code).includes(item.code)
          )
            newResult = [...newResult, item];
        });

        // console.log({ newResult, res, selectedCodes });

        setProcedureData(newResult);
      };
      getProcuderesList();
    } else {
      const getFavProceduresList = async () => {
        let res = await getFavProceduresSearch("");
        let newArr: any = [];

        res?.results.forEach((item: any) => {
          if (
            !selectedCodes.map((e: { code: any }) => e.code).includes(item.code)
          ) {
            newArr = [...newArr, { ...item, favorite: true }];
          }
        });

        setProcedureData(newArr);
      };
      getFavProceduresList();
    }
    setLoading(false);
  }, [favoriteButton, isOpen, selectedCodes]);

  const favoriteHandler = (item: any) => {
    let newArray: ProcedureSearchInterface[] = procedureData.map(
      (list: ProcedureSearchInterface, idx) => {
        if (list.code === item.code) {
          procedureFavoriteHandler({ ...item, favorite: !item.favorite });
          return { ...item, favorite: !item.favorite };
        } else {
          return list;
        }
      }
    );
    if (isSearchEnabled) {
      let searchArray = searchData.map((list, idx) => {
        if (list.code === item.code) {
          return { ...item, favorite: !item.favorite };
        } else {
          return list;
        }
      });
      setSearchData(searchArray);
    }

    setProcedureData(newArray);
    // setSelectedData(newArray.filter((list) => list?.isSelected === true));
  };

  const procedureFavoriteHandler = async (item: any) => {
    let res: any;
    if (item.favorite) {
      res = await favoriteProcedure(item.code);
    } else {
      res = await unFavoriteProcedure(item.code);
    }
  };

  const cardSelectHandler = (item: ProcedureSearchInterface) => {
    let isSelected = selectedData.some((code) => item.code === code.code);

    if (isSelected) {
      let temp = selectedData?.filter((code: ProcedureSearchInterface) => {
        let isSelected = item.code !== code.code;

        return isSelected;
      });
      setSelectedData(temp);
    } else {
      // if (selectedData.length < 2) {
      setSelectedData([...selectedData, item]);
      // }
    }
  };

  const searchhandler = (e: string) => {
    setLoading(true);
    if (e === "") {
      if (!favoriteButton) {
        setLoading(true);
        const getProcuderesList = async () => {
          let res = await getProcedures("");
          let newResult: any[] = [];
          res.results.forEach((item: any) => {
            if (
              !selectedCodes
                .map((e: { code: any }) => e.code)
                .includes(item.code)
            )
              newResult = [...newResult, item];
          });
          setSearchData(newResult);

          setProcedureData(newResult);
        };
        getProcuderesList();
      } else {
        const getProcuderesList = async () => {
          let res = await getFavProceduresSearch("");
          let newArr: any = [];
          res?.results.forEach((item: any) => {
            if (
              !selectedCodes
                .map((e: { code: any }) => e.code)
                .includes(item.code)
            ) {
              newArr = [...newArr, { ...item, favorite: true }];
            }
          });
          setSearchData(newArr);

          setProcedureData(newArr);
        };
        getProcuderesList();
      }
      setIsSeach(false);
    } else {
      if (!favoriteButton) {
        const getProcuderesList = async () => {
          let res = await getProcedures(e);
          let newResult: any[] = [];
          res.results.forEach((item: any) => {
            if (
              !selectedCodes
                .map((e: { code: any }) => e.code)
                .includes(item.code)
            )
              newResult = [...newResult, item];
          });
          setSearchData(newResult);

          setProcedureData(newResult);
        };
        getProcuderesList();
      } else {
        const getProcuderesList = async () => {
          let res = await getFavProceduresSearch(e);
          let newArr: any = [];
          res?.results.forEach((item: any) => {
            if (
              !selectedCodes
                .map((e: { code: any }) => e.code)
                .includes(item.code)
            ) {
              newArr = [...newArr, { ...item, favorite: true }];
            }
          });
          setSearchData(newArr);

          setProcedureData(newArr);
        };
        getProcuderesList();
      }
      setIsSeach(true);
    }
    setLoading(false);
  };

  const sendProcedureSearch = () => {};

  const closeModal = () => {
    setFavoriteButton(false);
    setIsSeach(false);
    setIsOpen(false);
  };

  const onAddProcedureCode = (code: any) => {
    setIsOpen(false);
    if (onSelectCode) {
      onSelectCode(code);
    }
  };
  const handleButtonSelect = (buttonName: string) => {
    switch (buttonName) {
      case "Favorites":
        setFavoriteButton(true);
        break;
      default:
        break;
    }
  };

  const handleButtonDeselect = (buttonName: string) => {
    switch (buttonName) {
      case "Favorites":
        setFavoriteButton(false);
        break;
      default:
        break;
    }
  };

  // console.log({ selectedCount });

  return (
    <IonModal
      className="procedure-code-main-container"
      isOpen={isOpen}
      onDidDismiss={closeModal}
      initialBreakpoint={0.8}
    >
      <IonHeader translucent mode="ios" className="add-procedure-header">
        <IonToolbar className="add-procedure-toolbar">
          <IonTitle className="add-procedure-title">Add CPT</IonTitle>
          <IonButtons>
            <IonButton
              onClick={(e) => {
                setIsOpen(false);
              }}
            >
              <IonIcon
                className="add-procedure-back"
                icon={chevronBackOutline}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <div className="container-search-box-procedurecode">
        <div className="procedure-search-box">
          <SearchBox
            placeholder="Search by code or description..."
            onChange={debounce(searchhandler, 1000)}
          />
        </div>

        <div className="filterbuttons">
          <ProcedureButton
            text="Favorites"
            isDirectSelect={true}
            defultSelected={favoriteButton}
            onSelect={handleButtonSelect}
            deSelect={handleButtonDeselect}
          ></ProcedureButton>

          <ProcedureButton text="Recent search"></ProcedureButton>

          <ProcedureButton text="Initial"></ProcedureButton>

          <ProcedureButton text="Subsequent"></ProcedureButton>
          <ProcedureButton text="Recent search"></ProcedureButton>

          <ProcedureButton text="Initial"></ProcedureButton>

          <ProcedureButton text="Subsequent"></ProcedureButton>
        </div>
      </div>
      <IonContent className="add-procedure-content">
        <IonGrid className="procedure-detail-container">
          {loading === false && finalData?.length > 0 ? (
            <p className="text-display">Tap to select codes</p>
          ) : loading === false && finalData?.length === 0 ? (
            <p className="text-display user-message">No result found</p>
          ) : (
            ""
          )}

          {finalData &&
            finalData.map((item: ProcedureSearchInterface, index: number) => {
              let isSelected = selectedData?.some(
                (code) => item.code === code.code
              );

              return (
                <IonRow key={`${item.code}+${index}`}>
                  <ProcedureCard
                    favoriteHandler={favoriteHandler}
                    cardSelectHandler={cardSelectHandler}
                    item={{ ...item, isSelected }}
                    cptClick={cptClick}
                  />
                </IonRow>
              );
            })}
        </IonGrid>
      </IonContent>

      <IonFooter className="add_diag_footer">
        <IonButton
          className="add_diag_footer_button"
          // color="primary"
          onClick={() => onAddProcedureCode(selectedData)}
          disabled={!selectedCount}
        >
          <IonIcon icon={addOutline} />
          <p>Add CPT</p>
          <div className="add_diag_footer_num">{selectedCount}</div>
        </IonButton>
      </IonFooter>
    </IonModal>
  );
};

export default ProcedureCode;
