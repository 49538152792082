import React, { useState } from "react";
import "./ProcedureButton.scss";
import { IonButton } from "@ionic/react";

type DaignosisButtonProps = {
  text: string;
  defultSelected?: boolean;
  isDirectSelect?: boolean;
  onSelect?: (code: string) => void;
  deSelect?: (code: string) => void;
};

const DiagnosisButton: React.FC<DaignosisButtonProps> = ({
  text,
  defultSelected,
  onSelect,
  deSelect,
  isDirectSelect,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(
    defultSelected ? true : false
  );

  const onClickSelect = () => {
    setIsSelected(() => !isSelected);
    if (onSelect && deSelect) {
      if (isDirectSelect && !defultSelected) {
        onSelect(text);
      } else {
        deSelect(text);
      }
    }
  };

  return (
    <IonButton
      onClick={onClickSelect}
      className={
        (isDirectSelect ? defultSelected : isSelected)
          ? "common-diagnosiscode selected-btn-diagnosiscode"
          : "common-diagnosiscode btn-diagnosiscode"
      }
      fill="outline"
      color="medium"
      shape="round"
    >
      <p
        onClick={onClickSelect}
        className={
          (isDirectSelect ? defultSelected : isSelected)
            ? "p-diagnosiscode-buttons-text"
            : "p-diagnosiscode-buttons-text-selected"
        }
      >
        {" "}
        {text}{" "}
      </p>
    </IonButton>
  );
};

export default DiagnosisButton;
