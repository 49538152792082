
import React, { useState, useEffect } from "react";
import {
  IonTitle,
  IonHeader,
  IonToolbar,
  IonPage,
  IonContent,
  useIonRouter,
  IonIcon,
  IonButtons,
  IonButton,
  IonRow,
  IonCol,
  IonMenuButton
} from "@ionic/react";
import "./PracticeIQ.scss";
import { groupBy } from "lodash";
import FilterModalPracticeIQ from "../../components/PracticeIQ/FilterModalPracticeIQ";
import Tab, { TabNav, TabRouter } from "../../components/Common/Tab/Tab";
import { IconSvg } from "../../components/Common/IconSvg/IconSvg";
import {
  Dollar,
  Globe,
  People,
  Percentage,
  SeparatorBar,
  StatsBar,
  CalendarIcon,
} from "../../icons/icons";
import { chevronBackOutline, refresh } from "ionicons/icons";

import { getfullRevenueData, getRevenueData } from "../../helper/backendHelper";
import moment from "moment";
import { EncountersTab } from "./EncountersTab";
import { RevenueTab } from "./RevenueTab";
import { RvuTab } from "./RvuTab";
import { DaysPerEncTab } from "./DaysPerEncTab";
import { AverageRevenue } from "./AverageRevenue";

const slider1 = [
  { sliderName: "This Week", iconName: CalendarIcon },
  { sliderName: "This Month", iconName: CalendarIcon },
  { sliderName: "This Year", iconName: CalendarIcon },
];
const slider2 = [
  { sliderName: "By Provider" },
  { sliderName: "By Insurence Company" },
  { sliderName: "Place of Services" },
];
const slider3 = [
  { sliderName: "By Provider" },
  { sliderName: "By Place Of Service" },
  { sliderName: "By Facility" },
  { sliderName: "Payment Type" },
  { sliderName: "Payor" },
  { sliderName: "By Code" },
  { sliderName: "By Time" },
];
export const PracticeIQAnalytics: React.FC = () => {
  const [RevenueData, processFullData] = useState<any>([]);
  const [Data, setData] = useState<any>([]);
  const [dummyData, setDummyData] = useState([
    {
      _id: "5aed3c5b3d9692be4a71537c",
      category: "provider",
      name: "ALEXIE, ION",
      value_total: 3061108.48,
      visit_count: 0,
      presentage: 19.49134659082564,
      color: "#2A7B9B",
    },
    {
      _id: "5e029e1323474befadb2bd9d",
      category: "provider",
      name: "alexie, ion",
      value_total: 1301265.86,
      visit_count: 0,
      presentage: 15.540513665189938,
      color: "#00BAAD",
    },
    {
      _id: "5b87e6ec3d9692be4abc6831",
      category: "provider",
      name: "OCHOA, JIJI",
      value_total: 30048.16,
      visit_count: 0,
      presentage: 5.6821343035110548,
      color: "#57C785",
    },
    {
      _id: "5d8260facf15b030c88d092c",
      category: "provider",
      name: "TAN, JUDITH",
      value_total: 11905.42,
      visit_count: 0,
      presentage: 30.270269306996055,
      color: "#ADD45C",
    },
    {
      _id: null,
      category: "provider",
      name: "Others",
      value_total: 693.18,
      visit_count: 0,
      presentage: 5.01573613347731751,
      color: "#EDDD53",
    },
    {
      _id: "5d8260facf15b030c88d092b",
      category: "provider",
      name: "RADIVAN, JESSICA",
      value_total: 0,
      visit_count: 0,
      presentage: 19,
      color: "#FFC300",
    },
  ]);

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const handleFilterModal = (item: boolean) => {
    setIsFilterOpen(item);
  };
  const handleFilterChange = (sortOptionData: String) => {
    // console.log("sort", sortOptionData)
  };

  const generateChart = async () => {
    document.getElementsByClassName("lineChart")[0].innerHTML = "";
    let chart = document.getElementsByClassName("lineChart")[0];
    for (let i = 0; i < dummyData.length; i++) {
      const item = document.createElement("div");
      item.style.width = dummyData[i].presentage + "%";
      item.style.backgroundColor = dummyData[i].color;
      if (i == 0) {
        item.classList.add("start");
      }
      if (i == dummyData.length - 1) {
        item.classList.add("end");
      }
      chart.append(item);
    }
  };
  const router = useIonRouter();
  const backButtonHandler = () => {
    router.push("/practice-iq", "back");
  };
  const fullRevenueData = async () => {
    processFullData(true);
    try {
      let res = await getfullRevenueData(" ");
      setData(res.result);
    } catch (error) { }
    processFullData(false);
  };

  useEffect(() => {
    fullRevenueData();
    generateChart();
  }, []);

  return (
    <IonPage className="ion-page-practice">
      <IonHeader className="ion-header-dashboard d-pad-05">
        <IonToolbar className="ion-toolbar-dashboard" mode="ios">
          <div>
            <IonIcon onClick={backButtonHandler} className="backIcon" icon={chevronBackOutline} />
          </div>
          <IonTitle className="title-header-dashboard">
            Practice IQ
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="visit-details-content">
        <Tab>
          <TabRouter>
            <TabNav component={RevenueTab}>
              <div>
                <IconSvg Icon={Dollar}></IconSvg>
              </div>
            </TabNav>
            <TabNav component={AverageRevenue}>
              <div>
                <IconSvg Icon={StatsBar}></IconSvg>
              </div>
            </TabNav>
            <TabNav component={EncountersTab}>
              <div>
                <IconSvg Icon={People}></IconSvg>
              </div>
            </TabNav>
            <TabNav component={RvuTab}>
              <div>
                <IconSvg Icon={Globe}></IconSvg>
              </div>
            </TabNav>
            <TabNav component={DaysPerEncTab}>
              <div>
                <IconSvg Icon={SeparatorBar}></IconSvg>
              </div>
            </TabNav>
            {/* <TabNav>
              <div>
                <IconSvg Icon={Percentage}></IconSvg>
              </div>
            </TabNav> */}
          </TabRouter>
        </Tab>
      </IonContent>
    </IonPage>
  );
};

export default PracticeIQAnalytics;