import { IonIcon, IonText, IonReorder, IonReorderGroup } from "@ionic/react";
import {
  addOutline,
  reorderTwo,
  star,
  starOutline,
  trashOutline,
} from "ionicons/icons";
import React, { Fragment, useEffect, useState } from "react";
import ProcedureCode from "../ProcedureCode/ProcedureCode";
import "./ProcedureCard.scss";
import RemoveProcedures from "../RemoveProcedures/RemoveProcedures";
import ProcedureCodeFullList from "../ProcedureCode/ProcedureCodeFullList";
import { useAppSelector } from "../../../store/hooks";

interface ItemReorderEventDetail {
  from: number;
  to: number;
  complete: (data?: boolean | any[]) => any;
}
interface procedure {
  code: string;
  description: string;
  long_description: string;
  enabled: boolean;
  favorite: boolean;
  isSelected?: boolean;
}

type ProcedureCardProps = {
  selectedCode?: any[];
  disabled?: boolean;
  onSetCode: (code: any) => void;
  handleRegroup: (regroupData: any) => void;
};

const ProcedureCard: React.FC<ProcedureCardProps> = ({
  selectedCode,
  onSetCode,
  handleRegroup,
  disabled,
}) => {
  const [selectedProcedures, setSelectedProcedures] = useState<procedure[]>(
    selectedCode?.length ? selectedCode : []
  );

  const [isRemoveOpen, setIsRemoveOpen] = useState<boolean>(false);
  const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
  const [fullListOpen, setFullListOpen] = useState(false);
  const darkThemeBoolean = useAppSelector((state) => state.layout.darkTheme);

  function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
    event.detail.complete();
    handleRegroup({
      from: event.detail.from,
      to: event.detail.to,
      code: "procedure",
    });
  }

  const emptySerial = () => {
    let indexArray = [];

    for (let i = selectedProcedures.length + 1; i <= 4; i++) {
      indexArray.push(i);
    }
    return (
      <>
        {indexArray.map((item, index) => {
          return (
            <div key={index} className="procedure-number visibility"></div>
          );
        })}
      </>
    );
  };

  const addItem = (disabled?: boolean) => {
    let indexArray = [];
    //let i = selectedProcedures.length + 1;
    for (let i = selectedProcedures.length + 1; i <= 4; i++) {
      indexArray.push(i);
      // i++;
    }
    return (
      <>
        {indexArray.map((item, index) => {
          return (
            <div className="reorderComponent" key={index}>
              <button
                key={item}
                className={`addProcedure ${index !== 0 && "inActive"}`}
                onClick={() => setIsAddOpen(true)}
                disabled={disabled}
              >
                <IonIcon icon={addOutline} />
                <p className="addProcedureText">Add procedures</p>
              </button>
            </div>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    if (
      selectedCode?.length == 1 &&
      selectedCode[0].modifies == null &&
      selectedCode[0]?.code == undefined
    ) {
      setSelectedProcedures([]);
    } else {
      if (selectedCode) {
        setSelectedProcedures(selectedCode);
      }
    }

    return () => {
      setSelectedProcedures([]);
    };
  }, [selectedCode]);

  const addProcedureData = (item: any) => {
    // console.log({ item });

    onSetCode(item);
    setIsAddOpen(false);
  };

  return (
    <div className="procudereCardContent">
      <div className="procedureCardContentHeader">
        <p>Selected procedure codes ({selectedProcedures.length})</p>

        <div className="trash-icon">
          {selectedProcedures && selectedProcedures.length > 0 && !disabled && (
            <IonIcon
              onClick={() => {
                setIsRemoveOpen(true);
              }}
              icon={trashOutline}
            />
          )}
          {!disabled && (
            <>
              <RemoveProcedures
                isOpen={isRemoveOpen}
                setIsOpen={setIsRemoveOpen}
                selectedCodes={selectedProcedures}
                onRemoveCode={onSetCode}
              />
              <ProcedureCodeFullList
                isOpen={fullListOpen}
                doReorder={doReorder}
                setIsOpen={setFullListOpen}
                selectedProcedure={selectedProcedures}
                setSelectedProcedure={setSelectedProcedures}
              />
              <ProcedureCode
                isOpen={isAddOpen}
                setIsOpen={setIsAddOpen}
                selectedCodes={selectedProcedures}
                onSelectCode={onSetCode}
                addProcedure={addProcedureData}
              />
            </>
          )}
        </div>
      </div>

      <div className="procedureCardSubContent">
        <div className="leftContent">
          {selectedProcedures.length >= 4 ? (
            <>
              {selectedProcedures?.map((item: any, index: any) => (
                <Fragment key={index}>
                  {index < 4 ? (
                    <div key={index} className="procedure-number">
                      {index + 1}
                    </div>
                  ) : null}
                </Fragment>
              ))}
            </>
          ) : (
            <>
              {selectedProcedures?.map((item: any, index: any) => (
                <div key={index} className="procedure-number">
                  {index + 1}
                </div>
              ))}
              {emptySerial()}
            </>
          )}
        </div>
        <div className="rightContent">
          <IonReorderGroup
            disabled={disabled}
            onIonItemReorder={doReorder}
            className="reorderComponent"
          >
            {selectedProcedures &&
              selectedProcedures.map(
                (item, index) =>
                  item?.code != null && (
                    <Fragment key={index}>
                      {index < 4 && (
                        <div className="addedProcedure" key={item.code}>
                          <div
                            className={`addedProcedureIcon ${
                              item.favorite && "favourite"
                            }`}
                          >
                            <IonIcon
                              icon={
                                item?.favorite === true
                                  ? star
                                  : !darkThemeBoolean
                                  ? starOutline
                                  : star
                              }
                            />
                          </div>
                          <div className="addedProcedureInfo">
                            <IonText className="addedProcedureText">
                              {item.code}
                            </IonText>
                            <IonText className="addedProcedurePara">
                              {item.description}
                            </IonText>
                          </div>
                          <div className="addedProcedureReorderIcon">
                            <IonReorder
                              children={
                                <IonIcon icon={reorderTwo} size="large" />
                              }
                            />
                          </div>
                        </div>
                      )}
                    </Fragment>
                  )
              )}
          </IonReorderGroup>
          {selectedProcedures.length < 4 ? addItem(disabled) : null}
        </div>
      </div>

      {selectedProcedures.length > 4 && (
        <div className="seeAllText" onClick={() => setFullListOpen(true)}>
          <IonText> See all</IonText>
        </div>
      )}

      {selectedProcedures.length >= 4 && (
        <button
          className="addProcedureButton"
          disabled={selectedProcedures.length >= 12 && disabled}
          onClick={() => setIsAddOpen(true)}
        >
          <div
            className={`buttonAndContent ${
              selectedProcedures.length >= 12
                ? "buttonAndContent-disabled"
                : "buttonContent-active"
            }`}
          >
            <IonIcon icon={addOutline} />
            <IonText>Add procedure</IonText>
          </div>
        </button>
      )}
    </div>
  );
};

export default ProcedureCard;
