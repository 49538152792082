import { defineCustomElements } from "@ionic/pwa-elements/loader";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import {
  cameraOutline,
  close,
  closeOutline,
  cloudUploadSharp,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import {
  COLLECTOR_URL,
  setupInterceptorsTo,
} from "../../helper/apiHelper/apiHelper";
import { blobToFile } from "../../helper/formater";
import { usePhotoCapture } from "../../hooks/usePhotoCapture";
import { useAppSelector } from "../../store/hooks";
import Toast, { newToast } from "../Common/Toaster/Toast";
import "./DocUpload.scss";
const collectorApi = setupInterceptorsTo(
  axios.create({
    baseURL: COLLECTOR_URL,
  })
);

interface DocUploadProps {
  openDocumentUpload: boolean;
  setOpenDocumentUpload: (item: boolean) => void;
  files: any;
  setFiles: (item: any) => void;
}

type ToastedList = {
  id: number;
  description: string;
};

const DocUpload: React.FC<DocUploadProps> = ({
  openDocumentUpload,
  setOpenDocumentUpload,
  files,
  setFiles,
}) => {
  const [uploads, setUploads] = useState<any[]>([]);
  const { photos, takePhoto } = usePhotoCapture("census");
  const [toastedMessage, setToastedMessage] = useState<ToastedList[]>([]);
  const currentFacility = useAppSelector(
    (state) => state.census.censusFacility
  );
  const thisDate = useAppSelector((state) => state.census.activeDate);

  const randomString = (length: any) => {
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  };

  const onBrowseImage = (e: any) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    let fileExt = file?.name?.split(".")?.pop();
    let fileName =
      currentFacility?._id + "_" + randomString(19) + "." + fileExt;

    let fileData = null;

    reader.readAsDataURL(file);
    reader.onloadend = async (ev: any) => {
      fileData = file;
      let cameraImage = {
        key: fileName, //file.name,
        size: file.size,
        file_type: fileExt,
        checksum: null, //checksumval,
        file: file,
        img: reader.result,
      };

      let formData = new FormData();

      formData.append("file", file);
      formData.append("fileName", fileName);
      collectorApi.post("/dailycensus/image", formData);
      let tempFiles = [];
      tempFiles.push(cameraImage);
      setFiles([...files, ...tempFiles]);
    };
  };

  useEffect(() => {
    if (photos) {
      upload_direct(photos);
    }
  }, [photos]);

  useEffect(() => {
    if (openDocumentUpload === false) {
      setFiles([]);
      setUploads([]);
    }
  }, [openDocumentUpload]);

  const upload_direct = async (photos: any) => {
    let formData = new FormData();

    let file = blobToFile(new Blob([photos?.webviewPath]), photos?.filepath);

    formData.append("file", file);
    formData.append("fileName", photos?.filepath);
    formData.append("size", photos?.size);

    collectorApi.post("/dailycensus/image", formData).then((res: any) => {
      if (res?.data) {
        setUploads([...uploads, photos]);
      }
    });
  };

  const removeFileHandler = (i: number) => {
    let newdata = files?.filter((item: any, index: any) => index !== i);
    setFiles(newdata);
  };

  const capturePhoto = () => {
    defineCustomElements(window);
    takePhoto();
  };

  const deletePicture = (fp: any) => {
    setUploads(uploads?.filter((photoPath) => photoPath.filepath !== fp));
  };

  const onUploadImage = () => {
    let newFiles = files?.map((file: any) => ({
      key: file?.key,
      size: file?.size,
      checksum: null,
      file: {},
    }));

    let newUploads = uploads?.map((upload: any) => ({
      key: upload?.filepath,
      size: upload?.size,
      checksum: null,
      file: {},
    }));

    let finalData = [...newFiles, ...newUploads];

    let uploadData = {
      files: finalData,
      facility_id: currentFacility?._id,
      comments: "",
      service_date: thisDate,
    };

    collectorApi.post("/dailycensus/documents", uploadData).then((res: any) => {
      if (res?.status === 200) {
        setOpenDocumentUpload(false);
        let toastMsg = newToast("Attachments uploaded successfully", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        setOpenDocumentUpload(false);
        let toastMsg = newToast("Failed to upload attachments", "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    });
  };

  return (
    <>
      <IonModal
        className="docupload-modal"
        isOpen={openDocumentUpload}
        onDidDismiss={() => setOpenDocumentUpload(false)}
        breakpoints={[0, 0.3, 0.4, 1]}
        initialBreakpoint={0.4}
        backdropBreakpoint={0.2}
        style={{ overflowY: "scroll" }}
      >
        <IonHeader className="doc-upload-header">
          <IonToolbar className="doc-upload-toolbar">
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  setOpenDocumentUpload(false);
                }}
              >
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle className="doc-upload-title">Add attachments</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="doc-upload-content">
          <div className="doc-upload-section">
            <p>Add a photo or a document</p>
            <div className="top-btn-section">
              <div className="fileBrowse btn btn-primary">
                {/* <IonIcon icon={cloudUploadSharp} /> */}
                <span>Browse files</span>
                <input
                  type="file"
                  className="input-upload"
                  onChange={(e) => {
                    onBrowseImage(e);
                  }}
                />
              </div>
              {/* <IonIcon
                icon={cameraOutline}
                onClick={capturePhoto}
                size="large"
                className="cam-icon"
              /> */}
            </div>
            <div className="doc-upload-SubContent">
              <div className="uploadedFiles">
                {files?.map((item: any, i: any) => (
                  // item?.status === "uploaded" ? (
                  <div className={`singleUploadingFileuploaded`} key={i}>
                    <div className="fileTypeInfo">
                      <p>{item?.file_type}</p>
                    </div>

                    <div className="file-info">
                      <div className="file-name">{item?.key}</div>

                      <div className="file-size">{item?.size}</div>
                    </div>

                    <div onClick={() => removeFileHandler(i)}>
                      <IonIcon icon={closeOutline} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <IonGrid>
              <IonRow>
                {uploads?.map((photo: any, index: any) => (
                  <>
                    <IonCol size="3" key={index}>
                      <IonImg src={photo?.webviewPath} />
                    </IonCol>
                    <IonCol size="1">
                      <IonIcon
                        icon={closeOutline}
                        onClick={(e) => deletePicture(photo?.filepath)}
                      />
                    </IonCol>
                  </>
                ))}
              </IonRow>
            </IonGrid>
          </div>

          <IonGrid>
            <IonRow>
              <IonCol size="12">
                {(files?.length > 0 || uploads?.length > 0) && (
                  <div className="bottomBtn">
                    <div
                      className="fileUpload btn btn-primary"
                      onClick={() => {
                        onUploadImage();
                      }}
                    >
                      <IonIcon icon={cloudUploadSharp} />
                      <span>Upload</span>
                    </div>
                  </div>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>

      <Toast
        toastList={toastedMessage}
        position="top-right"
        autoDelete={true}
        autoDeleteTime={4000}
      />
    </>
  );
};

export default DocUpload;
