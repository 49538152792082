/* eslint-disable react-hooks/exhaustive-deps */
import { IonRadio } from "@ionic/react";
import React, { useEffect, useState } from "react";

type RadioButtonProps = {
  value: string;
  className?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  onCheck?: (isChecked: boolean) => void;
};

const RadioButton: React.FC<RadioButtonProps> = ({
  value,
  className,
  onCheck,
  disabled,
  defaultChecked,
  ...rest
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked ? true : false);

  useEffect(() => {
    if (onCheck) {
      onCheck(isChecked);
    }
  }, [isChecked]);

  return (
    <IonRadio
      slot="start"
      value={value}
      mode="md"
      disabled={disabled ? true : false}
      className={className}
      defaultChecked={defaultChecked}
      onChange={() => setIsChecked(() => !isChecked)}
      {...rest}
    />
  );
};

export default RadioButton;
