import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonGrid,
  IonText,
  IonToolbar,
  IonRow,
  IonCol,
  IonButtons,
  IonBackButton,
  IonLabel,
  IonIcon,
  IonButton,
  useIonRouter,
} from "@ionic/react";

import {
  arrowBackOutline,
  eyeOffOutline,
  eyeOutline,
  informationCircleOutline,
} from "ionicons/icons";

import "../ForgotPassword/ForgotPassword.scss";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
// import { switchTheme } from "../../store/reducers/layout";
import Button from "../../components/Common/Buttons/Buttons";
import { Layout } from "../../components/Layout/Layout";
import { InputBoxType } from "../../types/Types";
import { updatePassword } from "../../helper/backendHelper";
import { resetData, setError } from "../../store/reducers/forgotPassword";
import InputFieldNew from "../../components/Common/InputFieldNew/InputFiledNew";

const SetNewPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const thisState = useAppSelector((state) => state.forgotPassword);

  const [newPassword, setNewPassword] = useState<InputBoxType>("");
  const [confirmPassword, setConfirmPassword] = useState<InputBoxType>("");

  const [invalidnewPassword, setInvalidNewPassword] = useState<boolean>(false);
  const [invalidconfirmPassword, setInvalidConfirmPassword] =
    useState<boolean>(false);
  const [errormsg, setErrormsg] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] =
    useState<boolean>(false);
  const router = useIonRouter();

  const HandleNewPasswordChange = (data: any) => {
    setNewPassword(data);
    let regularExpression =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{7,24}$/;
    if (
      data.length >= 8 &&
      regularExpression.test(data) &&
      data != thisState.userName
    ) {
      setInvalidNewPassword(false);
    } else {
      setInvalidNewPassword(true);
    }
  };

  const HandleConfirmPasswordChange = (data: any) => {
    setConfirmPassword(data);
  };

  useEffect(() => {
    if (newPassword == confirmPassword) {
      setInvalidConfirmPassword(false);
    } else {
      setInvalidConfirmPassword(true);
      setErrormsg("Both passwords must match");
    }
  }, [newPassword, confirmPassword]);

  const HandleUpdatePassword = () => {
    if (
      newPassword != "" &&
      confirmPassword != "" &&
      !invalidnewPassword &&
      !invalidconfirmPassword
    ) {
      setIsUpdated(true);
      const updatedPassword = {
        username: thisState.userName,
        password: newPassword,
        isOtpVerified: thisState.isOtpVerified,
        otp: thisState.otp,
      };
      updatePassword(updatedPassword).then((res) => {
        if (res?.status == 400) {
          setError("Server Error");
        } else {
          if (res?.status == "success") {
            setNewPassword("");
            setConfirmPassword("");
            dispatch(resetData());
            router.push("/resetPasswordSuccessful", "forward", "push");
          } else {
            setError(res?.message ? res?.message : "Update failed");
          }
        }
      });
    } else {
      setErrormsg("Please enter valid password");
    }
  };

  const handleIconClick = (data: string) => {
    if (data == "newpassword") {
      setNewPasswordVisible(!newPasswordVisible ? true : false);
    }
    if (data == "confirmpassword") {
      setConfirmPasswordVisible(!confirmPasswordVisible ? true : false);
    }
  };

  return (
    <Layout
      isLoading={thisState.isloading}
      isError={thisState.isError}
      errorMsg={thisState.errorMsg}
    >
      <IonContent className="forgot-password-page ion-padding">
        <div className="main-bg">
          <IonHeader class="ion-no-border" className="ion-header">
            <IonToolbar className="ion-toolbar" mode="ios">
              <IonButtons slot="start">
                <IonBackButton
                  defaultHref="/passwordRecovery"
                  text=""
                  icon={arrowBackOutline}
                />
              </IonButtons>

              {/* <IonTitle className="title-header">Password recovery</IonTitle> */}
            </IonToolbar>
          </IonHeader>
          <div className="centered-div-holder">
            <div className="reset-password-container">
              <IonGrid className="ion-grid1" fixed>
                <IonRow className="heading-row-updatepswrd">
                  <IonText className="heading-updatepswrd">
                    {" "}
                    Create new password{" "}
                  </IonText>
                </IonRow>

                <IonRow className="ion-row heading-updatepswrd-row-center">
                  <IonText className="ion-text content-text-updatepswrd">
                    Your new password must be different from previous used
                    passwords.
                  </IonText>
                </IonRow>

                <IonRow className="ion-row new-pasword-inputs">
                  <IonCol class="ion-align-self-start, my-thin-col">
                    <IonLabel
                      position="fixed"
                      className="ion-label updatepswrd-label"
                    >
                      {" "}
                      Password{" "}
                    </IonLabel>
                  </IonCol>
                </IonRow>

                <IonRow className="ion-row">
                  <div className="password-holder">
                    <InputFieldNew
                      type={newPasswordVisible ? "text" : "password"}
                      value={newPassword}
                      onKeyUp={(e) =>
                        HandleNewPasswordChange(e.currentTarget?.value)
                      }
                    />
                    <IonButton
                      className="passwordHideShow eye-holder"
                      fill="clear"
                      onClick={() => handleIconClick("newpassword")}
                    >
                      <IonIcon
                        className="show-icon"
                        icon={newPasswordVisible ? eyeOutline : eyeOffOutline}
                      ></IonIcon>
                    </IonButton>
                  </div>
                </IonRow>

                {invalidnewPassword && (
                  <div className="tooltip">
                    <div className="error-icon-msg">
                      <IonIcon
                        className="show-icon i-icon"
                        icon={informationCircleOutline}
                      ></IonIcon>
                      <span>Please ensure that your password...</span>
                    </div>
                    <span className="tooltiptext">
                      <ul>
                        <li>is 8 characters or longer</li>
                        <li>is not the same as your username</li>
                        <li>contains atleast one uppercase</li>
                        <li>contains atleast one lowercase</li>
                        <li>contains atleast one number</li>
                        <li>contains atleast one special character (!@$)</li>
                      </ul>
                    </span>
                  </div>
                )}

                <IonRow className="ion-row new-pasword-inputs">
                  <IonCol class="ion-align-self-start, my-thin-col">
                    <IonLabel
                      position="fixed"
                      className="ion-label updatepswrd-label"
                    >
                      {" "}
                      Confirm password{" "}
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-row">
                  <div className="password-holder">
                    <InputFieldNew
                      type={confirmPasswordVisible ? "text" : "password"}
                      value={confirmPassword}
                      onKeyUp={(e) =>
                        HandleConfirmPasswordChange(e.currentTarget?.value)
                      }
                    />
                    <IonButton
                      className="passwordHideShow eye-holder"
                      fill="clear"
                      onClick={() => handleIconClick("confirmpassword")}
                    >
                      <IonIcon
                        className="show-icon"
                        icon={
                          confirmPasswordVisible ? eyeOutline : eyeOffOutline
                        }
                      ></IonIcon>
                    </IonButton>
                  </div>
                </IonRow>

                {invalidconfirmPassword && (
                  <span className="errormsg">{errormsg}</span>
                )}

                <IonRow className="ion-row">
                  <Button
                    fullWidth
                    disabled={
                      !newPassword ||
                      !confirmPassword ||
                      newPassword != confirmPassword
                    }
                    onClick={HandleUpdatePassword}
                  >
                    {" "}
                    <IonLabel className="lbl-continue">
                      Reset password
                    </IonLabel>{" "}
                  </Button>
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </div>
      </IonContent>
    </Layout>
  );
};

export default SetNewPassword;
