import React from "react";
import "./BottomCards.scss";
import Card from "../../../components/Common/Card/Card";
import { IonImg } from "@ionic/react";
interface BottomCardsItemProps {
  key?: any;
  Bname?: any;
  Btext?: any;
}

const BottomCardsItem: React.FC<BottomCardsItemProps> = (props: any) => {
  return (
    <Card className="card2-bottomcards ion-no-padding ion-no-margin">
      <div className="box">
        <div className="right div-1">{props.Bname} </div>
        <div className="right div-2">{props.Btext}</div>
        <div className="left div-img">
          <IonImg
            src="./assets/icon/Serleg1.png"
            alt="no-image"
            className="ion-image-bottomcards"
          />
        </div>
      </div>
    </Card>
  );
};

export default BottomCardsItem;
