import React from "react";
import { CalendarNav } from "@mobiscroll/react";
import { Datepicker, CalendarPrev, CalendarNext } from "@mobiscroll/react";
import "./CalendarEncounterHistory.scss";
import { IonCol, IonRow } from "@ionic/react";
//import MarkedData from './MarkedData';
interface CalendarEncounterHistoryProps {
  item?: any;
  data?: any[];
  handleMonthChange?: (args: any) => void;
  handleDateChange?: (date: any) => void;
}

const marked = [
  {
    date: new Date(2022, 5 - 1, 12),
    color: "#FF877A",
  },
  {
    date: new Date(2022, 5 - 1, 13),
    color: "#A7FFF8",
  },
  {
    date: new Date(2022, 5 - 1, 14),
    color: "#A7FFF8",
  },
  {
    date: new Date(2022, 5 - 1, 15),
    color: "#A7FFF8",
  },
  {
    date: new Date(2022, 5 - 1, 16),
    color: "#A7FFF8",
  },
  {
    date: new Date(2022, 5 - 1, 17),
    color: "#FF877A",
  },
  {
    date: new Date(2022, 5 - 1, 18),
    color: "#FF877A",
  },
  {
    date: new Date(2022, 5 - 1, 19),
    color: "#A7FFF8",
  },
  {
    recurring: {
      repeat: "yearly",
      month: 4,
      day: 1,
    },
    color: "ffc400",
  },
];

const CalendarEncounterHistory: React.FC<CalendarEncounterHistoryProps> = (
  props: any
) => {
  let callendarData: any = [];

  props.data.forEach((item: any) => {
    callendarData = [
      ...callendarData,
      { date: item?.service_date_from, color: "#FF877A" },
    ];
  });

  callendarData = [
    ...callendarData,
    {
      recurring: {
        repeat: "yearly",
        month: 4,
        day: 1,
      },
      color: "ffc400",
    },
  ];

  const handleMonthChange = (args: any, init: any) => {
    props.handleMonthChange(args);
  };

  const handleDateSelect = (e: any) => {
    if (props.handleDateChange) props.handleDateChange(e.date);
  };

  const customWithNavButtons = () => {
    return (
      <>
        <div className="enc-history-calendar">
          <IonRow class="ion-no-margin ion-no-padding">
            <IonCol class="ion-align-self-start" size="1">
              <CalendarPrev />
            </IonCol>
            <IonCol class="ion-align-self-center">
              <CalendarNav />
            </IonCol>
            <IonCol class="ion-align-self-end" size="1">
              <CalendarNext />
            </IonCol>
          </IonRow>
        </div>
      </>
    );
  };

  return (
    <>
      <Datepicker
        className="calendardiv-encounterHistory"
        controls={["calendar"]}
        display="inline"
        calendarType={"month"}
        pages={1}
        renderCalendarHeader={customWithNavButtons}
        showWeekNumbers={false}
        touchUi={true}
        showOuterDays={true}
        showRangeLabels={false}
        onPageChange={handleMonthChange}
        onCellClick={handleDateSelect}
        // value={moment(new Date())}
        responsive={{
          custom: {
            // Custom breakpoint
            // breakpoint: 2000,
            display: "center",
          },
        }}
        marked={callendarData}
      />
    </>
  );
};

export default CalendarEncounterHistory;
