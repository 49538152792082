import React, { useState, useEffect } from "react";
import { IonIcon } from "@ionic/react";
import { searchOutline, closeOutline } from "ionicons/icons";
import { useAppSelector } from "../../../store/hooks";
import { selectTheme } from "../../../store/reducers/layout";
import { SearchFilter } from "./SearchFilter";
import "./SearchBox.scss";

interface propType {
  value?: string;
  disabled?: boolean;
  isEmpty?: boolean;
  handleSearchChange?: any;
  variant?: string;
  placeholder?: string;
  isError?: boolean;
  isSearchFilter?: boolean;
  filtOptions?: object;
  onChange: (txtdata: string) => void;
  onSubmit?: () => void;
}

const SearchBox: React.FC<propType> = ({
  onChange,
  variant,
  disabled,
  placeholder,
  onSubmit,
  isError,
  isEmpty,
  isSearchFilter,
  filtOptions,
  value,
  ...rest
}) => {
  const [isContent, setIsContent] = useState(false);
  const [txtContent, setTxtContent] = useState("");
  const [sbClass, setSbClass] = useState("");
  const isDarkTheme = useAppSelector(selectTheme);
  const [isFocus, setIsFocus] = useState(false);
  // const [isEmptyField, setIsEmptyField] = useState(false);
  const [searchfilt, setSearchfilt] = useState("All");
  const handleChangeText = (txtdata: string) => {
    onChange(txtdata);
    setIsContent(txtdata !== "");
    setTxtContent(txtdata);
  };
  const handleIconClick = (txticon: string) => {
    if (txticon === "closeOutline") {
      setTxtContent("");
      setIsContent(false);
      onChange("");
      if (onSubmit) {
        onSubmit();
      }
    }
  };
  const onSearchFilter = (selectSearchfilter: string) => {
    setSearchfilt(selectSearchfilter);
  };

  useEffect(() => {
    if (value === "" || typeof value === "undefined") {
      setTxtContent("");
      setIsContent(false);
    }
  }, [value]);

  useEffect(() => {
    let classes = "search-holder";
    if (variant === "normal") classes = `${classes} search-holder-normal`;
    if (variant === "round-corner")
      classes = `${classes} search-holder-roundCorner`;
    if (variant === "light-round-Corner")
      classes = `${classes} search-holder-roundCornerLight`;
    if (variant === "search-holder-dashboard")
      classes = `${classes} search-holder-dashboard`;
    if (variant === "round-edge") {
      classes = `search-holder-roundedge`;
      if (txtContent === "") {
        classes = `search-holder-roundedge search-holder-without-data`;
      }
    }
    if (!isDarkTheme) classes = `${classes} search-holder-black`;
    if (disabled) classes = `${classes} search-disable`;
    if (variant !== "round-edge")
      if (isFocus) classes = `${classes} search-holder-focus`;
    if (isError) classes = `${classes} search-holder-error`;
    setSbClass(classes);
  }, [isDarkTheme, variant, disabled, isFocus, isError, txtContent, isEmpty]);
  return (
    <div className="search-container">
      <div className={sbClass}>
        <div>
          {isSearchFilter && (
            <SearchFilter
              onSearchFilter={onSearchFilter}
              selected={searchfilt}
              filtOptions={filtOptions}
            />
          )}
        </div>
        {!isContent && !isSearchFilter && (
          <div className="search-icon-holder">
            <div className="icon">
              <IonIcon
                icon={searchOutline}
                size="small"
                slot="start"
                onClick={() =>
                  handleIconClick(isContent ? "closeOutline" : "searchOutline")
                }
              />
            </div>
          </div>
        )}
        <div className={"input-holder"}>
          <input
            type="text"
            name="search"
            id="search"
            placeholder={isEmpty ? "" : placeholder}
            disabled={disabled}
            className="search-input"
            value={txtContent}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            onChange={(e) => handleChangeText(e.target.value)}
          />
        </div>
        {isContent && (
          <div className="icon-holder-close">
            <div className="icon">
              <IonIcon
                icon={closeOutline}
                size="small"
                slot="start"
                style={{ color: "#706f74" }}
                onClick={() =>
                  handleIconClick(isContent ? "closeOutline" : "searchOutline")
                }
              />
            </div>
          </div>
        )}
        {!isContent && isSearchFilter && (
          <div className="icon-holder-close">
            <div className="icon">
              <IonIcon
                icon={searchOutline}
                size="small"
                slot="start"
                style={{ color: "#706f74" }}
                onClick={() =>
                  handleIconClick(isContent ? "closeOutline" : "searchOutline")
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBox;
