import React, { useEffect, useState } from "react";
import { Eventcalendar } from "@mobiscroll/react";
import {
  Datepicker,
  CalendarNav,
  CalendarPrev,
  CalendarNext,
} from "@mobiscroll/react";
import "./MobiscrollCalendar.scss";
import { getDailysummary } from "../../helper/backendHelper";
import moment from "moment";
import { useAppSelector } from "../../store/hooks";

interface MobiscrollCalendarProps {
  calendarType?: string;
}
const CalendarMobiscroll: React.FC<MobiscrollCalendarProps> = (props: any) => {
  const [firstLastDate, setFirstLastDate] = useState({
    firstDate: "",
    lastDate: "",
  });

  const { activeFacility } = useAppSelector((state) => state.dashboard);

  console.log("asdas", activeFacility);

  const [dailyCensusData, setDailyCensusData] = useState<any[]>([]);

  const calendarHeader = () => {
    return (
      <div className="custom-buttons">
        <CalendarPrev className="custom-prev" />
        <CalendarNav className="custom-nav" />
        <CalendarNext className="custom-next" />
      </div>
    );
  };

  const handleMonthChange = (args: any, inst: any) => {
    setFirstLastDate({
      firstDate: args.firstDay,
      lastDate: args.lastDay,
    });
  };

  useEffect(() => {
    const args = {
      firstDay: moment().startOf("week"),
      lastDay: moment().endOf("week"),
    };
    handleMonthChange(args, {});
  }, []);

  useEffect(() => {
    if (firstLastDate?.firstDate !== "" && firstLastDate?.lastDate !== "") {
      dailyCensusListHandler(firstLastDate);
    }
  }, [firstLastDate, activeFacility]);

  const dailyCensusListHandler = async (firstLastDate: any) => {
    try {
      let res = await getDailysummary({
        date_from: moment(firstLastDate.firstDate).format("YYYY-MM-DD"),
        date_to: moment(firstLastDate.lastDate).format("YYYY-MM-DD"),
      });

      console.log(res.results);
      let callendarDataNew: any[] = [];

      res.result.forEach((item: any) => {
        if (
          activeFacility === item.facility_id &&
          !callendarDataNew
            .map((element) => element.date)
            .includes(item.service_date_from)
        )
          callendarDataNew = [
            ...callendarDataNew,
            { date: item?.service_date_from, color: "#FFDF7B" },
          ];
      });

      setDailyCensusData(callendarDataNew);
    } catch (error) {}
  };

  return (
    <Datepicker
      className="dashboard-calendar"
      theme="material"
      renderCalendarHeader={calendarHeader}
      controls={["calendar"]}
      calendarType={props?.calendarType !== "" ? props?.calendarType : "month"}
      display="inline"
      onPageChange={handleMonthChange}
      marked={dailyCensusData}
      // view={{
      //   calendar: {
      //     labels: true,
      //     type: "week",
      //     size: 1,
      //   },
      // }}
    />
  );
};
export default CalendarMobiscroll;
