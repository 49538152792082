/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PatientDetailsType } from "../../types/PatientDetailsType";
import { setInitialCode } from "../thunk/editProcedureAndDiagnosis";

type EditProcedureAndDiagnosisState = {
  isloading: boolean;
  isError: boolean;
  errorMsg: any;
  thisPatient: PatientDetailsType | null;
  diagnosisCodesSelected: any[];
  procedureCodesSelected: any[];
};

const initialState: EditProcedureAndDiagnosisState = {
  isloading: true,
  isError: false,
  errorMsg: "",
  thisPatient: null,
  diagnosisCodesSelected: [],
  procedureCodesSelected: [],
};

export const editProcedureAndDiagnosisSlice = createSlice({
  name: "editProcedureAndDiagnosis",
  initialState,
  reducers: {
    setProcedureCode: (state, action: PayloadAction<any>) => {
      state.procedureCodesSelected = action.payload;
    },

    setDiagnosisCode: (state, action: PayloadAction<any>) => {
      action.payload.forEach((item: any) => {
        if (
          state.diagnosisCodesSelected
            .map((item) => item.code)
            .includes(item.code)
        ) {
          state.diagnosisCodesSelected = state.diagnosisCodesSelected.filter(
            (element) => element.code !== item.code
          );
        } else
          state.diagnosisCodesSelected = [
            ...state.diagnosisCodesSelected,
            item,
          ];
      });
    },

    handleRegroupCode: (state, action: any) => {
      if (action.payload.code === "diagnosis") {
        let newArray = [...state.diagnosisCodesSelected];
        const element = newArray.splice(action.payload.from, 1)[0];
        newArray.splice(action.payload.to, 0, element);
        state.diagnosisCodesSelected = [...newArray];
      }

      if (action.payload.code === "procedure") {
        let newArray = [...state.procedureCodesSelected];
        const element = newArray.splice(action.payload.from, 1)[0];
        newArray.splice(action.payload.to, 0, element);
        state.procedureCodesSelected = [...newArray];
      }
    },
    resetThisState: (state) => {
      state.diagnosisCodesSelected = initialState.diagnosisCodesSelected;
      state.errorMsg = initialState.errorMsg;
      state.isError = initialState.isError;
      state.isloading = initialState.isloading;

      state.procedureCodesSelected = initialState.procedureCodesSelected;

      state.thisPatient = initialState.thisPatient;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setInitialCode.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(setInitialCode.fulfilled, (state, action) => {
        state.thisPatient = action.payload?.patientDetail
          ? action.payload?.patientDetail
          : null;
        state.diagnosisCodesSelected = action.payload?.diagnosisData
          ? action.payload?.diagnosisData
          : [];
        state.procedureCodesSelected = action.payload?.proceduresData
          ? action.payload?.proceduresData
          : [];

        state.isloading = false;
      })
      .addCase(setInitialCode.rejected, (state, action) => {
        state.isloading = false;
        state.isError = true;
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload;
        } else {
          state.errorMsg = action.error.message?.toString();
        }
      });
  },
});

export const {
  setProcedureCode,
  setDiagnosisCode,
  handleRegroupCode,
  resetThisState,
} = editProcedureAndDiagnosisSlice.actions;
export const editProcedureAndDiagnosisReducer =
  editProcedureAndDiagnosisSlice.reducer;
