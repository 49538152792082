import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import React, { useState } from "react";
import "./togglebuttonbordered.scss";

const ToggleButtonBordered: React.FC<any> = (props) => {
  const [selected, setSelected] = useState("Male");
  return (
    <IonSegment
      value={selected}
      className="buttnSegmentlined"
      onIonChange={(e: any) => {
        setSelected(e.detail.value);
      }}
    >
      {props.data.arr.map((item: any, i: any) => (
        <IonSegmentButton
          value={item?.name}
          className={
            selected === item?.name
              ? "togglebttnlined active"
              : "togglebttnlined"
          }
        >
          <IonLabel>{item?.name}</IonLabel>
        </IonSegmentButton>
      ))}
    </IonSegment>
  );
};

export default ToggleButtonBordered;
