import { IonItemOption } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { getProgressNoteImage } from "../../helper/backendHelper";
import { VisitsByServicedate } from "../../types/VisitsByServicedate";
import ProgressPopup from "./ProgressPopup";

type SignedNotesProps = {
  visit?: VisitsByServicedate;
  onSlideClose?: any;
};

const SignedNotes: React.FC<SignedNotesProps> = ({ visit, onSlideClose }) => {
  const [noteImg, setNoteImg] = useState<any>();
  const [imgPopup, setImgPopup] = useState(false);

  useEffect(() => {
    // getVisitCharts

    if (visit?._id) {
      getProgressNoteImage(visit?._id).then((res) => {
        if (res?.status === "success") {
          setNoteImg(res.data);
        }
      });
    }
  }, [visit]);

  const onClick = () => {
    onSlideClose();
    if (noteImg) {
      setImgPopup(true);
    }
  };

  return (
    <>
      <ProgressPopup
        isOpen={imgPopup}
        closeModal={() => setImgPopup(false)}
        image={noteImg}
      />
      <IonItemOption className="end" onClick={onClick}>
        Last Visit Note
      </IonItemOption>
    </>
  );
};

export default SignedNotes;
