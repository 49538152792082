import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonLabel,
  useIonRouter,
  IonInput,
  IonToast,
} from "@ionic/react";
import {
  alertCircleOutline,
  calendarClearOutline,
  cameraOutline,
  chevronBack,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import Button from "../../components/Common/Buttons/Buttons";
import CheckBox from "../../components/Common/CheckBox/CheckBox";
import "./AddPatient.scss";
import moment from "moment";
import { Datepicker } from "@mobiscroll/react";
import { addPatients, getFacilityListData } from "../../helper/backendHelper";
import { FacilityObjType } from "../../types/FacilityType";
import { AddPatientFormValueProps } from "../../types/Types";
import { addPatientPayloadValidater } from "../../helper/Validation";
import { addPatientPayloadFromater } from "../../helper/formater";

interface TextInputProps {
  label: string;
  divclass: string;
  textclass: string;
}

// common input field component
export const TextInputComp: React.FC<TextInputProps> = ({
  label,
  divclass,
  textclass,
  children,
}) => {
  return (
    <div className={divclass}>
      <IonText className={textclass}>{label}</IonText>
      {children}
    </div>
  );
};

let initialValue = {
  firstname: "",
  middlename: "",
  lastname: "",
  phoneNumber: "",
  gender: "",
  ClaimocityMRN: "",
  FacilityMRN: "",
  Room: "",
  DOB: "",
  DOI: "",
  selectedFacility: null,
};

const AddPatient: React.FC = () => {
  const router = useIonRouter();
  const [values, setValues] = useState<AddPatientFormValueProps>(initialValue);
  const [validationData, setValidationData] = useState();
  const [facilityData, setFacilityData] = useState<FacilityObjType[]>();
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [isDatePicker2Open, setDatePicker2Open] = useState<boolean>(false);
  const [isValidate, setIsValidate] = useState(false);
  const [isHaveDob, setIsHaveDob] = useState(true);
  const [isSubmited, setIsSubmited] = useState(false);
  const [validateError, setValidateError] = useState({
    status: false,
    msg: "",
  });

  const HandleGender = (data: string) => {
    setValues({ ...values, gender: data });
  };

  const HandleSavePatient = (e: any) => {
    e.preventDefault();

    if (isValidate) {
      let payload = addPatientPayloadFromater(values);

      addPatients(payload).then((data) => {
        if (data?.status === "success" && data?.patient_id) {
          setIsSubmited(true);
          router.push("/census", "back");
        }
      });
    }
  };

  const onCancel = () => {
    setValues(initialValue);
    router.push("/census", "back");
  };

  const onInputChange = (e: any | React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.currentTarget?.name === "firstname" ||
      e.currentTarget?.name === "lastname"
    ) {
      if (e.currentTarget?.value?.length < 2) {
        setValidateError({
          status: true,
          msg: `${e.currentTarget?.name} should be minimum 2 characters`,
        });
      } else {
        setValidateError({
          status: false,
          msg: ``,
        });
      }
    }

    setValues({
      ...values,
      [e.currentTarget?.name]: e.currentTarget?.value.split(" ").join(""),
    });
  };

  useEffect(() => {
    getFacilityList();
  }, []);

  useEffect(() => {
    setIsValidate(addPatientPayloadValidater(values).validate);
  }, [values]);

  const getFacilityList = async () => {
    try {
      let res = await getFacilityListData();
      setFacilityData(res.results);
    } catch (error) {}
  };

  const getSelectedFacility = (e: any) => {
    let selectedFacility = facilityData?.find(
      (data) => data._id === e.detail.value
    );
    setValues({
      ...values,
      selectedFacility: selectedFacility ? selectedFacility : null,
    });
  };

  const handleDOB = (item: any) => {
    if (
      moment(item?.value).isAfter(new Date()) ||
      moment().diff(item?.value, "year") > 125
    ) {
      setValidateError({
        status: true,
        msg: "Please enter a valid date of birth",
      });
    } else {
      setValidateError({
        status: false,
        msg: ``,
      });
      setValues({
        ...values,
        DOB: item?.value,
      });
    }
  };

  // console.log({ values });

  return (
    <IonPage className="add-patient">
      <IonToast
        isOpen={isSubmited}
        onDidDismiss={() => setIsSubmited(false)}
        message={"Patient added Succesfully"}
        duration={1000}
      />
      <IonHeader className="newpatient-header">
        <IonToolbar className="ion-toolbar-dashboard" mode="ios">
          <IonIcon
            onClick={() => router.goBack()}
            className="left-icon"
            icon={chevronBack}
          />
          <IonTitle className="title-header-dashboard">New Patient</IonTitle>
          <div className="right-icon">
            <IonIcon className="camera-icon" icon={cameraOutline} />
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="addpatient-content">
        <div className="newpatient-container">
          <p>Patient information</p>
          {validateError.status && (
            <>
              <p className="error-red">{validateError.msg}</p>
            </>
          )}
          <TextInputComp
            label="Last name"
            divclass="patient-names-row-2"
            textclass="patient-sublabel"
          >
            <input
              type="text"
              name="lastname"
              id="lastname"
              className="name-input"
              onChange={onInputChange}
            />
          </TextInputComp>
          <TextInputComp
            label="First name"
            divclass="patient-names-row-2"
            textclass="patient-sublabel"
          >
            <input
              type="text"
              name="firstname"
              id="firstname"
              className="name-input"
              onChange={onInputChange}
            />
          </TextInputComp>
          <TextInputComp
            label="Middle name"
            divclass="patient-names-row-2"
            textclass="patient-sublabel"
          >
            <input
              type="text"
              name="middlename"
              id="middlename"
              className="name-input"
              onChange={onInputChange}
            />
          </TextInputComp>
          {/* <TextInputComp
            label="Phone number"
            divclass="patient-names-row-2"
            textclass="patient-sublabel"
          >
            <input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              className="name-input"
              placeholder="( _ _ _ ) _ _ _ - _ _ _ _"
              onChange={onInputChange}
            />
          </TextInputComp> */}
          <div className="two-col-input">
            <div className="dateOfServiceCardContent">
              <p>Date of Birth</p>
              <IonLabel
                className="dateOfServiceCardInput"
                onClick={() => (isHaveDob ? setDatePicker2Open(true) : null)}
              >
                <IonInput
                  type="text"
                  placeholder="DD/MM/YYYY"
                  value={
                    values.DOB ? moment(values.DOB).format("DD/MM/YYYY") : ""
                  }
                  disabled={!isHaveDob}
                />
                <IonIcon icon={calendarClearOutline} />
              </IonLabel>
              {isDatePicker2Open && (
                <Datepicker
                  controls={["calendar"]}
                  showOnClick={false}
                  showOnFocus={false}
                  isOpen={isDatePicker2Open}
                  onClose={() => setDatePicker2Open(false)}
                  onChange={(e) => handleDOB(e)}
                  touchUi={true}
                  value={values.DOB}
                  // onCellClick={handleDateSelect}
                  className="calendardiv-encounterHistory"
                />
              )}
            </div>
            <div className="checkbox-dob">
              <CheckBox
                value="off"
                onCheck={(data) => {
                  setIsHaveDob(!data);
                  if (data) {
                    setValues({
                      ...values,
                      DOB: "",
                    });
                  }
                }}
                label="I don't know"
              />
            </div>
          </div>
          <div className="gender-container">
            <IonText className="patient-sublabel">Gender</IonText>
            <IonGrid className="grid">
              <IonRow className="row">
                <IonCol
                  onClick={() => HandleGender("M")}
                  className={values.gender === "M" ? "col1-clicked" : "col1"}
                >
                  <div>
                    <IonText className="gender-text">Male</IonText>
                  </div>
                </IonCol>
                <IonCol
                  onClick={() => HandleGender("F")}
                  className={values.gender === "F" ? "col2-clicked" : "col2"}
                >
                  <div>
                    <IonText className="gender-text">Female</IonText>
                  </div>
                </IonCol>
                <IonCol
                  onClick={() => HandleGender("U")}
                  className={values.gender === "U" ? "col3-clicked" : "col3"}
                >
                  <div>
                    <IonText className="gender-text">Unknown</IonText>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <p>Facility information</p>
          {/* Med record input field */}

          <div className="patient-names-half-row">
            <div className="dateOfServiceCardContent">
              <p>Date of intake</p>
              <IonLabel
                className="dateOfServiceCardInput"
                onClick={() => setDatePickerOpen(true)}
              >
                <IonInput
                  type="text"
                  placeholder="DD/MM/YYYY"
                  value={
                    values.DOI ? moment(values.DOI).format("DD/MM/YYYY") : ""
                  }
                />
                <IonIcon icon={calendarClearOutline} />
              </IonLabel>
              {isDatePickerOpen && (
                <Datepicker
                  controls={["calendar"]}
                  showOnClick={false}
                  showOnFocus={false}
                  isOpen={isDatePickerOpen}
                  onClose={() => setDatePickerOpen(false)}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      DOI: e?.value,
                    })
                  }
                  touchUi={true}
                  value={values.DOI}
                  // onCellClick={handleDateSelect}
                  className="calendardiv-encounterHistory"
                />
              )}
            </div>
          </div>
          <div className="patient-names-half-row">
            {/* Last name input field */}
            <div className="patient-names-innerrow-1 patient-sublabel">
              <div className="new-patient-label">
                <IonLabel>Facility MRN</IonLabel>
                <IonIcon icon={alertCircleOutline} />
              </div>
              <TextInputComp
                label=""
                divclass="patient-names-innerrow-1"
                textclass="patient-sublabel"
              >
                <input
                  type="text"
                  name="FacilityMRN"
                  id="FacilityMRN"
                  className="name-input"
                  placeholder="_ _ _ _ _ _ _ _ _"
                  onChange={onInputChange}
                />
              </TextInputComp>
            </div>
          </div>
          <TextInputComp
            label="Facility"
            divclass="patient-names-row-2"
            textclass="patient-sublabel"
          >
            <IonSelect
              className="name-input select place"
              onIonChange={getSelectedFacility}
              placeholder={"Select Facility"}
            >
              {facilityData?.map((item, i: any) => {
                return (
                  <IonSelectOption
                    className="addpatient-dropdown-data"
                    value={item?._id}
                    key={i}
                  >
                    {item?.name}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
            {/* <IonAccordionGroup className="accordiangrp-style">
                <IonCard className="addpatient-accordian-card">
                  <IonAccordion className="accordian">
                    <IonItem
                      className="addpatient-title-container"
                      lines="none"
                      slot="header"
                    >
                      <IonLabel className="addpatient-card-title">
                      {facilityName}
                      </IonLabel>
                    </IonItem>
                    <IonList slot="content" className="addpatient-card-content">
                      {facilityData?.map((item: any, i: any) => {
                        return (
                          <IonList
                            className="addpatient-dropdown-data"
                            onClick={(i) => getSelectedFacilityID(item?._id,item?.name)}
                          >
                            {facilityData?.[i]?.name}
                          </IonList>
                        );
                      })}
                    </IonList>
                  </IonAccordion>
                </IonCard>
              </IonAccordionGroup> */}
          </TextInputComp>

          {/* <div className="patient-names-half-row">
            <div className="patient-names-innerrow-1 patient-sublabel">
              <div className="new-patient-label">
                <IonLabel>Claimocity MRN</IonLabel>
                <IonIcon icon={alertCircleOutline} />
              </div>
              <TextInputComp
                label=""
                divclass="patient-names-innerrow-1"
                textclass="patient-sublabel"
              >
                <input
                  type="text"
                  name="ClaimocityMRN"
                  id="ClaimocityMRN"
                  className="name-input"
                  placeholder="_ _ _ _ _ _ _ _ _"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      ClaimocityMRN: e.currentTarget?.value.split(" ").join(""),
                    })
                  }
                />
              </TextInputComp>
            </div>
          </div> */}

          <p>Other information</p>
          <div className="patient-names-half-row">
            {/* Last name input field */}
            <TextInputComp
              label="Room"
              divclass="patient-names-innerrow-1"
              textclass="patient-sublabel"
            >
              <input
                type="text"
                name="Room"
                id="Room"
                className="name-input"
                placeholder=""
                onChange={(e) =>
                  setValues({
                    ...values,
                    Room: e.currentTarget?.value.split(" ").join(""),
                  })
                }
              />
            </TextInputComp>
          </div>
          {/* <IonText className="patient-sublabel">
            <u>Add billing notes</u>
          </IonText> */}

          <div className="event-buttons">
            <div className="left-btn-holder">
              <Button boldfont color="secondary" fullWidth onClick={onCancel}>
                Cancel
              </Button>
            </div>
            <div className="right-btn-holder">
              <Button
                boldfont
                fullWidth
                onClick={HandleSavePatient}
                disabled={!isValidate || validateError.status}
              >
                Save patient
              </Button>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AddPatient;
