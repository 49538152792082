import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonText,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import {
  checkmark,
  chevronBackOutline,
  homeOutline,
  personOutline,
  star,
  starOutline,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { addNewPaticipantConversation, createGroupConversation, getCurrentUser, getReadConversation, listContactList, putFavoriteUser } from "../../../helper/backendHelper";
import "./contactList.scss";
import { useParams } from "react-router";
import Loader from "../../../components/Common/Loaders/Loader";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getContactList, setFavUser } from "../../../store/thunk/messageThunk";
import { setPracticeUsers, setSelectedChat, setSupportUsers } from "../../../store/reducers/message";

const ContactList: React.FC = (props: any) => {

  let Token: any = localStorage.getItem("Token");
  let currentUserId = JSON.parse(Token)?.user?._id;
  let conversationId:any = useParams<{ id?: string }>();
  let currentUser = JSON.parse(Token)?.user;

  const router = useIonRouter();
  const contactList = useAppSelector((state) => state.messages)?.contactList;
  const practiceUsers = useAppSelector((state) => state.messages)?.practiceUser;
  const supportUsers = useAppSelector((state) => state.messages)?.supportUser;
  const distributionList = useAppSelector((state) => state.messages)?.distributionGroup;
  const selectedChat = useAppSelector((state) => state.messages)?.selectedChat;
  const [selectedUser, setSelectedUser] = useState([currentUserId]);
  const [participants, setParticipants] = useState<any>([]);
  const [readId, setReadId] = useState("");
  const [showTab, setShowTab] = useState("All");
  const [loading,setLoading]=useState(false);
  const dispatch = useAppDispatch();

  const changeTab = (value: any) => {
    if(value === showTab){
      setShowTab("All");
    }
    else{
      setShowTab(value);
    }
  }

  console.log("tab",showTab);

  function groupBy(arr: any, criteria: any) {
    const newObj = arr.reduce(function (acc: any, currentValue: any) {
      if (!acc[currentValue[criteria]]) {
        acc[currentValue[criteria]] = [];
      }
      acc[currentValue[criteria]].push(currentValue);
      return acc;
    }, {});
    return newObj;
  }


  const getContactLists = async () => {
    setLoading(true);
    let res: any = await dispatch(getContactList({}))
    setLoading(false);
  };

  const getMessages = async () => {
    let res = await getReadConversation(conversationId?.id);
    if (res?.status === "success") {
      let user: any =[];
      if(res?.data[0]?.participantss?.length === 0 || res?.data[0]?.participantss === undefined){
        for(let i=0; i<selectedChat?.participants?.length; i++){
          user.push(selectedChat?.participants[i]?.user_id);
        }
        if(selectedChat?._id){
          setReadId(selectedChat?._id)
        }
        else{
          setReadId(conversationId?.id)
        }
      }else{
        for(let i=0; i<res?.data[0]?.participantss?.length; i++){
          user.push(res?.data[0]?.participantss[i]?.user_id);
        }
        setReadId(res?.data[0]?._id);
      }
     
      setSelectedUser([...user]);
      setParticipants([...user]);
    }
  };
console.log("participantss",participants);
  useEffect(() => {
    getContactLists();
    if(conversationId?.id){
      getMessages();
    }
  }, []);

  const changeTabUsers = async() => {
    let contact = contactList;
    if(showTab === "Favorites"){
     let practiceUser = contact?.filter((item: any) => {
       return item?.isInternal === false && item?.favorites;
     });
     await dispatch(setPracticeUsers(practiceUser));
     let supportUser = contact?.filter((item: any) => {
       return item?.isInternal === true && item?.favorites;
     });
     await dispatch(setSupportUsers(supportUser));
    }
    else{
     let practiceUser = contact?.filter((item: any) => {
       return item?.isInternal === false;
     });
     await dispatch(setPracticeUsers(practiceUser));
     let supportUser = contact?.filter((item: any) => {
       return item?.isInternal === true;
     });
     await dispatch(setSupportUsers(supportUser));
    }
  }

  useEffect(() => {
   changeTabUsers();
  }, [showTab]);

  const conversationUsers = (userId: any) => {
    let users = selectedUser;
    if(users.includes(userId)){
      let index = users.indexOf(userId);
      users.splice(index,1);
    }
    else{
      users.push(userId);
    }
    setSelectedUser([...users]);
  }

  const createNewConversation = async () => {
    let users = selectedUser;
    let userData: any = [];
    for(let i=0; i<users?.length; i++){
      if(users[i] === currentUserId){
        userData.push({
          user_id: users[i],
          has_newmessage: true, 
          status: true, 
          message_viewed: true
        })
      }else{
        userData.push({
          user_id: users[i],
          has_newmessage: true, 
          status: true, 
          message_viewed: false
        })
      }
    }
    let payload = {
      userdata: userData
    }
    let res = await createGroupConversation(payload);
    if(res?.status === "success"){
      for(let i=0; i<userData?.length; i++){
        if(userData[i]?.user_id === currentUserId){
          userData[i].practice_visibility = true
          userData[i].username = currentUser?.username;
          userData[i].status = true
        }else{
          const index = contactList.findIndex(object => {
            return object.user_id === userData[i]?.user_id;
          });
          console.log("index",index);
          
          userData[i].practice_visibility = true
          userData[i].username = contactList[index]?.display_name;
          userData[i].status = true
        }
       
      }
      let usersNewChat = JSON.parse(JSON.stringify(selectedChat));
      usersNewChat.participants = userData;
      setSelectedUser(currentUserId);
      await dispatch(setSelectedChat(usersNewChat))
      router.push(`/messagethread/${res?.results?.conversation_id}`,"back");
    }
  }

  const addOtherParticipants = async() => {
    let users = selectedUser;
    let userData = [];
    for(let i=0; i<users?.length; i++){
      if(users[i] === currentUserId){
        userData.push({
          user_id: users[i],
          has_newmessage: true, 
          status: true, 
          message_viewed: true
        })
      }else{
        userData.push({
          user_id: users[i],
          has_newmessage: true, 
          status: true, 
          message_viewed: false
        })
      }
    }
    let payload = [...userData]
    console.log("read id=",readId);
    
    let res = await addNewPaticipantConversation(readId,payload);
    if(res?.status === "success"){
      let usersNewChat = JSON.parse(JSON.stringify(selectedChat));
      usersNewChat.participantss = payload;
      await dispatch(setSelectedChat(usersNewChat))
      getMessages();
      router.push(`/messagethread/${conversationId?.id}`,"back");
    }
  }

  const searchUsers = async (searchText: any) => {
    if (searchText?.trim()?.length > 0) {
      let practiceUser = practiceUsers?.filter((item: any) =>
        item?.display_name?.toLowerCase()?.includes(searchText.toLowerCase())
      );
      await dispatch(setPracticeUsers(practiceUser));

      let supportUser = supportUsers?.filter((item: any) =>
        item?.display_name?.toLowerCase()?.includes(searchText.toLowerCase())
      );
      await dispatch(setSupportUsers(supportUser));
    } else if (searchText?.trim()?.length === 0) {
      let practiceUser = contactList?.filter((item: any) => {
        return item?.isInternal === false;
      });
      await dispatch(setPracticeUsers(practiceUser));
      let supportUser = contactList?.filter((item: any) => {
        return item?.isInternal === true;
      });
      await dispatch(setSupportUsers(supportUser));
    }
  };

  const favoriteUser=async(userId:any,status:any)=>{
    let body={
      status:!status,
      user_id:userId,
      contactList: contactList,
      practiceUsers: practiceUsers,
      supportUsers: supportUsers
    }
    let res=await dispatch(setFavUser(body));
    console.log(res);
  }

  console.log("selectedUser",selectedUser);
  return (
    <IonPage className="ion-page-dashboard">
      <IonHeader className="ion-header-dashboard d-pad-05">
        <IonToolbar className="ion-toolbar-dashboard" mode="ios">
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                router.goBack();
              }}
            >
              <IonIcon
                className="backbutton-color-contactList"
                icon={chevronBackOutline}
              />
            </IonButton>
          </IonButtons>
          <IonTitle className=".contactList-toolbar-text">
            {/* Send message to */}
            New message
          </IonTitle>
          {(selectedUser?.length > 1  && (selectedUser?.length!==participants?.length)) ? (
            <div
              className="contactListheadericon enabled"
              onClick={() => {
                readId?.trim()?.length === 0
                  ? createNewConversation()
                  : addOtherParticipants();
              }}
            >
              <IonIcon icon={checkmark} />
            </div>
          ) : (
            <div className="contactListheadericon">
              <IonIcon icon={checkmark} />
            </div>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className="contact-page">
        <IonToolbar className="ion-toolbar-dashboard" mode="ios">
          <p className="ion-text1-search" style={{marginLeft:"15px",marginBottom:"5px"}}>Send message</p>
          <p className="ion-text1-search" style={{marginLeft:"15px",marginBottom:"5px"}}>To</p>
          <IonSearchbar 
            placeholder="Search user..."
            className="ionsearchbar"
            showCancelButton="focus"
            cancelButtonText="X"
            //value={searchText}
            onIonChange={(e) => {
              searchUsers(e.detail.value);
            }}
          ></IonSearchbar>
          <IonRefresher
            //onIonRefresh={doRefresh}
            slot="fixed"
          >
            <IonRefresherContent
              //pullingIcon={chevronDownCircleOutline}
              pullingText="Pull to refresh"
              refreshingSpinner="circles"
              refreshingText="Refreshing..."
            >
              {" "}
            </IonRefresherContent>
          </IonRefresher>
        </IonToolbar>

        {(showTab === "All" ||
          showTab === "Practice" ||
          showTab === "Favorites") && (
          <div>
            <p className="contactHeading">Practice</p>
            {loading === true ? (
              <div className="loaderPatientSearch">
                <Loader
                  data={{
                    name: "crescent",
                    color: "primary",
                  }}
                />
              </div>
            ) : loading===false && practiceUsers?.length > 0 ? (
              practiceUsers?.map((item: any) => (
                <div className="singlecontact">
                  <div className="leftContact">
                    <div
                      className={`starIcon ${item?.favorites && "favourite"}`}
                    >
                      <IonIcon
                        icon={star}
                        onClick={() => {
                          favoriteUser(
                            item?.user_id,
                            item?.favorites ? item?.favorites : false
                          );
                        }}
                      />
                    </div>
                    <div className="conatctName">
                      <p>{item?.display_name}</p>
                    </div>
                  </div>
                  <div className="check-box">
                    <IonCheckbox
                      checked={
                        selectedUser.includes(item?.user_id) ? true : false
                      }
                      onClick={() => {
                        conversationUsers(item?.user_id);
                      }}
                      disabled={
                        participants?.includes(item?.user_id) ||
                        item?.user_id === currentUserId
                      }
                    />
                  </div>
                </div>
              ))
            ) :loading===false && practiceUsers?.length === 0 ? (
              <div>No contact</div>
            ) :
            (<></>)}
          </div>
        )}

        {(showTab === "All" ||
          showTab === "Support" ||
          showTab === "Favorites") && (
          <div>
            <p className="contactHeading">Support</p>
            {
            loading === true ? (
              <div className="loaderPatientSearch">
                <Loader
                  data={{
                    name: "crescent",
                    color: "primary",
                  }}
                />
              </div>
            )
            : loading===false && supportUsers?.length > 0 ? (
              supportUsers?.map((item: any) => (
                <div className="singlecontact">
                  <div className="leftContact">
                    <div
                      className={`starIcon ${item?.favorites && "favourite"}`}
                    >
                      <IonIcon
                        icon={star}
                        onClick={() => {
                          favoriteUser(
                            item?.user_id,
                            item?.favorites ? item?.favorites : false
                          );
                        }}
                      />
                    </div>
                    <div className="conatctName">
                      <p>{item?.display_name}</p>
                    </div>
                  </div>
                  <div className="check-box">
                    <IonCheckbox
                      checked={
                        selectedUser.includes(item?.user_id) ? true : false
                      }
                      onClick={() => {
                        conversationUsers(item?.user_id);
                      }}
                      disabled={
                        participants?.includes(item?.user_id) ||
                        item?.user_id === currentUserId
                      }
                    />
                  </div>
                </div>
              ))
            ) : loading===false && supportUsers?.length === 0 ?(
              <div>No contact</div>
            ) : (
              <></>
            )}
          </div>
        )}

        <div>
          <p className="contactHeading">Distribution List</p>
          {
           loading === true ? (
            <div className="loaderPatientSearch">
              <Loader
                data={{
                  name: "crescent",
                  color: "primary",
                }}
              />
            </div>
          )
          :
          Object.keys(distributionList).map((key: any, index) => (
            <div className="singlecontact">
              <div className="leftContact">
                <div className="conatctName">
                  <p>All {key}'s</p>
                </div>
              </div>
              <div className="check-box">
                <IonCheckbox />
              </div>
            </div>
          ))}
        </div>
      </IonContent>
      <IonFooter className="contact_footer">
        <div className="contact_footer_buttons">
          <div
            className={
              showTab === "Favorites"
                ? "selected_contact_single_button"
                : "contact_single_button"
            }
            onClick={() => changeTab("Favorites")}
          >
            <IonIcon icon={starOutline} />
            <IonText>Favorites</IonText>
          </div>
          <div
            className={
              showTab === "Practice"
                ? "selected_contact_single_button"
                : "contact_single_button"
            }
            onClick={() => changeTab("Practice")}
          >
            <IonIcon icon={homeOutline} />
            <IonText>Practice</IonText>
          </div>
          <div
            className={
              showTab === "Support"
                ? "selected_contact_single_button"
                : "contact_single_button"
            }
            onClick={() => changeTab("Support")}
          >
            <IonIcon icon={personOutline} />
            <IonText>Support</IonText>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default ContactList;
