import React, { useEffect, useRef, useState } from "react";
import {
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonItem,
  useIonRouter,
  IonCol,
  IonIcon,
  IonRow,
  IonText,
  useIonToast,
  IonLabel,
} from "@ionic/react";
import "./Census.scss";
import CptModal from "./CptModal";
import {
  callConfirmVisit,
  channgeStatus,
  getVisitDiagnosis,
  getVisitProcedures,
  putCptChange,
  recallSubmission,
  submitBillVisit,
  skipVisit,
  dischargeVisit,
  undoSkipVisit,
  putDiagnosiscodes,
} from "../../helper/backendHelper";
import CensusCard from "./CensusCard";
import { VisitsByServicedate } from "../../types/VisitsByServicedate";
import {
  alertCircle,
  calendar,
  checkmarkCircleSharp,
  personCircleOutline,
  star,
} from "ionicons/icons";
import { IconSvg } from "../Common/IconSvg/IconSvg";
import { Warning } from "../../icons/icons";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
// import { updateCPTVisit } from "../../store/thunk/censusThunk";
import MoreBtnModal from "./MoreBtnModal";
import { setError } from "../../store/reducers/forgotPassword";
import { resetThisState } from "../../store/reducers/newEncounter";
import CheckBox from "../Common/CheckBox/CheckBox";
import moment from "moment";
import RecallBtnFirstPopup from "./RecallBtnFirstPopup";
import RecallBtnSecondPopup from "./RecallBtnSecondPopup";
import DiagnosisCode from "../../pages/Encounter/DiagnosisCode/DiagnosisCode";
import ProcedureCode from "../../pages/Encounter/ProcedureCode/ProcedureCode";

import "./SwapCard.scss";

import {
  hapticFeedBack,
  processVisits,
  selectedDate,
  statusIcon,
  updateVisit,
  visitClone,
  visitHighlight,
  visitProcedureMenu,
  visitStatus,
  // visitUpdateEvent,
} from "../../services/CensusProvider";
import { socket, socket1 } from "../../socket";
import { getVisitsCensus } from "../../store/thunk/censusThunk";
import StatusIcon from "../Common/StatusIcon/StatusIcon";
import { selectUser } from "../../store/reducers/user";
import AddendumPopup from "../../components/Census/AddendumPopup";
import AddendumModal from "../../components/Census/Addendum";
import SignedNotes from "./SignedNotes";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import FeatureProvider from "../PermissionProvider/FeatureProvider";
import Toast, { newToast } from "../Common/Toaster/Toast";
import ChangeCPTModal from "../../pages/Encounter/ProcedureCode/ChangeCPTModal";
import multisort from "multisort";

interface SwapCardInterface {
  cardtype?: number;
  visit: VisitsByServicedate;
  slectionMode?: boolean;
  activeteSelectionMode?: () => void;

  index?: number;
  warning?: boolean;
  onSelect?: (
    visit: VisitsByServicedate,
    index: number,
    isSelected: boolean,
    group?: string
  ) => void;
  onLongPressMode?: boolean;
}
type ToastedList = {
  id: number;
  description: string;
};
const SwapCard: React.FC<SwapCardInterface> = ({
  cardtype,
  visit,
  slectionMode,
  activeteSelectionMode,
  onSelect,
  onLongPressMode,
  index,
  warning,
}) => {
  const thisEncounterState = useAppSelector((state) => state.newEncounter);

  const router = useIonRouter();
  const [openModal, setOpenModal] = useState(false);
  const [patientId, setPatientId] = useState("");
  const dispatch = useAppDispatch();
  const [isMoreBtnModal, setIsMoreBtnModal] = useState<boolean>(false);
  const [firstPopupISOpen, setFirstPopupISOpen] = useState<boolean>(false);
  const [secondPopupISOpen, setSecondPopupISOpen] = useState<boolean>(false);
  const serviceDate = useAppSelector((state) => state.census)?.serviceDate;
  const visitStatuses: any = useAppSelector(
    (state) => state.census
  )?.visitStatuses;
  const thisDate = useAppSelector((state) => state.census.activeDate);
  const userData = useAppSelector(selectUser);
  const [dxClick, setDxClick] = useState(false);
  const [cptClick, setCptClick] = useState(false);
  const currentFacility = useAppSelector(
    (state) => state.census.censusFacility
  );

  const isNoteRequired =
    (userData?.provider?.note_mode || userData?.practice?.note_mode) ===
    "required";
  // const [present, dismiss] = useIonToast();
  const ref: any = useRef(null);
  useOutsideClick(ref);
  const [addendumOpen, setAddendumOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isSigned, setIsSigned] = useState(false);
  const [noteStatus, setNoteStatus] = useState("Create Note");
  const [addNewOpen, setAddNewOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const selectedDiagnosis = thisEncounterState.diagnosisCodesSelected
    ? thisEncounterState.diagnosisCodesSelected
    : [];
  const selectedProcedures = thisEncounterState.procedureCodesSelected
    ? thisEncounterState.procedureCodesSelected
    : [];
  const [toastedMessage, setToastedMessage] = useState<ToastedList[]>([]);
  const handleAddendumModal = () => {
    setAddendumOpen(false);
    dispatch(
      getVisitsCensus({
        servicedate: moment(thisDate).format("YYYY-MM-DD"),
        // thisProvider: userData.provider,
        facilityId: currentFacility?._id,
      })
    );
  };
  interface DiagnosisSearchInterface {
    code: string;
    description: string;
    long_description: string;
    enabled: boolean;
    favourite: boolean;
    isSelected?: boolean;
  }
  interface ProcedureSearchInterface {
    code: string;
    description: string;
    long_description: string;
    enabled: boolean;
    favourite: boolean;
    isSelected?: boolean;
  }
  interface AddendumModalprops {
    isFilterOpen: boolean;
    handleFilterModal: (item: boolean) => void;
    handleFilterChange: (sort: String) => any;
    data: any;
    selectedFilterOptionList?: any;
  }

  const handleFilterChange = (text: String) => {};
  useEffect(() => {
    // getVisitChartsData(visit?._id);

    if (visit?.chart_id) {
      if (visit?.chart?.signed_on) {
        setIsSigned(true);
      } else {
        setNoteStatus("Continue Note");
        setIsSigned(false);
      }
    } else {
      setNoteStatus("Create Note");
      setIsSigned(false);
    }

    sliderCloseFun(ref);
  }, [visit]);
  const [isValidate, setIsValidate] = useState(false);

  useEffect(() => {
    if (
      thisEncounterState.dateOfService !== "" &&
      thisEncounterState.dateOfService !== null &&
      thisEncounterState.diagnosisCodesSelected.length > 0 &&
      thisEncounterState.procedureCodesSelected.length > 0
    ) {
      setIsValidate(true);
    } else {
      setIsValidate(false);
    }

    return () => {
      setIsValidate(false);
    };
  }, [thisEncounterState]);

  useEffect(() => {
    return () => {
      dispatch(resetThisState());
    };
  }, []);
  // const getVisitChartsData = async (visitId: any) => {
  //   let result = await getVisitCharts(visitId);
  //   if (result.status === "success") {
  //     if (result.data?.signed_on != undefined) {
  //       setIsSigned(true);
  //     } else {
  //       setIsSigned(false);
  //     }
  //   }
  //   // console.log("is sigens", isSigned);
  // };
  const onCheckSelect = (
    visit: VisitsByServicedate,
    index: number,
    isSelected: boolean,
    group?: string
  ) => {
    if (onSelect) {
      onSelect(visit, index, isSelected, group);
    }
  };

  const visitUpdateEvent = (visit: any) => {
    let _visit = Object.assign({}, visit);
    delete _visit.series;
    let data = {
      visit: _visit,
      room: _visit.practice_id + "_" + _visit.service_date_from,
    };

    let tmp_data = JSON.parse(JSON.stringify(data));
    // this.censusProvider.visitsObject[visit._id] = visit;
    // this.socketVisitService.emit("visit-update", tmp_data);
    socket1.emit("visit-update", tmp_data);
    dispatch(
      getVisitsCensus({
        servicedate: moment(thisDate).format("YYYY-MM-DD"),
        // thisProvider: userData.provider,
        facilityId: currentFacility?._id,
      })
    );
  };

  const quickBill = async (data: any) => {
    // let codes: any = await visit?.procedureCodes?.map((x: any) => {
    //   let res = {
    //     code: x.code,
    //     modifiers: x.modifiers ? x.modifiers : [],
    //   };
    //   return res;
    // });

    let codes: any = [];

    if (visit?.procedureCodes) {
      visit?.procedureCodes.forEach((x: any) => {
        let res = {
          code: x.code,
          modifiers: x.modifiers ? x.modifiers : [],
        };

        codes.push(res);
      });
    }

    // console.log({ codes });

    codes.splice(0, 1, { code: data.code, modifiers: data.modifiers || [] });
    // console.log({ codes });
    if (visit?.status === "Last") {
      visitClone(
        visit._id,
        "Scheduled",
        moment(selectedDate).format("YYYY-MM-DD"),
        // null,
        undefined
        // codes
      ).then((data: any) => {
        if (data.status === "success") {
          visit.clone_id = visit._id;
          visit._id = data.result._id;
          visit.status = data.result.status;
          visit.statuses = data.result.statuses;
          visit.statuses_index = data.result.statuses_index;
          visit.activities = data.result.activities;
          visit.service_lines = data.result.service_lines;
          visit.service_date_from = data.result.service_date_from;
          visit.legal_entity_id = data.result.legal_entity_id;
          visit.procedure_codes = codes;
          visit.procedureCodes?.splice(0, 1, data);
          if (codes.length > 0) {
            visit.procedure_code_primary = data;
          }
          // else {
          //   visit.procedure_code_primary = undefined;
          // }
          visit.rendering_provider_id = data.result.rendering_provider_id;
          visit.rendering_provider_name = data.result.rendering_provider_name;
          let provider = visit.rendering_provider_name
            ? visit.rendering_provider_name
            : "";
          let initials = provider
            .split(" ")
            .map(function (item: any) {
              return item[0];
            })
            .join("");
          visit.rendering_provider_initials = initials;

          // visitEventsProvider.seen += 1;
          statusIcon(visit);
          // groupCountUpdate(visit);
          visitHighlight(visit);
          visitUpdateEvent(visit); // emit visit-update event
          let toastMsg = newToast("Primary procedure changed", "Success");
          setToastedMessage([...toastedMessage, toastMsg]);
        } else {
          let toastMsg = newToast(data.message, "Danger");
          setToastedMessage([...toastedMessage, toastMsg]);
        }
      });
    } else if (visit?._id) {
      // console.log(codes);

      let cptres = await putCptChange(visit?._id, codes);
      if (cptres?.status === "success") {
        if (visit) {
          visit.activities = cptres.result.activities;
          visit.procedure_code_primary = codes;

          visitUpdateEvent(visit);
          let toastMsg = newToast("primary procedure changed", "Success");
          setToastedMessage([...toastedMessage, toastMsg]);
        }
        // visit
      } else {
        let toastMsg = newToast(data.message, "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    }
  };
  const handleAddendumModal1 = (isShow: boolean) => {
    setIsPopupOpen(isShow);
    dispatch(
      getVisitsCensus({
        servicedate: moment(thisDate).format("YYYY-MM-DD"),
        // thisProvider: userData.provider,
        facilityId: currentFacility?._id,
      })
    );
  };
  const data1 = {
    name: visit?.patient_name,
    dob: visit?.patient_dob,
    serviceDate: visit?.service_date_from,
    Facility: visit?.facility_name,
    signedBy: visit?.chart_signed_on,
    chargeid: visit?._id,
  };
  const handleModalItemClick = (data: any) => {
    // console.log({ data });
    // console.log({ visit });
    quickBill(data);

    setOpenModal(false);
    // dispatch(
    //   updateCPTVisit({
    //     patientId,
    //     data,
    //     servicedate: serviceDate ? serviceDate : "",
    //   })
    // );
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handlePId = (id: any) => {
    setPatientId(id);
  };

  const postBillVisitChanges = (data: any, visit: any) => {
    visit.status = data.status;
    visit.statuses = data.statuses;
    visit.statuses_index = data.statuses.reduce((obj: any, item: any) => {
      obj[item.key] = item.value;
      return obj;
    }, {});
    visit.statuses_index.submit_bill = true;
    visit.activities = data.activities;
    visit.rendering_provider_id = data.rendering_provider_id;
    visit.rendering_provider_name = data.rendering_provider_name;
    // updaing dos for visit on any update
    visit.service_date_from = data.service_date_from;
    visit.recallExpires = moment().add(60, "s");
    visit.estimate_amount = data.estimate_amount;
    visit.insurance_estimate_amount = data.insurance_estimate_amount;
    visit.patient_estimate_amount = data.patient_estimate_amount;
    visit.insurance_estimate_date = data.insurance_estimate_date;
    visit.patient_estimate_date = data.patient_estimate_date;
    visit.insurance = data.insurance ? data.insurance : [];
    visit.rvu = data.rvu;
    statusIcon(visit);
    // for (let currentVisit of this.visits) {
    //   if (currentVisit._id == visit._id) {
    //     currentVisit.billed = true;
    //     break;
    //   }
    // }
    //hapticFeedBack();
    visitHighlight(visit);
    visitUpdateEvent(visit);
    // this.groupVisits(this.visits, visit);
    // this.getVisitSeries(visit);
    // showToast("Submitted Successfully...");
    // present("Submitted Successfully...", 5000);
    let toastMsg = newToast(" Encounter was succesfully submitted", "Success");
    setToastedMessage([...toastedMessage, toastMsg]);
  };

  const billVisit = (visit: any, slidingItem: any, event: any) => {
    sliderCloseFun(ref);
    if (visit?.status !== "Superbill") {
      if (visit?.status === "Bill rejected") {
        channgeStatus(visit?._id).then((response) => {
          if (response?.status === 400) {
            // Set Error Message
            // showToast("Server Error");
            // present("Server Error", 5000);
            let toastMsg = newToast("server error", "Danger");
            setToastedMessage([...toastedMessage, toastMsg]);
          } else {
            if (response?.status === "success") {
              // if (response.taskDocument) {
              //   visit.taskstatus_id = response.taskDocument.taskstatus_id;
              // }
              postBillVisitChanges(response?.result, visit);
            } else {
              visitHighlight(visit);
              // showToast(
              //   "Oops... Failed to bill the visit. Please try again..."
              // );
              // present(
              //   "Oops... Failed to bill the visit. Please try again...",
              //   5000
              // );
              let toastMsg = newToast("failed to bill the visit", "Danger");
              setToastedMessage([...toastedMessage, toastMsg]);
            }
          }
        });
      } else {
        submitBillVisit(visit?._id).then((response) => {
          if (response?.status == 400) {
            // Set Error Message
            // showToast("Server Error");
            let toastMsg = newToast(" Server error", "Info");
            setToastedMessage([...toastedMessage, toastMsg]);
          } else {
            if (response?.status == "success") {
              postBillVisitChanges(response?.result, visit);
            } else {
              visitHighlight(visit);
              let toastMsg = newToast("request failed", "Danger");
              setToastedMessage([...toastedMessage, toastMsg]);
            }
          }
        });
      }
    } else {
      //hapticFeedBack();
      visitHighlight(visit);
      visit.statuses_index.submit_bill = true;
      statusIcon(visit);
      // this.billed += 1;
      // visit.recallExpires = moment().add(60, "s");
      // this.groupCountUpdate(visit);
      // showToast("Already Submitted ...");
      let toastMsg = newToast("Already submitted", "Success");
      setToastedMessage([...toastedMessage, toastMsg]);
    }
  };

  const skipVisitSocketUpdate = (visit: any) => {
    let _visit = Object.assign({}, visit);
    delete _visit.series;
    let data = {
      visit: _visit,
      room: _visit._id + "_" + _visit.service_date_from,
    };

    let tmp_data = JSON.parse(JSON.stringify(data));
    socket1.emit("visit-update", tmp_data);
    dispatch(
      getVisitsCensus({
        servicedate: moment(thisDate).format("YYYY-MM-DD"),
        // thisProvider: userData.provider,
        facilityId: currentFacility?._id,
      })
    );
  };

  const handleMoreBtnModal = (item: boolean) => {
    setIsMoreBtnModal(item);
  };

  const refreshCodes = async (visit: any) => {
    // load descriptions for all the diagnosis and procedure codes in the visit
    if (!visit.diagnosisCodes) {
      // visit.expanded &&
      visit.diagnosisCodes = [];
      let data = await getVisitDiagnosis(visit?._id);
      if (data.status == "success" && data.results.length > 0) {
        visit.diagnosisCodes = data.results;
        let toastMsg = newToast("Successfully updated", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        let toastMsg = newToast(data.message, "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    }
    if (!visit.procedureCodes) {
      // visit.expanded &&
      visit.procedureCodes = [];
      let data = await getVisitProcedures(visit?._id);
      if (data.status == "success" && data.results.length > 0) {
        visit.procedureCodes = data.results;
        let toastMsg = newToast("successfully updated", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        let toastMsg = newToast(data.message, "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    }
  };

  const visitNoteSliding = (
    visit?: VisitsByServicedate,
    slidingItem?: any,
    event?: Event
  ) => {
    sliderCloseFun(ref);
    router.push(`/progressnote/${visit?._id ? visit?._id : ""}`, "forward");

    // `/progressnote/${visitId ? visitId : ""}`,

    // if (visit.status == "Last") {
    //   let callConfirmVisitData = {
    //     status: "Not seen",
    //     service_date: moment(serviceDate).format("YYYY-MM-DD"),
    //   };
    //   let data = await callConfirmVisit(callConfirmVisitData, visit?._id);
    //   if (data.status == "success") {
    //     visit.clone_id = visit._id;
    //     visit._id = data.result._id;
    //     visit.status = data.result.status;
    //     visit.statuses = data.result.statuses;
    //     visit.statuses_index = data.result.statuses_index;
    //     visit.activities = data.result.activities;
    //     visit.service_date_from = data.result.service_date_from;
    //     visit.legal_entity_id = data.result.legal_entity_id;
    //     if (visit.series) {
    //       visit.series.push(visit);
    //       visit.series = multisort(visit.series, ["!service_date_from"]); // Sorting visit history data
    //       visit.series.forEach((el: any) => {
    //         let year_arr: any = [];
    //         let year = el.service_date_from;
    //         year = year.split("-");
    //         year = year[0];
    //         if (year != moment(new Date()).format("YYYY")) {
    //           if (year_arr.indexOf(year) > -1) {
    //             el.year = "";
    //           } else {
    //             el.year = year;
    //             year_arr.push(year);
    //           }
    //         } else {
    //           el.year = "";
    //         }
    //       });
    //     }
    //     statusIcon(visit);
    //     // this.groupVisits(this.visitEventsProvider.visits, visit);
    //     hapticFeedBack();
    //     visitHighlight(visit);
    //     visitUpdateEvent(visit);
    //     refreshCodes(visit);
    //     // this.navparams.setParams({
    //     //   visit: visit,
    //     //   codeTemplateList: this.codeTemplateList
    //     // });
    //     // this.navCtrl.navigateForward('visit-note');
    //     // showToast("Success...");
    //     present("Successfully updated the visit details..", 5000);
    //   } else {
    //     visitHighlight(visit);
    //     present(
    //       "Oops... Failed to update visit details. Please try again...",
    //       5000
    //     );
    //   }
    // } else if (visit.chart_id && !visit.chart) {
    //   refreshCodes(visit);
    //   let result = await getVisitCharts(visit?._id);
    //   if (result.status == "success") {
    //     visit.chart = result.data;
    //     let diagPlan = visit.chart.data.find((x: any) =>
    //       x.sections.some((section: any) => section.type == "diagnosisPlan")
    //     );
    //     if (diagPlan && diagPlan.sections) {
    //       let section = diagPlan.sections.find(
    //         (x: any) => x.type == "diagnosisPlan"
    //       );
    //       if (section && section.options && section.options.length > 0)
    //         visit.diagnosisCodes = section.options;
    //     }
    //     present("Successfully updated diagnosis codes...", 5000);
    //     // this.navparams.setParams({
    //     //   visit: visit,
    //     //   codeTemplateList: this.codeTemplateList
    //     // });
    //     // this.navCtrl.navigateForward('visit-note');
    //   } else {
    //     present(
    //       "Oops... Failed to update diagnosis codes. Please try again...",
    //       5000
    //     );
    //   }
    // } else {
    //   refreshCodes(visit);
    //   // this.navparams.setParams({
    //   //   visit: visit,
    //   //   codeTemplateList: this.codeTemplateList
    //   // });
    //   // this.navCtrl.navigateForward('visit-note');
    // }
  };

  const isAction = (visitstatus: any, action: any) => {
    if (
      (action == "Bill" || action == "Bill SALT") &&
      moment(serviceDate) > moment()
    ) {
      return false;
    } else {
      let config: any = visitStatuses;
      let available_Actions = [];
      if (config[visitstatus] && config[visitstatus].actions) {
        available_Actions = config[visitstatus].actions;
      }
      if (available_Actions.indexOf(action) > -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  const moreBtnModalHandle = (item: any) => {
    handleMoreBtnModal(item);
    sliderCloseFun(ref);
  };

  const cptModalHandle = () => {
    setOpenModal(true);
    sliderCloseFun(ref);
  };

  const addendumHandler = (item: any) => {
    setIsPopupOpen(item);
    sliderCloseFun(ref);
  };

  const sliderCloseFun = (ref: any) => {
    // To close the slider
    if (ref.current != null) {
      ref.current.close();
    }
  };
  const addDiagnosisData = async (item: any) => {
    let data = [];
    // console.log(visit?.diagnosis_codes, item);
    data = visit?.diagnosis_codes || [];
    let datas: any = [];
    item?.forEach((itm: any) => {
      datas.push(itm?.code);
    });

    // console.log(data);
    try {
      let res = await putDiagnosiscodes(visit?._id, {
        diagnosis_codes: [...new Set(data.concat(datas))],
      });
      if (res?.status == "success") {
        setAddNewOpen(false);

        // visit.statuses_index.submit_bill = false;
        let toastMsg = newToast("successfully submitted", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
        // this.statusIcon(visit);
        // this.getVisitSeries(visit);
        // this.billed -= 1;
        // delete visit.recallExpires;
        // for (let currentVisit of this.visits) {
        //   if (currentVisit._id == visit._id) {
        //     currentVisit.billed = false;
        //     break
        //   }
        // }
        // this.groupVisits(this.visits, visit);
        visitUpdateEvent(visit); // emit visit-update event
        // this.hapticFeedBack();
        // this.visitHighlight(visit);
      } else {
        let toastMsg = newToast(res.message, "Error");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
      return visit;
    } catch (e) {
      console.log(e, "eeeeeeeeeeeeee");
    }
    // console.log(visit, item);
    // onSetCode(item);
  };
  const addProcedureData = (item: any) => {
    // onSetCode(item);
    setIsAddOpen(false);
  };

  const statusType = visit.status;

  const confirmVisit = (
    visit_item: any,
    slidingItem: any,
    typeCall = "",
    groupindex: any,
    e: any
  ) => {
    sliderCloseFun(ref);
    let visit = JSON.parse(JSON.stringify(visit_item));
    // need to implement
    //visit.status='Admit';
    /* if (slidingItem && typeCall == '') {
      e.stopPropagation();
      slidingItem.close();
    } */
    if (visit.status == "Last") {
      visitClone(
        visit._id,
        "Checked In",
        moment(selectedDate).format("YYYY-MM-DD")
      ).then((data: any) => {
        if (data.status == "success") {
          visit.clone_id = visit._id;
          visit._id = data.result._id;
          visit.rendering_provider_id = data.result.rendering_provider_id;
          visit.rendering_provider_name = data.result.rendering_provider_name;
          visit.status = data.result.status;
          visit.statuses = data.result.statuses;
          visit.statuses_index = data.result.statuses_index;
          visit.activities = data.result.activities;
          //statusIcon(visit);
          // this.seen += 1;
          // this.scheduled += 1;
          // updaing dos for visit on any update
          visit.service_date_from = data.result.service_date_from;
          visit.legal_entity_id = data.result.legal_entity_id;
          if (visit.series) {
            visit.series.push(visit);
            visit.series = multisort(visit.series, ["!service_date_from"]); // Sorting visit history data
          }
          // if (this.groupedVisits[groupindex]) {
          //   this.groupedVisits[groupindex].scheduled += 1;
          // }
          //groupVisits(visit, visit);
          //hapticFeedBack();
          //visitHighlight(visit);
          visitUpdateEvent(visit);
          // this.progressMethod();
          // present("Success..", 5000);
          let toastMsg = newToast("Visit updated successfully", "Success");
          setToastedMessage([...toastedMessage, toastMsg]);
        } else {
          //visitHighlight(visit);
          let toastMsg = newToast(data.message, "Error");
          setToastedMessage([...toastedMessage, toastMsg]);
        }
      });
    } else if (
      visit.status == "Scheduled" ||
      visit.status == "Not seen" ||
      visit.status == "Admit"
    ) {
      visitStatus(visit._id, "Checked In").then((data: any) => {
        if (data.status == "success" && data.result._id == visit._id) {
          // if (visit.status == 'Not seen') {
          //   this.seen += 1;
          //   this.scheduled += 1;
          // }
          visit.status = data.result.status;
          visit.statuses = data.result.statuses;
          visit.statuses_index = data.result.statuses_index;
          visit.activities = data.result.activities;
          // this.seen += 1;
          // this.scheduled += 1;
          //visit = statusIcon(visit);
          // if (this.groupedVisits[groupindex]) {
          //   this.groupedVisits[groupindex].scheduled += 1;
          // }
          //groupVisits(visit, visit);
          // hapticFeedBack();
          //visitHighlight(visit);
          visitUpdateEvent(visit);
          // present("Success..", 5000);
          let toastMsg = newToast("Visit updated successfully", "Success");
          setToastedMessage([...toastedMessage, toastMsg]);
        } else {
          // visitHighlight(visit);
          let toastMsg = newToast(data.message, "Error");
          setToastedMessage([...toastedMessage, toastMsg]);
        }
      });
    } else {
      let toastMsg = newToast("Failed to update", "Error");
      setToastedMessage([...toastedMessage, toastMsg]);
    }
  };

  const billRecall = (visit_item?: any) => {
    sliderCloseFun(ref);
    let visit = JSON.parse(JSON.stringify(visit_item));
    if (visit?.status == "Claim assigned") {
      setFirstPopupISOpen(true);
    } else {
      const getrecallSubmissionWithoutMsg = async () => {
        try {
          let res = await recallSubmission(visit?._id);
          if (res?.status == "success") {
            if (visit.status == "Claim assigned") {
              visit.status = "Void"; // This is wrong ???
            } else {
              visit.status = "Checked In";
            }
            visit.statuses_index.submit_bill = false;
            let toastMsg = newToast("successfully submitted", "Success");
            setToastedMessage([...toastedMessage, toastMsg]);
            // this.statusIcon(visit);
            // this.getVisitSeries(visit);
            // this.billed -= 1;
            // delete visit.recallExpires;
            // for (let currentVisit of this.visits) {
            //   if (currentVisit._id == visit._id) {
            //     currentVisit.billed = false;
            //     break
            //   }
            // }
            // this.groupVisits(this.visits, visit);
            visitUpdateEvent(visit); // emit visit-update event
            // this.hapticFeedBack();
            // this.visitHighlight(visit);
          } else {
            let toastMsg = newToast(res.message, "Error");
            setToastedMessage([...toastedMessage, toastMsg]);
          }
          return visit;
        } catch (e) {
          console.log(e, "eeeeeeeeeeeeee");
        }
      };
      getrecallSubmissionWithoutMsg();
    }
  };

  const stopVisitTracking = async (
    visit_item?: any,
    slidingItem?: any,
    event?: Event,
    group?: any,
    groupindex?: any
  ) => {
    sliderCloseFun(ref);
    let visit = JSON.parse(JSON.stringify(visit_item));
    if (visit?.status == "Last") {
      let callConfirmVisitData = {
        status: "Not seen",
        service_date: moment(serviceDate).format("YYYY-MM-DD"),
        last_visit: true,
      };
      let data = await callConfirmVisit(visit?._id, callConfirmVisitData);
      if (data.status === "success") {
        visit.clone_id = visit._id;
        visit._id = data.result._id;
        visit.status = data.result.status;
        visit.statuses = data.result.statuses;
        visit.statuses_index = data.result.statuses_index;
        visit.activities = data.result.activities;
        visit.statuses_index.last_visit = true;
        // this.statusIcon(visit);
        // // this.scheduled = this.scheduled - 1;
        // // if (this.groupedVisits[groupindex])
        // //   this.groupedVisits[groupindex].scheduled -= 1;
        // // updaing dos for visit on any update
        visit.service_date_from = data.result.service_date_from;
        visit.legal_entity_id = data.result.legal_entity_id;
        if (visit.series) {
          visit.series.push(visit);
          visit.series = multisort(visit.series, ["!service_date_from"]); // Sorting visit history data
        }
        // this.groupVisits(this.visits, visit);
        // this.hapticFeedBack();
        // this.visitHighlight(visit);
        // this.visitUpdateEvent(visit);
        visitUpdateEvent(visit);
        // present("Success..", 5000);
        let toastMsg = newToast("Success", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        // this.visitHighlight(visit);
        // this.showToast('Oops... Failed to update status. Please try again...');
        // present("Oops... Failed to update status. Please try again...", 5000);
        let toastMsg = newToast(data.message, "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    } else if (
      visit?.status == "Scheduled" ||
      visit?.status == "Checked In" ||
      visit?.status == "Admit"
    ) {
      let skipVisitData = {
        status: "Not seen",
        last_visit: true,
      };
      let data = await skipVisit(visit._id, skipVisitData);
      if (data.status == "success" && data.result._id == visit._id) {
        visit.status = data.result.status;
        visit.statuses = data.result.statuses;
        // visit.statuses_index.last_visit = true; // data.result.statuses_index;
        // this.statusIcon(visit);
        // visit.statuses_index.last_visit = true;
        // this.groupVisits(this.visits, visit);
        // this.hapticFeedBack();
        // this.visitHighlight(visit);
        // this.visitUpdateEvent(visit);
        visitUpdateEvent(visit);
        // present("Success..", 5000);
        let toastMsg = newToast("Success", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        // this.visitHighlight(visit);
        // this.showToast('Oops... Failed to update status. Please try again...');
        // present("Oops... Failed to update status. Please try again...", 5000);
        let toastMsg = newToast(data.message, "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    } else {
      let dischargeVisitData = {
        key: "last_visit",
        value: true,
      };
      let data = await dischargeVisit(dischargeVisitData, visit?._id);
      if (data.status == "success") {
        visit.statuses_index.last_visit = true;
        // this.groupVisits(this.visits, visit);
        // this.hapticFeedBack();
        // this.visitHighlight(visit);
        // this.visitUpdateEvent(visit);
        visitUpdateEvent(visit);
        // present("Success...", 5000);
        let toastMsg = newToast("Success", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        // this.visitHighlight(visit);
        // present("Oops... Failed to update status. Please try again...", 5000);
        let toastMsg = newToast(data.message, "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
        //console.log("Oops... Failed to update status. Please try again...");
      }
    }
  };
  const billSalt = (
    visit_item: any,
    slidingItem: any,
    event: any,
    last_visit?: boolean
  ) => {
    sliderCloseFun(ref);
    let visit = JSON.parse(JSON.stringify(visit_item));
    if (visit.status == "Last") {
      // Disable SALT for initial diagnosis visit E&M codes -- this should be ultimately DB config driven
      let exclude = ["99218", "99219", "99220", "99221", "99222", "99223"]; //for testing purpose
      //       let exclude = initialProcedures;
      if (exclude.indexOf(visit.procedure_code_primary.code) > -1) {
        //Warning toast added temporarily
        let toastMsg = newToast("Billing code need to change", "Warning");
        setToastedMessage([...toastedMessage, toastMsg]);
        return visitProcedureMenu(visit, null, undefined, "");
      }

      visitClone(
        visit._id,
        "Checked In",
        moment(selectedDate).format("YYYY-MM-DD")
      ).then((data: any) => {
        if (data.status == "success") {
          visit.clone_id = visit._id;
          visit._id = data.result._id;
          visit.status = data.result.status;
          visit.statuses = data.result.statuses;
          visit.statuses_index = data.result.statuses_index;
          visit.activities = data.result.activities;
          // this.visitEventsProvider.seen += 1;
          // this.visitEventsProvider.scheduled += 1;
          // updaing dos for visit on any update
          visit.service_date_from = data.result.service_date_from;
          if (visit.series) {
            visit.series.push(visit);
            // visit.series = multisort(visit.series, ['!service_date_from']); // Sorting visit history data
            visit.series.forEach((el: any) => {
              let year_arr: any[] = [];
              let year = el.service_date_from;
              year = year.split("-");
              year = year[0];
              if (year != moment(new Date()).format("YYYY")) {
                if (year_arr.indexOf(year) > -1) {
                  el.year = "";
                } else {
                  el.year = year;
                  year_arr.push(year);
                }
              } else {
                el.year = "";
              }
            });
          }
          //   this.visitEventsProvider.statusIcon(visit);
          submitBillVisit(visit?._id).then((data) => {
            if (data.status == "success") {
              //   this.hapticFeedBack();
              //   this.visitHighlight(visit);
              visit.status = "Superbill";
              visit.statuses_index.submit_bill = true;

              visit.status = data.result.status;
              visit.statuses = data.result.statuses;
              visit.statuses_index = data.result.statuses.reduce(
                (obj: any, item: any) => {
                  obj[item.key] = item.value;
                  return obj;
                },
                {}
              );
              visit.activities = data.result.activities;

              visit.rendering_provider_id = data.result.rendering_provider_id;
              visit.rendering_provider_name =
                data.result.rendering_provider_name;
              // let provider = (visit.rendering_provider_name ? visit.rendering_provider_name : '');
              // let initials = provider.split(' ').map(function (item) { return item[0] }).join('');
              // visit.rendering_provider_initials = initials;
              // this.visitEventsProvider.billed += 1; // comment need to recheck
              //   this.groupCountUpdate(visit);

              visit.recallExpires = moment().add(60, "s");

              visit.estimate_amount = data.result.estimate_amount;
              visit.insurance_estimate_amount =
                data.result.insurance_estimate_amount;
              visit.patient_estimate_amount =
                data.result.patient_estimate_amount;
              visit.insurance_estimate_date =
                data.result.insurance_estimate_date;
              visit.patient_estimate_date = data.result.patient_estimate_date;
              visit.insurance = data.result.insurance
                ? data.result.insurance
                : [];

              visit.rvu = data.result.rvu;
              updateVisit(visit);
              processVisits();
              //   this.visitEventsProvider.statusIcon(visit);
              //   this.groupCountUpdate(visit);
              // this.visitEventsProvider.groupVisits(this.visits, visit);
              //   this.getVisitSeries(visit); // why are we loading the series again?
              visitUpdateEvent(visit);
              let toastMsg = newToast("Submitted Successfully", "Success");
              setToastedMessage([...toastedMessage, toastMsg]);

              // emit visit-update event
              //   if (!slidingItem && this.navCtrl.back.name == 'VisitDetailsPage') {
              //     this.navCtrl.pop();
              //   }
            } else {
              visitHighlight(visit);
              let toastMsg = newToast(data.message, "Error");
              setToastedMessage([...toastedMessage, toastMsg]);
            }
          });
        } else {
          visitHighlight(visit);
          let toastMsg = newToast("Failed", "Error");
          setToastedMessage([...toastedMessage, toastMsg]);
        }
      });
    } else {
      visitHighlight(visit);
      let toastMsg = newToast("failed to update status", "Error");
      setToastedMessage([...toastedMessage, toastMsg]);
    }
  };

  const undoSkipVisitHandler = async (visit_item?: any) => {
    handleMoreBtnModal(false);
    let visit = JSON.parse(JSON.stringify(visit_item));
    if (visit?.status == "Not seen") {
      let body = {
        status: "Scheduled",
        // ,
        // last_visit: visit.statuses_index.last_visit,
        // description: visit.diagnosis_code_primary.description,
      };

      let res = await undoSkipVisit(body, visit?._id);
      if (res.status === "success") {
        dispatch(
          getVisitsCensus({
            servicedate: moment(thisDate).format("YYYY-MM-DD"),
            // thisProvider: userData.provider,
            facilityId: currentFacility?._id,
          })
        );

        // present("Success...", 5000);
        let toastMsg = newToast("Success", "Success");
        setToastedMessage([...toastedMessage, toastMsg]);
      } else {
        // present("Oops... Failed to update. Please try again...", 5000);
        let toastMsg = newToast(res.message, "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      }
    }
  };
  const handleSkipVisit = async (
    visit_item?: any,
    slidingItem?: any,
    event?: Event,
    typeCall: any = "",
    groupindex?: any
  ) => {
    let visit = JSON.parse(JSON.stringify(visit_item));
    sliderCloseFun(ref);

    if (isAction(visit?.status, "Skip")) {
      if (visit?.status === "Last") {
        let callConfirmVisitData = {
          status: "Not seen",
          service_date: moment(serviceDate).format("YYYY-MM-DD"),
        };

        let data = await callConfirmVisit(visit?._id, callConfirmVisitData);
        if (data.status === "success") {
          dispatch(
            getVisitsCensus({
              servicedate: moment(thisDate).format("YYYY-MM-DD"),
              // thisProvider: userData.provider,
              facilityId: currentFacility?._id,
            })
          );
          let toastMsg = newToast("Success", "Success");
          setToastedMessage([...toastedMessage, toastMsg]);
          // visit.clone_id = visit._id;
          // visit._id = data.result._id;
          // visit.rendering_provider_id = data.result.rendering_provider_id;
          // visit.rendering_provider_name = data.result.rendering_provider_name;
          // visit.status = data.result.status;
          // visit.statuses = data.result.statuses;
          // visit.statuses_index = data.result.statuses_index;
          // visit.activities = data.result.activities;
          // this.statusIcon(visit);
          // visit.service_date_from = data.result.service_date_from;
          // visit.legal_entity_id = data.result.legal_entity_id;
          // if (visit.series) {
          //   visit.series.push(visit);
          //   visit.series = multisort(visit.series, ['!service_date_from']); // Sorting visit history data
          // }
          // this.groupVisits(this.visits, visit);
          // this.hapticFeedBack();
          // this.visitUpdateEvent(visit);
          // this.visitHighlight(visit);
        } else {
          let toastMsg = newToast(data.message, "Error");
          setToastedMessage([...toastedMessage, toastMsg]);
        }
      } else {
        let skipVisitData = {
          status: "Not seen",
        };
        let data = await skipVisit(visit?._id, skipVisitData);
        if (data.status === "success" && data.result._id === visit?._id) {
          dispatch(
            getVisitsCensus({
              servicedate: moment(thisDate).format("YYYY-MM-DD"),
              // thisProvider: userData.provider,
              facilityId: currentFacility?._id,
            })
          );

          // visit.status = data.result.status;
          // visit.statuses = data.result.statuses;
          // visit.statuses_index = data.result.statuses_index;
          // visit = this.statusIcon(visit);
          // this.groupVisits(this.visits, visit);
          // this.hapticFeedBack();
          // this.visitUpdateEvent(visit);
          // this.visitHighlight(visit);
          let toastMsg = newToast("Success", "Success");
          setToastedMessage([...toastedMessage, toastMsg]);
        } else {
          let toastMsg = newToast(data.message, "Error");
          setToastedMessage([...toastedMessage, toastMsg]);
        }
      }
    } else {
      let toastMsg = newToast("This action can't be completed", "Error");
      setToastedMessage([...toastedMessage, toastMsg]);
    }
  };

  return (
    <div className="slide-item-holder">
      <DiagnosisCode
        isOpen={addNewOpen}
        setIsOpen={setAddNewOpen}
        numberPermited={12 - selectedDiagnosis.length}
        addDiagnosis={addDiagnosisData}
        selectedDiagnosis={selectedDiagnosis}
        dxClick={dxClick}
      />
      <ProcedureCode
        isOpen={isAddOpen}
        setIsOpen={setIsAddOpen}
        selectedCodes={selectedProcedures}
        //onSelectCode={onSetCode}
        addProcedure={addProcedureData}
        cptClick={cptClick}
      />

      <IonItemSliding
        disabled={slectionMode}
        ref={(element: any) => {
          ref.current = element;
        }}
      >
        <IonItemOptions className="item-option" side="start">
          <IonItemOption
            className="start"
            onClick={() => moreBtnModalHandle(true)}
          >
            More
          </IonItemOption>
          <IonItemOption
            className="start cpt"
            onClick={() => {
              setIsAddOpen(true);
              setCptClick(true);
            }}
          >
            Change <br />
            CPT
          </IonItemOption>
          <IonItemOption
            className="start dx"
            // onClick={() => setAddNewOpen(true)
            // }
            onClick={() => {
              setAddNewOpen(true);
              setDxClick(true);
            }}
            // onClick={() => cptModalHandle()}
          >
            Change <br /> Dx
          </IonItemOption>
          <FeatureProvider
            children={
              isSigned ? (
                <IonItemOption
                  className="next-btn"
                  onClick={() => {
                    addendumHandler(true);
                  }}
                >
                  Add <br />
                  addendum
                </IonItemOption>
              ) : (
                <></>
              )
            }
            feature={"VisitNotes POC"}
          />
        </IonItemOptions>
        <IonItem className="census-card-swap ion-no-padding swapcard">
          <div className="patient-card">
            <div className="column-one">
              <div className="column-one-sub">
                <div>
                  <CheckBox
                    value={visit?._id}
                    onCheck={(data) =>
                      onCheckSelect(
                        visit,
                        index ? index : 0,
                        data,
                        visitStatuses[visit?.status].group
                      )
                    }
                    checked={visit?.isSelected}
                    strictMode
                  />
                </div>
                <div className="column-onw-sub-two">
                  <div>
                    <span className="name-bold">
                      {visit?.patient_lastname},{" "}
                    </span>
                    <span className="name-regular">
                      {visit?.patient_firstname}
                    </span>
                    <span className="division-line"> | </span>
                    <span className="status">
                      {" "}
                      {visit?.room ? `# ${visit?.room} ` : ""}
                    </span>
                  </div>

                  <div>
                    <div>
                      <span className="location">
                        {visit?.place_of_service} |
                      </span>
                      <span className="diagnosis-code">
                        {visit?.diagnosis_code_primary?.code} -{" "}
                      </span>
                      <span className="diagnosis-description">
                        {visit?.diagnosis_code_primary?.description}
                      </span>
                    </div>
                    <div>
                      {(visit?.status === "Bill rejected" ||
                        visit?.status === "Collections review" ||
                        visit?.status === "Claim provider hold") && (
                        <div className="missing-message">
                          <span>
                            <IonIcon
                              className="warning-icon"
                              icon={alertCircle}
                            ></IonIcon>
                          </span>
                          <span> Data is not valid or missing.</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="column-two">
              {cardtype === 7 && (
                <div className="status-with-bg-green">COMPLETED</div>
              )}
              {cardtype === 6 && (
                <div className="status-with-bg-yellow">IN PROGRESS</div>
              )}
              {/* {(visit?.status === "Bill rejected" ||
                visit?.status === "Collections review" ||
                visit?.status === "Claim provider hold") && (
                <div className="status-with-bg-red">PROVIDERHOLD</div>
              )} */}
              <div className="code">99233 </div>
              {visit?.admission_date && (
                <div className="admission">
                  {moment(visit?.admission_date).format("MM-DD-YYYY")}
                </div>
              )}
            </div>
          </div>
        </IonItem>
        <IonItemOptions className="item-option" side="end">
          <FeatureProvider
            children={
              isAction(visit?.status, "Visit note") && (
                <>
                  {isSigned ? (
                    <SignedNotes
                      visit={visit}
                      // onSlideClose={onSlideClose}
                    />
                  ) : (
                    <IonItemOption
                      className="end"
                      onClick={() => visitNoteSliding(visit)}
                    >
                      {noteStatus}
                    </IonItemOption>
                  )}
                </>
              )
            }
            feature={"VisitNotes POC"}
          />

          {isAction(visit?.status, "Change CPT") &&
            !isNoteRequired &&
            visit?.procedure_code_primary &&
            visit?.procedure_code_primary?.code &&
            moment(visit?.service_date_from).isBefore(new Date()) && (
              <IonItemOption
                className="end normal"
                onClick={() => cptModalHandle()}
              >
                Change CPT
              </IonItemOption>
            )}
          {/* {isAction(visit?.status, "Skip") && (
            <IonItemOption className="end" onClick={skipVisit}>
              Skip visit
            </IonItemOption>
          )} */}

          {isAction(visit?.status, "Bill") &&
            !isNoteRequired &&
            visit?.procedure_code_primary &&
            visit?.procedure_code_primary?.code &&
            moment(visit?.service_date_from).isBefore(new Date()) && (
              <IonItemOption
                className="end"
                onClick={(e) => billVisit(visit, IonItemSliding, e)}
              >
                Sign and bill
              </IonItemOption>
            )}

          <IonItemOption
            className="end normal"
            onClick={() => {
              setAddNewOpen(true);
              setDxClick(true);
            }}
          >
            Add Dx
          </IonItemOption>
          <IonItemOption
            className="end"
            onClick={() => {
              setIsAddOpen(true);
              setCptClick(true);
            }}
          >
            Add CPT
          </IonItemOption>
          {isAction(visit?.status, "Confirm") && (
            <IonItemOption
              className="end"
              onClick={(e) => confirmVisit(visit, IonItemSliding, "", "", e)}
            >
              My census
            </IonItemOption>
          )}
          {isAction(visit?.status, "Recall bill") &&
            !visit?.statuses_index?.recalled && (
              <IonItemOption className="end" onClick={() => billRecall(visit)}>
                Recall <br /> submission
              </IonItemOption>
            )}

          {isAction(visit?.status, "Discharge") &&
            !visit?.statuses_index?.last_visit &&
            moment(visit?.service_date_from) <= moment() && (
              <IonItemOption
                className="end normal"
                onClick={() => stopVisitTracking(visit)}
              >
                <div className="button-text">Discharge</div>
              </IonItemOption>
            )}
          {isAction(visit?.status, "Bill SALT") &&
            !isNoteRequired &&
            !visit?.statuses_index?.recalled &&
            visit?.procedure_code_primary &&
            visit?.procedure_code_primary?.code &&
            moment(visit?.service_date_from).isBefore(new Date()) && (
              <IonItemOption
                className="end"
                onClick={(e) => billSalt(visit, IonItemSliding, e)}
              >
                Bill salt
              </IonItemOption>
            )}

          {visit?.status == "Not seen" && (
            <IonItemOption
              className="end"
              onClick={() => undoSkipVisitHandler(visit)}
            >
              Undo skip
            </IonItemOption>
          )}
          {isAction(visit?.status, "Skip") && (
            <IonItemOption
              className="end"
              onClick={() => handleSkipVisit(visit)}
            >
              Skip visit
            </IonItemOption>
          )}
          {/* {visit?.status === "Bill submitted" &&
            <IonItemOption>
            <FeatureProvider
            children={
                <>
                    <SignedNotes visit={visit} onSlideClose={onSlideClose} />
                </>
            }
            feature={"Coding templates"}
          />
            </IonItemOption>
          } */}
        </IonItemOptions>
      </IonItemSliding>

      {/* <CptModal
        visit={visit}
        openModal={openModal}
        closeModal={handleCloseModal}
        handleClick={handleModalItemClick}
      /> */}

      <ChangeCPTModal
        setIsOpen={setOpenModal}
        isOpen={openModal}
        cptClick={handleModalItemClick}
      />

      <MoreBtnModal
        visit={visit}
        isMoreModal={isMoreBtnModal}
        handleMoreBtnModal={handleMoreBtnModal}
      />

      <RecallBtnFirstPopup
        firstPopupISOpen={firstPopupISOpen}
        setFirstPopupISOpen={setFirstPopupISOpen}
        setSecondPopupISOpen={setSecondPopupISOpen}
      />

      <RecallBtnSecondPopup
        visit={visit}
        secondPopupISOpen={secondPopupISOpen}
        setSecondPopupISOpen={setSecondPopupISOpen}
      />
      <AddendumPopup
        addendumOpen={addendumOpen}
        closeModal={handleAddendumModal}
      />
      <AddendumModal
        handleFilterModal={handleAddendumModal1}
        isFilterOpen={isPopupOpen}
        handleFilterChange={handleFilterChange}
        data1={data1}
      />
      <Toast
        toastList={toastedMessage}
        position="top-right"
        autoDelete={true}
        autoDeleteTime={6000}
      />
    </div>
  );
};

export default SwapCard;
