import { IonIcon, IonLabel, IonModal } from "@ionic/react";
import React, { useState } from "react";
import "./DateOfService.scss";
import { close } from "ionicons/icons";
import DateBox from "./DateBox";
import Button from "../Buttons/Buttons";
import { pencilOutline } from "ionicons/icons";

interface dateProps {
  pastDateTrue?: boolean;
  futureDateTrue?: boolean;
}
const DateOfService: React.FC<dateProps> = ({
  children,
  pastDateTrue,
  futureDateTrue,
}) => {
  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };
  return (
    <div className="date-of-service">
      <IonIcon
        onClick={() => setOpen(true)}
        icon={pencilOutline}
        size="sm"
      ></IonIcon>

      {/* <IonButton onClick={() => setOpen(true)}>
          Open Date Of Service
        </IonButton> */}
      <IonModal
        className="dateModal"
        isOpen={open}
        onDidDismiss={closeModal}
        breakpoints={[0, 0.2, 0.5, 1]}
        initialBreakpoint={0.5}
        backdropBreakpoint={0.2}
      >
        <div>
          <div className="header-holder">
            <div className="title-holder">
              <IonLabel className="labelTitle">Date of Service</IonLabel>
            </div>
            <div className="btn-holder">
              <IonIcon
                icon={close}
                className="ion-icon"
                onClick={() => setOpen(false)}
              ></IonIcon>
            </div>
          </div>
          <div>
            <DateBox
              pastDateTrue={pastDateTrue}
              futureDateTrue={futureDateTrue}
            />
          </div>

          <Button fullWidth variant="compressed">
            Save visit time
          </Button>
        </div>
      </IonModal>
    </div>
  );
};
export default DateOfService;
