import { AddPatientsPayload } from "../types/AddPatientsPayload";
import { GetFavDiagnosisArg, GetFavProceduresArg } from "../types/AddVisitType";
import { DailysummaryApiArg } from "../types/DailysummaryType";
import {
  BillVisitType,
  DischargeVisitType,
  ForgotpwdOtp,
  loginDataPayload,
  mfaOTPSendPayload,
  mfaOTPVerifyPayload,
  PatientNameType,
  PatientSearchType,
  PatientSearchTypeID,
  ProviderLoginData,
  VerifyUsernameType,
  VisitDetailsSearchType,
  providerHoldType,
  PutProgressNoteDiagnosiscodesPayload,
  PutProgressNoteProceduresPayload,
} from "../types/Types";
import createCancelTokenHandler from "./apiHelper/axiosUtils";
import {
  getCollector,
  postCollector,
  postCollectorBulk,
  postSkipInterceptorCollector,
  putCollector,
} from "./apiHelper/collectorApi";
import {
  getSettings,
  delSettings,
  putSettingsForSingleParam,
  postSettingsForSingleParam,
  putSettings,
} from "./apiHelper/settingsApi";
import {
  getSocket,
  postParticipantSocket,
  postSocket,
  putSocket,
} from "./apiHelper/socketApi";
// import { postSettings } from "./apiHelper/settingsApi";
// Login
export const login = (data: loginDataPayload) =>
  postCollector("authenticate/v3", data);
// Forgot Password
export const forgotpwd = (data: VerifyUsernameType) =>
  postCollector("resetpassword/sendOTP", data);
//Verification Code
export const verifyCode = (data: ForgotpwdOtp) =>
  postCollector("resetpassword/validateOTP", data);
//MFA otp mobile
export const mfaOTPSend = (data: mfaOTPSendPayload) =>
  postCollector("mfa/otp", data);
//MFA otp email
export const mfaMailOTPSend = (data: mfaOTPSendPayload) =>
  postCollector("mfa/mailOtp", data);
//Verification OTP
export const mfaOTPVerify = (data: mfaOTPVerifyPayload) =>
  postCollector("verify/otp", data);
//update Password
export const updatePassword = (data: any) =>
  postCollector("resetpassword/updatePassword", data);
//upload picture
export const uploadPicture = (data: any) =>
  postCollector("/dailycensus/image", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const uploadFiles = (data: any) =>
  postSkipInterceptorCollector("/dailycensus/image", data);
//BiLL Visit
export const submitBillVisit = (
  charge_id: string,
  last_visit?: boolean,
  legal_entity_id?: string
) => {
  let data: any = {};
  if (last_visit) {
    data["last_visit"] = last_visit;
  }
  if (legal_entity_id) {
    data["legal_entity_id"] = legal_entity_id;
  }
  return postCollector(`dailycensus/visits/${charge_id}/submitbill`, data);
};
//BiLL Visit
export const skipBillVisit = (data: BillVisitType) =>
  postCollector(`dailycensus/visits/${data._id}/visitstatus`, data);
// Change Status
export const channgeStatus = (visit_id?: string) =>
  postCollector(`dailycensus/visits/${visit_id}/resolve`, {});
//Recall Submission
export const recallSubmission = (id?: string, messageText?: any[]) =>
  postCollector(`dailycensus/visits/${id}/recallbill`, {
    messageText: messageText ? messageText : {},
  });

export const getPractice = (config: any = {}) =>
  getSettings(
    "currentuser/practices?enabled=true&pagesize=10000000000",
    config
  );

export const getProviders = (config: any = {}) =>
  getSettings("currentuser/providers", config);

//getFacilities
export const getFacilities = (config: any = {}) =>
  getSettings("currentprovider/facilities?enabled=true", config);

//getReason - Message module
export const getReason = (id: any = {}) =>
  getSettings(`settings/taskstatus/${id}/reasons`);

export const getProgressNoteImage = (charge_id: string) =>
  getCollector(`/charges/${charge_id}/chart/image?`);

export const getChartText = (charge_id: string) =>
  getCollector(`/charges/${charge_id}/chart/text`);

export const addendumText = (charge_id: any, data: {}) =>
  postCollector(`/charges/${charge_id}/chart/addendums`, data);

//Get uploadede documents list
export const getDocuments = (charge_id: string) =>
  getCollector(`/dailycensus/visits/${charge_id}/files?series=true`);

//Get documents thumbnail
export const getDocThumbnails = (file_name: string) =>
  getCollector(`/dailycensus/files/image/${file_name}?thumbnail=true`);

//Provider Login
export const providerlogin = (data: ProviderLoginData) =>
  postCollector("providerlogin", data);

export const currentProvider = () => getSettings("currentprovider");
export const currentPractice = () => getSettings("currentpractice");
export const currentSettings = () => getSettings("currentuser/settings");
export const currentRoles = () => getSettings("currentuser/roles");
export const currentPermissions = () => getSettings("currentuser/permissions");
export const currentFeatures = () => getSettings("currentuser/features");

export const getCodeTemplates = () =>
  getSettings("currentpractice/codetemplates");

export const getFavProcedures = (data?: GetFavProceduresArg) =>
  getSettings(
    `currentprovider/favprocedures?q=${
      data?.q ? data?.q : ""
    }&place_of_service=${data?.place_of_service ? data?.place_of_service : ""}${
      data?.template ? `&template=${data?.template}` : ""
    }`
  );

export const getFavDiagnosis = (data?: GetFavDiagnosisArg) =>
  getSettings(
    `currentprovider/favdiagnosis?q=${data?.q ? data?.q : ""}${
      data?.template ? `&template=${data?.template}` : ""
    }`
  );

export const listContactList = () => {
  return getSettings("currentpractice/contactlist");
};

export const getPatientsList = (data: PatientNameType) => {
  return getCollector(
    `dailycensus/patients?&lastname=${data.lastName}&firstname=${data.firstName}`,
    {
      cancelToken:
        patientCancel.getPatientsList.handleRequestCancellation().token,
    }
  );
};

export const getPatientPayment = (data: VisitDetailsSearchType) => {
  return getCollector(`dailycensus/visits/${data.id}`);
};

export const getProviderHold = (data: providerHoldType) => {
  //return getCollector(`dailycensus/providerhold`);
  return getCollector(
    `v2/providerHold?startIndex=${data.startIndex}&recordCount=${data.recordCount}&scope=${data.scope}`
  );
};

export const getProgressNoteTemplates = () => {
  return getSettings(`currentpractice/medicalcharttemplates?&enabled=true`);
};

export const getFacilityListData = () => {
  return getSettings(`/currentprovider/facilities?enabled=any`);
};

let patientCancel = createCancelTokenHandler({ getPatientsList });

export const getPatientsSearch = (data: PatientSearchType) =>
  getCollector(`dailycensus/patients?&q=${data.name}`, {
    cancelToken:
      patientSearchCancel.getPatientsSearch.handleRequestCancellation().token,
  });

let patientSearchCancel = createCancelTokenHandler({ getPatientsSearch });

export const getPatientsSearchByID = (data: PatientSearchTypeID) =>
  getCollector(`dailycensus/visits/${data.id}`);

export const getPatientsVisitDetailsID = (id: any) =>
  getCollector(`dailycensus/visits/${id}`);

export const getDiagnosisSearch = (data: string) =>
  getSettings(`currentprovider/diagnosis?q=${data}`, {
    cancelToken:
      diagnosisCancel.getDiagnosisSearch.handleRequestCancellation().token,
  });

export const getDiagnosisSortLimit = (data: {
  search: string;
  startIndex: number;
  count: number;
}) =>
  getSettings(
    `currentprovider/diagnosis?q=${data.search}&startIndex=${data.startIndex}&recordCount=${data.count}`,
    {
      cancelToken:
        getDiagnosisSortLimitCancel.getDiagnosisSortLimit.handleRequestCancellation()
          .token,
    }
  );

let getDiagnosisSortLimitCancel = createCancelTokenHandler({
  getDiagnosisSortLimit,
});

let diagnosisCancel = createCancelTokenHandler({ getDiagnosisSearch });

export const getFavDiagnosisSearch = (data: string) =>
  getSettings(`currentprovider/favdiagnosis?q=${data}`, {
    cancelToken:
      diagnosisFavCancel.getDiagnosisSearch.handleRequestCancellation().token,
  });
let diagnosisFavCancel = createCancelTokenHandler({ getDiagnosisSearch });

//https://collector-dev.claimocity.io/dailycensus/visits/5ba544c96de9421ec7b6fc5e/series
export const getVisitDetails = (data: VisitDetailsSearchType) =>
  getCollector(`dailycensus/visits/${data.id}/series`);

export const getDailysummary = (data: DailysummaryApiArg) =>
  getCollector(
    `dailycensus/visits/dailysummary?date_from=${data.date_from}&date_to=${data.date_to}`
  );

//for getting procedures from collector URL
export const getProcedureList = (charge_id: any) =>
  getCollector(`dailycensus/visits/${charge_id}/procedures`);

export const getVistByServiceDate = (servicedate: string) =>
  getCollector(
    `dailycensus/visits?servicedate=${servicedate}&saltvisits=true&teamvisits=true`
  );

export const getCptList = (code?: string, placeOfService?: any) =>
  getSettings(
    `currentprovider/favprocedures?q=${code}&place_of_service=${placeOfService}`
  );

export const getCPTListFav = (code: any, place_of_service: any) =>
  getSettings(
    `currentprovider/favprocedures?q=${code} &place_of_service=${place_of_service}`
  );

export const putCptChange = (patientId: any, data: any) =>
  putCollector(`charges/${patientId}/procedures`, {
    procedures: data,
  });

export const putProgressNoteDiagnosiscodes = (
  charge_id: string,
  data: PutProgressNoteDiagnosiscodesPayload
) => putCollector(`/charges/${charge_id}/diagnosiscodes`, data);

export const putProgressNoteProcedures = (
  charge_id: string,
  data: PutProgressNoteProceduresPayload
) => putCollector(`/charges/${charge_id}/procedures`, data);

export const saveChartProgressNote = (visit_id: string, data: any) =>
  putCollector(`charges/${visit_id}/chart`, data);
export const signChartProgressNote = (visit_id: string) =>
  postCollector(`charges/${visit_id}/chart/sign`, {});

//for submitting procedure codes on 'CPT change' button for visits
export const submitcpt = (chargeId: any, data: any) =>
  putCollector(`charges/${chargeId}/procedures`, {
    procedures: [
      {
        code: data.code,
        modifiers: data.modifiers,
      },
    ],
  });

export const getProcedures = (data?: String) =>
  getSettings(`currentprovider/procedures?q=${data}&place_of_service=`);

export const getDiagnosis = (data?: String) =>
  getSettings(`currentprovider/diagnosis?q=${data}&place_of_service=`);

export const getFavProceduresSearch = (data?: String) =>
  getSettings(`currentprovider/favprocedures?q=${data}&place_of_service=`);

export const getVisitStatuses = () =>
  getSettings(`globaldata/visit_statuses_beta`);

//Favorite Procedure
export const favoriteProcedure = (procedureId: any = {}) =>
  putSettingsForSingleParam(`currentprovider/favprocedures/${procedureId}`);

//Un Favorite Procedure
export const unFavoriteProcedure = (procedureId: any = {}) =>
  delSettings(`currentprovider/favprocedures/${procedureId}`);

//Favorite Diagnosis
export const favoriteDiagnosis = (diagnosisId: any = []) => {
  postSettingsForSingleParam("currentprovider/favdiagnosis", diagnosisId);
};

//Un Favorite Diagnosis
export const unFavoriteDiagnosis = (diagnosisId: any = {}) =>
  delSettings(`currentprovider/favdiagnosis/${diagnosisId}`);

export const postVisit = (data: any) => {
  const value = postCollector("dailycensus/visits", data).then((resonse) => {
    return resonse;
  });
  return value;
};

export const callConfirmVisit = (charge_id: any, body: any) =>
  postCollector(`/dailycensus/visits/${charge_id}/clone`, body);

export const addPatients = (payload: AddPatientsPayload) =>
  postCollector(`/patients/addnew`, payload);

//get issues count
export const getproviderholdCount = () =>
  getCollector(`dailycensus/providerholdCount`);

export const dischargeVisit = (data?: any, id?: string) =>
  putCollector(`dailycensus/visits/${id}/statuses`, data);

export const procedureUpdate = (chargeId: any, data: any) =>
  putCollector(`charges/${chargeId}/procedures`, {
    procedures: data,
  });

export const documentUpdate = (data?: any) =>
  putCollector(`dailycensus/visits`, data);

export const billVisitSubmit = (visit_id?: string) =>
  postCollector(`dailycensus/visits/${visit_id}/submitbill`, {});

export const diagnosisUpdate = (chargeId: any, data: any) =>
  putCollector(`charges/${chargeId}/diagnosiscodes`, {
    diagnosis_codes: data,
  });

export const undoDischargeVisit = (data?: DischargeVisitType, id?: string) =>
  putCollector(`dailycensus/visits/${id}/statuses`, data);

export const undoSkipVisit = (data?: any, id?: string) =>
  putCollector(`dailycensus/visits/${id}/visitstatus`, data);

export const skipVisit = (charge_id?: string, data?: any) =>
  putCollector(`dailycensus/visits/${charge_id}/visitstatus`, data);

export const getVisitByMonth = (fromDate: string, toDate: string) =>
  getCollector(
    `/dailycensus/visits/dailysummary?date_from=${fromDate}&date_to=${toDate}`
  );

export const visitStatusCensus = (charge_id: any, body: any) =>
  putCollector(`/dailycensus/visits/${charge_id}/visitstatus`, body);
// export const getVisitByMonth = (fromDate: string, toDate: string) =>
//   getCollector(
//     `/dailycensus/visits/dailysummary?date_from=${fromDate}&date_to=${toDate}`
//   );

//To get data for dashboard top card
export const callDashboardTopCard = (body: any) =>
  postCollector(`v2/dashboard/reports`, body);

export const getMipsmeasures = () =>
  getSettings(`currentpractice/mipsmeasures`);

//visit diagnosis
export const getVisitDiagnosis = (charge_id: any) =>
  getCollector(`/dailycensus/visits/${charge_id}/diagnosis`);

//visit procedure
export const getVisitProcedures = (charge_id: any) =>
  getCollector(`/dailycensus/visits/${charge_id}/procedures`);

export const getVisitCharts = (charge_id: string) =>
  getCollector(`/charges/${charge_id}/chart`);

//get conversations
export const getConversations = (data: any) => {
  return getSocket(
    `/conversations?q=${data?.q}&page=${data?.page}&type=${data?.type}`
  );
};

export const voidBill = (charge_id: string) => {
  let body = {};
  return postCollector(`dailycensus/visits/${charge_id}/void`, body);
};
export const getfullRevenueData = (data: any) => {
  return getCollector("/practiceiq/revenue", data);
};

//get specific conversation
export const getReadConversation = (id: any) => {
  return getSocket(`/conversations/${id}/read`);
};

export const createConversation = (conversation_id: any, body: any) =>
  postSocket(`/conversations/${conversation_id}`, body);

export const getPicture = (fileName: any) => {
  return getCollector("/dailycensus/files/image/" + fileName);
};

//For edit patient data update
export const putEditPatient = (patient_id: any, body: any) =>
  putCollector(`/patients/update-patient/${patient_id}`, body);

//For top paying insurance companies
export const getPayorData = (startDate: any, endDate: any) => {
  return getCollector(`/v2/practiceiq/revenue/payer/${startDate}/${endDate}`);
};

//change dos
export const changeDateOfService = (charge_id: any, body: any) =>
  putCollector(`/dailycensus/v2/visits/${charge_id}/servicedate`, body);
//revenue data
export const getRevenueData = (startDate: any, endDate: any) => {
  return getCollector(`/v2/practiceiq/revenue/${startDate}/${endDate}`);
};

//revenue by year
export const getRevenueByYear = (startDate: any, endDate: any) => {
  return getCollector(`/v2/practiceiq/revenue/${startDate}/${endDate}`);
};

//encounter by year
export const getEncounterByYear = (startDate: any, endDate: any) => {
  return getCollector(`/v2/practiceiq/encounters/${startDate}/${endDate}`);
};

//rvu by year
export const getRvuByYear = (startDate: any, endDate: any) => {
  return getCollector(`/v2/practiceiq/rvu/${startDate}/${endDate}`);
};

//avg revenue
export const getAverageRev = (startDate: any, endDate: any) => {
  return getCollector(`/v2/practiceiq/avgrvn/${startDate}/${endDate}`);
};

export const createGroupConversation = (body: any) =>
  postSocket(`/conversations`, body);
export const updateArchiveStatus = (req: any) => {
  return putSocket(`/conversations/${req.id}/messagetype/${req.status}`, {});
};

export const getCurrentUser = (config: any = {}) =>
  getSettings("currentuser", config);

export const addNewPaticipantConversation = (id: any, body: any) =>
  postParticipantSocket(`/conversations/${id}/participants`, body);

export const putFavoriteUser = (body: any) =>
  putSettings("currentuser/favoritesparticipants", body);

export const putProfileUser = (id: any, data: any) =>
  putSettings(`users/${id}`, data);
export const getDaysPerEnc = (startDate: any, endDate: any) => {
  return getCollector(
    `/v2/practiceiq/avgbillsubmitdays/${startDate}/${endDate}`
  );
};

export const getPatientsData = (currentDate: any, type: any) =>
  getCollector(`/v2/dashboard/practice/patients/${currentDate}/${type}`);
export const getEncounterData = (currentDate: any, type: any) =>
  getCollector(`/v2/dashboard/practice/encounters/${currentDate}/${type}`);
export const getRevenueDatas = (currentDate: any, type: any) =>
  getCollector(`/v2/dashboard/practices/revenue/${currentDate}/${type}`);
export const getRvuData = (currentDate: any, type: any) =>
  getCollector(`/v2/dashboard/practice/rvu/${currentDate}/${type}`);

export const getAdmissionStatusChartView = (payload: any) =>
  postCollector(`/v2/dashboard/distributionStatus`, payload);
export const getTotalPatientsChartView = (
  currentDate: any,
  type: any,
  payload: any
) =>
  postCollector(
    `/v2/dashboard/provider/patients/${currentDate}/${type}`,
    payload
  );
export const getPossibleRVUChartView = (
  currentDate: any,
  type: any,
  payload: any
) =>
  postCollector(`/v2/dashboard/provider/rvu/${currentDate}/${type}`, payload);

export const getVisitsByDateAndFacility = (date: string, facility_id: string) =>
  getCollector(
    `/dailycensus/list?servicedate=${date}&saltvisits=true&teamvisits=true&favdefault=true&facility_id=${facility_id}`
  );

//BULK-VOID
export const postBulkVoid = (payload: any) =>
  postCollector(`dailycensus/bulkvoid`, payload);

//BULK CLONE
export const bulkClone = (payload: any) =>
  postCollectorBulk(`dailycensus/v2/visits/bulkvisitclone`, payload);

//BULK VISIT STATUS
export const bulkVisitStatus = (payload: any) =>
  putCollector(`dailycensus/v2/visits/bulkvisitstatus`, payload);

//BULK VISIT STATUS KEY
export const bulkVisitStatusKey = (payload: any) =>
  putCollector(`dailycensus/v2/visits/bulkstatuses`, payload);

//BULK DISCHARGE
export const bulkUndoDischargeKey = (payload: any) =>
  putCollector(`dailycensus/v2/visits/bulkstatuses`, payload);

//BULK RECALL SUBMISSION
export const bulkVisitRecall = (body: any) =>
  postCollector(`dailycensus/v2/visits/bulkrecallbill`, body);
export const putDiagnosiscodes = (
  charge_id: string,
  data: PutProgressNoteDiagnosiscodesPayload
) => putCollector(`/charges/${charge_id}/diagnosiscodes`, data);
