import React from "react";
import {
  IonItem,
  IonLabel,
  IonList,
  IonAccordion,
  IonAccordionGroup,
  IonTextarea,
  IonGrid,
  IonRow,
} from "@ionic/react";

import "./HPI.scss";

export const HPI: React.FC = () => {
  return (
    <React.Fragment>
      <IonGrid className="hpi-ion-grid">
        <IonRow>
          <IonLabel className="hpi-ion-input-txt">
            History of present illness
          </IonLabel>
        </IonRow>
        <IonRow>
          <IonLabel className="hpi-ion-input">
            <IonTextarea className="hpi-ion-text" value="" />
          </IonLabel>
        </IonRow>
      </IonGrid>
   <p className="hpi-row-bottom-border"></p> 
      <IonGrid className="hpi-ion-grid">
       <IonAccordionGroup>
      {/*     <IonAccordion toggleIconSlot="start">
            <IonItem className="title-container" lines="none" slot="header">
              <IonLabel className="card-title">SAR ADMISSION</IonLabel>
            </IonItem>
            <IonList slot="content" className="card-content">
              <IonLabel>SAR ADMISSION conentent</IonLabel>
            </IonList>
  </IonAccordion> 
          <p className="hpi-row-bottom-border"></p> */}
          <IonAccordion toggleIconSlot="start">
            <IonItem className="title-container" lines="none" slot="header">
              <IonLabel className="card-title">ELEMENTS</IonLabel>
            </IonItem>
            <IonList slot="content" className="card-content">
              <IonLabel>ELEMENTS conentent</IonLabel>
            </IonList>
          </IonAccordion>
          <p className="hpi-row-bottom-border"></p>
          <IonAccordion toggleIconSlot="start">
            <IonItem className="title-container" lines="none" slot="header">
              <IonLabel className="card-title">CONDITIONS</IonLabel>
            </IonItem>
            <IonList slot="content" className="card-content">
              <IonLabel>CONDITIONS conentent</IonLabel>
            </IonList>
          </IonAccordion>
        </IonAccordionGroup>
      </IonGrid>
    </React.Fragment>
  );
};
