import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../store";

type UserState = {
  isloading: boolean;
  isError: boolean;
  dateOfService: string | null;
  diagnosisCodesSelected: any[];
  procedureCodesSelected: any[];
  additionalInfo: any[];
  errorMsg: any;
  patientDetail: any;
};

const initialState: UserState = {
  isloading: true,
  isError: true,
  dateOfService: null,
  diagnosisCodesSelected: [],
  procedureCodesSelected: [],
  additionalInfo: [],
  errorMsg: "",
  patientDetail: null,
};

export const newEncounterSlice = createSlice({
  name: "newEncounter",
  initialState,
  reducers: {
    closeError: (state) => {
      state.isError = false;
      state.errorMsg = "";
    },
    closeLoading: (state) => {
      state.isloading = false;
    },
    setDateOfService: (state, action: PayloadAction<any>) => {
      state.dateOfService = action.payload;
    },
    selectProcedureCode: (state, action: PayloadAction<any>) => {
      state.procedureCodesSelected = [
        ...state.procedureCodesSelected,
        ...action.payload,
      ];
    },
    setProcedureCode: (state, action: PayloadAction<any>) => {
      state.procedureCodesSelected = action.payload;
    },

    setPatientDetail: (state, action: PayloadAction<any>) => {
      state.patientDetail = action.payload;
    },
    selectDiagnosisCode: (state, action: PayloadAction<any>) => {
      state.diagnosisCodesSelected = [
        ...state.diagnosisCodesSelected,
        ...action.payload,
      ];
    },
    setDiagnosisCode: (state, action: PayloadAction<any>) => {
      action.payload.forEach((item: any) => {
        if (
          state.diagnosisCodesSelected
            .map((item) => item.code)
            .includes(item.code)
        ) {
          state.diagnosisCodesSelected = state.diagnosisCodesSelected.filter(
            (element) => element.code !== item.code
          );
        } else
          state.diagnosisCodesSelected = [
            ...state.diagnosisCodesSelected,
            item,
          ];
      });
    },

    handleRegroupCode: (state, action: any) => {
      if (action.payload.code === "diagnosis") {
        let newArray = [...state.diagnosisCodesSelected];
        const element = newArray.splice(action.payload.from, 1)[0];
        newArray.splice(action.payload.to, 0, element);
        state.diagnosisCodesSelected = [...newArray];
      }

      if (action.payload.code === "procedure") {
        let newArray = [...state.procedureCodesSelected];
        const element = newArray.splice(action.payload.from, 1)[0];
        newArray.splice(action.payload.to, 0, element);
        state.procedureCodesSelected = [...newArray];
      }
    },
    resetThisState: (state) => {
      state.diagnosisCodesSelected = initialState.diagnosisCodesSelected;
      state.errorMsg = initialState.errorMsg;
      state.isError = initialState.isError;
      state.isloading = initialState.isloading;

      state.procedureCodesSelected = initialState.procedureCodesSelected;
      state.additionalInfo = initialState.additionalInfo;
      state.dateOfService = initialState.dateOfService;
      state.patientDetail = initialState.patientDetail;
    },
  },

  extraReducers: (builder) => {},
});

export const {
  setDateOfService,
  closeError,
  selectProcedureCode,
  setProcedureCode,
  setPatientDetail,
  selectDiagnosisCode,
  setDiagnosisCode,
  handleRegroupCode,
  resetThisState,
} = newEncounterSlice.actions;

export const selectNewEncounter = (state: RootState) => state.newEncounter;
export const selectPatientDetail = (state: RootState) =>
  state.newEncounter.patientDetail;

export const newEncounterReducer = newEncounterSlice.reducer;
