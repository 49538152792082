import {
  IonButton,
  IonCol,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonRouter,
} from "@ionic/react";
import {
  addOutline,
  cameraOutline,
  checkmark,
  chevronBackOutline,
  exitOutline,
  mic,
  micCircleOutline,
  sendOutline,
  warningOutline,
} from "ionicons/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Loader from "../../../components/Common/Loaders/Loader";
import {
  channgeStatus,
  createConversation,
  getPatientsVisitDetailsID,
  getPicture,
  getReadConversation,
  getReason,
  getVisitStatuses,
} from "../../../helper/backendHelper";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectUser } from "../../../store/reducers/user";
import "./MessageThread.scss";
import { Camera, CameraResultType, Photo } from "@capacitor/camera";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import {
  SOCKET_URL,
  setupInterceptorsTo,
} from "../../../helper/apiHelper/apiHelper";
import axios from "axios";
import {
  VoiceRecorder,
  RecordingData,
  GenericResponse,
} from "capacitor-voice-recorder";
import ProviderModal from "./providerModal";
import { socket } from "../../../socket";
import {
  setMessages,
  setRedirectedFrom,
  setSelectedChat,
} from "../../../store/reducers/message";
import { getChatMessages } from "../../../store/thunk/messageThunk";
import StatusIcon from "../../../components/Common/StatusIcon/StatusIcon";
import { setVisitStatusesCensus } from "../../../store/reducers/census";

const MessageThread: React.FC = (props: any) => {
  const socketAPI = setupInterceptorsTo(
    axios.create({
      baseURL: SOCKET_URL,
    })
  );
  let conversationId = useParams<{ id?: string }>();
  const userData = useAppSelector(selectUser);
  const messages = useAppSelector((state) => state.messages)?.messages;
  const selectedChat = useAppSelector((state) => state.messages)?.selectedChat;
  const redirectedFrom = useAppSelector(
    (state) => state.messages
  )?.redirectedFrom;
  const [visitDetailsData, setVisitDetailsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [reasonsData, setReasonsData] = useState<any>([]);
  const [rescording, setRecording] = useState(false);
  const [visit_statuses, setVisitStatuses] = useState<any>();
  const [enableSubmitButton, setenableSubmitButton] = useState(false);
  const [tskStatusPH, settskStatusPH] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [msgCopy, setMsgCopy] = useState(messages);
  const router = useIonRouter();
  const dispatch = useAppDispatch();
  let Token: any = localStorage.getItem("Token");
  let currentUserId = JSON.parse(Token)?.user?._id;

  const readAsBase64 = async (photo: any) => {
    if (isPlatform("hybrid")) {
      const file = await Filesystem.readFile({
        path: photo.path,
      });

      return file.data;
    } else {
      // Fetch the photo, read as a blob, then convert to base64 format
      const response = await fetch(photo.webPath);
      const blob = await response.blob();

      return (await convertBlobToBase64(blob)) as string;
    }
  };

  // Helper function
  const convertBlobToBase64 = async (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  const saveImage = async (photo: Photo) => {
    const base64Data: any = photo?.base64String;

    const fileName = new Date().getTime() + ".jpeg";
    const savedFile: any = await Filesystem.writeFile({
      path: `${fileName}`,
      data: base64Data,
      directory: Directory.Data,
    });
    const rawData = atob(base64Data);
    const bytes = new Array(rawData.length);
    for (var x = 0; x < rawData.length; x++) {
      bytes[x] = rawData.charCodeAt(x);
    }
    const arr = new Uint8Array(bytes);
    const blob = new Blob([arr], { type: "image/png" });
    // const response = await fetch(base64Data);
    // const blob = await response.blob();
    console.log(blob, fileName);
    const formData = new FormData();
    formData.append("file", blob, fileName);
    formData.append("fileName", fileName);
    let uploadImage: any = await socketAPI.post(
      "/conversations/upload/file",
      formData
    );
    if (uploadImage?.data?.status === "success") {
      let body = {
        user_id: userData?.user?._id,
        conversation_id: conversationId?.id,
        message_type: 2,
        file_name: fileName,
        file_type: "image/jpeg",
      };

      let res = await createConversation(conversationId?.id, body);
      if (res) {
        setTextMessage("");
        socket.emit("new_message", {
          conversation_id: conversationId?.id,
          count_msg: "last_msg",
          user_id: currentUserId,
        });
      }
    }
  };

  const startUpload = async (file: any) => {
    const response = await fetch(file.data);
    const blob = await response.blob();
    const formData = new FormData();
    formData.append("file", blob, file.name);
    uploadData(formData);
  };

  // Upload the formData to our API
  const uploadData = async (formData: FormData) => {
    let res = await socketAPI.post("/conversations/upload/file", formData);
  };

  const takePhoto = async () => {
    const photo = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      // source: CameraSource.Photos,
    });
    if (photo) {
      saveImage(photo);
    }
  };

  const visitisAction = (status: any, action: any, statuses: any) => {
    let config = statuses;
    let available_Actions = [];
    if (config[status] && config[status]?.actions) {
      available_Actions = config[status]?.actions;
    }
    if (available_Actions?.indexOf(action) > -1) {
      return true;
    } else {
      return false;
    }
  };

  const isAction = (visitstatus: any, action: any, statuses: any) => {
    return visitisAction(visitstatus, action, statuses);
  };

  const processVisitData = async (data: any, visit: any) => {
    let res: any = await getVisitStatuses();
    if (res?.status === "success") {
      setVisitStatuses(res?.results[0]?.values);
    }
    if (
      (isAction(visit?.status, "Resolve", res?.results[0]?.values) ||
        visit?.taskstatus_id == "5a1ba9b0f7c5f4ebba017f25" ||
        visit?.taskstatus_id == "5a1e7d8ff7c5f4ebba3f503c") &&
      visit.exceptions &&
      visit.exceptions != null
    ) {
      // if (data.data[0].task_statuss && (data.data[0].task_statuss == "5a1ba9b0f7c5f4ebba017f25" || data.data[0].task_statuss == "5a1e7d8ff7c5f4ebba3f503c")) {
      if (visit?.taskstatus_id == "5a1e7d8ff7c5f4ebba3f503c") {
        setenableSubmitButton(true);
      }
      settskStatusPH(true);
      //console.log("...", tskStatusPH, enableSubmitButton, visit?.taskstatus_id);
      //  if(data.data[0].assigned_user_id!=currentUserId){

      //    showSubmit=true;
      //  }else{
      //    showSubmit=false;
      //  }

      let reason_codes = [];
      let filtered_codes: any = [];
      if (data?.data[0]?.reason_codes) {
        reason_codes = data?.data[0]?.reason_codes;
      }

      if (data?.data[0]?.filtered_codes) {
        filtered_codes = data?.data[0]?.filtered_codes;
      }

      reason_codes = reason_codes?.map((reason_code: any) => {
        let isAvail = false;
        for (let tmp of filtered_codes) {
          if (tmp.code_title == reason_code) {
            return (reason_code = tmp);
            // break;
          }
        }
        return {
          code_title: reason_code,
          code_description: "",
          enabled: true,
        };
      });
      reason_codes = reason_codes;
    } else {
      settskStatusPH(false);
    }
  };

  const getMessages = async () => {
    setIsLoading(true);
    let res: any = await dispatch(getChatMessages(conversationId));
    if (res?.payload?.status === "success") {
      res.data = res?.payload?.data;
      if (res?.data?.length === 0 && Object.keys(selectedChat)?.length > 0) {
        let payload: any = [
          {
            _id: conversationId?.id,
            participantss: JSON.parse(
              JSON.stringify(selectedChat?.participants)
            ),
          },
        ];
        for (let i = 0; i < payload[0]?.participantss?.length; i++) {
          let participant = payload[0]?.participantss[i];
          if (participant?.username || participant?.displayname) {
            if (!participant?.username) {
              participant.username = participant?.displayname;
            }
            if (participant?.username.indexOf(" ") == -1) {
              participant.username_character = participant?.username
                ?.charAt(0)
                .toUpperCase();
            } else {
              let x = participant?.username.substr(
                participant?.username?.indexOf(" ") + 1
              );
              participant.username_character =
                participant?.username?.charAt(0).toUpperCase() +
                x.charAt(0).toUpperCase();
            }
          }
          if (participant?.practice_visibility == false) {
            participant.pv = "assets/imgs/avatar-claimocity.png";
          }
        }

        await dispatch(setMessages(payload));
        setMsgCopy(payload);
        res.data = payload;
      }
      setMsgCopy(res?.data);

      if (res?.data[0]?.task_status?.charge_id) {
        let visitDetailsData = await getPatientsVisitDetailsID(
          res?.data[0]?.task_status?.charge_id
        );
        if (visitDetailsData != "" && visitDetailsData != undefined) {
          let visit = visitDetailsData?.result[0]
            ? visitDetailsData?.result[0]
            : {};

          let inital = "";
          if (visit?.rendering_provider_name) {
            inital = visit?.rendering_provider_name
              ?.split(",")[0]
              .trim()
              .slice(0, 1);
            if (visit.rendering_provider_name?.split(",")?.length > 1) {
              inital += visit.rendering_provider_name
                ?.split(",")[1]
                .trim()
                .slice(0, 1);
            }
          }
          visit["rendering_provider_initial"] = inital;
          processVisitData(res, visit);
        }
        if (visitDetailsData?.status === "success") {
          setVisitDetailsData(visitDetailsData?.result);
        }
      }

      if (res?.data[0]?.task_statuss) {
        let reasonData = await getReason(res?.data[0]?.task_statuss);
        if (reasonData?.status === "success") {
          setReasonsData(reasonData?.data);
        }
      }
    }
    setIsLoading(false);
    setTimeout(() => {
      scrollDown();
    }, 500);
  };

  const getRendomColor = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  const createConversationHelper = async () => {
    setenableSubmitButton(true);
    let body = {
      user_id: userData?.user?._id,
      conversation_id: conversationId?.id,
      message_type: 1,
      message_text: textMessage,
    };

    let res = await createConversation(conversationId?.id, body);
    console.log("msgb", messages);

    if (res) {
      setTextMessage("");
      // getMessages();

      socket.emit("new_message", {
        conversation_id: conversationId?.id,
        count_msg: "last_msg",
        user_id: currentUserId,
      });
    }
    console.log("msgaf", messages);
  };

  const startRecording = async () => {
    VoiceRecorder.requestAudioRecordingPermission().then(
      (result: GenericResponse) => console.log(result.value)
    );
    VoiceRecorder.startRecording()
      .then((result: GenericResponse) => {
        setRecording(true);
      })
      .catch((error: any) => console.log(error));
  };

  const stopRecording = async () => {
    VoiceRecorder.stopRecording()
      .then(async (result: RecordingData) => {
        setRecording(false);
        let fileName = "";
        let fileName_concat;
        let filePath;
        if (isPlatform("ios")) {
          fileName =
            "record" +
            new Date().getDate() +
            new Date().getMonth() +
            new Date().getFullYear() +
            new Date().getHours() +
            new Date().getMinutes() +
            new Date().getSeconds() +
            ".wav";
          fileName_concat =
            "record" +
            new Date().getDate() +
            new Date().getMonth() +
            new Date().getFullYear() +
            new Date().getHours() +
            new Date().getMinutes() +
            new Date().getSeconds() +
            "_" +
            conversationId?.id +
            ".mp3";
          filePath = fileName;
        } else if (isPlatform("android")) {
          fileName =
            "record" +
            new Date().getDate() +
            new Date().getMonth() +
            new Date().getFullYear() +
            new Date().getHours() +
            new Date().getMinutes() +
            new Date().getSeconds() +
            ".wav";
          fileName_concat =
            "record" +
            new Date().getDate() +
            new Date().getMonth() +
            new Date().getFullYear() +
            new Date().getHours() +
            new Date().getMinutes() +
            new Date().getSeconds() +
            "_" +
            conversationId?.id +
            ".mp3";
          filePath = fileName;
        }
        const savedFile: any = await Filesystem.writeFile({
          path: `${fileName}`,
          data: result.value.recordDataBase64,
          directory: Directory.Data,
        });
        console.log(result?.value);

        const rawData = atob(result.value.recordDataBase64);
        const bytes = new Array(rawData.length);
        for (var x = 0; x < rawData.length; x++) {
          bytes[x] = rawData.charCodeAt(x);
        }
        const arr = new Uint8Array(bytes);
        const blob = new Blob([arr], { type: "audio/webm" });

        // const response = await fetch(result?.value?.recordDataBase64);
        // console.log(response);
        // let blob: any = await response.blob();
        console.log(blob);
        const formData = new FormData();
        console.log(blob, fileName);
        formData.append("file", blob, fileName);
        formData.append("fileName", fileName);
        let uploadImage: any = await socketAPI.post(
          "/conversations/upload/file",
          formData
        );
        if (uploadImage?.data?.status === "success") {
          let body = {
            user_id: userData?.user?._id,
            conversation_id: conversationId?.id,
            message_type: 3,
            file_name: fileName,
            file_type: "audio/wav",
          };

          let res = await createConversation(conversationId?.id, body);
          if (res) {
            setTextMessage("");
            socket.emit("new_message", {
              conversation_id: conversationId?.id,
              count_msg: "last_msg",
              user_id: currentUserId,
            });
          }
        }
      })
      .catch((error: any) => console.log(error));
  };

  const visitStatus = async () => {
    let res: any = await getVisitStatuses();
    if (res?.status === "success") {
      setVisitStatuses(res?.results[0]?.values);
      await dispatch(setVisitStatusesCensus(res?.results[0]?.values))
    }
  };

  useEffect(() => {
    console.log("hello");
    visitStatus();
    getMessages();
  }, [selectedChat]);

  useEffect(() => {
    socket.connect();
    socket.emit("join-room", conversationId?.id);
  }, [conversationId?.id]);

  const showRecentMessage = async (data: any, msg: any) => {
    let messagesData = JSON.parse(JSON.stringify(msg));
    if (
      messagesData[0]?.messagess?.length === 0 ||
      messagesData[0]?.messagess === undefined
    ) {
      messagesData[0].messagess = [];
    }
    console.log("msg", messagesData);

    if (
      data?.data &&
      data?.data?.data[0] &&
      data?.data?.data[0]?._id &&
      conversationId?.id == data?.data?.data[0]?._id
    ) {
      if (
        data?.count_msg == "second_last_msg" &&
        data?.data?.data[0]?.second_last_message &&
        data?.data?.data[0]?.last_message
      ) {
        // socket binding second last message
        let second_last_msg = data?.data?.data[0]?.second_last_message;
        second_last_msg.username = data?.data?.data[0]?.username;
        messagesData[0]?.messagess?.push(second_last_msg);
        // socket binding last message
        let latestmsg = data?.data?.data[0]?.last_message;
        latestmsg.username = data?.data?.data[0]?.username;
        messagesData[0]?.messagess?.push(latestmsg);
      } else if (
        data?.count_msg == "last_msg" &&
        data?.data?.data[0]?.last_message
      ) {
        console.log(
          "data?.data?.data[0]?.last_message",
          data?.data?.data[0]?.last_message
        );
        // socket binding last message
        let latestmsg = data?.data?.data[0]?.last_message;
        latestmsg.username = data?.data?.data[0]?.username;
        messagesData[0]?.messagess?.push(latestmsg);
        console.log("messagesData[0].messagess", messagesData[0]?.messagess);
      }
      // this.aftergetmessagelistdata(this.messagesData);
      for (let i = 0; i < messagesData[0]?.messagess?.length; i++) {
        // Getting Image from s3
        if (
          (messagesData[0]?.messagess[i]?.message_type == 2 ||
            messagesData[0]?.messagess[i]?.message_type == 3) &&
          messagesData[0]?.messagess[i]?.file_name != null &&
          messagesData[0]?.messagess[i]?.file_name != undefined
        ) {
          let tmp = messagesData[0]?.messagess[i]?.file_name;
          try {
            let res = await getPicture(
              messagesData[0]?.messagess[i]?.file_name
            );
            if (res.status == "success") {
              messagesData[0].messagess[i]["fileurl"] = res?.data;
            }
          } catch (err) { }
        } else if (messagesData[0]?.messagess[i]?.message_type == 1) {
          setTimeout(() => {
            scrollDown();
          }, 800);
        }
        // Checking the same time of messages
        if (i > 0) {
          if (
            new Date(
              messagesData[0]?.messagess[i]?.message_datetime
            ).getFullYear() ==
            new Date(
              messagesData[0]?.messagess[i - 1]?.message_datetime
            ).getFullYear() &&
            new Date(
              messagesData[0]?.messagess[i]?.message_datetime
            ).getMonth() +
            1 ==
            new Date(
              messagesData[0]?.messagess[i - 1]?.message_datetime
            ).getMonth() +
            1 &&
            new Date(
              messagesData[0]?.messagess[i]?.message_datetime
            ).getDate() ==
            new Date(
              messagesData[0]?.messagess[i - 1]?.message_datetime
            ).getDate() &&
            new Date(
              messagesData[0]?.messagess[i]?.message_datetime
            ).getHours() ==
            new Date(
              messagesData[0]?.messagess[i - 1]?.message_datetime
            ).getHours() &&
            new Date(
              messagesData[0]?.messagess[i]?.message_datetime
            ).getMinutes() ==
            new Date(
              messagesData[0]?.messagess[i - 1]?.message_datetime
            ).getMinutes()
          ) {
            messagesData[0].messagess[i]["sameTime"] = "true";
          }
        }
      }
      await dispatch(setMessages(messagesData));
      setMsgCopy(messagesData);
    }
  };

  useEffect(() => {
    socket.on("recent_message", (data: any) => {
      console.log("msgsocA", messages);
      console.log("data", data);
      let msg: any = [];
      setMsgCopy((prevState: any) => {
        msg = [...prevState];
        return [...prevState];
      });
      data.data.data.forEach(function (item: any) {
        if (item.last_message.message_type == 3) {
          item.last_message["playPauseAudio"] = false;
        }
      });
      console.log("msg1", messages);
      showRecentMessage(data, msg);
    });

    return () => {
      socket.off("recent_message");
    };
  }, [socket]);

  const scrollDown = () => {
    const box = document.getElementById("thread-content") as any;
    box.scrollTop = 999999999;
  };
  console.log(tskStatusPH, enableSubmitButton);

  const completeAction = async () => {
    let data = await channgeStatus(messages[0]?.task_status?.charge_id);
    if (data.status == "success") {
      await dispatch(setRedirectedFrom(""));
      await dispatch(setSelectedChat({}));
      await dispatch(setMessages([]));
      router.push("/messagelist", "back");
    }
  };

  console.log("redirected", redirectedFrom);

  return (
    <IonPage className="ion-page-dashboard">
      <IonHeader>
        <IonToolbar
          className="ion-toolbar-dashboard toolbar-message-thread"
          mode="ios"
        >
          {/* <IonMenuButton slot="start" className="ion-icon-size-dashboard" /> */}
          <div className="message-icons back">
            {tskStatusPH && enableSubmitButton ? (
              <IonIcon
                onClick={() => setIsOpen(true)}
                icon={chevronBackOutline}
              ></IonIcon>
            ) : (
              <IonIcon
                onClick={async () => {
                  await dispatch(setSelectedChat({}));
                  await dispatch(setRedirectedFrom(""));
                  await dispatch(setMessages([]));
                  router.push("/messagelist", "back");
                }}
                icon={chevronBackOutline}
              ></IonIcon>
            )}
          </div>

          <div className="thread-conv-users">

            <div className="thread-users">
              {messages[0]?.participantss?.map((item: any, i: any) =>
                !item?.pv ? (
                  <p
                    className="users"
                    style={{ backgroundColor: getRendomColor() }}
                    key={item?.user_id}
                  >
                    {item?.username_character}
                  </p>
                ) : (
                  <p className="users" key={item?.user_id}>
                    <img src={"./assets/Logo.svg"} className="headerUsers" />
                  </p>
                )
              )}
            </div>

            <p
              className={redirectedFrom !== "patientDetails" && redirectedFrom !== "draft" ? "add" : "sub"}
              onClick={() => {
                if (redirectedFrom !== "patientDetails" && redirectedFrom !== "draft") {
                  router.push(`/contact-list/${conversationId?.id}`, "forward")
                }
              }
              }
            >
              {redirectedFrom !== "patientDetails" && redirectedFrom !== "draft" && (
                <IonIcon icon={addOutline} />
              )}
            </p>
          </div>
          {tskStatusPH && enableSubmitButton && (
            <div className="message-icons check" onClick={completeAction}>
              <IonIcon icon={checkmark} />
            </div>
          )}
        </IonToolbar>
        <IonToolbar className="msgThreadToolbarTitle">
          {!isLoading && (
            <p className="title-header-dashboard">
              {messages[0]?.title} on{" "}
              {moment(messages[0]?.conversation_created_date).format(
                "MM/DD/YYYY"
              )}
            </p>
          )}
        </IonToolbar>
      </IonHeader>
      <div className="scroll-element" id="thread-content">
        <div className="chat-thread">
          {isLoading === false && (
            <>
              {visitDetailsData?.length > 0 ? (
                visitDetailsData.map((item: any, i) => (
                  <>
                    <div className="visit-details-thread">
                      <p className="visit-title">
                        VISIT DETAILS | DOS{" "}
                        {moment(item?.service_date_from).format("MM/DD/YYYY")}
                      </p>

                      <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol
                          size="1"
                          className="visit-detail-icon ion-no-padding ion-no-margin"
                        >
                          <p className="primary">
                            <StatusIcon visit={item} />
                          </p>
                        </IonCol>
                        <IonCol className="visit-detail-second-col ion-no-padding ion-no-margin">
                          <p className="fname-container">
                            <p className="first-last-name">
                              <span>{item?.patient_name}</span>{" "}
                            </p>

                            <p className="icon-with-info">
                              <IonIcon
                                className="exit-icon"
                                icon={exitOutline}
                              />
                              {item?.service_lines[0]?.procedure_code}
                              <span>AI</span>
                            </p>
                          </p>
                          <p className="visit-detail-description">
                            <span>{item?.diagnosisCodes[0]?.code}</span> -{" "}
                            {item?.diagnosisCodes[0]?.description}
                          </p>
                        </IonCol>
                      </IonRow>
                    </div>
                    {tskStatusPH &&
                      item?.exceptions &&
                      item?.exceptions.length > 0 &&
                      item?.exceptions?.map((value: any) => (
                        <div className="visit-details-thread without-title">
                          <IonRow className="ion-no-padding ion-no-margin">
                            <IonCol
                              size="1"
                              className="visit-detail-icon ion-no-padding ion-no-margin"
                            >
                              <p className="red">
                                <IonIcon icon={warningOutline} />
                              </p>
                            </IonCol>
                            <IonCol className="visit-detail-second-col ion-no-padding ion-no-margin">
                              <p className="first-last-name">
                                <span>{value}</span>
                              </p>

                              {reasonsData[0]?.reason_code?.map(
                                (reason: any) =>
                                  reason?.code_title === value && (
                                    <p className="visit-detail-description">
                                      {reason?.code_description}
                                    </p>
                                  )
                              )}
                            </IonCol>
                          </IonRow>
                        </div>
                      ))}
                  </>
                ))
              ) : (
                <></>
              )}
            </>
          )}

          {isLoading === true ? (
            <div className="chatLoader">
              <Loader />
            </div>
          ) : isLoading === false && messages[0]?.messagess?.length > 0 ? (
            messages[0]?.messagess?.map((item: any, idx: any) => {
              return (
                <p
                  className={
                    item?.user_id !== currentUserId ? "inbound" : "outbound"
                  }
                  key={idx}
                >
                  <p
                    className={
                      item?.user_id !== currentUserId
                        ? "inbound-message"
                        : "outbound-message"
                    }
                  >
                    {/* <div className="vectorimgmain"> */}
                    <div className="vectorimg2">
                      {
                        <div className="vectormain">
                          <img src={"./assets/Vector.svg"} />
                          <span className="vectorimg1">{!item?.practice_visibility ? "Claimocity" : item?.username}{" "}</span>
                        </div>
                      }

                      <div className="vectorimg">
                        on{" "}
                        {/* {item?.is_internal && "(Internal)"} */}
                        {moment(item?.message_datetime).format("DD/MM/YYYY, HH:mm")}{" "}

                      </div>
                    </div>
                    <div>
                      <br />
                      <br />
                      <div className="vectorimg3">
                        {item?.message_type === 1 ? (
                          item?.message_text
                        ) : item?.message_type === 2 ? (
                          <img src={item?.fileurl} />
                        ) : item?.message_type === 3 ? (
                          <audio controls>
                            <source src={item?.fileurl} />
                          </audio>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </p>
                </p>
              );
            })
          ) : isLoading === false && messages[0]?.messagess?.length === 0 ? (
            <p>No data found</p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div style={{ height: "50px" }}>
        <div className="message-icons">
          <IonIcon
            icon={cameraOutline}
            onClick={() => {
              defineCustomElements(window);
              takePhoto();
            }}
          />
        </div>
        <div className="message-text-area">
          <input
            value={textMessage}
            onChange={(e) => setTextMessage(e.target.value)}
            type="text"
          />
        </div>
        <div className="message-icons voice">
          {!rescording && (
            <IonIcon icon={micCircleOutline} onClick={startRecording} />
          )}
          {rescording && <IonIcon icon={mic} onClick={stopRecording} />}
        </div>

        <div className="message-icons send">
          <IonIcon onClick={createConversationHelper} icon={sendOutline} />
        </div>
      </div>
      <ProviderModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        charge_id={messages[0]?.task_status?.charge_id}
      />
    </IonPage>
  );
};

export default MessageThread;
