import React from "react";
import {
  IonButtons,
  IonCard,
  IonCardSubtitle,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import "./ProcedureAndDiagnosis.scss";
import { IconSvg } from "../../../components/Common/IconSvg/IconSvg";
import { Edit2 } from "../../../icons/icons";

const ProcedureAndDiagnosis = (props: any) => {
  const options = {
    cssClass: "my-custom-interface",
  };
  const router = useIonRouter();
  const editProcedureDiagnosis = () => {
    router.push(
      `/editProcedureAndDiagnosis/${props?.visitId ? props?.visitId : ""}`,
      "forward"
    );
  };
  return (
    <IonCard className="EncounterHistory-card-container">
      <IonCardSubtitle>
        <IonRow class="ion-nowrap">
          <IonCol className="EncounterHistory-heading ion-nowrap" size="10">
            Procedure and Diagnosis
          </IonCol>

          <IonCol size="2">
            <div className="icon-edit-holder" onClick={editProcedureDiagnosis}>
              <IconSvg Icon={Edit2} />
            </div>
          </IonCol>

          <IonButtons slot="end"></IonButtons>
        </IonRow>
      </IonCardSubtitle>
      <h1 className="EncounterHistory-heading ion-nowrap">Procedure</h1>
      <IonList className="procedure-diagnosis-list">
        {props.SelectedData &&
          props.SelectedData.procedureCodes &&
          props.SelectedData.procedureCodes != null &&
          props.SelectedData.procedureCodes.map((x: any, index: number) => {
            return (
              <IonItem key={index} className="procedure-diagnosis">
                <IonLabel>{x.code}</IonLabel>
                <IonLabel>{x.description}</IonLabel>
              </IonItem>
            );
          })}
      </IonList>
      <h1 className="EncounterHistory-heading ion-nowrap">Diagnosis</h1>
      <IonList className="procedure-diagnosis-list">
        {props.SelectedData &&
          props.SelectedData.diagnosisCodes &&
          props.SelectedData.diagnosisCodes != null &&
          props.SelectedData.diagnosisCodes.map((x: any, index: number) => {
            return (
              <IonItem key={index} className="procedure-diagnosis">
                <IonLabel>{x.code}</IonLabel>
                <IonLabel>{x.description}</IonLabel>
              </IonItem>
            );
          })}
      </IonList>
    </IonCard>
  );
};
export default ProcedureAndDiagnosis;
