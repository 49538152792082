/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonButtons,
  IonBackButton,
  useIonRouter,
  IonRippleEffect,
} from "@ionic/react";

import { star } from "ionicons/icons";
import "./PatientDetails.scss";
import PatientInformation from "./PatientInformation/PatientInformation";
import FacilityInformation from "./FacilityInformation/FacilityInformation";
import OtherInformation from "./Other Information/otherInfo";
import EncounterHistory from "./EncounterHistory/EncounterHistory";
import ProgressNotesCard from "./Progress Notes/progressNotesCard";
import ProcedureAndDiagnosis from "./ProcedureAndDiagnosis/ProcedureAndDiagnosis";
import { IconSvg } from "../../components/Common/IconSvg/IconSvg";
import { Edit2, Stack } from "../../icons/icons";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getPatient } from "../../store/thunk/patientThunk";
import { setThispatient } from "../../store/reducers/patientDetails";
import { Layout } from "../../components/Layout/Layout";
import { getChartText, getVisitCharts } from "../../helper/backendHelper";

const PatientDetail: React.FC = () => {
  let quary: any = useParams<{ id?: string }>();
  const router = useIonRouter();
  const dispatch = useAppDispatch();

  const thisPateint = useAppSelector((state) => state.patientDetails);
  const visitDetailsResult = useAppSelector(
    (state) => state.patientDetails
  ).visitDetails;
  const [progressText, setprogressText] = useState("");

  // const [visitDetailsResult, setVisitDetailsResult] = useState<any>([]);

  useEffect(() => {
    if (quary?.id) {
      if (quary?.id !== null) {
        dispatch(getPatient(quary?.id));
      }
    }

    const getChartTextData = async () => {
      let result = await getChartText(quary?.id);
      if (result.status == "success") {
        setprogressText(result.data.note_txt);
      } else {
        setprogressText("");
      }
      // console.log("is sigens", isSigned);
    };

    getChartTextData();
  }, [quary?.id]);

  useEffect(() => {
    dispatch(setThispatient());
  }, []);

  return (
    <Layout
      isLoading={thisPateint?.isloading}
      isError={thisPateint?.isError}
      errorMsg={thisPateint.errorMsg}
    >
      <IonHeader className="patientDetailsHeader" mode="ios">
        <IonToolbar className="patientDetailsToolbar">
          <IonButtons slot="start">
            <IonBackButton text={""} className="setting-backbutton" />
          </IonButtons>
          <IonTitle className="titleText">Patient details</IonTitle>
          <IonButtons slot="end">
            <div
              className="icon-holder ion-activatable ripple-parent stack-icon"
              onClick={() => router.push(`/documents/${quary?.id}`, "forward")}
            >
              <IconSvg Icon={Stack} />
              <IonRippleEffect></IonRippleEffect>
            </div>
            <div
              className="icon-holder ion-activatable ripple-parent"
              onClick={() =>
                router.push(`/patientEdit/${quary?.id}`, "forward")
              }
            >
              <IconSvg Icon={Edit2} />
              <IonRippleEffect></IonRippleEffect>
            </div>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="patientDetailsContent">
        <p className="headerLabel">
          {/* <IonIcon icon={star} /> */}
          <strong>{thisPateint?.thisPatient?.patient_lastname}</strong>,{" "}
          {thisPateint?.thisPatient?.patient_firstname}
          {/* - 2345678 */}
        </p>
        <PatientInformation SelectedData={thisPateint.thisPatient} />
        <FacilityInformation SelectedData={thisPateint.thisPatient} />
        <ProcedureAndDiagnosis
          SelectedData={thisPateint.thisPatient}
          visitId={quary.id}
        ></ProcedureAndDiagnosis>
        <OtherInformation
          visitId={quary.id}
          data={thisPateint.thisPatient}
          SelectedData={thisPateint.thisPatient}
        />
        <ProgressNotesCard
          SelectedData={thisPateint.thisPatient}
          progressText={progressText}
        />
        <EncounterHistory data={visitDetailsResult} />
      </IonContent>
    </Layout>
  );
};
export default PatientDetail;
