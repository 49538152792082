import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonLabel,
  useIonRouter,
  IonInput,
  IonToast,
} from "@ionic/react";
import {
  alertCircleOutline,
  cameraOutline,
  chevronBack,
  calendarClearOutline,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import Button from "../../components/Common/Buttons/Buttons";
import CheckBox from "../../components/Common/CheckBox/CheckBox";
import "../AddPatient/AddPatient.scss";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import moment from "moment";
import { Datepicker } from "@mobiscroll/react";
import {
  getFacilityListData,
  putEditPatient,
} from "../../helper/backendHelper";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { usePhotoGallery } from "../../hooks/usePhotoGallery";
import { getPatient } from "../../store/thunk/patientThunk";
import { editPatientPayloadValidater } from "../../helper/Validation";
import { getVisitsCensus } from "../../store/thunk/censusThunk";
import { selectUser } from "../../store/reducers/user";
import Toast, { newToast } from "../../components/Common/Toaster/Toast";

interface TextInputProps {
  label: string;
  divclass: string;
  textclass: string;
}
type ToastedList = {
  id: number;
  description: string;
};
// common input field component
export const TextInputComp: React.FC<TextInputProps> = ({
  label,
  divclass,
  textclass,
  children,
}) => {
  return (
    <div className={divclass}>
      <IonText className={textclass}>{label}</IonText>
      {children}
    </div>
  );
};

const EditPatient: React.FC = () => {
  let quary = useParams<{ id?: string }>();
  const { photos, setPhotos, takePhoto } = usePhotoGallery();
  const dispatch = useAppDispatch();
  const thisPateint = useAppSelector((state) => state.patientDetails);
  const [facilityData, setFacilityData] = useState<any>();
  const [facilityName, setFacilityName] = useState<any>();
  const [toastedMessage, setToastedMessage] = useState<ToastedList[]>([]);
  const router = useIonRouter();
  const currentFacility = useAppSelector(
    (state) => state.census.censusFacility
  );
  let initialPatientDetails = {
    first_name: thisPateint.thisPatient?.patient_firstname,
    middle_name: thisPateint.thisPatient?.patient_middlename,
    last_name: thisPateint.thisPatient?.patient_lastname,
    dob: thisPateint.thisPatient?.patient_dob,
    // month: "",
    // day: "",
    // year: "",
    gender: thisPateint.thisPatient?.gender,
    // mrn: "",
  };

  const [values, setValues] = useState(initialPatientDetails);
  const [isHaveDob, setIsHaveDob] = useState(true);
  const [isValidate, setIsValidate] = useState(false);
  const [onsave, setOnsave] = useState<boolean>(false);
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [isDatePicker2Open, setDatePicker2Open] = useState<boolean>(false);
  const [dob, setdob] = useState("");
  const [doi, setdoi] = useState("");
  const [datePicker, setDatePicker] = useState("");
  // const [showToast, setShowToast] = useState(false);
  // const [toastmessage, setToastMessage] = useState("");
  const [validateError, setValidateError] = useState({
    status: false,
    msg: "",
  });
  const thisDate = useAppSelector((state) => state.census.activeDate);
  const userData = useAppSelector(selectUser);
  const HandleGender = (data: string) => {
    setValues({ ...values, gender: data });
  };

  const HandleSavePatient = (e: any) => {
    e.preventDefault();
    setOnsave(true);
  };

  const show = () => {
    setDatePickerOpen(true);
  };
  const onClose = () => {
    setDatePickerOpen(false);
  };

  const handleDateSelect = (e: any) => {
    if (datePicker === "dob") {
      setdob(moment(e.date).format("DD/MM/YYYY"));
    } else {
      setdoi(moment(e.date).format("DD/MM/YYYY"));
    }
    //setDatePicker("");
  };
  useEffect(() => {
    getFacilityList();
    setFacilityName(thisPateint.thisPatient?.facility_name);
  }, []);

  const getFacilityList = async () => {
    try {
      let res = await getFacilityListData();

      setFacilityData(res.results);
    } catch (error) {}
  };
  const getSelectedFacilityID = (index: any, name: string) => {
    setFacilityName(name);
  };

  const onInputChange = (e: any | React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.currentTarget?.name === "firstname" ||
      e.currentTarget?.name === "lastname"
    ) {
      if (e.currentTarget?.value?.length < 2) {
        setValidateError({
          status: true,
          msg: `${e.currentTarget?.name} should be minimum 2 characters`,
        });
      } else {
        setValidateError({
          status: false,
          msg: ``,
        });
      }
    }

    setValues({
      ...values,
      [e.currentTarget?.name]: e.currentTarget?.value.split(" ").join(""),
    });
  };

  const handleSavePatient = async () => {
    await putEditPatient(thisPateint.thisPatient?.patient_id, values)
      .then((res) => {
        if (res.status === "success") {
          dispatch(
            getVisitsCensus({
              servicedate: moment(thisDate).format("YYYY-MM-DD"),
              // thisProvider: userData.provider,
              facilityId: currentFacility?._id,
            })
          );
          if (thisPateint.thisPatient?._id) {
            dispatch(getPatient(thisPateint.thisPatient?._id));
          }
          let toastMsg = newToast("patient details updated", "Success");
          setToastedMessage([...toastedMessage, toastMsg]);
          router.goBack();
        }
      })
      .catch((response) => {
        let toastMsg = newToast(response.message, "Danger");
        setToastedMessage([...toastedMessage, toastMsg]);
      });
  };

  const onCancel = () => {
    setValues(initialPatientDetails);
    router.goBack();
  };

  const capturePhoto = () => {
    defineCustomElements(window);
    takePhoto();
  };

  const handleDOB = (item: any) => {
    if (
      moment(item?.value).isAfter(new Date()) ||
      moment().diff(item?.value, "year") > 125
    ) {
      setValidateError({
        status: true,
        msg: "Please enter a valid date of birth",
      });
    } else {
      setValidateError({
        status: false,
        msg: ``,
      });
      setValues({
        ...values,
        dob: moment(item?.value).format("YYYY-MM-DD"),
      });
    }
  };

  useEffect(() => {
    setIsValidate(editPatientPayloadValidater(values).validate);
  }, [values]);

  return (
    <IonPage className="add-patient">
      <IonHeader className="newpatient-header">
        <IonToolbar className="ion-toolbar-dashboard" mode="ios">
          <IonIcon
            onClick={() => router.goBack()}
            className="left-icon"
            icon={chevronBack}
          />
          <IonTitle className="title-header-dashboard">Edit Patient</IonTitle>
          {/* <div className="right-icon">
            <IonIcon
              className="camera-icon"
              icon={cameraOutline}
              onClick={capturePhoto}
            />
          </div> */}
        </IonToolbar>
      </IonHeader>
      <IonContent className="addpatient-content">
        <div className="newpatient-container">
          <p>Patient information</p>
          {validateError.status && (
            <>
              <p className="error-red">{validateError.msg}</p>
            </>
          )}
          <TextInputComp
            label="Last name"
            divclass="patient-names-row-2"
            textclass="patient-sublabel"
          >
            <input
              type="text"
              name="last_name"
              id="lastname"
              className="name-input"
              value={values.last_name}
              onChange={onInputChange}
            />
          </TextInputComp>
          <TextInputComp
            label="First name"
            divclass="patient-names-row-2"
            textclass="patient-sublabel"
          >
            <input
              type="text"
              name="first_name"
              id="firstname"
              className="name-input"
              value={values.first_name}
              onChange={onInputChange}
            />
          </TextInputComp>
          <TextInputComp
            label="Middle name"
            divclass="patient-names-row-2"
            textclass="patient-sublabel"
          >
            <input
              type="text"
              name="middle_name"
              id="midname"
              className="name-input"
              value={values.middle_name}
              onChange={onInputChange}
            />
          </TextInputComp>
          {/* <TextInputComp
            label="Phone number"
            divclass="patient-names-row-2"
            textclass="patient-sublabel"
          >
            <input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              className="name-input"
              placeholder="( _ _ _ ) _ _ _ - _ _ _ _"
              onChange={onInputChange}
            />
          </TextInputComp> */}
          <div className="two-col-input">
            <div className="dateOfServiceCardContent">
              <p>Date of Birth</p>
              <IonLabel
                className="dateOfServiceCardInput"
                onClick={() => (isHaveDob ? setDatePicker2Open(true) : null)}
              >
                <IonInput
                  type="text"
                  placeholder="DD/MM/YYYY"
                  value={
                    values.dob ? moment(values.dob).format("DD/MM/YYYY") : ""
                  }
                  disabled={!isHaveDob}
                />
                <IonIcon icon={calendarClearOutline} />
              </IonLabel>
              {isDatePicker2Open && (
                <Datepicker
                  controls={["calendar"]}
                  showOnClick={false}
                  showOnFocus={false}
                  isOpen={isDatePicker2Open}
                  onClose={() => setDatePicker2Open(false)}
                  onChange={(e) => handleDOB(e)}
                  touchUi={true}
                  value={values.dob}
                  // onCellClick={handleDateSelect}
                  className="calendardiv-encounterHistory"
                />
              )}
            </div>
            <div className="checkbox-dob">
              <CheckBox
                value="off"
                onCheck={(data) => {
                  setIsHaveDob(!data);
                  if (data) {
                    setValues({
                      ...values,
                      dob: "",
                    });
                  }
                }}
                label="I don't know"
              />
            </div>
          </div>
          <div className="gender-container">
            <IonText className="patient-sublabel">Gender</IonText>
            <IonGrid className="grid">
              <IonRow className="row">
                <IonCol
                  onClick={() => HandleGender("M")}
                  className={values.gender === "M" ? "col1-clicked" : "col1"}
                >
                  <div>
                    <IonText className="gender-text">Male</IonText>
                  </div>
                </IonCol>
                <IonCol
                  onClick={() => HandleGender("F")}
                  className={values.gender === "F" ? "col2-clicked" : "col2"}
                >
                  <div>
                    <IonText className="gender-text">Female</IonText>
                  </div>
                </IonCol>
                <IonCol
                  onClick={() => HandleGender("unknown")}
                  className={
                    values.gender === "unknown" ? "col3-clicked" : "col3"
                  }
                >
                  <div>
                    <IonText className="gender-text">Unknown</IonText>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          {/* <p>Facility information</p>

          <div className="patient-names-half-row">
            <div className="dateOfServiceCardContent">
              <p>Date of intake</p>
              <IonLabel
                className="dateOfServiceCardInput"
                onClick={() => setDatePickerOpen(true)}
              >
                <IonInput
                  type="text"
                  placeholder="DD/MM/YYYY"
                  value={doi ? moment(doi).format("DD/MM/YYYY") : ""}
                />
                <IonIcon icon={calendarClearOutline} />
              </IonLabel>
              {isDatePickerOpen && (
                <Datepicker
                  controls={["calendar"]}
                  showOnClick={false}
                  showOnFocus={false}
                  isOpen={isDatePickerOpen}
                  onClose={() => setDatePickerOpen(false)}
                  onChange={(e) => setdoi(e?.value)}
                  touchUi={true}
                  value={doi}
                  // onCellClick={handleDateSelect}
                  className="calendardiv-encounterHistory"
                />
              )}
            </div>
          </div>
          <div className="patient-names-half-row">
            <div className="patient-names-innerrow-1 patient-sublabel">
              <div className="new-patient-label">
                <IonLabel>Facility MRN</IonLabel>
                <IonIcon icon={alertCircleOutline} />
              </div>
              <TextInputComp
                label=""
                divclass="patient-names-innerrow-1"
                textclass="patient-sublabel"
              >
                <input
                  type="text"
                  name="FacilityMRN"
                  id="FacilityMRN"
                  className="name-input"
                  placeholder="_ _ _ _ _ _ _ _ _"
                  onChange={onInputChange}
                />
              </TextInputComp>
            </div>
          </div>
          <TextInputComp
            label="Facility"
            divclass="patient-names-row-2"
            textclass="patient-sublabel"
          >
            <IonSelect
              className="name-input select place"
              onIonChange={(e) => {}}
              placeholder={facilityName}
            >
              {facilityData?.map((item: any, i: any) => {
                return (
                  <IonSelectOption
                    className="addpatient-dropdown-data"
                    value={item?._id}
                    onChange={(i) =>
                      getSelectedFacilityID(item?._id, item?.name)
                    }
                    key={i}
                  >
                    {facilityData?.[i]?.name}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
            
          </TextInputComp>

          <div className="patient-names-half-row">
            <div className="patient-names-innerrow-1 patient-sublabel">
              <div className="new-patient-label">
                <IonLabel>Claimocity MRN</IonLabel>
                <IonIcon icon={alertCircleOutline} />
              </div>
              <TextInputComp
                label=""
                divclass="patient-names-innerrow-1"
                textclass="patient-sublabel"
              >
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  className="name-input"
                  placeholder="_ _ _ _ _ _ _ _ _"
                />
              </TextInputComp>
            </div>
          </div>

          <p>Other information</p>
          <div className="patient-names-half-row">
            <TextInputComp
              label="Room"
              divclass="patient-names-innerrow-1"
              textclass="patient-sublabel"
            >
              <input
                type="text"
                name="lastname"
                id="lastname"
                className="name-input"
                placeholder=""
              />
            </TextInputComp>
          </div>
          <IonText className="patient-sublabel">
            <u>Add billing notes</u>
          </IonText> */}

          {/* <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toastmessage}
            duration={1000}
          /> */}

          <div className="event-buttons">
            <div className="left-btn-holder">
              <Button boldfont color="secondary" fullWidth onClick={onCancel}>
                Cancel
              </Button>
            </div>
            <div className="right-btn-holder">
              <Button
                boldfont
                fullWidth
                onClick={handleSavePatient}
                disabled={!isValidate || validateError.status}
              >
                Save patient
              </Button>
            </div>
          </div>
        </div>
        <Toast
          toastList={toastedMessage}
          position="top-right"
          autoDelete={true}
          autoDeleteTime={4000}
        />
      </IonContent>
    </IonPage>
  );
};

export default EditPatient;
