import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { selectUser } from "../../store/reducers/user";

type PermissionProviderProps = {
  permission?: string;
  onSearchClear?:()=>void};
const PermissionProvider: React.FC<PermissionProviderProps> = ({
  children,
  permission,
  onSearchClear
}) => {
  const [isGranded, setIsGranded] = useState(false);
  const userData = useAppSelector(selectUser);

  useEffect(() => {
    let permissiondata = userData.permissions;
    if (permissiondata.includes(permission)) {
      setIsGranded(true);
    }
  }, []);
  return <>{isGranded ? <div onClick={onSearchClear}>{children }</div> : null}</>;
};

export default PermissionProvider;
