import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFacilities } from "../../helper/backendHelper";

export const getFacility = createAsyncThunk(
  //action type string
  "facility/getFacility",
  // callback function
  async ( _,thunkAPI) => {
    
    const facilityRes = await getFacilities();
    if (facilityRes?.status === 500) {
      return thunkAPI.rejectWithValue("Server Error");
    } else {
      if (facilityRes?.status === "success") {
          return {facilityRes };
        } 
      } 
    }
);


