import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonPage,
  IonMenuButton,
  IonText,
  useIonRouter,
} from "@ionic/react";
import PatientList from "./Components/PatientList/PatientList";
import TodayFacilityInfo from "./TodayFacilityInfo";

import { getProviderHold } from "../../helper/backendHelper";
import ProviderHolds from "./ProviderHolds/ProviderHolds";
import "./DashBoard.scss";
import PracticeOverviewInfo from "./PracticeOverviewInfo";
import Hint from "./Hint/Hint";
import Button from "../../components/Common/Buttons/Buttons";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getVisitsCensus } from "../../store/thunk/censusThunk";
import moment from "moment";
import { selectUser } from "../../store/reducers/user";
import {
  getFacilitiesList,
  getInpatientsEncounters,
} from "../../store/thunk/DashboardThunk";

const DashBoard: React.FC = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectUser);
  const features = useAppSelector((state) => state?.user?.features) || [];
  const patients =
    useAppSelector((state) => state.dashboard)?.patientsList || [];
  const { allFacilitiesList } = useAppSelector((state) => state.dashboard);
  const [todaysFacList, setTodaysFacList] = useState(
    allFacilitiesList.length > 0
      ? allFacilitiesList?.map((item: any) => {
          return {
            ...item,
            selected: true,
          };
        })
      : []
  );
  const [providerHoldData, setProviderHoldData] = useState<any>();
  const [issuesCount, setIssuesCount] = useState<number>(0);
  const router = useIonRouter();

  const roles = useAppSelector((state) => state?.user?.roles) || [];

  const getProviderHoldData = async () => {
    let res = await getProviderHold({
      startIndex: 0,
      recordCount: 3,
      scope: "provider",
    });
    if (res?.status === "SUCCESS") {
      setProviderHoldData(res?.data.result);
      setIssuesCount(res.data.total);
    }
  };
  const onGoToCensus = () => {
    router.push("census", "back");
  };
  useEffect(() => {
    getProviderHoldData();
    dispatch(
      getInpatientsEncounters({
        servicedate: moment(new Date()).format("YYYY-MM-DD"),
        thisProvider: userData.provider,
      })
    );
    dispatch(getFacilitiesList());
  }, []);

  useEffect(() => {
    setTodaysFacList(
      allFacilitiesList.length > 0
        ? allFacilitiesList?.map((item: any) => {
            return {
              ...item,
              selected: true,
            };
          })
        : []
    );
  }, [allFacilitiesList]);

  return (
    <IonPage className="ion-page-dashboard dashboard-bg">
      <IonHeader className="ion-header-dashboard d-pad-05">
        <IonToolbar className="ion-toolbar-dashboard" mode="ios">
          <IonMenuButton slot="start" className="ion-icon-size-dashboard" />
          <IonTitle className="title-header">
            {/* {selectedProvider?.organization_name} */}
          </IonTitle>
          {/* <IonButtons slot="end" className="action-holder">
            <div onClick={() => onGoToPracticeIQ()}>
              <IconSvg Icon={ChartIcon} />
            </div>
            <FeatureProvider
              children={
                <div onClick={() => onGoToMessaging()}>
                  <IconSvg Icon={MailIcon} />
                </div>
              }
              feature={"Messaging"}
            />
          </IonButtons> */}
        </IonToolbar>
      </IonHeader>

      <IonContent className="dashboard-content">
        <div>
          <TodayFacilityInfo
            todaysFacList={todaysFacList}
            setTodaysFacList={setTodaysFacList}
          />
          <PatientList
            allFacilitiesList={allFacilitiesList}
            patients={patients}
          />
          <div className="ProviderHolds-container">
            <ProviderHolds
              providerHoldData={providerHoldData}
              issuesCount={issuesCount}
            />
          </div>
          {roles.findIndex(
            (item: any) => item.groupname === "Claimocity Practice Owner"
          ) >= 0 ? (
            <PracticeOverviewInfo roles={roles} />
          ) : (
            <div></div>
          )}

          {features.find((item: any) => item === "Dashboard Hints") && (
            <Hint patients={patients} />
          )}
        </div>
      </IonContent>
      <div className="btn-holder-dashboard">
        <Button tallbutton boldfont onClick={onGoToCensus} fullWidth>
          <IonText className="btn-dashboard-text">Go to Census</IonText>
        </Button>
      </div>
    </IonPage>
  );
};

export default DashBoard;
