import { IonIcon } from "@ionic/react";
import React, { useState } from "react";
import "./SelectBox.scss";
import { chevronDownOutline } from "ionicons/icons";

const SelectBox: React.FC<{
  options: any[];
  selected: string;
  setSelected: (text: string) => void;
  getFacil?: (fac_id: string, fac_name: number) => void;
  selectedIds?: string[];
}> = (props) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <div className="select-box">
      <div
        className={isActive ? "options-container active" : "options-container"}
      >
        {props.options
          .filter((item) => item._id !== "-1")
          .map((option, index) => {
            return (
              <div
                className="option"
                onClick={() => {
                  props?.getFacil?.(option, index);
                  props.setSelected(option._id);
                  setIsActive(false);
                }}
                key={index}
              >
                <input
                  type="radio"
                  className="radio"
                  id={option}
                  name="category"
                />
                <label htmlFor={option.name}>{option.name}</label>
              </div>
            );
          })}
      </div>

      <div className="selected" onClick={() => setIsActive(!isActive)}>
        {props.options.find((item) => item._id === props.selected)?.name}
        <IonIcon className="downIcon" icon={chevronDownOutline}></IonIcon>
      </div>
    </div>
  );
};

export default SelectBox;
