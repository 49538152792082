/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  IonContent,
  IonHeader,
  IonGrid,
  IonText,
  IonToolbar,
  IonRow,
  IonButtons,
  IonBackButton,
  IonLabel,
  useIonRouter,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import "../ForgotPassword/ForgotPassword.scss";
import Button from "../../components/Common/Buttons/Buttons";
import { InputBoxType } from "../../types/Types";
import { Layout } from "../../components/Layout/Layout";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { verifyUsername } from "../../store/thunk/forgotPasswordThunk";
import { closeError } from "../../store/reducers/forgotPassword";
import InputFieldNew from "../../components/Common/InputFieldNew/InputFiledNew";

const PasswordRecovery: React.FC = () => {
  const [userName, setUsername] = useState<InputBoxType>(null);
  const [invalidUserName, setInvalidUserName] = useState<boolean>(false);

  const thisState = useAppSelector((state) => state.forgotPassword);
  const [isSubmited, setIsSubmited] = useState(false);

  const router = useIonRouter();

  const dispatch = useAppDispatch();

  const onPasswordRecovery = async () => {
    setIsSubmited(true);
    dispatch(closeError());

    if (userName != null && userName !== "") {
      let res = await dispatch(verifyUsername({ username: userName }));

      if (res.payload.username != null && res.payload.status === "success") {
        setUsername(null);
        router.push("/verificationCode", "forward", "push");
      }
    }
  };

  const HandleInput = (name: any) => {
    setUsername(name);

    dispatch(closeError());

    if (name.length > 2) {
      setInvalidUserName(false);
    } else {
      setInvalidUserName(true);
    }
  };

  return (
    <Layout isLoading={thisState.isloading}>
      <IonContent className="forgot-password-page ion-padding">
        <div className="main-bg">
          <IonHeader class="ion-no-border" className="ion-header">
            <IonToolbar className="ion-toolbar" mode="ios">
              <IonButtons slot="start">
                <IonBackButton
                  defaultHref="/login"
                  text=""
                  icon={arrowBackOutline}
                />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <div className="centered-div-holder">
            <div className="reset-password-container">
              <IonGrid fixed className="ion-grid1 ion-justify-content-center">
                <IonRow className="text-center title">
                  <IonText className="page-heading">Reset Password</IonText>
                </IonRow>

                <IonRow className="ion-row">
                  <IonText color="medium" className="ion-text page-sub-txt">
                    Please enter your Claimocity username and we will send a
                    recovery code to your registered mobile phone
                  </IonText>
                </IonRow>

                <IonRow className="label-text">
                  <IonLabel className="ionlabel">Username</IonLabel>
                </IonRow>

                <IonRow className="ion-row">
                  <InputFieldNew
                    type="text"
                    value={userName}
                    invalid={invalidUserName ? true : false}
                    onKeyUp={(e) => HandleInput(e.currentTarget?.value)}
                  />
                </IonRow>

                {invalidUserName && (
                  <IonRow>
                    <span className="errormsg">
                      Please enter a valid username
                    </span>
                  </IonRow>
                )}

                {isSubmited && thisState.isError && (
                  <IonRow>
                    <span className="errormsg">{thisState.errorMsg}</span>
                  </IonRow>
                )}
              </IonGrid>

              <div className="recovery-btn-holder">
                <Button
                  disabled={!userName}
                  onClick={onPasswordRecovery}
                  fullWidth
                >
                  <IonLabel className="lbl-continue">Continue</IonLabel>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </Layout>
  );
};

export default PasswordRecovery;
