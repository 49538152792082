/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import AllMessages from "../../pages/Messaging/MessageList/AllMessages";
import { disconnectSocket } from "../../socket";
import { PracticeType, ProviderType, UserType } from "../../types/Types";
import { RootState } from "../store";
import { getChatMessages, getContactList, getMessageList, loadMore, setFavUser } from "../thunk/messageThunk";
import {
  mfaLogin,
  providerLogin,
  rememberAuth,
  userLogin,
} from "../thunk/userThunk";

type MessageState = {
  messagesList: any[];
  archiveList: any[];
  draftList: any[];
  selectedChat: any;
  contactList: any[];
  practiceUser : any[]; 
  supportUser: any[];
  distributionGroup: any;
  messages: any[];
  redirectedFrom:any;
  allMessagesList: any[];
};

const initialState: MessageState = {
  messagesList: [],
  archiveList: [],
  draftList: [],
  selectedChat: {},
  contactList: [],
  practiceUser : [], 
  supportUser: [],
  distributionGroup: {},
  messages: [],
  redirectedFrom:"",
  allMessagesList: []
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setSelectedChat: (state, action) => {
      state.selectedChat = action?.payload;
    },
    setPracticeUsers: (state, action) => {
      state.practiceUser = action?.payload;
    },
    setSupportUsers: (state, action) => {
      state.supportUser = action?.payload;
    },
    setMessages: (state, action) => {
        state.messages = action?.payload;
    },
    setRedirectedFrom: (state, action) => {
      state.redirectedFrom = action?.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getMessageList.pending, (state, action) => {})
      .addCase(getMessageList.fulfilled, (state, action) => {
        if (action?.payload?.type === "messages") {
          state.messagesList = [...action?.payload?.data];
        } else if (action?.payload?.type === "archive") {
          state.archiveList = [...action?.payload?.data];
        } else if (action?.payload?.type === "draft") {
          state.draftList = [...action?.payload?.data];
        }
        else if (action?.payload?.type === "all") {
          state.allMessagesList = [...action?.payload?.data];
        }
      })

      .addCase(getMessageList.rejected, (state, action) => {})
      .addCase(loadMore.pending, (state, action) => {})
      .addCase(loadMore.fulfilled, (state, action) => {
        if (action?.payload?.type === "messages") {
          state.messagesList = [
            ...state?.messagesList,
            ...action?.payload?.data,
          ];
        } else if (action?.payload?.type === "archive") {
          state.archiveList = [...state?.archiveList, ...action?.payload?.data];
        } else if (action?.payload?.type === "draft") {
          state.draftList = [...state?.draftList, ...action?.payload?.data];
        }
        else if (action?.payload?.type === "all") {
          state.allMessagesList = [...state?.allMessagesList, ...action?.payload?.data];
        }
      })

      .addCase(loadMore.rejected, (state, action) => {})

      .addCase(getContactList.pending, (state, action) => {})
      .addCase(getContactList.fulfilled, (state, action) => {
        state.contactList = action?.payload?.data;
        state.practiceUser = action?.payload?.practiceUser;
        state.supportUser = action?.payload?.supportUser;
        state.distributionGroup = action?.payload?.distributionGroup;
      })

      .addCase(getContactList.rejected, (state, action) => {})

      .addCase(setFavUser.pending, (state, action) => {})
      .addCase(setFavUser.fulfilled, (state, action) => {
        state.contactList = action?.payload?.contactList;
        state.practiceUser = action?.payload?.practiceUser;
        state.supportUser = action?.payload?.supportUser;
      })

      .addCase(setFavUser.rejected, (state, action) => {})

      .addCase(getChatMessages.pending, (state, action) => {})
      .addCase(getChatMessages.fulfilled, (state, action) => {
        state.messages = action?.payload?.data;
      })

      .addCase(getChatMessages.rejected, (state, action) => {})
  },
});

export const {setSelectedChat, setPracticeUsers, setSupportUsers, setMessages, setRedirectedFrom} = messageSlice.actions;
export const selectUser = (state: RootState) => state.user;
export const messageReducer = messageSlice.reducer;
