/* eslint-disable react-hooks/exhaustive-deps */
import { IonCheckbox, IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./CheckBox.scss";

type CheckBoxProps = {
  value?: string;
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  onCheck?: (isChecked: boolean) => void;
  minus?: boolean;
  label?: string;
  labelClassName?: string;
  strictMode?: boolean;
};

const CheckBox: React.FC<CheckBoxProps> = ({
  value,
  onCheck,
  checked,
  disabled,
  minus,
  label,
  labelClassName,
  strictMode,
  ...rest
}) => {
  const [isChecked, setIsChecked] = useState(checked ? true : false);

  useEffect(() => {
    if (onCheck) {
      onCheck(isChecked);
    }
  }, [isChecked]);

  const trigerOnCheck = () => {
    if (onCheck && strictMode) {
      onCheck(!checked);
    }
  };

  let CheckId = Math.random().toString();

  return minus ? (
    <div
      className={`minus-check-box ion-color ion-color-primary ${
        disabled ? "disabled" : ""
      } interactive`}
    >
      <input
        type="checkbox"
        checked={strictMode ? checked : isChecked}
        onChange={(e) =>
          strictMode ? trigerOnCheck() : setIsChecked(e.currentTarget.checked)
        }
        id={CheckId}
        disabled={disabled ? true : false}
      />
      <label htmlFor={CheckId}>{label ? label : ""}</label>
    </div>
  ) : (
    <>
      <IonCheckbox
        slot="start"
        color="primary"
        className="checkbox"
        mode="md"
        value={value}
        checked={strictMode ? checked : isChecked}
        onIonChange={(e) =>
          strictMode ? trigerOnCheck() : setIsChecked(e.detail.checked)
        }
        disabled={disabled ? true : false}
        {...rest}
      />
      {label ? (
        <IonLabel
          onClick={() => (!disabled ? setIsChecked(!isChecked) : null)}
          className={`checkbox-label ${labelClassName ? labelClassName : ""}`}
        >
          {label ? label : ""}
        </IonLabel>
      ) : null}
    </>
  );
};

export default CheckBox;
