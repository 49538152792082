import { IonHeader } from "@ionic/react";
import React from "react";
import "./TypoGraphy.scss";
interface typoGraphyProps {
  displayMode?: string;
  contentType?: string;
  title?: string;
}
const TypoGraphy: React.FC<typoGraphyProps> = ({
  children,
  displayMode,
  contentType,
}) => {
  return (
    <div>
      {displayMode === "desktop" && contentType === "desktopHeadOne" ? (
        <IonHeader className="desktopHeadOne">{children}</IonHeader>
      ) : null}

      {displayMode === "desktop" && contentType === "desktopHeadTwo" ? (
        <IonHeader className="desktopHeadTwo">{children}</IonHeader>
      ) : null}
      {displayMode === "desktop" && contentType === "desktopHeadThree" ? (
        <IonHeader className="desktopHeadThree">{children}</IonHeader>
      ) : null}
      {displayMode === "desktop" && contentType === "desktopHeadFour" ? (
        <IonHeader className="desktopHeadFour">{children}</IonHeader>
      ) : null}
      {displayMode === "desktop" && contentType === "desktopHeadFive" ? (
        <IonHeader className="desktopHeadFive">{children}</IonHeader>
      ) : null}
      {displayMode === "desktop" && contentType === "desktopBodyOne" ? (
        <IonHeader className="desktopBodyOne">{children}</IonHeader>
      ) : null}
      {displayMode === "desktop" && contentType === "desktopBodyTwo" ? (
        <IonHeader className="desktopBodyTwo">{children}</IonHeader>
      ) : null}
      {displayMode === "desktop" && contentType === "desktopSubTitle" ? (
        <IonHeader className="desktopSubTitle">{children}</IonHeader>
      ) : null}
      {/*mobile Typography */}
      {displayMode === "mobile" && contentType === "mobileHeadOne" ? (
        <IonHeader className="mobileHeadOne">{children}</IonHeader>
      ) : null}
      {displayMode === "mobile" && contentType === "mobileHeadTwo" ? (
        <IonHeader className="mobileHeadTwo">{children}</IonHeader>
      ) : null}
      {displayMode === "mobile" && contentType === "mobileHeadThree" ? (
        <IonHeader className="mobileHeadThree">{children}</IonHeader>
      ) : null}
      {displayMode === "mobile" && contentType === "mobileHeadThreeSmall" ? (
        <IonHeader className="mobileHeadThreeSmall">{children}</IonHeader>
      ) : null}
      {displayMode === "mobile" && contentType === "mobileBodyOne" ? (
        <IonHeader className="mobileBodyOne">{children}</IonHeader>
      ) : null}
      {displayMode === "mobile" && contentType === "mobileBodyTwo" ? (
        <IonHeader className="mobileBodyTwo">{children}</IonHeader>
      ) : null}
      {displayMode === "mobile" && contentType === "mobileSubTitle" ? (
        <IonHeader className="mobileSubTitle">{children}</IonHeader>
      ) : null}
      {displayMode === "button" && contentType === "buttonText" ? (
        <IonHeader className="buttonText">{children}</IonHeader>
      ) : null}
      {displayMode === "link" && contentType === "linkText" ? (
        <IonHeader className="linkText">{children}</IonHeader>
      ) : null}
      {displayMode === "input" && contentType === "inputFill" ? (
        <IonHeader className="inputFill">{children}</IonHeader>
      ) : null}
      {displayMode === "input" && contentType === "inputTitle" ? (
        <IonHeader className="inputTitle">{children}</IonHeader>
      ) : null}
      {displayMode === "input" && contentType === "placeholder" ? (
        <IonHeader className="inputTitle">{children}</IonHeader>
      ) : null}
    </div>
  );
};

export default TypoGraphy;
